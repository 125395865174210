//
// AlertHistoryDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/alert_history_detail.html'
import htmlForDefault from './alert_history_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertHistoryDetailXml
export class AlertHistoryDetailXml {
    xmlRoot!: HTMLElement
    alertType!: HTMLElement
    site!: HTMLElement
    startedUser!: HTMLElement
    endedInfo!: HTMLElement
    reason!: HTMLElement
    changeLog!: HTMLDivElement
    threadMessages!: HTMLDivElement
    back!: HTMLButtonElement
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.alertType = getViewById<HTMLElement>(view, "alert_type")
        this.site = getViewById<HTMLElement>(view, "site")
        this.startedUser = getViewById<HTMLElement>(view, "started_user")
        this.endedInfo = getViewById<HTMLElement>(view, "ended_info")
        this.reason = getViewById<HTMLElement>(view, "reason")
        this.changeLog = getViewById<HTMLDivElement>(view, "changeLog")
        this.threadMessages = getViewById<HTMLDivElement>(view, "threadMessages")
        this.back = getViewById<HTMLButtonElement>(view, "back")
        return view
    }
}
