// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/ChatMessage.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Time } from '../util/time'
import { User } from './User'
import { ChatThread } from './ChatThread'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { ChatAttachment } from './ChatAttachment'
import { HasId, getNO_ID } from './HasId'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.ChatMessage
export class ChatMessage implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly thread: ForeignKey<ChatThread>;
    public readonly authorId: (ForeignKey<User> | null);
    public readonly created: Date;
    public readonly content: string;
    public readonly timestamp: Date;
    public deletedReason: string;
    public creator_building: (string | null);
    public creator_room_status: (string | null);
    public creator_room_name: (string | null);
    public readonly attachmentsDeep: Array<ChatAttachment>;
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, thread: ForeignKey<ChatThread>, authorId: (ForeignKey<User> | null) = null, created: Date = Time.INSTANCE.date(), content: string, timestamp: Date = Time.INSTANCE.date(), deletedReason: string = "", creator_building: (string | null) = "", creator_room_status: (string | null) = "", creator_room_name: (string | null) = "", attachmentsDeep: Array<ChatAttachment> = []) {
        this.id = id;
        this.deleted = deleted;
        this.thread = thread;
        this.authorId = authorId;
        this.created = created;
        this.content = content;
        this.timestamp = timestamp;
        this.deletedReason = deletedReason;
        this.creator_building = creator_building;
        this.creator_room_status = creator_room_status;
        this.creator_room_name = creator_room_name;
        this.attachmentsDeep = attachmentsDeep;
    }
    public static fromJson(obj: any): ChatMessage { return new ChatMessage(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["thread"], [Number]) as number,
        parseJsonTyped(obj["author_id"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["content"], [String]) as string,
        parseJsonTyped(obj["timestamp"], [Date]) as Date,
        parseJsonTyped(obj["deleted_reason"], [String]) as string,
        parseJsonTyped(obj["creator_building"], [String]) as (string | null),
        parseJsonTyped(obj["creator_room_status"], [String]) as (string | null),
        parseJsonTyped(obj["creator_room_name"], [String]) as (string | null),
        parseJsonTyped(obj["attachments_deep"], [Array, [ChatAttachment]]) as Array<ChatAttachment>
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        thread: this.thread,
        author_id: this.authorId,
        created: this.created,
        content: this.content,
        timestamp: this.timestamp,
        deleted_reason: this.deletedReason,
        creator_building: this.creator_building,
        creator_room_status: this.creator_room_status,
        creator_room_name: this.creator_room_name,
        attachments_deep: this.attachmentsDeep
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.thread);
        hash = 31 * hash + hashAnything(this.authorId);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.content);
        hash = 31 * hash + hashAnything(this.timestamp);
        hash = 31 * hash + hashAnything(this.deletedReason);
        hash = 31 * hash + hashAnything(this.creator_building);
        hash = 31 * hash + hashAnything(this.creator_room_status);
        hash = 31 * hash + hashAnything(this.creator_room_name);
        hash = 31 * hash + hashAnything(this.attachmentsDeep);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ChatMessage && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.thread, other.thread) && safeEq(this.authorId, other.authorId) && safeEq(this.created, other.created) && safeEq(this.content, other.content) && safeEq(this.timestamp, other.timestamp) && safeEq(this.deletedReason, other.deletedReason) && safeEq(this.creator_building, other.creator_building) && safeEq(this.creator_room_status, other.creator_room_status) && safeEq(this.creator_room_name, other.creator_room_name) && safeEq(this.attachmentsDeep, other.attachmentsDeep) }
    public toString(): string { return `ChatMessage(id=${this.id}, deleted=${this.deleted}, thread=${this.thread}, authorId=${this.authorId}, created=${this.created}, content=${this.content}, timestamp=${this.timestamp}, deletedReason=${this.deletedReason}, creator_building=${this.creator_building}, creator_room_status=${this.creator_room_status}, creator_room_name=${this.creator_room_name}, attachmentsDeep=${this.attachmentsDeep})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, thread: ForeignKey<ChatThread> = this.thread, authorId: (ForeignKey<User> | null) = this.authorId, created: Date = this.created, content: string = this.content, timestamp: Date = this.timestamp, deletedReason: string = this.deletedReason, creator_building: (string | null) = this.creator_building, creator_room_status: (string | null) = this.creator_room_status, creator_room_name: (string | null) = this.creator_room_name, attachmentsDeep: Array<ChatAttachment> = this.attachmentsDeep): ChatMessage { return new ChatMessage(id, deleted, thread, authorId, created, content, timestamp, deletedReason, creator_building, creator_room_status, creator_room_name, attachmentsDeep); }
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace ChatMessage {
    //! Declares com.tresitgroup.android.tresit.model.ChatMessage.Companion
    export class Companion {
        private constructor() {
            this.createdSort = ApiSort.constructorStringComparatorHasId<ChatMessage>("sort=created", (o1: ChatMessage, o2: ChatMessage): number => safeCompare(o1.created, o2.created));
            this.createdDownSort = ApiSort.constructorStringComparatorHasId<ChatMessage>("sort=-created", (o1: ChatMessage, o2: ChatMessage): number => safeCompare(o2.created, o1.created));
            this.contentSort = ApiSort.constructorStringComparatorHasId<ChatMessage>("sort=content", (o1: ChatMessage, o2: ChatMessage): number => safeCompare(o1.content, o2.content));
            this.contentDownSort = ApiSort.constructorStringComparatorHasId<ChatMessage>("sort=-content", (o1: ChatMessage, o2: ChatMessage): number => safeCompare(o2.content, o1.content));
        }
        public static INSTANCE = new Companion();
        
        public partOfThread(threadId: ForeignKey<ChatThread>): ApiFilter<ChatMessage> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessage>(`thread=${threadId}`, undefined, (it: ChatMessage): boolean => it.thread === threadId);
        }
        
        public withThreadId(threadId: number): ApiFilter<ChatMessage> {
            return new ApiFilter<ChatMessage>(`thread=${threadId}`);
        }
        
        public belongsToSchool(schoolId: ForeignKey<School>): ApiFilter<ChatMessage> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessage>(`school=${schoolId}`, (db: MockDatabase, msg: ChatMessage): boolean => ((): (boolean | null) => {
                const temp2105 = ((db.threads.objects.get(msg.thread) ?? null)?.schools ?? null);
                if(temp2105 === null) { return null }
                return temp2105.some((x) => safeEq(schoolId, x))
            })() === true, undefined);
        }
        public belongsToAlertThread(): ApiFilter<ChatMessage> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessage>("in_alert_thread=true", (db: MockDatabase, msg: ChatMessage): boolean => ((db.threads.objects.get(msg.thread) ?? null)?.alert ?? null) !== null, undefined);
        }
        public textSearch(query: string): ApiFilter<ChatMessage> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessage>(`query=${query}`, (db: MockDatabase, hist: ChatMessage): boolean => true, undefined);
        }
        
        public readonly createdSort: ApiSort<ChatMessage>;
        
        public readonly createdDownSort: ApiSort<ChatMessage>;
        
        
        //        val userSort:           ApiSort<ChatMessage> = ApiSort("sort=author_name", Comparator { o1, o2 -> o1.authorId?.compareTo(o2.authorId ?: NO_ID) ?: -1 })
        //        val userDownSort:       ApiSort<ChatMessage> = ApiSort("sort=-author_name", Comparator { o1, o2 -> o2.authorId?.compareTo(o1.authorId ?: NO_ID) ?: -1 })
        
        public readonly contentSort: ApiSort<ChatMessage>;
        
        public readonly contentDownSort: ApiSort<ChatMessage>;
        
    }
}