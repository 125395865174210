//
// SelectSchoolXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './select_school.html'
//! Declares com.tresitgroup.android.tresit.layouts.SelectSchoolXml
export class SelectSchoolXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    completeTask!: HTMLButtonElement
    filter!: HTMLInputElement
    schools!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.completeTask = getViewById<HTMLButtonElement>(view, "completeTask")
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.schools = getViewById<HTMLDivElement>(view, "schools")
        return view
    }
}
