// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/statuses.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { map as iterMap } from 'butterfly-web/dist/kotlin/lazyOp'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { SessionApi } from '../../api/SessionApi'
import { Floor } from '../../model/Floor'
import { Building } from '../../model/Building'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { CombinedStatus, xSequenceWorst } from '../../model/CombinedStatus'
import { Room } from '../../model/Room'
import { School } from '../../model/School'
import { xObservablePropertyDistinctUntilChanged } from 'butterfly-web/dist/observables/DistinctObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.map.roomsForSchool>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiRoomsForSchool(this_: SessionApi, siteId: ForeignKey<School>): ObservableProperty<(Array<Room> | null)> {
    return this_.rooms.observableListSimple(Room.Companion.INSTANCE.forSchool(siteId), undefined, 1000, undefined, undefined, undefined);
}

//! Declares com.tresitgroup.android.tresit.vg.map.roomStatusCached>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiRoomStatusCached(this_: SessionApi, roomId: ForeignKey<Room>): ObservableProperty<(CombinedStatus | null)> {
    return xObservablePropertyMap<(Room | null), (CombinedStatus | null)>(this_.rooms.cached(roomId), (it: (Room | null)): (CombinedStatus | null) => (it?.combinedStatus ?? null));
}

//! Declares com.tresitgroup.android.tresit.vg.map.floorStatusCached>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiFloorStatusCached(this_: SessionApi, siteId: ForeignKey<School>, floorId: ForeignKey<Floor>): ObservableProperty<(CombinedStatus | null)> {
    return xObservablePropertyDistinctUntilChanged<(CombinedStatus | null)>(xObservablePropertyMap<(Array<Room> | null), (CombinedStatus | null)>(xObservablePropertyMap<(Array<Room> | null), (Array<Room> | null)>(xSessionApiRoomsForSchool(this_, siteId), (it: (Array<Room> | null)): (Array<Room> | null) => ((): (Array<Room> | null) => {
                    const temp5201 = it;
                    if(temp5201 === null) { return null }
                    return temp5201.filter((it: Room): boolean => it.floor === floorId)
        })()), (it: (Array<Room> | null)): (CombinedStatus | null) => ((): (CombinedStatus | null) => {
            const temp5204 = ((): (Iterable<(CombinedStatus | null)> | null) => {
                const temp5205 = ((): (Iterable<Room> | null) => {
                    const temp5207 = it;
                    if(temp5207 === null) { return null }
                    return temp5207
                })();
                if(temp5205 === null) { return null }
                return iterMap(temp5205, (it: Room): (CombinedStatus | null) => it.combinedStatus)
            })();
            if(temp5204 !== null) {
                return xSequenceWorst(temp5204)
            } else { return null }
    })()));
}

//! Declares com.tresitgroup.android.tresit.vg.map.buildingStatusCached>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiBuildingStatusCached(this_: SessionApi, siteId: ForeignKey<School>, buildingId: ForeignKey<Building>): ObservableProperty<(CombinedStatus | null)> {
    return xObservablePropertyDistinctUntilChanged<(CombinedStatus | null)>(xObservablePropertyMap<(Array<Room> | null), (CombinedStatus | null)>(xObservablePropertyMap<(Array<Room> | null), (Array<Room> | null)>(xSessionApiRoomsForSchool(this_, siteId), (it: (Array<Room> | null)): (Array<Room> | null) => ((): (Array<Room> | null) => {
                    const temp5208 = it;
                    if(temp5208 === null) { return null }
                    return temp5208.filter((it: Room): boolean => it.building === buildingId)
        })()), (it: (Array<Room> | null)): (CombinedStatus | null) => {
            if (it !== null && it!.length === 0) {
                console.warn(`${"statuses"}: ${`Building ${buildingId} has no rooms?  Perhaps there is a school mismatch?  Looked for rooms in site ${siteId}`}`);
            }
            return ((): (CombinedStatus | null) => {
                const temp5213 = ((): (Iterable<(CombinedStatus | null)> | null) => {
                    const temp5214 = ((): (Iterable<Room> | null) => {
                        const temp5216 = it;
                        if(temp5216 === null) { return null }
                        return temp5216
                    })();
                    if(temp5214 === null) { return null }
                    return iterMap(temp5214, (it: Room): (CombinedStatus | null) => it.combinedStatus)
                })();
                if(temp5213 !== null) {
                    return xSequenceWorst(temp5213)
                } else { return null }
            })();
    }));
}

//! Declares com.tresitgroup.android.tresit.vg.map.campusStatusCached>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiCampusStatusCached(this_: SessionApi, siteId: ForeignKey<School>): ObservableProperty<(CombinedStatus | null)> {
    return xObservablePropertyDistinctUntilChanged<(CombinedStatus | null)>(xObservablePropertyMap<(Array<Room> | null), (CombinedStatus | null)>(xSessionApiRoomsForSchool(this_, siteId), (it: (Array<Room> | null)): (CombinedStatus | null) => ((): (CombinedStatus | null) => {
        const temp5217 = ((): (Iterable<(CombinedStatus | null)> | null) => {
            const temp5218 = ((): (Iterable<Room> | null) => {
                const temp5220 = it;
                if(temp5220 === null) { return null }
                return temp5220
            })();
            if(temp5218 === null) { return null }
            return iterMap(temp5218, (it: Room): (CombinedStatus | null) => it.combinedStatus)
        })();
        if(temp5217 !== null) {
            return xSequenceWorst(temp5217)
        } else { return null }
    })()));
}