// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Room.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { Floor } from './Floor'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { CombinedStatus } from './CombinedStatus'
import { StatusEnum } from './StatusEnum'
import { ForeignKey } from './ForeignKey'
import { Building } from './Building'
import { HasId, getNO_ID } from './HasId'
import { ApiModifier } from '../api/ApiModifier'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Room
export class Room implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly floor: ForeignKey<Floor>;
    public readonly building: (ForeignKey<Building> | null);
    public readonly school: (ForeignKey<School> | null);
    public readonly name: string;
    public readonly safe: (StatusEnum | null);
    public readonly alertId: (number | null);
    public readonly needsMedicalAttention: boolean;
    public constructor(id: number = getNO_ID(), floor: ForeignKey<Floor>, building: (ForeignKey<Building> | null) = null, school: (ForeignKey<School> | null) = null, name: string, safe: (StatusEnum | null) = null, alertId: (number | null) = null, needsMedicalAttention: boolean = false) {
        this.id = id;
        this.floor = floor;
        this.building = building;
        this.school = school;
        this.name = name;
        this.safe = safe;
        this.alertId = alertId;
        this.needsMedicalAttention = needsMedicalAttention;
        if (!(this.id === getNO_ID()) && (this.building === null || this.school === null)) {
            console.warn(`${"Room"}: ${`Created with id ${this.id} but a missing building or school: ${this}`}`);
        };
    }
    public static fromJson(obj: any): Room { return new Room(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["floor"], [Number]) as number,
        parseJsonTyped(obj["building"], [Number]) as (number | null),
        parseJsonTyped(obj["school"], [Number]) as (number | null),
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["safe"], [StatusEnum]) as (StatusEnum | null),
        parseJsonTyped(obj["alertId"], [Number]) as (number | null),
        parseJsonTyped(obj["needs_medical_attention"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        id: this.id,
        floor: this.floor,
        building: this.building,
        school: this.school,
        name: this.name,
        safe: this.safe,
        alertId: this.alertId,
        needs_medical_attention: this.needsMedicalAttention
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.floor);
        hash = 31 * hash + hashAnything(this.building);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.safe);
        hash = 31 * hash + hashAnything(this.alertId);
        hash = 31 * hash + hashAnything(this.needsMedicalAttention);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Room && safeEq(this.id, other.id) && safeEq(this.floor, other.floor) && safeEq(this.building, other.building) && safeEq(this.school, other.school) && safeEq(this.name, other.name) && safeEq(this.safe, other.safe) && safeEq(this.alertId, other.alertId) && safeEq(this.needsMedicalAttention, other.needsMedicalAttention) }
    public toString(): string { return `Room(id=${this.id}, floor=${this.floor}, building=${this.building}, school=${this.school}, name=${this.name}, safe=${this.safe}, alertId=${this.alertId}, needsMedicalAttention=${this.needsMedicalAttention})` }
    public copy(id: number = this.id, floor: ForeignKey<Floor> = this.floor, building: (ForeignKey<Building> | null) = this.building, school: (ForeignKey<School> | null) = this.school, name: string = this.name, safe: (StatusEnum | null) = this.safe, alertId: (number | null) = this.alertId, needsMedicalAttention: boolean = this.needsMedicalAttention): Room { return new Room(id, floor, building, school, name, safe, alertId, needsMedicalAttention); }
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.model.Room.combinedStatus
    public get combinedStatus(): (CombinedStatus | null) { return this.safe !== null ? new CombinedStatus(this.safe!, this.needsMedicalAttention) : null; }
    
    
    
}
export namespace Room {
    //! Declares com.tresitgroup.android.tresit.model.Room.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public setStatus(safe: StatusEnum): ApiModifier<Room> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<Room>("safe", safe, (it: Room): Room => it.copy(undefined, undefined, undefined, undefined, undefined, safe, undefined, undefined));
        }
        public setAlertId(alertId: (number | null)): ApiModifier<Room> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<Room>("alert_id", alertId, (it: Room): Room => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, alertId, undefined));
        }
        public setNullStatus(): ApiModifier<Room> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<Room>("safe", null, (it: Room): Room => it.copy(undefined, undefined, undefined, undefined, undefined, null, undefined, undefined));
        }
        
        public setNeedsMedical(need: boolean): ApiModifier<Room> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<Room>("needs_medical_attention", need, (it: Room): Room => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, need));
        }
        
        public textSearch(query: string): ApiFilter<Room> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Room>(`query=${query}`, undefined, (it: Room): boolean => (it.name.toLowerCase().indexOf(query.toLowerCase()) != -1));
        }
        
        public forSchool(schoolId: ForeignKey<School>): ApiFilter<Room> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Room>(`school=${schoolId}`, undefined, (it: Room): boolean => it.school === schoolId);
        }
        
        public forBuilding(buildingId: ForeignKey<Building>): ApiFilter<Room> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Room>(`building=${buildingId}`, undefined, (it: Room): boolean => it.building === buildingId);
        }
        
        public forId(roomId: ForeignKey<Room>): ApiFilter<Room> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Room>(`id=${roomId}`, undefined, (it: Room): boolean => it.id === roomId);
        }
        
        public forFloor(floorId: ForeignKey<Floor>): ApiFilter<Room> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Room>(`floor=${floorId}`, undefined, (it: Room): boolean => it.floor === floorId);
        }
    }
}