// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/MockApi.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { User } from '../model/User'
import { CampusGeoShape } from '../map/Models'
import { Exception, IllegalArgumentException } from 'butterfly-web/dist/Kotlin'
import { getTotalRequestsMade, setTotalRequestsMade } from './apiProperty'
import { Device } from '../model/Device'
import { AlertHistory } from '../model/AlertHistory'
import { getNO_ID } from '../model/HasId'
import { Image } from 'butterfly-web/dist/Image'
import { SessionApi } from './SessionApi'
import { Group } from '../model/Group'
import { MockDatabase, MockDatabasePreFilled } from './MockDatabase'
import { xAnyToJsonString } from 'butterfly-web/dist/Codable'
import { ChatThreadDetailed } from '../model/ChatThreadDetailed'
import { HttpPhase, HttpProgress } from 'butterfly-web/dist/net/HttpModels'
import { AlertHistoryDetailed } from '../model/AlertHistoryDetailed'
import { ChatAttachment } from '../model/ChatAttachment'
import { PermissionsResponse } from '../model/PermissionsResponse'
import { AdminRequestHistory } from '../model/AdminRequestHistory'
import { AlertHistoryIdResponse } from '../model/AlertHistoryIdResponse'
import { UserSchoolMembership } from '../model/UserSchoolMembership'
import { Observable, SubscriptionLike, of as rxOf, throwError as rxThrowError } from 'rxjs'
import { UserDetailed } from '../model/UserDetailed'
import { SocketMessage } from '../model/SocketMessage'
import { Alert } from '../model/Alert'
import { UnreadThread } from '../model/UnreadThread'
import { MessagesTable, ThreadTable, UserTable } from './ConcreteMockApiTables'
import { LoginActivity } from '../model/LoginActivity'
import { xObservablePropertyObservableNNGet } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { OffsiteResponse } from '../model/OffsiteResponse'
import { District } from '../model/District'
import { RegisteredDevice } from '../model/RegisteredDevice'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { MockApiTable, filterQuery } from './MockApiTable'
import { Floor } from '../model/Floor'
import { AdminApprovalListResponse } from '../model/AdminApprovalListResponse'
import { map as rxMap } from 'rxjs/operators'
import { ForeignKey } from '../model/ForeignKey'
import { AlertType } from '../model/AlertType'
import { filter as iterFilter, find as iterFind, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { BoardPost } from '../model/BoardPost'
import { Room } from '../model/Room'
import { Session } from '../model/Session'
import { StartLoginResponse } from '../model/StartLoginResponse'
import { runOrNull, safeEq } from 'butterfly-web/dist/kotlin/Language'
import { AlertGuidance } from '../model/AlertGuidance'
import { ChatMessageDetailed } from '../model/ChatMessageDetailed'
import { ChatMessage } from '../model/ChatMessage'
import { Building } from '../model/Building'
import { FirebaseMessagingToken } from '../model/FirebaseMessagingToken'
import { xMapFilter, xMapMapValues } from 'butterfly-web/dist/kotlin/Collections'
import { Api } from './Api'
import { ApiTable } from './ApiTable'
import { ApiPartialQuery } from './ApiPartialQuery'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xIterableDistinctBy, xIterableGroupBy } from 'butterfly-web/dist/kotlin/Iterables'
import { Stats } from '../model/Stats'
import { School } from '../model/School'
import { ChatThread } from '../model/ChatThread'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { CheckAlertLocationResponse } from '../model/CheckAlertLocationResponse'

//! Declares com.tresitgroup.android.tresit.api.MockApi
export class MockApi implements Api {
    public static implementsInterfaceComTresitgroupAndroidTresitApiApi = true;
    public constructor(database: (MockDatabase | null) = null) {
        this._database = database;
        if (database !== null) {
            xDisposableForever<SubscriptionLike>(database!.mainSubject.subscribe((it: SocketMessage): void => {
                console.debug(`${"MockApi"}: ${`Socket emitted ${it}`}`);
            }, undefined, undefined));
        };
        this.socketActive = new StandardObservableProperty<boolean>(true, undefined);
    }
    
    public _database: (MockDatabase | null);
    
    //! Declares com.tresitgroup.android.tresit.api.MockApi.database
    public get database(): MockDatabase {
        const it_906 = this._database;
        if (it_906 !== null) {
            return it_906;
        }
        const n = new MockDatabasePreFilled();
        
        this._database = n;
        return n;
    }
    
    
    
    
    public readonly socketActive: StandardObservableProperty<boolean>;
    
    
    public getSocket(session: Session, sessionApi: SessionApi): Observable<Observable<SocketMessage>> {
        return xObservablePropertyObservableNNGet(this.socketActive).pipe(rxMap((it: boolean): Observable<SocketMessage> => {
            if (it) {
                return this.database.mainSubject;
            } else {
                console.debug(`${"MockApi"}: ${"Asked to fail a socket connection"}`);
                throw new Exception("Socket failed to connect", undefined);
            }
        }));
    }
    
    public checkIsOkBuild(version: string): Observable<boolean> {
        return rxOf(true);
    }
    public createOffsite(session: Session, id: string): Observable<OffsiteResponse> {
        return rxOf(new OffsiteResponse(100));
    }
    public getAlertId(session: Session, id: string): Observable<AlertHistoryIdResponse> {
        return rxOf(new AlertHistoryIdResponse("100"));
    }
    
    public verifyToken(session: Session, token: string): Observable<void> {
        return rxOf(undefined);
    }
    
    
    public startLogin(email: string, fName: (string | null), lName: (string | null), phone: (string | null)): Observable<StartLoginResponse> {
        console.debug(`${"MockApi"}: ${`Request #${getTotalRequestsMade()} to startLogin`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        
        const user = iterFind(this.database.users.objects.values(), (it: User): boolean => it.email === email)
        if(user === null) { return rxThrowError(new IllegalArgumentException("User does not exist", undefined)) }
        return rxOf((this.database.userLoginStyle.get(user.id) ?? null) ?? new StartLoginResponse(undefined, undefined, undefined));
    }
    
    public cleverUrl(): Observable<StartLoginResponse> {
        console.debug(`${"MockApi"}: ${`Request #${getTotalRequestsMade()} to cleverUrl`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        
        return rxThrowError(new IllegalArgumentException("No Clever URL exists for mock.", undefined));
    }
    
    public passwordLogin(email: string, password: string, fName: (string | null), lName: (string | null), phone: (string | null)): Observable<Session> {
        console.debug(`${"MockApi"}: ${`Request #${getTotalRequestsMade()} to passwordLogin`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        
        const user = iterFind(this.database.users.objects.values(), (it: User): boolean => it.email === email)
        if(user === null) { return rxThrowError(new IllegalArgumentException("User does not exist", undefined)) }
        if (!(password === (this.database.userPasswords.get(user.id) ?? null))) {
            return rxThrowError(new IllegalArgumentException("Incorrect password", undefined));
        }
        return rxOf(MockDatabase.Companion.INSTANCE.makeSession(user));
    }
    
    public forgotPassword(email: string): Observable<void> {
        console.debug(`${"MockApi"}: ${`Request #${getTotalRequestsMade()} to forgotPassword`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        
        const user = iterFind(this.database.users.objects.values(), (it: User): boolean => it.email === email)
        if(user === null) { return rxThrowError(new IllegalArgumentException("User does not exist", undefined)) }
        this.database.userPasswords.set(user.id, "reset");
        return rxOf(undefined);
    }
    
    public changePassword(session: Session, password: string): Observable<void> {
        console.debug(`${"MockApi"}: ${`Request #${getTotalRequestsMade()} to changePassword`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        this.database.userPasswords.set(session.userId, password);
        return rxOf(undefined);
    }
    
    public clearFirebaseToken(session: Session, token: FirebaseMessagingToken): Observable<void> {
        return rxOf(undefined);
    }
    public postFirebaseToken(session: Session, token: FirebaseMessagingToken): Observable<void> {
        return rxOf(undefined);
    }
    public requestAdminAlert(session: Session, schoolId: number, name: number, created: string, initiator: number, policeStatus: string, scoped: boolean, silent: boolean, internal: boolean, description: string): Observable<void> {
        return rxOf(undefined);
    }
    
    public stats(session: Session): Observable<Stats> {
        console.debug(`${"MockApi"}: ${`Request #${getTotalRequestsMade()} to stats`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        
        const userschools: Array<ForeignKey<School>> = iterToArray(iterMap(xMapFilter(this.database.userSchoolMemberships.objects, (it: [number, UserSchoolMembership]): boolean => it[1].user === session.userId), (it: [number, UserSchoolMembership]): number => it[1].school));
        
        const otherUserMemberships: Array<UserSchoolMembership> = xIterableDistinctBy<UserSchoolMembership, number>(iterToArray(iterMap(xMapFilter(this.database.userSchoolMemberships.objects, (it: [number, UserSchoolMembership]): boolean => userschools.some((x) => safeEq(it[1].school, x))), (it: [number, UserSchoolMembership]): UserSchoolMembership => it[1])), (it: UserSchoolMembership): number => it.user);
        
        const alerts: Array<AlertHistory> = iterToArray(iterMap(xMapFilter(this.database.alertHistory.objects, (it: [number, AlertHistory]): boolean => userschools.some((x) => safeEq(it[1].school, x))), (it: [number, AlertHistory]): AlertHistory => it[1]));
        
        
        const usedGroups: Map<(ForeignKey<AlertType> | null), Array<AlertHistory>> = xIterableGroupBy<AlertHistory, (number | null)>(alerts, (it: AlertHistory): (number | null) => it.name);
        
        const usedValues: Map<(ForeignKey<AlertType> | null), number> = xMapMapValues<(number | null), Array<AlertHistory>, number>(usedGroups, (it: [(number | null), Array<AlertHistory>]): number => it[1].length);
        
        const usedMax: number = 1;
        
        const mostUsed: (AlertType | null) = iterFind(this.database.alertTypes.objects.values(), (it: AlertType): boolean => it.id === usedMax);
        
        const threads: Array<ForeignKey<ChatThread>> = iterToArray(iterFilter(this.database.threads.objects.values(), (it: ChatThread): boolean => it.schools.some((it: number): boolean => userschools.some((x) => safeEq(it, x))) && it.alert !== null)).map((it: ChatThread): number => it.id);
        
        const messPerAlert: number = iterToArray(iterFilter(this.database.messages.objects.values(), (message: ChatMessage): boolean => threads.some((x) => safeEq(message.thread, x)))).length / alerts.length;
        
        
        return rxOf(new Stats(this.database.users.objects.size, alerts.length, (mostUsed?.name ?? null) ?? "Not Named", this.database.alertTypes.objects.size, messPerAlert));
    }
    
    public refreshSession(token: string): Observable<Session> {
        return rxOf(MockDatabase.Companion.INSTANCE.makeSession((this.database.users.objects.get(MockDatabase.Companion.INSTANCE.getId(token)) ?? null)!!));
    }
    
    public cancelAlertWithReason(session: Session, id: ForeignKey<Alert>, reason: string): Observable<void> {
        const alert_1008 = (this.database.alerts.objects.get(id) ?? null);
        if (alert_1008 !== null) {
            this.database.alertHistory.addItem(new AlertHistory(getNO_ID(), alert_1008.school, alert_1008.name, new Date(), new Date(), alert_1008.initiator, session.userId, alert_1008.policeStatus, alert_1008.scoped, alert_1008.silent, alert_1008.internal, alert_1008.pendingApproval, false, reason));
            this.database.alertHistory.addItem(new AlertHistory(undefined, alert_1008.school, alert_1008.name, alert_1008.created, new Date(), alert_1008.initiator, session.userId, undefined, alert_1008.scoped, alert_1008.silent, alert_1008.internal, alert_1008.pendingApproval, undefined, reason));
        }
        this.database.alerts.deleteItemById(id);
        return rxOf(undefined);
    }
    
    public uploadAlertIcon(session: Session, id: ForeignKey<AlertType>, image: Image): Observable<AlertType> {
        return this.alertTypes(session).get(id);
    }
    
    public uploadAlertMp3(session: Session, id: ForeignKey<AlertType>, uri: File): Observable<AlertType> {
        return this.alertTypes(session).get(id);
    }
    
    public uploadAlertOgg(session: Session, id: ForeignKey<AlertType>, uri: File): Observable<AlertType> {
        return this.alertTypes(session).get(id);
    }
    
    
    public uploadAttachment(session: Session, uri: File, fileName: string, type: string): Observable<HttpProgress<ChatAttachment>> {
        return rxOf(new HttpProgress<ChatAttachment>(HttpPhase.Done, undefined, new ChatAttachment(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
    }
    
    public bindAttachments(session: Session, message: ForeignKey<ChatMessage>, attachments: Array<ForeignKey<ChatAttachment>>): Observable<void> {
        return rxOf(undefined);
    }
    
    public updateGoogleMap(session: Session, site: ForeignKey<School>, campus: CampusGeoShape): Observable<void> {
        console.log(xAnyToJsonString(campus));
        return rxOf(undefined);
    }
    
    public schools(session: Session): ApiTable<School> {
        return new MockApiTable<School>(session, this.database, this.database.schools);
    }
    
    public districts(session: Session): ApiTable<District> {
        return new MockApiTable<District>(session, this.database, this.database.districts);
    }
    
    public posts(session: Session): ApiTable<BoardPost> {
        return new MockApiTable<BoardPost>(session, this.database, this.database.posts);
    }
    
    public alerts(session: Session): ApiTable<Alert> {
        return new MockApiTable<Alert>(session, this.database, this.database.alerts);
    }
    
    public alertTypes(session: Session): ApiTable<AlertType> {
        return new MockApiTable<AlertType>(session, this.database, this.database.alertTypes);
    }
    
    public alertGuidances(session: Session): ApiTable<AlertGuidance> {
        return new MockApiTable<AlertGuidance>(session, this.database, this.database.alertGuidances);
    }
    
    public alertHistory(session: Session): ApiTable<AlertHistory> {
        return new MockApiTable<AlertHistory>(session, this.database, this.database.alertHistory);
    }
    
    public threads(session: Session): ApiTable<ChatThread> {
        return new ThreadTable(session, this.database, this.database.threads);
    }
    
    public messages(session: Session): ApiTable<ChatMessage> {
        return new MessagesTable(session, this.database, this.database.messages);
    }
    
    public buildings(session: Session): ApiTable<Building> {
        return new MockApiTable<Building>(session, this.database, this.database.buildings);
    }
    
    public floors(session: Session): ApiTable<Floor> {
        return new MockApiTable<Floor>(session, this.database, this.database.floors);
    }
    
    public rooms(session: Session): ApiTable<Room> {
        return new MockApiTable<Room>(session, this.database, this.database.rooms);
    }
    
    public users(session: Session): ApiTable<User> {
        return new UserTable(session, this.database, this.database.users);
    }
    
    public userSchoolMemberships(session: Session): ApiTable<UserSchoolMembership> {
        return new MockApiTable<UserSchoolMembership>(session, this.database, this.database.userSchoolMemberships);
    }
    //        database.userSchoolMemberships
    
    public permissionGroups(session: Session): ApiTable<Group> {
        return new MockApiTable<Group>(session, this.database, this.database.permissionGroups);
    }
    
    public loginActivity(session: Session): ApiTable<LoginActivity> {
        return new MockApiTable<LoginActivity>(session, this.database, this.database.loginActivity);
    }
    
    public firebaseMessagingToken(session: Session): ApiTable<FirebaseMessagingToken> {
        return new MockApiTable<FirebaseMessagingToken>(session, this.database, this.database.firebaseMessagingToken);
    }
    
    public devices(session: Session): ApiTable<Device> {
        return new MockApiTable<Device>(session, this.database, this.database.devices);
    }
    
    public unreadThreads(session: Session): ApiTable<UnreadThread> {
        return new MockApiTable<UnreadThread>(session, this.database, this.database.unreadThreads);
    }
    
    public postLog(session: Session, details: string): Observable<void> {
        return rxOf(undefined);
    }
    
    public usersDetailed(session: Session, query: ApiPartialQuery<UserDetailed>): Observable<Array<UserDetailed>> {
        const items = this.database.users.asList(((this.database.users.objects.get(session.userId) ?? null)?.permissionCanViewDeleted ?? null) === true).map((user: User): UserDetailed => {
            const schoolsMemberships = iterToArray(iterFilter(this.database.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === user.id));
            
            const firstSchool = (this.database.schools.objects.get(((schoolsMemberships[0] ?? null)?.school ?? null) ?? getNO_ID()) ?? null);
            
            const filteredActivities = iterToArray(iterFilter(this.database.loginActivity.objects.values(), (it: LoginActivity): boolean => it.user === user.id));
            
            const newestActivity = (((): Array<LoginActivity> => {
                const temp1033 = (it: LoginActivity): (Date | null) => it.createdAt;
                return filteredActivities.slice().sort((a, b) => -safeCompare(temp1033(a), temp1033(b)))
            })()[0] ?? null);
            
            const lastActivity = (newestActivity?.createdAt ?? null);
            
            return new UserDetailed(user.id, user.deleted, user.email, user.firstName, user.lastName, user.isStaff, user.currentLocation, user.cellPhone, user.lastAnswerUpdate, user.permissions, user.groups, user.password, user.confirmPassword, [], (firstSchool?.name ?? null), schoolsMemberships.length, lastActivity, null, undefined);
        });
        
        return rxOf(filterQuery<UserDetailed>(items, this.database, query));
    }
    
    public getAdminAlertHistory(session: Session, query: string): Observable<Array<AdminRequestHistory>> {
        return rxOf([]);
    }
    
    public getThreadId(session: Session, id: string): Observable<Array<ChatThread>> {
        return rxOf([]);
    }
    
    
    public alertHistoryDetailed(session: Session, query: ApiPartialQuery<AlertHistoryDetailed>): Observable<Array<AlertHistoryDetailed>> {
        const items = this.database.alertHistory.asList(((this.database.users.objects.get(session.userId) ?? null)?.permissionCanViewDeleted ?? null) === true).map((item: AlertHistory): AlertHistoryDetailed => {
            const type = ((): (AlertType | null) => {
                const temp1042 = item.name;
                if(temp1042 === null) { return null }
                return ((it: number): (AlertType | null) => (this.database.alertTypes.objects.get(it) ?? null))(temp1042)
            })();
            
            const initiator = ((): (User | null) => {
                const temp1045 = item.initiator;
                if(temp1045 === null) { return null }
                return ((it: number): (User | null) => (this.database.users.objects.get(it) ?? null))(temp1045)
            })();
            
            const canceler = ((): (User | null) => {
                const temp1048 = item.canceller;
                if(temp1048 === null) { return null }
                return ((it: number): (User | null) => (this.database.users.objects.get(it) ?? null))(temp1048)
            })();
            
            const school = (this.database.schools.objects.get(item.school) ?? null);
            
            return new AlertHistoryDetailed(item.id, item.school, item.name, item.created, item.cancelled, item.initiator, item.canceller, item.lastPoliceStatus, item.scoped, item.silent, item.internal, item.pendingApproval, item.requiresAdminApproval, item.cancelReason, (type?.name ?? null), (type?.icon ?? null), (school?.name ?? null), (initiator?.firstName ?? null), (canceler?.firstName ?? null), (initiator?.lastName ?? null), (canceler?.lastName ?? null), undefined, undefined, undefined);
        });
        
        
        return rxOf(filterQuery<AlertHistoryDetailed>(items, this.database, query));
    }
    
    public messagesDetailed(session: Session, query: ApiPartialQuery<ChatMessageDetailed>): Observable<Array<ChatMessageDetailed>> {
        const items = this.database.messages.asList(((this.database.users.objects.get(session.userId) ?? null)?.permissionCanViewDeleted ?? null) === true).map((message: ChatMessage): ChatMessageDetailed => {
            const user: (User | null) = ((): (User | null) => {
                const temp1059 = message.authorId;
                if(temp1059 === null) { return null }
                return ((it: number): (User | null) => (this.database.users.objects.get(it) ?? null))(temp1059)
            })();
            
            const thread: (ChatThread | null) = (this.database.threads.objects.get(message.thread) ?? null);
            
            const alert: (Alert | null) = ((): (Alert | null) => {
                const temp1064 = thread;
                if(temp1064 === null) { return null }
                return ((it: ChatThread): (Alert | null) => (this.database.alerts.objects.get(it.id) ?? null))(temp1064)
            })();
            
            const alertType: (AlertType | null) = (this.database.alertTypes.objects.get((alert?.name ?? null) ?? getNO_ID()) ?? null);
            
            const school: (School | null) = (this.database.schools.objects.get((alertType?.school ?? null) ?? getNO_ID()) ?? null);
            
            return new ChatMessageDetailed(message.id, message.deleted, message.thread, message.authorId, message.created, message.content, message.timestamp, message.deletedReason, message.attachmentsDeep, (user?.firstName ?? null), (user?.lastName ?? null), (alertType?.name ?? null), (alertType?.icon ?? null), (school?.name ?? null), false);
        });
        
        
        return rxOf(filterQuery<ChatMessageDetailed>(items, this.database, query));
    }
    
    public threadsDetailed(session: Session, query: ApiPartialQuery<ChatThreadDetailed>): Observable<Array<ChatThreadDetailed>> {
        const items = this.database.threads.asList(((this.database.users.objects.get(session.userId) ?? null)?.permissionCanViewDeleted ?? null) === true).map((thread: ChatThread): ChatThreadDetailed => {
            const alert: (Alert | null) = (this.database.alerts.objects.get(thread.alert ?? getNO_ID()) ?? null);
            
            const alertType: (AlertType | null) = (this.database.alertTypes.objects.get((alert?.name ?? null) ?? getNO_ID()) ?? null);
            
            return new ChatThreadDetailed(thread.id, thread.deleted, thread.name, thread.created, thread.userIds, thread.schools, thread.associatedSchools, thread.alert, thread.lastUpdated, thread.userNames, thread.schoolNames, (alertType?.icon ?? null));
        });
        
        
        return rxOf(filterQuery<ChatThreadDetailed>(items, this.database, query));
    }
    
    public submitContactEmail(email: string): Observable<void> {
        return rxOf(undefined);
    }
    public adminApproval(session: Session, schoolId: string): Observable<Array<AdminApprovalListResponse>> {
        return rxOf([]);
    }
    
    public submitAdminApproval(session: Session, status: string, alertId: number): Observable<void> {
        return rxOf(undefined);
    }
    
    public getRegisteredDevice(session: Session, userId: number): Observable<RegisteredDevice> {
        return rxOf(new RegisteredDevice(undefined, undefined, undefined, undefined, undefined, undefined));
    }
    public checkAlertLocation(session: Session): Observable<CheckAlertLocationResponse> {
        return rxOf(new CheckAlertLocationResponse(undefined, undefined));
    }
    
    public getPermissions(session: Session, userId: ForeignKey<User>): Observable<PermissionsResponse> {
        return rxOf(new PermissionsResponse(undefined, undefined));
    }
    
    public startGroupAlert(session: Session, request: Alert): Observable<Alert> {
        return rxOf(new Alert(getNO_ID(), getNO_ID(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    }
}
