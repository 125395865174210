//
// ComponentRowGuidanceXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_guidance.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowGuidanceXml
export class ComponentRowGuidanceXml {
    xmlRoot!: HTMLElement
    header!: HTMLDivElement
    title!: HTMLElement
    dropdown!: HTMLImageElement
    contents!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.header = getViewById<HTMLDivElement>(view, "header")
        this.title = getViewById<HTMLElement>(view, "title")
        this.dropdown = getViewById<HTMLImageElement>(view, "dropdown")
        this.contents = getViewById<HTMLElement>(view, "contents")
        return view
    }
}
