//
// ComponentRowSchoolNameDrillXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_school_name_drill.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowSchoolNameDrillXml
export class ComponentRowSchoolNameDrillXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    drillIcon!: HTMLImageElement
    schoolName!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.drillIcon = getViewById<HTMLImageElement>(view, "drill_icon")
        this.schoolName = getViewById<HTMLElement>(view, "school_name")
        return view
    }
}
