//
// GoogleMapEditXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './google_map_edit.html'
//! Declares com.tresitgroup.android.tresit.layouts.GoogleMapEditXml
export class GoogleMapEditXml {
    xmlRoot!: HTMLElement
    exportSvgWorking!: HTMLDivElement
    exportSvg!: HTMLButtonElement
    importSvgWorking!: HTMLDivElement
    importSvg!: HTMLButtonElement
    saveWorking!: HTMLDivElement
    save!: HTMLButtonElement
    editMapStackView!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.exportSvgWorking = getViewById<HTMLDivElement>(view, "exportSvgWorking")
        this.exportSvg = getViewById<HTMLButtonElement>(view, "exportSvg")
        this.importSvgWorking = getViewById<HTMLDivElement>(view, "importSvgWorking")
        this.importSvg = getViewById<HTMLButtonElement>(view, "importSvg")
        this.saveWorking = getViewById<HTMLDivElement>(view, "saveWorking")
        this.save = getViewById<HTMLButtonElement>(view, "save")
        this.editMapStackView = getViewById<HTMLDivElement>(view, "editMapStackView")
        return view
    }
}
