//
// RootXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/root.html'
import htmlForDefault from './root.html'
//! Declares com.tresitgroup.android.tresit.layouts.RootXml
export class RootXml {
    xmlRoot!: HTMLElement
    content!: HTMLDivElement
    dialogFade!: HTMLElement
    dialog!: HTMLDivElement
    loadHtmlString(): string {
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [700])
        this.xmlRoot = view
        this.content = getViewById<HTMLDivElement>(view, "content")
        this.dialogFade = getViewById<HTMLElement>(view, "dialogFade")
        this.dialog = getViewById<HTMLDivElement>(view, "dialog")
        return view
    }
}
