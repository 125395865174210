// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertHistoryIdResponse.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertHistoryIdResponse
export class AlertHistoryIdResponse {
    public readonly alert_id: string;
    public constructor(alert_id: string) {
        this.alert_id = alert_id;
    }
    public static fromJson(obj: any): AlertHistoryIdResponse { return new AlertHistoryIdResponse(
        parseJsonTyped(obj["alert_id"], [String]) as string
    ) }
    public toJSON(): object { return {
        alert_id: this.alert_id
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.alert_id);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertHistoryIdResponse && safeEq(this.alert_id, other.alert_id) }
    public toString(): string { return `AlertHistoryIdResponse(alert_id=${this.alert_id})` }
    public copy(alert_id: string = this.alert_id): AlertHistoryIdResponse { return new AlertHistoryIdResponse(alert_id); }
}