//
// MessagesXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/messages.html'
import htmlForDefault from './messages.html'
//! Declares com.tresitgroup.android.tresit.layouts.MessagesXml
export class MessagesXml {
    xmlRoot!: HTMLElement
    messagesLoader!: HTMLDivElement
    alertsHeader!: HTMLDivElement
    alerts!: HTMLDivElement
    groupsHeader!: HTMLDivElement
    groups!: HTMLDivElement
    groupsMore!: HTMLButtonElement
    contactsHeader!: HTMLDivElement
    contacts!: HTMLDivElement
    contactsMore!: HTMLButtonElement
    chatSubview: HTMLDivElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [700])
        this.xmlRoot = view
        this.messagesLoader = getViewById<HTMLDivElement>(view, "messagesLoader")
        this.alertsHeader = getViewById<HTMLDivElement>(view, "alertsHeader")
        this.alerts = getViewById<HTMLDivElement>(view, "alerts")
        this.groupsHeader = getViewById<HTMLDivElement>(view, "groupsHeader")
        this.groups = getViewById<HTMLDivElement>(view, "groups")
        this.groupsMore = getViewById<HTMLButtonElement>(view, "groupsMore")
        this.contactsHeader = getViewById<HTMLDivElement>(view, "contactsHeader")
        this.contacts = getViewById<HTMLDivElement>(view, "contacts")
        this.contactsMore = getViewById<HTMLButtonElement>(view, "contactsMore")
        this.chatSubview = findViewById<HTMLDivElement>(view, "chatSubview")
        return view
    }
}
