// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/ConversationOptionsVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { ComponentRowPersonXml } from '../../layout/ComponentRowPersonXml'
import { MessagesVG } from './MessagesVG'
import { ConversationOptionsXml } from '../../layout/ConversationOptionsXml'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xIterableMinus } from 'butterfly-web/dist/kotlin/Collections'
import { MessageTarget } from '../../model/MessageTarget'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { SharingObservableProperty, xObservablePropertyShare } from 'butterfly-web/dist/observables/SharingObservableProperty'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { DialogRequest, showDialog } from 'butterfly-web/dist/views/showDialog'
import { listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { flatMap as rxFlatMap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ConversationCreateVG } from './ConversationCreateVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableInput } from 'rxjs'
import { CacheNode } from '../../api/CacheNode'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.chat.ConversationOptionsVG
export class ConversationOptionsVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly threadId: ForeignKey<ChatThread>;
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, threadId: ForeignKey<ChatThread>, schoolFilter: ObservableProperty<(School | null)>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.threadId = threadId;
        this.schoolFilter = schoolFilter;
        this.stack = stack;
        this.leavingGroup = new StandardObservableProperty<boolean>(false, undefined);
        this.thread = this.session.threads.observable(this.threadId);
    }
    
    
    
    public readonly leavingGroup: StandardObservableProperty<boolean>;
    
    public readonly thread: CacheNode<ChatThread>;
    
    public participants(): SharingObservableProperty<(Array<(MessageTarget | null)> | null)> {
        return xObservablePropertyShare<(Array<(MessageTarget | null)> | null)>(xObservablePropertyFlatMapNotNull<ChatThread, Array<(MessageTarget | null)>>(this.thread, (it: ChatThread): ObservableProperty<(Array<(MessageTarget | null)> | null)> => xObservablePropertyMap<Array<MessageTarget>, (Array<(MessageTarget | null)> | null)>(xObservablePropertyCombine<Array<(User | null)>, Array<(School | null)>, Array<MessageTarget>>(xListCombined<(User | null)>(it.userIds.map((it: number): CacheNode<User> => this.session.users.observable(it))), xListCombined<(School | null)>(it.schools.map((it: number): CacheNode<School> => this.session.schools.observable(it))), (users: Array<(User | null)>, schools: Array<(School | null)>): Array<MessageTarget> => listFilterNotNull(users).map((user: User): MessageTarget => new MessageTarget(user.name, undefined, user)).concat(listFilterNotNull(schools).map((school: School): MessageTarget => new MessageTarget(school.name, school, undefined)))), (it: Array<MessageTarget>): (Array<(MessageTarget | null)> | null) => it as (Array<(MessageTarget | null)> | null))), undefined);
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.ConversationOptionsVG.title
    public get title(): string { return "Conversation Options"; }
    
    
    //--- Generate Properties
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ConversationOptionsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const participants = this.participants();
        
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<(ChatThread | null), string>(this.thread, (it: (ChatThread | null)): string => (it?.name ?? null) ?? "-"));
        
        //--- Set Up xml.close (overwritten on flow generation)
        xViewOnClick(xml.close, undefined, (): void => {
            this.closeClick();
        });
        
        //--- Set Up xml.participantsLoading
        xViewFlipperBindLoading(xml.participantsLoading, xObservablePropertyMap<(Array<(MessageTarget | null)> | null), boolean>(participants, (it: (Array<(MessageTarget | null)> | null)): boolean => it === null), undefined);
        
        //--- Set Up xml.participants
        xRecyclerViewBind<(MessageTarget | null)>(xml.participants, xObservablePropertyMap<(Array<(MessageTarget | null)> | null), Array<(MessageTarget | null)>>(participants, (it: (Array<(MessageTarget | null)> | null)): Array<(MessageTarget | null)> => it ?? []), null, (observable: ObservableProperty<(MessageTarget | null)>): HTMLElement => {
            //--- Make Subview For xml.participants (overwritten on flow generation)
            const cellXml = new ComponentRowPersonXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root (overwritten on flow generation)
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<(MessageTarget | null), string>(observable, (it: (MessageTarget | null)): string => (it?.display ?? null) ?? "-"));
            //--- End Make Subview For xml.participants (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.edit
        xViewOnClick(xml.edit, undefined, (): void => {
            this.editClick();
        });
        xViewBindExists(xml.edit, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionEditConversation ?? null) === true));
        
        //--- Set Up xml.leavingGroup
        xViewFlipperBindLoading(xml.leavingGroup, this.leavingGroup, undefined);
        xViewBindExists(xml.leavingGroup, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionEditConversation ?? null) === true));
        
        //delete
        xViewBindExists(xml._delete, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionDeleteThread ?? null) === true));
        xViewOnClick(xml._delete, undefined, (): void => {
            this.deleteThread();
        });
        
        //--- Set Up xml.leaveGroup (overwritten on flow generation)
        xViewOnClick(xml.leaveGroup, undefined, (): void => {
            this.leaveGroupClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeClick(): void {
        this.dialog.dismiss();
    }
    
    public editClick(): void {
        this.dialog.push(new ConversationCreateVG((it: ChatThread): void => {}, this.session, this.dialog, this.threadId, (this.schoolFilter.value?.id ?? null)));
    }
    
    public leaveGroupClick(): void {
        xSingleCallDisplayingError<ChatThread>(xSingleWorking<ChatThread>(this.session.threads.get(this.threadId).pipe(rxFlatMap((it: ChatThread): ObservableInput<ChatThread> => this.session.threads.patch(it.id, [ChatThread.Companion.INSTANCE.setUsers(xIterableMinus<number>(it.userIds, this.session.session.userId))]))), this.leavingGroup), undefined, (it: ChatThread): void => {
            this.dialog.dismiss();
        });
    }
    
    public deleteThread(): void {
        showDialog(new DialogRequest(new ViewStringResource(R._string.are_you_sure_delete_group), (): void => {
            xSingleCallDisplayingError<void>(xSingleWorking<void>(this.session.threads.get(this.threadId).pipe(rxFlatMap((it: ChatThread): ObservableInput<void> => this.session.threads._delete(it.id))), this.leavingGroup), undefined, (it: void): void => {
                //                    session.forceRefresh()
                this.stack.reset(new MessagesVG(this.stack, this.schoolFilter, this.session, this.stack));
                this.dialog.dismiss();
            });
        }));
        
    }
    
    //--- Body End
}
