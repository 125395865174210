// Generated by Butterfly TypeScript converter
// File: svg/XmlParser.actual.kt
// Package: com.tresitgroup.android.tresit.svg
import {map as iterMap, toArray as iterToArray} from 'iterable-operator'
import {listFilterNotNull} from 'butterfly-web/dist/KotlinCollections'
import {XmlNode} from './SchoolSvgRender'
import {NumberRange, tryCastInterface} from 'butterfly-web/dist/Kotlin'

//! Declares com.tresitgroup.android.tresit.svg.parseXml
export function parseXml(_string: string): XmlNode {
    let parser = new DOMParser();
    let result = parser.parseFromString(_string, "text/xml").getRootNode() as Element;

    function convert(node: Element): XmlNode {
        let attributes = new Map<string, string>();
        let children = new Array<XmlNode>();
        if (node.attributes) {
            for (let i = 0; i < node.attributes.length; i++) {
                const it = node.attributes.item(i) as Attr;
                attributes.set(it.name, it.value);
            }
        }
        let raw = ""
        if (node.childNodes) {
            for (let i = 0; i < node.childNodes.length; i++) {
                const it = node.childNodes.item(i) as ChildNode;
                if (it instanceof Element) {
                    children.push(convert(it));
                } else if(it instanceof Text) {
                    raw += it.data
                }
            }
        }
        return new XmlNode(
            node.nodeName,
            attributes,
            children,
            node.textContent
        )
    }

    return convert(result.firstElementChild ?? result);
}