//
// RowSchoolSafeUnsafeXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './row_school_safe_unsafe.html'
//! Declares com.tresitgroup.android.tresit.layouts.RowSchoolSafeUnsafeXml
export class RowSchoolSafeUnsafeXml {
    xmlRoot!: HTMLElement
    schoolName!: HTMLElement
    safeUnsafe!: HTMLElement
    cbSafeUnsafe!: HTMLInputElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.schoolName = getViewById<HTMLElement>(view, "schoolName")
        this.safeUnsafe = getViewById<HTMLElement>(view, "safeUnsafe")
        this.cbSafeUnsafe = getViewById<HTMLInputElement>(view, "cbSafeUnsafe")
        return view
    }
}
