//
// EditUserXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './edit_user.html'
//! Declares com.tresitgroup.android.tresit.layouts.EditUserXml
export class EditUserXml {
    xmlRoot!: HTMLElement
    editFirstName!: HTMLInputElement
    editLastName!: HTMLInputElement
    editEmail!: HTMLElement
    editCellNumber!: HTMLInputElement
    updatingUser!: HTMLDivElement
    updateUser!: HTMLButtonElement
    newPassword!: HTMLInputElement
    newPasswordConfirm!: HTMLInputElement
    updatingPassword!: HTMLDivElement
    submitNewPassword!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.editFirstName = getViewById<HTMLInputElement>(view, "edit_first_name")
        this.editLastName = getViewById<HTMLInputElement>(view, "edit_last_name")
        this.editEmail = getViewById<HTMLElement>(view, "edit_email")
        this.editCellNumber = getViewById<HTMLInputElement>(view, "edit_cell_number")
        this.updatingUser = getViewById<HTMLDivElement>(view, "updating_user")
        this.updateUser = getViewById<HTMLButtonElement>(view, "update_user")
        this.newPassword = getViewById<HTMLInputElement>(view, "new_password")
        this.newPasswordConfirm = getViewById<HTMLInputElement>(view, "new_password_confirm")
        this.updatingPassword = getViewById<HTMLDivElement>(view, "updating_password")
        this.submitNewPassword = getViewById<HTMLButtonElement>(view, "submit_new_password")
        return view
    }
}
