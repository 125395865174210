// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/MapsVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { SessionApi } from '../../api/SessionApi'
import { MapsXml } from '../../layout/MapsXml'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { AlertsVG } from '../alert/AlertsVG'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { LocationDetailVG } from './LocationDetailVG'
import { MapVG } from './MapVG'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.map.MapsVG
export class MapsVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.mapSchoolStack = new ObservableStack<ViewGenerator>();
    }
    
    
    public readonly mapSchoolStack: ObservableStack<ViewGenerator>;
    
    
    public onBackPressed(): boolean {
        return this.mapSchoolStack.backPressPop();
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.map.MapsVG.title
    public get title(): string { return "Maps"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new MapsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.content
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(School | null)>(this.schoolFilter, undefined, undefined, (it: (School | null)): void => {
            this.mapSchoolStack.reset(it !== null ? new MapVG(this.dialog, this.dialog, (device: number): void => {},  (room: number): void => {
                this.dialog.push(new LocationDetailVG(this.dialog, room, it!.id, this.session));
            }, it!.id, this.session) : new AlertsVG(this.dialog, this.schoolFilter, this.session, this.mapSchoolStack));
        }), xViewRemovedGet(view));
        xSwapViewBindStack<ViewGenerator>(xml.content, dependency, this.mapSchoolStack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
