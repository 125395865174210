// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Building.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Building
export class Building implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly school: ForeignKey<School>;
    public readonly name: string;
    public constructor(id: number = getNO_ID(), school: ForeignKey<School>, name: string) {
        this.id = id;
        this.school = school;
        this.name = name;
    }
    public static fromJson(obj: any): Building { return new Building(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string
    ) }
    public toJSON(): object { return {
        id: this.id,
        school: this.school,
        name: this.name
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.name);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Building && safeEq(this.id, other.id) && safeEq(this.school, other.school) && safeEq(this.name, other.name) }
    public toString(): string { return `Building(id=${this.id}, school=${this.school}, name=${this.name})` }
    public copy(id: number = this.id, school: ForeignKey<School> = this.school, name: string = this.name): Building { return new Building(id, school, name); }
    
    
}
export namespace Building {
    //! Declares com.tresitgroup.android.tresit.model.Building.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public belongingToSchool(school: ForeignKey<School>): ApiFilter<Building> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Building>(`school=${school}`, undefined, (it: Building): boolean => it.school === school);
        }
    }
}