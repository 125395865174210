// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/SessionVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { PinEntryVG } from './PinEntryVG'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { Image, ImageResource } from 'butterfly-web/dist/Image'
import { ApproveAdminData } from '../model/ApproveAdminData'
import { xSessionApiLogOut } from '../model/Session.api'
import { debounceTime as rxDebounceTime, distinct as rxDistinct, distinctUntilChanged as rxDistinctUntilChanged, filter as rxFilter, take as rxTake } from 'rxjs/operators'
import { xViewBindExists, xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { SettingsVG } from './settings/SettingsVG'
import { AlertClassificationEnum, xAlertClassificationEnumBackground2ColorGet, xAlertClassificationEnumBackgroundColorGet } from '../model/AlertClassificationEnum'
import { ApiOption } from '../api/ApiOption'
import { SessionApi } from '../api/SessionApi'
import { Notifications } from 'butterfly-fcm-web/dist/Notifications'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { TypedSocketMessage } from '../model/TypedSocketMessage'
import { OnAlertStartedVG } from './alert/OnAlertStartedVG'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xObservablePropertyObservableGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { cleanAlertNotifications } from '../launchTestNotification'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { AdminApprovalListResponse } from '../model/AdminApprovalListResponse'
import { xViewBindCollapseHorizontal } from '../util/bindCollapse'
import { AlertType } from '../model/AlertType'
import { xActivityAccessHasPhoneLock } from './PinAuth'
import { xObservablePropertyDistinctUntilChanged } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { Room } from '../model/Room'
import { AlertSound } from '../util/AlertSound'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { PinCreateVG } from './PinCreateVG'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { CombinedStatus, switchStatus, switchStatusText } from '../model/CombinedStatus'
import { FirebaseMessagingToken } from '../model/FirebaseMessagingToken'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { xThrowableGetDetailedMessage } from '../util/Throwable.extentions'
import { post } from 'butterfly-web/dist/delay'
import { numberToColor } from 'butterfly-web/dist/views/Colors'
import { PinVG } from './PinVG'
import { Time } from '../util/time'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xActivityAccessRequestFocus } from '../openSsoWindow'
import { School } from '../model/School'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ChatThread } from '../model/ChatThread'
import { printStackTrace, runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { PinDeviceVG } from './PinDeviceVG'
import { MapsVG } from './map/MapsVG'
import { checkIsInterface, safeEq } from 'butterfly-web/dist/Kotlin'
import { setViewBackgroundColor, setViewVisibility, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { User } from '../model/User'
import { R } from '../R'
import { AlertHistory } from '../model/AlertHistory'
import { BuildConfig } from '../BuildConfig'
import { xSessionApiUnreadSchoolFilter } from '../api/Session.read'
import { StatusEnum } from '../model/StatusEnum'
import { AlertHistoryVG } from './admin/AlertHistoryVG'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { Alert } from '../model/Alert'
import { SocketMessage } from '../model/SocketMessage'
import { CheckInVG } from './admin/CheckInVG'
import { iterFirstOrNullFind, iterLastOrNull } from 'butterfly-web/dist/KotlinCollections'
import { xStringFromJsonString } from 'butterfly-web/dist/Codable'
import { SessionXml } from '../layout/SessionXml'
import { ApiPartialQuery } from '../api/ApiPartialQuery'
import { ApplicationAccess } from 'butterfly-web/dist/ApplicationAccess'
import { OnStatusButtonPressedVG } from './alert/OnStatusButtonPressedVG'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { AlertCancelReportVG } from './alert/AlertCancelReportVG'
import { Session } from '../model/Session'
import { BoardVG } from './board/BoardVG'
import { CacheEdge } from '../api/CacheEdge'
import { AdminApproveDialogVG } from './admin/AdminApproveDialogVG'
import { xSingleCallDisplayingError } from '../util/apicalls'
import { xObservableMapNotNull } from 'butterfly-web/dist/rx/RxExtensions'
import { MessagesVG } from './chat/MessagesVG'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { Platform } from 'butterfly-web/dist/Platform'
import { SubscriptionLike } from 'rxjs'
import { logVG } from '../util/LogVG'
import { AlertVG } from './alert/AlertVG'
import { SelectSchoolVG } from './SelectSchoolVG'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { ConversationDetailVG } from './chat/ConversationDetailVG'
import { xVerticalRecyclerViewBindSchoolSelect } from './schoolsList'

//! Declares com.tresitgroup.android.tresit.vg.SessionVG
export class SessionVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly topStack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>, session: SessionApi, topStack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.session = session;
        this.topStack = topStack;
        this.alertListTest = [];
        this.firstView = null;
        this.sessionStack = new ObservableStack<ViewGenerator>();
        this.schoolFilter = new StandardObservableProperty<(School | null)>(null, undefined);
        this.changingStatus = new StandardObservableProperty<boolean>(false, undefined);
        this.lastClosedAt = 0;
        this.shouldDismissDialog = false;
        this.opened = Time.INSTANCE.date();
        this.lastAlarmSoundHandled = 0;
        this.isCollapsed = new StandardObservableProperty<boolean>(false, undefined);
        this.filter = new StandardObservableProperty<string>("", undefined);
        this.connectivityExists = xObservablePropertyMap<boolean, boolean>(this.session.socketConnected, (it: boolean): boolean => {
            console.log(`SessionVG saw that the socket is ${it}`);
            return (!it);
        });
        this.connectionExists = xObservablePropertyMap<boolean, boolean>(this.session.socketConnected, (it: boolean): boolean => {
            console.log(`SessionVG saw that the socket is ${it}`);
            return it;
        });
        this.allAlerts = this.session.alerts.observableListSimple(undefined, Alert.Companion.INSTANCE.createdSort, undefined, undefined, undefined, undefined);
        this.hasMultipleSchools = xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => it !== null && it!.length > 1);
        this.s = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<School>>(this.session.schools.getListSimple(undefined, undefined, undefined, undefined, undefined), undefined, (schoolList: Array<School>): void => {
            if (schoolList.length === 1) {
                this.schoolFilter.value = schoolList[0];
            }
        }));
        this.sessionStack.reset(new AlertVG(this.dialog, this.schoolFilter, this.session));
        if (this.session.rawApi === ApiOption.Companion.INSTANCE.choice.value.api) {
            //        if (session.rawApi !is MockApi && session.rawApi !is DelayedApi) {
                Preferences.INSTANCE.set<Session>([Session], Session.Companion.INSTANCE.key, this.session.session);
            };
        }
        
        public alertListTest: Array<Alert>;
        
        
        public firstView: (Alert | null);
        
        
        public readonly sessionStack: ObservableStack<ViewGenerator>;
        
        
        public readonly schoolFilter: StandardObservableProperty<(School | null)>;
        
        
        public readonly changingStatus: StandardObservableProperty<boolean>;
        
        public lastClosedAt: number;
        
        public shouldDismissDialog: boolean;
        
        public readonly opened: Date;
        
        public lastAlarmSoundHandled: number;
        
        public readonly isCollapsed: StandardObservableProperty<boolean>;
        
        public readonly filter: StandardObservableProperty<string>;
        
        public readonly connectivityExists: ObservableProperty<boolean>;
        
        public readonly connectionExists: ObservableProperty<boolean>;
        
        public readonly allAlerts: CacheEdge<Alert>;
        
        public readonly hasMultipleSchools: ObservableProperty<boolean>;
        
        public readonly s: (Session | null);
        
        
        private verifyToken(): void {
            xObservablePropertyObservableGet(this.connectivityExists).pipe(rxDistinctUntilChanged()).subscribe((it: boolean): void => {
                if (it) {
                    this.session.rawApi.verifyToken(this.session.session, this.session.session.token).subscribe((it: void): void => {}, (it: any): void => {
                        xThrowableGetDetailedMessage(it).subscribe((it: string): void => {
                            if (it === `Error(s):\n` + "non_field_errors: Signature has expired.") {
                                xSessionApiLogOut(this.session);
                            }
                        }, (it: any): void => {});
                    });
                }
            }, undefined, undefined);
            
        }
        
        
        public userRoom(): ObservableProperty<(Room | null)> {
            return xObservablePropertyFlatMap<(number | null), (Room | null)>(xObservablePropertyMap<(User | null), (number | null)>(this.session.me, (it: (User | null)): (number | null) => (it?.currentLocation ?? null)), (it: (number | null)): ObservableProperty<(Room | null)> => ((): ObservableProperty<(Room | null)> => {
                if (it === null) { return new ConstantObservableProperty<(Room | null)>(null) } else { return this.session.rooms.observable(it!) }
            })());
        }
        
        
        
        
        
        
        //! Declares com.tresitgroup.android.tresit.vg.SessionVG.title
        public get title(): string { return "Session"; }
        
        
        public generate(dependency: Window): HTMLElement {
            const xml = new SessionXml();
            
            const view = xml.setup(dependency);
            
            
            //--- Log
            logVG(this, this.session);
            
            this.verifyToken();
            //--- Properties
            const alertClassification = xObservablePropertyMap<(Array<Alert> | null), AlertClassificationEnum>(this.allAlerts, (alerts: (Array<Alert> | null)): AlertClassificationEnum => {
                console.log("alertClassification");
                return ((): AlertClassificationEnum => {
                    if (alerts !== null && alerts!.some((it: Alert): boolean => (!it.drill))) {
                        return AlertClassificationEnum.Alert
                    } else if (alerts !== null && alerts!.length !== 0) {
                        return AlertClassificationEnum.Drill
                    } else  {
                        return AlertClassificationEnum.None
                    }
                })()
            });
            
            const userRoom = this.userRoom();
            
            const combinedStatus = xObservablePropertyMap<(Room | null), (CombinedStatus | null)>(userRoom, (it: (Room | null)): (CombinedStatus | null) => (it?.combinedStatus ?? null));
            
            const statusWorkingExists = xObservablePropertyCombine<boolean, (Array<Alert> | null), boolean>(xObservablePropertyCombine<(Room | null), (User | null), boolean>(userRoom, this.session.me, (room: (Room | null), user: (User | null)): boolean => room !== null && (user?.localUser ?? null) === true), this.allAlerts, (otherLogic: boolean, alerts: (Array<Alert> | null)): boolean => otherLogic && ((): (boolean | null) => {
                const temp3139 = alerts;
                if(temp3139 === null) { return null }
                return temp3139.length !== 0
            })() === true);
            
            
            const isWeb = safeEq(Platform.Companion.INSTANCE.current, Platform.Web);
            
            
            
            // Roll out the pin!
            if ((!isWeb)) {
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(xObservablePropertyDistinctUntilChanged<boolean>(ApplicationAccess.INSTANCE.foreground), undefined, undefined, (it: boolean): void => {
                    post((): void => {
                        if (it && Time.INSTANCE.epochMilliseconds() - this.lastClosedAt > SessionVG.Companion.INSTANCE.PIN_TIMEOUT && !(checkIsInterface<PinVG>(iterLastOrNull(this.dialog.stack), "ComTresitgroupAndroidTresitVgPinVG"))) {
                            const existingPin = Preferences.INSTANCE.get<string>([String], PinCreateVG.Companion.INSTANCE.prefKey);
                            
                            if (xActivityAccessHasPhoneLock(dependency)) {
                                this.dialog.push(new PinDeviceVG(this.dialog, this.root, this.session));
                            } else {
                                if (existingPin === null) {
                                    this.dialog.push(new PinCreateVG(this.dialog, this.session));
                                } else {
                                    this.dialog.push(new PinEntryVG(this.dialog, this.root, this.session));
                                }
                            }
                        }
                        if (it) {
                            this.lastClosedAt = 2147483647;
                        } else {
                            this.lastClosedAt = Time.INSTANCE.epochMilliseconds();
                        }
                    });
                }), xViewRemovedGet(view));
            }
            
            //--- Pop up alert (alerts)
            xDisposableUntil<SubscriptionLike>(xObservablePropertyObservableGet(xObservablePropertyCombine<boolean, (Array<Alert> | null), (Array<Alert> | null)>(xObservablePropertyCombine<Array<ViewGenerator>, (User | null), boolean>(this.dialog, this.session.me, (dialog: Array<ViewGenerator>, user: (User | null)): boolean => dialog.length === 0 && user !== null && user!.currentLocation === null), this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (shouldShowAlert: boolean, alertsList: (Array<Alert> | null)): (Array<Alert> | null) => {
                            if (alertsList !== null) {
                                this.alertListTest = alertsList!;
                            }
                            if (shouldShowAlert) {
                                return ((): (Array<Alert> | null) => {
                                    const temp3148 = alertsList;
                                    if(temp3148 === null) { return null }
                                    return temp3148.filter((it: Alert): boolean => (!it.pendingApproval))
                                })();
                            } else {
                                return ([] as Array<Alert>);
                            }
                })).pipe(rxDebounceTime(100)).subscribe((alerts: (Array<Alert> | null)): void => {
                    console.info(`${"SessionVG"}: ${`Alerts update: ${alerts}`}`);
                    if ((this.firstView?.id ?? null) === (iterLastOrNull(this.alertListTest)?.id ?? null)) {
                        console.log("return from alertListTest");
                        
                        return;
                    }
                    const arraySize = this.alertListTest.length;
                    
                    this.firstView = ((): (Alert | null) => {
                        if (arraySize < 2) {
                            return ((): (Alert | null) => {
                                const temp3158 = alerts;
                                if(temp3158 === null) { return null }
                                return (temp3158[0] ?? null)
                            })();
                        } else {
                            return ((): (Alert | null) => {
                                if ((this.firstView?.id ?? null) === (iterLastOrNull(this.alertListTest)?.id ?? null)) {
                                    return null;
                                } else {
                                    return iterLastOrNull(this.alertListTest);
                                }
                            })()
                        }
                    })();
                    if (this.firstView === null) {
                        console.log("return from first view");
                        
                        return;
                    }
                    //val firstView = alerts.value?.firstOrNull() ?: return@subscribeBy
                    xSingleCallDisplayingError<School>(this.session.schools.get(this.firstView!!.school), undefined, (it: School): void => {
                        this.schoolFilter.value = it;
                    });
                    
                    let shouldAvoidRegionalAdmin = false;
                    
                    let shouldAvoidResponder = false;
                    
                    const hasAlertLocation = (this.session.me.value?.hasAlertLocation ?? null);
                    
                    const temp3164 = (this.session.me.value?.groups ?? null);
                    if(temp3164 !== null) {
                        for(const _x of temp3164) {
                            const group = _x;
                            if (group === 6) {
                                shouldAvoidRegionalAdmin = true;
                            }
                            if (group === 8) {
                                shouldAvoidResponder = true;
                            }
                        }
                    };
                    if ((this.session.me.value?.id ?? null) !== null) {
                        const s: (Session | null) = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
                        
                        if (s !== null) {
                            xSingleCallDisplayingError<User>(this.session.rawApi.users(s!).get(this.session.me.value!!.id), undefined, (it: User): void => {
                                if ((!shouldAvoidRegionalAdmin) && (!shouldAvoidResponder) && it.hasAlertLocation === false) {
                                    if ((this.schoolFilter.value?.show_safe_unsafe ?? null) === false) {
                                        if (!((this.session.me.value?.id ?? null) === (this.firstView?.initiator ?? null))) {
                                            this.dialog.push(new OnAlertStartedVG(this.firstView!!.id, this.dialog, this.root, this.session, this.sessionStack));
                                        }
                                    } else {
                                        this.dialog.push(new OnAlertStartedVG(this.firstView!!.id, this.dialog, this.root, this.session, this.sessionStack));
                                    }
                                }
                            });
                        }
                    }
                    
                    
                    xSingleCallDisplayingError<Array<ChatThread>>(this.session.threads.getListSimple(ChatThread.Companion.INSTANCE.forAlert(this.firstView!!.id), undefined, undefined, undefined, undefined), undefined, (it: Array<ChatThread>): void => {
                        const thread = (it[0] ?? null);
                        
                        console.log(`callDisplayingError:${thread}`);
                        if (thread !== null) {
                            const messagesVg = new MessagesVG(this.dialog, this.schoolFilter, this.session, this.sessionStack);
                            
                            if ((!isWeb)) { messagesVg.firstStartup = false }
                            this.sessionStack.reset(messagesVg);
                            if ((!isWeb)) {
                                this.sessionStack.push(new ConversationDetailVG(this.dialog, this.session, thread!.id, thread!.name, this.schoolFilter, this.sessionStack));
                            }
                        }
                    });
            }, undefined, undefined), xViewRemovedGet(view));
            
            if (xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanAcceptAlert ?? null) === true).value) {
                this.session.socketObservable.pipe(rxFilter((it: SocketMessage): boolean => it.type === "RequiresAdminApprovalAlert")).subscribe((it: SocketMessage): void => {
                    const adminAlertJson = xStringFromJsonString<ApproveAdminData>(it.data!!, [ApproveAdminData]);
                    
                    const approveAdminResponse = ((): (AdminApprovalListResponse | null) => {
                        const temp3179 = ((): (number | null) => {
                            const temp3181 = (adminAlertJson?.id ?? null);
                            if(temp3181 === null) { return null }
                            return temp3181
                        })();
                        if(temp3179 === null) { return null }
                        return ((it1: number): AdminApprovalListResponse => new AdminApprovalListResponse(it1, adminAlertJson!.schoolId, adminAlertJson!.alert_type ?? "", adminAlertJson!.school_name ?? "", `${adminAlertJson!.initiator_name}`, `${adminAlertJson!.description}`, adminAlertJson!.initiator, adminAlertJson!.cellNumber))(temp3179)
                    })();
                    
                    
                    if ((adminAlertJson?.pending_approval_status ?? null) === "pending") {
                        //show admin approve dialog
                        if ((!this.shouldDismissDialog)) {
                            this.shouldDismissDialog = true;
                        } else {
                            this.sessionStack.reset(new AlertVG(this.dialog, this.schoolFilter, this.session));
                        }
                        console.log(`this is from SesionVg-----> ${adminAlertJson!.cellNumber}`);
                        
                        const it2_3186 = ((): (AdminApproveDialogVG | null) => {
                            const temp3187 = approveAdminResponse;
                            if(temp3187 === null) { return null }
                            return ((it1: AdminApprovalListResponse): AdminApproveDialogVG => new AdminApproveDialogVG(it1, this.session, this.dialog, this.schoolFilter, this.sessionStack, this.sessionStack))(temp3187)
                        })();
                        if (it2_3186 !== null) {
                            this.sessionStack.reset(it2_3186);
                        }
                    }
                    
                    if ((adminAlertJson?.pending_approval_status ?? null) === "rejected") {
                        if (this.session.session.userId === ((): (number | null) => {
                            const temp3193 = adminAlertJson!.initiator;
                            if(temp3193 === null) { return null }
                            return temp3193
                        })()) {
                            showDialogAlert(new ViewStringResource(R._string.request_declined));
                        } else {
                            this.sessionStack.reset(new AlertVG(this.dialog, this.schoolFilter, this.session));
                        }
                    }
                    if ((adminAlertJson?.pending_approval_status ?? null) === "declined") {
                        showDialogAlert(new ViewStringResource(R._string.request_declined));
                    }
                }, undefined, undefined);
            }
            
            
            //--- Pop up cancellation report
            xDisposableUntil<SubscriptionLike>(xObservableMapNotNull<TypedSocketMessage<AlertHistory>, AlertHistory>(xObservableMapNotNull<SocketMessage, TypedSocketMessage<AlertHistory>>(this.session.socketObservable, (it: SocketMessage): (TypedSocketMessage<AlertHistory> | null) => it.typed<AlertHistory>([AlertHistory], "AlertHistory")), (it: TypedSocketMessage<AlertHistory>): (AlertHistory | null) => it.data).pipe(rxFilter((it: AlertHistory): boolean => ((): (boolean | null) => {
                                const temp3203 = it.cancelled;
                                if(temp3203 === null) { return null }
                                return (temp3203 > this.opened)
                })() === true)).pipe(rxDistinct((it: AlertHistory): number => it.id)).subscribe((it: AlertHistory): void => {
                    const report = new AlertCancelReportVG(this.dialog, it, this.session);
                    
                    if (iterLastOrNull(this.dialog.value) instanceof AlertCancelReportVG) {
                        this.dialog.swap(report);
                    } else {
                        this.alertTabClick();
                        this.dialog.push(report);
                    }
            }, undefined, undefined), xViewRemovedGet(view));
            
            //--- FCM
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(string | null)>(Notifications.INSTANCE.notificationToken, undefined, undefined, (it: (string | null)): void => {
                if (it !== null) {
                    console.info(`${"SessionVG"}: ${`Uploading FCM token ${it}...`}`);
                    this.session.rawApi.postFirebaseToken(this.session.session, new FirebaseMessagingToken(undefined, it!, Platform.Companion.INSTANCE.current.name, BuildConfig.VERSION_NAME, undefined, undefined, undefined, undefined, undefined)).subscribe((it: void): void => {
                            console.info(`${"SessionVG"}: ${"Uploading FCM: Upload success!"}`);
                        }, (it: any): void => {
                            console.error(`${"SessionVG"}: ${`Uploading FCM: Failed to upload token: ${it}`}`);
                    });
                }
            }), xViewRemovedGet(view));
            Notifications.INSTANCE.request(new ViewStringResource(R._string.insist_notifications), undefined);
            xDisposableUntil<SubscriptionLike>(this.allAlerts.rx.pipe(rxTake(1)).subscribe((it: Array<Alert>): void => {
                cleanAlertNotifications(dependency, it.map((it: Alert): number => it.id));
            }, (it: any): void => {}, undefined), xViewRemovedGet(view));
            
            //--- Set Up xml.logo
            const temp3221 = xml.logo;
            if(temp3221 !== null) {
                temp3221.onclick = (_ev) => { _ev.stopPropagation();
                    const it = _ev.target as HTMLElement;
                    this.session.forceRefresh();
                }
            };
            
            //--- Set Up xml.topBar
            //--- Set Up xml.bottomBar
            if (xml.landscapeIndicator === null) {
                xViewBindExists(xml.bottomBar, xObservablePropertyMap<boolean, boolean>(ApplicationAccess.INSTANCE.softInputActive, (it: boolean): boolean => (!it)));
            }
            const bars = ([xml.topBar, xml.bottomBar] as Array<(HTMLDivElement | null)>);
            
            const activeAlerts = this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined);
            
            for (const bar of bars) {
                const safeBar_3222 = bar;
                if (safeBar_3222 !== null) {
                    xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<AlertClassificationEnum>(alertClassification, undefined, undefined, (it: AlertClassificationEnum): void => {
                        setViewBackgroundColor(safeBar_3222, xAlertClassificationEnumBackgroundColorGet(it));
                    }), xViewRemovedGet(safeBar_3222));
                }
            }
            
            const dividers = ([xml.horizontalDivider, xml.verticalDivider, xml.verticalDivider2] as Array<(HTMLElement | null)>);
            
            for (const divider of dividers) {
                const safeDivider_3224 = divider;
                if (safeDivider_3224 !== null) {
                    xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<AlertClassificationEnum>(alertClassification, undefined, undefined, (it: AlertClassificationEnum): void => {
                        setViewBackgroundColor(safeDivider_3224, xAlertClassificationEnumBackground2ColorGet(it));
                    }), xViewRemovedGet(safeDivider_3224));
                }
            }
            
            //--- Set Up xml.back
            const hasExternalSchoolFilter = xml.filterBySchoolLayout === null;
            
            xViewBindVisible(xml.back, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.sessionStack, (it: Array<ViewGenerator>): boolean => it.length > 1), xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<(School | null), boolean>(this.schoolFilter, (it: (School | null)): boolean => it !== null), this.hasMultipleSchools, (a: boolean, b: boolean): boolean => hasExternalSchoolFilter && a && b), (a: boolean, b: boolean): boolean => a || b));
            xViewOnClick(xml.back, undefined, (): void => {
                this.onBackPressed();
            });
            
            //--- Set Up xml.header
            xTextViewBindString(xml.header, xObservablePropertyMap<(School | null), string>(this.schoolFilter, (it: (School | null)): string => (it?.name ?? null) ?? R._string.all_sites));
            
            //--- Set Up xml.canChangeSchoolFilter
            const temp3228 = xml.canChangeSchoolFilter;
            if(temp3228 !== null) {
                xViewBindVisible(temp3228, this.hasMultipleSchools)
            };
            
            //--- Set Up xml.subheader
            xTextViewBindString(xml.subheader, xObservablePropertyMap<Array<ViewGenerator>, string>(this.sessionStack, (it: Array<ViewGenerator>): string => ((iterLastOrNull(it)?.titleString ?? null)?.get(dependency) ?? null) ?? "DIR-S"));
            
            //--- Set Up xml.viewTitle
            xml.viewTitle.onclick = (_ev) => { _ev.stopPropagation();
                const it = _ev.target as HTMLElement;
                if (this.hasMultipleSchools.value) {
                    this.viewTitleClick();
                }
            };
            
            //--- Set Up xml.schoolSelectorSpace
            const temp3230 = xml.schoolSelectorSpace;
            if(temp3230 !== null) {
                xViewBindCollapseHorizontal(temp3230, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<boolean, boolean>(this.isCollapsed, (it: boolean): boolean => (!it)), this.hasMultipleSchools, (a: boolean, b: boolean): boolean => a && b))
            };
            
            //--- Set Up xml.filterBySchoolLayout
            const temp3231 = xml.filterBySchoolLayout;
            if(temp3231 !== null) {
                xViewBindCollapseHorizontal(temp3231, xObservablePropertyMap<boolean, boolean>(this.isCollapsed, (it: boolean): boolean => (!it)))
            };
            
            //--- Set Up xml.filter
            const temp3232 = xml.filter;
            if(temp3232 !== null) {
                xEditTextBindString(temp3232, this.filter)
            };
            
            //--- Set Up xml.collapseLayout
            const layout_3233 = xml.collapseLayout;
            if (layout_3233 !== null) {
                xViewBindExists(layout_3233, this.hasMultipleSchools);
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<AlertClassificationEnum>(alertClassification, undefined, undefined, (it: AlertClassificationEnum): void => {
                    setViewBackgroundColor(layout_3233, xAlertClassificationEnumBackgroundColorGet(it));
                }), xViewRemovedGet(layout_3233));
            }
            
            //--- Set Up xml.collapseLabel
            const temp3235 = xml.collapseLabel;
            if(temp3235 !== null) {
                xViewBindExists(temp3235, this.isCollapsed)
            };
            
            //--- Set Up xml.collapseButton
            const temp3236 = xml.collapseButton;
            if(temp3236 !== null) {
                xImageViewBindImage(temp3236, xObservablePropertyMap<boolean, (Image | null)>(this.isCollapsed, (it: boolean): (Image | null) => ((): ImageResource => {
                    if (it) {
                        return new ImageResource(R.drawable.ic_chevron_right);
                    } else {
                        return new ImageResource(R.drawable.ic_chevron_left);
                    }
                })()))
            };
            const temp3237 = xml.collapseButton;
            if(temp3237 !== null) {
                xViewOnClick(temp3237, undefined, (): void => {
                    this.collapseButtonClick();
                })
            };
            
            //--- Set Up xml.schools
            const temp3238 = xml.schools;
            if(temp3238 !== null) {
                xVerticalRecyclerViewBindSchoolSelect(temp3238, dependency, this.session, this.filter, this.schoolFilter, alertClassification, (it: (School | null)): void => {
                    this.schoolFilter.value = it;
                })
            };
            
            
            //--- Set Up xml.statusWorking
            xViewFlipperBindLoading(xml.statusWorking, this.changingStatus, undefined);
            
            xViewBindExists(xml.statusWorking, statusWorkingExists);
            
            //--- Set Up xml.statusLayout
            xViewOnClick(xml.statusLayout, undefined, (): void => {
                if (((): (boolean | null) => {
                    const temp3240 = this.allAlerts.value;
                    if(temp3240 === null) { return null }
                    return temp3240.length === 0
                })() === true) {
                    xSingleCallDisplayingError<User>(this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.removeLocation]), undefined, (it: User): void => {});
                } else {
                    this.statusButtonClick();
                }
            });
            
            //--- Set Up xml.statusButton
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(CombinedStatus | null)>(combinedStatus, undefined, undefined, (it: (CombinedStatus | null)): void => {
                imageViewSetImageResource(xml.statusButton, switchStatus(it));
            }), xViewRemovedGet(xml.statusButton));
            
            //--- Set Up xml.statusText
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(CombinedStatus | null)>(combinedStatus, undefined, undefined, (it: (CombinedStatus | null)): void => {
                setViewText(xml.statusText, switchStatusText(it));
            }), xViewRemovedGet(xml.statusText));
            
            //--- Set Up xml.connectivityIndicator
            xViewBindExists(xml.connectivityIndicator, this.connectivityExists);
            
            const temp3245 = xml.connectedIndicator;
            if(temp3245 !== null) {
                xViewBindExists(temp3245, this.connectionExists)
            };
            
            
            //--- Set Up xml.backButtonMirror
            xViewBindExists(xml.backButtonMirror, xObservablePropertyCombine<boolean, boolean, boolean>(statusWorkingExists, this.connectivityExists, (status: boolean, socket: boolean): boolean => (!(status || socket)) && (!isWeb)));
            
            //--- Set Up xml.statusTextSafe
            const safeText_3247 = xml.statusTextSafe;
            if (safeText_3247 !== null) {
                xViewBindVisible(safeText_3247, xObservablePropertyMap<(CombinedStatus | null), boolean>(combinedStatus, (it: (CombinedStatus | null)): boolean => safeEq((it?.status ?? null), StatusEnum.Safe)));
                xViewBindVisible(xml.statusText, xObservablePropertyMap<(CombinedStatus | null), boolean>(combinedStatus, (it: (CombinedStatus | null)): boolean => !safeEq((it?.status ?? null), StatusEnum.Safe)));
            }
            
            
            //--- Set Up xml.unreadIndicator
            xViewBindVisible(xml.unreadIndicator, xSessionApiUnreadSchoolFilter(this.session, this.schoolFilter));
            
            //--- Set Up xml.mute
            //--- Set Up xml.muteLabel
            //--- Set Up xml.muteImage
            
            if (isWeb) {
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Alert> | null)>(this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), undefined, undefined, (alerts: (Array<Alert> | null)): void => {
                    if (alerts !== null) {
                        if (alerts!.length !== 0) {
                            xActivityAccessRequestFocus(dependency);
                            const playFor = iterFirstOrNullFind(alerts!, (it: Alert): boolean => it.created.getTime() > this.lastAlarmSoundHandled);
                            
                            if (playFor !== null) {
                                this.lastAlarmSoundHandled = playFor!.created.getTime();
                                const alertTypeId = playFor!.name;
                                
                                if (alertTypeId !== null) {
                                    if (playFor!.internal && playFor!.silent && (this.session.me.value?.permissionIgnoreDrill ?? null) === true) {
                                        return;
                                    }
                                    xDisposableForever<SubscriptionLike>(this.session.alertTypes.get(alertTypeId!).subscribe((it: AlertType): void => {
                                            const alertType = this.session.rawApi.alertTypes(this.session.session)
                                                .getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<AlertType>(AlertType.Companion.INSTANCE.forSchool(it.school), undefined, undefined, undefined));
                                            
                                            alertType.subscribe((listItem: Array<AlertType>): void => {
                                                const specificAlertType = iterFirstOrNullFind(listItem, (singleItem: AlertType): boolean => singleItem.id === it.id);
                                                
                                                
                                                if ((specificAlertType?.subscribed ?? null) === true) {
                                                    if ((!playFor!.silent)) {
                                                        AlertSound.INSTANCE.audioUrl.value = it.mp3File ?? it.oggFile;
                                                        AlertSound.INSTANCE.playing.value = true;
                                                    } else {
                                                        AlertSound.INSTANCE.playing.value = false;
                                                        AlertSound.INSTANCE.audioUrl.value = null;
                                                    }
                                                }
                                            }, undefined);
                                        }, (it: any): void => {
                                            printStackTrace(it);
                                            AlertSound.INSTANCE.audioUrl.value = null;
                                            AlertSound.INSTANCE.playing.value = true;
                                    }));
                                } else {
                                    AlertSound.INSTANCE.audioUrl.value = null;
                                    AlertSound.INSTANCE.playing.value = true;
                                }
                            }
                        }
                        if (alerts!.length === 0) {
                            AlertSound.INSTANCE.playing.value = false;
                        }
                    }
                }), xViewRemovedGet(view));
                xViewOnClick(xml.mute, undefined, (): void => {
                    AlertSound.INSTANCE.playing.value = (!AlertSound.INSTANCE.playing.value);
                });
                xViewBindVisible(xml.mute, xObservablePropertyMap<(Array<Alert> | null), boolean>(this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
                    const temp3265 = it;
                    if(temp3265 === null) { return null }
                    return temp3265.length === 0
                })() === false));
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(AlertSound.INSTANCE.playing, undefined, undefined, (it: boolean): void => {
                    setViewText(xml.muteLabel, (it ? R._string.on : R._string.off));
                }), xViewRemovedGet(xml.muteLabel));
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(AlertSound.INSTANCE.playing, undefined, undefined, (it: boolean): void => {
                    imageViewSetImageResource(xml.muteImage, it ? R.drawable.ic_sound_on : R.drawable.ic_sound_off);
                }), xViewRemovedGet(xml.muteImage));
            } else {
                setViewVisibility(xml.mute, "gone");
            }
            
            //--- Set Up xml.history
            //--- Set Up xml.historyImage
            //--- Set Up xml.historyLabel
            const temp3272 = xml.history;
            if(temp3272 !== null) {
                xViewBindExists(temp3272, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionAdmin ?? null) === true))
            };
            const temp3274 = xml.history;
            if(temp3274 !== null) {
                xViewOnClick(temp3274, undefined, (): void => {
                    this.sessionStack.push(new AlertHistoryVG(this.schoolFilter, this.session, this.sessionStack, this.dialog));
                })
            };
            
            //--- Set Up xml.home
            //--- Set Up xml.homeImage
            //--- Set Up xml.homeLabel
            const temp3275 = xml.home;
            if(temp3275 !== null) {
                xViewBindExists(temp3275, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionAdmin ?? null) === true && (it?.localUser ?? null) === false))
            };
            const temp3278 = xml.home;
            if(temp3278 !== null) {
                xViewOnClick(temp3278, undefined, (): void => {
                    this.schoolFilter.value = null;
                    this.alertTabClick();
                })
            };
            
            xObservablePropertyObservableGet(this.session.me).subscribe((it: (User | null)): void => {
                if ((it?.allowSiteCheckIn ?? null) === true) {
                    if (((): (boolean | null) => {
                        const temp3283 = this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined).value;
                        if(temp3283 === null) { return null }
                        return temp3283.length === 0
                    })() === true) {
                        this.dialog.push(new CheckInVG(this.session, this.root, this.dialog));
                    }
                }
            }, undefined, undefined);
            
            //--- Set Up xml.content (overwritten on flow generation)
            xSwapViewBindStack<ViewGenerator>(xml.content, dependency, this.sessionStack);
            
            const selectedIsAlpha = (view: HTMLInputElement, selected: ObservableProperty<boolean>): void => {
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(selected, undefined, undefined, (isSelected: boolean): void => {
                    view.checked = isSelected;
                }), xViewRemovedGet(view));
                
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<[boolean, AlertClassificationEnum]>(xObservablePropertyCombine<boolean, AlertClassificationEnum, [boolean, AlertClassificationEnum]>(selected, alertClassification, (a: boolean, b: AlertClassificationEnum): [boolean, AlertClassificationEnum] => [a, b]), undefined, undefined, (pair: [boolean, AlertClassificationEnum]): void => {
                    const isSelected = pair[0];
                    
                    const classification = pair[1];
                    
                    if (isSelected) {
                        setViewBackgroundColor(view, xAlertClassificationEnumBackground2ColorGet(classification));
                    } else {
                        setViewBackgroundColor(view, numberToColor(0));
                    }
                }), xViewRemovedGet(view));
                
            }
            
            //--- Set Up xml.alertTab
            xViewBindExists(xml.alertTab, xObservablePropertyCombine<boolean, (School | null), boolean>(xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => ((): (boolean | null) => {
                const temp3287 = it;
                if(temp3287 === null) { return null }
                return temp3287.some((it: School): boolean => it.alertsEnabled)
            })() === true), this.schoolFilter, (a: boolean, school: (School | null)): boolean => (school?.alertsEnabled ?? null) ?? a));
            selectedIsAlpha(xml.alertTab, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.sessionStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof AlertVG));
            xViewOnClick(xml.alertTab, undefined, (): void => {
                this.alertTabClick();
            });
            
            //--- Set Up xml.boardTab
            xViewBindExists(xml.boardTab, xObservablePropertyCombine<boolean, (School | null), boolean>(xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => ((): (boolean | null) => {
                const temp3290 = it;
                if(temp3290 === null) { return null }
                return temp3290.some((it: School): boolean => it.boardsEnabled)
            })() === true), this.schoolFilter, (a: boolean, school: (School | null)): boolean => (school?.boardsEnabled ?? null) ?? a));
            selectedIsAlpha(xml.boardTab, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.sessionStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof BoardVG));
            xViewOnClick(xml.boardTab, undefined, (): void => {
                this.boardTabClick();
            });
            
            //--- Set Up xml.messagesTab
            xViewBindExists(xml.messagesTab, xObservablePropertyCombine<boolean, (Array<Alert> | null), boolean>(xObservablePropertyCombine<boolean, (School | null), boolean>(xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => ((): (boolean | null) => {
                                const temp3293 = it;
                                if(temp3293 === null) { return null }
                                return temp3293.some((it: School): boolean => it.chatEnabled)
                })() === true), this.schoolFilter, (a: boolean, school: (School | null)): boolean => (school?.chatEnabled ?? null) ?? a), activeAlerts, (a: boolean, b: (Array<Alert> | null)): boolean => a || ((): (boolean | null) => {
                    const temp3296 = b;
                    if(temp3296 === null) { return null }
                    return temp3296.length !== 0
            })() === true));
            selectedIsAlpha(xml.messagesTab, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.sessionStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof MessagesVG));
            xViewOnClick(xml.messagesTab, undefined, (): void => {
                this.messagesTabClick();
            });
            
            //--- Set Up xml.mapTab
            xViewBindExists(xml.mapTab, xObservablePropertyCombine<boolean, (Array<Alert> | null), boolean>(xObservablePropertyCombine<boolean, (School | null), boolean>(xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => ((): (boolean | null) => {
                                const temp3298 = it;
                                if(temp3298 === null) { return null }
                                return temp3298.some((it: School): boolean => it.mapsEnabled)
                })() === true), this.schoolFilter, (a: boolean, school: (School | null)): boolean => (school?.mapsEnabled ?? null) ?? a), activeAlerts, (a: boolean, b: (Array<Alert> | null)): boolean => a || ((): (boolean | null) => {
                    const temp3301 = b;
                    if(temp3301 === null) { return null }
                    return temp3301.length !== 0
            })() === true));
            selectedIsAlpha(xml.mapTab, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.sessionStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof MapsVG));
            xViewOnClick(xml.mapTab, undefined, (): void => {
                this.mapTabClick();
            });
            
            //--- Set Up xml.settingsTab
            selectedIsAlpha(xml.settingsTab, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.sessionStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof SettingsVG));
            xViewOnClick(xml.settingsTab, undefined, (): void => {
                this.settingsTabClick();
            });
            
            //--- Generate End (overwritten on flow generation)
            
            return view;
        }
        
        //--- Init
        
        
        
        //--- Actions
        
        public statusButtonClick(): void {
            this.dialog.push(new OnStatusButtonPressedVG(this.dialog, this.session, (this.firstView?.id ?? null)));
        }
        
        public onBackPressed(): boolean {
            if (this.sessionStack.backPressPop()) { return true }
            const hasMultipleSchools = xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => it === null ? false : it!.length > 1);
            
            if (this.schoolFilter.value !== null && hasMultipleSchools.value) {
                this.schoolFilter.value = null;
                if ((this.sessionStack.stack[0] ?? null) instanceof MapsVG) {
                    this.mapTabClick();
                } else {
                    this.alertTabClick();
                }
                return true;
            }
            return false;
        }
        
        public viewTitleClick(): void {
            this.dialog.push(new SelectSchoolVG((it: (School | null)): void => {
                this.schoolFilter.value = it;
            }, this.session, this.dialog));
        }
        
        public alertTabClick(): void {
            this.sessionStack.reset(new AlertVG(this.dialog, this.schoolFilter, this.session));
        }
        
        public boardTabClick(): void {
            this.sessionStack.reset(new BoardVG(this.dialog, this.schoolFilter, this.session));
        }
        
        public messagesTabClick(): void {
            this.sessionStack.reset(new MessagesVG(this.dialog, this.schoolFilter, this.session, this.sessionStack));
        }
        
        public mapTabClick(): void {
            this.sessionStack.reset(new MapsVG(this.dialog, this.schoolFilter, this.session));
        }
        
        public settingsTabClick(): void {
            this.sessionStack.reset(new SettingsVG(this.dialog, this.root, this.schoolFilter, this.session, this.sessionStack, this.sessionStack, this.topStack));
        }
        
        public schoolsClick(school: (School | null)): void {
            this.schoolFilter.value = school;
        }
        
        public collapseButtonClick(): void {
            this.isCollapsed.value = (!this.isCollapsed.value);
        }
        
        //--- Body End
    }
    export namespace SessionVG {
        //! Declares com.tresitgroup.android.tresit.vg.SessionVG.Companion
        export class Companion {
            private constructor() {
                this.PIN_TIMEOUT = 60000 * 5;
            }
            public static INSTANCE = new Companion();
            
            public readonly PIN_TIMEOUT: number;
            
        }
    }
    
    
    /*session.rawApi.checkAlertLocation(session.session).subscribeBy(
        onError = {
            showDialog(ViewStringResource(R.string.something_went_wrong))
        },
        onSuccess = {
            
        }
    )*/
    /* session.users.get(session.me.value?.id!!).callDisplayingError {
        println("callDisplayingError:${it?.hasAlertLocation}")
        
    }.forever()*/
    /*    session.users.get(session.me.value?.id!!).callDisplayingError {
        println("callDisplayingError:${it.hasAlertLocation}")
        
        if (!shouldAvoidRegionalAdmin && !shouldAvoidResponder && it.hasAlertLocation==false) {
            if (schoolFilter.value?.show_safe_unsafe == false && session.me.value?.id == firstView?.initiator){
                
            }else{
                dialog.push(
                    OnAlertStartedVG(
                        alertId = firstView!!.id,
                        dialog = dialog,
                        session = session,
                        root = root,
                        sessionStack = sessionStack
                    )
                )
            }
        }
    }.forever()*/
    
    