// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/SessionApi.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { User } from '../model/User'
import { ApiFilter } from './ApiFilter'
import { CacheNode } from './CacheNode'
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { Device } from '../model/Device'
import { AlertHistory } from '../model/AlertHistory'
import { Refreshable } from './Refreshable'
import { delay as rxDelay, publish as rxPublish, refCount as rxRefCount, share as rxShare, switchMap as rxSwitchMap, tap as rxTap } from 'rxjs/operators'
import { SocketInfo } from './SocketInfo'
import { Group } from '../model/Group'
import { Observable, ObservableInput, Subject, SubscriptionLike, concat as rxConcat, NEVER as rxNEVER, of as rxOf } from 'rxjs'
import { xSessionApiLogOut } from '../model/Session.api'
import { also } from 'butterfly-web/dist/Kotlin'
import { UserSchoolMembership } from '../model/UserSchoolMembership'
import { SocketMessage } from '../model/SocketMessage'
import { Alert } from '../model/Alert'
import { UnreadThread } from '../model/UnreadThread'
import { doOnSubscribe as rxDoOnSubscribe, rxRetryWhenAlt } from 'butterfly-web/dist/rx/RxActual'
import { LoginActivity } from '../model/LoginActivity'
import { TypedSocketMessage } from '../model/TypedSocketMessage'
import { xObservablePropertyObservableNNGet } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { District } from '../model/District'
import { ApplicationAccess } from 'butterfly-web/dist/ApplicationAccess'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { Floor } from '../model/Floor'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ForeignKey } from '../model/ForeignKey'
import { AlertType } from '../model/AlertType'
import { BoardPost } from '../model/BoardPost'
import { AlertEnded } from '../model/AlertEnded'
import { Room } from '../model/Room'
import { Session } from '../model/Session'
import { AlertGuidance } from '../model/AlertGuidance'
import { ChatMessage } from '../model/ChatMessage'
import { Building } from '../model/Building'
import { FirebaseMessagingToken } from '../model/FirebaseMessagingToken'
import { Api } from './Api'
import { ApiTable } from './ApiTable'
import { xThrowableGetDetailedMessage } from '../util/Throwable.extentions'
import { xObservableMapNotNull } from 'butterfly-web/dist/rx/RxExtensions'
import { Time } from '../util/time'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { SoftDeletable } from '../model/SoftDeletable'
import { School } from '../model/School'
import { ChatThread } from '../model/ChatThread'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { CachedApiTable } from './CachedApiTable'

//! Declares com.tresitgroup.android.tresit.api.SessionApi
export class SessionApi implements SocketInfo {
    public static implementsInterfaceComTresitgroupAndroidTresitApiSocketInfo = true;
    public readonly session: Session;
    public readonly rawApi: Api;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(session: Session, rawApi: Api, root: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>) {
        this.session = session;
        this.rawApi = rawApi;
        this.root = root;
        this.dialog = dialog;
        this.socketOpenAt = 0;
        this.socketClosedAt = 1;
        this.expiration = 10000;
        this.refreshables = ([] as Array<Refreshable>);
        this.socketShouldBeOpen = new Subject();
        this.foregroundListener = xObservablePropertyObservableNNGet(ApplicationAccess.INSTANCE.foreground).subscribe((it: boolean): void => {
            this.socketShouldBeOpen.next(it);
        }, undefined, undefined);
        this.shouldShowChatIcon = new StandardObservableProperty<boolean>(false, undefined);
        this.socketConnected = new StandardObservableProperty<boolean>(false, undefined);
        this.socketObservable = rxConcat(rxOf(true), this.socketShouldBeOpen).pipe(rxSwitchMap((it: boolean): ObservableInput<SocketMessage> => {
            console.debug(`${"SessionApi"}: ${`Socket for user ${this.session.userId} should be open? ${it}`}`);
            return ((): Observable<SocketMessage> => {
                if (it) {
                    return this.rawApi.getSocket(this.session, this).pipe(rxDoOnSubscribe((it: SubscriptionLike): void => {
                        console.debug(`${"SessionApi"}: ${`Attempting to open socket for ${this.session.userId}`}`);
                    })).pipe(rxSwitchMap((it: Observable<SocketMessage>): ObservableInput<SocketMessage> => {
                        console.debug(`${"SessionApi"}: ${`Socket for user ${this.session.userId} connected`}`);
                        this.refresh();
                        this.socketOpenAt = Time.INSTANCE.epochMilliseconds();
                        this.socketConnected.value = true;
                        return ((): Observable<SocketMessage> => {
                            const temp1293 = (): void => {
                                console.debug(`${"SessionApi"}: ${`Socket for user ${this.session.userId} disconnected`}`);
                                this.socketClosedAt = Time.INSTANCE.epochMilliseconds();
                                this.socketConnected.value = false;
                            };
                            return it.pipe(rxTap(undefined, temp1293, temp1293))
                        })().pipe(rxTap((it: SocketMessage): void => {
                            console.debug(`${"SessionApi"}: ${`Socket for user ${this.session.userId} message: ${it}`}`);
                        }));
                    })).pipe(rxRetryWhenAlt( (e: Observable<any>): ObservableInput<any> => {
                        const sched = HttpClient.INSTANCE.responseScheduler;
                        
                        const mid = e.pipe(rxTap((it: any): void => {
                            this.socketClosedAt = Time.INSTANCE.epochMilliseconds();
                            this.socketConnected.value = false;
                            console.debug(`${"SessionApi"}: ${`Socket for user ${this.session.userId} had error ${it}, will reattempt in one expiration period...`}`);
                        }));
                        
                        return sched !== null ? mid.pipe(rxDelay(this.expiration)) : mid
                    })).pipe(rxShare());
                } else {
                    console.debug(`${"SessionApi"}: ${`Socket for user ${this.session.userId} deliberately disconnected`}`);
                    this.socketClosedAt = Time.INSTANCE.epochMilliseconds();
                    this.socketConnected.value = false;
                    return rxNEVER;
                }
            })()
        })).pipe(rxPublish()).pipe(rxRefCount());
        this.lastRefreshAt = 0;
        this.refreshCount = 0;
        this.schools = also(new CachedApiTable<School>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<School>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<School> | null) => it.typed<School>([School], "School")), (): ApiTable<School> => this.rawApi.schools(this.session), this.expiration, undefined, undefined, ApiFilter.Companion.INSTANCE.allList<School>([new ApiFilter<School>("check_in=true"), new ApiFilter<School>("check_in_permission=true")])), (it: CachedApiTable<School>): void => {
            this.refreshables.push(it);
        });
        this.allSchools = also(new CachedApiTable<School>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<School>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<School> | null) => it.typed<School>([School], "School")), (): ApiTable<School> => this.rawApi.schools(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<School>): void => {
            this.refreshables.push(it);
        });
        this.districts = also(new CachedApiTable<District>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<District>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<District> | null) => it.typed<District>([District], "District")), (): ApiTable<District> => this.rawApi.districts(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<District>): void => {
            this.refreshables.push(it);
        });
        this.posts = also(new CachedApiTable<BoardPost>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<BoardPost>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<BoardPost> | null) => it.typed<BoardPost>([BoardPost], "Post")), (): ApiTable<BoardPost> => this.rawApi.posts(this.session), this.expiration, (it: BoardPost): BoardPost => it.copy(undefined, new Date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined, SoftDeletable.Companion.INSTANCE.isActive<BoardPost>()), (it: CachedApiTable<BoardPost>): void => {
            this.refreshables.push(it);
        });
        this.alerts = also(new CachedApiTable<Alert>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<Alert>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<Alert> | null) => it.typed<Alert>([Alert], "Alert")), (): ApiTable<Alert> => this.rawApi.alerts(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<Alert>): void => {
            this.refreshables.push(it);
        });
        this.alertTypes = also(new CachedApiTable<AlertType>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<AlertType>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<AlertType> | null) => it.typed<AlertType>([AlertType], "AlertType")), (): ApiTable<AlertType> => this.rawApi.alertTypes(this.session), this.expiration, (it: AlertType): AlertType => it.copy(undefined, new Date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined, SoftDeletable.Companion.INSTANCE.isActive<AlertType>()), (it: CachedApiTable<AlertType>): void => {
            this.refreshables.push(it);
        });
        this.alertGuidances = also(new CachedApiTable<AlertGuidance>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<AlertGuidance>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<AlertGuidance> | null) => it.typed<AlertGuidance>([AlertGuidance], "AlertGuidance")), (): ApiTable<AlertGuidance> => this.rawApi.alertGuidances(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<AlertGuidance>): void => {
            this.refreshables.push(it);
        });
        this.alertHistory = also(new CachedApiTable<AlertHistory>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<AlertHistory>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<AlertHistory> | null) => it.typed<AlertHistory>([AlertHistory], "AlertHistory")), (): ApiTable<AlertHistory> => this.rawApi.alertHistory(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<AlertHistory>): void => {
            this.refreshables.push(it);
        });
        this.threads = also(new CachedApiTable<ChatThread>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<ChatThread>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<ChatThread> | null) => it.typed<ChatThread>([ChatThread], "Thread")), (): ApiTable<ChatThread> => this.rawApi.threads(this.session), this.expiration, (it: ChatThread): ChatThread => it.copy(undefined, new Date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined, SoftDeletable.Companion.INSTANCE.isActive<ChatThread>()), (it: CachedApiTable<ChatThread>): void => {
            this.refreshables.push(it);
        });
        this.messages = also(new CachedApiTable<ChatMessage>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<ChatMessage>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<ChatMessage> | null) => it.typed<ChatMessage>([ChatMessage], "Message")), (): ApiTable<ChatMessage> => this.rawApi.messages(this.session), this.expiration, (it: ChatMessage): ChatMessage => it.copy(undefined, new Date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (it: TypedSocketMessage<ChatMessage>): void => {
                    const it_1333 = it.data;
                    if (it_1333 !== null) {
                        const cn = this.threads.cached(it_1333.thread);
                        
                        const c = this.threads.cached(it_1333.thread).value;
                        
                        if (c !== null) {
                            const old_1334 = c!.lastUpdated;
                            if (old_1334 !== null) {
                                if ((it_1333.created > old_1334)) {
                                    this.threads.received(c!.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, it_1333.created, undefined, undefined, undefined, undefined), cn.lastUpdated);
                                    if (!(it_1333.authorId === this.session.userId)) {
                                        this.shouldShowChatIcon.value = true;
                                    }
                                }
                            } else {
                                this.threads.received(c!.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, it_1333.created, undefined, undefined, undefined, undefined), cn.lastUpdated);
                            }
                        } else {
                            this.threads.observable(it_1333.thread);
                        }
                    }
            }, SoftDeletable.Companion.INSTANCE.isActive<ChatMessage>()), (it: CachedApiTable<ChatMessage>): void => {
                this.refreshables.push(it);
        });
        this.buildings = also(new CachedApiTable<Building>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<Building>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<Building> | null) => it.typed<Building>([Building], "Building")), (): ApiTable<Building> => this.rawApi.buildings(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<Building>): void => {
            this.refreshables.push(it);
        });
        this.floors = also(new CachedApiTable<Floor>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<Floor>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<Floor> | null) => it.typed<Floor>([Floor], "Floor")), (): ApiTable<Floor> => this.rawApi.floors(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<Floor>): void => {
            this.refreshables.push(it);
        });
        this.rooms = new CachedApiTable<Room>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<Room>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<Room> | null) => it.typed<Room>([Room], "Room")), (): ApiTable<Room> => this.rawApi.rooms(this.session), this.expiration, undefined, undefined, undefined);
        this.users = also(new CachedApiTable<User>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<User>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<User> | null) => it.typed<User>([User], "User")), (): ApiTable<User> => this.rawApi.users(this.session), this.expiration, (it: User): User => it.copy(undefined, new Date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined, SoftDeletable.Companion.INSTANCE.isActive<User>()), (it: CachedApiTable<User>): void => {
            this.refreshables.push(it);
        });
        this.userSchoolMembership = also(new CachedApiTable<UserSchoolMembership>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<UserSchoolMembership>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<UserSchoolMembership> | null) => it.typed<UserSchoolMembership>([UserSchoolMembership], "UserSchoolMembership")), (): ApiTable<UserSchoolMembership> => this.rawApi.userSchoolMemberships(this.session), this.expiration, (it: UserSchoolMembership): UserSchoolMembership => it.copy(undefined, new Date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined, SoftDeletable.Companion.INSTANCE.isActive<UserSchoolMembership>()), (it: CachedApiTable<UserSchoolMembership>): void => {
            this.refreshables.push(it);
        });
        this.permissionGroups = also(new CachedApiTable<Group>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<Group>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<Group> | null) => it.typed<Group>([Group], "Group")), (): ApiTable<Group> => this.rawApi.permissionGroups(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<Group>): void => {
            this.refreshables.push(it);
        });
        this.loginActivity = also(new CachedApiTable<LoginActivity>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<LoginActivity>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<LoginActivity> | null) => it.typed<LoginActivity>([LoginActivity], "LoginActivity")), (): ApiTable<LoginActivity> => this.rawApi.loginActivity(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<LoginActivity>): void => {
            this.refreshables.push(it);
        });
        this.firebaseMessagingToken = also(new CachedApiTable<FirebaseMessagingToken>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<FirebaseMessagingToken>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<FirebaseMessagingToken> | null) => it.typed<FirebaseMessagingToken>([FirebaseMessagingToken], "FirebaseMessagingToken")), (): ApiTable<FirebaseMessagingToken> => this.rawApi.firebaseMessagingToken(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<FirebaseMessagingToken>): void => {
            this.refreshables.push(it);
        });
        this.devices = also(new CachedApiTable<Device>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<Device>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<Device> | null) => it.typed<Device>([Device], "Device")), (): ApiTable<Device> => this.rawApi.devices(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<Device>): void => {
            this.refreshables.push(it);
        });
        this.unreadThreads = also(new CachedApiTable<UnreadThread>(this, xObservableMapNotNull<SocketMessage, TypedSocketMessage<UnreadThread>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<UnreadThread> | null) => it.typed<UnreadThread>([UnreadThread], "UnreadThread")), (): ApiTable<UnreadThread> => this.rawApi.unreadThreads(this.session), this.expiration, undefined, undefined, undefined), (it: CachedApiTable<UnreadThread>): void => {
            this.refreshables.push(it);
        });
        this.alertCancelListener = xObservableMapNotNull<SocketMessage, TypedSocketMessage<AlertEnded>>(this.socketObservable, (it: SocketMessage): (TypedSocketMessage<AlertEnded> | null) => it.typed<AlertEnded>([AlertEnded], "AlertEnded")).subscribe((it: TypedSocketMessage<AlertEnded>): void => {
            const it_1369 = it.data;
            if (it_1369 !== null) {
                for (const node of this.rooms.items.values()) {
                    const v_1371 = node.value;
                    if (v_1371 !== null) {
                        if (v_1371.school === it_1369.schoolId) {
                            node.update(v_1371.copy(undefined, undefined, undefined, undefined, undefined, null, undefined, undefined));
                        }
                    }
                }
                for (const userId of it_1369.usersCleared) {
                    const node_1374 = (this.users.items.get(userId) ?? null);
                    if (node_1374 !== null) {
                        const v_1376 = node_1374.value;
                        if (v_1376 !== null) {
                            node_1374.update(v_1376.copy(undefined, undefined, undefined, undefined, undefined, undefined, null, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                        }
                    }
                }
            }
        }, (it: any): void => {}, undefined);
    }
    
    public socketOpenAt: number;
    
    public socketClosedAt: number;
    
    public readonly expiration: number;
    
    public readonly refreshables: Array<Refreshable>;
    
    
    public readonly socketShouldBeOpen: Subject<boolean>;
    
    public readonly foregroundListener: SubscriptionLike;
    
    
    public readonly shouldShowChatIcon: StandardObservableProperty<boolean>;
    
    public readonly socketConnected: StandardObservableProperty<boolean>;
    
    public readonly socketObservable: Observable<SocketMessage>;
    
    
    
    private verifyToken(): void {
        this.rawApi.verifyToken(this.session, this.session.token).subscribe((it: void): void => {}, (it: any): void => {
            xThrowableGetDetailedMessage(it).subscribe((it: string): void => {
                if (it === `Error(s):\n` + "non_field_errors: Signature has expired.") {
                    xSessionApiLogOut(this);
                }
            }, (it: any): void => {});
        });
    }
    
    
    public lastRefreshAt: number;
    
    public refreshCount: number;
    
    public refresh(): void {
        const now = Time.INSTANCE.epochMilliseconds();
        
        if (now - this.lastRefreshAt < 100) { return }
        this.lastRefreshAt = now;
        this.refreshCount = this.refreshCount + 1;
        console.info(`${"SessionApi"}: ${`For user ${this.session.userId}, refreshing data...`}`);
        this.verifyToken();
        //        deadBuildCheck()
        for (const item of this.refreshables) {
            item.refresh();
        }
    }
    
    public clear(): void {
        const now = Time.INSTANCE.epochMilliseconds();
        
        this.socketOpenAt = now;
        this.socketClosedAt = now - 1;
        for (const item of this.refreshables) {
            item.clear();
        }
    }
    
    public forceRefresh(): void {
        this.clear();
        this.refresh();
    }
    
    public readonly schools: CachedApiTable<School>;
    
    
    public readonly allSchools: CachedApiTable<School>;
    
    
    public readonly districts: CachedApiTable<District>;
    
    
    public readonly posts: CachedApiTable<BoardPost>;
    
    
    public readonly alerts: CachedApiTable<Alert>;
    
    
    public readonly alertTypes: CachedApiTable<AlertType>;
    
    
    public readonly alertGuidances: CachedApiTable<AlertGuidance>;
    
    
    public readonly alertHistory: CachedApiTable<AlertHistory>;
    
    
    public readonly threads: CachedApiTable<ChatThread>;
    
    
    public readonly messages: CachedApiTable<ChatMessage>;
    
    
    public readonly buildings: CachedApiTable<Building>;
    
    
    public readonly floors: CachedApiTable<Floor>;
    
    
    public readonly rooms: CachedApiTable<Room>;
    
    
    public readonly users: CachedApiTable<User>;
    
    
    
    public readonly userSchoolMembership: CachedApiTable<UserSchoolMembership>;
    
    
    public readonly permissionGroups: CachedApiTable<Group>;
    
    
    public readonly loginActivity: CachedApiTable<LoginActivity>;
    
    
    public readonly firebaseMessagingToken: CachedApiTable<FirebaseMessagingToken>;
    
    
    public readonly devices: CachedApiTable<Device>;
    
    
    public readonly unreadThreads: CachedApiTable<UnreadThread>;
    
    
    public cancelAlertWithReason(id: ForeignKey<Alert>, reason: string): Observable<void> {
        return this.rawApi.cancelAlertWithReason(this.session, id, reason).pipe(rxTap((it: void): void => {
            this.alerts.terminated(id, undefined);
        }));
    }
    
    //! Declares com.tresitgroup.android.tresit.api.SessionApi.me
    public get me(): CacheNode<User> { return this.users.observable(this.session.userId); }
    
    
    public readonly alertCancelListener: SubscriptionLike;
    
    
    //unused
    //    fun requestAlert(schoolId: Int, name: Int, created: String, initiator: Int, policeStatus: String, scoped: Boolean, silent: Boolean, internal: Boolean): Single<Unit> {
        //        return HttpClient.call(
            //            url = (ApiOption.Production.api as LiveApi).httpUrl + "api/v2/requires-admin-approval-alert/",
            //            method = HttpClient.POST,
            //            body = mapOf(Pair("school", schoolId), Pair("name", name), Pair("created", created),
                //                Pair("initiator", initiator), Pair("police_status", policeStatus), Pair("scoped", scoped),
                //                Pair("silent", silent), Pair("internal", internal), Pair("pending_approval_status", "pending")
            //            ).toJsonHttpBody(),
            //            headers = mapOf(Pair("Authorization", "JWT ${session.token}")),
        //            ).discard().doOnSubscribe {
            //            android.util.Log.d("TAG", "requestAlert: " + it)
        //        }
    //    }
    
    /*
    fun updateAdminApproval(alertId: Int?, status: String): Single<Unit> {
        return HttpClient.call(
            url = (ApiOption.Production.api as LiveApi).httpUrl + "api/v2/requires-admin-approval-alert/$alertId/",
            method = HttpClient.PATCH,
            body = mapOf(Pair("pending_approval_status", status)
            ).toJsonHttpBody(),
            headers = mapOf(Pair("Authorization", "JWT ${session.token}")),
        ).discard().doOnSubscribe {
        }
    }*/
    
    /*     fun adminApproval(
        session: Session,
        schoolId: String
    ): Single<List<AdminApprovalListResponse>> =
    HttpClient.call(
        url = (ApiOption.Production.api as LiveApi).httpUrl + "api/v2/requires-admin-approval-alert/?school=${schoolId}",
        method = HttpClient.GET,
        headers = mapOf(Pair("Authorization", "JWT ${session.token}"))
    ).readJsonDebug<Page<AdminApprovalListResponse>>().map { it.results }*/
    
    //    fun getAdminAlertHistory(
        //        query: String
    //    ): Single<List<AdminRequestHistory>> {
        //        return HttpClient.call(
            //            url = (ApiOption.Production.api as LiveApi).httpUrl + "api/v2/requires-admin-approval-alert-history/?" + query,
            //            method = HttpClient.GET,
            //            headers = mapOf(Pair("Authorization", "JWT ${session.token}")),
        //        ).readJsonDebug<Page<AdminRequestHistory>>().map { it.results }
    //    }
    
    public close(): void {
        this.alertCancelListener.unsubscribe();
        for (const item of this.refreshables) {
            item.close();
        }
        this.socketShouldBeOpen.next(false);
        this.foregroundListener.unsubscribe();
        console.info(`${"SessionApi"}: ${`For user ${this.session.userId}, closing the session.  Signing off.`}`);
    }
}