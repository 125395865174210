// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/SocketMessage.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Time } from '../util/time'
import { xStringFromJsonString } from 'butterfly-web/dist/Codable'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'
import { TypedSocketMessage } from './TypedSocketMessage'

//! Declares com.tresitgroup.android.tresit.model.SocketMessage
export class SocketMessage {
    public readonly type: string;
    public readonly data: (string | null);
    public readonly timestamp: Date;
    public readonly id: (number | null);
    public constructor(type: string = "", data: (string | null) = null, timestamp: Date = Time.INSTANCE.date(), id: (number | null) = null) {
        this.type = type;
        this.data = data;
        this.timestamp = timestamp;
        this.id = id;
    }
    public static fromJson(obj: any): SocketMessage { return new SocketMessage(
        parseJsonTyped(obj["type"], [String]) as string,
        parseJsonTyped(obj["data"], [String]) as (string | null),
        parseJsonTyped(obj["timestamp"], [Date]) as Date,
        parseJsonTyped(obj["id"], [Number]) as (number | null)
    ) }
    public toJSON(): object { return {
        type: this.type,
        data: this.data,
        timestamp: this.timestamp,
        id: this.id
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.type);
        hash = 31 * hash + hashAnything(this.data);
        hash = 31 * hash + hashAnything(this.timestamp);
        hash = 31 * hash + hashAnything(this.id);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof SocketMessage && safeEq(this.type, other.type) && safeEq(this.data, other.data) && safeEq(this.timestamp, other.timestamp) && safeEq(this.id, other.id) }
    public toString(): string { return `SocketMessage(type=${this.type}, data=${this.data}, timestamp=${this.timestamp}, id=${this.id})` }
    public copy(type: string = this.type, data: (string | null) = this.data, timestamp: Date = this.timestamp, id: (number | null) = this.id): SocketMessage { return new SocketMessage(type, data, timestamp, id); }
    
    public typed<T extends any>(T: Array<any>, typeName: string): (TypedSocketMessage<T> | null) {
        if (!(typeName === this.type)) { return null } else { return new TypedSocketMessage<T>(this.type, ((): (T | null) => {
            const temp2226 = this.data;
            if(temp2226 !== null) {
                return xStringFromJsonString<T>(temp2226, T)
            } else { return null }
        })(), this.timestamp, this.id) }
    }
}
