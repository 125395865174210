// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/FormUserDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { safeEq } from 'butterfly-web/dist/kotlin/Language'
import { ViewStringRaw } from 'butterfly-web/dist/views/ViewString'
import { User } from '../../model/User'
import { FormUserDetailXml } from '../../layout/FormUserDetailXml'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { AdminPasswordResetVG } from './AdminPasswordResetVG'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ComponentUserSchoolXml } from '../../layout/ComponentUserSchoolXml'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { PartSchoolSelectVG } from './PartSchoolSelectVG'
import { xMutableObservablePropertyMapWithExisting } from 'butterfly-web/dist/observables/TransformedMutableObservableProperty2'
import { spinnerBind } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { find as iterFind } from 'butterfly-web/dist/kotlin/lazyOp'
import { School } from '../../model/School'
import { Group } from '../../model/Group'

//! Declares com.tresitgroup.android.tresit.vg.admin.FormUserDetailVG
export class FormUserDetailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly user: MutableObservableProperty<User>;
    public readonly userSchools: MutableObservableProperty<Array<School>>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, user: MutableObservableProperty<User>, userSchools: MutableObservableProperty<Array<School>>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.user = user;
        this.userSchools = userSchools;
        this.newSchool = new StandardObservableProperty<boolean>(false, undefined);
        this.newSchoolFilter = new PartSchoolSelectVG(this.session);
        this.groups = xObservablePropertyMap<(Array<Group> | null), Array<Group>>(this.session.permissionGroups.observableListSimple(ApiFilter.Companion.INSTANCE.all<Group>(), undefined, undefined, undefined, undefined, undefined), (it: (Array<Group> | null)): Array<Group> => it ?? []);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.FormUserDetailVG.title
    public get title(): string { return "Form User Detail"; }
    
    
    public readonly newSchool: MutableObservableProperty<boolean>;
    
    public readonly newSchoolFilter: PartSchoolSelectVG;
    
    public readonly groups: ObservableProperty<Array<Group>>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new FormUserDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- From Xml
        this.generateFromXml(dependency, xml);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public generateFromXml(dependency: Window, xml: FormUserDetailXml): void {
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<User, string>(this.user, (it: User): string => `Editing ${it.firstName} ${it.lastName}`));
        
        //--- Set Up xml.firstName
        xEditTextBindString(xml.firstName, xMutableObservablePropertyMapWithExisting<User, string>(this.user, (it: User): string => it.firstName, (user: User, value: string): User => user.copy(undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.lastName
        xEditTextBindString(xml.lastName, xMutableObservablePropertyMapWithExisting<User, string>(this.user, (it: User): string => it.lastName, (user: User, value: string): User => user.copy(undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.cellPhone
        xEditTextBindString(xml.cellPhone, xMutableObservablePropertyMapWithExisting<User, string>(this.user, (it: User): string => it.cellPhone ?? "", (user: User, value: string): User => user.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.email
        xEditTextBindString(xml.email, xMutableObservablePropertyMapWithExisting<User, string>(this.user, (it: User): string => it.email, (user: User, value: string): User => user.copy(undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.passwordReset (overwritten on flow generation)
        xViewOnClick(xml.passwordReset, undefined, (): void => {
            this.passwordResetClick();
        });
        
        //--- Set Up xml.userType
        spinnerBind<Group>(xml.userType, this.groups, xObservablePropertyWithWrite<Group>(xObservablePropertyCombine<Array<Group>, User, Group>(this.groups, this.user, (groups: Array<Group>, user: User): Group => iterFind(groups, (it: Group): boolean => it.id === (user.groups[0] ?? null)) ?? new Group(undefined, R._string.select_a_user_type)), (group: Group): void => {
                    this.user.value = this.user.value.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [group.id], undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            }), (it: Group): string => {
                return it.name;
        });
        
        //--- Set Up xml.userSchoolsLoading
        xViewFlipperBindLoading(xml.userSchoolsLoading, xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => it === null), undefined);
        
        //--- Set Up xml.userSchools
        xLinearLayoutBind<School>(xml.userSchools, this.userSchools, new School(undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (school: ObservableProperty<School>): HTMLElement => {
            //--- Make Subview For xml.userSchools (overwritten on flow generation)
            const cellXml = new ComponentUserSchoolXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root (overwritten on flow generation)
            
            //--- Set Up cellXml.label
            xTextViewBindString(cellXml.label, xObservablePropertyMap<School, string>(school, (it: School): string => it.name));
            
            //--- Set Up cellXml.remove
            xViewOnClick(cellXml.remove, undefined, (): void => {
                this.cellXmlRemoveClick(school.value.id);
            });
            //--- End Make Subview For xml.userSchools (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.newSchool
        xViewBindExists(xml.newSchool, this.newSchool);
        
        xCompoundButtonBind(xml.isVip, xMutableObservablePropertyMapWithExisting<User, boolean>(this.user, (it: User): boolean => it.isVip, (user: User, value: boolean): User => user.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined)));
        
        //--- Set Up xml.newSchoolSelect.xmlRoot
        this.newSchoolFilter.bindSelect((it: School): void => {
            this.chooseNewSchool(it);
        });
        this.newSchoolFilter.inlineGenerate(xml.newSchoolSelect);
        
        //--- Set Up xml.newSchoolSelect.schoolFilter (overwritten on flow generation)
        
        //--- Set Up xml.addSite
        xViewBindExists(xml.addSite, xObservablePropertyMap<boolean, boolean>(this.newSchool, (it: boolean): boolean => (!it)));
        xViewOnClick(xml.addSite, undefined, (): void => {
            this.addSiteClick();
        });
        
        
        //--- End FromXml
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public passwordResetClick(): void {
        this.dialog.push(new AdminPasswordResetVG(this.session, this.dialog, this.user.value.id));
    }
    public cellXmlRemoveClick(id: (ForeignKey<School> | null)): void {
        this.userSchools.value = this.userSchools.value.filter((it: School): boolean => !(it.id === id));
    }
    public addSiteClick(): void {
        this.newSchool.value = true;
    }
    public chooseNewSchool(school: School): void {
        if (this.userSchools.value.map((it: School): number => it.id).some((x) => safeEq(school.id, x)) === false) {
            
            this.newSchool.value = false;
            const temp3911 = this.userSchools;
            temp3911.value = temp3911.value.concat([school]);
        } else {
            showDialogAlert(new ViewStringRaw("This user already belongs to that school."));
        }
    }
    
    
    //--- Body End
}
