//
// ComponentFloorXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_floor.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentFloorXml
export class ComponentFloorXml {
    xmlRoot!: HTMLElement
    background!: HTMLDivElement
    space!: HTMLElement
    label!: HTMLElement
    indicator!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.background = getViewById<HTMLDivElement>(view, "background")
        this.space = getViewById<HTMLElement>(view, "space")
        this.label = getViewById<HTMLElement>(view, "label")
        this.indicator = getViewById<HTMLElement>(view, "indicator")
        return view
    }
}
