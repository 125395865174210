// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminDashboardVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { AlertHistoryVG } from './AlertHistoryVG'
import { ChatHistoryVG } from './ChatHistoryVG'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xObservableAsObservablePropertyDefaultNull } from 'butterfly-web/dist/observables/EventToObservableProperty'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { shareReplay as rxShareReplay } from 'rxjs/operators'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { logVG } from '../../util/LogVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { MoreSettingVG } from './MoreSettingVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { AdminUsersVG } from './AdminUsersVG'
import { AdminAlertTypesVG } from './AdminAlertTypesVG'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { School } from '../../model/School'
import { AdminDashboardXml } from '../../layout/AdminDashboardXml'
import { Stats } from '../../model/Stats'
import { sprintf } from 'sprintf-js'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminDashboardVG
export class AdminDashboardVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.stats = xObservableAsObservablePropertyDefaultNull<Stats>(this.session.rawApi.stats(this.session.session).pipe(rxShareReplay(1)));
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminDashboardVG.title
    public get title(): string { return "Admin Dashboard"; }
    
    
    public readonly stats: ObservableProperty<(Stats | null)>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminDashboardXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.users (overwritten on flow generation)
        xViewOnClick(xml.users, undefined, (): void => {
            this.usersClick();
        });
        
        //--- Set Up xml.totalUsers
        xTextViewBindString(xml.totalUsers, xObservablePropertyMap<(Stats | null), string>(this.stats, (it: (Stats | null)): string => ((it?.total_users ?? null)?.toString() ?? null) ?? "-"));
        
        //--- Set Up xml.alertTypes (overwritten on flow generation)
        xViewOnClick(xml.alertTypes, undefined, (): void => {
            this.alertTypesClick();
        });
        
        //--- Set Up xml.mostUsedAlert
        xTextViewBindString(xml.mostUsedAlert, xObservablePropertyMap<(Stats | null), string>(this.stats, (it: (Stats | null)): string => (it?.most_used_alert ?? null) ?? "-"));
        
        //--- Set Up xml.totalAlertTypes
        xTextViewBindString(xml.totalAlertTypes, xObservablePropertyMap<(Stats | null), string>(this.stats, (it: (Stats | null)): string => ((it?.total_alert_types ?? null)?.toString() ?? null) ?? "-"));
        
        //--- Set Up xml.alertHistory (overwritten on flow generation)
        xViewOnClick(xml.alertHistory, undefined, (): void => {
            this.alertHistoryClick();
        });
        
        //--- Set Up xml.alertsThisMonth
        xTextViewBindString(xml.alertsThisMonth, xObservablePropertyMap<(Stats | null), string>(this.stats, (it: (Stats | null)): string => ((it?.alerts_this_month ?? null)?.toString() ?? null) ?? "-"));
        
        //--- Set Up xml.chat (overwritten on flow generation)
        xViewOnClick(xml.chat, undefined, (): void => {
            this.chatClick();
        });
        
        //--- Set Up xml.avgMessagesPerAlert
        xTextViewBindString(xml.avgMessagesPerAlert, xObservablePropertyMap<(Stats | null), string>(this.stats, (it: (Stats | null)): string => ((): (string | null) => {
            const temp3502 = (it?.avg_mess_per_alert ?? null);
            if(temp3502 === null) { return null }
            return ((it: number): string => sprintf(R._string.two_decimals_rounded, it))(temp3502)
        })() ?? "-"));
        
        xViewBindExists(xml.toggleSafeUnsafe, new StandardObservableProperty<boolean>(false, undefined));
        xViewOnClick(xml.toggleSafeUnsafe, undefined, (): void => {
            this.stack.reset(new MoreSettingVG(this.schoolFilter, this.session));
        });
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public usersClick(): void {
        this.stack.reset(new AdminUsersVG(this.dialog, this.schoolFilter, this.session, this.stack));
    }
    
    public alertTypesClick(): void {
        this.stack.reset(new AdminAlertTypesVG(this.schoolFilter, this.session, this.dialog, this.stack));
    }
    
    public alertHistoryClick(): void {
        this.stack.reset(new AlertHistoryVG(this.schoolFilter, this.session, this.stack, this.dialog));
    }
    public chatClick(): void {
        this.stack.reset(new ChatHistoryVG(this.dialog, this.schoolFilter, this.session, this.stack));
    }
    
    //--- Body End
}
