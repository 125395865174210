// Generated by Butterfly TypeScript converter
// File: map/MapWithOverlayView.actual.kt
// Package: com.tresitgroup.android.tresit.map
import {GeoCoordinateBounds} from './Geometry'
import {GeoCoordinate} from 'butterfly-web/dist/location/GeoCoordinate'
import {getAnimationFrame} from 'butterfly-web/dist/delay'
import {Preferences} from 'butterfly-web/dist/Preferences'
import {Projector} from './Projector'
import {xViewRemovedGet, xDisposableUntil} from 'butterfly-web/dist/rx/DisposeCondition.ext'
import {DisposableLambda} from 'butterfly-web/dist/rx/DisposableLambda'
import {xGeoCoordinateToMaps, xLatLngToButterfly} from 'butterfly-maps-web-google/dist/LatLng.ext'
import {aquireMap, retireMap} from 'butterfly-maps-web-google/dist/MapView.bind'
import MapTypeStyle = google.maps.MapTypeStyle;
import LatLngBounds = google.maps.LatLngBounds;
import LatLng = google.maps.LatLng;
import MapCanvasProjection = google.maps.MapCanvasProjection;
import Point = google.maps.Point;
import MapOptions = google.maps.MapOptions;
import MapsEventListener = google.maps.MapsEventListener;

//Multiplatform interface

const mapControllerSymbol = Symbol("mapControllerSymbol");
declare global {
    interface HTMLDivElement {
        [mapControllerSymbol]: MapWithOverlayViewController | undefined;
    }
}

class MapWithOverlayViewController {
    map: google.maps.Map;
    overlay: CanvasOverlay;
    rerender: boolean = false;
    styles: Array<MapTypeStyle>;
    render: (a: CanvasRenderingContext2D, b: Projector) => void;
    bounded: boolean = false;
    static lastLocationKey: string = "com.tresitgroup.android.tresit.map.MapWithOverlayView.location"
    static lastZoomKey: string = "com.tresitgroup.android.tresit.map.MapWithOverlayView.zoom"
}

class CanvasOverlay extends google.maps.OverlayView implements Projector {
    container: HTMLElement
    canvas: HTMLCanvasElement
    render: (a: CanvasRenderingContext2D, b: Projector) => void;
    cachedProjector: MapCanvasProjection | null = null

    constructor(container: HTMLElement, render: (a: CanvasRenderingContext2D, b: Projector) => void) {
        super()
        this.canvas = document.createElement("canvas")
        this.canvas.style.width = "100%"
        this.canvas.style.height = "100%"
        this.canvas.style.zIndex = "99999"
        this.canvas.style.pointerEvents = "none"
        this.container = container
        this.render = render
    }

    onAdd() {
    }

    draw() {
        this.updateProj()
        this.update()
    }

    update() {
        if (this.xAdd === 0) return
        const canvas = this.canvas
        canvas.width = canvas.offsetWidth
        canvas.height = canvas.offsetHeight
        if (canvas && canvas.getContext) {
            const ctx = canvas.getContext("2d");
            if (ctx && canvas.width > 2 && canvas.height > 2) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                this.render(ctx, this)
            }
        }
    }

    onRemove() {
    }

    center: GeoCoordinate = new GeoCoordinate(0, 0)
    visibleBounds: GeoCoordinateBounds = new GeoCoordinateBounds(new GeoCoordinate(0, 0), new GeoCoordinate(1, 1))
    zoom: number = 5
    xMultiplier: number = 1
    xAdd: number = 0
    yMultiplier: number = 1
    yAdd: number = 0
    ratio: number = 0

    updateProj() {
        const map = this.getMap()
        if (map instanceof google.maps.Map) {
            this.cachedProjector = this.getProjection()
            const visibleBounds = map.getBounds()!
            this.visibleBounds = boundsToButterfly(visibleBounds)
            this.center = coordToButterfly(visibleBounds.getCenter())
            this.zoom = map.getZoom()

            const ne = visibleBounds.getNorthEast()
            const nex = this.cachedProjector.fromLatLngToContainerPixel(ne).x
            const ney = this.cachedProjector.fromLatLngToContainerPixel(ne).y
            const sw = visibleBounds.getSouthWest()
            const swx = this.cachedProjector.fromLatLngToContainerPixel(sw).x
            const swy = this.cachedProjector.fromLatLngToContainerPixel(sw).y
            this.xMultiplier = (nex - swx) / (ne.lng() - sw.lng())
            this.xAdd = (nex - this.xMultiplier * ne.lng()) //y = mx + b, b = y - mx
            this.yMultiplier = (ney - swy) / (ne.lat() - sw.lat())
            this.yAdd = (ney - this.yMultiplier * ne.lat()) //y = mx + b, b = y - mx
        }
    }

    convertToX(geo: GeoCoordinate): number {
        return geo.longitude * this.xMultiplier + this.xAdd
    }

    convertToY(geo: GeoCoordinate): number {
        return geo.latitude * this.yMultiplier + this.yAdd
    }
}

function coordToMaps(coord: GeoCoordinate): LatLng {
    return new LatLng(coord.latitude, coord.longitude);
}

function boundsToMaps(bounds: GeoCoordinateBounds): LatLngBounds {
    return new LatLngBounds(coordToMaps(bounds.southwest), coordToMaps(bounds.northeast));
}

function coordToButterfly(coord: LatLng): GeoCoordinate {
    return new GeoCoordinate(coord.lat(), coord.lng());
}

function boundsToButterfly(bounds: LatLngBounds): GeoCoordinateBounds {
    return new GeoCoordinateBounds(coordToButterfly(bounds.getSouthWest()), coordToButterfly(bounds.getNorthEast()));
}

export function mwovConfigure(
    container: HTMLDivElement,
    dependency: Window,
    style: (string | null) = null,
    onClick: ((a: GeoCoordinate) => void) = (_0: GeoCoordinate): void => {
    },
    render: ((a: CanvasRenderingContext2D, b: Projector) => void) = (_0: CanvasRenderingContext2D, _1: Projector): void => {
    }
): void {
    container.classList.add("khr")
    container.classList.add("butterfly-frame")
    const mapContainer = document.createElement("div")
    mapContainer.style.position = "relative"
    const controller = new MapWithOverlayViewController();
    controller.render = render;
    let styles: Array<MapTypeStyle> = [];
    if (style) {
        styles = JSON.parse(style);
    }
    controller.styles = styles;

    let reusableMap = aquireMap()
    reusableMap.map.setOptions({
        styles: styles,
        clickableIcons: false,
        fullscreenControl: false,
        streetViewControl: false,
        rotateControl: false,
        mapTypeControl: false
    })
    mapContainer.appendChild(reusableMap.div);
    container.appendChild(mapContainer);

    const map = reusableMap.map;
    controller.map = map;

    const overlay = new CanvasOverlay(container, controller.render);
    overlay.setMap(map)
    controller.overlay = overlay
    container.appendChild(overlay.canvas)

    let lastClick = 0
    const listeners: Array<MapsEventListener> = []
    listeners.push(map.addListener("click", (ev) => {
        const newClick = Date.now()
        if(newClick - lastClick > 1000){
            onClick(new GeoCoordinate(ev.latLng.lat(), ev.latLng.lng()));
            lastClick = newClick
        }
    }));

    function savePosition() {
        if (!controller.bounded) return;
        Preferences.INSTANCE.set(GeoCoordinate, MapWithOverlayViewController.lastLocationKey, xLatLngToButterfly(map.getCenter()));
        Preferences.INSTANCE.set(Number, MapWithOverlayViewController.lastZoomKey, map.getZoom());
    }

    listeners.push(map.addListener("bounds_changed", () => {
        controller.rerender = true;
        savePosition();
    }))
    listeners.push(map.addListener("center_changed", () => {
        controller.rerender = true;
        savePosition();
    }))
    container[mapControllerSymbol] = controller;

    xViewRemovedGet(container).call(new DisposableLambda(() => {
        for(const listener of listeners){
            listener.remove()
        }
        container.removeChild(reusableMap.div);
        retireMap(reusableMap);
    }))

    let isDrawing = false
    xDisposableUntil(getAnimationFrame().subscribe(() => {
        if (controller.rerender && !isDrawing) {
            isDrawing = true
            const proj = controller.overlay;
            if (proj === null) return;
            controller.rerender = false;
            proj.update()
            isDrawing = false
        }
    }, () => {
    }, () => {
    }), xViewRemovedGet(container));
}

export function mwovSetBounds(container: HTMLDivElement, geoCoordinateBounds: GeoCoordinateBounds): void {
    let controller = container[mapControllerSymbol];
    if (controller === undefined) return;
    const pos = Preferences.INSTANCE.get<GeoCoordinate>(GeoCoordinate, MapWithOverlayViewController.lastLocationKey);
    const zoom = Preferences.INSTANCE.get<number>(Number, MapWithOverlayViewController.lastZoomKey);
    const inbounds = pos != null ? geoCoordinateBounds.contains(pos) : false;
    const focus = inbounds ? pos! : geoCoordinateBounds.center;
    function latRad(lat: number): number {
        const sin = Math.sin(lat * Math.PI / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }
    controller.map.setOptions({
        center: {lat: focus.latitude, lng: focus.longitude},
        zoom: inbounds ? zoom : Math.min(
            Math.floor(Math.log(container.scrollWidth * 360 / (geoCoordinateBounds.northeast.longitude - geoCoordinateBounds.southwest.longitude) / 256) / Math.LN2),
            Math.floor(Math.log(container.scrollHeight / ((latRad(geoCoordinateBounds.northeast.latitude) - latRad(geoCoordinateBounds.southwest.latitude)) / Math.PI) / 256) / Math.LN2)
        ),
        styles: controller.styles,
        restriction: {
            latLngBounds: boundsToMaps(new GeoCoordinateBounds(
                new GeoCoordinate(
                    geoCoordinateBounds.southwest.latitude - geoCoordinateBounds.height,
                    geoCoordinateBounds.southwest.longitude - geoCoordinateBounds.width
                ),
                new GeoCoordinate(
                    geoCoordinateBounds.northeast.latitude + geoCoordinateBounds.height,
                    geoCoordinateBounds.northeast.longitude + geoCoordinateBounds.width
                )
            )),
            strictBounds: false
        },
        animatedZoom: false
    } as MapOptions);
    controller.bounded = true;
}

export function mwovRequestRedraw(container: HTMLDivElement): void {
    let controller = container[mapControllerSymbol];
    if (controller === undefined) return;
    controller.rerender = true;
}

export function mwovFocus(container: HTMLDivElement, bounds: GeoCoordinateBounds): void {
    container[mapControllerSymbol]?.map?.fitBounds(boundsToMaps(bounds), 0);
}
