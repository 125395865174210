//
// AlertsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './alerts.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertsXml
export class AlertsXml {
    xmlRoot!: HTMLElement
    alertsHeader!: HTMLDivElement
    alerts!: HTMLDivElement
    alertsSeparator!: HTMLElement
    drillsHeader!: HTMLDivElement
    drills!: HTMLDivElement
    drillsSeparator!: HTMLElement
    schoolNames!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.alertsHeader = getViewById<HTMLDivElement>(view, "alerts_header")
        this.alerts = getViewById<HTMLDivElement>(view, "alerts")
        this.alertsSeparator = getViewById<HTMLElement>(view, "alerts_separator")
        this.drillsHeader = getViewById<HTMLDivElement>(view, "drills_header")
        this.drills = getViewById<HTMLDivElement>(view, "drills")
        this.drillsSeparator = getViewById<HTMLElement>(view, "drillsSeparator")
        this.schoolNames = getViewById<HTMLDivElement>(view, "school_names")
        return view
    }
}
