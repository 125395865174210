//
// ComponentRowUserXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/component_row_user.html'
import htmlForDefault from './component_row_user.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowUserXml
export class ComponentRowUserXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    name!: HTMLElement
    userType!: HTMLElement
    row: HTMLDivElement | null = null
    latestActivityDate: HTMLElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.name = getViewById<HTMLElement>(view, "name")
        this.userType = getViewById<HTMLElement>(view, "userType")
        this.row = findViewById<HTMLDivElement>(view, "row")
        this.latestActivityDate = findViewById<HTMLElement>(view, "latestActivityDate")
        return view
    }
}
