// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AlertHistoryDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { ChatMessage } from '../../model/ChatMessage'
import { Image, xIntAsImage, xStringAsImage } from 'butterfly-web/dist/Image'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { ComponentRowMessageSentXml } from '../../layout/ComponentRowMessageSentXml'
import { AlertHistoryDetailed } from '../../model/AlertHistoryDetailed'
import { AlertHistoryDetailXml } from '../../layout/AlertHistoryDetailXml'
import { ComponentRowMessageXml } from '../../layout/ComponentRowMessageXml'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { User } from '../../model/User'
import { ComponentChatAttachmentXml } from '../../layout/ComponentChatAttachmentXml'
import { xRecyclerViewBindMulti, xRecyclerViewReverseDirectionSet } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { setViewBackgroundClass, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { R } from '../../R'
import { ComponentRowMessageAttachmentsXml } from '../../layout/ComponentRowMessageAttachmentsXml'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { ComponentRowMessageSentAttachmentsXml } from '../../layout/ComponentRowMessageSentAttachmentsXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xCharSequenceIsBlank, xStringSubstringAfterLast, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { ChatAttachment } from '../../model/ChatAttachment'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ComponentRowMessageSystemXml } from '../../layout/ComponentRowMessageSystemXml'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { AlertHistory } from '../../model/AlertHistory'

//! Declares com.tresitgroup.android.tresit.vg.admin.AlertHistoryDetailVG
export class AlertHistoryDetailVG extends ViewGenerator {
    public readonly historyId: ForeignKey<AlertHistory>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly cancelledDate: (Date | null);
    public readonly cancellerName: (string | null);
    public readonly alertDetails: AlertHistoryDetailed;
    public constructor(historyId: ForeignKey<AlertHistory>, session: SessionApi, stack: ObservableStack<ViewGenerator>, cancelledDate: (Date | null), cancellerName: (string | null), alertDetails: AlertHistoryDetailed) {
        super();
        this.historyId = historyId;
        this.session = session;
        this.stack = stack;
        this.cancelledDate = cancelledDate;
        this.cancellerName = cancellerName;
        this.alertDetails = alertDetails;
        this.history = new StandardObservableProperty<(AlertHistory | null)>(null, undefined);
        this.cancelReason = new StandardObservableProperty<string>(this.alertDetails.cancelReason, undefined);
        this.school = new StandardObservableProperty<string>(this.alertDetails.siteName ?? "", undefined);
        this.alertType = new StandardObservableProperty<string>(this.alertDetails.alertTypeName ?? "", undefined);
        this.startUser = new StandardObservableProperty<string>(`${this.alertDetails.initiatorFirstName ?? ""} ${this.alertDetails.initiatorLastName ?? ""}`, undefined);
        this.endUser = new StandardObservableProperty<string>(`${this.alertDetails.cancellerFirstName} ${this.alertDetails.cancellerLastName ?? ""}`, undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AlertHistoryDetailVG.title
    public get title(): string { return "Alert History Detail"; }
    
    
    public readonly history: MutableObservableProperty<(AlertHistory | null)>;
    
    public readonly cancelReason: MutableObservableProperty<string>;
    
    public readonly school: ObservableProperty<string>;
    
    public readonly alertType: ObservableProperty<string>;
    
    public readonly startUser: ObservableProperty<string>;
    
    public readonly endUser: ObservableProperty<string>;
    
    
    
    
    public xml: AlertHistoryDetailXml;
    
    public dependency: Window;
    
    
    
    public generate(dependency: Window): HTMLElement {
        this.xml = new AlertHistoryDetailXml();
        const view = this.xml.setup(dependency);
        
        
        this.dependency = dependency;
        
        //--- Log
        logVG(this, this.session);
        this.getTMessage(this.alertDetails.threadId);
        
        
        //--- Set Up xml.alertType
        xTextViewBindString(this.xml.alertType, this.alertType);
        
        //--- Set Up xml.site
        xTextViewBindString(this.xml.site, this.school);
        //this was added to show change log but michel changed his mind
        //  xml.tvAlertChangeLog?.bindExists(value.map { it.isNotEmpty() })
        
        //--- Set Up xml.startedUser
        xTextViewBindString(this.xml.startedUser, xObservablePropertyCombine<string, (AlertHistory | null), string>(this.startUser, this.history, (user: string, history: (AlertHistory | null)): string => `${user ?? "Unknown"} at ${xDateFormat(this.alertDetails.created, ClockPartSize.Short, ClockPartSize.Short) ?? "--"}`));
        
        //--- Set Up xml.endedInfo (overwritten on flow generation)
        
        //--- Set Up xml.endedUser
        xTextViewBindString(this.xml.endedInfo, xObservablePropertyCombine<string, (AlertHistory | null), string>(this.endUser, this.history, (user: string, history: (AlertHistory | null)): string => {
            console.debug(`${"Check name"}: ${`generate: ${this.cancellerName}`}`);
            return `${((): (boolean | null) => {
                const temp3622 = this.cancellerName;
                if(temp3622 !== null) {
                    return xCharSequenceIsBlank(temp3622)
                } else { return null }
            })() === true ? "--" : this.cancellerName} at ${((): (string | null) => {
                const temp3623 = this.cancelledDate;
                if(temp3623 !== null) {
                    return xDateFormat(temp3623, ClockPartSize.Short, ClockPartSize.Short)
                } else { return null }
            })() ?? "--"}`;
        }));
        
        //--- Set Up xml.reason
        xTextViewBindString(this.xml.reason, this.cancelReason);
        
        //--- Set Up xml.back (overwritten on flow generation)
        xViewOnClick(this.xml.back, undefined, (): void => {
            this.backClick();
        });
        
        //this was added to show change log but michel changed his mind
        /*  xml.changeLog.bind(
            data = value,
            defaultValue = AlertHistoryChild(),
            makeView = attachment@{ obs ->
                val attXml = AlertChangeItemXml()
                val attView = attXml.setup(dependency)
                attXml.alertType.bindString(obs.map { it.alertTypeName?:"Loading" })
                attXml.site.bindString(obs.map { it.initiatorFirstName?:"Loading" })
                
                return@attachment attView
            }
        )*/
        
        //--- Generate End (overwritten on flow generation)
        
        
        
        return view;
    }
    
    /*    private fun getAlertId(id: Long) {
        session.rawApi.getAlertId(session.session, id.toString()).subscribeBy(
            onError = {
                showDialog(ViewStringResource(R.string.something_went_wrong))
            },
            onSuccess = {
                getThread(it.alert_id)
            }
        )
    }*/
    
    /* private fun getThread(alertId: String) {
        
        session.rawApi.getThreadId(session.session, alertId).subscribeBy(
            onError = {
                showDialog(ViewStringResource(R.string.something_went_wrong))
            },
            onSuccess = {
                val threadId = it[0].id
                val messages = session.messages.observableList(
                    filter = ChatMessage.partOfThread(it[0].id),
                    sort = ChatMessage.createdDownSort,
                    includeDefaultFilter = false
                ).map { it ?: listOf() }
                showThreadList(threadId, messages)
            }
        )
    }*/
    
    private getTMessage(threadId: number): void {
        const messages = xObservablePropertyMap<(Array<ChatMessage> | null), Array<ChatMessage>>(this.session.messages.observableListSimple(ChatMessage.Companion.INSTANCE.partOfThread(threadId), ChatMessage.Companion.INSTANCE.createdDownSort, undefined, undefined, false, undefined), (it: (Array<ChatMessage> | null)): Array<ChatMessage> => it ?? []);
        
        this.showThreadList(threadId, messages);
    }
    
    private showThreadList(threadId: number, messages: ObservableProperty<Array<ChatMessage>>): void {
        //  val thread = session.threads.observable(threadId).map { it }
        /*      val threadAlert : ObservableProperty<Alert?> =
        thread.map { it?.alert }.flatMapNotNull { session.alerts.observable(it) }*/
        xRecyclerViewBindMulti<ChatMessage>(this.xml.threadMessages, messages, new ChatMessage(undefined, undefined, threadId, undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined), (it: ChatMessage): number => it.authorId === this.session.session.userId ? 2 + (it.attachmentsDeep.length !== 0 ? 1 : 0) : it.authorId === null ? 4 : 0 + (it.attachmentsDeep.length !== 0 ? 1 : 0), (type: number, observable: ObservableProperty<ChatMessage>): HTMLElement => {
            const messageAuthor: ObservableProperty<(User | null)> = xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<ChatMessage, (number | null)>(observable, (it: ChatMessage): (number | null) => it.authorId), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it));
            
            
            const roomName: ObservableProperty<string> = xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.creator_room_name ?? "");
            
            
            const buildingName: ObservableProperty<string> = xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.creator_building ?? "");
            
            
            const authorStatus: ObservableProperty<string> = xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.creator_room_status ?? "");
            
            
            
            const names: ObservableProperty<Array<string>> = xObservablePropertyMap<Array<string>, Array<string>>(xListCombined<string>(([roomName, buildingName] as Array<ObservableProperty<string>>)), (it: Array<string>): Array<string> => it.filter((it: string): boolean => !(it === "Default")));
            
            
            const details = xObservablePropertyCombine<string, (User | null), string>(xObservablePropertyCombine<string, Array<string>, string>(xObservablePropertyCombine<string, string, string>(xObservablePropertyMap<ChatMessage, string>(observable, (message: ChatMessage): string => xDateFormat(message.created, ClockPartSize.None, ClockPartSize.Short)), authorStatus, (message: string, status: string): string => ((it: string): string => `${message} | ${it}`)(status)), names, (message: string, locations: Array<string>): string => ((): (string | null) => {
                const temp3633 = (locations[0] ?? null);
                if(temp3633 === null) { return null }
                return ((it: string): string => `${(locations[0] ?? null) ?? "Somewhere"} | ${message}`)(temp3633)
            })() ?? message), messageAuthor, (message: string, author: (User | null)): string => `${(author?.firstName ?? null) ?? "Unknown"} ${(author?.lastName ?? null) ?? "Author"} | ${message}`);
            
            
            
            return ((): HTMLElement => {
                switch(type) {
                    case 0:
                    // Message Received
                    const cellXmlA = new ComponentRowMessageXml();
                    
                    const cellViewA = cellXmlA.setup(this.dependency);
                    
                    
                    xTextViewBindString(cellXmlA.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3635 = ((): (string | null) => {
                            const temp3636 = author;
                            if(temp3636 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3636)
                        })();
                        if(temp3635 === null) { return null }
                        return temp3635.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlA.name, details)
                    xTextViewBindString(cellXmlA.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    return cellViewA
                    case 1:
                    // Message Received with attachment
                    const cellXmlB = new ComponentRowMessageAttachmentsXml();
                    
                    const cellViewB = cellXmlB.setup(this.dependency);
                    
                    
                    xTextViewBindString(cellXmlB.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3640 = ((): (string | null) => {
                            const temp3641 = author;
                            if(temp3641 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3641)
                        })();
                        if(temp3640 === null) { return null }
                        return temp3640.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlB.name, details)
                    xTextViewBindString(cellXmlB.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    this.bindAttachments(cellXmlB.attachments, cellXmlB.attachments, this.dependency, observable)
                    return cellViewB
                    case 2:
                    // Message Sent
                    const cellXmlC = new ComponentRowMessageSentXml();
                    
                    const cellViewC = cellXmlC.setup(this.dependency);
                    
                    xTextViewBindString(cellXmlC.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3645 = ((): (string | null) => {
                            const temp3646 = author;
                            if(temp3646 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3646)
                        })();
                        if(temp3645 === null) { return null }
                        return temp3645.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlC.name, details)
                    xTextViewBindString(cellXmlC.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    return cellViewC
                    case 3:
                    // Message Sent with attachment
                    const cellXmlD = new ComponentRowMessageSentAttachmentsXml();
                    
                    const cellViewD = cellXmlD.setup(this.dependency);
                    
                    xTextViewBindString(cellXmlD.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3650 = ((): (string | null) => {
                            const temp3651 = author;
                            if(temp3651 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3651)
                        })();
                        if(temp3650 === null) { return null }
                        return temp3650.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlD.name, details)
                    xTextViewBindString(cellXmlD.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    this.bindAttachments(cellXmlD.attachments, cellXmlD.attachments, this.dependency, observable)
                    return cellViewD
                    default:
                    // Message from system
                    
                    const cellXmlE = new ComponentRowMessageSystemXml();
                    
                    const cellViewE = cellXmlE.setup(this.dependency);
                    
                    setViewBackgroundClass(cellXmlE.backgroundView, R.drawable.card_alert.cssClass)
                    
                    
                    xTextViewBindString(cellXmlE.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    xImageViewBindImage(cellXmlE.alertTypeIcon, xObservablePropertyMap<ChatMessage, (Image | null)>(observable, (it: ChatMessage): (Image | null) => ((): (Image | null) => {
                        const temp3657 = ((it.attachmentsDeep[0] ?? null)?.image ?? null);
                        if(temp3657 !== null) {
                            return xStringAsImage(temp3657)
                        } else { return null }
                    })()))
                    xTextViewBindString(cellXmlE.name, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => `System Message | ${xDateFormat(it.created, ClockPartSize.Medium, ClockPartSize.Short)}`))
                    return cellViewE
                }
                
            })()
        });
        xRecyclerViewReverseDirectionSet(this.xml.threadMessages, true);
        
    }
    
    
    
    public bindAttachments(section: HTMLElement, toView: HTMLDivElement, dependency: Window, observable: ObservableProperty<ChatMessage>): void {
        xViewBindExists(section, xObservablePropertyMap<ChatMessage, boolean>(observable, (it: ChatMessage): boolean => it.attachmentsDeep.length !== 0));
        xLinearLayoutBind<ChatAttachment>(toView, xObservablePropertyMap<ChatMessage, Array<ChatAttachment>>(observable, (it: ChatMessage): Array<ChatAttachment> => it.attachmentsDeep), new ChatAttachment(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (obs: ObservableProperty<ChatAttachment>): HTMLElement => {
            const attXml = new ComponentChatAttachmentXml();
            
            const attView = attXml.setup(dependency);
            
            
            xImageViewBindImage(attXml.image, xObservablePropertyMap<ChatAttachment, (Image | null)>(obs, (it: ChatAttachment): (Image | null) => ((): (Image | null) => {
                        const temp3660 = it.videoThumbnail;
                        if(temp3660 !== null) {
                            return xStringAsImage(temp3660)
                        } else { return null }
                })() ?? ((): (Image | null) => {
                    const temp3661 = it.imageThumbnail;
                    if(temp3661 !== null) {
                        return xStringAsImage(temp3661)
                    } else { return null }
            })() ?? xIntAsImage(R.drawable.ic_attachment)));
            
            xViewBindExists(attXml.fileName, xObservablePropertyMap<ChatAttachment, boolean>(obs, (it: ChatAttachment): boolean => it.doc !== null));
            xTextViewBindString(attXml.fileName, xObservablePropertyMap<ChatAttachment, string>(obs, (it: ChatAttachment): string => {
                return ((): string => {
                    if (it.video !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.video!, '/', undefined), '?', undefined)
                    } else if (it.image !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.image!, '/', undefined), '?', undefined)
                    } else if (it.doc !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.doc!, '/', undefined), '?', undefined)
                    } else  {
                        return ""
                    }
                })();
            }));
            
            return attView;
        });
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public backClick(): void {
        this.stack.pop();
    }
    
    //--- Body End
}
