// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/FirebaseMessagingToken.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { User } from './User'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.FirebaseMessagingToken
export class FirebaseMessagingToken implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly token: string;
    public readonly platform: string;
    public readonly appVersion: string;
    public readonly user: (ForeignKey<User> | null);
    public readonly active: boolean;
    public readonly created_at: Date;
    public readonly updated_at: Date;
    public readonly user_agent: string;
    public constructor(id: number = getNO_ID(), token: string = "", platform: string = "", appVersion: string = "", user: (ForeignKey<User> | null) = null, active: boolean = true, created_at: Date = new Date(), updated_at: Date = new Date(), user_agent: string = "") {
        this.id = id;
        this.token = token;
        this.platform = platform;
        this.appVersion = appVersion;
        this.user = user;
        this.active = active;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.user_agent = user_agent;
    }
    public static fromJson(obj: any): FirebaseMessagingToken { return new FirebaseMessagingToken(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["token"], [String]) as string,
        parseJsonTyped(obj["platform"], [String]) as string,
        parseJsonTyped(obj["app_version"], [String]) as string,
        parseJsonTyped(obj["user"], [Number]) as (number | null),
        parseJsonTyped(obj["active"], [Boolean]) as boolean,
        parseJsonTyped(obj["created_at"], [Date]) as Date,
        parseJsonTyped(obj["updated_at"], [Date]) as Date,
        parseJsonTyped(obj["user_agent"], [String]) as string
    ) }
    public toJSON(): object { return {
        id: this.id,
        token: this.token,
        platform: this.platform,
        app_version: this.appVersion,
        user: this.user,
        active: this.active,
        created_at: this.created_at,
        updated_at: this.updated_at,
        user_agent: this.user_agent
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.token);
        hash = 31 * hash + hashAnything(this.platform);
        hash = 31 * hash + hashAnything(this.appVersion);
        hash = 31 * hash + hashAnything(this.user);
        hash = 31 * hash + hashAnything(this.active);
        hash = 31 * hash + hashAnything(this.created_at);
        hash = 31 * hash + hashAnything(this.updated_at);
        hash = 31 * hash + hashAnything(this.user_agent);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof FirebaseMessagingToken && safeEq(this.id, other.id) && safeEq(this.token, other.token) && safeEq(this.platform, other.platform) && safeEq(this.appVersion, other.appVersion) && safeEq(this.user, other.user) && safeEq(this.active, other.active) && safeEq(this.created_at, other.created_at) && safeEq(this.updated_at, other.updated_at) && safeEq(this.user_agent, other.user_agent) }
    public toString(): string { return `FirebaseMessagingToken(id=${this.id}, token=${this.token}, platform=${this.platform}, appVersion=${this.appVersion}, user=${this.user}, active=${this.active}, created_at=${this.created_at}, updated_at=${this.updated_at}, user_agent=${this.user_agent})` }
    public copy(id: number = this.id, token: string = this.token, platform: string = this.platform, appVersion: string = this.appVersion, user: (ForeignKey<User> | null) = this.user, active: boolean = this.active, created_at: Date = this.created_at, updated_at: Date = this.updated_at, user_agent: string = this.user_agent): FirebaseMessagingToken { return new FirebaseMessagingToken(id, token, platform, appVersion, user, active, created_at, updated_at, user_agent); }
    
    
}
export namespace FirebaseMessagingToken {
    //! Declares com.tresitgroup.android.tresit.model.FirebaseMessagingToken.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public forUser(userId: ForeignKey<User>): ApiFilter<FirebaseMessagingToken> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<FirebaseMessagingToken>(`user=${userId}`, undefined, (it: FirebaseMessagingToken): boolean => it.user === userId);
        }
    }
}