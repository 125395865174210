// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/ConversationAllVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { Observable } from 'rxjs'
import { unreadObs } from '../../api/Session.read'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { MessageTarget } from '../../model/MessageTarget'
import { logVG } from '../../util/LogVG'
import { xObservablePropertyPlusRx } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { ComponentRowConversationDarkXml } from '../../layout/ComponentRowConversationDarkXml'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { debounceTime as rxDebounceTime } from 'rxjs/operators'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { FlatMappedObservableProperty, xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ConversationCreateVG } from './ConversationCreateVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ChatThread } from '../../model/ChatThread'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ConversationAllXml } from '../../layout/ConversationAllXml'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.chat.ConversationAllVG
export class ConversationAllVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly onComplete:  ((a: ChatThread) => void);
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, onComplete:  ((a: ChatThread) => void), schoolFilter: ObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.onComplete = onComplete;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.name = new StandardObservableProperty<string>("", undefined);
        this.participants = new StandardObservableProperty<Array<MessageTarget>>(([] as Array<MessageTarget>), undefined);
    }
    
    
    
    public readonly name: StandardObservableProperty<string>;
    
    public readonly participants: StandardObservableProperty<Array<MessageTarget>>;
    
    public results(): FlatMappedObservableProperty<ApiFilter<ChatThread>, (Array<ChatThread> | null)> {
        return xObservablePropertyFlatMap<ApiFilter<ChatThread>, (Array<ChatThread> | null)>(xObservablePropertyCombine<string, (School | null), ApiFilter<ChatThread>>(xObservablePropertyPlusRx<string>(this.name, (it: Observable<string>): Observable<string> => it.pipe(rxDebounceTime(1000))), this.schoolFilter, (query: string, school: (School | null)): ApiFilter<ChatThread> => {
            const filters = ([] as Array<ApiFilter<ChatThread>>);
            
            if (school !== null) {
                filters.push(ChatThread.Companion.INSTANCE.forSchool(school!.id));
            }
            if (!xCharSequenceIsBlank(query)) {
                filters.push(ChatThread.Companion.INSTANCE.search(query));
            }
            filters.push(ChatThread.Companion.INSTANCE.isGroupThread());
            return ApiFilter.Companion.INSTANCE.allList<ChatThread>(filters);
        }), (filters: ApiFilter<ChatThread>): ObservableProperty<(Array<ChatThread> | null)> => this.session.threads.observableListSimple(filters, ChatThread.Companion.INSTANCE.alphabetical, 500, undefined, undefined, true));
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.ConversationAllVG.title
    public get title(): string { return "Conversation All"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ConversationAllXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Props
        const results = this.results();
        
        
        //--- Set Up xml.groupNameEntry
        xEditTextBindString(xml.groupNameEntry, this.name);
        
        //--- Set Up xml.loadingList (overwritten on flow generation)
        
        //--- Set Up xml.list
        xRecyclerViewBind<ChatThread>(xml.list, xObservablePropertyMap<(Array<ChatThread> | null), Array<ChatThread>>(results, (it: (Array<ChatThread> | null)): Array<ChatThread> => it ?? []), new ChatThread(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<ChatThread>): HTMLElement => {
            //--- Make Subview For xml.list (overwritten on flow generation)
            const cellXml = new ComponentRowConversationDarkXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.convRoot
            xViewOnClick(cellXml.convRoot, undefined, (): void => {
                this.dialog.dismiss();
                this.onComplete(observable.value);
            });
            
            //--- Set Up cellXml.unreadIndicator
            xViewBindExists(cellXml.unreadIndicator, unreadObs(this.session, xObservablePropertyMap<ChatThread, number>(observable, (it: ChatThread): number => it.id)));
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<ChatThread, string>(observable, (it: ChatThread): string => it.name ?? "-"));
            
            //--- End Make Subview For xml.list (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.create (overwritten on flow generation)
        xViewOnClick(xml.create, undefined, (): void => {
            this.createClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action createConversation
    //--- Action goToConversation
    
    public createClick(): void {
        this.dialog.swap(new ConversationCreateVG(this.onComplete, this.session, this.dialog, null, (this.schoolFilter.value?.id ?? null)));
    }
    
    //--- Body End
}

