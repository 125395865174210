// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/MessageTarget.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { User } from './User'

//! Declares com.tresitgroup.android.tresit.model.MessageTarget
export class MessageTarget {
    public readonly display: string;
    public readonly school: (School | null);
    public readonly user: (User | null);
    public constructor(display: string, school: (School | null) = null, user: (User | null) = null) {
        this.display = display;
        this.school = school;
        this.user = user;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.display);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.user);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof MessageTarget && safeEq(this.display, other.display) && safeEq(this.school, other.school) && safeEq(this.user, other.user) }
    public toString(): string { return `MessageTarget(display=${this.display}, school=${this.school}, user=${this.user})` }
    public copy(display: string = this.display, school: (School | null) = this.school, user: (User | null) = this.user): MessageTarget { return new MessageTarget(display, school, user); }
}