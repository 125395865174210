//
// DefaultLocationsListXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './default_locations_list.html'
//! Declares com.tresitgroup.android.tresit.layouts.DefaultLocationsListXml
export class DefaultLocationsListXml {
    xmlRoot!: HTMLElement
    fetchingSchools!: HTMLDivElement
    schoolList!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.fetchingSchools = getViewById<HTMLDivElement>(view, "fetching_schools")
        this.schoolList = getViewById<HTMLDivElement>(view, "school_list")
        return view
    }
}
