//
// FormAlertTypeDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FormAlertTypeXml } from './FormAlertTypeXml'
import htmlForDefault from './form_alert_type_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.FormAlertTypeDetailXml
export class FormAlertTypeDetailXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    siteSection!: HTMLDivElement
    school!: HTMLElement
    regionSection!: HTMLDivElement
    region!: HTMLElement
    form!: FormAlertTypeXml
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.siteSection = getViewById<HTMLDivElement>(view, "siteSection")
        this.school = getViewById<HTMLElement>(view, "school")
        this.regionSection = getViewById<HTMLDivElement>(view, "regionSection")
        this.region = getViewById<HTMLElement>(view, "region")
        replaceViewWithId(view, ()=>{ 
                this.form = new FormAlertTypeXml()
                return this.form.setup(dependency)
        }, "form")
        return view
    }
}
