// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/DeviceEnum.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.DeviceEnum
export class DeviceEnum {
    private constructor(name: string, jsonName: string) {
        this.name = name;
        this.jsonName = jsonName;
    }
    
    public static WINDOWS = new DeviceEnum("WINDOWS", "WINDOWS");
    
    public static MAC = new DeviceEnum("MAC", "MAC");
    
    public static IOS = new DeviceEnum("IOS", "IOS");
    
    public static ANDROID = new DeviceEnum("ANDROID", "ANDROID");
    
    public static OTHER = new DeviceEnum("OTHER", "OTHER");
    
    private static _values: Array<DeviceEnum> = [DeviceEnum.WINDOWS, DeviceEnum.MAC, DeviceEnum.IOS, DeviceEnum.ANDROID, DeviceEnum.OTHER];
    public static values(): Array<DeviceEnum> { return DeviceEnum._values; }
    public readonly name: string;
    public readonly jsonName: string;
    public static valueOf(name: string): DeviceEnum { return (DeviceEnum as any)[name]; }
    public toString(): string { return this.name }
    public toJSON(): string { return this.jsonName }
}