//
// LocationDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './location_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.LocationDetailXml
export class LocationDetailXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    close!: HTMLButtonElement
    settingLocation!: HTMLDivElement
    setLocation!: HTMLButtonElement
    settingStatus!: HTMLDivElement
    statusOptions!: HTMLDivElement
    safe!: HTMLButtonElement
    unsafe!: HTMLButtonElement
    extraButtons!: HTMLDivElement
    cleared!: HTMLButtonElement
    reset!: HTMLButtonElement
    settingMedical!: HTMLDivElement
    needMedicalAttention!: HTMLInputElement
    peopleInRoomHeader!: HTMLElement
    peopleInRoomContainer!: HTMLDivElement
    peopleInRoom!: HTMLDivElement
    movePerson!: HTMLButtonElement
    addingDefaultLocation!: HTMLDivElement
    addAsDefaultLocation!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.close = getViewById<HTMLButtonElement>(view, "close")
        this.settingLocation = getViewById<HTMLDivElement>(view, "settingLocation")
        this.setLocation = getViewById<HTMLButtonElement>(view, "setLocation")
        this.settingStatus = getViewById<HTMLDivElement>(view, "settingStatus")
        this.statusOptions = getViewById<HTMLDivElement>(view, "statusOptions")
        this.safe = getViewById<HTMLButtonElement>(view, "safe")
        this.unsafe = getViewById<HTMLButtonElement>(view, "unsafe")
        this.extraButtons = getViewById<HTMLDivElement>(view, "extra_buttons")
        this.cleared = getViewById<HTMLButtonElement>(view, "cleared")
        this.reset = getViewById<HTMLButtonElement>(view, "reset")
        this.settingMedical = getViewById<HTMLDivElement>(view, "settingMedical")
        this.needMedicalAttention = getViewById<HTMLInputElement>(view, "needMedicalAttention")
        this.peopleInRoomHeader = getViewById<HTMLElement>(view, "peopleInRoomHeader")
        this.peopleInRoomContainer = getViewById<HTMLDivElement>(view, "peopleInRoomContainer")
        this.peopleInRoom = getViewById<HTMLDivElement>(view, "peopleInRoom")
        this.movePerson = getViewById<HTMLButtonElement>(view, "movePerson")
        this.addingDefaultLocation = getViewById<HTMLDivElement>(view, "addingDefaultLocation")
        this.addAsDefaultLocation = getViewById<HTMLButtonElement>(view, "addAsDefaultLocation")
        return view
    }
}
