// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminAlertTypeAddVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { District } from '../../model/District'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { Observable, ObservableInput, SubscriptionLike, of as rxOf, zip as rxZip } from 'rxjs'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { getNO_ID } from '../../model/HasId'
import { AlertType } from '../../model/AlertType'
import { AdminAlertTypeAddXml } from '../../layout/AdminAlertTypeAddXml'
import { xActivityAccessShowToast } from '../../showToast'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { flatMap as rxFlatMap, map as rxMap } from 'rxjs/operators'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { FormAddAlertTypeVG } from './FormAddAlertTypeVG'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { AlertGuidance } from '../../model/AlertGuidance'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypeAddVG
export class AdminAlertTypeAddVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.stack = stack;
        this.selectedRegion = new StandardObservableProperty<(District | null)>(null, undefined);
        this.selectedSchool = new StandardObservableProperty<(School | null)>(null, undefined);
        this.submitting = new StandardObservableProperty<boolean>(false, undefined);
        this.guidances = new StandardObservableProperty<Array<AlertGuidance>>([], undefined);
        this.newAlert = new StandardObservableProperty<AlertType>(new AlertType(undefined, undefined, getNO_ID(), "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined);
        this.form = new FormAddAlertTypeVG(this.dialog, this.newAlert, this.guidances, this.selectedRegion, this.selectedSchool, this.session, new StandardObservableProperty<Array<ChatThread>>([], undefined), new StandardObservableProperty<Array<ChatThread>>([], undefined));
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypeAddVG.title
    public get title(): string { return "Admin Alert Type Add"; }
    
    
    public readonly selectedRegion: MutableObservableProperty<(District | null)>;
    
    public readonly selectedSchool: MutableObservableProperty<(School | null)>;
    
    public readonly submitting: MutableObservableProperty<boolean>;
    
    public readonly guidances: MutableObservableProperty<Array<AlertGuidance>>;
    
    public readonly newAlert: MutableObservableProperty<AlertType>;
    
    
    public readonly form: FormAddAlertTypeVG;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminAlertTypeAddXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.addForm.xmlRoot
        //--- Set Up xml.addForm.title
        //--- Set Up xml.addForm.schoolFilter
        //--- Set Up xml.addForm.region
        //--- Set Up xml.addForm.form.xmlRoot
        //--- Set Up xml.addForm.form.alertName
        //--- Set Up xml.addForm.form.iconImage
        //--- Set Up xml.addForm.form.iconChange
        //--- Set Up xml.addForm.form.mp3Change
        //--- Set Up xml.addForm.form.mp3Name
        //--- Set Up xml.addForm.form.oggChange
        //--- Set Up xml.addForm.form.oggName
        //--- Set Up xml.addForm.form.guidances
        //--- Make Subview For xml.addForm.form.guidances
        //--- Set Up cellXml.root
        //--- Set Up cellXml.title
        //--- Set Up cellXml.body
        //--- Set Up cellXml.remove
        //--- End Make Subview For xml.addForm.form.guidances
        //--- Set Up xml.addForm.form.newGuidance
        //--- Set Up xml.addForm.form.newTitle
        //--- Set Up xml.addForm.form.newPlan
        //--- Set Up xml.addForm.form.cancelGuidance
        //--- Set Up xml.addForm.form.saveGuidance
        //--- Set Up xml.addForm.form.addGuidance
        //--- Set Up xml.addForm.form.adminApproval
        this.form.generateFromXml(dependency, xml.addForm);
        
        //--- Set Up xml.submitWorking
        xViewFlipperBindLoading(xml.submitWorking, this.submitting, undefined);
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.submit (overwritten on flow generation)
        xViewOnClick(xml.submit, undefined, (): void => {
            this.submitClick(dependency);
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cellXmlRemoveClick(): void {
    }
    //--- Action addFormFormCancelGuidanceClick
    //--- Action addFormFormSaveGuidanceClick
    //--- Action addFormFormAddGuidanceClick
    
    public cancelClick(): void {
        this.stack.pop();
    }
    
    public submitClick(dependency: Window): void {
        let action: (Observable<void> | null) = ((): (Observable<void> | null) => {
            const temp3324 = this.selectedRegion.value;
            if(temp3324 === null) { return null }
            return ((region: District): Observable<void> => this.session.schools
                    .getListSimple(School.Companion.INSTANCE.byDistrict(region.id), undefined, undefined, undefined, undefined).pipe(rxFlatMap((it: Array<School>): ObservableInput<Array<any>> => rxZip(...it.map((school: School): Observable<any> => this.session.alertTypes
                        .post(this.newAlert.value.copy(undefined, undefined, school.id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)).pipe(rxFlatMap((alertType: AlertType): ObservableInput<any> => {
                        const submissions: Array<Observable<void>> = [];
                        
                        
                        const image_3334 = this.form.form.iconActual.value;
                        if (image_3334 !== null) {
                            submissions.push(this.session.rawApi.uploadAlertIcon(this.session.session, alertType.id, image_3334).pipe(rxMap((it: AlertType): void => {
                                return undefined;
                            })));
                        }
                        const uri_3338 = this.form.form.mp3.value;
                        if (uri_3338 !== null) {
                            submissions.push(this.session.rawApi.uploadAlertMp3(this.session.session, alertType.id, uri_3338).pipe(rxMap((it: AlertType): void => {
                                return undefined;
                            })));
                        }
                        const uri_3342 = this.form.form.ogg.value;
                        if (uri_3342 !== null) {
                            submissions.push(this.session.rawApi.uploadAlertOgg(this.session.session, alertType.id, uri_3342).pipe(rxMap((it: AlertType): void => {
                                return undefined;
                            })));
                        }
                        for (const guidance of this.guidances.value) {
                            submissions.push(this.session.alertGuidances.post(guidance.copy(undefined, alertType.id, undefined, undefined)).pipe(rxMap((it: AlertGuidance): void => {
                                return undefined;
                            })));
                        }
                        
                        return ((): Observable<any> => {
                            if (submissions.length === 0) {
                                return rxOf(undefined);
                            } else {
                                return rxZip(...submissions);
                            }
                        })()
                })))))).pipe(rxMap((it: Array<any>): void => {
                    return undefined;
            })))(temp3324)
        })() ?? ((): (Observable<void> | null) => {
            const temp3350 = this.selectedSchool.value;
            if(temp3350 === null) { return null }
            return ((school: School): Observable<void> => this.session.alertTypes
                    .post(this.newAlert.value.copy(undefined, undefined, school.id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)).pipe(rxFlatMap((alertType: AlertType): ObservableInput<any> => {
                    const submissions: Array<Observable<void>> = [];
                    
                    
                    const image_3356 = this.form.form.iconActual.value;
                    if (image_3356 !== null) {
                        submissions.push(this.session.rawApi.uploadAlertIcon(this.session.session, alertType.id, image_3356).pipe(rxMap((it: AlertType): void => {
                            return undefined;
                        })));
                    }
                    const uri_3360 = this.form.form.mp3.value;
                    if (uri_3360 !== null) {
                        submissions.push(this.session.rawApi.uploadAlertMp3(this.session.session, alertType.id, uri_3360).pipe(rxMap((it: AlertType): void => {
                            return undefined;
                        })));
                    }
                    const uri_3364 = this.form.form.ogg.value;
                    if (uri_3364 !== null) {
                        submissions.push(this.session.rawApi.uploadAlertOgg(this.session.session, alertType.id, uri_3364).pipe(rxMap((it: AlertType): void => {
                            return undefined;
                        })));
                    }
                    for (const guidance of this.guidances.value) {
                        submissions.push(this.session.alertGuidances.post(guidance.copy(undefined, alertType.id, undefined, undefined)).pipe(rxMap((it: AlertGuidance): void => {
                            return undefined;
                        })));
                    }
                    
                    return ((): Observable<any> => {
                        if (submissions.length === 0) {
                            return rxOf(undefined);
                        } else {
                            return rxZip(...submissions);
                        }
                    })()
                })).pipe(rxMap((it: any): void => {
                    return undefined;
            })))(temp3350)
        })();
        
        
        const temp3372 = ((): (SubscriptionLike | null) => {
            const temp3373 = ((): (Observable<void> | null) => {
                const temp3374 = action;
                if(temp3374 !== null) {
                    return xSingleWorking<void>(temp3374, this.submitting)
                } else { return null }
            })();
            if(temp3373 !== null) {
                return xSingleCallDisplayingError<void>(temp3373, undefined, (it: void): void => {
                    xActivityAccessShowToast(dependency, "New alert-type saved.");
                    this.stack.pop();
                })
            } else { return null }
        })();
        if(temp3372 !== null) {
            xDisposableForever<SubscriptionLike>(temp3372)
        };
    }
    
    //--- Body End
}
