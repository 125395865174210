// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/CheckAlertLocationResponse.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.CheckAlertLocationResponse
export class CheckAlertLocationResponse implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly hasAlertLocation: boolean;
    public constructor(id: number = getNO_ID(), hasAlertLocation: boolean = false) {
        this.id = id;
        this.hasAlertLocation = hasAlertLocation;
    }
    public static fromJson(obj: any): CheckAlertLocationResponse { return new CheckAlertLocationResponse(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["has_alertlocation"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        id: this.id,
        has_alertlocation: this.hasAlertLocation
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.hasAlertLocation);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof CheckAlertLocationResponse && safeEq(this.id, other.id) && safeEq(this.hasAlertLocation, other.hasAlertLocation) }
    public toString(): string { return `CheckAlertLocationResponse(id=${this.id}, hasAlertLocation=${this.hasAlertLocation})` }
    public copy(id: number = this.id, hasAlertLocation: boolean = this.hasAlertLocation): CheckAlertLocationResponse { return new CheckAlertLocationResponse(id, hasAlertLocation); }
}