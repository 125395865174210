// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Alert.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { PoliceStatus } from './PoliceStatus'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { R } from '../R'
import { Time } from '../util/time'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { AlertType } from './AlertType'
import { HasId, getNO_ID } from './HasId'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ApiModifier } from '../api/ApiModifier'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Alert
export class Alert implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly school: ForeignKey<School>;
    public readonly name: (ForeignKey<AlertType> | null);
    public readonly created: Date;
    public readonly initiator: (ForeignKey<User> | null);
    public readonly policeStatus: PoliceStatus;
    public readonly scoped: boolean;
    public readonly silent: boolean;
    public readonly internal: boolean;
    public readonly pendingApproval: boolean;
    public constructor(id: number = getNO_ID(), school: ForeignKey<School>, name: (ForeignKey<AlertType> | null) = null, created: Date = Time.INSTANCE.date(), initiator: (ForeignKey<User> | null) = null, policeStatus: PoliceStatus = PoliceStatus.NotNotified, scoped: boolean = false, silent: boolean = false, internal: boolean = false, pendingApproval: boolean = false) {
        this.id = id;
        this.school = school;
        this.name = name;
        this.created = created;
        this.initiator = initiator;
        this.policeStatus = policeStatus;
        this.scoped = scoped;
        this.silent = silent;
        this.internal = internal;
        this.pendingApproval = pendingApproval;
    }
    public static fromJson(obj: any): Alert { return new Alert(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as number,
        parseJsonTyped(obj["name"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["initiator"], [Number]) as (number | null),
        parseJsonTyped(obj["police_status"], [PoliceStatus]) as PoliceStatus,
        parseJsonTyped(obj["scoped"], [Boolean]) as boolean,
        parseJsonTyped(obj["silent"], [Boolean]) as boolean,
        parseJsonTyped(obj["internal"], [Boolean]) as boolean,
        parseJsonTyped(obj["pending_approval"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        id: this.id,
        school: this.school,
        name: this.name,
        created: this.created,
        initiator: this.initiator,
        police_status: this.policeStatus,
        scoped: this.scoped,
        silent: this.silent,
        internal: this.internal,
        pending_approval: this.pendingApproval
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.initiator);
        hash = 31 * hash + hashAnything(this.policeStatus);
        hash = 31 * hash + hashAnything(this.scoped);
        hash = 31 * hash + hashAnything(this.silent);
        hash = 31 * hash + hashAnything(this.internal);
        hash = 31 * hash + hashAnything(this.pendingApproval);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Alert && safeEq(this.id, other.id) && safeEq(this.school, other.school) && safeEq(this.name, other.name) && safeEq(this.created, other.created) && safeEq(this.initiator, other.initiator) && safeEq(this.policeStatus, other.policeStatus) && safeEq(this.scoped, other.scoped) && safeEq(this.silent, other.silent) && safeEq(this.internal, other.internal) && safeEq(this.pendingApproval, other.pendingApproval) }
    public toString(): string { return `Alert(id=${this.id}, school=${this.school}, name=${this.name}, created=${this.created}, initiator=${this.initiator}, policeStatus=${this.policeStatus}, scoped=${this.scoped}, silent=${this.silent}, internal=${this.internal}, pendingApproval=${this.pendingApproval})` }
    public copy(id: number = this.id, school: ForeignKey<School> = this.school, name: (ForeignKey<AlertType> | null) = this.name, created: Date = this.created, initiator: (ForeignKey<User> | null) = this.initiator, policeStatus: PoliceStatus = this.policeStatus, scoped: boolean = this.scoped, silent: boolean = this.silent, internal: boolean = this.internal, pendingApproval: boolean = this.pendingApproval): Alert { return new Alert(id, school, name, created, initiator, policeStatus, scoped, silent, internal, pendingApproval); }
    
    //! Declares com.tresitgroup.android.tresit.model.Alert.drill
    public get drill(): boolean { return this.internal; }
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.model.Alert.colorResource
    public get colorResource(): string { return this.drill ? R.color.drill : R.color.unsafe; }
    
    //! Declares com.tresitgroup.android.tresit.model.Alert.colorResourceDark
    public get colorResourceDark(): string { return this.drill ? R.color.drillDark : R.color.unsafeDark; }
    
}
export namespace Alert {
    //! Declares com.tresitgroup.android.tresit.model.Alert.Companion
    export class Companion {
        private constructor() {
            this.createdSort = ApiSort.constructorStringComparatorHasId<Alert>("sort=created", (o1: Alert, o2: Alert): number => safeCompare(o1.created, o2.created));
        }
        public static INSTANCE = new Companion();
        
        public readonly createdSort: ApiSort<Alert>;
        
        
        public forSchool(school: ForeignKey<School>): ApiFilter<Alert> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Alert>(`school=${school}`, undefined, (it: Alert): boolean => it.school === school);
        }
        
        public isNotDrill(): ApiFilter<Alert> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Alert>("internal=false", undefined, (it: Alert): boolean => (!it.internal));
        }
        
        public setPoliceStatus(status: PoliceStatus): ApiModifier<Alert> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<Alert>("police_status", status, (it: Alert): Alert => it.copy(undefined, undefined, undefined, undefined, undefined, status, undefined, undefined, undefined, undefined));
        }
        
        public setPendingApproval(pending: boolean = false): ApiModifier<Alert> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<Alert>("pending_approval", pending, (it: Alert): Alert => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, pending));
        }
    }
}