// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/LoginActivity.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { Time } from '../util/time'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { User } from './User'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'
import { ApiSort } from '../api/ApiSort'

//! Declares com.tresitgroup.android.tresit.model.LoginActivity
export class LoginActivity implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly device: string;
    public readonly createdAt: Date;
    public readonly updatedAt: Date;
    public readonly user: ForeignKey<User>;
    public readonly ip: string;
    public constructor(id: number = getNO_ID(), device: string = "Android", createdAt: Date = Time.INSTANCE.date(), updatedAt: Date = Time.INSTANCE.date(), user: ForeignKey<User>, ip: string = "") {
        this.id = id;
        this.device = device;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.user = user;
        this.ip = ip;
    }
    public static fromJson(obj: any): LoginActivity { return new LoginActivity(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["device"], [String]) as string,
        parseJsonTyped(obj["created_at"], [Date]) as Date,
        parseJsonTyped(obj["updated_at"], [Date]) as Date,
        parseJsonTyped(obj["user"], [Number]) as number,
        parseJsonTyped(obj["ip"], [String]) as string
    ) }
    public toJSON(): object { return {
        id: this.id,
        device: this.device,
        created_at: this.createdAt,
        updated_at: this.updatedAt,
        user: this.user,
        ip: this.ip
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.device);
        hash = 31 * hash + hashAnything(this.createdAt);
        hash = 31 * hash + hashAnything(this.updatedAt);
        hash = 31 * hash + hashAnything(this.user);
        hash = 31 * hash + hashAnything(this.ip);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof LoginActivity && safeEq(this.id, other.id) && safeEq(this.device, other.device) && safeEq(this.createdAt, other.createdAt) && safeEq(this.updatedAt, other.updatedAt) && safeEq(this.user, other.user) && safeEq(this.ip, other.ip) }
    public toString(): string { return `LoginActivity(id=${this.id}, device=${this.device}, createdAt=${this.createdAt}, updatedAt=${this.updatedAt}, user=${this.user}, ip=${this.ip})` }
    public copy(id: number = this.id, device: string = this.device, createdAt: Date = this.createdAt, updatedAt: Date = this.updatedAt, user: ForeignKey<User> = this.user, ip: string = this.ip): LoginActivity { return new LoginActivity(id, device, createdAt, updatedAt, user, ip); }
    
    
}
export namespace LoginActivity {
    //! Declares com.tresitgroup.android.tresit.model.LoginActivity.Companion
    export class Companion {
        private constructor() {
            this.mostRecent = ApiSort.constructorStringComparatorHasId<LoginActivity>("ordering=-updated_at", (a: LoginActivity, b: LoginActivity): number => (-safeCompare(a.createdAt, b.createdAt)));
        }
        public static INSTANCE = new Companion();
        
        public readonly mostRecent: ApiSort<LoginActivity>;
        
        
        public forUser(userId: ForeignKey<User>): ApiFilter<LoginActivity> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<LoginActivity>(`user=${userId}`, undefined, (it: LoginActivity): boolean => it.user === userId);
        }
    }
}