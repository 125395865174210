// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiFilter.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { HasId } from '../model/HasId'
import { hashAnything, safeEq, takeUnless } from 'butterfly-web/dist/Kotlin'
import { MockDatabase } from './MockDatabase'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'

//! Declares com.tresitgroup.android.tresit.api.ApiFilter
export class ApiFilter<T extends HasId> {
    public readonly params: string;
    public constructor(params: string) {
        this.params = params;
        this.mockFilter = null;
        this.filter = (it: T): boolean => true;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.params);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ApiFilter && safeEq(this.params, other.params) }
    public toString(): string { return `ApiFilter(params=${this.params})` }
    public copy(params: string = this.params): ApiFilter<T> { return new ApiFilter(params); }
    
    public mockFilter: (((a: MockDatabase, b: T) => boolean) | null);
    
    public filter: ((a: T) => boolean);
    
    
    public static constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<T extends HasId>(
        params: string,
        mockFilter: (((a: MockDatabase, b: T) => boolean) | null) = null,
        filter:  ((a: T) => boolean) = (it: T): boolean => true
    ) {
        let result = new ApiFilter<T>(params);
        
        result.mockFilter = mockFilter
        result.filter = filter
        
        return result;
    }
    
    
}
export namespace ApiFilter {
    //! Declares com.tresitgroup.android.tresit.api.ApiFilter.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public always<T extends HasId>(): ApiFilter<T> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<T>("", undefined, (it: T): boolean => true);
        }
        
        public all<T extends HasId>(...filters: ApiFilter<T>[]): ApiFilter<T> {
            return this.allList<T>(iterToArray(filters));
        }
        
        public allList<T extends HasId>(filters: Array<ApiFilter<T>>): ApiFilter<T> {
            const compositeNormalFilter = (it: T): boolean => filters.every((f: ApiFilter<T>): boolean => f.filter(it));
            
            const compositeDbFilter = ((): (((a: MockDatabase, b: T) => boolean) | null) => {
                const temp1 = takeUnless(iterToArray(iterableFilterNotNull(iterMap(filters, (it: ApiFilter<T>): (((a: MockDatabase, b: T) => boolean) | null) => it.mockFilter))), (it: Array<(a: MockDatabase, b: T) => boolean>): boolean => it.length === 0);
                if(temp1 === null) { return null }
                return ((filters: Array<(a: MockDatabase, b: T) => boolean>): (a: MockDatabase, b: T) => boolean => (db: MockDatabase, it: T): boolean => filters.every((f: (a: MockDatabase, b: T) => boolean): boolean => f(db, it)))(temp1)
            })();
            
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<T>(filters.map((it: ApiFilter<T>): string => it.params).join("&"), compositeDbFilter, compositeNormalFilter);
        }
    }
}