//
// ComponentChatAttachmentPreviewXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_chat_attachment_preview.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentChatAttachmentPreviewXml
export class ComponentChatAttachmentPreviewXml {
    xmlRoot!: HTMLElement
    image!: HTMLImageElement
    remove!: HTMLButtonElement
    fileName!: HTMLElement
    progress!: HTMLProgressElement
    error!: HTMLImageElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.image = getViewById<HTMLImageElement>(view, "image")
        this.remove = getViewById<HTMLButtonElement>(view, "remove")
        this.fileName = getViewById<HTMLElement>(view, "fileName")
        this.progress = getViewById<HTMLProgressElement>(view, "progress")
        this.error = getViewById<HTMLImageElement>(view, "error")
        return view
    }
}
