// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/board/BoardVG.kt
// Package: com.tresitgroup.android.tresit.vg.board
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewString, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { CacheEdge } from '../../api/CacheEdge'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { xRecyclerViewBindMulti } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { BoardPost } from '../../model/BoardPost'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { BoardXml } from '../../layout/BoardXml'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { RowPostVG } from './RowPostVG'
import { FlatMappedObservableProperty, xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { PostCreateVG } from './PostCreateVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ComponentRowPostHeaderXml } from '../../layout/ComponentRowPostHeaderXml'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.board.BoardVG
export class BoardVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: ObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.textFilter = new StandardObservableProperty<string>("", undefined);
    }
    
    
    
    public readonly textFilter: StandardObservableProperty<string>;
    
    public activeAlerts(): ObservableProperty< (Array<Alert> | null)> {
        return xObservablePropertyFlatMap<(School | null), (Array<Alert> | null)>(this.schoolFilter, (it: (School | null)): ObservableProperty<(Array<Alert> | null)> => ((): (CacheEdge<Alert> | null) => {
            const temp4144 = it;
            if(temp4144 === null) { return null }
            return ((it: School): CacheEdge<Alert> => this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(it.id), undefined, undefined, undefined, undefined, undefined))(temp4144)
        })() ?? this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined));
    }
    public currentFilter(activeAlerts: ObservableProperty<(Array<Alert> | null)>): ObservableProperty<Array<ApiFilter<BoardPost>>> {
        return xObservablePropertyCombine<string, (Array<Alert> | null), Array<ApiFilter<BoardPost>>>(this.textFilter, activeAlerts, (a: string, alerts: (Array<Alert> | null)): Array<ApiFilter<BoardPost>> => {
            const filters = ([] as Array<ApiFilter<BoardPost>>);
            
            if (!xCharSequenceIsBlank(a)) {
                filters.push(BoardPost.Companion.INSTANCE.textSearch(a));
            }
            filters.push(BoardPost.Companion.INSTANCE.forAlerts((alerts ?? []).map((it: Alert): number => it.id)));
            return filters;
        });
    }
    public allPosts(currentFilter: ObservableProperty<Array<ApiFilter<BoardPost>>>): FlatMappedObservableProperty<Array<ApiFilter<BoardPost>>, (Array<BoardPost> | null)> {
        return xObservablePropertyFlatMap<Array<ApiFilter<BoardPost>>, (Array<BoardPost> | null)>(currentFilter, (filters: Array<ApiFilter<BoardPost>>): ObservableProperty<(Array<BoardPost> | null)> => this.session.posts.observableListSimple(ApiFilter.Companion.INSTANCE.allList<BoardPost>(filters), BoardPost.Companion.INSTANCE.createdSort, 1000, undefined, undefined, undefined));
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.board.BoardVG.title
    public get title(): string { return "Board"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new BoardXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shorcuts
        const activeAlerts = this.activeAlerts();
        
        const currentFilter = this.currentFilter(activeAlerts);
        
        const allPosts = this.allPosts(currentFilter);
        
        
        const data = xObservablePropertyMap<(Array<BoardPost> | null), Array<BoardVG.HeaderOrEntry>>(allPosts, (it: (Array<BoardPost> | null)): Array<BoardVG.HeaderOrEntry> => {
            if (it === null) { return ([] as Array<BoardVG.HeaderOrEntry>) }
            const active = it!.filter((it: BoardPost): boolean => it.resolvedAt === null);
            
            const resolved = it!.filter((it: BoardPost): boolean => it.resolvedAt !== null);
            
            const result = ([] as Array<BoardVG.HeaderOrEntry>);
            
            result.push(new BoardVG.HeaderOrEntry(new ViewStringResource(R._string.missing), undefined, (): void => {
                this.addPostClick();
            }));
            for (const it of active) {
                result.push(new BoardVG.HeaderOrEntry(undefined, it, undefined));
            }
            result.push(new BoardVG.HeaderOrEntry(new ViewStringResource(R._string.found), undefined, undefined));
            for (const it of resolved) {
                result.push(new BoardVG.HeaderOrEntry(undefined, it, undefined));
            }
            const typeBlocked: Array<BoardVG.HeaderOrEntry> = result;
            
            return typeBlocked;
        });
        
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.textFilter);
        
        //--- Set Up xml.postsLoading
        xViewFlipperBindLoading(xml.postsLoading, xObservablePropertyMap<(Array<BoardPost> | null), boolean>(allPosts, (it: (Array<BoardPost> | null)): boolean => it === null), undefined);
        
        //--- Set Up xml.posts
        xViewBindExists(xml.posts, xObservablePropertyMap<(Array<Alert> | null), boolean>(activeAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4157 = it;
            if(temp4157 === null) { return null }
            return temp4157.length !== 0
        })() === true));
        xRecyclerViewBindMulti<BoardVG.HeaderOrEntry>(xml.posts, data, new BoardVG.HeaderOrEntry(undefined, undefined, undefined), (it: BoardVG.HeaderOrEntry): number => it.entry !== null ? 0 : 1, (type: number, observable: ObservableProperty<BoardVG.HeaderOrEntry>): HTMLElement => ((): HTMLElement => {
            if (type === 0) {
                //--- Make Subview For xml.posts
                const cellVg = new RowPostVG(this.dialog, xObservablePropertyMap<BoardVG.HeaderOrEntry, BoardPost>(observable, (it: BoardVG.HeaderOrEntry): BoardPost => it.entry ?? new BoardPost(undefined, undefined, undefined, undefined, undefined, (-1), (-1), undefined, undefined, undefined, undefined, undefined)), this.session);
                
                const cellView = cellVg.generate(dependency);
                
                //--- End Make Subview For xml.posts
                return cellView;
            } else {
                //--- Make Subview For xml.posts
                const cellXml = new ComponentRowPostHeaderXml();
                
                const cellView = cellXml.setup(dependency);
                
                
                xTextViewBindString(cellXml.title, xObservablePropertyMap<BoardVG.HeaderOrEntry, string>(observable, (it: BoardVG.HeaderOrEntry): string => (it.headerString?.get(dependency) ?? null) ?? ""));
                xViewBindExists(cellXml.action, xObservablePropertyMap<BoardVG.HeaderOrEntry, boolean>(observable, (it: BoardVG.HeaderOrEntry): boolean => it.action !== null));
                xViewOnClick(cellXml.action, undefined, (): void => {
                    const it_4158 = observable.value.action;
                    if (it_4158 !== null) {
                        it_4158();
                    }
                });
                
                //--- End Make Subview For xml.posts
                return cellView;
            }
        })());
        
        //--- Set Up xml.mainContent
        xViewBindExists(xml.mainContent, xObservablePropertyMap<(Array<Alert> | null), boolean>(activeAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4160 = it;
            if(temp4160 === null) { return null }
            return temp4160.length !== 0
        })() === true));
        
        //--- Set Up xml.empty
        xViewBindExists(xml.empty, xObservablePropertyMap<(Array<Alert> | null), boolean>(activeAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4162 = it;
            if(temp4162 === null) { return null }
            return temp4162.length === 0
        })() === true));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public addPostClick(): void {
        this.dialog.push(new PostCreateVG(undefined, (this.schoolFilter.value?.id ?? null), this.session, this.dialog));
    }
    
    //--- Body End
    
}
export namespace BoardVG {
    //! Declares com.tresitgroup.android.tresit.vg.board.BoardVG.HeaderOrEntry
    export class HeaderOrEntry {
        public readonly headerString: (ViewString | null);
        public readonly entry: (BoardPost | null);
        public readonly action: ((() => void) | null);
        public constructor(headerString: (ViewString | null) = null, entry: (BoardPost | null) = null, action: ((() => void) | null) = null) {
            this.headerString = headerString;
            this.entry = entry;
            this.action = action;
        }
    }
}
