//
// ComponentRowAlertThreadXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/component_row_alert_thread.html'
import htmlForDefault from './component_row_alert_thread.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowAlertThreadXml
export class ComponentRowAlertThreadXml {
    xmlRoot!: HTMLElement
    root: HTMLDivElement | null = null
    threadName!: HTMLElement
    timestamp!: HTMLElement
    schoolName!: HTMLElement
    alertTypeIcon: HTMLImageElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.root = findViewById<HTMLDivElement>(view, "root")
        this.threadName = getViewById<HTMLElement>(view, "threadName")
        this.timestamp = getViewById<HTMLElement>(view, "timestamp")
        this.schoolName = getViewById<HTMLElement>(view, "schoolName")
        this.alertTypeIcon = findViewById<HTMLImageElement>(view, "alert_type_icon")
        return view
    }
}
