// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/time.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.

//! Declares com.tresitgroup.android.tresit.util.Time
export class Time {
    private constructor() {
        this.provider = (): number => Date.now();
    }
    public static INSTANCE = new Time();
    
    public provider: (() => number);
    
    epochMilliseconds(): number {
        return this.provider();
    }
    date(): Date {
        return new Date();
    }
}