// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/approval/RequiresApprovalDescriptionVG.kt
// Package: com.tresitgroup.android.tresit.vg.approval
// Generated by Khrysalis - this file will be overwritten.
import { xActivityAccessShowToast } from '../../showToast'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { DescriptionDialogXml } from '../../layout/DescriptionDialogXml'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { User } from '../../model/User'
import { Alert } from '../../model/Alert'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { ApiModifier } from '../../api/ApiModifier'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { PinVG } from '../PinVG'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.approval.RequiresApprovalDescriptionVG
export class RequiresApprovalDescriptionVG extends ViewGenerator implements HasBackAction, PinVG {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public static implementsInterfaceComTresitgroupAndroidTresitVgPinVG = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly alert: Alert;
    public readonly alertStack: ObservableStack<ViewGenerator>;
    public readonly changes: (Array<ApiModifier<Alert>> | null);
    public readonly schoolId: ForeignKey<School>;
    public readonly policeStatus: string;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, alert: Alert, alertStack: ObservableStack<ViewGenerator>, changes: (Array<ApiModifier<Alert>> | null) = null, schoolId: ForeignKey<School>, policeStatus: string) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.alert = alert;
        this.alertStack = alertStack;
        this.changes = changes;
        this.schoolId = schoolId;
        this.policeStatus = policeStatus;
        this.isLoading = new StandardObservableProperty<boolean>(false, undefined);
        this.description = new StandardObservableProperty<string>("", undefined);
    }
    
    public readonly isLoading: StandardObservableProperty<boolean>;
    
    
    
    public onBackPressed(): boolean {
        return true;
    }
    
    public readonly description: StandardObservableProperty<string>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new DescriptionDialogXml();
        
        const view = xml.setup(dependency);
        
        xEditTextBindString(xml.description, this.description);
        xViewFlipperBindLoading(xml.submitWorking, this.isLoading, undefined);
        xViewOnClick(xml.submit, undefined, (): void => {
            xSingleWorking<void>(this.session.rawApi.requestAdminAlert(this.session.session, this.alert.school, ((): (number | null) => {
                        const temp4126 = this.alert.name;
                        if(temp4126 === null) { return null }
                        return temp4126
                })()!!, this.alert.created.toString(), ((): (number | null) => {
                    const temp4127 = this.alert.initiator;
                    if(temp4127 === null) { return null }
                    return temp4127
            })()!!, this.policeStatus, this.alert.scoped, this.alert.silent, this.alert.internal, this.description.value), this.isLoading).subscribe((it: void): void => {
                    const isAdmin = xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionAdmin ?? null) === true);
                    
                    if (isAdmin.value) {
                        
                    } else {
                        xActivityAccessShowToast(dependency, "Admin Alert request has been sent. You will be notified soon.");
                    }
                    this.dialog.dismiss();
                    this.alertStack.pop();
                }, (it: any): void => {
                    showDialogAlert(new ViewStringResource(R._string.admin_alert_not_created));
                    this.dialog.dismiss();
                    this.alertStack.pop();
            });
        });
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Action pinClick
    
    //--- Action verifyPinClick
    
    //--- Body End
}
