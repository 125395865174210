//
// ConversationCreateXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './conversation_create.html'
//! Declares com.tresitgroup.android.tresit.layouts.ConversationCreateXml
export class ConversationCreateXml {
    xmlRoot!: HTMLElement
    groupNameEntry!: HTMLInputElement
    summmary!: HTMLElement
    filter!: HTMLInputElement
    recipients!: HTMLDivElement
    cancelMessage!: HTMLButtonElement
    post!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.groupNameEntry = getViewById<HTMLInputElement>(view, "groupNameEntry")
        this.summmary = getViewById<HTMLElement>(view, "summmary")
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.recipients = getViewById<HTMLDivElement>(view, "recipients")
        this.cancelMessage = getViewById<HTMLButtonElement>(view, "cancelMessage")
        this.post = getViewById<HTMLButtonElement>(view, "post")
        return view
    }
}
