// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ConcreteMockApiTables.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { Session } from '../model/Session'
import { User } from '../model/User'
import { Observable, of as rxOf } from 'rxjs'
import { getTotalRequestsMade, setTotalRequestsMade } from './apiProperty'
import { MockTable } from './MockTable'
import { MockDatabase } from './MockDatabase'
import { runOrNull, safeEq } from 'butterfly-web/dist/kotlin/Language'
import { filter as iterFilter, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { ChatThread } from '../model/ChatThread'
import { MockApiTable } from './MockApiTable'
import { ChatMessage } from '../model/ChatMessage'
import { ForeignKey } from '../model/ForeignKey'
import { UserSchoolMembership } from '../model/UserSchoolMembership'
import { cMax } from 'butterfly-web/dist/kotlin/Comparable'

//! Declares com.tresitgroup.android.tresit.api.ThreadTable
export class ThreadTable extends MockApiTable<ChatThread> {
    public constructor(session: Session, parent: MockDatabase, table: MockTable<ChatThread>) {
        super(session, parent, table);
    }
    
    public getImmediate(id: ForeignKey<ChatThread>): (ChatThread | null) {
        const schools = iterToArray(iterFilter(this.database.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === this.session.userId)).map((it: UserSchoolMembership): number => it.school);
        
        const thread_264 = super.getImmediate(id);
        if (thread_264 !== null) {
            if (thread_264.userIds.some((x) => safeEq(this.session.userId, x)) || thread_264.schools.some((it: number): boolean => thread_264.schools.some((x) => safeEq(it, x)))) { return thread_264 }
        }
        return null;
    }
    
    public listImmediate(): Array<ChatThread> {
        const schools = iterToArray(iterFilter(this.database.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === this.session.userId)).map((it: UserSchoolMembership): number => it.school);
        
        return super.listImmediate().filter((thread: ChatThread): boolean => thread.userIds.some((x) => safeEq(this.session.userId, x)) || thread.schools.some((it: number): boolean => schools.some((x) => safeEq(it, x))));
    }
}

//! Declares com.tresitgroup.android.tresit.api.MessagesTable
export class MessagesTable extends MockApiTable<ChatMessage> {
    public constructor(session: Session, parent: MockDatabase, table: MockTable<ChatMessage>) {
        super(session, parent, table);
    }
    
    public post(value: ChatMessage): Observable<ChatMessage> {
        const it_283 = (this.database.threads.objects.get(value.thread) ?? null);
        if (it_283 !== null) {
            const lastUpdated_286 = it_283.lastUpdated;
            if (lastUpdated_286 !== null) {
                this.database.threads.replaceItem(it_283.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, cMax(lastUpdated_286, value.created), undefined, undefined, undefined, undefined));
            }
        }
        return super.post(value);
    }
}
//! Declares com.tresitgroup.android.tresit.api.UserTable
export class UserTable extends MockApiTable<User> {
    public constructor(session: Session, database: MockDatabase, table: MockTable<User>) {
        super(session, database, table);
    }
    
    public post(value: User): Observable<User> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} to post ${value}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        this.table.addItem(value);
        this.database.userPasswords.set(value.id, value.password ?? "");
        return rxOf(value);
    }
}