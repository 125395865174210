// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/svg/Point2D.kt
// Package: com.tresitgroup.android.tresit.svg
// Generated by Khrysalis - this file will be overwritten.
import { NumberRange, hashAnything, parseFloatOrNull, safeEq } from 'butterfly-web/dist/Kotlin'
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { xCharSequenceIsBlank, xStringSubstringAfter, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { xIterableJoinToString } from 'butterfly-web/dist/kotlin/Iterables'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { PointF } from 'butterfly-web/dist/views/geometry/PointF'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.svg.Point
export class Point {
    public readonly x: number;
    public readonly y: number;
    public constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
    public static fromJson(obj: any): Point { return new Point(
        parseJsonTyped(obj["x"], [Number]) as number,
        parseJsonTyped(obj["y"], [Number]) as number
    ) }
    public toJSON(): object { return {
        x: this.x,
        y: this.y
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.x);
        hash = 31 * hash + hashAnything(this.y);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Point && safeEq(this.x, other.x) && safeEq(this.y, other.y) }
    public toString(): string { return `Point(x=${this.x}, y=${this.y})` }
    public copy(x: number = this.x, y: number = this.y): Point { return new Point(x, y); }
    
    public toPointF(): PointF {
        return new PointF(this.x, this.y);
    }
}

//! Declares com.tresitgroup.android.tresit.svg.toPoint2D>android.graphics.PointF
export function xPointFToPoint2D(this_: PointF): Point {
    return new Point(this_.x, this_.y);
}

//! Declares com.tresitgroup.android.tresit.svg.Matrix2D
export class Matrix2D {
    public readonly values: Array<number>;
    public constructor(values: Array<number> = ([1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0] as Array<number>)) {
        this.values = values;
    }
    
    
    
    
    public assuredGet(input: number, output: number): number {
        return this.values[output * Matrix2D.Companion.INSTANCE.inputSize + input];
    }
    
    public assuredSet(input: number, output: number, value: number): void {
        this.values[output * Matrix2D.Companion.INSTANCE.inputSize + input] = value;
    }
    
    public forEachIndexed(action: ((input: number, output: number, value: number) => void)): void {
        for (const input of (new NumberRange(0, Matrix2D.Companion.INSTANCE.inputSize - 1))) {
            for (const output of (new NumberRange(0, Matrix2D.Companion.INSTANCE.outputSize - 1))) {
                action(input, output, this.assuredGet(input, output));
            }
        }
    }
    
    public times(other: Matrix2D): Matrix2D {
        const newMatrix = new Matrix2D(undefined);
        
        for (const input of (new NumberRange(0, Matrix2D.Companion.INSTANCE.inputSize - 1))) {
            for (const output of (new NumberRange(0, Matrix2D.Companion.INSTANCE.outputSize - 1))) {
                let sum = 0.0;
                
                for (const cursor of (new NumberRange(0, Matrix2D.Companion.INSTANCE.outputSize - 1))) {
                    sum = sum + this.assuredGet(input, cursor) * other.assuredGet(cursor, output);
                }
                newMatrix.assuredSet(input, output, sum);
            }
        }
        return newMatrix;
    }
    
    public before(other: Matrix2D): Matrix2D {
        return other.times(this);
    }
    public after(other: Matrix2D): Matrix2D {
        return this.times(other);
    }
    
    public transform(values: Point): Point {
        return new Point(this.assuredGet(0, 0) * values.x + this.assuredGet(1, 0) * values.y + this.assuredGet(2, 0), this.assuredGet(0, 1) * values.x + this.assuredGet(1, 1) * values.y + this.assuredGet(2, 1));
    }
    
    public transformPointF(values: PointF): PointF {
        return new PointF((this.assuredGet(0, 0) * values.x + this.assuredGet(1, 0) * values.y + this.assuredGet(2, 0)), (this.assuredGet(0, 1) * values.x + this.assuredGet(1, 1) * values.y + this.assuredGet(2, 1)));
    }
    
    public timesScalar(scale: number): Matrix2D {
        const m = new Matrix2D(undefined);
        
        for (const i of new NumberRange(0, m.values.length-1)) {
            m.values[i] = this.values[i] * scale;
        }
        return m;
    }
    
    public toTraditional(): DOMMatrix {
        const mat = new DOMMatrix();
        
        mat.a = this.values[0]; mat.b = this.values[1]; mat.c = this.values[2]; mat.d = this.values[3]; mat.e = this.values[4]; mat.f = this.values[5];
        return mat;
    }
    
    public toString(): string {
        return xIterableJoinToString(this.values, undefined, undefined, undefined, undefined, undefined, undefined);
    }
}
export namespace Matrix2D {
    //! Declares com.tresitgroup.android.tresit.svg.Matrix2D.Companion
    export class Companion {
        private constructor() {
            this.inputSize = 3;
            this.outputSize = 3;
            this.splitter = new RegExp("[ ,]+");
            this.commandRegex = new RegExp("[^a-zA-Z]+");
            this.identity = new Matrix2D(([1.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0] as Array<number>));
        }
        public static INSTANCE = new Companion();
        
        public readonly inputSize: number;
        
        public readonly outputSize: number;
        
        public readonly splitter: RegExp;
        
        public readonly commandRegex: RegExp;
        
        
        public readonly identity: Matrix2D;
        
        
        public rotate(angle: number): Matrix2D {
            return new Matrix2D(([Math.cos(angle), (-Math.sin(angle)), 0.0, Math.sin(angle), Math.cos(angle), 0.0, 0.0, 0.0, 1.0] as Array<number>));
        }
        
        public skew(xAngle: number, yAngle: number): Matrix2D {
            return new Matrix2D(([1.0, Math.tan(xAngle), 0.0, Math.tan(yAngle), 1.0, 0.0, 0.0, 0.0, 1.0] as Array<number>));
        }
        
        public skewX(angle: number): Matrix2D {
            return new Matrix2D(([1.0, Math.tan(angle), 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0] as Array<number>));
        }
        
        public skewY(angle: number): Matrix2D {
            return new Matrix2D(([1.0, 0.0, 0.0, Math.tan(angle), 1.0, 0.0, 0.0, 0.0, 1.0] as Array<number>));
        }
        
        public translate(point: Point): Matrix2D {
            return new Matrix2D(([1.0, 0.0, point.x, 0.0, 1.0, point.y, 0.0, 0.0, 1.0] as Array<number>));
        }
        
        public scale(x: number, y: number): Matrix2D {
            return new Matrix2D(([x, 0.0, 0.0, 0.0, y, 0.0, 0.0, 0.0, 1.0] as Array<number>));
        }
        
        public parseTransforms(transforms: string): Matrix2D {
            let matrix = this.identity;
            
        for (const it of transforms.split(')')) {
            const command = xStringSubstringBefore(it, '(', undefined).replace(new RegExp(this.commandRegex.source, "g"), "");
                
                if (xCharSequenceIsBlank(command)) { continue }
                const _arguments = iterToArray(iterableFilterNotNull(iterMap(xStringSubstringAfter(it, '(', undefined).split(this.splitter).filter((it: string): boolean => it !== ""), (it: string): (number | null) => parseFloatOrNull(it))));
                    
                    console.log(`Got command ${command} with args ${_arguments}`);
                    switch(command) {
                        case "translate":
                        switch(_arguments.length) {
                            case 1:
                            matrix = matrix.before(this.translate(new Point(_arguments[0], 0.0)))
                            break;
                            case 2:
                            matrix = matrix.before(this.translate(new Point(_arguments[0], _arguments[1])))
                            break;
                        }
                        
                        break;
                        case "scale":
                        switch(_arguments.length) {
                            case 1:
                            matrix = matrix.before(this.scale(_arguments[0], _arguments[0]))
                            break;
                            case 2:
                            matrix = matrix.before(this.scale(_arguments[0], _arguments[1]))
                            break;
                        }
                        
                        break;
                        case "rotate":
                        switch(_arguments.length) {
                            case 1:
                            matrix = matrix.before(this.rotate(_arguments[0] * Math.PI / 180))
                            break;
                            case 3:
                            matrix = matrix.before((
                                this.translate(new Point((-_arguments[1]), (-_arguments[2]))).after(this.rotate(_arguments[0] * Math.PI / 180)).after(this.translate(new Point(_arguments[1], _arguments[2])))
                            ))
                            break;
                        }
                        
                        break;
                        case "skewX":
                        if (_arguments.length === 1) {
                            matrix = matrix.before(this.skewX(_arguments[0] * Math.PI / 180));
                        }
                        break;
                        case "skewY":
                        if (_arguments.length === 1) {
                            matrix = matrix.before(this.skewY(_arguments[0] * Math.PI / 180));
                        }
                        break;
                        case "matrix":
                        if (_arguments.length === 6) {
                            matrix = new Matrix2D(([_arguments[0], _arguments[1], _arguments[2], _arguments[3], _arguments[4], _arguments[5], 0.0, 0.0, 1.0] as Array<number>));
                        }
                        break;
                    }
                    
                }
                return matrix;
            }
        }
    }