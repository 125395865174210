// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/Session.read.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { UnreadThread } from '../model/UnreadThread'
import { User } from '../model/User'
import { Observable, ObservableInput, SubscriptionLike, NEVER as rxNEVER } from 'rxjs'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { printStackTrace, safeEq } from 'butterfly-web/dist/kotlin/Language'
import { SessionApi } from './SessionApi'
import { School } from '../model/School'
import { flatMap as rxFlatMap } from 'rxjs/operators'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ChatThread } from '../model/ChatThread'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ForeignKey } from '../model/ForeignKey'

//! Declares com.tresitgroup.android.tresit.api.unread>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiUnread(this_: SessionApi, thread: ForeignKey<ChatThread>): ObservableProperty<boolean> {
    return xObservablePropertyMap<(Array<UnreadThread> | null), boolean>(this_.unreadThreads.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<UnreadThread> | null)): boolean => ((): (boolean | null) => {
        const temp1250 = it;
        if(temp1250 === null) { return null }
        return temp1250.some((it: UnreadThread): boolean => it.thread === thread)
    })() === true);
}
//! Declares com.tresitgroup.android.tresit.api.unread>com.tresitgroup.android.tresit.api.SessionApi
export function unreadObs(this_: SessionApi, threadObs: ObservableProperty<ForeignKey<ChatThread>>): ObservableProperty<boolean> {
    return xObservablePropertyFlatMap<number, boolean>(threadObs, (it: number): ObservableProperty<boolean> => xSessionApiUnread(this_, it));
}

//! Declares com.tresitgroup.android.tresit.api.unreadSchool>com.tresitgroup.android.tresit.api.SessionApi
export function unreadSchool(this_: SessionApi, school: ForeignKey<School>): ObservableProperty<boolean> {
    return xObservablePropertyMap<(Array<UnreadThread> | null), boolean>(this_.unreadThreads.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<UnreadThread> | null)): boolean => ((): (boolean | null) => {
        const temp1254 = it;
        if(temp1254 === null) { return null }
        return temp1254.some((it: UnreadThread): boolean => it.associatedSchools.some((x) => safeEq(school, x)))
    })() === true);
}
//! Declares com.tresitgroup.android.tresit.api.unreadSchool>com.tresitgroup.android.tresit.api.SessionApi
export function unreadSchoolObs(this_: SessionApi, threadObs: ObservableProperty<ForeignKey<School>>): ObservableProperty<boolean> {
    return xObservablePropertyFlatMap<number, boolean>(threadObs, (it: number): ObservableProperty<boolean> => unreadSchool(this_, it));
}
//! Declares com.tresitgroup.android.tresit.api.unreadSchoolFilter>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiUnreadSchoolFilter(this_: SessionApi, threadObs: ObservableProperty<(School | null)>): ObservableProperty<boolean> {
    return xObservablePropertyFlatMap<(School | null), boolean>(threadObs, (it: (School | null)): ObservableProperty<boolean> => it !== null ? unreadSchool(this_, it!.id) : xSessionApiUnreadAny(this_));
}

//! Declares com.tresitgroup.android.tresit.api.unreadUser>com.tresitgroup.android.tresit.api.SessionApi
export function unreadUser(this_: SessionApi, user: ForeignKey<User>): ObservableProperty<boolean> {
    return xObservablePropertyMap<(Array<UnreadThread> | null), boolean>(this_.unreadThreads.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<UnreadThread> | null)): boolean => ((): (boolean | null) => {
        const temp1258 = it;
        if(temp1258 === null) { return null }
        return temp1258.some((it: UnreadThread): boolean => it.userIds.some((x) => safeEq(user, x)))
    })() === true);
}
//! Declares com.tresitgroup.android.tresit.api.unreadUser>com.tresitgroup.android.tresit.api.SessionApi
export function unreadUserObs(this_: SessionApi, userObs: ObservableProperty<ForeignKey<User>>): ObservableProperty<boolean> {
    return xObservablePropertyFlatMap<number, boolean>(userObs, (it: number): ObservableProperty<boolean> => unreadUser(this_, it));
}

//! Declares com.tresitgroup.android.tresit.api.unreadAny>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiUnreadAny(this_: SessionApi): ObservableProperty<boolean> {
    return xObservablePropertyMap<(Array<UnreadThread> | null), boolean>(this_.unreadThreads.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<UnreadThread> | null)): boolean => ((): (boolean | null) => {
        const temp1262 = it;
        if(temp1262 === null) { return null }
        return temp1262.length === 0
    })() === false);
}

//! Declares com.tresitgroup.android.tresit.api.reading>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiReading(this_: SessionApi, thread: ForeignKey<ChatThread>): SubscriptionLike {
    return this_.unreadThreads.observableListSimple(UnreadThread.Companion.INSTANCE.forThread(thread), undefined, undefined, undefined, undefined, undefined).rx.pipe(rxFlatMap((it: Array<UnreadThread>): ObservableInput<void> => ((): (Observable<void> | null) => {
        const temp1267 = (it[0] ?? null);
        if(temp1267 === null) { return null }
        return ((it: UnreadThread): Observable<void> => this_.unreadThreads._delete(it.id))(temp1267)
    })() ?? rxNEVER)).subscribe(undefined, (it: any): void => {
        printStackTrace(it);
    }, undefined);
}