//
// ComponentUserActivityXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_user_activity.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentUserActivityXml
export class ComponentUserActivityXml {
    xmlRoot!: HTMLElement
    device!: HTMLElement
    ip!: HTMLElement
    datetime!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.device = getViewById<HTMLElement>(view, "device")
        this.ip = getViewById<HTMLElement>(view, "ip")
        this.datetime = getViewById<HTMLElement>(view, "datetime")
        return view
    }
}
