// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/DeepLinks.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { School } from '../model/School'
import { ChatThread } from '../model/ChatThread'
import { parseIntOrNull } from 'butterfly-web/dist/Kotlin'
import { ChatMessage } from '../model/ChatMessage'
import { ForeignKey } from '../model/ForeignKey'
import { xStringSubstringAfterLast } from 'butterfly-web/dist/kotlin/kotlin.text'

//! Declares com.tresitgroup.android.tresit.vg.DeepLink
export class DeepLink {
    public constructor() {
    }
    
    
    
    
    
    
    
}
export namespace DeepLink {
    //! Declares com.tresitgroup.android.tresit.vg.DeepLink.LogIn
    export class LogIn extends DeepLink {
        public readonly token: string;
        public constructor(token: string) {
            super();
            this.token = token;
        }
    }
}
export namespace DeepLink {
    //! Declares com.tresitgroup.android.tresit.vg.DeepLink.OpenSchool
    export class OpenSchool extends DeepLink {
        public readonly id: ForeignKey<School>;
        public constructor(id: ForeignKey<School>) {
            super();
            this.id = id;
        }
    }
}
export namespace DeepLink {
    //! Declares com.tresitgroup.android.tresit.vg.DeepLink.OpenThread
    export class OpenThread extends DeepLink {
        public readonly id: ForeignKey<ChatThread>;
        public constructor(id: ForeignKey<ChatThread>) {
            super();
            this.id = id;
        }
    }
}
export namespace DeepLink {
    //! Declares com.tresitgroup.android.tresit.vg.DeepLink.OpenMessage
    export class OpenMessage extends DeepLink {
        public readonly id: ForeignKey<ChatMessage>;
        public constructor(id: ForeignKey<ChatMessage>) {
            super();
            this.id = id;
        }
    }
}
export namespace DeepLink {
    //! Declares com.tresitgroup.android.tresit.vg.DeepLink.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public parse(schema: string, host: string, path: string, params: Map<string, string>): (DeepLink | null) {
            return ((): (DeepLink | null) => {
                if (schema === "dirs" && host === "switch_school"){
                    return ((): (DeepLink.OpenSchool | null) => {
                        const temp3018 = ((): (number | null) => {
                            const temp3020 = (params.get("id") ?? null);
                            if(temp3020 === null) { return null }
                            return parseIntOrNull(temp3020)
                        })();
                        if(temp3018 === null) { return null }
                        return ((it: number): DeepLink.OpenSchool => new DeepLink.OpenSchool(it))(temp3018)
                    })()
                } else if ((path.indexOf("/oauth-success/") != -1)){
                    return new DeepLink.LogIn(xStringSubstringAfterLast(path, '/', undefined));
                } else if (params.has("jwt")){
                    return ((): (DeepLink.LogIn | null) => {
                        const temp3024 = (params.get("jwt") ?? null);
                        if(temp3024 === null) { return null }
                        return ((it: string): DeepLink.LogIn => new DeepLink.LogIn(it))(temp3024)
                    })()
                } else if ((params.get("action") ?? null) === "login"){
                    return ((): (DeepLink.LogIn | null) => {
                        const temp3030 = (params.get("token") ?? null);
                        if(temp3030 === null) { return null }
                        return ((it: string): DeepLink.LogIn => new DeepLink.LogIn(it))(temp3030)
                    })()
                } else if ((params.get("page") ?? null) === "school"){
                    return ((): (DeepLink.OpenSchool | null) => {
                        const temp3036 = ((): (number | null) => {
                            const temp3038 = (params.get("id") ?? null);
                            if(temp3038 === null) { return null }
                            return parseIntOrNull(temp3038)
                        })();
                        if(temp3036 === null) { return null }
                        return ((it: number): DeepLink.OpenSchool => new DeepLink.OpenSchool(it))(temp3036)
                    })()
                } else if ((params.get("page") ?? null) === "thread"){
                    return ((): (DeepLink.OpenThread | null) => {
                        const temp3043 = ((): (number | null) => {
                            const temp3045 = (params.get("id") ?? null);
                            if(temp3045 === null) { return null }
                            return parseIntOrNull(temp3045)
                        })();
                        if(temp3043 === null) { return null }
                        return ((it: number): DeepLink.OpenThread => new DeepLink.OpenThread(it))(temp3043)
                    })()
                } else if ((params.get("page") ?? null) === "message"){
                    return ((): (DeepLink.OpenMessage | null) => {
                        const temp3050 = ((): (number | null) => {
                            const temp3052 = (params.get("id") ?? null);
                            if(temp3052 === null) { return null }
                            return parseIntOrNull(temp3052)
                        })();
                        if(temp3050 === null) { return null }
                        return ((it: number): DeepLink.OpenMessage => new DeepLink.OpenMessage(it))(temp3050)
                    })()
                } else  {
                    return null
                }
            })();
        }
    }
}