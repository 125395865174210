// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Floor.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { Building } from './Building'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { RemoteFileRef } from './RemoteFileRef'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Floor
export class Floor implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly building: ForeignKey<Building>;
    public readonly school: (ForeignKey<School> | null);
    public readonly svgMap: (RemoteFileRef | null);
    public readonly name: string;
    public readonly ordering: number;
    public constructor(id: number = getNO_ID(), building: ForeignKey<Building>, school: (ForeignKey<School> | null) = null, svgMap: (RemoteFileRef | null) = null, name: string, ordering: number = 0) {
        this.id = id;
        this.building = building;
        this.school = school;
        this.svgMap = svgMap;
        this.name = name;
        this.ordering = ordering;
    }
    public static fromJson(obj: any): Floor { return new Floor(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["building"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as (number | null),
        parseJsonTyped(obj["svg"], [String]) as (string | null),
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["ordering"], [Number]) as number
    ) }
    public toJSON(): object { return {
        id: this.id,
        building: this.building,
        school: this.school,
        svg: this.svgMap,
        name: this.name,
        ordering: this.ordering
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.building);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.svgMap);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.ordering);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Floor && safeEq(this.id, other.id) && safeEq(this.building, other.building) && safeEq(this.school, other.school) && safeEq(this.svgMap, other.svgMap) && safeEq(this.name, other.name) && safeEq(this.ordering, other.ordering) }
    public toString(): string { return `Floor(id=${this.id}, building=${this.building}, school=${this.school}, svgMap=${this.svgMap}, name=${this.name}, ordering=${this.ordering})` }
    public copy(id: number = this.id, building: ForeignKey<Building> = this.building, school: (ForeignKey<School> | null) = this.school, svgMap: (RemoteFileRef | null) = this.svgMap, name: string = this.name, ordering: number = this.ordering): Floor { return new Floor(id, building, school, svgMap, name, ordering); }
    
    
}
export namespace Floor {
    //! Declares com.tresitgroup.android.tresit.model.Floor.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public forBuilding(building: ForeignKey<Building>): ApiFilter<Floor> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Floor>(`building=${building}`, undefined, (it: Floor): boolean => it.building === building);
        }
        public forSchool(school: ForeignKey<School>): ApiFilter<Floor> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Floor>(`school=${school}`, undefined, (it: Floor): boolean => it.school === school);
        }
    }
}