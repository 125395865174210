// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/AlertCancelVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewStringRaw } from 'butterfly-web/dist/views/ViewString'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { xCompoundButtonBindSelect } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { DisposeCondition } from 'butterfly-web/dist/rx/DisposeCondition'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { AlertCancelXml } from '../../layout/AlertCancelXml'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { iterLastOrNull } from 'butterfly-web/dist/KotlinCollections'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.alert.AlertCancelVG
export class AlertCancelVG extends ViewGenerator {
    public readonly alertId: ForeignKey<Alert>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(alertId: ForeignKey<Alert>, dialog: ObservableStack<ViewGenerator>, schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.alertId = alertId;
        this.dialog = dialog;
        this.schoolId = schoolId;
        this.session = session;
        this.endingAlert = new StandardObservableProperty<boolean>(false, undefined);
        this.alert = xObservablePropertyMap<(Alert | null), (Alert | null)>(this.session.alerts.observable(this.alertId), (it: (Alert | null)): (Alert | null) => it);
        this.reasonType = new StandardObservableProperty<AlertCancelVG.ReasonType>(AlertCancelVG.ReasonType.RESOLVED, undefined);
        this.otherReason = new StandardObservableProperty<string>("", undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertCancelVG.title
    public get title(): string { return "Alert Cancel"; }
    
    
    public readonly endingAlert: StandardObservableProperty<boolean>;
    
    public readonly alert: ObservableProperty<(Alert | null)>;
    
    
    
    public reasonType: MutableObservableProperty<AlertCancelVG.ReasonType>;
    
    public otherReason: MutableObservableProperty<string>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertCancelXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.reasons
        
        //--- Set Up xml.resolved
        xCompoundButtonBindSelect<AlertCancelVG.ReasonType>(xml.resolved, AlertCancelVG.ReasonType.RESOLVED, this.reasonType);
        
        //--- Set Up xml.falseAlarm
        xCompoundButtonBindSelect<AlertCancelVG.ReasonType>(xml.falseAlarm, AlertCancelVG.ReasonType.FALSE_ALARM, this.reasonType);
        
        //--- Set Up xml.other
        xCompoundButtonBindSelect<AlertCancelVG.ReasonType>(xml.other, AlertCancelVG.ReasonType.OTHER, this.reasonType);
        
        //--- Set Up xml.cancelReasonView
        xViewBindExists(xml.cancelReasonView, xObservablePropertyMap<AlertCancelVG.ReasonType, boolean>(this.reasonType, (it: AlertCancelVG.ReasonType): boolean => safeEq(it, AlertCancelVG.ReasonType.OTHER)));
        xEditTextBindString(xml.cancelReasonView, this.otherReason);
        
        
        //--- Set Up xml.cancelButton (overwritten on flow generation)
        xViewOnClick(xml.cancelButton, undefined, (): void => {
            this.cancelButtonClick();
        });
        
        //--- Set Up xml.endWorking
        xViewFlipperBindLoading(xml.endWorking, this.endingAlert, undefined);
        
        //--- Set Up xml.endAlert
        xViewOnClick(xml.endAlert, undefined, (): void => {
            this.endAlertClick(xViewRemovedGet(xml.endAlert));
        });
        xTextViewBindString(xml.endAlert, xObservablePropertyMap<(Alert | null), string>(this.alert, (it: (Alert | null)): string => it !== null && it!.drill ? "End Drill" : "End Alert"));
        
        ///--- Get out
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            post((): void => {
                if (it === null && iterLastOrNull(this.dialog.value) === this) {
                    this.dialog.dismiss();
                }
            });
        }), xViewRemovedGet(view));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cancelButtonClick(): void {
        this.dialog.dismiss();
    }
    public endAlertClick(disposeCondition: DisposeCondition): void {
        const reasonText = ((): string => {
            switch(this.reasonType.value) {
                case AlertCancelVG.ReasonType.RESOLVED:
                return "Incident Resolved"
                case AlertCancelVG.ReasonType.FALSE_ALARM:
                return "False Alarm"
                case AlertCancelVG.ReasonType.OTHER:
                return this.otherReason.value
                default:
                return ""
            }
            
        })();
        
        
        if (reasonText === "") {
            showDialogAlert(new ViewStringRaw("Please provide a reason"));
        } else {
            xDisposableUntil<SubscriptionLike>(xSingleCallDisplayingError<void>(xSingleWorking<void>(this.session.cancelAlertWithReason(this.alertId, reasonText), this.endingAlert), undefined, (it: void): void => {}), disposeCondition);
        }
    }
    
    //--- Body End
}
export namespace AlertCancelVG {
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertCancelVG.ReasonType
    export class ReasonType {
        private constructor(name: string, jsonName: string) {
            this.name = name;
            this.jsonName = jsonName;
        }
        public static RESOLVED = new ReasonType("RESOLVED", "RESOLVED");
        public static FALSE_ALARM = new ReasonType("FALSE_ALARM", "FALSE_ALARM");
        public static OTHER = new ReasonType("OTHER", "OTHER");
        private static _values: Array<ReasonType> = [ReasonType.RESOLVED, ReasonType.FALSE_ALARM, ReasonType.OTHER];
        public static values(): Array<ReasonType> { return ReasonType._values; }
        public readonly name: string;
        public readonly jsonName: string;
        public static valueOf(name: string): ReasonType { return (ReasonType as any)[name]; }
        public toString(): string { return this.name }
        public toJSON(): string { return this.jsonName }
    }
}
