// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/map/maker/svgImport.kt
// Package: com.tresitgroup.android.tresit.map.maker
// Generated by Khrysalis - this file will be overwritten.
import { find as iterFind, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { IllegalStateException, safeEq } from 'butterfly-web/dist/Kotlin'
import { FullIdentifier, FullIdentifierType, MercatorProjection, xStringToFullIdentifier, xStringToStrictIdentifier } from './SketchSvgFormat'
import { xStringFromJsonString } from 'butterfly-web/dist/Codable'
import { BuildingGeoShape, BuildingGeoShapeFloor, CampusGeoShape, RoomGeoShape, emitGeometry } from '../Models'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { GeoCoordinate } from 'butterfly-web/dist/location/GeoCoordinate'
import { Point } from '../../svg/Point2D'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { SvgNode } from '../../svg/SchoolSvgRender'
import { getNO_ID } from '../../model/HasId'

//! Declares com.tresitgroup.android.tresit.map.maker.generateCampus
export function generateCampus(root: SvgNode): CampusGeoShape {
    const mercatorProjection = root.findValue<MercatorProjection>((it: SvgNode): (MercatorProjection | null) => ((): (MercatorProjection | null) => {
        const temp1852 = it.text;
        if(temp1852 !== null) {
            return xStringFromJsonString<MercatorProjection>(temp1852, [MercatorProjection])
        } else { return null }
    })())
    if(mercatorProjection === null) { throw new IllegalStateException("No projection information found!", undefined) }
    
    const findClearLabel = (inNode: SvgNode, name: string): (GeoCoordinate | null) => {
        return ((): (GeoCoordinate | null) => {
            const temp1854 = iterFind(inNode.children, (it: SvgNode): boolean => {
                const id = ((): (FullIdentifier | null) => {
                    const temp1858 = it.id;
                    if(temp1858 !== null) {
                        return xStringToFullIdentifier(temp1858)
                    } else { return null }
                })();
                
                if (id !== null) {
                    return safeEq(id!.type, FullIdentifierType.Label) && id!.name.trim() === name.trim();
                }
                return false;
            });
            if(temp1854 === null) { return null }
            return ((node: SvgNode): (GeoCoordinate | null) => ((): (GeoCoordinate | null) => {
                const temp1862 = (node.absolutePoints[0] ?? null);
                if(temp1862 === null) { return null }
                return ((it: Point): GeoCoordinate => mercatorProjection.unproject(it))(temp1862)
            })())(temp1854)
        })();
    }
    const findBackupLabel = (inNode: SvgNode): (GeoCoordinate | null) => {
        return ((): (GeoCoordinate | null) => {
            const temp1865 = iterFind(inNode.children, (it: SvgNode): boolean => it.id === null && it.points.length === 1);
            if(temp1865 === null) { return null }
            return ((node: SvgNode): (GeoCoordinate | null) => ((): (GeoCoordinate | null) => {
                const temp1871 = (node.absolutePoints[0] ?? null);
                if(temp1871 === null) { return null }
                return ((it: Point): GeoCoordinate => mercatorProjection.unproject(it))(temp1871)
            })())(temp1865)
        })();
    }
    
    const findClearBoundaries = (inNode: SvgNode, name: string): (string | null) => {
        return ((): (string | null) => {
            const temp1874 = ((): (Array<GeoCoordinate> | null) => {
                const temp1876 = iterFind(inNode.children, (it: SvgNode): boolean => {
                    const id = ((): (FullIdentifier | null) => {
                        const temp1880 = it.id;
                        if(temp1880 !== null) {
                            return xStringToFullIdentifier(temp1880)
                        } else { return null }
                    })();
                    
                    if (id !== null) {
                        return safeEq(id!.type, FullIdentifierType.Bounds) && id!.name.trim() === name.trim();
                    }
                    return false;
                });
                if(temp1876 === null) { return null }
                return ((node: SvgNode): Array<GeoCoordinate> => node.absolutePoints.map((it: Point): GeoCoordinate => mercatorProjection.unproject(it)))(temp1876)
            })();
            if(temp1874 === null) { return null }
            return ((it: Array<GeoCoordinate>): string => emitGeometry(it))(temp1874)
        })();
    }
    const findBackupBoundaries = (inNode: SvgNode): (string | null) => {
        return ((): (string | null) => {
            const temp1886 = ((): (Array<GeoCoordinate> | null) => {
                const temp1888 = iterFind(inNode.children, (it: SvgNode): boolean => ((): (FullIdentifier | null) => {
                    const temp1892 = it.id;
                    if(temp1892 !== null) {
                        return xStringToFullIdentifier(temp1892)
                    } else { return null }
                })() === null);
                if(temp1888 === null) { return null }
                return ((node: SvgNode): Array<GeoCoordinate> => node.absolutePoints.map((it: Point): GeoCoordinate => mercatorProjection.unproject(it)))(temp1888)
            })();
            if(temp1886 === null) { return null }
            return ((it: Array<GeoCoordinate>): string => emitGeometry(it))(temp1886)
        })();
    }
    const useDirectBoundaries = (node: SvgNode): string => {
        return emitGeometry(node.absolutePoints.map((it: Point): GeoCoordinate => mercatorProjection.unproject(it)));
    }
    
    const campusNode_1897 = root.find((it: SvgNode): boolean => safeEq((((): (FullIdentifier | null) => {
        const temp1898 = it.id;
        if(temp1898 !== null) {
            return xStringToStrictIdentifier(temp1898)
        } else { return null }
    })()?.type ?? null), FullIdentifierType.Identifier));
    if (campusNode_1897 !== null) {
        const campusName = xStringToStrictIdentifier(campusNode_1897.id!!)!!.name;
        
        console.log(`Campus ID: ${((): (FullIdentifier | null) => {
            const temp1900 = campusNode_1897.id;
            if(temp1900 !== null) {
                return xStringToStrictIdentifier(temp1900)
            } else { return null }
        })()}`);
        const campusGeometry = findClearBoundaries(campusNode_1897, campusName) ?? findBackupBoundaries(campusNode_1897) ?? "";
        
        return new CampusGeoShape(getNO_ID(), campusName, campusGeometry, iterToArray(iterableFilterNotNull(iterMap(campusNode_1897.children, (buildingNode: SvgNode): (BuildingGeoShape | null) => {
            const buildingId = ((): (FullIdentifier | null) => {
                const temp1903 = buildingNode.id;
                if(temp1903 !== null) {
                    return xStringToFullIdentifier(temp1903)
                } else { return null }
            })()
            if(buildingId === null) { return null }
            console.log(`Building id: ${buildingId}`);
            if (!safeEq(buildingId.type, FullIdentifierType.Identifier)) { return null }
            if (buildingNode.points.length === 0) {
                if (buildingNode.children.some((it: SvgNode): boolean => safeEq((((): (FullIdentifier | null) => {
                    const temp1908 = it.id;
                    if(temp1908 !== null) {
                        return xStringToFullIdentifier(temp1908)
                    } else { return null }
                })()?.type ?? null), FullIdentifierType.Identifier) && it.children.length !== 0)) {
                    //Using floors
                    const geo = findClearBoundaries(buildingNode, buildingId.name) ?? findBackupBoundaries(buildingNode) ?? "";
                    
                    return new BuildingGeoShape(getNO_ID(), buildingId.name, geo, iterToArray(iterableFilterNotNull(iterMap(buildingNode.children, (floorNode: SvgNode): (BuildingGeoShapeFloor | null) => {
                        const floorId = ((): (FullIdentifier | null) => {
                            const temp1912 = floorNode.id;
                            if(temp1912 !== null) {
                                return xStringToFullIdentifier(temp1912)
                            } else { return null }
                        })()
                        if(floorId === null) { return null }
                        if (!safeEq(floorId.type, FullIdentifierType.Identifier)) { return null }
                        return new BuildingGeoShapeFloor(getNO_ID(), floorId.name, iterToArray(iterableFilterNotNull(iterMap(floorNode.children, (roomNode: SvgNode): (RoomGeoShape | null) => {
                            const roomId = ((): (FullIdentifier | null) => {
                                const temp1915 = roomNode.id;
                                if(temp1915 !== null) {
                                    return xStringToFullIdentifier(temp1915)
                                } else { return null }
                            })()
                            if(roomId === null) { return null }
                            if (!safeEq(roomId.type, FullIdentifierType.Identifier)) { return null }
                            return new RoomGeoShape(getNO_ID(), roomId.name, useDirectBoundaries(roomNode), findClearLabel(buildingNode, roomId.name));
                        }))));
                    }))), findClearLabel(buildingNode, buildingId.name) ?? findBackupLabel(buildingNode));
                } else {
                    //Using rooms directly
                    const geo = findClearBoundaries(buildingNode, buildingId.name) ?? findBackupBoundaries(buildingNode) ?? "";
                    
                    return new BuildingGeoShape(getNO_ID(), buildingId.name, geo, [new BuildingGeoShapeFloor(getNO_ID(), "Default", iterToArray(iterableFilterNotNull(iterMap(buildingNode.children, (roomNode: SvgNode): (RoomGeoShape | null) => {
                        const roomId = ((): (FullIdentifier | null) => {
                            const temp1918 = roomNode.id;
                            if(temp1918 !== null) {
                                return xStringToFullIdentifier(temp1918)
                            } else { return null }
                        })()
                        if(roomId === null) { return null }
                        if (!safeEq(roomId.type, FullIdentifierType.Identifier)) { return null }
                        return new RoomGeoShape(getNO_ID(), roomId.name, useDirectBoundaries(roomNode), findClearLabel(buildingNode, roomId.name));
                    }))))], findClearLabel(buildingNode, buildingId.name) ?? findBackupLabel(buildingNode));
                }
            } else {
                //Building with default
                const geometry = useDirectBoundaries(buildingNode);
                
                const labelLocation = findClearLabel(campusNode_1897, buildingId.name) ?? findBackupLabel(buildingNode);
                
                return new BuildingGeoShape(getNO_ID(), buildingId.name, geometry, [new BuildingGeoShapeFloor(getNO_ID(), "Default", [new RoomGeoShape(getNO_ID(), "Default", geometry, labelLocation)])], labelLocation);
            }
        }))), findClearLabel(campusNode_1897, campusName) ?? findBackupLabel(campusNode_1897));
    }
    throw new IllegalStateException("No campus found!", undefined);
}