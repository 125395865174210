//
// DefaultLocationSettingsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './default_location_settings.html'
//! Declares com.tresitgroup.android.tresit.layouts.DefaultLocationSettingsXml
export class DefaultLocationSettingsXml {
    xmlRoot!: HTMLElement
    location1!: HTMLDivElement
    value1!: HTMLElement
    floor1!: HTMLElement
    plusLocation1!: HTMLImageElement
    deleteLocation1!: HTMLButtonElement
    location2!: HTMLDivElement
    value2!: HTMLElement
    floor2!: HTMLElement
    plusLocation2!: HTMLImageElement
    deleteLocation2!: HTMLButtonElement
    location3!: HTMLDivElement
    value3!: HTMLElement
    floor3!: HTMLElement
    plusLocation3!: HTMLImageElement
    deleteLocation3!: HTMLButtonElement
    location4!: HTMLDivElement
    value4!: HTMLElement
    floor4!: HTMLElement
    plusLocation4!: HTMLImageElement
    deleteLocation4!: HTMLButtonElement
    location5!: HTMLDivElement
    value5!: HTMLElement
    floor5!: HTMLElement
    plusLocation5!: HTMLImageElement
    deleteLocation5!: HTMLButtonElement
    cancel!: HTMLButtonElement
    updating!: HTMLDivElement
    save!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.location1 = getViewById<HTMLDivElement>(view, "location1")
        this.value1 = getViewById<HTMLElement>(view, "value1")
        this.floor1 = getViewById<HTMLElement>(view, "floor1")
        this.plusLocation1 = getViewById<HTMLImageElement>(view, "plus_location1")
        this.deleteLocation1 = getViewById<HTMLButtonElement>(view, "delete_location1")
        this.location2 = getViewById<HTMLDivElement>(view, "location2")
        this.value2 = getViewById<HTMLElement>(view, "value2")
        this.floor2 = getViewById<HTMLElement>(view, "floor2")
        this.plusLocation2 = getViewById<HTMLImageElement>(view, "plus_location2")
        this.deleteLocation2 = getViewById<HTMLButtonElement>(view, "delete_location2")
        this.location3 = getViewById<HTMLDivElement>(view, "location3")
        this.value3 = getViewById<HTMLElement>(view, "value3")
        this.floor3 = getViewById<HTMLElement>(view, "floor3")
        this.plusLocation3 = getViewById<HTMLImageElement>(view, "plus_location3")
        this.deleteLocation3 = getViewById<HTMLButtonElement>(view, "delete_location3")
        this.location4 = getViewById<HTMLDivElement>(view, "location4")
        this.value4 = getViewById<HTMLElement>(view, "value4")
        this.floor4 = getViewById<HTMLElement>(view, "floor4")
        this.plusLocation4 = getViewById<HTMLImageElement>(view, "plus_location4")
        this.deleteLocation4 = getViewById<HTMLButtonElement>(view, "delete_location4")
        this.location5 = getViewById<HTMLDivElement>(view, "location5")
        this.value5 = getViewById<HTMLElement>(view, "value5")
        this.floor5 = getViewById<HTMLElement>(view, "floor5")
        this.plusLocation5 = getViewById<HTMLImageElement>(view, "plus_location5")
        this.deleteLocation5 = getViewById<HTMLButtonElement>(view, "delete_location5")
        this.cancel = getViewById<HTMLButtonElement>(view, "cancel")
        this.updating = getViewById<HTMLDivElement>(view, "updating")
        this.save = getViewById<HTMLButtonElement>(view, "save")
        return view
    }
}
