// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/Throwable.extentions.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.
import { Observable, Observer, SubscriptionLike } from 'rxjs'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xResponseReadText } from 'butterfly-web/dist/net/RxHttpAssist'
import { printStackTrace } from 'butterfly-web/dist/kotlin/Language'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xStringParseAsErrors } from './String.extentions'
import { ViewStringRaw } from 'butterfly-web/dist/views/ViewString'

//! Declares com.tresitgroup.android.tresit.util.showMessage>kotlin.Throwable
export function xThrowableShowMessage(this_: any): void {
    printStackTrace(this_);
    xDisposableForever<SubscriptionLike>(xThrowableGetDetailedMessage(this_).subscribe((it: string): void => {
        showDialogAlert(new ViewStringRaw(it));
    }, undefined));
}

//! Declares com.tresitgroup.android.tresit.util.getDetailedMessage>kotlin.Throwable
export function xThrowableGetDetailedMessage(this_: any): Observable<string> {
    const throwable = this_;
    
    return new Observable((emitter: Observer<string>): void => {
        if (throwable instanceof HttpResponseException) {
            xResponseReadText((throwable as HttpResponseException).response).subscribe((response: string): void => {
                    console.error(`${"Throwable.extensions"}: ${response}`);
                    emitter.next(xStringParseAsErrors(response) ?? response); emitter.complete();
                }, (it: any): void => {
                    emitter.next(this_.message ?? "An unknown error occurred"); emitter.complete();
            });
        } else {
            emitter.next(this_.message ?? "An unknown error occurred"); emitter.complete();
        }
    });
}
