// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiQuery.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { ApiFilter } from './ApiFilter'
import { ApiSort } from './ApiSort'

//! Declares com.tresitgroup.android.tresit.api.ApiQuery
export class ApiQuery<T extends HasId> {
    public readonly filter: ApiFilter<T>;
    public readonly sort: ApiSort<T>;
    public constructor(filter: ApiFilter<T> = ApiFilter.Companion.INSTANCE.always<T>(), sort: ApiSort<T> = ApiSort.Companion.INSTANCE.byId<T>()) {
        this.filter = filter;
        this.sort = sort;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.filter);
        hash = 31 * hash + hashAnything(this.sort);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ApiQuery && safeEq(this.filter, other.filter) && safeEq(this.sort, other.sort) }
    public toString(): string { return `ApiQuery(filter=${this.filter}, sort=${this.sort})` }
    public copy(filter: ApiFilter<T> = this.filter, sort: ApiSort<T> = this.sort): ApiQuery<T> { return new ApiQuery(filter, sort); }
    
    
    public queryString(): string {
        let ret = this.filter.params;
        
        ret = ret + ((): string => {
            if (!(this.sort.params === "") && !(ret === "")) {
                return `&${this.sort.params}`;
            } else {
                return this.sort.params;
            }
        })();
        return ret;
    }
    
}
export namespace ApiQuery {
    //! Declares com.tresitgroup.android.tresit.api.ApiQuery.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public _default<T extends HasId>(): ApiQuery<T> {
            return new ApiQuery<T>(undefined, undefined);
        }
    }
}