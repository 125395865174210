import "butterfly-web/dist/polyfill"
import {RootVG} from "./vg/RootVG";
import { xStringFromJsonString } from "butterfly-web/dist/Codable";
import { mainWithFcm } from "butterfly-fcm-web/dist";
import { Notifications } from "butterfly-fcm-web/dist/Notifications";
import firebase from "firebase";
import {ChatThread} from "./model/ChatThread";
import './butterfly.css'
import './main.css'
import './custom.css'
import {xUriToHttpBody} from "butterfly-web/dist/net/HttpBody";

if(!(window as any).usingElectron){
    const firebaseConfig = {
        apiKey: "AIzaSyCBVu59nrWiGoZpYo_rWg6lN7KRqrS5ToU",
        authDomain: "dir-s-1373.firebaseapp.com",
        databaseURL: "https://dir-s-1373.firebaseio.com",
        projectId: "dir-s-1373",
        storageBucket: "dir-s-1373.appspot.com",
        messagingSenderId: "405351706879",
        appId: "1:405351706879:web:c7d9158c977816dc"
    };
    firebase.initializeApp(firebaseConfig);
    Notifications.INSTANCE.serviceWorkerLocation = "./firebase-messaging-sw.js"
}

const vg = new RootVG();
mainWithFcm(vg, undefined)
