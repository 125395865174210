// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/DemoSessionVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { User } from '../model/User'
import { DemoSessionXml } from '../layout/DemoSessionXml'
import { SessionApi } from '../api/SessionApi'
import { MockApi } from '../api/MockApi'
import { SessionVG } from './SessionVG'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { MockDatabase } from '../api/MockDatabase'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { spinnerBind } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { getMockDatabase } from '../api/ApiOption'
import { toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'

//! Declares com.tresitgroup.android.tresit.vg.DemoSessionVG
export class DemoSessionVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.demoStack = new ObservableStack<ViewGenerator>();
    }
    
    
    public readonly demoStack: ObservableStack<ViewGenerator>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.DemoSessionVG.title
    public get title(): string { return "Demo Session"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new DemoSessionXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Set Mock Server
        
        //--- Set Up xml.user
        const currentUser: MutableObservableProperty<User> = new StandardObservableProperty<User>(getMockDatabase().adminUser, undefined);
        
        spinnerBind<User>(xml.user, new ConstantObservableProperty<Array<User>>(iterToArray(getMockDatabase().users.objects.values())), currentUser, (it: User): string => {
            return it.firstName;
        });
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<User>(currentUser, undefined, undefined, (it: User): void => {
            this.demoStack.reset(new SessionVG(this.dialog, this.root, new SessionApi(MockDatabase.Companion.INSTANCE.makeSession(it), new MockApi(getMockDatabase()), this.root, this.dialog), this.demoStack));
        }), xViewRemovedGet(view));
        
        //--- Set Up xml.demo (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.demo, dependency, this.demoStack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
