// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/svg/SchoolSvgRender.kt
// Package: com.tresitgroup.android.tresit.svg
// Generated by Khrysalis - this file will be overwritten.
import { chunk as iterChunk, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { parseFloatOrNull, parseIntOrNull } from 'butterfly-web/dist/Kotlin'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ImageRaw } from 'butterfly-web/dist/Image'
import { xCharIsUpperCase, xCharSequenceIndexOfAny, xStringSubstringAfter } from 'butterfly-web/dist/kotlin/kotlin.text'
import { pathFromLTRB } from 'butterfly-web/dist/views/draw/Path'
import { SubscriptionLike } from 'rxjs'
import { Matrix2D, Point } from './Point2D'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { applyMatrixToCanvas, xCanvasDrawBitmap } from 'butterfly-web/dist/views/draw/Canvas'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { PolygonF } from 'butterfly-web/dist/views/geometry/PolygonF'
import { xImageLoad } from 'butterfly-web/dist/Image.loadingRx'
import { PointF } from 'butterfly-web/dist/views/geometry/PointF'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'

//! Declares com.tresitgroup.android.tresit.svg.XmlNode
export class XmlNode {
    public readonly name: string;
    public readonly attributes: Map<string, string>;
    public readonly children: Array<XmlNode>;
    public readonly rawContent: (string | null);
    public constructor(name: string, attributes: Map<string, string>, children: Array<XmlNode>, rawContent: (string | null) = null) {
        this.name = name;
        this.attributes = attributes;
        this.children = children;
        this.rawContent = rawContent;
    }
}

//! Declares com.tresitgroup.android.tresit.svg.toSvgNode>com.tresitgroup.android.tresit.svg.XmlNode
export function xXmlNodeToSvgNode(this_: XmlNode): SvgNode {
    const transform: (Matrix2D | null) = ((): (Matrix2D | null) => {
        const temp2723 = (this_.attributes.get("transform") ?? null);
        if(temp2723 === null) { return null }
        return ((it: string): Matrix2D => Matrix2D.Companion.INSTANCE.parseTransforms(it))(temp2723)
    })();
    
    const dataId = ((): (number | null) => {
        const temp2727 = (this_.attributes.get("data-id") ?? null);
        if(temp2727 === null) { return null }
        return parseIntOrNull(temp2727)
    })();
    
    switch(this_.name) {
        case "image":
        let bitmap: (ImageBitmap | null) = null;
        
        const prefix = "data:image/png;base64,";
        
        const ref_2730 = (this_.attributes.get("xlink:href") ?? null);
        if (ref_2730 !== null) {
            if (ref_2730.startsWith(prefix)) {
                const data: Int8Array = Int8Array.from(atob(xStringSubstringAfter(ref_2730, prefix, undefined)), (c)=>c.charCodeAt(0));
                
                xDisposableForever<SubscriptionLike>(xImageLoad(new ImageRaw(data)).subscribe((it: ImageBitmap): void => {
                        bitmap = it;
                    }, (it: any): void => {
                        console.log(`Failed to get bitmap: ${it}`);
                }));
            }
        }
        const left = ((): (number | null) => {
            const temp2738 = ((): (number | null) => {
                const temp2739 = (this_.attributes.get("x") ?? null);
                if(temp2739 === null) { return null }
                return parseFloatOrNull(temp2739)
            })();
            if(temp2738 === null) { return null }
            return temp2738
        })() ?? 0;
        
        const top = ((): (number | null) => {
            const temp2742 = ((): (number | null) => {
                const temp2743 = (this_.attributes.get("y") ?? null);
                if(temp2743 === null) { return null }
                return parseFloatOrNull(temp2743)
            })();
            if(temp2742 === null) { return null }
            return temp2742
        })() ?? 0;
        
        const right = left + (((): (number | null) => {
            const temp2747 = ((): (number | null) => {
                const temp2748 = (this_.attributes.get("width") ?? null);
                if(temp2748 === null) { return null }
                return parseFloatOrNull(temp2748)
            })();
            if(temp2747 === null) { return null }
            return temp2747
        })() ?? 0);
        
        const bottom = top + (((): (number | null) => {
            const temp2752 = ((): (number | null) => {
                const temp2753 = (this_.attributes.get("height") ?? null);
                if(temp2753 === null) { return null }
                return parseFloatOrNull(temp2753)
            })();
            if(temp2752 === null) { return null }
            return temp2752
        })() ?? 0);
        
        return new SvgNode((this_.attributes.get("id") ?? null), dataId, undefined, (canvas: CanvasRenderingContext2D, paints: Array<Paint>): void => {
            const bitmap_2758 = bitmap;
            if (bitmap_2758 !== null) {
                xCanvasDrawBitmap(canvas, bitmap_2758, left, top, right, bottom);
            }
        }, undefined, undefined, transform, this_.children.map((it: XmlNode): SvgNode => xXmlNodeToSvgNode(it)))
        case "rect":
        const rLeft = ((): (number | null) => {
            const temp2761 = ((): (number | null) => {
                const temp2762 = (this_.attributes.get("x") ?? null);
                if(temp2762 === null) { return null }
                return parseFloatOrNull(temp2762)
            })();
            if(temp2761 === null) { return null }
            return temp2761
        })() ?? 0;
        
        const rTop = ((): (number | null) => {
            const temp2765 = ((): (number | null) => {
                const temp2766 = (this_.attributes.get("y") ?? null);
                if(temp2766 === null) { return null }
                return parseFloatOrNull(temp2766)
            })();
            if(temp2765 === null) { return null }
            return temp2765
        })() ?? 0;
        
        const rRight = rLeft + (((): (number | null) => {
            const temp2770 = ((): (number | null) => {
                const temp2771 = (this_.attributes.get("width") ?? null);
                if(temp2771 === null) { return null }
                return parseFloatOrNull(temp2771)
            })();
            if(temp2770 === null) { return null }
            return temp2770
        })() ?? 0);
        
        const rBottom = rTop + (((): (number | null) => {
            const temp2775 = ((): (number | null) => {
                const temp2776 = (this_.attributes.get("height") ?? null);
                if(temp2776 === null) { return null }
                return parseFloatOrNull(temp2776)
            })();
            if(temp2775 === null) { return null }
            return temp2775
        })() ?? 0);
        
        return new SvgNode((this_.attributes.get("id") ?? null), dataId, undefined, (it: CanvasRenderingContext2D, paints: Array<Paint>): void => {
            for (const paint of paints) {
                paint.render(it, pathFromLTRB(rLeft, rTop, rRight, rBottom));
            }
        }, (it: PointF): boolean => it.x >= rLeft && it.x <= rRight && it.y >= rTop && it.y <= rBottom, [new Point(rLeft, rTop), new Point(rRight, rTop), new Point(rRight, rBottom), new Point(rLeft, rBottom)], transform, undefined)
        case "path":
        const pathPoints = pathDataToPoints((this_.attributes.get("d") ?? null) ?? "m0,0h1v1");
        
        const pathPoly = new PolygonF(pathPoints.map((it: Point): PointF => it.toPointF()));
        
        return new SvgNode((this_.attributes.get("id") ?? null), dataId, undefined, (it: CanvasRenderingContext2D, paints: Array<Paint>): void => {
            const path = new Path2D();
            
            let first = true;
            
            for (const pt of pathPoly.points) {
                if (first) {
                    first = false;
                    path.moveTo(pt.x, pt.y);
                } else {
                    path.lineTo(pt.x, pt.y);
                }
            }
            path.closePath();
            for (const paint of paints) {
                paint.render(it, path);
            }
        }, (it: PointF): boolean => pathPoly.contains(it), pathPoints, transform, undefined)
        case "circle":
        return new SvgNode((this_.attributes.get("id") ?? null), dataId, undefined, undefined, undefined, [new Point(((): (number | null) => {
                    const temp2796 = (this_.attributes.get("cx") ?? null);
                    if(temp2796 === null) { return null }
                    return parseFloat(temp2796)
            })() ?? 0.0, ((): (number | null) => {
                const temp2799 = (this_.attributes.get("cy") ?? null);
                if(temp2799 === null) { return null }
                return parseFloat(temp2799)
        })() ?? 0.0)], transform, this_.children.map((it: XmlNode): SvgNode => xXmlNodeToSvgNode(it)))
        case "polygon":
        //rip polygone [sic], the old name of this in the Android codebase.
        const points = ((): (Array<Point> | null) => {
            const temp2804 = (this_.attributes.get("points") ?? null);
            if(temp2804 === null) { return null }
            return ((it: string): Array<Point> => iterToArray(iterableFilterNotNull(iterMap(iterChunk(iterableFilterNotNull(iterMap(it.replaceAll(',', ' ').split(' '), (it: string): (number | null) => parseFloatOrNull(it))), 2), (it: Array<number>): (Point | null) => it.length === 2 ? new Point(it[0], it[1]) : null))))(temp2804)
        })() ?? [new Point(0.0, 0.0), new Point(1.0, 0.0), new Point(1.0, 1.0)];
        
        const poly = new PolygonF(points.map((it: Point): PointF => it.toPointF()));
        
        return new SvgNode((this_.attributes.get("id") ?? null), dataId, undefined, (it: CanvasRenderingContext2D, paints: Array<Paint>): void => {
            const path = new Path2D();
            
            let first = true;
            
            for (const pt of poly.points) {
                if (first) {
                    first = false;
                    path.moveTo(pt.x, pt.y);
                } else {
                    path.lineTo(pt.x, pt.y);
                }
            }
            path.closePath();
            for (const paint of paints) {
                paint.render(it, path);
            }
        }, (it: PointF): boolean => poly.contains(it), points, transform, undefined)
        case "text":
        return new SvgNode((this_.attributes.get("id") ?? null), dataId, this_.rawContent, undefined, undefined, undefined, transform, this_.children.map((it: XmlNode): SvgNode => xXmlNodeToSvgNode(it)))
    }
    
    return new SvgNode((this_.attributes.get("id") ?? null), dataId, undefined, undefined, undefined, undefined, transform, this_.children.map((it: XmlNode): SvgNode => xXmlNodeToSvgNode(it)));
}

//! Declares com.tresitgroup.android.tresit.svg.SvgNode
export class SvgNode {
    public readonly id: (string | null);
    public readonly dataId: (number | null);
    public readonly text: (string | null);
    public readonly renderInstructions:  ((a: CanvasRenderingContext2D, b: Array<Paint>) => void);
    public readonly hitInstructions:  ((a: PointF) => boolean);
    public readonly points: Array<Point>;
    public readonly transform: (Matrix2D | null);
    public readonly children: Array<SvgNode>;
    public constructor(id: (string | null) = null, dataId: (number | null) = null, text: (string | null) = null, renderInstructions:  ((a: CanvasRenderingContext2D, b: Array<Paint>) => void) = (a: CanvasRenderingContext2D, b: Array<Paint>): void => {}, hitInstructions:  ((a: PointF) => boolean) = (it: PointF): boolean => false, points: Array<Point> = [], transform: (Matrix2D | null) = null, children: Array<SvgNode> = []) {
        this.id = id;
        this.dataId = dataId;
        this.text = text;
        this.renderInstructions = renderInstructions;
        this.hitInstructions = hitInstructions;
        this.points = points;
        this.transform = transform;
        this.children = children;
        this.parent = null;
        for (const child of this.children) {
            child.parent = this;
        };
    }
    
    public parent: (SvgNode | null);
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.svg.SvgNode.absoluteTransform
    public get absoluteTransform(): Matrix2D {
        return ((): (Matrix2D | null) => {
            const temp2832 = this.parent;
            if(temp2832 === null) { return null }
            return ((parent: SvgNode): Matrix2D => ((): (Matrix2D | null) => {
                const temp2834 = this.transform;
                if(temp2834 === null) { return null }
                return ((it: Matrix2D): Matrix2D => parent.absoluteTransform.before(it))(temp2834)
            })() ?? parent.absoluteTransform)(temp2832)
        })() ?? this.transform ?? Matrix2D.Companion.INSTANCE.identity;
    }
    
    //! Declares com.tresitgroup.android.tresit.svg.SvgNode.absolutePoints
    public get absolutePoints(): Array<Point> { return this.points.map((it: Point): Point => this.absoluteTransform.transform(it)); }
    
    public forEach(action: ((a: SvgNode) => void)): void {
        action(this);
        for (const item of this.children) {
            item.forEach(action);
        }
    }
    
    public findValue<T>(action: ((a: SvgNode) => (T | null))): (T | null) {
        const it_2838 = action(this);
        if (it_2838 !== null) {
            return it_2838;
        }
        for (const item of this.children) {
            const it_2839 = item.findValue<T>(action);
            if (it_2839 !== null) {
                return it_2839;
            }
        }
        return null;
    }
    
    public find(action: ((a: SvgNode) => boolean)): (SvgNode | null) {
        if (action(this)) {
            return this;
        }
        for (const item of this.children) {
            const it_2840 = item.find(action);
            if (it_2840 !== null) {
                return it_2840;
            }
        }
        return null;
    }
    
    public hit(point: PointF): (SvgNode | null) {
        const pt = (this.transform?.transformPointF(point) ?? null) ?? point;
        
        
        if (this.hitInstructions(pt)) { return this } else { for (const child of this.children) {
            const r = child.hit(pt);
            
            if (r !== null) {
                return r;
            }
        } }
        return null;
    }
    
    public render(onto: CanvasRenderingContext2D, current: DOMMatrix, getPaint: ((a: SvgNode) => Array<Paint>)): void {
        const transform = this.transform;
        
        if (transform !== null) {
            onto.save();
            applyMatrixToCanvas(onto, transform!.toTraditional());
        }
        this.renderInstructions(onto, getPaint(this));
        for (const child of this.children) {
            child.render(onto, current, getPaint);
        }
        if (transform !== null) {
            onto.restore();
        }
    }
}


//! Declares com.tresitgroup.android.tresit.svg.pathLetters
export const _pathLetters = (['M', 'L', 'Z', 'H', 'V', 'Q', 'T', 'C', 'S', 'A'] as Array<string>);
export function getPathLetters(): Array<string> { return _pathLetters; }

//! Declares com.tresitgroup.android.tresit.svg.spaceOrComma
export const _spaceOrComma = new RegExp("[ ,]+");
export function getSpaceOrComma(): RegExp { return _spaceOrComma; }

function pathDataToPoints(pathData: string): Array<Point> {
    const points = ([] as Array<Point>);
    
    let referenceX: number = 0.0;
    
    let referenceY: number = 0.0;
    
    let previousC2X: number = 0.0;
    
    let previousC2Y: number = 0.0;
    
    let stringIndex = xCharSequenceIndexOfAny(pathData, getPathLetters(), 0, true);
    
    while (true) {
        let nextLetterIndex = xCharSequenceIndexOfAny(pathData, getPathLetters(), stringIndex + 1, true);
        
        if (nextLetterIndex === (-1)) { nextLetterIndex = pathData.length }
        
        const rawInstruction: string = pathData[stringIndex];
        
        const _arguments: Array<number> = Array.from(iterToArray(iterableFilterNotNull(iterMap(pathData.substring(stringIndex + 1, nextLetterIndex).split(getSpaceOrComma()),  (it: string): (number | null) => ((): (number | null) => {
            const temp2852 = parseFloatOrNull(it);
            if(temp2852 === null) { return null }
            return temp2852
        })()))));
        
        
        const instruction = rawInstruction.toLowerCase();
        
        const isAbsolute: boolean = xCharIsUpperCase(rawInstruction);
        
        const offsetX = (): number => {
            return isAbsolute ? 0.0 : referenceX;
        }
        const offsetY = (): number => {
            return isAbsolute ? 0.0 : referenceY;
        }
        let updateReference = true;
        
        while (_arguments.length !== 0) {
            let destX: number = 0.0;
            
            let destY: number = 0.0;
            
            let controlX: number = 0.0;
            
            let controlY: number = 0.0;
            
            let control1X: number = 0.0;
            
            let control1Y: number = 0.0;
            
            let control2X: number = 0.0;
            
            let control2Y: number = 0.0;
            
            switch(instruction) {
                case 'm':
                while (_arguments.length >= 2) {
                    destX = _arguments.splice(0, 1)[0] + offsetX();
                    destY = _arguments.splice(0, 1)[0] + offsetY();
                    referenceX = destX;
                    referenceY = destY;
                    points.push(new Point(destX, destY));
                }
                break;
                case 'l':
                while (_arguments.length >= 2) {
                    destX = _arguments.splice(0, 1)[0] + offsetX();
                    destY = _arguments.splice(0, 1)[0] + offsetY();
                    referenceX = destX;
                    referenceY = destY;
                    points.push(new Point(destX, destY));
                }
                break;
                case 'z':
                
                break;
                case 'h':
                updateReference = false
                referenceX = (isAbsolute ? _arguments.splice(0, 1)[0] : referenceX + _arguments.splice(0, 1)[0])
                points.push(new Point(referenceX, referenceY))
                break;
                case 'v':
                updateReference = false
                referenceY = (isAbsolute ? _arguments.splice(0, 1)[0] : referenceY + _arguments.splice(0, 1)[0])
                points.push(new Point(referenceX, referenceY))
                break;
                case 'q':
                controlX = _arguments.splice(0, 1)[0] + offsetX()
                controlY = _arguments.splice(0, 1)[0] + offsetY()
                destX = _arguments.splice(0, 1)[0] + offsetX()
                destY = _arguments.splice(0, 1)[0] + offsetY()
                previousC2X = controlX
                previousC2Y = controlY
                referenceX = destX
                referenceY = destY
                points.push(new Point(destX, destY))
                break;
                case 't':
                destX = _arguments.splice(0, 1)[0] + offsetX()
                destY = _arguments.splice(0, 1)[0] + offsetY()
                controlX = referenceX - (referenceX - previousC2X)
                controlY = referenceY - (referenceY - previousC2Y)
                referenceX = destX
                referenceY = destY
                points.push(new Point(destX, destY))
                break;
                case 'c':
                control1X = _arguments.splice(0, 1)[0] + offsetX()
                control1Y = _arguments.splice(0, 1)[0] + offsetY()
                control2X = _arguments.splice(0, 1)[0] + offsetX()
                control2Y = _arguments.splice(0, 1)[0] + offsetY()
                destX = _arguments.splice(0, 1)[0] + offsetX()
                destY = _arguments.splice(0, 1)[0] + offsetY()
                previousC2X = control2X
                previousC2Y = control2Y
                referenceX = destX
                referenceY = destY
                points.push(new Point(destX, destY))
                break;
                case 's':
                control2X = _arguments.splice(0, 1)[0] + offsetX()
                control2Y = _arguments.splice(0, 1)[0] + offsetY()
                destX = _arguments.splice(0, 1)[0] + offsetX()
                destY = _arguments.splice(0, 1)[0] + offsetY()
                const c1x = referenceX - (referenceX - previousC2X);
                
                const c1y = referenceY - (referenceY - previousC2Y);
                
                referenceX = destX
                referenceY = destY
                points.push(new Point(destX, destY))
                break;
                case 'a':
                const radiusX = _arguments.splice(0, 1)[0];
                
                const radiusY = _arguments.splice(0, 1)[0];
                
                const xAxisRotation = _arguments.splice(0, 1)[0];
                
                const largeArcFlag = _arguments.splice(0, 1)[0];
                
                const sweepFlag = _arguments.splice(0, 1)[0];
                
                destX = _arguments.splice(0, 1)[0] + offsetX()
                destY = _arguments.splice(0, 1)[0] + offsetY()
                points.push(new Point(destX, destY))
                break;
                default:
                console.log(`WARNING: Non-legal command ${instruction} while parsing SVG`)
                break;
            }
            
        }
        
        stringIndex = nextLetterIndex;
        if (nextLetterIndex === pathData.length) { break  }
    }
    return points;
}
