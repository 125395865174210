// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/OnStatusButtonPressedVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { OnStatusButtonPressedXml } from '../../layout/OnStatusButtonPressedXml'
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { SelectStatusVG } from './SelectStatusVG'
import { SessionApi } from '../../api/SessionApi'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'

//! Declares com.tresitgroup.android.tresit.vg.alert.OnStatusButtonPressedVG
export class OnStatusButtonPressedVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly alertId: (number | null);
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, alertId: (number | null) = null) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.alertId = alertId;
        this.alertStartedStack = new ObservableStack<ViewGenerator>();
        this.alertStartedStack.reset(new SelectStatusVG(this.dialog, this.session, this.alertId));
    }
    
    
    public readonly alertStartedStack: ObservableStack<ViewGenerator>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.OnStatusButtonPressedVG.title
    public get title(): string { return "On Status Button Pressed"; }
    
    
    public onBackPressed(): boolean {
        return this.alertStartedStack.backPressPop();
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new OnStatusButtonPressedXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.alertStartedView (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.alertStartedView, dependency, this.alertStartedStack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
