// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/FancyBackgroundDelegate.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { xActivityAccessGetColor } from 'butterfly-web/dist/views/ViewDependency'
import { DisplayMetrics } from 'butterfly-web/dist/views/DisplayMetrics'
import { applyAlphaToColor, numberToColor } from 'butterfly-web/dist/views/Colors'
import { Shader } from 'butterfly-web/dist/views/draw/LinearGradient'
import { R } from '../R'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { pathFromLTRB } from 'butterfly-web/dist/views/draw/Path'
import { CustomViewDelegate } from 'butterfly-web/dist/views/CustomViewDelegate'
import { also } from 'butterfly-web/dist/Kotlin'
import { newRadialGradient } from 'butterfly-web/dist/views/draw/RadialGradient'

//! Declares com.tresitgroup.android.tresit.vg.FancyBackgroundDelegate
export class FancyBackgroundDelegate extends CustomViewDelegate {
    public constructor() {
        super();
        this.radialPaint = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
        });
        this.squarePaint = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
        });
        this.innerRadialColor = numberToColor(0x0);
    }
    
    public readonly radialPaint: Paint;
    
    public readonly squarePaint: Paint;
    
    public innerRadialColor: string;
    
    public setup(dependency: Window): void {
        this.squarePaint.color = xActivityAccessGetColor(dependency, R.color.backgroundSquare);
        this.innerRadialColor = xActivityAccessGetColor(dependency, R.color.backgroundRadial);
    }
    public draw(canvas: CanvasRenderingContext2D, width: number, height: number, displayMetrics: DisplayMetrics): void {
        this.radialPaint.shader = newRadialGradient(width / 2, height / 2, Math.min(width / 2, height / 2), [this.innerRadialColor, applyAlphaToColor(this.innerRadialColor, 0)], [0, 1], Shader.TileMode.CLAMP);
        canvas.beginPath(); canvas.arc(width / 2, height / 2, Math.min(width / 2, height / 2), 0, Math.PI * 2); this.radialPaint.complete(canvas);
        
        const rectSize = Math.min(width, height) / 1.5;
        
        const drawRotatedSquare = (x: number, y: number): void => {
            canvas.save();
            canvas.translate(x, y);
            canvas.rotate((-30) * Math.PI / 180);
            this.squarePaint.render(canvas, pathFromLTRB((-rectSize) / 2, (-rectSize) / 2, rectSize / 2, rectSize / 2));
            canvas.restore();
        }
        drawRotatedSquare((-rectSize) / 4, height / 4);
        drawRotatedSquare(width / 4, height + rectSize / 4);
        drawRotatedSquare(width + rectSize / 4, height / 3);
    }
    
    public generateAccessibilityView(): (HTMLElement | null) {
        return null;
    }
}