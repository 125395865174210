// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/AlertCancelReportVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { Time } from '../../util/time'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewStringResource, ViewStringTemplate } from 'butterfly-web/dist/views/ViewString'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { AlertType } from '../../model/AlertType'
import { R } from '../../R'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { AlertCancelReportXml } from '../../layout/AlertCancelReportXml'
import { SubscriptionLike, interval as rxInterval } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { iterLastOrNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { filter as rxFilter } from 'rxjs/operators'
import { AlertHistory } from '../../model/AlertHistory'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.alert.AlertCancelReportVG
export class AlertCancelReportVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly endedAlert: AlertHistory;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, endedAlert: AlertHistory, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.endedAlert = endedAlert;
        this.session = session;
        this.alertType = ((): ObservableProperty<(AlertType | null)> => {
            if (this.endedAlert.name !== null) { return xObservablePropertyMap<(AlertType | null), (AlertType | null)>(this.session.alertTypes.observable(this.endedAlert.name!), (it: (AlertType | null)): (AlertType | null) => it) } else { return new StandardObservableProperty<(AlertType | null)>(null, undefined) }
        })();
        this.school = xObservablePropertyMap<(School | null), (School | null)>(this.session.schools.observable(this.endedAlert.school), (it: (School | null)): (School | null) => it);
        this.canceller = ((): ObservableProperty<(User | null)> => {
            if (this.endedAlert.canceller !== null) { return xObservablePropertyMap<(User | null), (User | null)>(this.session.users.observable(this.endedAlert.canceller!), (it: (User | null)): (User | null) => it) } else { return new StandardObservableProperty<(User | null)>(null, undefined) }
        })();
        this.initiator = ((): ObservableProperty<(User | null)> => {
            if (this.endedAlert.initiator !== null) { return xObservablePropertyMap<(User | null), (User | null)>(this.session.users.observable(this.endedAlert.initiator!), (it: (User | null)): (User | null) => it) } else { return new StandardObservableProperty<(User | null)>(null, undefined) }
        })();
        this.internal = this.endedAlert.internal ? new ViewStringResource(R._string.drill) : new ViewStringResource(R._string.alert);
        this.startTime = Time.INSTANCE.epochMilliseconds();
        this.activationTime = 30000;
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertCancelReportVG.title
    public get title(): string { return "Alert Cancel Report"; }
    
    
    public readonly alertType: ObservableProperty<(AlertType | null)>;
    
    public readonly school: ObservableProperty<(School | null)>;
    
    public readonly canceller: ObservableProperty<(User | null)>;
    
    public readonly initiator: ObservableProperty<(User | null)>;
    
    public readonly internal: ViewStringResource;
    
    public readonly startTime: number;
    
    public readonly activationTime: number;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertCancelReportXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.alertEndedTitle
        
        setViewText(xml.alertEndedTitle, new ViewStringTemplate(new ViewStringResource(R._string.ended_alert_type), [this.internal]).get(dependency));
        
        //--- Auto-close
        xDisposableUntil<SubscriptionLike>(rxInterval(1000).pipe(rxFilter((it: number): boolean => Time.INSTANCE.epochMilliseconds() - this.startTime > this.activationTime)).subscribe((it: number): void => {
            post((): void => {
                if (iterLastOrNull(this.dialog.stack) === this) {
                    this.dialog.dismiss();
                }
            });
        }, (it: any): void => {}, undefined), xViewRemovedGet(view));
        
        //--- Set Up xml.alertTypeIcon
        xImageViewBindImage(xml.alertTypeIcon, xObservablePropertyMap<(AlertType | null), (Image | null)>(this.alertType, (it: (AlertType | null)): (Image | null) => ((): (Image | null) => {
            const temp3927 = (it?.icon ?? null);
            if(temp3927 !== null) {
                return xStringAsImage(temp3927)
            } else { return null }
        })()));
        
        //--- Set Up xml.alertName
        xTextViewBindString(xml.alertName, xObservablePropertyMap<(AlertType | null), string>(this.alertType, (it: (AlertType | null)): string => (it?.name ?? null) ?? "Emergency"));
        
        //--- Set Up xml.initiatorName
        xTextViewBindString(xml.initiatorName, xObservablePropertyMap<(User | null), string>(this.initiator, (it: (User | null)): string => (it?.name ?? null) ?? "Unknown"));
        
        //--- Set Up xml.initiatorTime
        setViewText(xml.initiatorTime, xDateFormat(this.endedAlert.created, ClockPartSize.None, ClockPartSize.Medium));
        
        //--- Set Up xml.schoolName
        xTextViewBindString(xml.schoolName, xObservablePropertyMap<(School | null), string>(this.school, (it: (School | null)): string => (it?.name ?? null) ?? "School"));
        
        //--- Set Up xml.cancellerName
        xTextViewBindString(xml.cancellerName, xObservablePropertyMap<(User | null), string>(this.canceller, (it: (User | null)): string => (it?.name ?? null) ?? "Unknown"));
        
        //--- Set Up xml.cancelTime
        setViewText(xml.cancelTime, ((): (string | null) => {
            const temp3932 = this.endedAlert.cancelled;
            if(temp3932 !== null) {
                return xDateFormat(temp3932, ClockPartSize.None, ClockPartSize.Medium)
            } else { return null }
        })() ?? "-");
        
        //--- Set Up xml.reason
        setViewText(xml.reason, this.endedAlert.cancelReason);
        
        //--- Set Up xml.dismissButton (overwritten on flow generation)
        xViewOnClick(xml.dismissButton, undefined, (): void => {
            this.dismissButtonClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public dismissButtonClick(): void {
        this.dialog.dismiss();
    }
    
    //--- Body End
}
