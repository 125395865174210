// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertType.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { RemoteFileRef } from './RemoteFileRef'
import { ChatThread } from './ChatThread'
import { ForeignKey } from './ForeignKey'
import { HasId, getNO_ID } from './HasId'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertType
export class AlertType implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly school: ForeignKey<School>;
    public readonly name: string;
    public readonly icon: (RemoteFileRef | null);
    public readonly notifyOnlyThisGroup: (ForeignKey<ChatThread> | null);
    public readonly requiresAdminApproval: boolean;
    public readonly canStartOthersGroupAlert: boolean;
    public readonly mp3File: (RemoteFileRef | null);
    public readonly oggFile: (RemoteFileRef | null);
    public groups: (Array<number> | null);
    public readonly sortOrder: number;
    public urgency: string;
    public subscribed: boolean;
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, school: ForeignKey<School>, name: string, icon: (RemoteFileRef | null) = null, notifyOnlyThisGroup: (ForeignKey<ChatThread> | null) = null, requiresAdminApproval: boolean = false, canStartOthersGroupAlert: boolean = false, mp3File: (RemoteFileRef | null) = null, oggFile: (RemoteFileRef | null) = null, groups: (Array<number> | null) = null, sortOrder: number = 1, urgency: string = "", subscribed: boolean = true) {
        this.id = id;
        this.deleted = deleted;
        this.school = school;
        this.name = name;
        this.icon = icon;
        this.notifyOnlyThisGroup = notifyOnlyThisGroup;
        this.requiresAdminApproval = requiresAdminApproval;
        this.canStartOthersGroupAlert = canStartOthersGroupAlert;
        this.mp3File = mp3File;
        this.oggFile = oggFile;
        this.groups = groups;
        this.sortOrder = sortOrder;
        this.urgency = urgency;
        this.subscribed = subscribed;
    }
    public static fromJson(obj: any): AlertType { return new AlertType(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["school"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["icon"], [String]) as (string | null),
        parseJsonTyped(obj["notifyOnlyThisGroup"], [Number]) as (number | null),
        parseJsonTyped(obj["requires_admin_approval"], [Boolean]) as boolean,
        parseJsonTyped(obj["can_start_others_group_alert"], [Boolean]) as boolean,
        parseJsonTyped(obj["mp3_file"], [String]) as (string | null),
        parseJsonTyped(obj["ogg_file"], [String]) as (string | null),
        parseJsonTyped(obj["alert_to_specific_groups_only"], [Array, [Number]]) as (Array<number> | null),
        parseJsonTyped(obj["sort_order"], [Number]) as number,
        parseJsonTyped(obj["urgency"], [String]) as string,
        parseJsonTyped(obj["subscribed"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        school: this.school,
        name: this.name,
        icon: this.icon,
        notifyOnlyThisGroup: this.notifyOnlyThisGroup,
        requires_admin_approval: this.requiresAdminApproval,
        can_start_others_group_alert: this.canStartOthersGroupAlert,
        mp3_file: this.mp3File,
        ogg_file: this.oggFile,
        alert_to_specific_groups_only: this.groups,
        sort_order: this.sortOrder,
        urgency: this.urgency,
        subscribed: this.subscribed
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.icon);
        hash = 31 * hash + hashAnything(this.notifyOnlyThisGroup);
        hash = 31 * hash + hashAnything(this.requiresAdminApproval);
        hash = 31 * hash + hashAnything(this.canStartOthersGroupAlert);
        hash = 31 * hash + hashAnything(this.mp3File);
        hash = 31 * hash + hashAnything(this.oggFile);
        hash = 31 * hash + hashAnything(this.groups);
        hash = 31 * hash + hashAnything(this.sortOrder);
        hash = 31 * hash + hashAnything(this.urgency);
        hash = 31 * hash + hashAnything(this.subscribed);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertType && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.school, other.school) && safeEq(this.name, other.name) && safeEq(this.icon, other.icon) && safeEq(this.notifyOnlyThisGroup, other.notifyOnlyThisGroup) && safeEq(this.requiresAdminApproval, other.requiresAdminApproval) && safeEq(this.canStartOthersGroupAlert, other.canStartOthersGroupAlert) && safeEq(this.mp3File, other.mp3File) && safeEq(this.oggFile, other.oggFile) && safeEq(this.groups, other.groups) && safeEq(this.sortOrder, other.sortOrder) && safeEq(this.urgency, other.urgency) && safeEq(this.subscribed, other.subscribed) }
    public toString(): string { return `AlertType(id=${this.id}, deleted=${this.deleted}, school=${this.school}, name=${this.name}, icon=${this.icon}, notifyOnlyThisGroup=${this.notifyOnlyThisGroup}, requiresAdminApproval=${this.requiresAdminApproval}, canStartOthersGroupAlert=${this.canStartOthersGroupAlert}, mp3File=${this.mp3File}, oggFile=${this.oggFile}, groups=${this.groups}, sortOrder=${this.sortOrder}, urgency=${this.urgency}, subscribed=${this.subscribed})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, school: ForeignKey<School> = this.school, name: string = this.name, icon: (RemoteFileRef | null) = this.icon, notifyOnlyThisGroup: (ForeignKey<ChatThread> | null) = this.notifyOnlyThisGroup, requiresAdminApproval: boolean = this.requiresAdminApproval, canStartOthersGroupAlert: boolean = this.canStartOthersGroupAlert, mp3File: (RemoteFileRef | null) = this.mp3File, oggFile: (RemoteFileRef | null) = this.oggFile, groups: (Array<number> | null) = this.groups, sortOrder: number = this.sortOrder, urgency: string = this.urgency, subscribed: boolean = this.subscribed): AlertType { return new AlertType(id, deleted, school, name, icon, notifyOnlyThisGroup, requiresAdminApproval, canStartOthersGroupAlert, mp3File, oggFile, groups, sortOrder, urgency, subscribed); }
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace AlertType {
    //! Declares com.tresitgroup.android.tresit.model.AlertType.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public textSearch(query: string): ApiFilter<AlertType> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertType>(`query=${query}`, undefined, (it: AlertType): boolean => (it.toString().toLowerCase().indexOf(query.toLowerCase()) != -1));
        }
        public schoolIn(schools: Array<ForeignKey<School>>): ApiFilter<AlertType> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertType>(`schools=${schools.join(",")}`, undefined, (it: AlertType): boolean => schools.length === 0  || schools.some((x) => safeEq(it.school, x)));
        }
        public forSchool(school: ForeignKey<School>): ApiFilter<AlertType> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertType>(`school=${school}`, undefined, (it: AlertType): boolean => it.school === school);
        }
        
        public forSchoolNotDeleted(school: ForeignKey<School>, isDeleted: (boolean | null) = false): ApiFilter<AlertType> {
            return new ApiFilter<AlertType>(`school=${school}&deleted=${isDeleted}`);
        }
        
        public forAlert(): ApiFilter<AlertType> {
            return new ApiFilter<AlertType>("from=alert");
        }
    }
}