//
// PostDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './post_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.PostDetailXml
export class PostDetailXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    close!: HTMLButtonElement
    postDetails!: HTMLElement
    name!: HTMLElement
    time!: HTMLElement
    resolveDetails!: HTMLElement
    resolvedName!: HTMLElement
    resolvedTime!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.close = getViewById<HTMLButtonElement>(view, "close")
        this.postDetails = getViewById<HTMLElement>(view, "postDetails")
        this.name = getViewById<HTMLElement>(view, "name")
        this.time = getViewById<HTMLElement>(view, "time")
        this.resolveDetails = getViewById<HTMLElement>(view, "resolveDetails")
        this.resolvedName = getViewById<HTMLElement>(view, "resolvedName")
        this.resolvedTime = getViewById<HTMLElement>(view, "resolvedTime")
        return view
    }
}
