// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/SchoolListVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { SchoolListXml } from '../../layout/SchoolListXml'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ComponentRowSchoolNameXml } from '../../layout/ComponentRowSchoolNameXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ManageAlertsVG } from './ManageAlertsVG'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { DialogWrapperVG } from '../DialogWrapperVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { setViewVisibility, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.settings.SchoolListVG
export class SchoolListVG extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.session = session;
        this.stack = stack;
        this.loading = new StandardObservableProperty<boolean>(false, undefined);
    }
    
    
    //--- Provides schoolId
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.SchoolListVG.title
    public get title(): string { return "Manage Alerts"; }
    
    
    public loading: MutableObservableProperty<boolean>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new SchoolListXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.fetchingSchools
        xViewFlipperBindLoading(xml.fetchingSchools, this.loading, undefined);
        
        //--- Set Up xml.schoolList
        xLinearLayoutBind<School>(xml.schoolList, xObservablePropertyMap<(Array<School> | null), Array<School>>(this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => it ?? []), new School(undefined, "School", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<School>): HTMLElement => {
            //--- Make Subview For xml.schoolList (overwritten on flow generation)
            const cellXml = new ComponentRowSchoolNameXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.unreadIndicator
            setViewVisibility(cellXml.unreadIndicator, "gone");
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.xmlRoot, undefined, (): void => {
                this.schoolClick(observable.value.id);
            });
            
            //--- Set Up cellXml.schoolName
            xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<School, string>(observable, (it: School): string => it.name));
            //--- End Make Subview For xml.schoolList
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action cellXmlXmlRootClick
    //--- Action schoolListClick
    
    public schoolClick(schoolId: ForeignKey<School>): void {
        this.stack.push(new DialogWrapperVG(new ManageAlertsVG(schoolId, this.session), this.stack));
    }
    
    //--- Body End
}

