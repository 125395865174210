// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { AlertHistoryVG } from './AlertHistoryVG'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { logVG } from '../../util/LogVG'
import { AdminXml } from '../../layout/AdminXml'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { ChatHistoryVG } from './ChatHistoryVG'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { iterLastOrNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { SelectSchoolVG } from '../SelectSchoolVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { AdminUsersVG } from './AdminUsersVG'
import { AdminAlertTypesVG } from './AdminAlertTypesVG'
import { AdminDashboardVG } from './AdminDashboardVG'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminVG
export class AdminVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly sessionStack: ObservableStack<ViewGenerator>;
    public readonly topStack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, sessionStack: ObservableStack<ViewGenerator>, topStack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.sessionStack = sessionStack;
        this.topStack = topStack;
        this.adminStack = new ObservableStack<ViewGenerator>();
        this.schoolFilter = new StandardObservableProperty<(School | null)>(null, undefined);
        this.onlySchool = xObservablePropertyMap<(Array<School> | null), (School | null)>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): (School | null) => ((): (School | null) => {
            const temp3605 = it;
            if(temp3605 === null) { return null }
            return (temp3605.length == 1 ? temp3605[0] : null)
        })());
        this.hasMultipleSchools = xObservablePropertyMap<(School | null), boolean>(this.onlySchool, (it: (School | null)): boolean => it === null);
        this.adminStack.reset(new AdminDashboardVG(this.dialog, this.schoolFilter, this.session, this.adminStack));
    }
    
    
    public readonly adminStack: ObservableStack<ViewGenerator>;
    
    public readonly schoolFilter: StandardObservableProperty<(School | null)>;
    
    
    public readonly onlySchool: ObservableProperty<(School | null)>;
    
    public readonly hasMultipleSchools: ObservableProperty<boolean>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminVG.title
    public get title(): string { return "Admin"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.topBar (overwritten on flow generation)
        
        //--- Set Up xml.back
        xViewBindVisible(xml.back, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.adminStack, (it: Array<ViewGenerator>): boolean => it.length > 0), xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<(School | null), boolean>(this.schoolFilter, (it: (School | null)): boolean => it !== null), this.hasMultipleSchools, (a: boolean, b: boolean): boolean => a && b), (a: boolean, b: boolean): boolean => a || b));
        xViewOnClick(xml.back, undefined, (): void => {
            this.onBackPressed();
        });
        
        //--- Set Up xml.header
        xTextViewBindString(xml.header, xObservablePropertyMap<(School | null), string>(this.schoolFilter, (it: (School | null)): string => (it?.name ?? null) ?? R._string.all_sites));
        
        //--- Set Up xml.canChangeSchoolFilter
        const temp3608 = xml.canChangeSchoolFilter;
        if(temp3608 !== null) {
            xViewBindVisible(temp3608, this.hasMultipleSchools)
        };
        
        //--- Set Up xml.subheader
        xTextViewBindString(xml.subheader, xObservablePropertyMap<Array<ViewGenerator>, string>(this.adminStack, (it: Array<ViewGenerator>): string => ((iterLastOrNull(it)?.titleString ?? null)?.get(dependency) ?? null) ?? "DIR-S"));
        
        //--- Set Up xml.viewTitle
        xml.viewTitle.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            if (this.hasMultipleSchools.value) {
                this.viewTitleClick();
            }
        };
        
        //--- Set Up xml.back
        xViewOnClick(xml.back, undefined, (): void => {
            this.onBackPressed();
        });
        
        
        //--- Set Up xml.content (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.content, dependency, this.adminStack);
        
        //--- Set Up xml.bottomBar (overwritten on flow generation)
        
        //--- Set Up xml.adminDashboard
        this.selectedIsAlpha(xml.adminDashboard, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.adminStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof AdminDashboardVG));
        xViewOnClick(xml.adminDashboard, undefined, (): void => {
            this.adminDashboardClick();
        });
        
        //--- Set Up xml.adminUsers
        this.selectedIsAlpha(xml.adminUsers, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.adminStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof AdminUsersVG));
        xViewOnClick(xml.adminUsers, undefined, (): void => {
            this.adminUsersClick();
        });
        
        //--- Set Up xml.alertTypes
        this.selectedIsAlpha(xml.alertTypes, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.adminStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof AdminAlertTypesVG));
        xViewOnClick(xml.alertTypes, undefined, (): void => {
            this.alertTypesClick();
        });
        
        //--- Set Up xml.alertHistory
        this.selectedIsAlpha(xml.alertHistory, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.adminStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof AlertHistoryVG));
        xViewOnClick(xml.alertHistory, undefined, (): void => {
            this.alertHistoryClick();
        });
        
        //--- Set Up xml.alertThreads
        this.selectedIsAlpha(xml.alertThreads, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.adminStack, (it: Array<ViewGenerator>): boolean => (it[0] ?? null) instanceof ChatHistoryVG));
        xViewOnClick(xml.alertThreads, undefined, (): void => {
            this.alertThreadsClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    public selectedIsAlpha(view: HTMLInputElement, selected: ObservableProperty<boolean>): void {
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(selected, undefined, undefined, (isSelected: boolean): void => {
            view.parentElement!.style.opacity = `${(isSelected ? 1 : 0.5)}`;
            view.checked = isSelected;
        }), xViewRemovedGet(view));
        
    }
    
    //--- Actions
    
    public onBackPressed(): boolean {
        if (this.adminStack.stack.length === 1) {
            this.topStack.pop();
            return true;
        } else {
            return this.adminStack.backPressPop();
        }
    }
    
    public viewTitleClick(): void {
        if (!((this.adminStack.stack[0] ?? null) instanceof AdminDashboardVG)) {
            this.dialog.push(new SelectSchoolVG((it: (School | null)): void => {
                this.schoolFilter.value = it;
            }, this.session, this.dialog));
        }
    }
    
    public adminDashboardClick(): void {
        this.adminStack.reset(new AdminDashboardVG(this.dialog, this.schoolFilter, this.session, this.adminStack));
    }
    public adminUsersClick(): void {
        this.adminStack.reset(new AdminUsersVG(this.dialog, this.schoolFilter, this.session, this.adminStack));
    }
    public alertTypesClick(): void {
        this.adminStack.reset(new AdminAlertTypesVG(this.schoolFilter, this.session, this.dialog, this.adminStack));
    }
    public alertHistoryClick(): void {
        this.adminStack.reset(new AlertHistoryVG(this.schoolFilter, this.session, this.adminStack, this.dialog));
    }
    
    public alertThreadsClick(): void {
        this.adminStack.reset(new ChatHistoryVG(this.dialog, this.schoolFilter, this.session, this.adminStack));
    }
    
    //--- Body End
}
