//
// CampusMapSvgXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './campus_map_svg.html'
//! Declares com.tresitgroup.android.tresit.layouts.CampusMapSvgXml
export class CampusMapSvgXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    mapLoading!: HTMLDivElement
    map!: HTMLCanvasElement
    mapReload!: HTMLButtonElement
    zoomIn!: HTMLButtonElement
    zoomOut!: HTMLButtonElement
    dummy!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.mapLoading = getViewById<HTMLDivElement>(view, "mapLoading")
        this.map = getViewById<HTMLCanvasElement>(view, "map")
        this.mapReload = getViewById<HTMLButtonElement>(view, "mapReload")
        this.zoomIn = getViewById<HTMLButtonElement>(view, "zoomIn")
        this.zoomOut = getViewById<HTMLButtonElement>(view, "zoomOut")
        this.dummy = getViewById<HTMLElement>(view, "dummy")
        return view
    }
}
