//
// GuidanceDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './guidance_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.GuidanceDetailXml
export class GuidanceDetailXml {
    xmlRoot!: HTMLElement
    alertTypeIcon!: HTMLImageElement
    alertTypeTitle!: HTMLElement
    guidances!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.alertTypeIcon = getViewById<HTMLImageElement>(view, "alert_type_icon")
        this.alertTypeTitle = getViewById<HTMLElement>(view, "alertTypeTitle")
        this.guidances = getViewById<HTMLDivElement>(view, "guidances")
        return view
    }
}
