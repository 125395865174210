// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/DialogWrapperVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { DialogWrapperXml } from '../layout/DialogWrapperXml'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { iterLastOrNull } from 'butterfly-web/dist/KotlinCollections'

//! Declares com.tresitgroup.android.tresit.vg.DialogWrapperVG
export class DialogWrapperVG extends ViewGenerator {
    public readonly content: ViewGenerator;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(content: ViewGenerator, dialog: ObservableStack<ViewGenerator>) {
        super();
        this.content = content;
        this.dialog = dialog;
        this.substack = new ObservableStack<ViewGenerator>();
        this.substack.reset(this.content);
    }
    
    
    public readonly substack: ObservableStack<ViewGenerator>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.DialogWrapperVG.title
    public get title(): string { return "Dialog Wrapper"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new DialogWrapperXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<Array<ViewGenerator>, string>(this.substack, (it: Array<ViewGenerator>): string => ((iterLastOrNull(it)?.titleString ?? null)?.get(dependency) ?? null) ?? ""));
        
        //--- Set Up xml.close (overwritten on flow generation)
        xViewOnClick(xml.close, undefined, (): void => {
            this.closeClick();
        });
        
        //--- Set Up xml.substack (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.substack, dependency, this.substack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeClick(): void {
        this.dialog.dismiss();
    }
    
    //--- Body End
}
