//
// ComponentGuidanceXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_guidance.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentGuidanceXml
export class ComponentGuidanceXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    title!: HTMLElement
    body!: HTMLElement
    remove!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.title = getViewById<HTMLElement>(view, "title")
        this.body = getViewById<HTMLElement>(view, "body")
        this.remove = getViewById<HTMLButtonElement>(view, "remove")
        return view
    }
}
