// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/map/Models.kt
// Package: com.tresitgroup.android.tresit.map
// Generated by Khrysalis - this file will be overwritten.
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { Projector } from './Projector'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Device } from '../model/Device'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { GeoCoordinate } from 'butterfly-web/dist/location/GeoCoordinate'
import { iterFlatMap as iterFlatMap } from 'butterfly-web/dist/kotlin/Collections'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { GeoCoordinateBounds } from './Geometry'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { cMax } from 'butterfly-web/dist/kotlin/Comparable'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.map.GeoShape
export interface GeoShape {
    
    readonly parsedGeometry: Array<GeoCoordinate>;
    
    readonly calculatedBounds: GeoCoordinateBounds;
    
    readonly calculatedLabelLocation: GeoCoordinate;
    
    readonly labelLocation: (GeoCoordinate | null);
    
    readonly name: string;
    
}


//! Declares com.tresitgroup.android.tresit.map.CampusGeoShape
export class CampusGeoShape implements GeoShape {
    public static implementsInterfaceComTresitgroupAndroidTresitMapGeoShape = true;
    public readonly id: number;
    public readonly name: string;
    public readonly geometry: string;
    public readonly buildings: Array<BuildingGeoShape>;
    public readonly labelLocation: (GeoCoordinate | null);
    public constructor(id: number = 0, name: string = "Campus", geometry: string, buildings: Array<BuildingGeoShape>, labelLocation: (GeoCoordinate | null) = null) {
        this.id = id;
        this.name = name;
        this.geometry = geometry;
        this.buildings = buildings;
        this.labelLocation = labelLocation;
        this.parsedGeometryField = null;
        this.calculatedLabelLocationField = null;
        this.calculatedBoundsField = null;
    }
    public static fromJson(obj: any): CampusGeoShape { return new CampusGeoShape(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["geometry"], [String]) as string,
        parseJsonTyped(obj["buildings"], [Array, [BuildingGeoShape]]) as Array<BuildingGeoShape>,
        parseJsonTyped(obj["labelLocation"], [GeoCoordinate]) as (GeoCoordinate | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        name: this.name,
        geometry: this.geometry,
        buildings: this.buildings,
        labelLocation: this.labelLocation
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.geometry);
        hash = 31 * hash + hashAnything(this.buildings);
        hash = 31 * hash + hashAnything(this.labelLocation);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof CampusGeoShape && safeEq(this.id, other.id) && safeEq(this.name, other.name) && safeEq(this.geometry, other.geometry) && safeEq(this.buildings, other.buildings) && safeEq(this.labelLocation, other.labelLocation) }
    public toString(): string { return `CampusGeoShape(id=${this.id}, name=${this.name}, geometry=${this.geometry}, buildings=${this.buildings}, labelLocation=${this.labelLocation})` }
    public copy(id: number = this.id, name: string = this.name, geometry: string = this.geometry, buildings: Array<BuildingGeoShape> = this.buildings, labelLocation: (GeoCoordinate | null) = this.labelLocation): CampusGeoShape { return new CampusGeoShape(id, name, geometry, buildings, labelLocation); }
    
    public clean(): CampusGeoShape {
        const cleanBuildings = this.buildings.filter((it: BuildingGeoShape): boolean => !(it.name.toLowerCase() === "offsite") && !(it.name.toLowerCase() === "off-site") && !(it.name.toLowerCase() === "off site")).map((it: BuildingGeoShape): BuildingGeoShape => it.clean());
        
        return this.copy(undefined, undefined, this.geometry === "" ? emitGeometry(xListBounds(iterToArray(iterFlatMap(cleanBuildings, (it: BuildingGeoShape): Iterable<GeoCoordinate> => it.parsedGeometry))).points()) : this.geometry, cleanBuildings, undefined);
    }
    
    public parsedGeometryField: (Array<GeoCoordinate> | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.CampusGeoShape.parsedGeometry
    public get parsedGeometry(): Array<GeoCoordinate> {
        const it_1504 = this.parsedGeometryField;
        if (it_1504 !== null) {
            return it_1504;
        }
        const newData = parseGeometry(this.geometry);
        
        this.parsedGeometryField = newData;
        return newData;
    }
    
    
    public calculatedLabelLocationField: (GeoCoordinate | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.CampusGeoShape.calculatedLabelLocation
    public get calculatedLabelLocation(): GeoCoordinate {
        if (this.labelLocation !== null) { return this.labelLocation! }
        const it_1505 = this.calculatedLabelLocationField;
        if (it_1505 !== null) {
            return it_1505;
        }
        const newCalc = this.calculatedBounds.center;
        
        this.calculatedLabelLocationField = newCalc;
        return newCalc;
    }
    
    
    public calculatedBoundsField: (GeoCoordinateBounds | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.CampusGeoShape.calculatedBounds
    public get calculatedBounds(): GeoCoordinateBounds {
        const it_1506 = this.calculatedBoundsField;
        if (it_1506 !== null) {
            return it_1506;
        }
        const newCalc = xListBounds(this.parsedGeometry);
        
        this.calculatedBoundsField = newCalc;
        return newCalc;
    }
    
}

//! Declares com.tresitgroup.android.tresit.map.BuildingGeoShape
export class BuildingGeoShape implements GeoShape {
    public static implementsInterfaceComTresitgroupAndroidTresitMapGeoShape = true;
    public readonly id: number;
    public readonly name: string;
    public readonly geometry: string;
    public readonly floors: Array<BuildingGeoShapeFloor>;
    public readonly labelLocation: (GeoCoordinate | null);
    public constructor(id: number, name: string, geometry: string, floors: Array<BuildingGeoShapeFloor>, labelLocation: (GeoCoordinate | null) = null) {
        this.id = id;
        this.name = name;
        this.geometry = geometry;
        this.floors = floors;
        this.labelLocation = labelLocation;
        this.zeroFloorIndex = cMax(this.floors.findIndex((it: BuildingGeoShapeFloor): boolean => (it.name.indexOf("1") != -1) || it.name === "Lobby" || it.name === "Main"), 0);
        this.parsedGeometryField = null;
        this.calculatedLabelLocationField = null;
        this.calculatedBoundsField = null;
    }
    public static fromJson(obj: any): BuildingGeoShape { return new BuildingGeoShape(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["geometry"], [String]) as string,
        parseJsonTyped(obj["floors"], [Array, [BuildingGeoShapeFloor]]) as Array<BuildingGeoShapeFloor>,
        parseJsonTyped(obj["labelLocation"], [GeoCoordinate]) as (GeoCoordinate | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        name: this.name,
        geometry: this.geometry,
        floors: this.floors,
        labelLocation: this.labelLocation
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.geometry);
        hash = 31 * hash + hashAnything(this.floors);
        hash = 31 * hash + hashAnything(this.labelLocation);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof BuildingGeoShape && safeEq(this.id, other.id) && safeEq(this.name, other.name) && safeEq(this.geometry, other.geometry) && safeEq(this.floors, other.floors) && safeEq(this.labelLocation, other.labelLocation) }
    public toString(): string { return `BuildingGeoShape(id=${this.id}, name=${this.name}, geometry=${this.geometry}, floors=${this.floors}, labelLocation=${this.labelLocation})` }
    public copy(id: number = this.id, name: string = this.name, geometry: string = this.geometry, floors: Array<BuildingGeoShapeFloor> = this.floors, labelLocation: (GeoCoordinate | null) = this.labelLocation): BuildingGeoShape { return new BuildingGeoShape(id, name, geometry, floors, labelLocation); }
    
    //! Declares com.tresitgroup.android.tresit.map.BuildingGeoShape.isStub
    public get isStub(): boolean { return this.floors.length === 1 && this.floors[0].rooms.length === 1; }
    
    
    public clean(): BuildingGeoShape {
        return this.copy(undefined, undefined, this.geometry === "" ? emitGeometry(xListBounds(iterToArray(iterFlatMap(iterToArray(iterFlatMap(this.floors, (it: BuildingGeoShapeFloor): Iterable<RoomGeoShape> => it.rooms)), (it: RoomGeoShape): Iterable<GeoCoordinate> => it.parsedGeometry))).points()) : this.geometry, undefined, undefined);
    }
    
    public readonly zeroFloorIndex: number;
    
    
    public floorByIndex(value: number): (BuildingGeoShapeFloor | null) {
        const newPosition = this.zeroFloorIndex + value;
        
        if (this.floors.length > newPosition) {
            return (this.floors[this.zeroFloorIndex + value] ?? null);
        } else {
            return (this.floors[this.zeroFloorIndex] ?? null);
        }
    }
    
    public closestFloorByIndex(value: number): (BuildingGeoShapeFloor | null) {
        return this.floors[Math.min(Math.max((this.zeroFloorIndex + value), 0), (this.floors.length - 1))];
    }
    
    public floorsByIndex(): Array<[number, BuildingGeoShapeFloor]> {
        return this.floors.map((_v, _i) => {
            const index = _i;
            const it = _v;
            return [index - this.zeroFloorIndex, it];
        });
    }
    
    public parsedGeometryField: (Array<GeoCoordinate> | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.BuildingGeoShape.parsedGeometry
    public get parsedGeometry(): Array<GeoCoordinate> {
        const it_1544 = this.parsedGeometryField;
        if (it_1544 !== null) {
            return it_1544;
        }
        const newData = parseGeometry(this.geometry);
        
        this.parsedGeometryField = newData;
        return newData;
    }
    
    
    public calculatedLabelLocationField: (GeoCoordinate | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.BuildingGeoShape.calculatedLabelLocation
    public get calculatedLabelLocation(): GeoCoordinate {
        if (this.labelLocation !== null) { return this.labelLocation! }
        const it_1545 = this.calculatedLabelLocationField;
        if (it_1545 !== null) {
            return it_1545;
        }
        const newCalc = this.calculatedBounds.center;
        
        this.calculatedLabelLocationField = newCalc;
        return newCalc;
    }
    
    
    public calculatedBoundsField: (GeoCoordinateBounds | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.BuildingGeoShape.calculatedBounds
    public get calculatedBounds(): GeoCoordinateBounds {
        const it_1546 = this.calculatedBoundsField;
        if (it_1546 !== null) {
            return it_1546;
        }
        const newCalc = xListBounds(this.parsedGeometry);
        
        this.calculatedBoundsField = newCalc;
        return newCalc;
    }
    
}

//! Declares com.tresitgroup.android.tresit.map.BuildingGeoShapeFloor
export class BuildingGeoShapeFloor {
    public readonly id: number;
    public readonly name: string;
    public readonly rooms: Array<RoomGeoShape>;
    public constructor(id: number, name: string, rooms: Array<RoomGeoShape>) {
        this.id = id;
        this.name = name;
        this.rooms = rooms;
    }
    public static fromJson(obj: any): BuildingGeoShapeFloor { return new BuildingGeoShapeFloor(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["rooms"], [Array, [RoomGeoShape]]) as Array<RoomGeoShape>
    ) }
    public toJSON(): object { return {
        id: this.id,
        name: this.name,
        rooms: this.rooms
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.rooms);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof BuildingGeoShapeFloor && safeEq(this.id, other.id) && safeEq(this.name, other.name) && safeEq(this.rooms, other.rooms) }
    public toString(): string { return `BuildingGeoShapeFloor(id=${this.id}, name=${this.name}, rooms=${this.rooms})` }
    public copy(id: number = this.id, name: string = this.name, rooms: Array<RoomGeoShape> = this.rooms): BuildingGeoShapeFloor { return new BuildingGeoShapeFloor(id, name, rooms); }
}

//! Declares com.tresitgroup.android.tresit.map.RoomGeoShape
export class RoomGeoShape implements GeoShape {
    public static implementsInterfaceComTresitgroupAndroidTresitMapGeoShape = true;
    public readonly id: number;
    public readonly name: string;
    public readonly geometry: string;
    public readonly labelLocation: (GeoCoordinate | null);
    public constructor(id: number, name: string, geometry: string, labelLocation: (GeoCoordinate | null) = null) {
        this.id = id;
        this.name = name;
        this.geometry = geometry;
        this.labelLocation = labelLocation;
        this.parsedGeometryField = null;
        this.calculatedLabelLocationField = null;
        this.calculatedBoundsField = null;
    }
    public static fromJson(obj: any): RoomGeoShape { return new RoomGeoShape(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["geometry"], [String]) as string,
        parseJsonTyped(obj["labelLocation"], [GeoCoordinate]) as (GeoCoordinate | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        name: this.name,
        geometry: this.geometry,
        labelLocation: this.labelLocation
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.geometry);
        hash = 31 * hash + hashAnything(this.labelLocation);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof RoomGeoShape && safeEq(this.id, other.id) && safeEq(this.name, other.name) && safeEq(this.geometry, other.geometry) && safeEq(this.labelLocation, other.labelLocation) }
    public toString(): string { return `RoomGeoShape(id=${this.id}, name=${this.name}, geometry=${this.geometry}, labelLocation=${this.labelLocation})` }
    public copy(id: number = this.id, name: string = this.name, geometry: string = this.geometry, labelLocation: (GeoCoordinate | null) = this.labelLocation): RoomGeoShape { return new RoomGeoShape(id, name, geometry, labelLocation); }
    
    public parsedGeometryField: (Array<GeoCoordinate> | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.RoomGeoShape.parsedGeometry
    public get parsedGeometry(): Array<GeoCoordinate> {
        const it_1547 = this.parsedGeometryField;
        if (it_1547 !== null) {
            return it_1547;
        }
        const newData = parseGeometry(this.geometry);
        
        this.parsedGeometryField = newData;
        return newData;
    }
    
    
    public calculatedLabelLocationField: (GeoCoordinate | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.RoomGeoShape.calculatedLabelLocation
    public get calculatedLabelLocation(): GeoCoordinate {
        if (this.labelLocation !== null) { return this.labelLocation! }
        const it_1548 = this.calculatedLabelLocationField;
        if (it_1548 !== null) {
            return it_1548;
        }
        const newCalc = this.calculatedBounds.center;
        
        this.calculatedLabelLocationField = newCalc;
        return newCalc;
    }
    
    
    public calculatedBoundsField: (GeoCoordinateBounds | null);
    
    
    //! Declares com.tresitgroup.android.tresit.map.RoomGeoShape.calculatedBounds
    public get calculatedBounds(): GeoCoordinateBounds {
        const it_1549 = this.calculatedBoundsField;
        if (it_1549 !== null) {
            return it_1549;
        }
        const newCalc = xListBounds(this.parsedGeometry);
        
        this.calculatedBoundsField = newCalc;
        return newCalc;
    }
    
}


//! Declares com.tresitgroup.android.tresit.map.DeviceGeoShape
export class DeviceGeoShape implements GeoShape {
    public static implementsInterfaceComTresitgroupAndroidTresitMapGeoShape = true;
    public readonly device: Device;
    public readonly location: GeoCoordinate;
    public readonly name: string;
    public constructor(device: Device, location: GeoCoordinate, name: string = "") {
        this.device = device;
        this.location = location;
        this.name = name;
        this.bounds = new GeoCoordinateBounds(this.location, this.location);
    }
    
    public readonly bounds: GeoCoordinateBounds;
    
    //! Declares com.tresitgroup.android.tresit.map.DeviceGeoShape.parsedGeometry
    public get parsedGeometry(): Array<GeoCoordinate> { return [this.location]; }
    
    //! Declares com.tresitgroup.android.tresit.map.DeviceGeoShape.calculatedBounds
    public get calculatedBounds(): GeoCoordinateBounds { return this.bounds; }
    
    //! Declares com.tresitgroup.android.tresit.map.DeviceGeoShape.calculatedLabelLocation
    public get calculatedLabelLocation(): GeoCoordinate { return this.location; }
    
    //! Declares com.tresitgroup.android.tresit.map.DeviceGeoShape.labelLocation
    public get labelLocation(): (GeoCoordinate | null) { return this.location; }
    
}


//! Declares com.tresitgroup.android.tresit.map.parseGeometry
export function parseGeometry(geometry: string): Array<GeoCoordinate> {
    return iterToArray(iterableFilterNotNull(iterMap(geometry.split(','), (it: string): (GeoCoordinate | null) => {
        const parts = it.trim().split(' ');
        
        if (parts.length === 2) {
            return new GeoCoordinate(parseFloat(parts[1]), parseFloat(parts[0]));
        } else {
            return null;
        }
    })));
}

//! Declares com.tresitgroup.android.tresit.map.emitGeometry
export function emitGeometry(geometry: Array<GeoCoordinate>): string {
    return geometry.map((it: GeoCoordinate): string => `${it.longitude} ${it.latitude}`).join(", ");
}


//--- Rendering

//! Declares com.tresitgroup.android.tresit.map.bounds>kotlin.collections.List<com.lightningkite.butterfly.location.GeoCoordinate>
export function xListBounds(this_: Array<GeoCoordinate>): GeoCoordinateBounds {
    let lonMin = 1000.0;
    
    let lonMax = (-1000.0);
    
    let latMin = 1000.0;
    
    let latMax = (-1000.0);
    
    for (const item of this_) {
        lonMin = Math.min(item.longitude, lonMin);
        lonMax = Math.max(item.longitude, lonMax);
        latMin = Math.min(item.latitude, latMin);
        latMax = Math.max(item.latitude, latMax);
    }
    return new GeoCoordinateBounds(new GeoCoordinate(latMin, lonMin), new GeoCoordinate(latMax, lonMax));
}

//! Declares com.tresitgroup.android.tresit.map.shouldRenderDetail>com.tresitgroup.android.tresit.map.GeoShape
export function xGeoShapeShouldRenderDetail(this_: GeoShape, projector: Projector): boolean {
    //    return calculatedBounds takesUp projector.visibleBounds > 1.01f
    // intersecting and zoomed enough
    return this_.calculatedBounds.intersects(projector.visibleBounds) && Math.max(this_.calculatedBounds.width / projector.visibleBounds.width, this_.calculatedBounds.height / projector.visibleBounds.height) > 0.5;
}

//! Declares com.tresitgroup.android.tresit.map.shouldRenderLabel>com.tresitgroup.android.tresit.map.GeoShape
export function xGeoShapeShouldRenderLabel(this_: GeoShape, projector: Projector): boolean {
    const textWidth = this_.name.length * 10.0;
    
    const areaWidth = projector.convertToX(this_.calculatedBounds.northeast) - projector.convertToX(this_.calculatedBounds.southwest);
    
    return projector.zoom > 20.5 || textWidth < areaWidth;
}

//! Declares com.tresitgroup.android.tresit.map.render>com.tresitgroup.android.tresit.map.GeoShape
export function xGeoShapeRender(this_: GeoShape, canvas: CanvasRenderingContext2D, projector: Projector, paint: Paint): void {
    const path = new Path2D();
    
    let moved = false;
    
    for (const point of this_.parsedGeometry) {
        if (moved) {
            path.lineTo(projector.convertToX(point), projector.convertToY(point));
        } else {
            moved = true;
            path.moveTo(projector.convertToX(point), projector.convertToY(point));
        }
    }
    path.closePath();
    paint.render(canvas, path);
}

//! Declares com.tresitgroup.android.tresit.map.render>com.tresitgroup.android.tresit.map.DeviceGeoShape
export function xDeviceGeoShapeRender(this_: DeviceGeoShape, canvas: CanvasRenderingContext2D, projector: Projector, innerPaint: Paint, outerPaint: Paint, radius: number): void {
    canvas.beginPath(); canvas.arc(projector.convertToX(this_.location), projector.convertToY(this_.location), radius, 0, Math.PI * 2); innerPaint.complete(canvas);
    canvas.beginPath(); canvas.arc(projector.convertToX(this_.location), projector.convertToY(this_.location), radius, 0, Math.PI * 2); outerPaint.complete(canvas);
}