// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/DemoEmailVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ApiOption } from '../api/ApiOption'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { DemoEmailXml } from '../layout/DemoEmailXml'
import { DemoSessionVG } from './DemoSessionVG'

//! Declares com.tresitgroup.android.tresit.vg.DemoEmailVG
export class DemoEmailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.submitting = new StandardObservableProperty<boolean>(false, undefined);
        this.email = new StandardObservableProperty<string>("", undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.DemoEmailVG.title
    public get title(): string { return "Demo Email"; }
    
    
    public readonly submitting: MutableObservableProperty<boolean>;
    
    public readonly email: MutableObservableProperty<string>;
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new DemoEmailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Set Up xml.background
        xml.backgroundDelegate.setup(dependency);
        
        //--- Set Up xml.email
        xEditTextBindString(xml.email, this.email);
        
        //--- Set Up xml.submitWorking
        xViewFlipperBindLoading(xml.submitWorking, this.submitting, undefined);
        
        //--- Set Up xml.submit (overwritten on flow generation)
        xViewOnClick(xml.submit, undefined, (): void => {
            this.submitClick();
        });
        
        //--- Set Up xml.skip (overwritten on flow generation)
        xViewOnClick(xml.skip, undefined, (): void => {
            this.skipClick();
        });
        
        //--- Set Up xml.back (overwritten on flow generation)
        xViewOnClick(xml.back, undefined, (): void => {
            this.backClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public submitClick(): void {
        xDisposableForever<SubscriptionLike>(ApiOption.Companion.INSTANCE.current.submitContactEmail(this.email.value).subscribe((it: void): void => {
            this.root.reset(new DemoSessionVG(this.dialog, this.root));
        }, (it: any): void => {}));
    }
    
    public skipClick(): void {
        this.root.reset(new DemoSessionVG(this.dialog, this.root));
    }
    public backClick(): void {
        this.root.pop();
    }
    
    //--- Body End
}
