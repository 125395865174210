//
// ConversationDetailHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './conversation_detail_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.ConversationDetailHistoryXml
export class ConversationDetailHistoryXml {
    xmlRoot!: HTMLElement
    details!: HTMLDivElement
    typeIcon!: HTMLImageElement
    groupName!: HTMLElement
    participants!: HTMLElement
    moreParticipants!: HTMLElement
    threadMessages!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.details = getViewById<HTMLDivElement>(view, "details")
        this.typeIcon = getViewById<HTMLImageElement>(view, "typeIcon")
        this.groupName = getViewById<HTMLElement>(view, "groupName")
        this.participants = getViewById<HTMLElement>(view, "participants")
        this.moreParticipants = getViewById<HTMLElement>(view, "moreParticipants")
        this.threadMessages = getViewById<HTMLDivElement>(view, "threadMessages")
        return view
    }
}
