// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/FormAddUserVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { safeEq } from 'butterfly-web/dist/kotlin/Language'
import { ViewStringRaw } from 'butterfly-web/dist/views/ViewString'
import { User } from '../../model/User'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { getNO_ID } from '../../model/HasId'
import { filter as rxFilter, take as rxTake } from 'rxjs/operators'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { FormAddUserXml } from '../../layout/FormAddUserXml'
import { SessionApi } from '../../api/SessionApi'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyObservableNNGet } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ComponentUserSchoolXml } from '../../layout/ComponentUserSchoolXml'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { find as iterFind, first as iterFirst } from 'butterfly-web/dist/kotlin/lazyOp'
import { PartSchoolSelectVG } from './PartSchoolSelectVG'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { xMutableObservablePropertyMapWithExisting } from 'butterfly-web/dist/observables/TransformedMutableObservableProperty2'
import { spinnerBind } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { School } from '../../model/School'
import { Group } from '../../model/Group'

//! Declares com.tresitgroup.android.tresit.vg.admin.FormAddUserVG
export class FormAddUserVG extends ViewGenerator {
    public readonly newUser: MutableObservableProperty<User>;
    public readonly session: SessionApi;
    public readonly userSchools: MutableObservableProperty<Array<School>>;
    public constructor(newUser: MutableObservableProperty<User>, session: SessionApi, userSchools: MutableObservableProperty<Array<School>>) {
        super();
        this.newUser = newUser;
        this.session = session;
        this.userSchools = userSchools;
        this.newSchool = new StandardObservableProperty<boolean>(false, undefined);
        this.newSchoolFilter = new PartSchoolSelectVG(this.session);
        this.groups = xObservablePropertyMap<(Array<Group> | null), Array<Group>>(this.session.permissionGroups.observableListSimple(ApiFilter.Companion.INSTANCE.all<Group>(), undefined, undefined, undefined, undefined, undefined), (it: (Array<Group> | null)): Array<Group> => it ?? []);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.FormAddUserVG.title
    public get title(): string { return "Form Add User"; }
    
    
    public readonly newSchool: MutableObservableProperty<boolean>;
    
    public readonly newSchoolFilter: PartSchoolSelectVG;
    
    public readonly groups: ObservableProperty<Array<Group>>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new FormAddUserXml();
        
        const view = xml.setup(dependency);
        
        
        //--- From Xml
        this.generateFromXml(dependency, xml);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public generateFromXml(dependency: Window, xml: FormAddUserXml): void {
        
        //--- Subscribe to groups
        xDisposableUntil<SubscriptionLike>(xObservablePropertyObservableNNGet(this.groups).pipe(rxFilter((it: Array<Group>): boolean => it.length !== 0)).pipe(rxTake(1)).subscribe((it: Array<Group>): void => {
            if (this.newUser.value.groups.length === 0) {
                this.newUser.value = this.newUser.value.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [(iterFind(it, (it: Group): boolean => it.name === "Base User")?.id ?? null) ?? iterFirst(it).id], undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            }
        }, undefined, undefined), xViewRemovedGet(xml.xmlRoot));
        
        //--- Set Up xml.title (overwritten on flow generation)
        xTextViewBindString(xml.title, new ConstantObservableProperty<string>("Add New User"));
        
        //--- Set Up xml.firstName
        xEditTextBindString(xml.firstName, xMutableObservablePropertyMapWithExisting<User, string>(this.newUser, (it: User): string => it.firstName, (user: User, value: string): User => user.copy(undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.lastName
        xEditTextBindString(xml.lastName, xMutableObservablePropertyMapWithExisting<User, string>(this.newUser, (it: User): string => it.lastName, (user: User, value: string): User => user.copy(undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        xCompoundButtonBind(xml.isVip, xMutableObservablePropertyMapWithExisting<User, boolean>(this.newUser, (it: User): boolean => it.isVip, (user: User, value: boolean): User => user.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined)));
        
        //--- Set Up xml.cellPhone
        xEditTextBindString(xml.cellPhone, xMutableObservablePropertyMapWithExisting<User, string>(this.newUser, (it: User): string => it.cellPhone ?? "", (user: User, value: string): User => user.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.email
        xEditTextBindString(xml.email, xMutableObservablePropertyMapWithExisting<User, string>(this.newUser, (it: User): string => it.email, (user: User, value: string): User => user.copy(undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.password
        xEditTextBindString(xml.password, xMutableObservablePropertyMapWithExisting<User, string>(this.newUser, (it: User): string => it.password ?? "", (user: User, value: string): User => user.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //--- Set Up xml.confirmPassword
        xEditTextBindString(xml.confirmPassword, xMutableObservablePropertyMapWithExisting<User, string>(this.newUser, (it: User): string => it.confirmPassword ?? "", (user: User, value: string): User => user.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined)));
        
        
        //--- Set Up xml.userType
        spinnerBind<Group>(xml.userType, this.groups, xObservablePropertyWithWrite<Group>(xObservablePropertyCombine<Array<Group>, User, Group>(this.groups, this.newUser, (groups: Array<Group>, user: User): Group => iterFind(groups, (it: Group): boolean => it.id === (user.groups[0] ?? null)) ?? iterFind(groups, (it: Group): boolean => it.name === "Base User") ?? new Group(getNO_ID(), "")), (group: Group): void => {
                    this.newUser.value = this.newUser.value.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [group.id], undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            }), (it: Group): string => {
                return it.name;
        });
        
        //--- Set Up xml.userSchoolsLoading
        xViewFlipperBindLoading(xml.userSchoolsLoading, xObservablePropertyMap<(Array<School> | null), boolean>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): boolean => it === null), undefined);
        
        //--- Set Up xml.userSchools
        xLinearLayoutBind<School>(xml.userSchools, this.userSchools, new School(undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (school: ObservableProperty<School>): HTMLElement => {
            //--- Make Subview For xml.userSchools (overwritten on flow generation)
            const cellXml = new ComponentUserSchoolXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root (overwritten on flow generation)
            
            //--- Set Up cellXml.label
            xTextViewBindString(cellXml.label, xObservablePropertyMap<School, string>(school, (it: School): string => it.name));
            
            //--- Set Up cellXml.remove
            xViewOnClick(cellXml.remove, undefined, (): void => {
                this.cellXmlRemoveClick(school.value.id);
            });
            //--- End Make Subview For xml.userSchools (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.newSchool
        xViewBindExists(xml.newSchool, this.newSchool);
        
        //--- Set Up xml.addSite (overwritten on flow generation)
        xViewOnClick(xml.addSite, undefined, (): void => {
            this.addSiteClick();
        });
        
        //--- Set Up xml.newSchoolSelect.xmlRoot
        //--- Set Up xml.newSchoolSelect.schoolFilter
        this.newSchoolFilter.bindSelect((it: School): void => {
            this.chooseNewSchool(it);
        });
        this.newSchoolFilter.inlineGenerate(xml.newSchoolSelect);
        
        //--- Set Up xml.addSite
        xViewBindExists(xml.addSite, xObservablePropertyMap<boolean, boolean>(this.newSchool, (it: boolean): boolean => (!it)));
        xViewOnClick(xml.addSite, undefined, (): void => {
            this.addSiteClick();
        });
        
        //--- End From Xml
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cellXmlRemoveClick(id: (ForeignKey<School> | null)): void {
        this.userSchools.value = this.userSchools.value.filter((it: School): boolean => !(it.id === id));
    }
    
    public addSiteClick(): void {
        this.newSchool.value = true;
    }
    
    public chooseNewSchool(school: School): void {
        if (this.userSchools.value.map((it: School): number => it.id).some((x) => safeEq(school.id, x)) === false) {
            this.newSchool.value = false;
            const temp3847 = this.userSchools;
            temp3847.value = temp3847.value.concat([school]);
        } else {
            showDialogAlert(new ViewStringRaw("This user already belongs to that school."));
        }
    }
    
    //--- Body End
}
