// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/PinCreateVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { post } from 'butterfly-web/dist/delay'
import { PinCreateXml } from '../layout/PinCreateXml'
import { SessionApi } from '../api/SessionApi'
import { PinVG } from './PinVG'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { logVG } from '../util/LogVG'
import { ApplicationAccess } from 'butterfly-web/dist/ApplicationAccess'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { Preferences } from 'butterfly-web/dist/Preferences'

//! Declares com.tresitgroup.android.tresit.vg.PinCreateVG
export class PinCreateVG extends ViewGenerator implements HasBackAction, PinVG {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public static implementsInterfaceComTresitgroupAndroidTresitVgPinVG = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.firstPin = new StandardObservableProperty<string>("", undefined);
        this.verifyPin = new StandardObservableProperty<string>("", undefined);
    }
    
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.PinCreateVG.title
    public get title(): string { return "Pin Create"; }
    
    
    public onBackPressed(): boolean {
        return true;
    }
    
    public readonly firstPin: StandardObservableProperty<string>;
    
    public readonly verifyPin: StandardObservableProperty<string>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PinCreateXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.background
        xml.backgroundDelegate.setup(dependency);
        
        //--- Set Up xml.pin
        xEditTextBindString(xml.pin, this.firstPin);
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<string>(this.firstPin, undefined, undefined, (it: string): void => {
            if (it.length === 4) {
                xml.verifyPin.focus();
            } else if (it.length > 4) {
                const partial = it.substring(4);
                
                post((): void => {
                    this.firstPin.value = partial;
                });
            }
        }), xViewRemovedGet(xml.pin));
        post((): void => {
            xml.pin.focus();
            ApplicationAccess.INSTANCE.softInputActive.value = true;
        });
        
        //--- Set Up xml.verifyPin
        xEditTextBindString(xml.verifyPin, this.verifyPin);
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<void>(xObservablePropertyCombine<string, string, void>(this.firstPin, this.verifyPin, (a: string, b: string): void => {
            if (b.length === 4) {
                if (a === b) {
                    //complete!
                    Preferences.INSTANCE.set<string>([String], PinCreateVG.Companion.INSTANCE.prefKey, a);
                    this.dialog.dismiss();
                    ApplicationAccess.INSTANCE.softInputActive.value = false;
                } else {
                    //reset
                    post((): void => {
                        this.firstPin.value = "";
                        this.verifyPin.value = "";
                        xml.pin.focus();
                    });
                }
            }
            return undefined;
        }), undefined, undefined, undefined), xViewRemovedGet(xml.pin));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Action pinClick
    
    //--- Action verifyPinClick
    
    //--- Body End
}
export namespace PinCreateVG {
    //! Declares com.tresitgroup.android.tresit.vg.PinCreateVG.Companion
    export class Companion {
        private constructor() {
            this.prefKey = "com.tresitgroup.android.tresit.pin";
        }
        public static INSTANCE = new Companion();
        
        public readonly prefKey: string;
        
    }
}
