//
// BuildingMapSvgXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './building_map_svg.html'
//! Declares com.tresitgroup.android.tresit.layouts.BuildingMapSvgXml
export class BuildingMapSvgXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    back!: HTMLButtonElement
    floor!: HTMLSelectElement
    mapLoading!: HTMLDivElement
    map!: HTMLCanvasElement
    mapReload!: HTMLButtonElement
    zoomIn!: HTMLButtonElement
    zoomOut!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.back = getViewById<HTMLButtonElement>(view, "back")
        this.floor = getViewById<HTMLSelectElement>(view, "floor")
        this.mapLoading = getViewById<HTMLDivElement>(view, "mapLoading")
        this.map = getViewById<HTMLCanvasElement>(view, "map")
        this.mapReload = getViewById<HTMLButtonElement>(view, "mapReload")
        this.zoomIn = getViewById<HTMLButtonElement>(view, "zoomIn")
        this.zoomOut = getViewById<HTMLButtonElement>(view, "zoomOut")
        return view
    }
}
