// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/AlertVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { distinctUntilChanged as rxDistinctUntilChanged, flatMap as rxFlatMap, map as rxMap, tap as rxTap } from 'rxjs/operators'
import { xObservablePropertyObservableGet, xObservablePropertyObservableNNGet } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { Observable, ObservableInput, SubscriptionLike, of as rxOf } from 'rxjs'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { AlertStatusSafeVG } from './AlertStatusSafeVG'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { AdminApprovalListResponse } from '../../model/AdminApprovalListResponse'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { SessionApi } from '../../api/SessionApi'
import { AlertsVG } from './AlertsVG'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { AlertStatusActiveVG } from './AlertStatusActiveVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { Session } from '../../model/Session'
import { AlertXml } from '../../layout/AlertXml'
import { School } from '../../model/School'
import { AdminApproveDialogVG } from '../admin/AdminApproveDialogVG'

//! Declares com.tresitgroup.android.tresit.vg.alert.AlertVG
export class AlertVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.alertStack = new ObservableStack<ViewGenerator>();
        this.schoolName = new StandardObservableProperty<string>("", undefined);
        this.s = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
        this.alertRequest = ((): (SubscriptionLike | null) => {
            const temp4001 = this.s;
            if(temp4001 === null) { return null }
            return ((it: Session): SubscriptionLike => xSingleCallDisplayingError<Array<AdminApprovalListResponse>>(this.session.rawApi.adminApproval(it, ""), undefined, (adminApprovalResponse: Array<AdminApprovalListResponse>): void => {
                if (adminApprovalResponse.length !== 0) {
                    let adminAlertJson = adminApprovalResponse[0];
                    
                    const approveAdminResponse = new AdminApprovalListResponse(adminAlertJson.id, adminAlertJson.schoolId, adminAlertJson.alertType ?? "", adminAlertJson.schoolName ?? "", `${adminAlertJson.initiatorName}`, `${adminAlertJson.description}`, adminAlertJson.initiator, adminAlertJson.cellNumber);
                    
                    
                    console.log(`this is from alertVg-----> ${adminAlertJson.cellNumber}`);
                    
                    this.alertStack.reset(new AdminApproveDialogVG(approveAdminResponse, this.session, this.dialog, this.schoolFilter, this.alertStack, this.alertStack));
                    
                }
            }))(temp4001)
        })();
        this.alertStack.reset(new ViewGenerator.Default());
    }
    
    
    public readonly alertStack: ObservableStack<ViewGenerator>;
    
    //--- Provides schoolId
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertVG.title
    public get title(): string { return "Alert"; }
    
    
    public onBackPressed(): boolean {
        return this.alertStack.backPressPop();
    }
    
    public schoolName: ObservableProperty<string>;
    
    
    public viewDecider(): Observable<void> {
        return xObservablePropertyObservableGet(this.schoolFilter).pipe(rxFlatMap((schoolBoxed: (School | null)): ObservableInput<void> => {
            const school = schoolBoxed
            if(school === null) { return rxOf(undefined).pipe(rxTap((it: void): void => {
                this.alertStack.reset(new AlertsVG(this.dialog, this.schoolFilter, this.session, this.alertStack));
            })) }
            return xObservablePropertyObservableNNGet(xObservablePropertyMap<(Array<Alert> | null), number>(this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(school.id), undefined, 1, undefined, undefined, undefined), (it: (Array<Alert> | null)): number => (((): (Alert | null) => {
                const temp4014 = it;
                if(temp4014 === null) { return null }
                return (temp4014[0] ?? null)
            })()?.id ?? null) ?? (-1))).pipe(rxDistinctUntilChanged()).pipe(rxTap((alertId: number): void => {
                if (!(alertId === (-1))) {
                    this.alertStack.reset(new AlertStatusActiveVG(alertId, this.alertStack, this.dialog, school.id, this.session));
                } else {
                    this.alertStack.reset(new AlertStatusSafeVG(this.alertStack, this.dialog, school.id, school.drill_enabled, this.session, this.alertStack, this.schoolFilter));
                }
            })).pipe(rxMap((it: number): void => {
                return undefined;
            }));
        }));
    }
    
    public readonly s: (Session | null);
    
    
    public readonly alertRequest: (SubscriptionLike | null);
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertXml();
        
        const view = xml.setup(dependency);
        
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Decide where we going
        xDisposableUntil<SubscriptionLike>(this.viewDecider().subscribe(undefined, undefined, undefined), xViewRemovedGet(view));
        
        //--- Set Up xml.content (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.content, dependency, this.alertStack);
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}