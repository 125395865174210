// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/GoogleMapVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { MapPaints } from './Paints'
import { GeoCoordinateBounds, xListPolygonContains } from '../../map/Geometry'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xResponseReadText, xSingleReadJsonDebug } from 'butterfly-web/dist/net/RxHttpAssist'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { xViewBindExists, xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { CombinedStatus, xSequenceWorst } from '../../model/CombinedStatus'
import { BuildingGeoShape, BuildingGeoShapeFloor, CampusGeoShape, DeviceGeoShape, GeoShape, RoomGeoShape, xDeviceGeoShapeRender, xGeoShapeRender, xGeoShapeShouldRenderDetail, xGeoShapeShouldRenderLabel } from '../../map/Models'
import { xObservablePropertyObservableNNGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { EqualOverrideSet, iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { GeoAddress } from 'butterfly-web/dist/location/GeoAddress'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { xSessionApiBuildingStatusCached, xSessionApiCampusStatusCached, xSessionApiFloorStatusCached, xSessionApiRoomStatusCached } from './statuses'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { xActivityAccessGeocode } from 'butterfly-web/dist/location/Geocoding'
import { filter as iterFilter, find as iterFind, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { mwovConfigure, mwovFocus, mwovRequestRedraw, mwovSetBounds } from '../../map/MapWithOverlayView'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { StatusEnum } from '../../model/StatusEnum'
import { OffsiteResponse } from '../../model/OffsiteResponse'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { GeoCoordinate } from 'butterfly-web/dist/location/GeoCoordinate'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { xObservableAsObservableProperty } from 'butterfly-web/dist/observables/EventToObservableProperty'
import { xLinearLayoutParams } from 'butterfly-web/dist/views/LinearLayout'
import { Device } from '../../model/Device'
import { xObservablePropertyFlatMap, xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { Projector } from '../../map/Projector'
import { getAnimationFrame, post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { numberToColor } from 'butterfly-web/dist/views/Colors'
import { ComponentFloorXml } from '../../layout/ComponentFloorXml'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { GoogleMapXml } from '../../layout/GoogleMapXml'
import { printStackTrace, runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xCanvasDrawTextCentered } from 'butterfly-web/dist/views/draw/Canvas'
import { DelayedApi } from '../../api/DelayedApi'
import { AlignPair } from 'butterfly-web/dist/views/geometry/Align'
import { Floor } from '../../model/Floor'
import { NumberRange, also, hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { xEditTextSetOnDoneClick } from 'butterfly-web/dist/views/EditText'
import { DisplayMetrics } from 'butterfly-web/dist/views/DisplayMetrics'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { getMockDatabase } from '../../api/ApiOption'
import { Alert } from '../../model/Alert'
import { setViewBackgroundClass, setViewVisibility, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { R } from '../../R'
import { xStringFromJsonString } from 'butterfly-web/dist/Codable'
import { ForeignKey } from '../../model/ForeignKey'
import { SelectStatusVG } from '../alert/SelectStatusVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'
import { distinctUntilChanged as rxDistinctUntilChanged, map as rxMap, tap as rxTap } from 'rxjs/operators'
import { MockApi } from '../../api/MockApi'
import { School } from '../../model/School'
import { xSessionApiOffsiteLocation } from './offsite'
import { Subject, SubscriptionLike } from 'rxjs'
import { CacheEdge } from '../../api/CacheEdge'
import { Room } from '../../model/Room'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'

//! Declares com.tresitgroup.android.tresit.vg.map.GoogleMapVG
export class GoogleMapVG extends ViewGenerator {
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly mapUrl: string;
    public readonly onDeviceTap:  ((a: ForeignKey<Device>) => void);
    public readonly onRoomTap:  ((a: ForeignKey<Room>) => void);
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>, mapUrl: string, onDeviceTap:  ((a: ForeignKey<Device>) => void), onRoomTap:  ((a: ForeignKey<Room>) => void), schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.stack = stack;
        this.dialog = dialog;
        this.mapUrl = mapUrl;
        this.onDeviceTap = onDeviceTap;
        this.onRoomTap = onRoomTap;
        this.schoolId = schoolId;
        this.session = session;
        this.mapStyle = "[{\"featureType\":\"administrative\",\"elementType\":\"geometry\",\"stylers\":[{\"visibility\":\"off\"}]},{\"featureType\":\"poi\",\"stylers\":[{\"visibility\":\"off\"}]},{\"featureType\":\"road\",\"elementType\":\"labels.icon\",\"stylers\":[{\"visibility\":\"off\"}]},{\"featureType\":\"transit\",\"stylers\":[{\"visibility\":\"off\"}]}]";
        this.visibleBuildings = new StandardObservableProperty<Set<BuildingGeoShape>>(new EqualOverrideSet([]), undefined);
        this.settingOffsite = new StandardObservableProperty<boolean>(false, undefined);
        this.floorIndex = new StandardObservableProperty<number>(0, undefined);
        this.loadingMap = new StandardObservableProperty<boolean>(false, undefined);
        this.geometry = new StandardObservableProperty<(CampusGeoShape | null)>(null, undefined);
        this.currentViewBounds = new StandardObservableProperty<(GeoCoordinateBounds | null)>(null, undefined);
        this.useConstrainMap = true;
        this.editMode = false;
        this.ongoingAlerts = this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(this.schoolId), undefined, undefined, undefined, undefined, undefined);
        this.hasActiveAlert = xObservablePropertyMap<(Array<Alert> | null), boolean>(this.ongoingAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4619 = it;
            if(temp4619 === null) { return null }
            return temp4619.length === 0
        })() === false);
        this.alertId = (((): (Alert | null) => {
            const temp4620 = this.ongoingAlerts.value;
            if(temp4620 === null) { return null }
            return (temp4620[0] ?? null)
        })()?.id ?? null);
        this.offsiteLocation = xSessionApiOffsiteLocation(this.session, this.schoolId);
        this.deviceSizeOffset = .00001;
        this.rooms = this.session.rooms.observableListSimple(Room.Companion.INSTANCE.forSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined);
        this.floors = this.session.floors.observableListSimple(Floor.Companion.INSTANCE.forSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined);
        this.buildings = this.session.buildings.observableListSimple(Building.Companion.INSTANCE.belongingToSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined);
        this.devices = xObservablePropertyMap<(Array<Device> | null), (Array<DeviceGeoShape> | null)>(this.session.devices.observableListSimple(Device.Companion.INSTANCE.belongingToSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), (it: (Array<Device> | null)): (Array<DeviceGeoShape> | null) => ((): (Array<DeviceGeoShape> | null) => {
            const temp4621 = it;
            if(temp4621 === null) { return null }
            return iterToArray(iterableFilterNotNull(iterMap(temp4621, (device: Device): (DeviceGeoShape | null) => ((): (DeviceGeoShape | null) => {
                const temp4623 = device.latitude;
                if(temp4623 === null) { return null }
                return ((lat: number): (DeviceGeoShape | null) => ((): (DeviceGeoShape | null) => {
                    const temp4625 = device.longitude;
                    if(temp4625 === null) { return null }
                    return ((lon: number): DeviceGeoShape => new DeviceGeoShape(device, new GeoCoordinate(lat, lon), undefined))(temp4625)
                })())(temp4623)
            })())))
        })());
        this.mapReloadClick();
        this.focusEvent = new Subject();
    }
    
    
    
    public readonly mapStyle: string;
    
    
    public readonly visibleBuildings: StandardObservableProperty<Set<BuildingGeoShape>>;
    
    public readonly settingOffsite: StandardObservableProperty<boolean>;
    
    public readonly floorIndex: StandardObservableProperty<number>;
    
    public readonly loadingMap: StandardObservableProperty<boolean>;
    
    public readonly geometry: StandardObservableProperty<(CampusGeoShape | null)>;
    
    public readonly currentViewBounds: StandardObservableProperty<(GeoCoordinateBounds | null)>;
    
    public useConstrainMap: boolean;
    
    public editMode: boolean;
    
    public readonly ongoingAlerts: CacheEdge<Alert>;
    
    public readonly hasActiveAlert: ObservableProperty<boolean>;
    
    public readonly alertId: (number | null);
    
    public readonly offsiteLocation: ObservableProperty<(Room | null)>;
    
    
    public readonly deviceSizeOffset: number;
    
    
    public readonly rooms: CacheEdge<Room>;
    
    public readonly floors: CacheEdge<Floor>;
    
    public readonly buildings: CacheEdge<Building>;
    
    
    public readonly devices: ObservableProperty<(Array<DeviceGeoShape> | null)>;
    
    
    public myBuildingId(): ObservableProperty<(ForeignKey<Building> | null)> {
        return xObservablePropertyMap<(Floor | null), (number | null)>(xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyMap<(User | null), (number | null)>(this.session.me, (it: (User | null)): (number | null) => (it?.currentLocation ?? null)), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it)), (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it)), (it: (Floor | null)): (number | null) => (it?.building ?? null));
    }
    
    
    public mapReloadClick(): void {
        console.log(`Loading URL ${this.mapUrl}`);
        this.geometry.value = null;
        if (this.mapUrl !== "") {
            xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<CampusGeoShape>(xSingleWorking<CampusGeoShape>(xSingleReadJsonDebug<CampusGeoShape>(HttpClient.INSTANCE.call(this.mapUrl, undefined, undefined, undefined, undefined), [CampusGeoShape]).pipe(rxTap((it: CampusGeoShape): void => {
                                console.debug(`${"GoogleMapSVG"}: ${`Got ${it}`}`);
                    })).pipe(rxTap(undefined, (it: any): void => {
                        if (it instanceof HttpResponseException) {
                            xResponseReadText((it as HttpResponseException).response).subscribe((it: string): void => {
                                console.debug(`${"GoogleMapSVG"}: ${`Got error ${it}`}`);
                            }, (it: any): void => {});
                        }
                })).pipe(rxMap((it: CampusGeoShape): CampusGeoShape => it.clean())), this.loadingMap), undefined, (it: CampusGeoShape): void => {
                    this.geometry.value = it;
            }));
        }
        if (this.mapUrl === "" && (this.session.rawApi instanceof MockApi || this.session.rawApi instanceof DelayedApi)) {
            this.geometry.value = xStringFromJsonString<CampusGeoShape>(getMockDatabase().mainSchoolJson, [CampusGeoShape]);
        }
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.map.GoogleMapVG.title
    public get title(): string { return "Google Map"; }
    
    
    public readonly focusEvent: Subject<GeoShape>;
    
    public focus(on: GeoShape): void {
        this.focusEvent.next(on);
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new GoogleMapXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.mapLoading
        xViewFlipperBindLoading(xml.mapLoading, this.loadingMap, undefined);
        
        //--- Shorcuts
        const myBuildingId = this.myBuildingId();
        
        
        const floorsToSelect: ObservableProperty<Array<[number, Array<BuildingGeoShapeFloor>]>> = xObservableAsObservableProperty<Array<[number, Array<BuildingGeoShapeFloor>]>>(xObservablePropertyObservableNNGet(this.visibleBuildings).pipe(rxDistinctUntilChanged()).pipe(rxMap((it: Set<BuildingGeoShape>): Array<BuildingGeoShape> => iterToArray(iterFilter(it, (it: BuildingGeoShape): boolean => it.floors.length > 1)))).pipe(rxMap((buildings: Array<BuildingGeoShape>): Array<[number, Array<BuildingGeoShapeFloor>]> => {
            let minIndex = 1000;
            
            let maxIndex = (-1000);
            
            for (const item of buildings) {
                minIndex = Math.min(minIndex, (-item.zeroFloorIndex));
                maxIndex = Math.max(maxIndex, (item.floors.length - 1) - item.zeroFloorIndex);
            }
            if (minIndex > maxIndex) {
                return ([] as Array<[number, Array<BuildingGeoShapeFloor>]>);
            } else if (minIndex === maxIndex) {
                return ([] as Array<[number, Array<BuildingGeoShapeFloor>]>);
            } else {
                return iterToArray(iterToArray(iterMap((new NumberRange(minIndex, maxIndex)), (index: number): [number, Array<BuildingGeoShapeFloor>] => [index, iterToArray(iterableFilterNotNull(iterMap(buildings, (it: BuildingGeoShape): (BuildingGeoShapeFloor | null) => it.floorByIndex(index))))]))).reverse();
            }
        })), []);
        
        
        //--- Map url
        console.info(`${"GoogleMapVG"}: ${`Map URL is ${this.mapUrl}`}`);
        
        //--- Set Up xml.insertMapHere
        const paints = new MapPaints(dependency);
        
        const textPaint = also(new Paint(), (this_: Paint): void => {
            this_.color = numberToColor(0xFF000000);
            this_.style = Paint.Style.FILL;
            this_.textSize = DisplayMetrics.INSTANCE.scaledDensity * 12;
        });
        
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<DeviceGeoShape> | null)>(this.devices, undefined, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        
        let lastProjector: (Projector | null) = null;
        
        mwovConfigure(xml.insertMapHere, dependency, this.mapStyle, (coordinate: GeoCoordinate): void => {
                const proj = lastProjector
                if(proj === null) { return }
                const geom = this.geometry.value
                if(geom === null) { return }
                const it = this.hit(geom, proj, coordinate);
                
                console.log(`Hit ${it} at ${coordinate}`);
                if (it instanceof RoomGeoShape){
                    this.onRoomTap((it as RoomGeoShape).id);
                } else if (it instanceof BuildingGeoShape){
                    mwovFocus(xml.insertMapHere, (it as BuildingGeoShape).calculatedBounds);
                    if ((it as BuildingGeoShape).isStub) {
                        this.onRoomTap((it as BuildingGeoShape).floors[0].rooms[0].id);
                    }
                } else if (it instanceof CampusGeoShape){
                    mwovFocus(xml.insertMapHere, (it as CampusGeoShape).calculatedBounds);
                } else if (it instanceof DeviceGeoShape){
                    this.onDeviceTap((it as DeviceGeoShape).device.id);
                }
            }, (canvas: CanvasRenderingContext2D, projector: Projector): void => {
                lastProjector = projector;
                this.currentViewBounds.value = projector.visibleBounds;
                const detailedBuildings = ([] as Array<BuildingGeoShape>);
                
                const campus = this.geometry.value
                if(campus === null) { return }
                const drawLabel = (location: GeoCoordinate, name: string): void => {
                    xCanvasDrawTextCentered(canvas, name, projector.convertToX(location), projector.convertToY(location), textPaint);
                }
                if (xGeoShapeShouldRenderDetail(campus, projector)) {
                    xGeoShapeRender(campus, canvas, projector, paints.campus);
                    for (const building of campus.buildings) {
                        if (xGeoShapeShouldRenderDetail(building, projector) && (!building.isStub)) {
                            xGeoShapeRender(building, canvas, projector, paints.buildingBranch);
                            xGeoShapeRender(building, canvas, projector, paints.buildingBranchOutline);
                            detailedBuildings.push(building);
                            const floor = building.closestFloorByIndex(this.floorIndex.value)
                            if(floor === null) { continue }
                            const roomsWithStatus = ((): Array<[RoomGeoShape, (CombinedStatus | null)]> => {
                                const temp4688 = (it: [RoomGeoShape, (CombinedStatus | null)]): (number | null) => {
                                    let total = 0;
                                    
                                    if ((it[1]?.needsMedicalAttention ?? null) === true) { total = total + 8 }
                                    const it_4691 = it[1];
                                    if (it_4691 !== null) {
                                        total = total + 5 - it_4691.status.comparableValue;
                                    }
                                    return total;
                                };
                                return floor.rooms.map((it: RoomGeoShape): [RoomGeoShape, (CombinedStatus | null)] => [it, (this.hasActiveAlert.value ? xSessionApiRoomStatusCached(this.session, it.id).value : null)]).slice().sort((a, b) => safeCompare(temp4688(a), temp4688(b)))
                            })();
                            
                            for (const toDestructure of roomsWithStatus) {
                                const room = toDestructure[0]
                                const status = toDestructure[1]
                                
                                for(const _x of paints.forCombinedStatus(status, [paints.room, paints.roomOutline])) {
                                    const it = _x;
                                    xGeoShapeRender(room, canvas, projector, it);
                                }
                                if (room.id === (this.session.me.value?.currentLocation ?? null)) {
                                    xGeoShapeRender(room, canvas, projector, paints.highlight());
                                }
                                if (xGeoShapeShouldRenderLabel(room, projector)) {
                                    drawLabel(room.calculatedLabelLocation, !(room.name === "Default") ? room.name : building.name);
                                }
                                for (const device of ((): (Array<DeviceGeoShape> | null) => {
                                    const temp4702 = this.devices.value;
                                    if(temp4702 === null) { return null }
                                    return temp4702.filter((it: DeviceGeoShape): boolean => it.device.room === room.id)
                                })() ?? ([] as Array<DeviceGeoShape>)) {
                                    const p1 = projector.convertToX(device.location);
                                    
                                    const p2 = projector.convertToX(new GeoCoordinate(device.location.latitude, device.location.longitude + this.deviceSizeOffset));
                                    
                                    xDeviceGeoShapeRender(device, canvas, projector, paints.unsafe, paints.unsafeOutline, p2 - p1);
                                }
                                
                            }
                        } else {
                            for(const _x of paints.forCombinedStatus(this.hasActiveAlert.value ? xSessionApiBuildingStatusCached(this.session, this.schoolId, building.id).value : null, [paints.building, paints.buildingOutline])) {
                                const it = _x;
                                xGeoShapeRender(building, canvas, projector, it);
                            };
                            if (building.id === myBuildingId.value) {
                                xGeoShapeRender(building, canvas, projector, paints.highlight());
                            }
                            if (xGeoShapeShouldRenderLabel(building, projector)) {
                                drawLabel(building.calculatedLabelLocation, building.name);
                            }
                        }
                    }
                } else {
                    for(const _x of paints.forCombinedStatus(this.hasActiveAlert.value ? xSessionApiCampusStatusCached(this.session, campus.id).value : null, [paints.campus])) {
                        const it = _x;
                        xGeoShapeRender(campus, canvas, projector, it);
                    };
                    if (xGeoShapeShouldRenderLabel(campus, projector)) {
                        drawLabel(campus.calculatedLabelLocation, campus.name);
                    }
                }
                for (const device of ((): (Array<DeviceGeoShape> | null) => {
                    const temp4712 = this.devices.value;
                    if(temp4712 === null) { return null }
                    return temp4712.filter((it: DeviceGeoShape): boolean => it.device.room === null)
                })() ?? ([] as Array<DeviceGeoShape>)) {
                    xDeviceGeoShapeRender(device, canvas, projector, paints.unsafe, paints.unsafeOutline, DisplayMetrics.INSTANCE.density * 7);
                }
                post((): void => {
                    this.visibleBuildings.value = new EqualOverrideSet(detailedBuildings);
                });
        });
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(CampusGeoShape | null)>(this.geometry, undefined, undefined, (it: (CampusGeoShape | null)): void => {
            if (this.useConstrainMap) {
                const loc_4715 = (it?.calculatedBounds ?? null);
                if (loc_4715 !== null) {
                    mwovSetBounds(xml.insertMapHere, loc_4715);
                }
            } else {
                if (it !== null) {
                    mwovFocus(xml.insertMapHere, it!.calculatedBounds);
                }
            }
        }), xViewRemovedGet(xml.insertMapHere));
        xDisposableUntil<SubscriptionLike>(this.focusEvent.subscribe((it: GeoShape): void => {
            mwovFocus(xml.insertMapHere, it.calculatedBounds);
        }, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        xDisposableUntil<SubscriptionLike>(getAnimationFrame().subscribe((it: number): void => {
            mwovRequestRedraw(xml.insertMapHere);
        }, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        //This keeps the data flowing
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(number | null)>(myBuildingId, undefined, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Room> | null)>(this.rooms, undefined, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Floor> | null)>(this.floors, undefined, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Building> | null)>(this.buildings, undefined, undefined, undefined), xViewRemovedGet(xml.insertMapHere));
        
        //--- Set Up xml.floors
        xLinearLayoutBindBottomLeft<[number, Array<BuildingGeoShapeFloor>]>(xml.floors, floorsToSelect, [0, []], (obs: ObservableProperty<[number, Array<BuildingGeoShapeFloor>]>): HTMLElement => {
            const cellXml = new ComponentFloorXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            xTextViewBindString(cellXml.label, xObservablePropertyMap<[number, Array<BuildingGeoShapeFloor>], string>(obs, (it: [number, Array<BuildingGeoShapeFloor>]): string => it[1].map((it: BuildingGeoShapeFloor): string => it.name).join("/")));
            xViewBindExists(cellXml.space, xObservablePropertyCombine<[number, Array<BuildingGeoShapeFloor>], number, boolean>(obs, this.floorIndex, (a: [number, Array<BuildingGeoShapeFloor>], b: number): boolean => a[0] === b));
            cellXml.xmlRoot.onclick = (_ev) => { _ev.stopPropagation();
                const it = _ev.target as HTMLElement;
                this.floorIndex.value = obs.value[0];
            };
            
            const floorStatus = xObservablePropertyFlatMap<[number, Array<BuildingGeoShapeFloor>], (CombinedStatus | null)>(obs, (it: [number, Array<BuildingGeoShapeFloor>]): ObservableProperty<(CombinedStatus | null)> => xObservablePropertyMap<Array<(CombinedStatus | null)>, (CombinedStatus | null)>(xListCombined<(CombinedStatus | null)>(it[1].map((it: BuildingGeoShapeFloor): ObservableProperty<(CombinedStatus | null)> => xSessionApiFloorStatusCached(this.session, this.schoolId, it.id))), (it: Array<(CombinedStatus | null)>): (CombinedStatus | null) => xSequenceWorst(it)));
            
            xViewBindVisible(cellXml.indicator, xObservablePropertyMap<(CombinedStatus | null), boolean>(floorStatus, (it: (CombinedStatus | null)): boolean => safeEq((it?.status ?? null), StatusEnum.Unsafe)));
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(CombinedStatus | null)>(floorStatus, undefined, undefined, (it: (CombinedStatus | null)): void => {
                if ((it?.needsMedicalAttention ?? null) === true) {
                    setViewBackgroundClass(cellXml.indicator, R.drawable.indicator_unsafe_medical.cssClass);
                } else {
                    setViewBackgroundClass(cellXml.indicator, R.drawable.indicator_unsafe.cssClass);
                }
            }), xViewRemovedGet(cellXml.indicator));
            
            return cellView;
        });
        
        //--- Set Up xml.settingOffsite
        //        xml.settingOffsite.bindExists(session.me.map { it?.localUser == true }
            //            .combine(offsiteLocation.map { it != null }) { a, b -> a && b }
            //            .combine(hasActiveAlert) { a, b -> a && b }
        //        )
        
        xViewFlipperBindLoading(xml.settingOffsite, this.settingOffsite, undefined);
        
        
        //--- Set Up xml.offsite
        xml.offsite.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            if (this.offsiteLocation.value === null) {
                this.createOffsite();
                return;
            }
            const off = this.offsiteLocation.value;
            
            this.onRoomTap(off!!.id);
        };
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(StatusEnum | null)>(xObservablePropertyCombine<(Room | null), (User | null), (StatusEnum | null)>(this.offsiteLocation, this.session.me, (off: (Room | null), me: (User | null)): (StatusEnum | null) => ((): (StatusEnum | null) => {
                        if ((me?.currentLocation ?? null) === (off?.id ?? null)) { return (off?.safe ?? null) } else { return null }
            })()), (it: any): void => {
                this.createOffsite();
            }, undefined, (it: (StatusEnum | null)): void => {
                if (safeEq(it, StatusEnum.Safe)) {
                    setViewBackgroundClass(xml.offsite, R.drawable.offsite_button_background.cssClass);
                } else if (safeEq(it, StatusEnum.Unsafe)) {
                    setViewBackgroundClass(xml.offsite, R.drawable.offsite_button_background_unsafe.cssClass);
                } else {
                    setViewBackgroundClass(xml.offsite, R.drawable.offsite_button_background_off.cssClass);
                }
        }), xViewRemovedGet(xml.offsite));
        
        //--- Set Up xml.myLocation
        xViewBindExists(xml.myLocation, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.currentLocation ?? null) !== null));
        xml.myLocation.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            const campus_4749 = this.geometry.value;
            if (campus_4749 !== null) {
                for (const building of campus_4749.buildings) {
                    for (const floor of building.floors) {
                        for (const room of floor.rooms) {
                            if (room.id === (this.session.me.value?.currentLocation ?? null)) {
                                const it_4752 = ((): (number | null) => {
                                    const temp4753 = iterFind(building.floorsByIndex(), (it: [number, BuildingGeoShapeFloor]): boolean => it[1].id === floor.id);
                                    if(temp4753 === null) { return null }
                                    return temp4753[0]
                                })();
                                if (it_4752 !== null) {
                                    this.floorIndex.value = it_4752;
                                }
                                mwovFocus(xml.insertMapHere, room.calculatedBounds);
                                return;
                            }
                        }
                    }
                }
            }
        };
        
        //--- Set Up xml.mapReload
        //--- Set Up xml.search
        if (this.editMode) {
            setViewVisibility(xml.mapReload, "gone");
            setViewVisibility(xml.search, "visible");
            const searchQuery = new StandardObservableProperty<string>("", undefined);
            
            xEditTextBindString(xml.search, searchQuery);
            xEditTextSetOnDoneClick(xml.search, (): void => {
                xActivityAccessGeocode(dependency, searchQuery.value, undefined).subscribe((it: Array<GeoAddress>): void => {
                        const it_4767 = ((it[0] ?? null)?.coordinate ?? null);
                        if (it_4767 !== null) {
                            mwovFocus(xml.insertMapHere, new GeoCoordinateBounds(it_4767, it_4767));
                        }
                    }, (it: any): void => {
                        printStackTrace(it);
                });
            });
        } else {
            setViewVisibility(xml.search, "gone");
            xViewBindVisible(xml.mapReload, xObservablePropertyCombine<boolean, (CampusGeoShape | null), boolean>(this.loadingMap, this.geometry, (load: boolean, geo: (CampusGeoShape | null)): boolean => (!load) && geo === null));
            xViewOnClick(xml.mapReload, undefined, (): void => {
                this.mapReloadClick();
            });
        }
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public createOffsite(): void {
        this.session.rawApi.createOffsite(this.session.session, this.schoolId.toString()).subscribe((it: OffsiteResponse): void => {
                xSingleCallDisplayingError<User>(this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.setLocation(it.room_id)]), undefined, (it: User): void => {
                    this.stack.reset(new SelectStatusVG(this.dialog, this.session, this.alertId));
                });
            }, (it: any): void => {
                showDialogAlert(new ViewStringResource(R._string.something_went_wrong));
        });
    }
    
    public hit(campus: CampusGeoShape, projector: Projector, geo: GeoCoordinate): (GeoShape | null) {
        if (xGeoShapeShouldRenderDetail(campus, projector)) {
            for (const building of campus.buildings) {
                if (xGeoShapeShouldRenderDetail(building, projector)) {
                    const floor = building.closestFloorByIndex(this.floorIndex.value)
                    if(floor === null) { continue }
                    for (const device of this.devices.value ?? []) {
                        const x = projector.convertToX(geo);
                        
                        const y = projector.convertToY(geo);
                        
                        const deviceX = projector.convertToX(device.location);
                        
                        const deviceY = projector.convertToY(device.location);
                        
                        const p1 = projector.convertToX(device.location);
                        
                        const p2 = projector.convertToX(new GeoCoordinate(device.location.latitude, device.location.longitude + this.deviceSizeOffset));
                        
                        const radius = p2 - p1;
                        
                        const distance = Math.sqrt(Math.pow((x - deviceX), 2) + Math.pow((y - deviceY), 2));
                        
                        if (distance <= radius) {
                            return device;
                        }
                    }
                    for (const room of floor.rooms) {
                        if (xListPolygonContains(room.parsedGeometry, projector, geo)) {
                            return room;
                        }
                    }
                } else if (xListPolygonContains(building.parsedGeometry, projector, geo)) {
                    return building;
                }
            }
        } else if (xListPolygonContains(campus.parsedGeometry, projector, geo)) {
            return campus;
        }
        return null;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Action insertMapHereClick
    
    //--- Action offsiteClick
    //--- Action myLocationClick
    
    
    //--- Body End
}

//--- Convenience bind

//! Declares com.tresitgroup.android.tresit.vg.map.LinearLayoutBoundSubview
class LinearLayoutBoundSubview<T> {
    public readonly view: HTMLElement;
    public readonly property: StandardObservableProperty<T>;
    public constructor(view: HTMLElement, property: StandardObservableProperty<T>) {
        this.view = view;
        this.property = property;
    }
}

//! Declares com.tresitgroup.android.tresit.vg.map.bindBottomLeft>android.widget.LinearLayout
export function xLinearLayoutBindBottomLeft<T>(this_: HTMLDivElement, data: ObservableProperty<Array<T>>, defaultValue: T, makeView:  ((a: ObservableProperty<T>) => HTMLElement)): void {
    const existingViews: Array<LinearLayoutBoundSubview<T>> = [];
    
    xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<Array<T>>(data, undefined, undefined, (value: Array<T>): void => {
        //Fix view count
        const excessViews = existingViews.length - value.length;
        
        if (excessViews > 0) {
            //remove views
            for (const iter of new NumberRange(1, excessViews)) {
                const old = existingViews.splice((existingViews.length - 1), 1)[0];
                
                this_.removeChild(old.view);
            }
        } else if (existingViews.length < value.length) {
            //add views
            for (const iter of new NumberRange(1, ((-excessViews)))) {
                const prop = new StandardObservableProperty<T>(defaultValue, undefined);
                
                const view = makeView(prop);
                
                this_.appendChild(xLinearLayoutParams(this_, undefined, undefined, undefined, undefined, undefined, undefined, AlignPair.Companion.INSTANCE.bottomLeft, undefined)(view));
                existingViews.push(new LinearLayoutBoundSubview<T>(view, prop));
            }
        }
        
        //Update views
        for (const index of new NumberRange(0, value.length-1)) {
            existingViews[index].property.value = value[index];
        }
    }), xViewRemovedGet(this_));
}


//! Declares com.tresitgroup.android.tresit.vg.map.DeviceWithGeo
export class DeviceWithGeo {
    public readonly device: Device;
    public readonly geo: GeoCoordinate;
    public constructor(device: Device, geo: GeoCoordinate) {
        this.device = device;
        this.geo = geo;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.device);
        hash = 31 * hash + hashAnything(this.geo);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof DeviceWithGeo && safeEq(this.device, other.device) && safeEq(this.geo, other.geo) }
    public toString(): string { return `DeviceWithGeo(device=${this.device}, geo=${this.geo})` }
    public copy(device: Device = this.device, geo: GeoCoordinate = this.geo): DeviceWithGeo { return new DeviceWithGeo(device, geo); }
}
