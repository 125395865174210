// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertEnded.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertEnded
export class AlertEnded {
    public readonly schoolId: ForeignKey<School>;
    public readonly usersCleared: Array<ForeignKey<User>>;
    public constructor(schoolId: ForeignKey<School>, usersCleared: Array<ForeignKey<User>>) {
        this.schoolId = schoolId;
        this.usersCleared = usersCleared;
    }
    public static fromJson(obj: any): AlertEnded { return new AlertEnded(
        parseJsonTyped(obj["school_id"], [Number]) as number,
        parseJsonTyped(obj["users_cleared"], [Array, [Number]]) as Array<number>
    ) }
    public toJSON(): object { return {
        school_id: this.schoolId,
        users_cleared: this.usersCleared
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.schoolId);
        hash = 31 * hash + hashAnything(this.usersCleared);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertEnded && safeEq(this.schoolId, other.schoolId) && safeEq(this.usersCleared, other.usersCleared) }
    public toString(): string { return `AlertEnded(schoolId=${this.schoolId}, usersCleared=${this.usersCleared})` }
    public copy(schoolId: ForeignKey<School> = this.schoolId, usersCleared: Array<ForeignKey<User>> = this.usersCleared): AlertEnded { return new AlertEnded(schoolId, usersCleared); }
}