// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminUsersVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xDateFormat, xDateMinus } from 'butterfly-web/dist/time/Date'
import { xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { ApiSort } from '../../api/ApiSort'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Observable, SubscriptionLike } from 'rxjs'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { AdminUserDetailVG } from './AdminUserDetailVG'
import { AdminUserAddVG } from './AdminUserAddVG'
import { xIntDays } from 'butterfly-web/dist/time/TimeInterval'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { User } from '../../model/User'
import { xObservablePropertyPlusRx } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { PagingHelper } from '../../util/PagingHelper'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { debounceTime as rxDebounceTime, map as rxMap } from 'rxjs/operators'
import { UserDetailed } from '../../model/UserDetailed'
import { xRecyclerViewBind, xRecyclerViewBindRefresh, xRecyclerViewWhenScrolledToEnd } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyObservableNNGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { AdminUsersXml } from '../../layout/AdminUsersXml'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ComponentRowUserXml } from '../../layout/ComponentRowUserXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ApiPartialQuery } from '../../api/ApiPartialQuery'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { ApiQuery } from '../../api/ApiQuery'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminUsersVG
export class AdminUsersVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.query = new StandardObservableProperty<string>("", undefined);
        this.userDefinedSort = new StandardObservableProperty<ApiSort<UserDetailed>>(UserDetailed.Companion.INSTANCE.latestActivityDownSort, undefined);
        this.debouncedFilter = xObservablePropertyPlusRx<string>(this.query, (it: Observable<string>): Observable<string> => it.pipe(rxDebounceTime(1000)));
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminUsersVG.title
    public get title(): string { return "Admin Users"; }
    
    
    public readonly query: MutableObservableProperty<string>;
    
    public readonly userDefinedSort: StandardObservableProperty<ApiSort<UserDetailed>>;
    
    public readonly debouncedFilter: ObservableProperty<string>;
    
    
    
    public paging(): PagingHelper<UserDetailed> {
        return new PagingHelper<UserDetailed>(xObservablePropertyObservableNNGet(xObservablePropertyCombine<Array<ApiFilter<UserDetailed>>, ApiSort<UserDetailed>, ApiQuery<UserDetailed>>(xObservablePropertyCombine<string, (School | null), Array<ApiFilter<UserDetailed>>>(this.debouncedFilter, this.schoolFilter, (query: string, school: (School | null)): Array<ApiFilter<UserDetailed>> => {
                            const filters = ([] as Array<ApiFilter<UserDetailed>>);
                            
                            if (school !== null) {
                                filters.push(UserDetailed.Companion.INSTANCE.belongingToAnySchool([school!.id]));
                            } else {
                                const id = ((): (Array<number> | null) => {
                                    const temp3579 = this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined).value;
                                    if(temp3579 === null) { return null }
                                    return temp3579.map((it: School): number => it.id)
                                })();
                                
                                filters.push(UserDetailed.Companion.INSTANCE.belongingToAnySchool(id ?? []));
                            }
                            filters.push(UserDetailed.Companion.INSTANCE.textSearch(query));
                            return filters;
            }), this.userDefinedSort, (filters: Array<ApiFilter<UserDetailed>>, sort: ApiSort<UserDetailed>): ApiQuery<UserDetailed> => new ApiQuery<UserDetailed>(ApiFilter.Companion.INSTANCE.allList<UserDetailed>(filters.concat([UserDetailed.Companion.INSTANCE.deletedAfter(xDateMinus(new Date(), xIntDays(365)))])), sort))), (q: ApiQuery<UserDetailed>, p: number): Observable<Array<UserDetailed>> => ((): Observable<Array<UserDetailed>> => {
                if ((this.session.me.value?.isSuper ?? null) === true) {
                    return this.session.rawApi.usersDetailed(this.session.session, new ApiPartialQuery<UserDetailed>(q, 100, 100 * p));
                } else {
                    return this.session.rawApi.usersDetailed(this.session.session, new ApiPartialQuery<UserDetailed>(q, 100, 100 * p)).pipe(rxMap((it: Array<UserDetailed>): Array<UserDetailed> => it.filter((it: UserDetailed): boolean => (!it.isSuper))));
                }
        })());
    }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminUsersXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const paging = this.paging();
        
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.query);
        
        //--- Set Up xml.nameHeader
        const temp3589 = xml.nameHeader;
        if(temp3589 !== null) {
            xViewOnClick(temp3589, undefined, (): void => {
                this.nameHeaderClick();
            })
        };
        const temp3590 = xml.nameHeader2;
        if(temp3590 !== null) {
            xViewOnClick(temp3590, undefined, (): void => {
                this.nameHeaderClick();
            })
        };
        const temp3591 = xml.sortNameAscending;
        if(temp3591 !== null) {
            xViewBindVisible(temp3591, xObservablePropertyMap<ApiSort<UserDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<UserDetailed>): boolean => !safeEq(it, UserDetailed.Companion.INSTANCE.nameSort)))
        };
        const temp3592 = xml.sortNameDescending;
        if(temp3592 !== null) {
            xViewBindVisible(temp3592, xObservablePropertyMap<ApiSort<UserDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<UserDetailed>): boolean => !safeEq(it, UserDetailed.Companion.INSTANCE.nameDownSort)))
        };
        
        //--- Set Up xml.siteHeader
        /* xml.siteHeader?.onClick { siteHeaderClick() }
        xml.sortSiteAscending?.bindVisible(userDefinedSort.map { it != UserDetailed.siteSort })
        xml.sortSiteDescending?.bindVisible(userDefinedSort.map { it != UserDetailed.siteDownSort })*/
        
        //--- Set Up xml.dateHeader
        const temp3593 = xml.dateHeader;
        if(temp3593 !== null) {
            xViewOnClick(temp3593, undefined, (): void => {
                this.dateHeaderClick();
            })
        };
        const temp3594 = xml.sortDateAscending;
        if(temp3594 !== null) {
            xViewBindVisible(temp3594, xObservablePropertyMap<ApiSort<UserDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<UserDetailed>): boolean => !safeEq(it, UserDetailed.Companion.INSTANCE.latestActivitySort)))
        };
        const temp3595 = xml.sortDateDescending;
        if(temp3595 !== null) {
            xViewBindVisible(temp3595, xObservablePropertyMap<ApiSort<UserDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<UserDetailed>): boolean => !safeEq(it, UserDetailed.Companion.INSTANCE.latestActivityDownSort)))
        };
        
        //--- Set Up xml.usersLoading
        
        //--- Set Up xml.users
        xRecyclerViewBindRefresh(xml.users, paging.loading, (): void => {
            paging.refresh();
        });
        xRecyclerViewWhenScrolledToEnd(xml.users, (): void => {
            paging.loadNewPage();
        });
        xRecyclerViewBind<UserDetailed>(xml.users, paging, new UserDetailed(undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<UserDetailed>): HTMLElement => {
            //--- Make Subview For xml.users
            const cellXml = new ComponentRowUserXml();
            
            const cellView = cellXml.setup(dependency);
            
            //--- Set Up soft deleted users
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<UserDetailed>(observable, undefined, undefined, (it: UserDetailed): void => {
                (cellXml.row ?? cellXml.root).style.opacity = `${(it.deleted !== null ? 0.2 : 1)}`;
            }), xViewRemovedGet(cellView));
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.root, undefined, (): void => {
                this.cellXmlRootClick(observable.value.id);
            });
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<UserDetailed, string>(observable, (it: UserDetailed): string => it.name));
            
            //--- Set Up cellXml.userType
            const temp3598 = cellXml.userType;
            if(temp3598 !== null) {
                xTextViewBindString(temp3598, xObservablePropertyMap<UserDetailed, string>(observable, (it: UserDetailed): string => {
                    const it_3599 = it.groupNames;
                    if (it_3599 !== null) {
                        if (it_3599.length === 0) {
                            return "";
                        } else {
                            return ((): (string | null) => {
                                const temp3600 = it_3599[0];
                                if(temp3600 === null) { return null }
                                return temp3600.replaceAll("Base User", "")
                            })() ?? "";
                        }
                    } else {
                        return "";
                    }
                }))
            };
            
            //--- Set Up cellXml.schools
            /*    cellXml.schools.bindString(observable.map {
                if (it.associatedSitesCount ?: 0 > 1) "Multiple Sites (${it.associatedSitesCount ?: 0})" else it.associatedSite
                ?: ""
            })*/
            
            //--- Set Up cellXml.latestActivityDate
            const temp3603 = cellXml.latestActivityDate;
            if(temp3603 !== null) {
                xTextViewBindString(temp3603, xObservablePropertyMap<UserDetailed, string>(observable, (it: UserDetailed): string => ((): (string | null) => {
                    const temp3604 = it.lastActivity;
                    if(temp3604 !== null) {
                        return xDateFormat(temp3604, ClockPartSize.Short, ClockPartSize.Short)
                    } else { return null }
                })() ?? ""))
            };
            
            /*****************
            Registered Device(s)
            *****************/
            //--- Set Up cellXml.deviceMac
            /*  cellXml.deviceMac?.bindExists(observable.map { userData -> DeviceEnum.MAC in userData.fcmDeviceTypes })
            
            //--- Set Up cellXml.deviceWindows
            cellXml.deviceWindows?.bindExists(observable.map { userData -> DeviceEnum.WINDOWS in userData.fcmDeviceTypes })
            
            //--- Set Up cellXml.deviceAndroid
            cellXml.deviceAndroid?.bindExists(observable.map { userData -> DeviceEnum.ANDROID in userData.fcmDeviceTypes })
            
            //--- Set Up cellXml.deviceIOS
            cellXml.deviceIOS?.bindExists(observable.map { userData -> DeviceEnum.IOS in userData.fcmDeviceTypes })
            
            //--- Set Up cellXml.deviceOther
            cellXml.deviceOther?.bindExists(observable.map { userData -> DeviceEnum.OTHER in userData.fcmDeviceTypes })*/
            
            //--- End Make Subview For xml.users (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.addUser (overwritten on flow generation)
        xViewOnClick(xml.addUser, undefined, (): void => {
            this.addUserClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action removeSchool
    
    public cellXmlRootClick(userId: ForeignKey<User>): void {
        this.stack.push(new AdminUserDetailVG(this.dialog, this.session, this.stack, userId));
    }
    
    public addUserClick(): void {
        this.stack.push(new AdminUserAddVG(this.session, this.stack));
    }
    
    public nameHeaderClick(): void {
        if (safeEq(this.userDefinedSort.value, UserDetailed.Companion.INSTANCE.nameSort)) {
            this.userDefinedSort.value = UserDetailed.Companion.INSTANCE.nameDownSort;
        } else {
            this.userDefinedSort.value = UserDetailed.Companion.INSTANCE.nameSort;
        }
    }
    
    public siteHeaderClick(): void {
        if (safeEq(this.userDefinedSort.value, UserDetailed.Companion.INSTANCE.siteSort)) {
            this.userDefinedSort.value = UserDetailed.Companion.INSTANCE.siteDownSort;
        } else {
            this.userDefinedSort.value = UserDetailed.Companion.INSTANCE.siteSort;
        }
    }
    
    public dateHeaderClick(): void {
        if (safeEq(this.userDefinedSort.value, UserDetailed.Companion.INSTANCE.latestActivitySort)) {
            this.userDefinedSort.value = UserDetailed.Companion.INSTANCE.latestActivityDownSort;
        } else {
            this.userDefinedSort.value = UserDetailed.Companion.INSTANCE.latestActivitySort;
        }
    }
    
    //--- Body End
}
