// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/UnreadThread.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { User } from './User'
import { ChatThread } from './ChatThread'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.UnreadThread
export class UnreadThread implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly userId: ForeignKey<User>;
    public readonly thread: ForeignKey<ChatThread>;
    public readonly associatedSchools: Array<ForeignKey<School>>;
    public readonly userIds: Array<ForeignKey<User>>;
    public readonly created: Date;
    public constructor(id: number = getNO_ID(), userId: ForeignKey<User>, thread: ForeignKey<ChatThread>, associatedSchools: Array<ForeignKey<School>>, userIds: Array<ForeignKey<User>>, created: Date) {
        this.id = id;
        this.userId = userId;
        this.thread = thread;
        this.associatedSchools = associatedSchools;
        this.userIds = userIds;
        this.created = created;
    }
    public static fromJson(obj: any): UnreadThread { return new UnreadThread(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["user_id"], [Number]) as number,
        parseJsonTyped(obj["thread"], [Number]) as number,
        parseJsonTyped(obj["associated_schools"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["user_ids"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["created"], [Date]) as Date
    ) }
    public toJSON(): object { return {
        id: this.id,
        user_id: this.userId,
        thread: this.thread,
        associated_schools: this.associatedSchools,
        user_ids: this.userIds,
        created: this.created
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.userId);
        hash = 31 * hash + hashAnything(this.thread);
        hash = 31 * hash + hashAnything(this.associatedSchools);
        hash = 31 * hash + hashAnything(this.userIds);
        hash = 31 * hash + hashAnything(this.created);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof UnreadThread && safeEq(this.id, other.id) && safeEq(this.userId, other.userId) && safeEq(this.thread, other.thread) && safeEq(this.associatedSchools, other.associatedSchools) && safeEq(this.userIds, other.userIds) && safeEq(this.created, other.created) }
    public toString(): string { return `UnreadThread(id=${this.id}, userId=${this.userId}, thread=${this.thread}, associatedSchools=${this.associatedSchools}, userIds=${this.userIds}, created=${this.created})` }
    public copy(id: number = this.id, userId: ForeignKey<User> = this.userId, thread: ForeignKey<ChatThread> = this.thread, associatedSchools: Array<ForeignKey<School>> = this.associatedSchools, userIds: Array<ForeignKey<User>> = this.userIds, created: Date = this.created): UnreadThread { return new UnreadThread(id, userId, thread, associatedSchools, userIds, created); }
    
    
}
export namespace UnreadThread {
    //! Declares com.tresitgroup.android.tresit.model.UnreadThread.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public forThread(thread: ForeignKey<ChatThread>): ApiFilter<UnreadThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UnreadThread>(`thread=${thread}`, undefined, (it: UnreadThread): boolean => it.thread === thread);
        }
    }
}