// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./font/montserrat_light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./font/montserrat_light.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./font/montserrat_light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./font/montserrat_regular.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./font/montserrat_regular.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./font/montserrat_regular.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./font/montserrat_semibold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./font/montserrat_semibold.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("./font/montserrat_semibold.ttf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("./font/montserrat_bold.woff2");
var ___CSS_LOADER_URL_IMPORT_10___ = require("./font/montserrat_bold.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("./font/montserrat_bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "/*------------------------------------*\\\n              ¡TYPOGRAPHY\n\\*------------------------------------*/\n\n@font-face {\n    font-family: 'montserratlight';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'montserratregular';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'montserratsemibold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'montserratbold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff2'), url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n\n.id-convRoot:hover .id-selectedIndicator {\n    visibility: visible !important;\n}\n\nhtml {\n    font-size: 16px;\n}\n\nbody {\n    background: white;\n    font-family: 'montserratregular', sans-serif;\n    font-weight: 400;\n    -webkit-font-smoothing: antialiased;\n}\n\n.khr {\n    font-family: 'montserratregular', sans-serif;\n    user-select: none;\n}\n.khr.textStyleBold {\n    font-family: 'montserratsemibold', sans-serif;\n}\n", ""]);
// Exports
module.exports = exports;
