//
// AlertCancelReportXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './alert_cancel_report.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertCancelReportXml
export class AlertCancelReportXml {
    xmlRoot!: HTMLElement
    alertEndedTitle!: HTMLElement
    schoolName!: HTMLElement
    alertTypeIcon!: HTMLImageElement
    alertName!: HTMLElement
    initiatorName!: HTMLElement
    initiatorTime!: HTMLElement
    cancellerName!: HTMLElement
    cancelTime!: HTMLElement
    reason!: HTMLElement
    dismissButton!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.alertEndedTitle = getViewById<HTMLElement>(view, "alert_ended_title")
        this.schoolName = getViewById<HTMLElement>(view, "school_name")
        this.alertTypeIcon = getViewById<HTMLImageElement>(view, "alert_type_icon")
        this.alertName = getViewById<HTMLElement>(view, "alert_name")
        this.initiatorName = getViewById<HTMLElement>(view, "initiator_name")
        this.initiatorTime = getViewById<HTMLElement>(view, "initiator_time")
        this.cancellerName = getViewById<HTMLElement>(view, "canceller_name")
        this.cancelTime = getViewById<HTMLElement>(view, "cancel_time")
        this.reason = getViewById<HTMLElement>(view, "reason")
        this.dismissButton = getViewById<HTMLButtonElement>(view, "dismiss_button")
        return view
    }
}
