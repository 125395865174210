//
// ChatHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/chat_history.html'
import htmlForDefault from './chat_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.ChatHistoryXml
export class ChatHistoryXml {
    xmlRoot!: HTMLElement
    filter!: HTMLInputElement
    alertThreads!: HTMLDivElement
    dummyGoTo!: HTMLElement
    threadNameHeader: HTMLDivElement | null = null
    sortThreadNameAscending: HTMLImageElement | null = null
    sortThreadNameDescending: HTMLImageElement | null = null
    associatedSitesHeader: HTMLDivElement | null = null
    sortSiteAscending: HTMLImageElement | null = null
    sortSitesDescending: HTMLImageElement | null = null
    timestampHeader: HTMLDivElement | null = null
    sortTimestampAscending: HTMLImageElement | null = null
    sortTimestampDescending: HTMLImageElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.alertThreads = getViewById<HTMLDivElement>(view, "alertThreads")
        this.dummyGoTo = getViewById<HTMLElement>(view, "dummyGoTo")
        this.threadNameHeader = findViewById<HTMLDivElement>(view, "threadNameHeader")
        this.sortThreadNameAscending = findViewById<HTMLImageElement>(view, "sortThreadNameAscending")
        this.sortThreadNameDescending = findViewById<HTMLImageElement>(view, "sortThreadNameDescending")
        this.associatedSitesHeader = findViewById<HTMLDivElement>(view, "associatedSitesHeader")
        this.sortSiteAscending = findViewById<HTMLImageElement>(view, "sortSiteAscending")
        this.sortSitesDescending = findViewById<HTMLImageElement>(view, "sortSitesDescending")
        this.timestampHeader = findViewById<HTMLDivElement>(view, "timestampHeader")
        this.sortTimestampAscending = findViewById<HTMLImageElement>(view, "sortTimestampAscending")
        this.sortTimestampDescending = findViewById<HTMLImageElement>(view, "sortTimestampDescending")
        return view
    }
}
