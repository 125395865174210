//
// ImagePreviewXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './image_preview.html'
//! Declares com.tresitgroup.android.tresit.layouts.ImagePreviewXml
export class ImagePreviewXml {
    xmlRoot!: HTMLElement
    image!: HTMLImageElement
    closeButton!: HTMLButtonElement
    download!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.image = getViewById<HTMLImageElement>(view, "image")
        this.closeButton = getViewById<HTMLButtonElement>(view, "closeButton")
        this.download = getViewById<HTMLButtonElement>(view, "download")
        return view
    }
}
