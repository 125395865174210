//
// ExportOptionLayoutXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FancyBackgroundDelegate } from '../vg/FancyBackgroundDelegate'
import htmlForDefault from './export_option_layout.html'
//! Declares com.tresitgroup.android.tresit.layouts.ExportOptionLayoutXml
export class ExportOptionLayoutXml {
    xmlRoot!: HTMLElement
    background!: HTMLCanvasElement
    btnClose!: HTMLButtonElement
    summaryPDF!: HTMLElement
    detailedPDF!: HTMLElement
    csv!: HTMLElement
    backgroundDelegate!: FancyBackgroundDelegate
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.background = getViewById<HTMLCanvasElement>(view, "background")
        this.btnClose = getViewById<HTMLButtonElement>(view, "btnClose")
        this.summaryPDF = getViewById<HTMLElement>(view, "summaryPDF")
        this.detailedPDF = getViewById<HTMLElement>(view, "detailedPDF")
        this.csv = getViewById<HTMLElement>(view, "csv")
        if(this.background){ this.backgroundDelegate = new FancyBackgroundDelegate(); customViewSetDelegate(this.background, this.backgroundDelegate); }
        return view
    }
}
