//
// DemoEmailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FancyBackgroundDelegate } from '../vg/FancyBackgroundDelegate'
import htmlForDefault from './demo_email.html'
//! Declares com.tresitgroup.android.tresit.layouts.DemoEmailXml
export class DemoEmailXml {
    xmlRoot!: HTMLElement
    background!: HTMLCanvasElement
    email!: HTMLInputElement
    submitWorking!: HTMLDivElement
    submit!: HTMLButtonElement
    skip!: HTMLButtonElement
    back!: HTMLButtonElement
    backgroundDelegate!: FancyBackgroundDelegate
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.background = getViewById<HTMLCanvasElement>(view, "background")
        this.email = getViewById<HTMLInputElement>(view, "email")
        this.submitWorking = getViewById<HTMLDivElement>(view, "submitWorking")
        this.submit = getViewById<HTMLButtonElement>(view, "submit")
        this.skip = getViewById<HTMLButtonElement>(view, "skip")
        this.back = getViewById<HTMLButtonElement>(view, "back")
        if(this.background){ this.backgroundDelegate = new FancyBackgroundDelegate(); customViewSetDelegate(this.background, this.backgroundDelegate); }
        return view
    }
}
