// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/DefaultLocationSettingsVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { DefaultLocationSettingsXml } from '../../layout/DefaultLocationSettingsXml'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { Floor } from '../../model/Floor'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { ApiModifier } from '../../api/ApiModifier'
import { Room } from '../../model/Room'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { MapVG } from '../map/MapVG'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { ApiFilter } from '../../api/ApiFilter'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.settings.DefaultLocationSettingsVG
export class DefaultLocationSettingsVG extends ViewGenerator {
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(schoolId: ForeignKey<School>, session: SessionApi, stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>) {
        super();
        this.schoolId = schoolId;
        this.session = session;
        this.stack = stack;
        this.dialog = dialog;
        this.usmId = new StandardObservableProperty<(number | null)>(null, undefined);
        this.isLoading = new StandardObservableProperty<boolean>(false, undefined);
        this.value1 = new StandardObservableProperty<(number | null)>(null, undefined);
        this.value2 = new StandardObservableProperty<(number | null)>(null, undefined);
        this.value3 = new StandardObservableProperty<(number | null)>(null, undefined);
        this.value4 = new StandardObservableProperty<(number | null)>(null, undefined);
        this.value5 = new StandardObservableProperty<(number | null)>(null, undefined);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<UserSchoolMembership>>(this.session.userSchoolMembership.getListSimple(ApiFilter.Companion.INSTANCE.all<UserSchoolMembership>(UserSchoolMembership.Companion.INSTANCE.forSchool(this.schoolId), UserSchoolMembership.Companion.INSTANCE.forUser(this.session.session.userId)), undefined, undefined, undefined, undefined), undefined, (memberships: Array<UserSchoolMembership>): void => {
            const it_5274 = (memberships[0] ?? null);
            if (it_5274 !== null) {
                this.usmId.value = it_5274.id;
                this.value1.value = it_5274.defaultLocation1;
                this.value2.value = it_5274.defaultLocation2;
                this.value3.value = it_5274.defaultLocation3;
                this.value4.value = it_5274.defaultLocation4;
                this.value5.value = it_5274.defaultLocation5;
            }
        }));
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.DefaultLocationSettingsVG.title
    public get title(): string { return "Default Location Settings"; }
    
    
    public readonly usmId: MutableObservableProperty<(ForeignKey<UserSchoolMembership> | null)>;
    
    public readonly isLoading: MutableObservableProperty<boolean>;
    
    
    public readonly value1: MutableObservableProperty<(ForeignKey<Room> | null)>;
    
    public readonly value2: MutableObservableProperty<(ForeignKey<Room> | null)>;
    
    public readonly value3: MutableObservableProperty<(ForeignKey<Room> | null)>;
    
    public readonly value4: MutableObservableProperty<(ForeignKey<Room> | null)>;
    
    public readonly value5: MutableObservableProperty<(ForeignKey<Room> | null)>;
    
    
    public room1(): ObservableProperty<(Room | null)> {
        return xObservablePropertyFlatMapNotNull<number, Room>(this.value1, (room: number): ObservableProperty<(Room | null)> => xObservablePropertyMap<(Room | null), (Room | null)>(this.session.rooms.observable(room), (it: (Room | null)): (Room | null) => it));
    }
    
    public room2(): ObservableProperty<(Room | null)> {
        return xObservablePropertyFlatMapNotNull<number, Room>(this.value2, (room: number): ObservableProperty<(Room | null)> => xObservablePropertyMap<(Room | null), (Room | null)>(this.session.rooms.observable(room), (it: (Room | null)): (Room | null) => it));
    }
    
    public room3(): ObservableProperty<(Room | null)> {
        return xObservablePropertyFlatMapNotNull<number, Room>(this.value3, (room: number): ObservableProperty<(Room | null)> => xObservablePropertyMap<(Room | null), (Room | null)>(this.session.rooms.observable(room), (it: (Room | null)): (Room | null) => it));
    }
    
    public room4(): ObservableProperty<(Room | null)> {
        return xObservablePropertyFlatMapNotNull<number, Room>(this.value4, (room: number): ObservableProperty<(Room | null)> => xObservablePropertyMap<(Room | null), (Room | null)>(this.session.rooms.observable(room), (it: (Room | null)): (Room | null) => it));
    }
    
    public room5(): ObservableProperty<(Room | null)> {
        return xObservablePropertyFlatMapNotNull<number, Room>(this.value5, (room: number): ObservableProperty<(Room | null)> => xObservablePropertyMap<(Room | null), (Room | null)>(this.session.rooms.observable(room), (it: (Room | null)): (Room | null) => it));
    }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new DefaultLocationSettingsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const room1 = this.room1();
        
        const room2 = this.room2();
        
        const room3 = this.room3();
        
        const room4 = this.room4();
        
        const room5 = this.room5();
        
        
        
        //--- Set Up xml.location1
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(room1, undefined, undefined, (it: (Room | null)): void => {
            xml.location1.style.opacity = `${(it !== null ? 1.0 : 0.5)}`;
        }), xViewRemovedGet(xml.location1));
        
        //--- Set Up xml.value1
        const names1 = this.getNames(room1);
        
        xTextViewBindString(xml.value1, xObservablePropertyMap<Array<string>, string>(names1, (it: Array<string>): string => (it[0] ?? null) ?? "Tap to add default location"));
        xViewOnClick(xml.value1, undefined, (): void => {
            this.value1Click();
        });
        
        //--- Set Up xml.floor1
        xTextViewBindString(xml.floor1, xObservablePropertyMap<Array<string>, string>(names1, (it: Array<string>): string => (it[1] ?? null) ?? ""));
        
        //--- Set Up xml.plusLocation1
        xViewBindExists(xml.plusLocation1, xObservablePropertyMap<(number | null), boolean>(this.value1, (it: (number | null)): boolean => it === null));
        
        //--- Set Up xml.deleteLocation1
        xViewBindExists(xml.deleteLocation1, xObservablePropertyMap<(number | null), boolean>(this.value1, (it: (number | null)): boolean => it !== null));
        xViewOnClick(xml.deleteLocation1, undefined, (): void => {
            this.deleteLocation1Click();
        });
        
        //--- Set Up xml.location2
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(room2, undefined, undefined, (it: (Room | null)): void => {
            xml.location2.style.opacity = `${(it !== null ? 1.0 : 0.5)}`;
        }), xViewRemovedGet(xml.location2));
        
        //--- Set Up xml.value2
        const names2 = this.getNames(room2);
        
        xTextViewBindString(xml.value2, xObservablePropertyMap<Array<string>, string>(names2, (it: Array<string>): string => (it[0] ?? null) ?? "Tap to add default location"));
        xViewOnClick(xml.value2, undefined, (): void => {
            this.value2Click();
        });
        
        //--- Set Up xml.floor2
        xTextViewBindString(xml.floor2, xObservablePropertyMap<Array<string>, string>(names2, (it: Array<string>): string => (it[1] ?? null) ?? ""));
        
        //--- Set Up xml.plusLocation2
        xViewBindExists(xml.plusLocation2, xObservablePropertyMap<(number | null), boolean>(this.value2, (it: (number | null)): boolean => it === null));
        
        //--- Set Up xml.deleteLocation2
        xViewBindExists(xml.deleteLocation2, xObservablePropertyMap<(number | null), boolean>(this.value2, (it: (number | null)): boolean => it !== null));
        xViewOnClick(xml.deleteLocation2, undefined, (): void => {
            this.deleteLocation2Click();
        });
        
        //--- Set Up xml.location3
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(room3, undefined, undefined, (it: (Room | null)): void => {
            xml.location3.style.opacity = `${(it !== null ? 1.0 : 0.5)}`;
        }), xViewRemovedGet(xml.location3));
        
        //--- Set Up xml.value3
        const names3 = this.getNames(room3);
        
        xTextViewBindString(xml.value3, xObservablePropertyMap<Array<string>, string>(names3, (it: Array<string>): string => (it[0] ?? null) ?? "Tap to add default location"));
        xViewOnClick(xml.value3, undefined, (): void => {
            this.value3Click();
        });
        
        //--- Set Up xml.floor3
        xTextViewBindString(xml.floor3, xObservablePropertyMap<Array<string>, string>(names3, (it: Array<string>): string => (it[1] ?? null) ?? ""));
        
        //--- Set Up xml.plusLocation3
        xViewBindExists(xml.plusLocation3, xObservablePropertyMap<(number | null), boolean>(this.value3, (it: (number | null)): boolean => it === null));
        
        //--- Set Up xml.deleteLocation3
        xViewBindExists(xml.deleteLocation3, xObservablePropertyMap<(number | null), boolean>(this.value3, (it: (number | null)): boolean => it !== null));
        xViewOnClick(xml.deleteLocation3, undefined, (): void => {
            this.deleteLocation3Click();
        });
        
        //--- Set Up xml.location4
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(room4, undefined, undefined, (it: (Room | null)): void => {
            xml.location4.style.opacity = `${(it !== null ? 1.0 : 0.5)}`;
        }), xViewRemovedGet(xml.location4));
        
        //--- Set Up xml.value4
        const names4 = this.getNames(room4);
        
        xTextViewBindString(xml.value4, xObservablePropertyMap<Array<string>, string>(names4, (it: Array<string>): string => (it[0] ?? null) ?? "Tap to add default location"));
        xViewOnClick(xml.value4, undefined, (): void => {
            this.value4Click();
        });
        
        //--- Set Up xml.floor4
        xTextViewBindString(xml.floor4, xObservablePropertyMap<Array<string>, string>(names4, (it: Array<string>): string => (it[1] ?? null) ?? ""));
        
        //--- Set Up xml.plusLocation4
        xViewBindExists(xml.plusLocation4, xObservablePropertyMap<(number | null), boolean>(this.value4, (it: (number | null)): boolean => it === null));
        
        //--- Set Up xml.deleteLocation4
        xViewBindExists(xml.deleteLocation4, xObservablePropertyMap<(number | null), boolean>(this.value4, (it: (number | null)): boolean => it !== null));
        xViewOnClick(xml.deleteLocation4, undefined, (): void => {
            this.deleteLocation4Click();
        });
        
        //--- Set Up xml.location5
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(room5, undefined, undefined, (it: (Room | null)): void => {
            xml.location5.style.opacity = `${(it !== null ? 1.0 : 0.5)}`;
        }), xViewRemovedGet(xml.location5));
        
        //--- Set Up xml.value5
        const names5 = this.getNames(room5);
        
        xTextViewBindString(xml.value5, xObservablePropertyMap<Array<string>, string>(names5, (it: Array<string>): string => (it[0] ?? null) ?? "Tap to add default location"));
        xViewOnClick(xml.value5, undefined, (): void => {
            this.value5Click();
        });
        
        //--- Set Up xml.floor5
        xTextViewBindString(xml.floor5, xObservablePropertyMap<Array<string>, string>(names5, (it: Array<string>): string => (it[1] ?? null) ?? ""));
        
        //--- Set Up xml.plusLocation5
        xViewBindExists(xml.plusLocation5, xObservablePropertyMap<(number | null), boolean>(this.value5, (it: (number | null)): boolean => it === null));
        
        //--- Set Up xml.deleteLocation5
        xViewBindExists(xml.deleteLocation5, xObservablePropertyMap<(number | null), boolean>(this.value5, (it: (number | null)): boolean => it !== null));
        xViewOnClick(xml.deleteLocation5, undefined, (): void => {
            this.deleteLocation5Click();
        });
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.updating
        xViewFlipperBindLoading(xml.updating, this.isLoading, undefined);
        
        //--- Set Up xml.save (overwritten on flow generation)
        xViewOnClick(xml.save, undefined, (): void => {
            this.saveClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public value1Click(): void {
        this.stack.push(new MapVG(this.stack, this.dialog, (it: number): void => {}, (it: number): void => {
            this.value1.value = it;
            this.stack.pop();
        }, this.schoolId, this.session));
    }
    
    public deleteLocation1Click(): void {
        this.value1.value = null;
    }
    
    public value2Click(): void {
        this.stack.push(new MapVG(this.stack, this.dialog, (it: number): void => {}, (it: number): void => {
            this.value2.value = it;
            this.stack.pop();
        }, this.schoolId, this.session));
    }
    
    public deleteLocation2Click(): void {
        this.value2.value = null;
    }
    
    public value3Click(): void {
        this.stack.push(new MapVG(this.stack, this.dialog, (it: number): void => {}, (it: number): void => {
            this.value3.value = it;
            this.stack.pop();
        }, this.schoolId, this.session));
    }
    
    public deleteLocation3Click(): void {
        this.value3.value = null;
    }
    
    public value4Click(): void {
        this.stack.push(new MapVG(this.stack, this.dialog, (it: number): void => {}, (it: number): void => {
            this.value4.value = it;
            this.stack.pop();
        }, this.schoolId, this.session));
    }
    
    public deleteLocation4Click(): void {
        this.value4.value = null;
    }
    
    public value5Click(): void {
        this.stack.push(new MapVG(this.stack, this.dialog, (it: number): void => {}, (it: number): void => {
            this.value5.value = it;
            this.stack.pop();
        }, this.schoolId, this.session));
    }
    
    public deleteLocation5Click(): void {
        this.value5.value = null;
    }
    
    public cancelClick(): void {
        this.stack.pop();
    }
    public saveClick(): void {
        if (this.usmId.value !== null) {
            xSingleCallDisplayingError<UserSchoolMembership>(xSingleWorking<UserSchoolMembership>(this.session.userSchoolMembership.patch(this.usmId.value!!, [ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("default_location1", this.value1.value, (item: UserSchoolMembership): UserSchoolMembership => item.copy(undefined, undefined, undefined, undefined, undefined, undefined, this.value1.value, undefined, undefined, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("default_location2", this.value2.value, (item: UserSchoolMembership): UserSchoolMembership => item.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.value2.value, undefined, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("default_location3", this.value3.value, (item: UserSchoolMembership): UserSchoolMembership => item.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.value3.value, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("default_location4", this.value4.value, (item: UserSchoolMembership): UserSchoolMembership => item.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.value4.value, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("default_location5", this.value5.value, (item: UserSchoolMembership): UserSchoolMembership => item.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.value5.value))]), this.isLoading), undefined, (it: UserSchoolMembership): void => {
                this.stack.pop();
            });
        } else {
            this.stack.pop();
        }
    }
    
    public getNames(observable: ObservableProperty<(Room | null)>): ObservableProperty<Array<string>> {
        const roomName = xObservablePropertyMap<(Room | null), string>(observable, (it: (Room | null)): string => (it?.name ?? null) ?? "Default");
        
        const floor = xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(observable, (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it));
        
        const floorName = xObservablePropertyMap<(Floor | null), string>(floor, (it: (Floor | null)): string => (it?.name ?? null) ?? "Default");
        
        const buildingName = xObservablePropertyMap<(Building | null), string>(xObservablePropertyFlatMapNotNull<number, Building>(xObservablePropertyMap<(Floor | null), (number | null)>(floor, (it: (Floor | null)): (number | null) => (it?.building ?? null)), (it: number): ObservableProperty<(Building | null)> => this.session.buildings.observable(it)), (it: (Building | null)): string => (it?.name ?? null) ?? "Default");
        
        return xObservablePropertyMap<Array<string>, Array<string>>(xListCombined<string>([roomName, floorName, buildingName]), (it: Array<string>): Array<string> => it.filter((it: string): boolean => !(it === "Default")));
    }
    
    //--- Body End
}
