// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminAlertTypeDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { District } from '../../model/District'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { Observable, ObservableInput, SubscriptionLike, zip as rxZip } from 'rxjs'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { getNO_ID } from '../../model/HasId'
import { ApiModifier } from '../../api/ApiModifier'
import { AlertType } from '../../model/AlertType'
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { DialogRequest, showDialog } from 'butterfly-web/dist/views/showDialog'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { FormAlertTypeDetailVG } from './FormAlertTypeDetailVG'
import { xIterableGroupBy } from 'butterfly-web/dist/kotlin/Iterables'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { AdminAlertTypeDetailXml } from '../../layout/AdminAlertTypeDetailXml'
import { flatMap as rxFlatMap, map as rxMap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { xMapFilter } from 'butterfly-web/dist/kotlin/Collections'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { AlertGuidance } from '../../model/AlertGuidance'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypeDetailVG
export class AdminAlertTypeDetailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly alertTypes: Array<ForeignKey<AlertType>>;
    public readonly region: (ForeignKey<District> | null);
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, alertTypes: Array<ForeignKey<AlertType>>, region: (ForeignKey<District> | null), session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.alertTypes = alertTypes;
        this.region = region;
        this.session = session;
        this.stack = stack;
        this.alertType = new StandardObservableProperty<AlertType>(new AlertType(undefined, undefined, getNO_ID(), "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined);
        this.initialGuidances = new Map([]);
        this.guidances = new StandardObservableProperty<Array<AlertGuidance>>([], undefined);
        this.submitting = new StandardObservableProperty<boolean>(false, undefined);
        this.loading = new StandardObservableProperty<boolean>(false, undefined);
        this.allGroups = new StandardObservableProperty<Array<ChatThread>>([], undefined);
        this.editedAllGroupsObservable = new StandardObservableProperty<Array<ChatThread>>([], undefined);
        this.alreadySelectedGroupObservable = new StandardObservableProperty<Array<ChatThread>>([], undefined);
        this.editedAllGroups = [];
        this.alreadySelectedGroup = [];
        this.form = new FormAlertTypeDetailVG(this.dialog, this.alertType, this.guidances, this.region, this.session, this.editedAllGroupsObservable, this.alreadySelectedGroupObservable);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<AlertGuidance>>(xSingleWorking<Array<AlertGuidance>>(this.session.alertTypes
                    .get(this.alertTypes[0]).pipe(rxFlatMap((it: AlertType): ObservableInput<Array<AlertGuidance>> => {
                    this.alertType.value = it;
                    return this.session.alertGuidances
                        .getListSimple(AlertGuidance.Companion.INSTANCE.forAlertTypes(this.alertTypes), undefined, undefined, undefined, undefined);
            })), this.loading), undefined, (response: Array<AlertGuidance>): void => {
                if (this.alertTypes.length > 1) {
                    this.initialGuidances = xMapFilter(xIterableGroupBy<AlertGuidance, string>(response, (it: AlertGuidance): string => it.title), (entry: [string, Array<AlertGuidance>]): boolean => entry[1].length === this.alertTypes.length);
                    this.guidances.value = iterToArray(iterMap(this.initialGuidances, (it: [string, Array<AlertGuidance>]): AlertGuidance => it[1][0]));
                } else {
                    this.guidances.value = response;
                    this.initialGuidances = new Map(response.map((it: AlertGuidance): [string, Array<AlertGuidance>] => [it.title, [it]]));
                }
        }));
    }
    
    
    
    //--- Provides alertType
    //--- Provides guidances
    
    public readonly alertType: MutableObservableProperty<AlertType>;
    
    
    public initialGuidances: Map<string, Array<AlertGuidance>>;
    
    public readonly guidances: MutableObservableProperty<Array<AlertGuidance>>;
    
    public readonly submitting: MutableObservableProperty<boolean>;
    
    public readonly loading: MutableObservableProperty<boolean>;
    
    
    //val loading: MutableObservableProperty<Boolean> = StandardObservableProperty(false)
    
    public readonly allGroups: MutableObservableProperty<Array<ChatThread>>;
    
    
    public readonly editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>;
    
    
    public readonly alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>;
    
    
    public readonly editedAllGroups: Array<ChatThread>;
    
    
    public readonly alreadySelectedGroup: Array<ChatThread>;
    
    
    
    public readonly form: FormAlertTypeDetailVG;
    
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypeDetailVG.title
    public get title(): string { return "Admin Alert Type Detail"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminAlertTypeDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        
        //--- Set Up xml.loading
        xViewFlipperBindLoading(xml.loading, this.loading, undefined);
        
        //--- Set Up xml.detailForm.xmlRoot
        //--- Set Up xml.detailForm.title
        //--- Set Up xml.detailForm.siteSection
        //--- Set Up xml.detailForm.school
        //--- Set Up xml.detailForm.regionSection
        //--- Set Up xml.detailForm.region
        //--- Set Up xml.detailForm.form.xmlRoot
        //--- Set Up xml.detailForm.form.alertName
        //--- Set Up xml.detailForm.form.iconImage
        //--- Set Up xml.detailForm.form.iconChange
        //--- Set Up xml.detailForm.form.mp3Change
        //--- Set Up xml.detailForm.form.mp3Name
        //--- Set Up xml.detailForm.form.oggChange
        //--- Set Up xml.detailForm.form.oggName
        //--- Set Up xml.detailForm.form.guidances
        //--- Make Subview For xml.detailForm.form.guidances
        //--- Set Up cellXml.root
        //--- Set Up cellXml.title
        //--- Set Up cellXml.body
        //--- Set Up cellXml.remove
        //--- End Make Subview For xml.detailForm.form.guidances
        //--- Set Up xml.detailForm.form.newGuidance
        //--- Set Up xml.detailForm.form.newTitle
        //--- Set Up xml.detailForm.form.newPlan
        //--- Set Up xml.detailForm.form.cancelGuidance
        //--- Set Up xml.detailForm.form.saveGuidance
        //--- Set Up xml.detailForm.form.addGuidance
        //--- Set Up xml.detailForm.form.adminApproval
        this.form.generateFromXml(dependency, xml.detailForm);
        
        //--- Set Up xml.submitWorking
        xViewFlipperBindLoading(xml.submitWorking, this.submitting, undefined);
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.submit (overwritten on flow generation)
        xViewOnClick(xml.submit, undefined, (): void => {
            this.submitClick();
        });
        
        //--- Set Up xml.delete (overwritten on flow generation)
        xViewOnClick(xml._delete, undefined, (): void => {
            this.deleteClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cellXmlRemoveClick(): void {
    }
    //--- Action detailFormFormCaxncelGuidanceClick
    //--- Action detailFormFormSaveGuidanceClick
    //--- Action detailFormFormAddGuidanceClick
    
    public cancelClick(): void {
        this.stack.pop();
    }
    
    public submitClick(): void {
        
        const actions: Array<Observable<void>> = [];
        
        
        const modifiers: Array<ApiModifier<AlertType>> = [ApiModifier.constructorStringAnyFunction1HasIdHasId<AlertType>("name", this.alertType.value.name, (at: AlertType): AlertType => at.copy(undefined, undefined, undefined, this.alertType.value.name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<AlertType>("alert_to_specific_groups_only", this.alertType.value.groups, (at: AlertType): AlertType => at.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.alertType.value.groups, undefined, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<AlertType>("requires_admin_approval", this.alertType.value.requiresAdminApproval, (at: AlertType): AlertType => at.copy(undefined, undefined, undefined, undefined, undefined, undefined, this.alertType.value.requiresAdminApproval, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<AlertType>("can_start_others_group_alert", this.alertType.value.canStartOthersGroupAlert, (at: AlertType): AlertType => at.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.alertType.value.canStartOthersGroupAlert, undefined, undefined, undefined, undefined, undefined, undefined)), ApiModifier.constructorStringAnyFunction1HasIdHasId<AlertType>("urgency", this.alertType.value.urgency, (at: AlertType): AlertType => at.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.alertType.value.urgency, undefined))];
        
        
        actions.push(...this.alertTypes.map((it: number): Observable<void> => this.session.alertTypes.patch(it, modifiers).pipe(rxMap((it: AlertType): void => {
            return undefined;
        }))));
        
        const image_3396 = this.form.form.iconActual.value;
        if (image_3396 !== null) {
            actions.push(...this.alertTypes.map((it: number): Observable<void> => this.session.rawApi.uploadAlertIcon(this.session.session, it, image_3396).pipe(rxMap((it: AlertType): void => {
                return undefined;
            }))));
        }
        const uri_3402 = this.form.form.mp3.value;
        if (uri_3402 !== null) {
            actions.push(...this.alertTypes.map((it: number): Observable<void> => this.session.rawApi.uploadAlertMp3(this.session.session, it, uri_3402).pipe(rxMap((it: AlertType): void => {
                return undefined;
            }))));
        }
        const uri_3408 = this.form.form.ogg.value;
        if (uri_3408 !== null) {
            actions.push(...this.alertTypes.map((it: number): Observable<void> => this.session.rawApi.uploadAlertOgg(this.session.session, it, uri_3408).pipe(rxMap((it: AlertType): void => {
                return undefined;
            }))));
        }
        
        for (const guidance of this.guidances.value) {
            if (guidance.id < 0) {
                actions.push(...this.alertTypes.map((id: number): Observable<void> => this.session.alertGuidances.post(new AlertGuidance(undefined, id, guidance.title, guidance.plan)).pipe(rxMap((it: AlertGuidance): void => {
                    return undefined;
                }))));
            }
        }
        for (const guidance of this.initialGuidances) {
            if ((!this.guidances.value.some((it: AlertGuidance): boolean => it.id === guidance[1][0].id))) {
                actions.push(...guidance[1].map((it: AlertGuidance): Observable<void> => this.session.alertGuidances._delete(it.id)));
            }
        }
        
        xSingleCallDisplayingError<Array<void>>(xSingleWorking<Array<void>>(rxZip(...actions), this.submitting), undefined, (it: Array<void>): void => {
            this.stack.pop();
        });
    }
    
    public deleteClick(): void {
        showDialog(new DialogRequest(new ViewStringResource(R._string.are_you_sure_delete_alert_type), (): void => {
            xSingleCallDisplayingError<Array<void>>(xSingleWorking<Array<void>>(rxZip(...this.alertTypes.map((id: number): Observable<void> => this.session.alertTypes._delete(id))), this.submitting), undefined, (it: Array<void>): void => {
                this.stack.pop();
            });
        }));
    }
    
    //--- Body End
}
