// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/ChatThread.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Time } from '../util/time'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { Alert } from './Alert'
import { HasId, getNO_ID } from './HasId'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ApiModifier } from '../api/ApiModifier'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.ChatThread
export class ChatThread implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly name: (string | null);
    public readonly created: Date;
    public readonly userIds: Array<ForeignKey<User>>;
    public readonly schools: Array<ForeignKey<School>>;
    public readonly associatedSchools: Array<ForeignKey<School>>;
    public readonly alert: (ForeignKey<Alert> | null);
    public readonly lastUpdated: (Date | null);
    public readonly thread_type: (string | null);
    public readonly userNames: Array<string>;
    public readonly schoolNames: Array<string>;
    public subscribed: boolean;
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, name: (string | null) = null, created: Date = Time.INSTANCE.date(), userIds: Array<ForeignKey<User>> = [], schools: Array<ForeignKey<School>> = [], associatedSchools: Array<ForeignKey<School>> = [], alert: (ForeignKey<Alert> | null) = null, lastUpdated: (Date | null) = null, thread_type: (string | null) = null, userNames: Array<string> = [], schoolNames: Array<string> = [], subscribed: boolean = false) {
        this.id = id;
        this.deleted = deleted;
        this.name = name;
        this.created = created;
        this.userIds = userIds;
        this.schools = schools;
        this.associatedSchools = associatedSchools;
        this.alert = alert;
        this.lastUpdated = lastUpdated;
        this.thread_type = thread_type;
        this.userNames = userNames;
        this.schoolNames = schoolNames;
        this.subscribed = subscribed;
    }
    public static fromJson(obj: any): ChatThread { return new ChatThread(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["name"], [String]) as (string | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["user_ids"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["schools"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["associated_schools"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["alert_id"], [Number]) as (number | null),
        parseJsonTyped(obj["last_update"], [Date]) as (Date | null),
        parseJsonTyped(obj["thread_type"], [String]) as (string | null),
        parseJsonTyped(obj["user_names"], [Array, [String]]) as Array<string>,
        parseJsonTyped(obj["school_names"], [Array, [String]]) as Array<string>,
        parseJsonTyped(obj["subscribed"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        name: this.name,
        created: this.created,
        user_ids: this.userIds,
        schools: this.schools,
        associated_schools: this.associatedSchools,
        alert_id: this.alert,
        last_update: this.lastUpdated,
        thread_type: this.thread_type,
        user_names: this.userNames,
        school_names: this.schoolNames,
        subscribed: this.subscribed
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.userIds);
        hash = 31 * hash + hashAnything(this.schools);
        hash = 31 * hash + hashAnything(this.associatedSchools);
        hash = 31 * hash + hashAnything(this.alert);
        hash = 31 * hash + hashAnything(this.lastUpdated);
        hash = 31 * hash + hashAnything(this.thread_type);
        hash = 31 * hash + hashAnything(this.userNames);
        hash = 31 * hash + hashAnything(this.schoolNames);
        hash = 31 * hash + hashAnything(this.subscribed);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ChatThread && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.name, other.name) && safeEq(this.created, other.created) && safeEq(this.userIds, other.userIds) && safeEq(this.schools, other.schools) && safeEq(this.associatedSchools, other.associatedSchools) && safeEq(this.alert, other.alert) && safeEq(this.lastUpdated, other.lastUpdated) && safeEq(this.thread_type, other.thread_type) && safeEq(this.userNames, other.userNames) && safeEq(this.schoolNames, other.schoolNames) && safeEq(this.subscribed, other.subscribed) }
    public toString(): string { return `ChatThread(id=${this.id}, deleted=${this.deleted}, name=${this.name}, created=${this.created}, userIds=${this.userIds}, schools=${this.schools}, associatedSchools=${this.associatedSchools}, alert=${this.alert}, lastUpdated=${this.lastUpdated}, thread_type=${this.thread_type}, userNames=${this.userNames}, schoolNames=${this.schoolNames}, subscribed=${this.subscribed})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, name: (string | null) = this.name, created: Date = this.created, userIds: Array<ForeignKey<User>> = this.userIds, schools: Array<ForeignKey<School>> = this.schools, associatedSchools: Array<ForeignKey<School>> = this.associatedSchools, alert: (ForeignKey<Alert> | null) = this.alert, lastUpdated: (Date | null) = this.lastUpdated, thread_type: (string | null) = this.thread_type, userNames: Array<string> = this.userNames, schoolNames: Array<string> = this.schoolNames, subscribed: boolean = this.subscribed): ChatThread { return new ChatThread(id, deleted, name, created, userIds, schools, associatedSchools, alert, lastUpdated, thread_type, userNames, schoolNames, subscribed); }
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.model.ChatThread.threadType
    public get threadType(): ChatThread.ThreadType { return ((): ChatThread.ThreadType => {
        if (this.alert !== null) {
            return ChatThread.ThreadType.Alert
        } else if (this.thread_type === "direct") {
            return ChatThread.ThreadType.Contact
        } else  {
            return ChatThread.ThreadType.Group
        }
    })(); }
    
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace ChatThread {
    //! Declares com.tresitgroup.android.tresit.model.ChatThread.ThreadType
    export class ThreadType {
        private constructor(name: string, jsonName: string) {
            this.name = name;
            this.jsonName = jsonName;
        }
        
        public static Alert = new ThreadType("Alert", "Alert");
        public static Group = new ThreadType("Group", "Group");
        public static Contact = new ThreadType("Contact", "Contact");
        
        private static _values: Array<ThreadType> = [ThreadType.Alert, ThreadType.Group, ThreadType.Contact];
        public static values(): Array<ThreadType> { return ThreadType._values; }
        public readonly name: string;
        public readonly jsonName: string;
        public static valueOf(name: string): ThreadType { return (ThreadType as any)[name]; }
        public toString(): string { return this.name }
        public toJSON(): string { return this.jsonName }
    }
}
export namespace ChatThread {
    //! Declares com.tresitgroup.android.tresit.model.ChatThread.Companion
    export class Companion {
        private constructor() {
            this.mostRecent = ApiSort.constructorStringComparatorHasId<ChatThread>("sort=-last_update", (left: ChatThread, right: ChatThread): number => {
                const leftLast = left.lastUpdated;
                
                const rightLast = right.lastUpdated;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(rightLast!, leftLast);
                        } else {
                            return (-1);
                        }
                    } else {
                        if (rightLast !== null) {
                            return 1;
                        } else {
                            return safeCompare(left.id, right.id);
                        }
                    }
                })()
            });
            this.mostRecentDown = ApiSort.constructorStringComparatorHasId<ChatThread>("sort=last_update", (left: ChatThread, right: ChatThread): number => {
                const leftLast = left.lastUpdated;
                
                const rightLast = right.lastUpdated;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(leftLast!, rightLast);
                        } else {
                            return 1;
                        }
                    } else {
                        if (rightLast !== null) {
                            return (-1);
                        } else {
                            return (-safeCompare(left.id, right.id));
                        }
                    }
                })()
            });
            this.alphabetical = ApiSort.constructorStringComparatorHasId<ChatThread>("sort=name", (left: ChatThread, right: ChatThread): number => {
                const leftLast = left.name;
                
                const rightLast = right.name;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(leftLast!, rightLast!);
                        } else {
                            return (-1);
                        }
                    } else {
                        if (rightLast !== null) {
                            return 1;
                        } else {
                            return safeCompare(left.id, right.id);
                        }
                    }
                })()
            });
            this.alphabeticalDown = ApiSort.constructorStringComparatorHasId<ChatThread>("sort=-name", (left: ChatThread, right: ChatThread): number => {
                const leftLast = left.name;
                
                const rightLast = right.name;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(rightLast!, leftLast!);
                        } else {
                            return 1;
                        }
                    } else {
                        if (rightLast !== null) {
                            return (-1);
                        } else {
                            return safeCompare(right.id, left.id);
                        }
                    }
                })()
            });
        }
        public static INSTANCE = new Companion();
        
        public textSearch(query: string): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>(`query=${query}`, (db: MockDatabase, hist: ChatThread): boolean => true, undefined);
        }
        
        public setUsers(userIds: Array<ForeignKey<User>>): ApiModifier<ChatThread> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<ChatThread>("user_ids", userIds, (it: ChatThread): ChatThread => it.copy(undefined, undefined, undefined, undefined, userIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public setSchools(schoolIds: Array<ForeignKey<School>>): ApiModifier<ChatThread> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<ChatThread>("schools", schoolIds, (it: ChatThread): ChatThread => it.copy(undefined, undefined, undefined, undefined, undefined, schoolIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public setName(name: string): ApiModifier<ChatThread> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<ChatThread>("name", name, (it: ChatThread): ChatThread => it.copy(undefined, undefined, name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public isNotAlertThread(): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>("alert_id__isnull=true", undefined, (it: ChatThread): boolean => it.alert === null);
        }
        
        public isAlertThread(): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>("alert_id__isnull=false", undefined, (it: ChatThread): boolean => it.alert !== null);
        }
        
        public isGroupThread(): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>("alert_id__isnull=true&is_direct_user=false", undefined, (it: ChatThread): boolean => safeEq(it.threadType, ThreadType.Group));
        }
        
        public forAlertType(): ApiFilter<ChatThread> {
            return new ApiFilter<ChatThread>("from=alert_type");
        }
        
        public isUserThread(): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>("alert_id__isnull=true&is_direct_user=true", undefined, (it: ChatThread): boolean => safeEq(it.threadType, ThreadType.Contact));
        }
        
        public forSchool(schoolId: ForeignKey<School>): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>(`school=${schoolId}`, undefined, (it: ChatThread): boolean => it.associatedSchools.some((x) => safeEq(schoolId, x)));
        }
        
        public forAlert(alertId: ForeignKey<Alert>): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>(`alert_id=${alertId}`, undefined, (it: ChatThread): boolean => it.alert === alertId);
        }
        
        public exactlyUsers(userIds: Array<ForeignKey<User>>): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>(`user_ids=${userIds.join(",")}`, undefined, (it: ChatThread): boolean => safeEq(new Set(it.userIds), new Set(userIds)));
        }
        
        public active(): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>("is_active=true", undefined, (it: ChatThread): boolean => it.isActive === true);
        }
        public search(query: string): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>(`query=${query}`, undefined, (it: ChatThread): boolean => ((): (boolean | null) => {
                const temp2149 = it.name;
                if(temp2149 === null) { return null }
                return (temp2149.toLowerCase().indexOf(query.toLowerCase()) != -1)
            })() ?? false);
        }
        
        public anyMatchingUsers(userIds: Array<ForeignKey<User>>): ApiFilter<ChatThread> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThread>(`user_ids__contains=${userIds.join(",")}`, undefined, (it: ChatThread): boolean => it.userIds.some((a: number): boolean => userIds.some((b: number): boolean => a === b)));
        }
        
        public readonly mostRecent: ApiSort<ChatThread>;
        
        public readonly mostRecentDown: ApiSort<ChatThread>;
        
        
        public readonly alphabetical: ApiSort<ChatThread>;
        
        
        public readonly alphabeticalDown: ApiSort<ChatThread>;
        
    }
}