// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/Paints.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { xActivityAccessGetColor } from 'butterfly-web/dist/views/ViewDependency'
import { R } from '../../R'
import { DisplayMetrics } from 'butterfly-web/dist/views/DisplayMetrics'
import { applyAlphaToColor, numberToColor } from 'butterfly-web/dist/views/Colors'
import { CombinedStatus } from '../../model/CombinedStatus'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { also } from 'butterfly-web/dist/Kotlin'
import { StatusEnum } from '../../model/StatusEnum'

//! Declares com.tresitgroup.android.tresit.vg.map.SvgPaints
export class SvgPaints {
    public constructor(dependency: Window) {
        this.currentCached = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
        });
        this.start = Date.now();
        this.safe = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = applyAlphaToColor(xActivityAccessGetColor(dependency, R.color.safe), 128);
        });
        this.unsafe = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = applyAlphaToColor(xActivityAccessGetColor(dependency, R.color.unsafe), 128);
        });
        this.unknown = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = applyAlphaToColor(xActivityAccessGetColor(dependency, R.color.unknown), 128);
        });
        this.cleared = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = applyAlphaToColor(xActivityAccessGetColor(dependency, R.color.cleared), 128);
        });
        this.medicalOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.medical);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 3;
        });
    }
    
    private readonly currentCached: Paint;
    
    public readonly start: number;
    
    //! Declares com.tresitgroup.android.tresit.vg.map.SvgPaints.elapsed
    public get elapsed(): number { return (Date.now() - this.start) / 1000; }
    
    public highlight(): Paint {
        const betweenZeroAndOne = Math.sin(this.elapsed * 3) / 2 + .5;
        
        const multiplier = betweenZeroAndOne * .15 + .85;
        
        this.currentCached.color = applyAlphaToColor(numberToColor(0x0), 255 - Math.floor((multiplier * 255)));
        return this.currentCached;
    }
    public readonly safe: Paint;
    
    public readonly unsafe: Paint;
    
    public readonly unknown: Paint;
    
    public readonly cleared: Paint;
    
    public readonly medicalOutline: Paint;
    
    
    public forStatus(status: StatusEnum): Paint {
        return ((): Paint => {
            switch(status) {
                case StatusEnum.Safe:
                return this.safe
                case StatusEnum.Cleared:
                return this.cleared
                case StatusEnum.Unsafe:
                return this.unsafe
                case StatusEnum.Unknown:
                return this.unknown
                default:
                return this.unknown
            }
            
        })();
    }
    
    public forCombinedStatus(it: CombinedStatus): Array<Paint> {
        const base = this.forStatus(it.status);
        
        if (it.needsMedicalAttention) {
            return [base, this.medicalOutline];
        } else {
            return [base];
        }
    }
}

//! Declares com.tresitgroup.android.tresit.vg.map.MapPaints
export class MapPaints {
    public constructor(dependency: Window) {
        this.currentCached = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
        });
        this.start = Date.now();
        this.safe = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.safeMap);
        });
        this.unsafe = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.unsafeMap);
        });
        this.unknown = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.unknownMap);
        });
        this.cleared = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.clearedMap);
        });
        this.safeOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.safeMapDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 1;
        });
        this.unsafeOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.unsafeMapDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 1;
        });
        this.unknownOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.unknownMapDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 1;
        });
        this.clearedOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.clearedMapDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 1;
        });
        this.medicalOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.medical);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 3;
        });
        this.campus = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.campus);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 1;
        });
        this.room = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.room);
        });
        this.roomOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.roomDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 2;
        });
        this.building = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.building);
        });
        this.buildingOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.buildingDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 2;
        });
        this.buildingBranch = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.FILL;
            this_.color = xActivityAccessGetColor(dependency, R.color.buildingBranch);
        });
        this.buildingBranchOutline = also(new Paint(), (this_: Paint): void => {
            this_.style = Paint.Style.STROKE;
            this_.color = xActivityAccessGetColor(dependency, R.color.buildingBranchDark);
            this_.strokeWidth = DisplayMetrics.INSTANCE.density * 2;
        });
    }
    
    private readonly currentCached: Paint;
    
    public readonly start: number;
    
    //! Declares com.tresitgroup.android.tresit.vg.map.MapPaints.elapsed
    public get elapsed(): number { return (Date.now() - this.start) / 1000; }
    
    public highlight(): Paint {
        const betweenZeroAndOne = Math.sin(this.elapsed * 3) / 2 + .5;
        
        const multiplier = betweenZeroAndOne * .15 + .85;
        
        this.currentCached.color = applyAlphaToColor(numberToColor(0x0), 255 - Math.floor((multiplier * 255)));
        return this.currentCached;
    }
    public readonly safe: Paint;
    
    public readonly unsafe: Paint;
    
    public readonly unknown: Paint;
    
    public readonly cleared: Paint;
    
    public readonly safeOutline: Paint;
    
    public readonly unsafeOutline: Paint;
    
    public readonly unknownOutline: Paint;
    
    public readonly clearedOutline: Paint;
    
    public readonly medicalOutline: Paint;
    
    public readonly campus: Paint;
    
    public readonly room: Paint;
    
    public readonly roomOutline: Paint;
    
    public readonly building: Paint;
    
    public readonly buildingOutline: Paint;
    
    public readonly buildingBranch: Paint;
    
    public readonly buildingBranchOutline: Paint;
    
    
    public forStatus(status: StatusEnum): Array<Paint> {
        return ((): Array<Paint> => {
            switch(status) {
                case StatusEnum.Safe:
                return [this.safe, this.safeOutline]
                case StatusEnum.Cleared:
                return [this.cleared, this.clearedOutline]
                case StatusEnum.Unsafe:
                return [this.unsafe, this.unsafeOutline]
                case StatusEnum.Unknown:
                return [this.unknown, this.unknownOutline]
                default:
                return []
            }
            
        })();
    }
    
    public forCombinedStatus(it: (CombinedStatus | null), defaultTo: Array<Paint>): Array<Paint> {
        if (it === null) { return defaultTo }
        if (it!.needsMedicalAttention) {
            switch(it!.status) {
                case StatusEnum.Safe:
                return [this.safe, this.medicalOutline]
                case StatusEnum.Cleared:
                return [this.cleared, this.medicalOutline]
                case StatusEnum.Unsafe:
                return [this.unsafe, this.medicalOutline]
                case StatusEnum.Unknown:
                return [this.unknown, this.medicalOutline]
            }
            
        } else {
            switch(it!.status) {
                case StatusEnum.Safe:
                return [this.safe, this.safeOutline]
                case StatusEnum.Cleared:
                return [this.cleared, this.clearedOutline]
                case StatusEnum.Unsafe:
                return [this.unsafe, this.unsafeOutline]
                case StatusEnum.Unknown:
                return [this.unknown, this.unknownOutline]
            }
            
        }
        return defaultTo;
    }
}