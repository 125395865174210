// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/LiveApiTable.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { Session } from '../model/Session'
import { xAnyToJsonHttpBody } from 'butterfly-web/dist/net/HttpBody'
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { Observable, SubscriptionLike } from 'rxjs'
import { getTotalRequestsMade, setTotalRequestsMade } from './apiProperty'
import { Page } from '../model/Page'
import { doOnSubscribe } from 'butterfly-web/dist/Kotlin'
import { xSingleDiscard } from 'butterfly-web/dist/net/RxHttpAssist'
import { ApiModifier } from './ApiModifier'
import { map as rxMap } from 'rxjs/operators'
import { ForeignKey } from '../model/ForeignKey'
import { ApiTable } from './ApiTable'
import { ApiPartialQuery } from './ApiPartialQuery'

//! Declares com.tresitgroup.android.tresit.api.LiveApiTable
export class LiveApiTable<T extends HasId> extends ApiTable<T> {
    public readonly httpUrl: string;
    public readonly path: string;
    public readonly readSingle:  ((a: Observable<Response>) => Observable<T>);
    public readonly readPage:  ((a: Observable<Response>) => Observable<Page<T>>);
    public readonly session: Session;
    public constructor(httpUrl: string, path: string, readSingle:  ((a: Observable<Response>) => Observable<T>), readPage:  ((a: Observable<Response>) => Observable<Page<T>>), session: Session) {
        super();
        this.httpUrl = httpUrl;
        this.path = path;
        this.readSingle = readSingle;
        this.readPage = readPage;
        this.session = session;
        this.defaultHeaders = new Map([["Authorization", `JWT ${this.session.token}`]]);
    }
    
    public readonly defaultHeaders: Map<string, string>;
    
    public get(id: ForeignKey<T>): Observable<T> {
        return doOnSubscribe((this.readSingle)(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/${id}/`, HttpClient.INSTANCE.GET, this.defaultHeaders, undefined, undefined)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        });
    }
    
    public getMultiple(ids: Array<ForeignKey<T>>): Observable<Array<T>> {
        return doOnSubscribe((this.readPage)(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/?ids=${ids.join(", ")}`, HttpClient.INSTANCE.GET, this.defaultHeaders, undefined, undefined)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        }).pipe(rxMap((it: Page<T>): Array<T> => it.results));
    }
    
    public getList(query: ApiPartialQuery<T>): Observable<Array<T>> {
        return doOnSubscribe((this.readPage)(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/${query.queryString()}`, HttpClient.INSTANCE.GET, this.defaultHeaders, undefined, undefined)).pipe(rxMap((it: Page<T>): Array<T> => it.results)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        });
    }
    
    public put(value: T): Observable<T> {
        return doOnSubscribe((this.readSingle)(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/${value.id}/`, HttpClient.INSTANCE.PUT, this.defaultHeaders, xAnyToJsonHttpBody(value), undefined)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        });
    }
    
    public post(value: T): Observable<T> {
        return doOnSubscribe((this.readSingle)(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/`, HttpClient.INSTANCE.POST, this.defaultHeaders, xAnyToJsonHttpBody(value), undefined)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        });
    }
    
    public patch(id: ForeignKey<T>, modifiers: Array<ApiModifier<T>>): Observable<T> {
        return doOnSubscribe((this.readSingle)(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/${id}/`, HttpClient.INSTANCE.PATCH, this.defaultHeaders, xAnyToJsonHttpBody(new Map<string, (any | null)>(modifiers.map((it: ApiModifier<T>): [string, (any | null)] => [it.key, it.value]))), undefined)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        });
    }
    
    public _delete(id: ForeignKey<T>): Observable<void> {
        return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/${this.path}/${id}/`, HttpClient.INSTANCE.DELETE, this.defaultHeaders, undefined, undefined)), (it: SubscriptionLike): void => {
            setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
        });
    }
    
    //    override fun createOffsite(id: String): Single<T> {
        //        return HttpClient.call(
            //            url = httpUrl + "api/v2/schools/$id/offsite/create/",
            //            method = HttpClient.GET,
            //            headers = defaultHeaders
        //        ).let(readSingle).doOnSubscribe { totalRequestsMade++ }
    //    }
    
}


