//
// ConversationAllXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './conversation_all.html'
//! Declares com.tresitgroup.android.tresit.layouts.ConversationAllXml
export class ConversationAllXml {
    xmlRoot!: HTMLElement
    groupNameEntry!: HTMLInputElement
    loadingList!: HTMLDivElement
    list!: HTMLDivElement
    create!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.groupNameEntry = getViewById<HTMLInputElement>(view, "groupNameEntry")
        this.loadingList = getViewById<HTMLDivElement>(view, "loadingList")
        this.list = getViewById<HTMLDivElement>(view, "list")
        this.create = getViewById<HTMLButtonElement>(view, "create")
        return view
    }
}
