// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiModifier.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'

//! Declares com.tresitgroup.android.tresit.api.ApiModifier
export class ApiModifier<T extends HasId> {
    public readonly key: string;
    public readonly value: (any | null);
    public constructor(key: string, value: (any | null)) {
        this.key = key;
        this.value = value;
    }
    
    public setter: ((a: T) => T);
    
    
    public static constructorStringAnyFunction1HasIdHasId<T extends HasId>(key: string, value: (any | null), setter:  ((a: T) => T)) {
        let result = new ApiModifier<T>(key, value);
        
        result.setter = setter
        
        return result;
    }
}