//
// PostCompleteXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './post_complete.html'
//! Declares com.tresitgroup.android.tresit.layouts.PostCompleteXml
export class PostCompleteXml {
    xmlRoot!: HTMLElement
    close!: HTMLButtonElement
    title!: HTMLElement
    personType!: HTMLElement
    name!: HTMLElement
    time!: HTMLElement
    postDetails!: HTMLElement
    reason!: HTMLTextAreaElement
    cancel!: HTMLButtonElement
    completeWorking!: HTMLDivElement
    complete!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.close = getViewById<HTMLButtonElement>(view, "close")
        this.title = getViewById<HTMLElement>(view, "title")
        this.personType = getViewById<HTMLElement>(view, "personType")
        this.name = getViewById<HTMLElement>(view, "name")
        this.time = getViewById<HTMLElement>(view, "time")
        this.postDetails = getViewById<HTMLElement>(view, "postDetails")
        this.reason = getViewById<HTMLTextAreaElement>(view, "reason")
        this.cancel = getViewById<HTMLButtonElement>(view, "cancel")
        this.completeWorking = getViewById<HTMLDivElement>(view, "completeWorking")
        this.complete = getViewById<HTMLButtonElement>(view, "complete")
        return view
    }
}
