// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/OnAlertStartedVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { setViewVisibility, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { AlertType } from '../../model/AlertType'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { R } from '../../R'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { SelectLocationVG } from './SelectLocationVG'
import { OnAlertStartedXml } from '../../layout/OnAlertStartedXml'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { iterLastOrNull } from 'butterfly-web/dist/KotlinCollections'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.alert.OnAlertStartedVG
export class OnAlertStartedVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly alertId: ForeignKey<Alert>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly sessionStack: ObservableStack<ViewGenerator>;
    public constructor(alertId: ForeignKey<Alert>, dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>, session: SessionApi, sessionStack: ObservableStack<ViewGenerator>) {
        super();
        this.alertId = alertId;
        this.dialog = dialog;
        this.root = root;
        this.session = session;
        this.sessionStack = sessionStack;
        this.alertStartedStack = new ObservableStack<ViewGenerator>();
        this.alert = this.session.alerts.observable(this.alertId);
        console.error(`${"OnAlertStarted"}: ${"Created"}`);
        this.alertStartedStack.reset(new SelectLocationVG(this.alertId, this.dialog, this.session, this.alertStartedStack));
    }
    
    
    public readonly alertStartedStack: ObservableStack<ViewGenerator>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.OnAlertStartedVG.title
    public get title(): string { return "On Alert Started"; }
    
    
    public readonly alert: ObservableProperty<(Alert | null)>;
    
    public alertType(): ObservableProperty<(AlertType | null)> {
        return xObservablePropertyFlatMapNotNull<number, AlertType>(xObservablePropertyMap<(Alert | null), (number | null)>(this.alert, (it: (Alert | null)): (number | null) => (it?.name ?? null)), (it: number): ObservableProperty<(AlertType | null)> => this.session.alertTypes.observable(it));
    }
    
    public onBackPressed(): boolean {
        return this.alertStartedStack.backPressPop();
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new OnAlertStartedXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.back
        //        xml.back.bindVisible(alertStartedStack.map { it.size > 1 })
        setViewVisibility(xml.back, "gone");
        xViewOnClick(xml.back, undefined, (): void => {
            this.alertStartedStack.pop();
        });
        
        //--- current alert
        const alertType = this.alertType();
        
        
        //--- Catch background taps
        xViewOnClick(xml.xmlRoot, undefined, (): void => {});
        
        //--- Set Up xml.statusImage
        xImageViewBindImage(xml.statusImage, xObservablePropertyMap<(AlertType | null), (Image | null)>(alertType, (it: (AlertType | null)): (Image | null) => ((): (Image | null) => {
            const temp4045 = (it?.icon ?? null);
            if(temp4045 !== null) {
                return xStringAsImage(temp4045)
            } else { return null }
        })()));
        
        //--- Set Up xml.statusText
        xTextViewBindString(xml.statusText, xObservablePropertyMap<(AlertType | null), string>(alertType, (it: (AlertType | null)): string => (it?.name ?? null) ?? "Emergency"));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            xml.statusText.style.color = (it?.colorResource ?? null) ?? R.color.foreground;
        }), xViewRemovedGet(xml.statusText));
        
        //--- Set Up xml.school
        xTextViewBindString(xml.school, xObservablePropertyMap<(School | null), string>(xObservablePropertyFlatMapNotNull<number, School>(xObservablePropertyMap<(Alert | null), (number | null)>(this.alert, (it: (Alert | null)): (number | null) => (it?.school ?? null)), (it: number): ObservableProperty<(School | null)> => this.session.schools.observable(it)), (it: (School | null)): string => (it?.name ?? null) ?? "-"));
        
        //--- Auto-dismiss on alert end
        console.error(`${"OnAlertStarted"}: ${"Generated"}`);
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            post((): void => {
                console.error(`${"OnAlertStarted"}: ${`Got value ${it} for the alert, is last: ${iterLastOrNull(this.dialog.value) === this}`}`);
                if (it === null && iterLastOrNull(this.dialog.value) === this) {
                    this.dialog.dismiss();
                }
            });
        }), xViewRemovedGet(view));
        
        //--- Set Up xml.alertStartedView (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.alertStartedView, dependency, this.alertStartedStack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public backClick(): void {
        if (this.sessionStack.pop()) {} else if (this.root.pop()) {}
    }
    
    //--- Body End
}
