// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/TypedSocketMessage.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Time } from '../util/time'

//! Declares com.tresitgroup.android.tresit.model.TypedSocketMessage
export class TypedSocketMessage<T extends any> {
    public readonly type: string;
    public readonly data: (T | null);
    public readonly timestamp: Date;
    public readonly id: (number | null);
    public constructor(type: string = "", data: (T | null) = null, timestamp: Date = Time.INSTANCE.date(), id: (number | null) = null) {
        this.type = type;
        this.data = data;
        this.timestamp = timestamp;
        this.id = id;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.type);
        hash = 31 * hash + hashAnything(this.data);
        hash = 31 * hash + hashAnything(this.timestamp);
        hash = 31 * hash + hashAnything(this.id);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof TypedSocketMessage && safeEq(this.type, other.type) && safeEq(this.data, other.data) && safeEq(this.timestamp, other.timestamp) && safeEq(this.id, other.id) }
    public toString(): string { return `TypedSocketMessage(type=${this.type}, data=${this.data}, timestamp=${this.timestamp}, id=${this.id})` }
    public copy(type: string = this.type, data: (T | null) = this.data, timestamp: Date = this.timestamp, id: (number | null) = this.id): TypedSocketMessage<T> { return new TypedSocketMessage(type, data, timestamp, id); }
}