// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/ContactsVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { debounceTime as rxDebounceTime, distinctUntilChanged as rxDistinctUntilChanged, switchMap as rxSwitchMap } from 'rxjs/operators'
import { xObservableAsObservablePropertyUnboxed } from 'butterfly-web/dist/observables/EventToObservableProperty.ext'
import { unreadUserObs } from '../../api/Session.read'
import { ComponentContactXml } from '../../layout/ComponentContactXml'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { ObservableInput, SubscriptionLike, concat as rxConcat, of as rxOf } from 'rxjs'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { ContactsXml } from '../../layout/ContactsXml'
import { R } from '../../R'
import { iterFirstOrNullFind, iterableFilterNotNull, listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyObservableGet, xObservablePropertyObservableNNGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { School } from '../../model/School'
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { AlertStatusEnum } from '../../model/AlertStatusEnum'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { StatusEnum } from '../../model/StatusEnum'
import { xObservablePropertyShare } from 'butterfly-web/dist/observables/SharingObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { Room } from '../../model/Room'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xObservablePropertyFlatMap, xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { SessionApi } from '../../api/SessionApi'
import { xActivityAccessCall } from '../../PhoneCall'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { CacheNode } from '../../api/CacheNode'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ChatThread } from '../../model/ChatThread'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { xIterableDistinctBy } from 'butterfly-web/dist/kotlin/Iterables'

//! Declares com.tresitgroup.android.tresit.vg.chat.ContactsVG
export class ContactsVG extends ViewGenerator {
    public readonly includeDMs: boolean;
    public readonly onSelected:  ((a: User, b: MutableObservableProperty<boolean>) => void);
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public constructor(includeDMs: boolean = true, onSelected:  ((a: User, b: MutableObservableProperty<boolean>) => void), schoolFilter: ObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.includeDMs = includeDMs;
        this.onSelected = onSelected;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.query = new StandardObservableProperty<string>("", undefined);
        this.openingUserConversation = new StandardObservableProperty<boolean>(false, undefined);
    }
    
    
    
    //--- Provides threadId
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.ContactsVG.title
    public get title(): string { return "Contacts"; }
    
    
    public readonly query: StandardObservableProperty<string>;
    
    public readonly openingUserConversation: StandardObservableProperty<boolean>;
    
    public getUsersObservable(): ObservableProperty<(Array<User> | null)> {
        const usualContacts = xObservablePropertyMap<(Array<User> | null), (Array<User> | null)>(xObservableAsObservablePropertyUnboxed<(Array<User> | null)>(rxConcat(rxOf([(this.schoolFilter.value?.id ?? null), this.query.value]), xObservablePropertyObservableNNGet(xObservablePropertyCombine<(School | null), string, [(number | null), string]>(this.schoolFilter, this.query, (a: (School | null), b: string): [(number | null), string] => [(a?.id ?? null), b])).pipe(rxDistinctUntilChanged()).pipe(rxDebounceTime(1000))).pipe(rxSwitchMap((schoolAndQuery: [(number | null), string]): ObservableInput<(Array<User> | null)> => {
                            const school = schoolAndQuery[0];
                            
                            const query = schoolAndQuery[1];
                            
                            const filters = ([] as Array<ApiFilter<User>>);
                            
                            
                            if (!xCharSequenceIsBlank(query)) {
                                filters.push(User.Companion.INSTANCE.textSearch(query));
                            }
                            if (school !== null) {
                                filters.push(User.Companion.INSTANCE.belongingToSchool(school!));
                            }
                            const it_4201 = this.session.me.value;
                            if (it_4201 !== null) {
                                filters.push(it_4201.chatUsers());
                            }
                            return xObservablePropertyObservableGet(this.session.users.observableListSimple(ApiFilter.Companion.INSTANCE.allList<User>(filters), undefined, 500, undefined, undefined, true));
            })), null), (it: (Array<User> | null)): (Array<User> | null) => ((): (Array<User> | null) => {
                const temp4203 = it;
                if(temp4203 === null) { return null }
                return temp4203.filter((it: User): boolean => !(it.id === this.session.session.userId))
        })());
        
        if (this.includeDMs) {
            const allDirectThreads = xObservablePropertyFlatMap<(School | null), (Array<ChatThread> | null)>(this.schoolFilter, (it: (School | null)): ObservableProperty<(Array<ChatThread> | null)> => ((): ObservableProperty<(Array<ChatThread> | null)> => {
                if (it !== null) { return new ConstantObservableProperty<(Array<ChatThread> | null)>(([] as Array<ChatThread>)) } else { return this.session.threads.observableListSimple(ChatThread.Companion.INSTANCE.isUserThread(), undefined, undefined, undefined, undefined, undefined) }
            })());
            
            const directThreadUsers = xObservablePropertyFlatMap<(Array<ChatThread> | null), Array<User>>(allDirectThreads, (it: (Array<ChatThread> | null)): ObservableProperty<Array<User>> => {
                const listToCombine = ((): (Array<CacheNode<User>> | null) => {
                    const temp4207 = it;
                    if(temp4207 === null) { return null }
                    return iterToArray(iterableFilterNotNull(iterMap(temp4207, (it: ChatThread): (CacheNode<User> | null) => ((): (CacheNode<User> | null) => {
                        const temp4209 = iterFirstOrNullFind(it.userIds, (it: number): boolean => !(it === this.session.session.userId));
                        if(temp4209 === null) { return null }
                        return ((notMe: number): CacheNode<User> => this.session.users.observable(notMe))(temp4209)
                    })())))
                })();
                
                return ((): (ObservableProperty<Array<User>> | null) => {
                    const temp4214 = ((): (ObservableProperty<Array<(User | null)>> | null) => {
                        if(listToCombine !== null) {
                            return xListCombined<(User | null)>(listToCombine)
                        } else { return null }
                    })();
                    if(temp4214 !== null) {
                        return xObservablePropertyMap<Array<(User | null)>, Array<User>>(temp4214, (it: Array<(User | null)>): Array<User> => listFilterNotNull(it))
                    } else { return null }
                })() ?? new ConstantObservableProperty<Array<User>>([]);
            });
            
            const filteredDirectThreadUsers = xObservablePropertyCombine<Array<User>, string, Array<User>>(directThreadUsers, this.query, (u: Array<User>, q: string): Array<User> => u.filter((it: User): boolean => (it.name.toLowerCase().indexOf(q.toLowerCase()) != -1)));
            
            return xObservablePropertyShare<(Array<User> | null)>(xObservablePropertyCombine<(Array<User> | null), Array<User>, (Array<User> | null)>(usualContacts, filteredDirectThreadUsers, (usual: (Array<User> | null), direct: Array<User>): (Array<User> | null) => ((): (Array<User> | null) => {
                const temp4219 = usual;
                if(temp4219 === null) { return null }
                return ((it: Array<User>): Array<User> => ((): Array<User> => {
                    const temp4222 = (it: User): (string | null) => it.name.toLowerCase();
                    return xIterableDistinctBy<User, number>((it.concat(direct)), (it: User): number => it.id).slice().sort((a, b) => safeCompare(temp4222(a), temp4222(b)))
                })())(temp4219)
            })()), undefined);
        } else {
            return xObservablePropertyMap<(Array<User> | null), (Array<User> | null)>(usualContacts, (it: (Array<User> | null)): (Array<User> | null) => ((): (Array<User> | null) => {
                const temp4225 = (it: User): (string | null) => it.name.toLowerCase();
                const temp4224 = it;
                if(temp4224 === null) { return null }
                return temp4224.slice().sort((a, b) => safeCompare(temp4225(a), temp4225(b)))
            })());
        }
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ContactsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const users = this.getUsersObservable();
        
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.query);
        
        //--- Set Up xml.loadingList
        xViewFlipperBindLoading(xml.loadingList, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<(Array<User> | null), boolean>(users, (it: (Array<User> | null)): boolean => it === null), this.openingUserConversation, (a: boolean, b: boolean): boolean => a || b), undefined);
        
        //--- Set Up xml.list
        xRecyclerViewBind<User>(xml.list, xObservablePropertyMap<(Array<User> | null), Array<User>>(users, (it: (Array<User> | null)): Array<User> => it ?? []), new User(undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<User>): HTMLElement => {
            //--- Make Subview For xml.list (overwritten on flow generation)
            const cellXml = new ComponentContactXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Conveniences
            const belongsToSchoolWithAlert: ObservableProperty<boolean> = xObservablePropertyMap<Array<School>, boolean>(xObservablePropertyMap<(Array<School> | null), Array<School>>(this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => ((): (Array<School> | null) => {
                const temp4227 = ((): (Array<School> | null) => {
                    const temp4229 = it;
                    if(temp4229 === null) { return null }
                    const temp4230 = (it: School): (string | null) => it.name;
                    return temp4229.slice().sort((a, b) => safeCompare(temp4230(a), temp4230(b)))
                })();
                if(temp4227 === null) { return null }
                return temp4227.filter((it: School): boolean => safeEq(it.status, AlertStatusEnum.Active))
            })() ?? []), (it: Array<School>): boolean => it.length === 0);
            
            
            //--- Set Up cellXml.convRoot (overwritten on flow generation)
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<User, string>(observable, (it: User): string => it.name));
            
            //--- Set Up cellXml.unreadIndicator
            xViewBindExists(cellXml.unreadIndicator, unreadUserObs(this.session, xObservablePropertyMap<User, number>(observable, (it: User): number => it.id)));
            
            //--- Set Up cellXml.userStatus
            const userLocation: ObservableProperty<(Room | null)> = xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyMap<User, (number | null)>(observable, (it: User): (number | null) => it.currentLocation), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it));
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(userLocation, undefined, undefined, (room: (Room | null)): void => {
                switch((room?.safe ?? null)) {
                    case StatusEnum.Safe:
                    case StatusEnum.Cleared:
                    imageViewSetImageResource(cellXml.userStatus, R.drawable.ic_chat_green)
                    break;
                    case StatusEnum.Unsafe:
                    imageViewSetImageResource(cellXml.userStatus, R.drawable.ic_chat_red)
                    break;
                    default:
                    imageViewSetImageResource(cellXml.userStatus, R.drawable.ic_chat_white)
                    break;
                }
                
            }), xViewRemovedGet(cellXml.userStatus));
            xViewOnClick(cellXml.userStatus, undefined, (): void => {
                const it_4237 = observable.value.cellPhone;
                if (it_4237 !== null) {
                    xActivityAccessCall(dependency, `+1${it_4237}`);
                }
            });
            
            //--- Click
            cellView.onclick = (_ev) => { _ev.stopPropagation();
                const it = _ev.target as HTMLElement;
                this.onSelected(observable.value, this.openingUserConversation);
            };
            
            //--- End Make Subview For xml.list
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action cellXmlXmlRootClick
    //--- Action listClick
    
    //--- Body End
}
