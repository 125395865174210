//
// ComponentRowSchoolTypeXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_school_type.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowSchoolTypeXml
export class ComponentRowSchoolTypeXml {
    xmlRoot!: HTMLElement
    icon!: HTMLImageElement
    title!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.icon = getViewById<HTMLImageElement>(view, "icon")
        this.title = getViewById<HTMLElement>(view, "title")
        return view
    }
}
