import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { setViewVisibility } from 'butterfly-web/dist/views/View.ext'
import { SubscriptionLike } from 'rxjs'

const style = document.createElement("style")
style.textContent = ".horizontallyCollapsed { width: 0px !important }"
document.head.appendChild(style)

//! Declares com.tresitgroup.android.tresit.util.bindCollapseHorizontal
export function xViewBindCollapseHorizontal(this_: HTMLElement, show: ObservableProperty<boolean>) {
    this_.style.transition = "width 0.5s"
    xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(show, undefined, undefined, (value: boolean): void => {
        if(value){
            this_.classList.remove("horizontallyCollapsed")
        } else {
            this_.classList.add("horizontallyCollapsed")
        }
    }), xViewRemovedGet(this_));
}