// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/DefaultLocationsListVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { DefaultLocationSettingsVG } from './DefaultLocationSettingsVG'
import { DefaultLocationsListXml } from '../../layout/DefaultLocationsListXml'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { ComponentRowSchoolNameButtonXml } from '../../layout/ComponentRowSchoolNameButtonXml'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.settings.DefaultLocationsListVG
export class DefaultLocationsListVG extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(session: SessionApi, stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>) {
        super();
        this.session = session;
        this.stack = stack;
        this.dialog = dialog;
    }
    
    
    //--- Provides schoolId
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.DefaultLocationsListVG.title
    public get title(): string { return "Default Locations Settings"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new DefaultLocationsListXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.fetchingSchools
        //        xml.fetchingSchools.bindLoading(schools.map { it == null })
        
        //--- Set Up xml.schoolList
        xRecyclerViewBind<School>(xml.schoolList, xObservablePropertyMap<(Array<School> | null), Array<School>>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => it ?? []), new School(undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<School>): HTMLElement => {
            //--- Make Subview For xml.schoolList (overwritten on flow generation)
            const cellXml = new ComponentRowSchoolNameButtonXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root
            
            //--- Set Up cellXml.schoolName
            xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<School, string>(observable, (it: School): string => it.name));
            xViewOnClick(cellXml.schoolName, undefined, (): void => {
                this.cellXmlRootClick(observable.value.id);
            });
            
            //--- End Make Subview For xml.schoolList (overwritten on flow generation)
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cellXmlRootClick(schoolId: ForeignKey<School>): void {
        this.stack.push(new DefaultLocationSettingsVG(schoolId, this.session, this.stack, this.stack));
    }
    
    
    //--- Action schoolListClick
    
    
    //--- Body End
}
