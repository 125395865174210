// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/SelectSchoolVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { Alert } from '../model/Alert'
import { SessionApi } from '../api/SessionApi'
import { AlertClassificationEnum } from '../model/AlertClassificationEnum'
import { CacheEdge } from '../api/CacheEdge'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { getNO_ID } from '../model/HasId'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { logVG } from '../util/LogVG'
import { School } from '../model/School'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { SelectSchoolXml } from '../layout/SelectSchoolXml'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { xVerticalRecyclerViewBindSchoolSelect } from './schoolsList'

//! Declares com.tresitgroup.android.tresit.vg.SelectSchoolVG
export class SelectSchoolVG extends ViewGenerator {
    public readonly onSelected:  ((a: (School | null)) => void);
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(onSelected:  ((a: (School | null)) => void), session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.onSelected = onSelected;
        this.session = session;
        this.stack = stack;
        this.filter = new StandardObservableProperty<string>("", undefined);
        this.allAlerts = this.session.alerts.observableListSimple(undefined, Alert.Companion.INSTANCE.createdSort, undefined, undefined, undefined, undefined);
        this.schools = xObservablePropertyMap<Array<School>, Array<(School | null)>>(xObservablePropertyMap<(Array<School> | null), Array<School>>(this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => ((): (Array<School> | null) => {
                        const temp3114 = it;
                        if(temp3114 === null) { return null }
                        const temp3115 = (it: School): (string | null) => it.name;
                        return temp3114.slice().sort((a, b) => safeCompare(temp3115(a), temp3115(b)))
            })() ?? []), (it: Array<School>): Array<(School | null)> => {
                const all = ([] as Array<(School | null)>);
                
                all.push(null);
                all.push(...it);
                return all as Array<(School | null)>;
        });
    }
    
    
    //--- Provides schoolId
    
    //! Declares com.tresitgroup.android.tresit.vg.SelectSchoolVG.title
    public get title(): string { return "Select School"; }
    
    
    public readonly filter: StandardObservableProperty<string>;
    
    public readonly allAlerts: CacheEdge<Alert>;
    
    public readonly schools: ObservableProperty<Array<(School | null)>>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new SelectSchoolXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        
        //--- Shortcuts
        
        //--- Set Up xml.title (overwritten on flow generation)
        
        //--- Set Up xml.completeTask (overwritten on flow generation)
        xViewOnClick(xml.completeTask, undefined, (): void => {
            this.completeTaskClick();
        });
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.filter);
        
        //--- Set Up xml.schools
        
        //--- Make Subview For xml.schools
        //--- Set Up cellXml.unreadIndicator
        //--- Set Up cellXml.schoolName
        //--- End Make Subview For xml.schools
        const alertClassification = xObservablePropertyMap<(Array<Alert> | null), AlertClassificationEnum>(this.allAlerts, (alerts: (Array<Alert> | null)): AlertClassificationEnum => ((): AlertClassificationEnum => {
            if (alerts !== null && alerts!.some((it: Alert): boolean => (!it.drill))) {
                return AlertClassificationEnum.Alert
            } else if (alerts !== null && alerts!.length !== 0) {
                return AlertClassificationEnum.Drill
            } else  {
                return AlertClassificationEnum.None
            }
        })());
        
        xVerticalRecyclerViewBindSchoolSelect(xml.schools, dependency, this.session, this.filter, new ConstantObservableProperty<(School | null)>(new School(getNO_ID(), "-", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), alertClassification, (it: (School | null)): void => {
            this.onSelected(it);
            this.stack.dismiss();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public completeTaskClick(): void {
        this.stack.dismiss();
    }
    public cellXmlXmlRootClick(): void {
        this.stack.dismiss();
    }
    public schoolsClick(school: (School | null)): void {
        this.onSelected(school);
        this.stack.dismiss();
    }
    
    //--- Body End
}
