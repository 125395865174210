// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/LiveApi.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { flatMap as rxFlatMap, map as rxMap, publishReplay as rxPublishReplay, switchMap as rxSwitchMap, tap as rxTap } from 'rxjs/operators'
import { HttpBody, multipartFormBody, multipartFormFilePart, xAnyToJsonHttpBody, xImageToHttpBody, xUriToHttpBody } from 'butterfly-web/dist/net/HttpBody'
import { CampusGeoShape } from '../map/Models'
import { User } from '../model/User'
import { IllegalStateException, doOnSubscribe, hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { getTotalRequestsMade, setTotalRequestsMade } from './apiProperty'
import { Device } from '../model/Device'
import { AlertHistory } from '../model/AlertHistory'
import { Image } from 'butterfly-web/dist/Image'
import { SessionApi } from './SessionApi'
import { Group } from '../model/Group'
import { ChatThreadDetailed } from '../model/ChatThreadDetailed'
import { Observable, ObservableInput, SubscriptionLike, interval as rxInterval, zip as rxZip } from 'rxjs'
import { HttpProgress } from 'butterfly-web/dist/net/HttpModels'
import { AlertHistoryDetailed } from '../model/AlertHistoryDetailed'
import { ChatAttachment } from '../model/ChatAttachment'
import { PermissionsResponse } from '../model/PermissionsResponse'
import { AdminRequestHistory } from '../model/AdminRequestHistory'
import { AlertHistoryIdResponse } from '../model/AlertHistoryIdResponse'
import { UserSchoolMembership } from '../model/UserSchoolMembership'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'
import { UserDetailed } from '../model/UserDetailed'
import { SocketMessage } from '../model/SocketMessage'
import { Alert } from '../model/Alert'
import { xResponseReadJson, xResponseReadJsonDebug, xSingleDiscard, xSingleReadJsonDebug } from 'butterfly-web/dist/net/RxHttpAssist'
import { ConnectedWebSocket } from 'butterfly-web/dist/net/ConnectedWebSocket'
import { UnreadThread } from '../model/UnreadThread'
import { LoginActivity } from '../model/LoginActivity'
import { doOnDispose as rxDoOnDispose } from 'butterfly-web/dist/rx/RxActual'
import { xStringFromJsonString } from 'butterfly-web/dist/Codable'
import { OffsiteResponse } from '../model/OffsiteResponse'
import { District } from '../model/District'
import { LiveApiTable } from './LiveApiTable'
import { autoconnect as rxAutoconnect } from 'butterfly-web/dist/rx/autoconnect'
import { RegisteredDevice } from '../model/RegisteredDevice'
import { Floor } from '../model/Floor'
import { AdminApprovalListResponse } from '../model/AdminApprovalListResponse'
import { ForeignKey } from '../model/ForeignKey'
import { AlertType } from '../model/AlertType'
import { BoardPost } from '../model/BoardPost'
import { Room } from '../model/Room'
import { Session } from '../model/Session'
import { StartLoginResponse } from '../model/StartLoginResponse'
import { Page } from '../model/Page'
import { AlertGuidance } from '../model/AlertGuidance'
import { ChatMessageDetailed } from '../model/ChatMessageDetailed'
import { ChatMessage } from '../model/ChatMessage'
import { Building } from '../model/Building'
import { WebSocketFrame } from 'butterfly-web/dist/net/WebSocketFrame'
import { FirebaseMessagingToken } from '../model/FirebaseMessagingToken'
import { Api } from './Api'
import { ApiTable } from './ApiTable'
import { ApiPartialQuery } from './ApiPartialQuery'
import { xObservableMapNotNull } from 'butterfly-web/dist/rx/RxExtensions'
import { Platform } from 'butterfly-web/dist/Platform'
import { Stats } from '../model/Stats'
import { School } from '../model/School'
import { ChatThread } from '../model/ChatThread'
import { printStackTrace } from 'butterfly-web/dist/kotlin/Language'
import { CheckAlertLocationResponse } from '../model/CheckAlertLocationResponse'

//! Declares com.tresitgroup.android.tresit.api.LiveApi
export class LiveApi implements Api {
    public static implementsInterfaceComTresitgroupAndroidTresitApiApi = true;
    public readonly httpUrl: string;
    public readonly websocketUrl: string;
    public constructor(httpUrl: string, websocketUrl: string) {
        this.httpUrl = httpUrl;
        this.websocketUrl = websocketUrl;
    }
    
    
    public getSocket(session: Session, sessionApi: SessionApi): Observable<Observable<SocketMessage>> {
        let disp: (SubscriptionLike | null) = null;
        
        let pingsWithoutPong = 0;
        
        
        return HttpClient.INSTANCE.webSocket(this.websocketUrl + session.token).pipe(rxTap((sock: ConnectedWebSocket): void => {
            pingsWithoutPong = 0;
            const temp529 = disp;
            if(temp529 !== null) {
                temp529.unsubscribe()
            };
            disp = rxInterval(5000).subscribe((it: number): void => {
                if (pingsWithoutPong > 2) {
                    const temp533 = disp;
                    if(temp533 !== null) {
                        temp533.unsubscribe()
                    };
                    const e = new IllegalStateException("Websocket seems to be disconnected - missing pong responses.", undefined);
                    
                    printStackTrace(e);
                    sock.error(e);
                } else {
                    console.log(`${"LiveApi"}: ${"Websocket ping"}`);
                    sock.next(new WebSocketFrame(undefined, "{}"));
                    pingsWithoutPong = pingsWithoutPong + 1;
                }
            }, undefined, undefined);
        })).pipe(rxDoOnDispose((): void => {
            const temp539 = disp;
            if(temp539 !== null) {
                temp539.unsubscribe()
            };
        })).pipe(rxTap(undefined, (it: any): void => {
            const temp540 = disp;
            if(temp540 !== null) {
                temp540.unsubscribe()
            };
            console.error(`${"LiveApi"}: ${`WEBSOCKET ERROR: ${it}`}`);
        })).pipe(rxMap((it: ConnectedWebSocket): Observable<SocketMessage> => xObservableMapNotNull<WebSocketFrame, SocketMessage>(it.read, (it: WebSocketFrame): (SocketMessage | null) => {
                        if (((): (boolean | null) => {
                                    const temp544 = it.text;
                                    if(temp544 === null) { return null }
                                    return temp544.startsWith("{")
                            })() === false) {
                                console.log(`${"LiveApi"}: ${"Websocket pong"}`);
                                pingsWithoutPong = 0;
                                return null;
                            } else {
                                const message = ((): (SocketMessage | null) => {
                                    const temp547 = it.text;
                                    if(temp547 !== null) {
                                        return xStringFromJsonString<SocketMessage>(temp547, [SocketMessage])
                                    } else { return null }
                                })();
                                
                                if ((message?.type ?? null) === "error") {
                                    console.log(`${"LiveApi"}: ${"Websocket pong"}`);
                                    pingsWithoutPong = 0;
                                    return null;
                                }
                                return message;
                            }
            })));
        }
        
        public checkIsOkBuild(version: string): Observable<boolean> {
            return doOnSubscribe(xSingleReadJsonDebug<boolean>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/valid-build/${version}/`, undefined, undefined, undefined, undefined), [Boolean]), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        
        
        
        public startLogin(email: string, fName: (string | null), lName: (string | null), phone: (string | null)): Observable<StartLoginResponse> {
            return doOnSubscribe(xSingleReadJsonDebug<LiveApi.StartLoginResponseActual>(HttpClient.INSTANCE.call(this.httpUrl + `rest/login-location/?mobile=${!safeEq(Platform.Companion.INSTANCE.current, Platform.Web) ? "true" : "false"}`, HttpClient.INSTANCE.POST, new Map([]), xAnyToJsonHttpBody(new Map([["email", email], ["cell_phone", phone], ["first_name", fName], ["last_name", lName]])), undefined), [LiveApi.StartLoginResponseActual]).pipe(rxMap((it: LiveApi.StartLoginResponseActual): StartLoginResponse => ((): StartLoginResponse => {
                                if (it.sso) {
                                    return new StartLoginResponse(it.loginUrl, it.isLocationLogin, it.hasTempDetails);
                                } else {
                                    return new StartLoginResponse(null, it.isLocationLogin, it.hasTempDetails);
                                }
                })())), (it: SubscriptionLike): void => {
                    setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public cleverUrl(): Observable<StartLoginResponse> {
            return doOnSubscribe(xSingleReadJsonDebug<StartLoginResponse>(HttpClient.INSTANCE.call(this.httpUrl + `rest/login-clever/?mobile=${!safeEq(Platform.Companion.INSTANCE.current, Platform.Web) ? "true" : "false"}`, HttpClient.INSTANCE.GET, new Map([]), undefined, undefined), [StartLoginResponse]), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        
        
        public passwordLogin(email: string, password: string, fName: (string | null), lName: (string | null), phone: (string | null)): Observable<Session> {
            return doOnSubscribe(xSingleReadJsonDebug<LiveApi.LoginResponse>(HttpClient.INSTANCE.call(this.httpUrl + "api/v1/auth/", HttpClient.INSTANCE.POST, new Map([]), xAnyToJsonHttpBody(new Map([["email", email], ["password", password], ["cell_phone", fName], ["first_name", lName], ["last_name", phone]])), undefined), [LiveApi.LoginResponse]).pipe(rxMap((it: LiveApi.LoginResponse): Session => new Session(it.jwt, it.id))), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        
        public forgotPassword(email: string): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + "rest/forgot-password/", HttpClient.INSTANCE.POST, new Map([]), xAnyToJsonHttpBody(new Map([["email", email]])), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public changePassword(session: Session, password: string): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + `rest/users/${session.userId}/`, HttpClient.INSTANCE.PATCH, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map([["password", password]])), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        
        
        public refreshSession(token: string): Observable<Session> {
            return doOnSubscribe(xSingleReadJsonDebug<LiveApi.JustAnId>(HttpClient.INSTANCE.call(this.httpUrl + "rest/users/me/?slim=1", HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${token}`]]), undefined, undefined), [LiveApi.JustAnId]).pipe(rxMap((it: LiveApi.JustAnId): Session => new Session(token, it.id))), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public postFirebaseToken(session: Session, token: FirebaseMessagingToken): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + "rest/notifications/fcm/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(token), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public requestAdminAlert(session: Session, schoolId: number, name: number, created: string, initiator: number, policeStatus: string, scoped: boolean, silent: boolean, internal: boolean, description: string): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + "api/v2/requires-admin-approval-alert/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map<string, any>([["school", schoolId], ["name", name], ["initiator", initiator], ["description", description], ["silent", silent], ["internal", internal]])), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1);
                console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public createOffsite(session: Session, id: string): Observable<OffsiteResponse> {
            return doOnSubscribe(xSingleReadJsonDebug<OffsiteResponse>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/schools/${id}/offsite/create/`, HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map([["", ""]])), undefined), [OffsiteResponse]), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        
        public getAlertId(session: Session, id: string): Observable<AlertHistoryIdResponse> {
            return doOnSubscribe(xSingleReadJsonDebug<AlertHistoryIdResponse>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/alert-history/alert/?alert_history_id=${id}`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [AlertHistoryIdResponse]), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public getThreadId(session: Session, id: string): Observable<Array<ChatThread>> {
            return xSingleReadJsonDebug<Page<ChatThread>>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/thread/?alert_id=${id}`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [ChatThread]]).pipe(rxMap((it: Page<ChatThread>): Array<ChatThread> => it.results));
        }
        
        
        public verifyToken(session: Session, token: string): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + "rest/verify-jwt-token/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map([["token", token]])), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public clearFirebaseToken(session: Session, token: FirebaseMessagingToken): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + "rest/notifications/fcm/deregister/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(token), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public cancelAlertWithReason(session: Session, id: ForeignKey<Alert>, reason: string): Observable<void> {
            return doOnSubscribe(xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/alert/${id}/`, HttpClient.INSTANCE.DELETE, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map([["cancel_reason", reason]])), undefined)), (it: SubscriptionLike): void => {
                setTotalRequestsMade(getTotalRequestsMade() + 1); console.debug(`${"LiveApi"}: ${`Request count: ${getTotalRequestsMade()}`}`);
            });
        }
        
        public uploadAlertIcon(session: Session, id: ForeignKey<AlertType>, image: Image): Observable<AlertType> {
            return xImageToHttpBody(image, 500, undefined).pipe(rxFlatMap((image: HttpBody): ObservableInput<Response> => HttpClient.INSTANCE.call(this.httpUrl + `api/v2/alert-type/${id}/`, HttpClient.INSTANCE.PATCH, new Map([["Authorization", `JWT ${session.token}`]]), multipartFormBody(multipartFormFilePart("icon", "icon.jpg", image)), undefined))).pipe(rxFlatMap((it: Response): ObservableInput<AlertType> => xResponseReadJson<AlertType>(it, [AlertType])));
        }
        
        public uploadAlertMp3(session: Session, id: ForeignKey<AlertType>, uri: File): Observable<AlertType> {
            return xUriToHttpBody(uri).pipe(rxFlatMap((file: HttpBody): ObservableInput<Response> => HttpClient.INSTANCE.call(this.httpUrl + `api/v2/alert-type/${id}/`, HttpClient.INSTANCE.PATCH, new Map([["Authorization", `JWT ${session.token}`]]), multipartFormBody(multipartFormFilePart("mp3_file", "alert-sound.mp3", file)), undefined))).pipe(rxFlatMap((it: Response): ObservableInput<AlertType> => xResponseReadJson<AlertType>(it, [AlertType])));
        }
        
        public uploadAlertOgg(session: Session, id: ForeignKey<AlertType>, uri: File): Observable<AlertType> {
            return xUriToHttpBody(uri).pipe(rxFlatMap((file: HttpBody): ObservableInput<Response> => HttpClient.INSTANCE.call(this.httpUrl + `api/v2/alert-type/${id}/`, HttpClient.INSTANCE.PATCH, new Map([["Authorization", `JWT ${session.token}`]]), multipartFormBody(multipartFormFilePart("ogg_file", "alert-sound.ogg", file)), undefined))).pipe(rxFlatMap((it: Response): ObservableInput<AlertType> => xResponseReadJson<AlertType>(it, [AlertType])));
        }
        
        public uploadAttachment(session: Session, uri: File, fileName: string, type: string): Observable<HttpProgress<ChatAttachment>> {
            return xUriToHttpBody(uri).pipe(rxSwitchMap((file: HttpBody): ObservableInput<HttpProgress<ChatAttachment>> => HttpClient.INSTANCE.callWithProgress<ChatAttachment>(this.httpUrl + "api/v2/message-attachments/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), multipartFormBody(multipartFormFilePart(((): string => {
                if (type.startsWith("image/")) {
                    return "image"
                } else if (type.startsWith("video/")) {
                    return "video"
                } else  {
                    return "doc"
                }
            })(), fileName, file)), undefined, (it: Response): Observable<ChatAttachment> => xResponseReadJsonDebug<ChatAttachment>(it, [ChatAttachment])))).pipe(rxPublishReplay(1)).pipe(rxAutoconnect());
        }
        
        public bindAttachments(session: Session, message: ForeignKey<ChatMessage>, attachments: Array<ForeignKey<ChatAttachment>>): Observable<void> {
            return rxZip(...attachments.map((it: number): Observable<void> => xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/message-attachments/${it}/`, HttpClient.INSTANCE.PATCH, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map([["message", message]])), undefined)))).pipe(rxMap((it: Array<void>): void => {
                undefined;
            }));
        }
        
        public updateGoogleMap(session: Session, site: ForeignKey<School>, campus: CampusGeoShape): Observable<void> {
            return xSingleDiscard(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/update-site-map-json/${site}/`, HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(campus), undefined));
        }
        
        public schools(session: Session): ApiTable<School> {
            return new LiveApiTable<School>(this.httpUrl, "school", (it: Observable<Response>): Observable<School> => xSingleReadJsonDebug<School>(it, [School]), (it: Observable<Response>): Observable<Page<School>> => xSingleReadJsonDebug<Page<School>>(it, [Page, [School]]), session);
        }
        
        public districts(session: Session): ApiTable<District> {
            return new LiveApiTable<District>(this.httpUrl, "district", (it: Observable<Response>): Observable<District> => xSingleReadJsonDebug<District>(it, [District]), (it: Observable<Response>): Observable<Page<District>> => xSingleReadJsonDebug<Page<District>>(it, [Page, [District]]), session);
        }
        
        public posts(session: Session): ApiTable<BoardPost> {
            return new LiveApiTable<BoardPost>(this.httpUrl, "post", (it: Observable<Response>): Observable<BoardPost> => xSingleReadJsonDebug<BoardPost>(it, [BoardPost]), (it: Observable<Response>): Observable<Page<BoardPost>> => xSingleReadJsonDebug<Page<BoardPost>>(it, [Page, [BoardPost]]), session);
        }
        
        public alerts(session: Session): ApiTable<Alert> {
            return new LiveApiTable<Alert>(this.httpUrl, "alert", (it: Observable<Response>): Observable<Alert> => xSingleReadJsonDebug<Alert>(it, [Alert]), (it: Observable<Response>): Observable<Page<Alert>> => xSingleReadJsonDebug<Page<Alert>>(it, [Page, [Alert]]), session);
        }
        
        public adminApproval(session: Session, schoolId: string): Observable<Array<AdminApprovalListResponse>> {
            return xSingleReadJsonDebug<Page<AdminApprovalListResponse>>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/requires-admin-approval-alert/?school=${schoolId}`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [AdminApprovalListResponse]]).pipe(rxMap((it: Page<AdminApprovalListResponse>): Array<AdminApprovalListResponse> => it.results));
        }
        
        public submitAdminApproval(session: Session, status: string, alertId: number): Observable<void> {
            return HttpClient.INSTANCE.call(this.httpUrl + `api/v2/requires-admin-approval-alert/${alertId}/`, HttpClient.INSTANCE.PATCH, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map<string, string>([["pending_approval_status", status]])), undefined).pipe(rxMap((it: Response): void => {
                undefined;
            }));
        }
        
        public alertTypes(session: Session): ApiTable<AlertType> {
            return new LiveApiTable<AlertType>(this.httpUrl, "alert-type", (it: Observable<Response>): Observable<AlertType> => xSingleReadJsonDebug<AlertType>(it, [AlertType]), (it: Observable<Response>): Observable<Page<AlertType>> => xSingleReadJsonDebug<Page<AlertType>>(it, [Page, [AlertType]]), session);
        }
        
        public alertGuidances(session: Session): ApiTable<AlertGuidance> {
            return new LiveApiTable<AlertGuidance>(this.httpUrl, "alert-guidance", (it: Observable<Response>): Observable<AlertGuidance> => xSingleReadJsonDebug<AlertGuidance>(it, [AlertGuidance]), (it: Observable<Response>): Observable<Page<AlertGuidance>> => xSingleReadJsonDebug<Page<AlertGuidance>>(it, [Page, [AlertGuidance]]), session);
        }
        
        public alertHistory(session: Session): ApiTable<AlertHistory> {
            return new LiveApiTable<AlertHistory>(this.httpUrl, "alert-history", (it: Observable<Response>): Observable<AlertHistory> => xSingleReadJsonDebug<AlertHistory>(it, [AlertHistory]), (it: Observable<Response>): Observable<Page<AlertHistory>> => xSingleReadJsonDebug<Page<AlertHistory>>(it, [Page, [AlertHistory]]), session);
        }
        
        public threads(session: Session): ApiTable<ChatThread> {
            return new LiveApiTable<ChatThread>(this.httpUrl, "thread", (it: Observable<Response>): Observable<ChatThread> => xSingleReadJsonDebug<ChatThread>(it, [ChatThread]), (it: Observable<Response>): Observable<Page<ChatThread>> => xSingleReadJsonDebug<Page<ChatThread>>(it, [Page, [ChatThread]]), session);
        }
        
        public messages(session: Session): ApiTable<ChatMessage> {
            return new LiveApiTable<ChatMessage>(this.httpUrl, "message", (it: Observable<Response>): Observable<ChatMessage> => xSingleReadJsonDebug<ChatMessage>(it, [ChatMessage]), (it: Observable<Response>): Observable<Page<ChatMessage>> => xSingleReadJsonDebug<Page<ChatMessage>>(it, [Page, [ChatMessage]]), session);
        }
        
        public buildings(session: Session): ApiTable<Building> {
            return new LiveApiTable<Building>(this.httpUrl, "building", (it: Observable<Response>): Observable<Building> => xSingleReadJsonDebug<Building>(it, [Building]), (it: Observable<Response>): Observable<Page<Building>> => xSingleReadJsonDebug<Page<Building>>(it, [Page, [Building]]), session);
        }
        
        public floors(session: Session): ApiTable<Floor> {
            return new LiveApiTable<Floor>(this.httpUrl, "floor", (it: Observable<Response>): Observable<Floor> => xSingleReadJsonDebug<Floor>(it, [Floor]), (it: Observable<Response>): Observable<Page<Floor>> => xSingleReadJsonDebug<Page<Floor>>(it, [Page, [Floor]]), session);
        }
        
        public rooms(session: Session): ApiTable<Room> {
            return new LiveApiTable<Room>(this.httpUrl, "room", (it: Observable<Response>): Observable<Room> => xSingleReadJsonDebug<Room>(it, [Room]), (it: Observable<Response>): Observable<Page<Room>> => xSingleReadJsonDebug<Page<Room>>(it, [Page, [Room]]), session);
        }
        
        public users(session: Session): ApiTable<User> {
            return new LiveApiTable<User>(this.httpUrl, "user", (it: Observable<Response>): Observable<User> => xSingleReadJsonDebug<User>(it, [User]), (it: Observable<Response>): Observable<Page<User>> => xSingleReadJsonDebug<Page<User>>(it, [Page, [User]]), session);
        }
        
        public userSchoolMemberships(session: Session): ApiTable<UserSchoolMembership> {
            return new LiveApiTable<UserSchoolMembership>(this.httpUrl, "user-school-membership", (it: Observable<Response>): Observable<UserSchoolMembership> => xSingleReadJsonDebug<UserSchoolMembership>(it, [UserSchoolMembership]), (it: Observable<Response>): Observable<Page<UserSchoolMembership>> => xSingleReadJsonDebug<Page<UserSchoolMembership>>(it, [Page, [UserSchoolMembership]]), session);
        }
        
        public permissionGroups(session: Session): ApiTable<Group> {
            return new LiveApiTable<Group>(this.httpUrl, "group", (it: Observable<Response>): Observable<Group> => xSingleReadJsonDebug<Group>(it, [Group]), (it: Observable<Response>): Observable<Page<Group>> => xSingleReadJsonDebug<Page<Group>>(it, [Page, [Group]]), session);
        }
        
        public stats(session: Session): Observable<Stats> {
            return xSingleReadJsonDebug<Stats>(HttpClient.INSTANCE.call(this.httpUrl + "api/v2/stats/", HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Stats]);
        }
        
        public loginActivity(session: Session): ApiTable<LoginActivity> {
            return new LiveApiTable<LoginActivity>(this.httpUrl, "login-activity", (it: Observable<Response>): Observable<LoginActivity> => xSingleReadJsonDebug<LoginActivity>(it, [LoginActivity]), (it: Observable<Response>): Observable<Page<LoginActivity>> => xSingleReadJsonDebug<Page<LoginActivity>>(it, [Page, [LoginActivity]]), session);
        }
        
        public firebaseMessagingToken(session: Session): ApiTable<FirebaseMessagingToken> {
            return new LiveApiTable<FirebaseMessagingToken>(this.httpUrl, "fcm-token", (it: Observable<Response>): Observable<FirebaseMessagingToken> => xSingleReadJsonDebug<FirebaseMessagingToken>(it, [FirebaseMessagingToken]), (it: Observable<Response>): Observable<Page<FirebaseMessagingToken>> => xSingleReadJsonDebug<Page<FirebaseMessagingToken>>(it, [Page, [FirebaseMessagingToken]]), session);
        }
        
        public devices(session: Session): ApiTable<Device> {
            return new LiveApiTable<Device>(this.httpUrl, "device", (it: Observable<Response>): Observable<Device> => xSingleReadJsonDebug<Device>(it, [Device]), (it: Observable<Response>): Observable<Page<Device>> => xSingleReadJsonDebug<Page<Device>>(it, [Page, [Device]]), session);
        }
        
        public unreadThreads(session: Session): ApiTable<UnreadThread> {
            return new LiveApiTable<UnreadThread>(this.httpUrl, "unread-thread", (it: Observable<Response>): Observable<UnreadThread> => xSingleReadJsonDebug<UnreadThread>(it, [UnreadThread]), (it: Observable<Response>): Observable<Page<UnreadThread>> => xSingleReadJsonDebug<Page<UnreadThread>>(it, [Page, [UnreadThread]]), session);
        }
        
        public postLog(session: Session, details: string): Observable<void> {
            return xSingleReadJsonDebug<Page<UserDetailed>>(HttpClient.INSTANCE.call(this.httpUrl + "api/v2/platform-log/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(new Map<string, any>([["user", session.userId], ["details", details]])), undefined), [Page, [UserDetailed]]).pipe(rxMap((it: Page<UserDetailed>): void => {
                undefined;
            }));
        }
        
        public usersDetailed(session: Session, query: ApiPartialQuery<UserDetailed>): Observable<Array<UserDetailed>> {
            return xSingleReadJsonDebug<Page<UserDetailed>>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/user-detailed-refactor/${query.queryString()}`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [UserDetailed]]).pipe(rxMap((it: Page<UserDetailed>): Array<UserDetailed> => it.results));
        }
        
        public getAdminAlertHistory(session: Session, query: string): Observable<Array<AdminRequestHistory>> {
            return xSingleReadJsonDebug<Page<AdminRequestHistory>>(HttpClient.INSTANCE.call(this.httpUrl + "api/v2/requires-admin-approval-alert-history/?" + query, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [AdminRequestHistory]]).pipe(rxMap((it: Page<AdminRequestHistory>): Array<AdminRequestHistory> => it.results));
        }
        
        
        public alertHistoryDetailed(session: Session, query: ApiPartialQuery<AlertHistoryDetailed>): Observable<Array<AlertHistoryDetailed>> {
            return xSingleReadJsonDebug<Page<AlertHistoryDetailed>>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/alert-history-detailed/${query.queryString()}&is_origin=true`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [AlertHistoryDetailed]]).pipe(rxMap((it: Page<AlertHistoryDetailed>): Array<AlertHistoryDetailed> => it.results));
        }
        
        public messagesDetailed(session: Session, query: ApiPartialQuery<ChatMessageDetailed>): Observable<Array<ChatMessageDetailed>> {
            return xSingleReadJsonDebug<Page<ChatMessageDetailed>>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/message-detailed/${query.queryString()}`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [ChatMessageDetailed]]).pipe(rxMap((it: Page<ChatMessageDetailed>): Array<ChatMessageDetailed> => it.results));
        }
        
        public threadsDetailed(session: Session, query: ApiPartialQuery<ChatThreadDetailed>): Observable<Array<ChatThreadDetailed>> {
            return xSingleReadJsonDebug<Page<ChatThreadDetailed>>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/thread-detailed/${query.queryString()}`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [Page, [ChatThreadDetailed]]).pipe(rxMap((it: Page<ChatThreadDetailed>): Array<ChatThreadDetailed> => it.results));
        }
        
        
        public submitContactEmail(email: string): Observable<void> {
            return HttpClient.INSTANCE.call(this.httpUrl + "api/v2/submit-contact-email/", HttpClient.INSTANCE.POST, undefined, xAnyToJsonHttpBody(new Map<string, string>([["email", email]])), undefined).pipe(rxMap((it: Response): void => {
                undefined;
            }));
        }
        
        public getRegisteredDevice(session: Session, userId: number): Observable<RegisteredDevice> {
            return xSingleReadJsonDebug<RegisteredDevice>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/registered-fcm-device-types-check/${userId}/`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [RegisteredDevice]).pipe(rxMap((it: RegisteredDevice): RegisteredDevice => it));
        }
        
        
        public checkAlertLocation(session: Session): Observable<CheckAlertLocationResponse> {
            return xSingleReadJsonDebug<CheckAlertLocationResponse>(HttpClient.INSTANCE.call(this.httpUrl + "api/v2/has-alert-location/", HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [CheckAlertLocationResponse]).pipe(rxMap((it: CheckAlertLocationResponse): CheckAlertLocationResponse => it));
        }
        
        public getPermissions(session: Session, userId: ForeignKey<User>): Observable<PermissionsResponse> {
            return xSingleReadJsonDebug<PermissionsResponse>(HttpClient.INSTANCE.call(this.httpUrl + `api/v2/user-permissions/${userId}/`, HttpClient.INSTANCE.GET, new Map([["Authorization", `JWT ${session.token}`]]), undefined, undefined), [PermissionsResponse]).pipe(rxMap((it: PermissionsResponse): PermissionsResponse => it));
        }
        
        
        
        public startGroupAlert(session: Session, request: Alert): Observable<Alert> {
            return xSingleReadJsonDebug<Alert>(HttpClient.INSTANCE.call(this.httpUrl + "api/v2/alert/", HttpClient.INSTANCE.POST, new Map([["Authorization", `JWT ${session.token}`]]), xAnyToJsonHttpBody(request), undefined), [Alert]).pipe(rxMap((it: Alert): Alert => it));
        }
        
    }
    export namespace LiveApi {
        //! Declares com.tresitgroup.android.tresit.api.LiveApi.StartLoginResponseActual
        export class StartLoginResponseActual {
            public readonly loginUrl: string;
            public readonly isLocationLogin: (boolean | null);
            public readonly hasTempDetails: (boolean | null);
            public readonly sso: boolean;
            public constructor(loginUrl: string, isLocationLogin: (boolean | null) = false, hasTempDetails: (boolean | null) = false, sso: boolean) {
                this.loginUrl = loginUrl;
                this.isLocationLogin = isLocationLogin;
                this.hasTempDetails = hasTempDetails;
                this.sso = sso;
            }
            public static fromJson(obj: any): StartLoginResponseActual { return new StartLoginResponseActual(
                parseJsonTyped(obj["login_url"], [String]) as string,
                parseJsonTyped(obj["is_location_login"], [Boolean]) as (boolean | null),
                parseJsonTyped(obj["has_temporary_user_details"], [Boolean]) as (boolean | null),
                parseJsonTyped(obj["sso"], [Boolean]) as boolean
            ) }
            public toJSON(): object { return {
                login_url: this.loginUrl,
                is_location_login: this.isLocationLogin,
                has_temporary_user_details: this.hasTempDetails,
                sso: this.sso
            } }
            public hashCode(): number {
                let hash = 17;
                hash = 31 * hash + hashAnything(this.loginUrl);
                hash = 31 * hash + hashAnything(this.isLocationLogin);
                hash = 31 * hash + hashAnything(this.hasTempDetails);
                hash = 31 * hash + hashAnything(this.sso);
                return hash;
            }
            public equals(other: any): boolean { return other instanceof StartLoginResponseActual && safeEq(this.loginUrl, other.loginUrl) && safeEq(this.isLocationLogin, other.isLocationLogin) && safeEq(this.hasTempDetails, other.hasTempDetails) && safeEq(this.sso, other.sso) }
            public toString(): string { return `StartLoginResponseActual(loginUrl=${this.loginUrl}, isLocationLogin=${this.isLocationLogin}, hasTempDetails=${this.hasTempDetails}, sso=${this.sso})` }
            public copy(loginUrl: string = this.loginUrl, isLocationLogin: (boolean | null) = this.isLocationLogin, hasTempDetails: (boolean | null) = this.hasTempDetails, sso: boolean = this.sso): StartLoginResponseActual { return new StartLoginResponseActual(loginUrl, isLocationLogin, hasTempDetails, sso); }
        }
    }
    export namespace LiveApi {
        //! Declares com.tresitgroup.android.tresit.api.LiveApi.LoginResponse
        export class LoginResponse {
            public readonly jwt: string;
            public readonly id: ForeignKey<User>;
            public constructor(jwt: string, id: ForeignKey<User>) {
                this.jwt = jwt;
                this.id = id;
            }
            public static fromJson(obj: any): LoginResponse { return new LoginResponse(
                parseJsonTyped(obj["jwt"], [String]) as string,
                parseJsonTyped(obj["id"], [Number]) as number
            ) }
            public toJSON(): object { return {
                jwt: this.jwt,
                id: this.id
            } }
            public hashCode(): number {
                let hash = 17;
                hash = 31 * hash + hashAnything(this.jwt);
                hash = 31 * hash + hashAnything(this.id);
                return hash;
            }
            public equals(other: any): boolean { return other instanceof LoginResponse && safeEq(this.jwt, other.jwt) && safeEq(this.id, other.id) }
            public toString(): string { return `LoginResponse(jwt=${this.jwt}, id=${this.id})` }
            public copy(jwt: string = this.jwt, id: ForeignKey<User> = this.id): LoginResponse { return new LoginResponse(jwt, id); }
        }
    }
    export namespace LiveApi {
        //! Declares com.tresitgroup.android.tresit.api.LiveApi.JustAnId
        export class JustAnId {
            public readonly id: number;
            public constructor(id: number) {
                this.id = id;
            }
            public static fromJson(obj: any): JustAnId { return new JustAnId(
                parseJsonTyped(obj["id"], [Number]) as number
            ) }
            public toJSON(): object { return {
                id: this.id
            } }
            public hashCode(): number {
                let hash = 17;
                hash = 31 * hash + hashAnything(this.id);
                return hash;
            }
            public equals(other: any): boolean { return other instanceof JustAnId && safeEq(this.id, other.id) }
            public toString(): string { return `JustAnId(id=${this.id})` }
            public copy(id: number = this.id): JustAnId { return new JustAnId(id); }
        }
    }
    
    
    