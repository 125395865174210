// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/PermissionsResponse.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.PermissionsResponse
export class PermissionsResponse implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly permissions: (Array<string> | null);
    public constructor(id: number = getNO_ID(), permissions: (Array<string> | null) = []) {
        this.id = id;
        this.permissions = permissions;
    }
    public static fromJson(obj: any): PermissionsResponse { return new PermissionsResponse(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["permissions"], [Array, [String]]) as (Array<string> | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        permissions: this.permissions
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.permissions);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof PermissionsResponse && safeEq(this.id, other.id) && safeEq(this.permissions, other.permissions) }
    public toString(): string { return `PermissionsResponse(id=${this.id}, permissions=${this.permissions})` }
    public copy(id: number = this.id, permissions: (Array<string> | null) = this.permissions): PermissionsResponse { return new PermissionsResponse(id, permissions); }
}