// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertStatusEnum.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertStatusEnum
export class AlertStatusEnum {
    private constructor(name: string, jsonName: string) {
        this.name = name;
        this.jsonName = jsonName;
    }
    
    public static Active = new AlertStatusEnum("Active", "Active");
    public static Scoped = new AlertStatusEnum("Scoped", "Scoped");
    public static Inactive = new AlertStatusEnum("Inactive", "Inactive");
    
    private static _values: Array<AlertStatusEnum> = [AlertStatusEnum.Active, AlertStatusEnum.Scoped, AlertStatusEnum.Inactive];
    public static values(): Array<AlertStatusEnum> { return AlertStatusEnum._values; }
    public readonly name: string;
    public readonly jsonName: string;
    public static valueOf(name: string): AlertStatusEnum { return (AlertStatusEnum as any)[name]; }
    public toString(): string { return this.name }
    public toJSON(): string { return this.jsonName }
}