// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/CombinedStatus.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { iterMinBy } from 'butterfly-web/dist/kotlin/Collections'
import { filter as iterFilter, some as iterSome } from 'butterfly-web/dist/kotlin/lazyOp'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { DrawableResource } from 'butterfly-web/dist/views/DrawableResource'
import { StatusEnum } from './StatusEnum'
import { R } from '../R'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'

//! Declares com.tresitgroup.android.tresit.model.CombinedStatus
export class CombinedStatus {
    public readonly status: StatusEnum;
    public readonly needsMedicalAttention: boolean;
    public constructor(status: StatusEnum, needsMedicalAttention: boolean) {
        this.status = status;
        this.needsMedicalAttention = needsMedicalAttention;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.status);
        hash = 31 * hash + hashAnything(this.needsMedicalAttention);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof CombinedStatus && safeEq(this.status, other.status) && safeEq(this.needsMedicalAttention, other.needsMedicalAttention) }
    public toString(): string { return `CombinedStatus(status=${this.status}, needsMedicalAttention=${this.needsMedicalAttention})` }
    public copy(status: StatusEnum = this.status, needsMedicalAttention: boolean = this.needsMedicalAttention): CombinedStatus { return new CombinedStatus(status, needsMedicalAttention); }
    
}

//! Declares com.tresitgroup.android.tresit.model.switchStatus
export function switchStatus(s: (CombinedStatus | null)): DrawableResource {
    if (s === null) { return R.drawable.switch_empty }
    if (s!.needsMedicalAttention) {
        switch(s!.status) {
            case StatusEnum.Safe:
            return R.drawable.switch_safe_medical
            case StatusEnum.Unsafe:
            return R.drawable.switch_unsafe_medical
            case StatusEnum.Cleared:
            return R.drawable.switch_cleared_medical
            case StatusEnum.Unknown:
            return R.drawable.switch_unknown_medical
        }
        
    } else {
        switch(s!.status) {
            case StatusEnum.Safe:
            return R.drawable.switch_safe
            case StatusEnum.Unsafe:
            return R.drawable.switch_unsafe
            case StatusEnum.Cleared:
            return R.drawable.switch_cleared
            case StatusEnum.Unknown:
            return R.drawable.switch_unknown
        }
        
    }
    return R.drawable.switch_empty;
}

//! Declares com.tresitgroup.android.tresit.model.switchStatusText
export function switchStatusText(s: (CombinedStatus | null)): string {
    if (s === null) { return R._string.no_status }
    if (s!.needsMedicalAttention) {
        switch(s!.status) {
            case StatusEnum.Safe:
            return R._string.safe
            case StatusEnum.Unsafe:
            return R._string.unsafe
            case StatusEnum.Cleared:
            return R._string.cleared
            case StatusEnum.Unknown:
            return R._string.unknown
        }
        
    } else {
        switch(s!.status) {
            case StatusEnum.Safe:
            return R._string.safe
            case StatusEnum.Unsafe:
            return R._string.unsafe
            case StatusEnum.Cleared:
            return R._string.cleared
            case StatusEnum.Unknown:
            return R._string.unknown
        }
        
    }
    return R._string.no_status;
}

//! Declares com.tresitgroup.android.tresit.model.worst>kotlin.sequences.Sequence<com.tresitgroup.android.tresit.model.CombinedStatus>
export function xSequenceWorst(this_: Iterable<(CombinedStatus | null)>): (CombinedStatus | null) {
    let isUnsafeCount = 0;
    
    
    let isSafeCount = 0;
    
    
    let isClearedCount = 0;
    
    let totalCount = 0;
    
    const isUnsafe = iterFilter(iterableFilterNotNull(this_), (it: CombinedStatus): boolean => it.status.comparableValue === 1);
    
    const isSafe = iterFilter(iterableFilterNotNull(this_), (it: CombinedStatus): boolean => it.status.comparableValue === 3);
    
    const isCleared = iterFilter(iterableFilterNotNull(this_), (it: CombinedStatus): boolean => it.status.comparableValue === 4);
    
    for (const ele of this_) {
        totalCount = totalCount + 1;
    }
    for (const ele of isUnsafe) {
        isUnsafeCount = isUnsafeCount + 1;
    }
    for (const ele of isSafe) {
        isSafeCount = isSafeCount + 1;
    }
    for (const ele of isCleared) {
        isClearedCount = isClearedCount + 1;
    }
    if (isUnsafeCount === 0) {
        if (totalCount > isSafeCount + isClearedCount) {
            return null;
        }
    }
    const resultingStatus = (iterMinBy(iterableFilterNotNull(this_), (it: CombinedStatus): number => it.status.comparableValue)?.status ?? null)
    if(resultingStatus === null) { return null }
    return new CombinedStatus(resultingStatus, iterSome(this_, (it: (CombinedStatus | null)): boolean => (it?.needsMedicalAttention ?? null) ?? false));
}