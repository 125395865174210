// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertClassificationEnum.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../R'

//! Declares com.tresitgroup.android.tresit.model.AlertClassificationEnum
export class AlertClassificationEnum {
    private constructor(name: string, jsonName: string) {
        this.name = name;
        this.jsonName = jsonName;
    }
    
    public static None = new AlertClassificationEnum("None", "None");
    public static Drill = new AlertClassificationEnum("Drill", "Drill");
    public static Alert = new AlertClassificationEnum("Alert", "Alert");
    
    private static _values: Array<AlertClassificationEnum> = [AlertClassificationEnum.None, AlertClassificationEnum.Drill, AlertClassificationEnum.Alert];
    public static values(): Array<AlertClassificationEnum> { return AlertClassificationEnum._values; }
    public readonly name: string;
    public readonly jsonName: string;
    public static valueOf(name: string): AlertClassificationEnum { return (AlertClassificationEnum as any)[name]; }
    public toString(): string { return this.name }
    public toJSON(): string { return this.jsonName }
}

//! Declares com.tresitgroup.android.tresit.model.foregroundColor>com.tresitgroup.android.tresit.model.AlertClassificationEnum
export function xAlertClassificationEnumForegroundColorGet(this_: AlertClassificationEnum): string {
    switch(this_) {
        case AlertClassificationEnum.Alert:
        return R.color.unsafe
        case AlertClassificationEnum.Drill:
        return R.color.drill
        case AlertClassificationEnum.None:
        return R.color.safe
    }
    
    return R.color.foreground;
}

//! Declares com.tresitgroup.android.tresit.model.background2Color>com.tresitgroup.android.tresit.model.AlertClassificationEnum
export function xAlertClassificationEnumBackground2ColorGet(this_: AlertClassificationEnum): string {
    switch(this_) {
        case AlertClassificationEnum.Alert:
        return R.color.barBackgroundAlertFade2
        case AlertClassificationEnum.Drill:
        return R.color.barBackgroundDrillFade2
        case AlertClassificationEnum.None:
        return R.color.barBackgroundFade2
    }
    
    return R.color.background;
}

//! Declares com.tresitgroup.android.tresit.model.backgroundColor>com.tresitgroup.android.tresit.model.AlertClassificationEnum
export function xAlertClassificationEnumBackgroundColorGet(this_: AlertClassificationEnum): string {
    switch(this_) {
        case AlertClassificationEnum.Alert:
        return R.color.barBackgroundAlert
        case AlertClassificationEnum.Drill:
        return R.color.barBackgroundDrill
        case AlertClassificationEnum.None:
        return R.color.barBackground
    }
    
    return R.color.background;
}
