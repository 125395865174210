//
// ComponentRowAlertHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/component_row_alert_history.html'
import htmlForDefault from './component_row_alert_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowAlertHistoryXml
export class ComponentRowAlertHistoryXml {
    xmlRoot!: HTMLElement
    root: HTMLDivElement | null = null
    drillBox!: HTMLInputElement
    alertTypeIcon!: HTMLImageElement
    alertType!: HTMLElement
    site!: HTMLElement
    startTime!: HTMLElement
    endTime!: HTMLElement
    tvCount!: HTMLElement
    initiator: HTMLElement | null = null
    canceller: HTMLElement | null = null
    reason: HTMLElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.root = findViewById<HTMLDivElement>(view, "root")
        this.drillBox = getViewById<HTMLInputElement>(view, "drillBox")
        this.alertTypeIcon = getViewById<HTMLImageElement>(view, "alert_type_icon")
        this.alertType = getViewById<HTMLElement>(view, "alertType")
        this.site = getViewById<HTMLElement>(view, "site")
        this.startTime = getViewById<HTMLElement>(view, "startTime")
        this.endTime = getViewById<HTMLElement>(view, "endTime")
        this.tvCount = getViewById<HTMLElement>(view, "tvCount")
        this.initiator = findViewById<HTMLElement>(view, "initiator")
        this.canceller = findViewById<HTMLElement>(view, "canceller")
        this.reason = findViewById<HTMLElement>(view, "reason")
        return view
    }
}
