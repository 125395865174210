// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/CacheEdge.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { Comparator } from 'butterfly-web/dist/kotlin/Comparable'
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { HasId } from '../model/HasId'
import { Observable, Subject, concat as rxConcat, of as rxOf } from 'rxjs'
import { Time } from '../util/time'
import { iterLastOrNull, iterableFilterNotNull, listRemoveItem } from 'butterfly-web/dist/KotlinCollections'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ApiQuery } from './ApiQuery'
import { CachedApiTable } from './CachedApiTable'
import { map as rxMap } from 'rxjs/operators'
import { ForeignKey } from '../model/ForeignKey'

//! Declares com.tresitgroup.android.tresit.api.CacheEdge
export class CacheEdge<T extends HasId> extends ObservableProperty<(Array<T> | null)> {
    public readonly parent: CachedApiTable<T>;
    public readonly query: ApiQuery<T>;
    public lastUpdated: number;
    public constructor(parent: CachedApiTable<T>, query: ApiQuery<T>, lastUpdated: number = 0) {
        super();
        this.parent = parent;
        this.query = query;
        this.lastUpdated = lastUpdated;
        this.populated = false;
        this.outboundRequest = null;
        this.outboundRequestCount = 0;
        this.hasAll = false;
        this.references = ([] as Array<number>);
        this.onChangeSubject = new Subject();
    }
    
    public populated: boolean;
    
    public outboundRequest: (Observable<Array<T>> | null);
    
    public outboundRequestCount: number;
    
    public hasAll: boolean;
    
    
    //! Declares com.tresitgroup.android.tresit.api.CacheEdge.rx
    public get rx(): Observable<Array<T>> {
        const value = this.value;
        
        return ((): Observable<Array<T>> => {
            if (value !== null) {
                return rxConcat(rxOf(value), this.onChange.pipe(rxMap((it: (Array<T> | null)): Array<T> => it!!)));
            } else {
                return this.onChange.pipe(rxMap((it: (Array<T> | null)): Array<T> => it!!));
            }
        })();
    }
    
    
    public update(values: Array<T>, requestedCount: number): void {
        this.hasAll = values.length < requestedCount;
        this.populated = true;
        this.references.length = 0;
        for (const v of values) {
            this.references.push(v.id);
        }
        this.lastUpdated = Time.INSTANCE.epochMilliseconds();
        this.onChangeSubject.next(values);
        console.debug(`${"CacheEdge"}: ${`${this.query} updates to ${values.length}`}`);
    }
    
    public fromOther(values: Array<T>): void {
        this.hasAll = true;
        this.populated = true;
        this.references.length = 0;
        for(const _x of ((it: Array<T>): Array<T> => ((): (Array<T> | null) => {
            const temp40 = this.query.sort.comparator;
            if(temp40 === null) { return null }
            return ((c: Comparator<T>): Array<T> => it.slice().sort(c))(temp40)
        })() ?? it)(values.filter((it: T): boolean => this.query.filter.filter(it)))) {
            const it = _x;
            this.references.push(it.id);
        };
        this.lastUpdated = Time.INSTANCE.epochMilliseconds();
        const myValues = this.value!!;
        
        this.onChangeSubject.next(myValues);
        console.debug(`${"CacheEdge"}: ${`${this.query} updates to ${myValues.length}`}`);
    }
    
    public clear(): void {
        this.populated = false;
        this.references.length = 0;
        this.hasAll = false;
        this.onChangeSubject.next(null);
        this.lastUpdated = 0;
    }
    
    public changed(value: T): void {
        if ((!this.populated)) { return }
        const lastIndex = iterLastOrNull(this.references) ?? 2147483647;
        
        const last = ((this.parent.items.get(lastIndex) ?? null)?.value ?? null);
        
        const oldIndex: number = this.references.indexOf(value.id);
        
        const fitsNew = this.query.filter.filter(value) &&
        (last === null || this.hasAll || !(((): (boolean | null) => {
            const temp56 = ((): (number | null) => {
                const temp58 = this.query.sort.comparator;
                if(temp58 === null) { return null }
                return temp58(value, last)
            })();
            if(temp56 === null) { return null }
            return ((it: number): boolean => it <= 0)(temp56)
        })() === false)) &&
        (this.query.filter.mockFilter === null || !(oldIndex === (-1)));
        
        if (fitsNew && !(oldIndex === (-1))){
            const initialPosition = ((): (number | null) => {
                const temp61 = this.query.sort.comparator;
                if(temp61 === null) { return null }
                return ((comp: Comparator<T>): number => this.references.findIndex((it: number): boolean => {
                    if (it === value.id) { return false }
                    const v = ((this.parent.items.get(it) ?? null)?.value ?? null)
                    if(v === null) { return false }
                    return comp(value, v) <= 0;
                }))(temp61)
            })() ?? oldIndex;
            
            const newPosition = initialPosition === (-1) ? this.references.length - 1 : initialPosition > oldIndex ? initialPosition - 1 : initialPosition;
            
            
            if (newPosition === oldIndex) {
                //change
                this.onChangeSubject.next(this.value);
            } else {
                //move
                this.references.splice(oldIndex, 1)[0];
                this.references.splice(newPosition, 0, value.id);
                this.onChangeSubject.next(this.value);
            }
        } else if (fitsNew){
            //addition
            const position = ((): (number | null) => {
                const temp80 = this.query.sort.comparator;
                if(temp80 === null) { return null }
                return ((comp: Comparator<T>): number => this.references.findIndex((it: number): boolean => {
                    const v = ((this.parent.items.get(it) ?? null)?.value ?? null)
                    if(v === null) { return false }
                    return comp(value, v) <= 0;
                }))(temp80)
            })() ?? this.references.length;
            
            if (!(position === (-1))) {
                this.references.splice(position, 0, value.id);
            } else {
                this.references.push(value.id);
            }
            this.onChangeSubject.next(this.value);
        } else if (!(oldIndex === (-1))){
            //removal
            this.references.splice(oldIndex, 1)[0];
            this.onChangeSubject.next(this.value);
        }
    }
    
    public terminated(oldIndex: ForeignKey<T>): void {
        if ((!this.populated)) { return }
        listRemoveItem(this.references, oldIndex);
        this.onChangeSubject.next(this.value);
    }
    
    public readonly references: Array<number>;
    
    
    public readonly onChangeSubject: Subject<(Array<T> | null)>;
    
    //! Declares com.tresitgroup.android.tresit.api.CacheEdge.onChange
    public get onChange(): Observable<(Array<T> | null)> { return this.onChangeSubject; }
    
    //! Declares com.tresitgroup.android.tresit.api.CacheEdge.value
    public get value(): (Array<T> | null) {
        if ((!this.populated)) { return null }
        return iterToArray(iterableFilterNotNull(iterMap(this.references, (it: number): (T | null) => ((this.parent.items.get(it) ?? null)?.value ?? null))));
    }
    
}