// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/AlertStatusActiveVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { AlertStatusActiveXml } from '../../layout/AlertStatusActiveXml'
import { xObservablePropertyObservableGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { ComponentRowGuidanceXml } from '../../layout/ComponentRowGuidanceXml'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { AlertCancelVG } from './AlertCancelVG'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { PoliceStatus } from '../../model/PoliceStatus'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { Alert } from '../../model/Alert'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { filter as rxFilter, take as rxTake } from 'rxjs/operators'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { AlertType } from '../../model/AlertType'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { AlertGuidance } from '../../model/AlertGuidance'
import { spinnerBind } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { SelectAlertVG } from './SelectAlertVG'
import { School } from '../../model/School'
import { toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'

//! Declares com.tresitgroup.android.tresit.vg.alert.AlertStatusActiveVG
export class AlertStatusActiveVG extends ViewGenerator {
    public readonly alertId: ForeignKey<Alert>;
    public readonly alertStack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(alertId: ForeignKey<Alert>, alertStack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>, schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.alertId = alertId;
        this.alertStack = alertStack;
        this.dialog = dialog;
        this.schoolId = schoolId;
        this.session = session;
        this.approvingAlert = new StandardObservableProperty<boolean>(false, undefined);
        this.endingAlert = new StandardObservableProperty<boolean>(false, undefined);
        this.localStatus = new StandardObservableProperty<PoliceStatus>(((this.session.alerts.observable(this.alertId)?.value ?? null)?.policeStatus ?? null) ?? PoliceStatus.NotNotified, undefined);
        this.sendingPoliceStatus = new StandardObservableProperty<boolean>(false, undefined);
        this.alert = this.session.alerts.observable(this.alertId);
        this.focusedGuidance = new StandardObservableProperty<number>((-1), undefined);
    }
    
    
    
    public readonly approvingAlert: StandardObservableProperty<boolean>;
    
    public readonly endingAlert: StandardObservableProperty<boolean>;
    
    
    public readonly localStatus: StandardObservableProperty<PoliceStatus>;
    
    public readonly sendingPoliceStatus: StandardObservableProperty<boolean>;
    
    public readonly alert: ObservableProperty<(Alert | null)>;
    
    public alertType(): ObservableProperty<(AlertType | null)> {
        return xObservablePropertyFlatMapNotNull<number, AlertType>(xObservablePropertyMap<(Alert | null), (number | null)>(this.alert, (it: (Alert | null)): (number | null) => (it?.name ?? null)), (it: number): ObservableProperty<(AlertType | null)> => this.session.alertTypes.observable(it));
    }
    public guidances(): ObservableProperty<(Array<AlertGuidance> | null)> {
        return xObservablePropertyFlatMapNotNull<number, Array<AlertGuidance>>(xObservablePropertyMap<(Alert | null), (number | null)>(this.alert, (it: (Alert | null)): (number | null) => (it?.name ?? null)), (it: number): ObservableProperty<(Array<AlertGuidance> | null)> => this.session.alertGuidances.observableListSimple(AlertGuidance.Companion.INSTANCE.forAlertType(it), undefined, undefined, undefined, undefined, undefined));
    }
    public readonly focusedGuidance: StandardObservableProperty<number>;
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertStatusActiveVG.title
    public get title(): string { return "Alert Status Active"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertStatusActiveXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const alertType = this.alertType();
        
        const guidances = this.guidances();
        
        
        //--- Set Up xml.statusImage
        xImageViewBindImage(xml.statusImage, xObservablePropertyMap<(AlertType | null), (Image | null)>(alertType, (it: (AlertType | null)): (Image | null) => ((): (Image | null) => {
            const temp3937 = (it?.icon ?? null);
            if(temp3937 !== null) {
                return xStringAsImage(temp3937)
            } else { return null }
        })()));
        
        //--- Set Up xml.statusText
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            if (it !== null) { xml.statusText.style.color = it!.colorResource } else { xml.statusText.style.color = R.color.textForeground }
        }), xViewRemovedGet(xml.statusText));
        xTextViewBindString(xml.statusText, xObservablePropertyMap<(AlertType | null), string>(alertType, (it: (AlertType | null)): string => (it?.name ?? null) ?? "Emergency"));
        
        //--- Set Up xml.typeText
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            if (it !== null) {
                xml.statusText.style.color = it!.colorResource;
                xml.typeText.style.color = it!.colorResource;
            } else {
                xml.statusText.style.color = R.color.textForeground;
                xml.typeText.style.color = R.color.textForeground;
            }
        }), xViewRemovedGet(xml.typeText));
        xTextViewBindString(xml.typeText, xObservablePropertyMap<(Alert | null), string>(this.alert, (it: (Alert | null)): string => (it?.drill ?? null) === true ? R._string.drill_in_progress : R._string.alert_in_progress));
        
        //--- Set Up xml.modifyBar
        xViewBindExists(xml.modifyBar, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<(Alert | null), boolean>(this.alert, (it: (Alert | null)): boolean => (it?.initiator ?? null) === this.session.session.userId), xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanUpdateAlert ?? null) === true), (a: boolean, b: boolean): boolean => a || b));
        
        //--- Set Up xml.approveWorking
        xViewBindExists(xml.approveWorking, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanEscalateAlert ?? null) === true), xObservablePropertyMap<(Alert | null), boolean>(this.alert, (it: (Alert | null)): boolean => (it?.pendingApproval ?? null) === true), (a: boolean, b: boolean): boolean => a && b));
        xViewFlipperBindLoading(xml.approveWorking, this.approvingAlert, undefined);
        
        //--- Set Up xml.approve (overwritten on flow generation)
        xViewOnClick(xml.approve, undefined, (): void => {
            this.approveClick();
        });
        
        //--- Set Up xml.change
        //--- Set Up xml.changeBox
        xViewBindExists(xml.changeBox, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanUpdateAlert ?? null) === true));
        xViewOnClick(xml.change, undefined, (): void => {
            this.changeClick(this.schoolId);
        });
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            if (it !== null) {
                if (it!.drill){
                    setViewText(xml.change, "CHANGE DRILL");
                    setViewText(xml.end, "END DRILL");
                } else if ((!it!.drill)){
                    setViewText(xml.change, "CHANGE ALERT");
                    setViewText(xml.end, "END ALERT");
                }
            }
        }), xViewRemovedGet(xml.change));
        
        //--- Set Up xml.endWorking
        xViewFlipperBindLoading(xml.endWorking, this.endingAlert, undefined);
        
        //--- Set Up xml.end
        xViewOnClick(xml.end, undefined, (): void => {
            this.endClick();
        });
        
        //--- Set Up xml.guidances
        
        if (this.focusedGuidance.value < 0) {
            xDisposableUntil<SubscriptionLike>(xObservablePropertyObservableGet(guidances).pipe(rxFilter((it: (Array<AlertGuidance> | null)): boolean => it !== null)).pipe(rxTake(1)).subscribe((it: (Array<AlertGuidance> | null)): void => {
                this.focusedGuidance.value = (((): (AlertGuidance | null) => {
                    const temp3974 = it;
                    if(temp3974 === null) { return null }
                    return (temp3974[0] ?? null)
                })()?.id ?? null) ?? (-1);
            }, undefined, undefined), xViewRemovedGet(view));
        }
        xLinearLayoutBind<AlertGuidance>(xml.guidances, xObservablePropertyMap<(Array<AlertGuidance> | null), Array<AlertGuidance>>(guidances, (it: (Array<AlertGuidance> | null)): Array<AlertGuidance> => it ?? []), new AlertGuidance(undefined, 0, "", undefined), (observable: ObservableProperty<AlertGuidance>): HTMLElement => {
            //--- Make Subview For xml.guidances (overwritten on flow generation)
            const cellXml = new ComponentRowGuidanceXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.header
            cellXml.header.onclick = (_ev) => { _ev.stopPropagation();
                const it = _ev.target as HTMLElement;
                if (this.focusedGuidance.value === observable.value.id) {
                    this.focusedGuidance.value = (-1);
                } else {
                    this.focusedGuidance.value = observable.value.id;
                }
            };
            
            //--- Set Up cellXml.title
            xTextViewBindString(cellXml.title, xObservablePropertyMap<AlertGuidance, string>(observable, (it: AlertGuidance): string => it.title));
            
            //--- Set Up cellXml.dropdown
            const expanded = xObservablePropertyCombine<number, AlertGuidance, boolean>(this.focusedGuidance, observable, (focused: number, current: AlertGuidance): boolean => focused === current.id);
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(expanded, undefined, undefined, (it: boolean): void => {
                if (it) { imageViewSetImageResource(cellXml.dropdown, R.drawable.ic_arrow_drop_up) } else { imageViewSetImageResource(cellXml.dropdown, R.drawable.ic_arrow_drop_down) }
            }), xViewRemovedGet(cellXml.dropdown));
            
            //--- Set Up cellXml.contents
            xTextViewBindString(cellXml.contents, xObservablePropertyMap<AlertGuidance, string>(observable, (it: AlertGuidance): string => it.plan ?? ""));
            xViewBindExists(cellXml.contents, expanded);
            
            //--- End Make Subview For xml.guidances (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.policeStatusSending
        xViewFlipperBindLoading(xml.policeStatusSending, this.sendingPoliceStatus, undefined);
        xViewBindExists(xml.policeStatusSending, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanUpdatePoliceStatus ?? null) === true));
        
        //--- Set Up xml.policeStatusDropdown
        spinnerBind<PoliceStatus>(xml.policeStatusDropdown, new ConstantObservableProperty<Array<PoliceStatus>>(iterToArray(PoliceStatus.values())), xObservablePropertyWithWrite<PoliceStatus>(this.localStatus, (it: PoliceStatus): void => {
            this.localStatus.value = it;
            xSingleCallDisplayingError<Alert>(xSingleWorking<Alert>(this.session.alerts.patch(this.alertId, [Alert.Companion.INSTANCE.setPoliceStatus(it)]), this.sendingPoliceStatus), undefined, undefined);
        }), (it: PoliceStatus): string => it.textResource);
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(this.alert, undefined, undefined, (it: (Alert | null)): void => {
            this.localStatus.value = (it?.policeStatus ?? null) ?? PoliceStatus.NotNotified;
        }), xViewRemovedGet(xml.policeStatusDropdown));
        
        //--- Set Up xml.policeStatusView
        xViewBindExists(xml.policeStatusView, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => !((it?.permissionCanUpdatePoliceStatus ?? null) === true)));
        xTextViewBindString(xml.policeStatusView, xObservablePropertyMap<(Alert | null), string>(this.alert, (it: (Alert | null)): string => ((): string => {
            if (it !== null) {
                return it!.policeStatus.textResource;
            } else {
                return "";
            }
        })()));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public approveClick(): void {
        xSingleCallDisplayingError<Alert>(xSingleWorking<Alert>(this.session.alerts
            .patch(this.alertId, [Alert.Companion.INSTANCE.setPendingApproval(false)]), this.approvingAlert), undefined, undefined);
    }
    public changeClick(schoolId: ForeignKey<School>): void {
        this.alertStack.push(new SelectAlertVG(this.alertId, this.alertStack, this.dialog, (this.session.alerts.observable(this.alertId).value?.internal ?? null) ?? false, schoolId, this.session));
    }
    
    public endClick(): void {
        this.dialog.push(new AlertCancelVG(this.alertId, this.dialog, this.schoolId, this.session));
    }
    
    //--- Body End
}
