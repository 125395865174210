// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/MockTable.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { SocketMessage } from '../model/SocketMessage'
import { HasId } from '../model/HasId'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ForeignKey } from '../model/ForeignKey'
import { Subject } from 'rxjs'
import { toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { SoftDeletable } from '../model/SoftDeletable'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'
import { xAnyToJsonString } from 'butterfly-web/dist/Codable'

//! Declares com.tresitgroup.android.tresit.api.SignalData
export class SignalData<T extends HasId> {
    public readonly value: T;
    public readonly created: boolean;
    public readonly deleted: boolean;
    public constructor(value: T, created: boolean = false, deleted: boolean = false) {
        this.value = value;
        this.created = created;
        this.deleted = deleted;
    }
    public static fromJson<T extends HasId>(obj: any, T: any): SignalData<T> { return new SignalData(
        parseJsonTyped(obj["value"], T) as T,
        parseJsonTyped(obj["created"], [Boolean]) as boolean,
        parseJsonTyped(obj["deleted"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        value: this.value,
        created: this.created,
        deleted: this.deleted
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.value);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.deleted);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof SignalData && safeEq(this.value, other.value) && safeEq(this.created, other.created) && safeEq(this.deleted, other.deleted) }
    public toString(): string { return `SignalData(value=${this.value}, created=${this.created}, deleted=${this.deleted})` }
    public copy(value: T = this.value, created: boolean = this.created, deleted: boolean = this.deleted): SignalData<T> { return new SignalData(value, created, deleted); }
}

//! Declares com.tresitgroup.android.tresit.api.MockTable
export class MockTable<T extends HasId> {
    public readonly mainSubject: Subject<SocketMessage>;
    public readonly typeString: string;
    public constructor(mainSubject: Subject<SocketMessage>, typeString: string) {
        this.mainSubject = mainSubject;
        this.typeString = typeString;
        this.objects = new Map([]);
        this.signals = new Subject();
        this.currentPk = 0;
    }
    
    public readonly objects: Map<number, T>;
    
    public readonly signals: Subject<SignalData<T>>;
    
    public currentPk: number;
    
    
    public get(id: ForeignKey<T>, includeDeleted: boolean): (T | null) {
        return (this.objects.get(id) ?? null);
    }
    
    public asList(includeDeleted: boolean): Array<T> {
        return iterToArray(this.objects.values());
    }
    
    public addItem(item: T): T {
        item.id = this.currentPk = this.currentPk + 1;
        this.objects.set(item.id, item);
        this.signals.next(new SignalData<T>(item, true, undefined));
        this.mainSubject.next(new SocketMessage(this.typeString, xAnyToJsonString(item), undefined, undefined));
        return item;
    }
    public deleteItem(item: T): void {
        this.objects.delete(item.id);
        this.signals.next(new SignalData<T>(item, undefined, true));
        this.mainSubject.next(new SocketMessage(this.typeString, undefined, undefined, item.id));
    }
    public deleteItemById(id: number): void {
        const it_1229 = (this.objects.get(id) ?? null);
        if (it_1229 !== null) {
            this.deleteItem(it_1229);
        }
    }
    public replaceItem(item: T): void {
        this.objects.set(item.id, item);
        this.signals.next(new SignalData<T>(item, undefined, undefined));
        this.mainSubject.next(new SocketMessage(this.typeString, xAnyToJsonString(item), undefined, undefined));
    }
}

//! Declares com.tresitgroup.android.tresit.api.SoftDeleteMockTable
export class SoftDeleteMockTable<T extends SoftDeletable> extends MockTable<T> {
    public constructor(mainSubject: Subject<SocketMessage>, typeString: string) {
        super(mainSubject, typeString);
        this.deleted = new Map([]);
    }
    
    public readonly deleted: Map<number, T>;
    
    
    public get(id: ForeignKey<T>, includeDeleted: boolean): (T | null) {
        const it_1237 = super.get(id, includeDeleted);
        if (it_1237 !== null) {
            return it_1237;
        } else {
            if (includeDeleted) {
                return (this.deleted.get(id) ?? null);
            }
        }
        return null;
    }
    public asList(includeDeleted: boolean): Array<T> {
        if (includeDeleted) { return iterToArray(this.objects.values()).concat(iterToArray(this.deleted.values())) } else { return iterToArray(this.objects.values()) }
    }
    
    public deleteItem(item: T): void {
        item.deleted = new Date();
        this.deleted.set(item.id, item);
        super.deleteItem(item);
    }
}