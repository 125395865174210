//
// ComponentRowAlertCheckboxXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_alert_checkbox.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowAlertCheckboxXml
export class ComponentRowAlertCheckboxXml {
    xmlRoot!: HTMLElement
    isChecked!: HTMLInputElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.isChecked = getViewById<HTMLInputElement>(view, "is_checked")
        return view
    }
}
