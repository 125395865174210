// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/StartingAlertVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { xActivityAccessShowToast } from '../../showToast'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { tap as rxTap } from 'rxjs/operators'
import { StartingAlertXml } from '../../layout/StartingAlertXml'
import { xSingleCallDisplayingError, xSingleCallWithError } from '../../util/apicalls'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ForeignKey } from '../../model/ForeignKey'
import { AlertStatusActiveVG } from './AlertStatusActiveVG'
import { logVG } from '../../util/LogVG'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { Alert } from '../../model/Alert'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { Session } from '../../model/Session'
import { RequiresApprovalDescriptionVG } from '../approval/RequiresApprovalDescriptionVG'
import { ApiModifier } from '../../api/ApiModifier'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.alert.StartingAlertVG
export class StartingAlertVG extends ViewGenerator {
    public readonly alert: Alert;
    public readonly alertStack: ObservableStack<ViewGenerator>;
    public readonly changes: (Array<ApiModifier<Alert>> | null);
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public readonly requiresAdminApprove: boolean;
    public readonly isGroup: boolean;
    public constructor(alert: Alert, alertStack: ObservableStack<ViewGenerator>, changes: (Array<ApiModifier<Alert>> | null) = null, dialog: ObservableStack<ViewGenerator>, schoolId: ForeignKey<School>, session: SessionApi, requiresAdminApprove: boolean, isGroup: boolean = false) {
        super();
        this.alert = alert;
        this.alertStack = alertStack;
        this.changes = changes;
        this.dialog = dialog;
        this.schoolId = schoolId;
        this.session = session;
        this.requiresAdminApprove = requiresAdminApprove;
        this.isGroup = isGroup;
        this.sent = false;
    }
    
    
    //--- Provides alertId
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.StartingAlertVG.title
    public get title(): string { return "Starting Alert"; }
    
    
    public sent: boolean;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new StartingAlertXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Send
        if ((!this.requiresAdminApprove)) {
            if ((!this.sent)) {
                this.sent = true;
                if (this.changes !== null) {
                    this.patchAlert();
                } else {
                    if (this.isGroup) {
                        this.postGroupAlert(dependency);
                    } else {
                        this.postAlert();
                    }
                }
            }
        } else {
            this.requestAlert(dependency);
        }
        
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    private postGroupAlert(dependency: Window): void {
        const s: (Session | null) = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
        
        if (s !== null) {
            xSingleCallWithError<Alert>(this.session.rawApi.startGroupAlert(s!, this.alert), undefined, (it: Alert): void => {
                    xActivityAccessShowToast(dependency, "Group alert has started!");
                    this.alertStack.pop();
                }, (): void => {
                    this.alertStack.pop();
            });
        }
    }
    
    private requestAlert(dependency: Window): void {
        let policeStatus = "";
        
        
        if (this.alert.policeStatus.toString() === "NotNotified") {
            policeStatus = "not_notified";
        }
        if (this.alert.policeStatus.toString() === "Notified") {
            policeStatus = "notified";
        }
        if (this.alert.policeStatus.toString() === "En Route") {
            policeStatus = "en_route";
        }
        if (this.alert.policeStatus.toString() === "On Site") {
            policeStatus = "on_site";
        }
        
        
        this.dialog.push(new RequiresApprovalDescriptionVG(this.dialog, this.session, this.alert, this.alertStack, this.changes, this.schoolId, policeStatus));
        
        //        post {
            //
            //
            //            session.requestAlert(alert.school.toInt(),
                //                alert.name?.toInt()!!, alert.created.toString(),
            //                alert.initiator?.toInt()!!, alert.policeStatus.toString(), alert.scoped, alert.silent, alert.internal).subscribeBy(
                //                onError = {
                    //                    Log.e("StartingAlertVG", "onError: $it")
                //                },
                //                onSuccess = {
                    //                    Log.i("StartingAlertVG", "Uploading FCM: Upload success!")
                //                }
            //            )
            //
        //        }
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    public patchAlert(): void {
        post((): void => {
            const it_4117 = this.changes;
            if (it_4117 !== null) {
                xSingleCallDisplayingError<Alert>(this.session.alerts.patch(this.alert.id, it_4117).pipe(rxTap(undefined, (it: any): void => {
                                this.alertStack.pop();
                    })), undefined, (it: Alert): void => {
                        this.alertStack.reset(new AlertStatusActiveVG(this.alert.id, this.alertStack, this.dialog, this.schoolId, this.session));
                });
            }
        });
    }
    
    public postAlert(): void {
        post((): void => {
            xSingleCallDisplayingError<Alert>(this.session.alerts.post(this.alert).pipe(rxTap(undefined, (it: any): void => {
                this.alertStack.pop();
            })), undefined, undefined);
        });
    }
    
    //--- Body End
}
