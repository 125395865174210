// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminUserDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { UserActivityHistoryVG } from './UserActivityHistoryVG'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Observable, SubscriptionLike, zip as rxZip } from 'rxjs'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ViewString, ViewStringRaw, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { ApiModifier } from '../../api/ApiModifier'
import { FormUserDetailVG } from './FormUserDetailVG'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { DialogRequest, showDialog, showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { RegisteredDevice } from '../../model/RegisteredDevice'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { AdminUserDetailXml } from '../../layout/AdminUserDetailXml'
import { map as rxMap } from 'rxjs/operators'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminUserDetailVG
export class AdminUserDetailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly userId: ForeignKey<User>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, stack: ObservableStack<ViewGenerator>, userId: ForeignKey<User>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.stack = stack;
        this.userId = userId;
        this.user = new StandardObservableProperty<User>(new User(undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined);
        this.initial = null;
        this.initialSchools = xObservablePropertyMap<(Array<UserSchoolMembership> | null), Array<UserSchoolMembership>>(this.session.userSchoolMembership.observableListSimple(UserSchoolMembership.Companion.INSTANCE.forUser(this.userId), undefined, undefined, undefined, undefined, undefined), (it: (Array<UserSchoolMembership> | null)): Array<UserSchoolMembership> => it ?? []);
        this.userSchools = new StandardObservableProperty<Array<School>>([], undefined);
        this.submitting = new StandardObservableProperty<boolean>(false, undefined);
        this.form = new FormUserDetailVG(this.dialog, this.session, this.user, this.userSchools);
        this.registeredDevice = new StandardObservableProperty<RegisteredDevice>(new RegisteredDevice(undefined, undefined, undefined, undefined, undefined, undefined), undefined);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<User>(this.session.users.get(this.userId), undefined, (it: User): void => {
            this.user.value = it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            this.initial = it;
        }));
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<School>>(this.session.schools.getListSimple(School.Companion.INSTANCE.userBelongsTo(this.userId), undefined, undefined, undefined, undefined), undefined, (it: Array<School>): void => {
            this.userSchools.value = it;
        }));
        this.session.rawApi.getRegisteredDevice(this.session.session, this.userId).subscribe((it: RegisteredDevice): void => {
                this.registeredDevice.value = it;
            }, (it: any): void => {
                showDialogAlert(new ViewStringResource(R._string.something_went_wrong));
        });
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminUserDetailVG.title
    public get title(): string { return "Admin User Detail"; }
    
    
    public readonly user: MutableObservableProperty<User>;
    
    public initial: (User | null);
    
    public initialSchools: ObservableProperty<Array<UserSchoolMembership>>;
    
    public readonly userSchools: MutableObservableProperty<Array<School>>;
    
    public readonly submitting: MutableObservableProperty<boolean>;
    
    
    public readonly form: FormUserDetailVG;
    
    
    public registeredDevice: MutableObservableProperty<RegisteredDevice>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminUserDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.detailForm.xmlRoot
        //--- Set Up xml.detailForm.title
        //--- Set Up xml.detailForm.firstName
        //--- Set Up xml.detailForm.lastName
        //--- Set Up xml.detailForm.cellPhone
        //--- Set Up xml.detailForm.email
        //--- Set Up xml.detailForm.passwordReset
        //--- Set Up xml.detailForm.userType
        //--- Set Up xml.detailForm.userSchoolsLoading
        //--- Set Up xml.detailForm.userSchools
        //--- Make Subview For xml.detailForm.userSchools
        //--- Set Up cellXml.root
        //--- Set Up cellXml.label
        //--- Set Up cellXml.remove
        //--- End Make Subview For xml.detailForm.userSchools
        //--- Set Up xml.detailForm.newSchool
        //--- Set Up xml.detailForm.newSchoolSelect.xmlRoot
        //--- Set Up xml.detailForm.newSchoolSelect.schoolFilter
        //--- Set Up xml.detailForm.addSite
        this.form.generateFromXml(dependency, xml.detailForm);
        
        //--- Set Up xml.userHistory (overwritten on flow generation)
        xViewOnClick(xml.userHistory, undefined, (): void => {
            this.userHistoryClick();
        });
        
        //--- Set Up xml.submitWorking
        xViewFlipperBindLoading(xml.submitWorking, this.submitting, undefined);
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.submit (overwritten on flow generation)
        xViewOnClick(xml.submit, undefined, (): void => {
            this.submitClick();
        });
        
        //--- Set Up xml.delete
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<User>(this.user, undefined, undefined, (it: User): void => {
            setViewText(xml._delete, it.isActive ? R._string._delete : R._string.restore);
        }), xViewRemovedGet(view));
        xViewOnClick(xml._delete, undefined, (): void => {
            this.deleteClick(this.user.value.isActive);
        });
        
        //--- Generate End (overwritten on flow generation)
        
        
        //--- Set Up cellXml.deviceMac
        xViewBindExists(xml.deviceMac, xObservablePropertyMap<RegisteredDevice, boolean>(this.registeredDevice, (it: RegisteredDevice): boolean => it.hasMac === true));
        
        //--- Set Up cellXml.deviceWindows
        xViewBindExists(xml.deviceWindows, xObservablePropertyMap<RegisteredDevice, boolean>(this.registeredDevice, (it: RegisteredDevice): boolean => it.hasWindows === true));
        
        //--- Set Up cellXml.deviceAndroid
        xViewBindExists(xml.deviceAndroid, xObservablePropertyMap<RegisteredDevice, boolean>(this.registeredDevice, (it: RegisteredDevice): boolean => it.hasAndroid === true));
        
        //--- Set Up cellXml.deviceIOS
        xViewBindExists(xml.deviceIOS, xObservablePropertyMap<RegisteredDevice, boolean>(this.registeredDevice, (it: RegisteredDevice): boolean => it.hasIos === true));
        
        //--- Set Up cellXml.deviceOther
        xViewBindExists(xml.deviceOther, xObservablePropertyMap<RegisteredDevice, boolean>(this.registeredDevice, (it: RegisteredDevice): boolean => it.others === true));
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action detailFormPasswordResetClick
    //--- Action cellXmlRemoveClick
    //--- Action detailFormAddSiteClick
    //--- Action addSiteClick
    
    public userHistoryClick(): void {
        this.stack.push(new UserActivityHistoryVG(this.session, this.userId));
    }
    public cancelClick(): void {
        this.stack.pop();
    }
    public submitClick(): void {
        const changes: Array<ApiModifier<User>> = [];
        
        if (!(this.user.value.firstName === (this.initial?.firstName ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("first_name", this.user.value.firstName, (it: User): User => it.copy(undefined, undefined, undefined, this.user.value.firstName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        if (!(this.user.value.lastName === (this.initial?.lastName ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("last_name", this.user.value.lastName, (it: User): User => it.copy(undefined, undefined, undefined, undefined, this.user.value.lastName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        if (!(this.user.value.isVip === (this.initial?.isVip ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("is_vip", this.user.value.isVip, (it: User): User => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.user.value.isVip, undefined, undefined)));
        }
        if (!(this.user.value.cellPhone === (this.initial?.cellPhone ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("cell_phone", this.user.value.cellPhone, (it: User): User => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.user.value.cellPhone, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        if (!(this.user.value.email === (this.initial?.email ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("email", this.user.value.email, (it: User): User => it.copy(undefined, undefined, this.user.value.email, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        if (!safeEq(new Set(this.userSchools.value.map((it: School): number => it.id)), new Set(this.initialSchools.value.map((it: UserSchoolMembership): number => it.school)))) {
            // NOTE: this will crash the app.
            // There needs to either be a custom endpoint,
            // or this endpoint needs to be able to handle this
            changes.push(new ApiModifier<User>("schools", new Set(this.userSchools.value.map((it: School): number => it.id))));
        }
        if (!((this.user.value.groups[0] ?? null) === ((): (number | null) => {
            const temp3550 = (this.initial?.groups ?? null);
            if(temp3550 === null) { return null }
            return (temp3550[0] ?? null)
        })())) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("groups", this.user.value.groups, (it: User): User => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.user.value.groups, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        if (!((this.user.value.groups[0] ?? null) === ((): (number | null) => {
            const temp3555 = (this.initial?.groups ?? null);
            if(temp3555 === null) { return null }
            return (temp3555[0] ?? null)
        })())) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("groups", this.user.value.groups, (it: User): User => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.user.value.groups, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        
        const actions = ([] as Array<Observable<void>>);
        
        if (changes.length !== 0) {
            actions.push(this.session.users
                    .patch(this.userId, changes).pipe(rxMap((it: User): void => {
                    return undefined;
            })));
            console.error(`${"AdminuserDVG:"}: ${`${this.userId}`}`);
        }
        
        for (const userschoolmember of this.initialSchools.value) {
            if ((!this.userSchools.value.some((it: School): boolean => it.id === userschoolmember.school))) {
                actions.push(this.session.userSchoolMembership._delete(userschoolmember.id).pipe(rxMap((it: void): void => {
                    return undefined;
                })));
            }
        }
        for (const school of this.userSchools.value) {
            if ((!this.initialSchools.value.some((userschoolmember: UserSchoolMembership): boolean => userschoolmember.school === school.id))) {
                const membership = new UserSchoolMembership(undefined, undefined, this.userId, school.id, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                
                actions.push(this.session.userSchoolMembership.post(membership).pipe(rxMap((it: UserSchoolMembership): void => {
                    return undefined;
                })));
            }
        }
        if (actions.length !== 0) {
            xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<void>>(xSingleWorking<Array<void>>(rxZip(...actions), this.submitting), undefined, (it: Array<void>): void => {
                this.stack.pop();
            }));
            
        } else {
            this.stack.pop();
        }
    }
    
    public deleteClick(active: boolean): void {
        if (active) {
            showDialog(new DialogRequest(new ViewStringResource(R._string.are_you_sure_delete_user), (): void => {
                xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<void>(xSingleWorking<void>(this.session.users._delete(this.userId), this.submitting), undefined, (it: void): void => {
                    this.stack.pop();
                }));
            }));
        } else {
            xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(this.userId, [ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("deleted", null, (it: User): User => it.copy(undefined, null, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))]), this.submitting), (_int: number, message: string): (ViewString | null) => {
                    return _int === 400 ? new ViewStringRaw(message) : new ViewStringResource(R._string.error_bad_request);
                }, (it: User): void => {
                    this.stack.pop();
            }));
            
        }
    }
    
    //--- Body End
}
