// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertGuidance.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { AlertType } from './AlertType'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertGuidance
export class AlertGuidance implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly alertType: ForeignKey<AlertType>;
    public readonly title: string;
    public readonly plan: (string | null);
    public constructor(id: number = getNO_ID(), alertType: ForeignKey<AlertType>, title: string, plan: (string | null) = null) {
        this.id = id;
        this.alertType = alertType;
        this.title = title;
        this.plan = plan;
    }
    public static fromJson(obj: any): AlertGuidance { return new AlertGuidance(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["alert_type"], [Number]) as number,
        parseJsonTyped(obj["title"], [String]) as string,
        parseJsonTyped(obj["plan"], [String]) as (string | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        alert_type: this.alertType,
        title: this.title,
        plan: this.plan
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.alertType);
        hash = 31 * hash + hashAnything(this.title);
        hash = 31 * hash + hashAnything(this.plan);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertGuidance && safeEq(this.id, other.id) && safeEq(this.alertType, other.alertType) && safeEq(this.title, other.title) && safeEq(this.plan, other.plan) }
    public toString(): string { return `AlertGuidance(id=${this.id}, alertType=${this.alertType}, title=${this.title}, plan=${this.plan})` }
    public copy(id: number = this.id, alertType: ForeignKey<AlertType> = this.alertType, title: string = this.title, plan: (string | null) = this.plan): AlertGuidance { return new AlertGuidance(id, alertType, title, plan); }
    
    
}
export namespace AlertGuidance {
    //! Declares com.tresitgroup.android.tresit.model.AlertGuidance.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public forAlertType(alertTypeId: ForeignKey<AlertType>): ApiFilter<AlertGuidance> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertGuidance>(`alert_type=${alertTypeId}`, undefined, (it: AlertGuidance): boolean => it.alertType === alertTypeId);
        }
        public forAlertTypes(alertTypes: Array<ForeignKey<AlertType>>): ApiFilter<AlertGuidance> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertGuidance>(`alert_types=${alertTypes.map((it: number): string => it.toString()).join(", ")}`, undefined, (it: AlertGuidance): boolean => alertTypes.some((x) => safeEq(it.alertType, x)));
        }
    }
}