// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/PartSchoolSelectVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { xAutoCompleteTextViewBindList } from 'butterfly-web/dist/observables/binding/AutoCompleteTextView.binding'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { PartSchoolSelectXml } from '../../layout/PartSchoolSelectXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { logVG } from '../../util/LogVG'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.PartSchoolSelectVG
export class PartSchoolSelectVG extends ViewGenerator {
    public readonly session: SessionApi;
    public constructor(session: SessionApi) {
        super();
        this.session = session;
        this.query = new StandardObservableProperty<string>("", undefined);
        this.selectedAction = (it: School): void => {};
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.PartSchoolSelectVG.title
    public get title(): string { return "Part School Select"; }
    
    
    public readonly query: MutableObservableProperty<string>;
    
    
    public selectedAction: ((a: School) => void);
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PartSchoolSelectXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.schoolFilter
        this.inlineGenerate(xml);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public inlineGenerate(xml: PartSchoolSelectXml): void {
        xEditTextBindString(xml.schoolFilter, this.query);
        xAutoCompleteTextViewBindList<School>(xml.schoolFilter, xObservablePropertyMap<(Array<School> | null), Array<School>>(xObservablePropertyFlatMap<string, (Array<School> | null)>(this.query, (it: string): ObservableProperty<(Array<School> | null)> => this.session.allSchools.observableListSimple(School.Companion.INSTANCE.textSearch(it), undefined, undefined, undefined, undefined, undefined)), (it: (Array<School> | null)): Array<School> => ((): (Array<School> | null) => {
                        const temp3913 = it;
                        if(temp3913 === null) { return null }
                        const temp3914 = (school: School): (string | null) => school.name;
                        return temp3913.slice().sort((a, b) => safeCompare(temp3914(a), temp3914(b)))
            })() ?? []), (it: School): string => it.name, (it: School): void => {
                this.query.value = "";
                this.selectedAction(it);
        });
    }
    
    //--- Init
    
    
    
    public bindSelect(action:  ((a: School) => void)): void {
        this.selectedAction = action;
    }
    
    
    //--- Body End
}
