//
// OnStatusButtonPressedXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './on_status_button_pressed.html'
//! Declares com.tresitgroup.android.tresit.layouts.OnStatusButtonPressedXml
export class OnStatusButtonPressedXml {
    xmlRoot!: HTMLElement
    alertStartedView!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.alertStartedView = getViewById<HTMLDivElement>(view, "alertStartedView")
        return view
    }
}
