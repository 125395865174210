// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/SelectAlertVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { ComponentRowAlertTypeXml } from '../../layout/ComponentRowAlertTypeXml'
import { tap as rxTap } from 'rxjs/operators'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { DisplayMetrics } from 'butterfly-web/dist/views/DisplayMetrics'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { Alert } from '../../model/Alert'
import { SelectAlertXml } from '../../layout/SelectAlertXml'
import { setViewVisibility, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { ApiModifier } from '../../api/ApiModifier'
import { StartingAlertVG } from './StartingAlertVG'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { AlertType } from '../../model/AlertType'
import { cMax, cMin } from 'butterfly-web/dist/kotlin/Comparable'
import { R } from '../../R'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { AlertStatusActiveVG } from './AlertStatusActiveVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.alert.SelectAlertVG
export class SelectAlertVG extends ViewGenerator {
    public readonly alertId: ForeignKey<Alert>;
    public readonly alertStack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly isInternal: boolean;
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(alertId: ForeignKey<Alert>, alertStack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>, isInternal: boolean, schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.alertId = alertId;
        this.alertStack = alertStack;
        this.dialog = dialog;
        this.isInternal = isInternal;
        this.schoolId = schoolId;
        this.session = session;
        this.isSilent = new StandardObservableProperty<boolean>(false, undefined);
        this.initial = null;
        this.titleText = new StandardObservableProperty<string>(`${this.alertId >= 0 ? "Modify" : "Select"} ${this.isInternal ? "Drill" : "Alert"} Type`, undefined);
        this.swapDrillOrAlert = new StandardObservableProperty<boolean>(false, undefined);
        this.isLoading = new StandardObservableProperty<boolean>(false, undefined);
        if (this.alertId >= 0) {
            xSingleCallDisplayingError<Alert>(this.session.alerts.get(this.alertId), undefined, (alert: Alert): void => {
                this.initial = alert;
                this.isSilent.value = alert.silent;
            });
        };
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.SelectAlertVG.title
    public get title(): string { return `Select ${this.isInternal ? "Drill" : "Alert"}`; }
    
    
    public readonly isSilent: MutableObservableProperty<boolean>;
    
    public initial: (Alert | null);
    
    public titleText: MutableObservableProperty<string>;
    
    public swapDrillOrAlert: MutableObservableProperty<boolean>;
    
    
    public readonly isLoading: StandardObservableProperty<boolean>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new SelectAlertXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.selectAlertOrDrill
        xTextViewBindString(xml.selectAlertOrDrill, this.titleText);
        
        //--- Set Up xml.back (overwritten on flow generation)
        xViewOnClick(xml.back, undefined, (): void => {
            this.backClick();
        });
        
        
        
        //--- Set Up xml.internalAlert
        setViewVisibility(xml.internalAlert, (this.alertId >= 0 ? "visible" : "gone"));
        xViewBindExists(xml.internalAlert, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanEscalateAlert ?? null) === true));
        xCompoundButtonBind(xml.internalAlert, this.swapDrillOrAlert);
        if (this.isInternal) {
            setViewText(xml.internalAlert, R._string.change_to_alert);
        } else {
            setViewText(xml.internalAlert, R._string.change_to_drill);
        }
        
        if (this.initial !== null) {
            this.isSilent.value = (this.initial?.silent ?? null) ?? false;
            
        } else {
            this.isSilent.value = this.isInternal;
            
        }
        //--- Set Up xml.silentAlert
        xCompoundButtonBind(xml.silentAlert, this.isSilent);
        
        
        xViewFlipperBindLoading(xml.alertTypeLoader, this.isLoading, undefined);
        
        //--- Set Up xml.alertTypeLoader
        xViewFlipperBindLoading(xml.alertTypeLoader, xObservablePropertyMap<(Array<AlertType> | null), boolean>(this.session.alertTypes.observableListSimple(AlertType.Companion.INSTANCE.forSchool(this.schoolId), undefined, undefined, undefined, undefined, undefined), (it: (Array<AlertType> | null)): boolean => it === null), undefined);
        
        //--- Set Up xml.list
        const dpWidth = DisplayMetrics.INSTANCE.widthPixels / DisplayMetrics.INSTANCE.density;
        
        Object.assign(xml.list.style, { display: "grid", gridTemplateColumns: `repeat(${cMax(cMin(Math.floor((dpWidth / 200)), 4), 2)}, 1fr)`, alignContent: "start", gridAutoRows: "min-content" });
        xRecyclerViewBind<AlertType>(xml.list, xObservablePropertyMap<(Array<AlertType> | null), Array<AlertType>>(this.session.alertTypes.observableListSimple(ApiFilter.Companion.INSTANCE.all<AlertType>(AlertType.Companion.INSTANCE.forSchool(this.schoolId), AlertType.Companion.INSTANCE.forAlert()), undefined, undefined, undefined, undefined, undefined), (it: (Array<AlertType> | null)): Array<AlertType> => it ?? []), new AlertType(undefined, undefined, (-1), "Emergency", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<AlertType>): HTMLElement => {
            //--- Make Subview For xml.list (overwritten on flow generation)
            const cellXml = new ComponentRowAlertTypeXml();
            
            const cellView = cellXml.setup(dependency);
            
            //                cellXml.emergency.bindExists(observable.map { it.urgency =="emergency"})
            
            //--- Set Up cellXml.icon (overwritten on flow generation)
            
            //--- Set Up cellXml.name
            
            //--- Set Up cellXml.leftAlert
            xViewOnClick(cellXml.xmlRoot, undefined, (): void => {
                //                    this.listClick(
                    //                        alertTypeId = observable.value.id,
                    //                        requiresAdminApproval = observable.value.requiresAdminApproval
                //                    )
                
                xSingleCallDisplayingError<User>(this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.removeLocation]), undefined, (it: User): void => {
                    const isGroup = ((): (boolean | null) => {
                        const temp4074 = observable.value.groups;
                        if(temp4074 === null) { return null }
                        return temp4074.length !== 0
                    })() === true;
                    
                    this.listClick(observable.value.id, observable.value.requiresAdminApproval, isGroup);
                });
            });
            
            //--- Set Up cellXml.icon
            xImageViewBindImage(cellXml.icon, xObservablePropertyMap<AlertType, (Image | null)>(observable, (it: AlertType): (Image | null) => ((): (Image | null) => {
                const temp4075 = it.icon;
                if(temp4075 !== null) {
                    return xStringAsImage(temp4075)
                } else { return null }
            })()));
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<AlertType, string>(observable, (it: AlertType): string => it.name));
            console.log(`name:${xObservablePropertyMap<AlertType, string>(observable, (it: AlertType): string => it.name).value},,,,urgency${xObservablePropertyMap<AlertType, string>(observable, (it: AlertType): string => it.urgency).value}`);
            
            
            //--- End Make Subview For xml.list
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    
    //--- Actions
    
    public backClick(): void {
        this.alertStack.pop();
    }
    
    
    public listClick(alertTypeId: ForeignKey<AlertType>, requiresAdminApproval: boolean, isGroup: boolean): void {
        if (this.alertId === (-1)) {
            this.alertStack.push(new StartingAlertVG(new Alert(undefined, this.schoolId, alertTypeId, undefined, this.session.session.userId, undefined, undefined, this.isSilent.value, ((): boolean => {
                if (this.swapDrillOrAlert.value) {
                    return (!this.isInternal);
                } else {
                    return this.isInternal;
                }
            })(), undefined), this.alertStack, undefined, this.dialog, this.schoolId, this.session, requiresAdminApproval, isGroup));
        } else {
            const changes: Array<ApiModifier<Alert>> = [];
            
            if (!(alertTypeId === (this.initial?.name ?? null))) {
                changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<Alert>("name", alertTypeId, (it: Alert): Alert => it.copy(undefined, undefined, alertTypeId, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
            }
            if (this.swapDrillOrAlert.value) {
                changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<Alert>("internal", (!this.isInternal), (it: Alert): Alert => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (!this.isInternal), undefined)));
            }
            //todo
            if (!(this.isSilent.value === (this.initial?.silent ?? null))) {
                changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<Alert>("silent", this.isSilent.value, (it: Alert): Alert => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.isSilent.value, undefined, undefined)));
            }
            this.patchAlert(changes, new Alert(this.alertId, this.schoolId, alertTypeId, undefined, this.session.session.userId, undefined, undefined, this.isSilent.value, this.isInternal, undefined));
            //            this.alertStack.push(
                //                StartingAlertVG(
                    //                    alert = Alert(
                        //                        id = this.alertId,
                        //                        school = schoolId,
                        //                        name = alertTypeId,
                        //                        initiator = session.session.userId,
                        //                        silent = isSilent.value,
                        //                        internal = isInternal
                    //                    ),
                    //                    alertStack = this.alertStack,
                    //                    session = this.session,
                    //                    changes = changes,
                    //                    dialog = this.dialog,
                    //                    schoolId = this.schoolId,
                    //                    requiresAdminApprove = requiresAdminApproval
                //                )
            //            )
        }
        
    }
    
    
    public patchAlert(changes: Array<ApiModifier<Alert>>, alert: Alert): void {
        post((): void => {
            ((it: Array<ApiModifier<Alert>>): void => {
                xSingleCallDisplayingError<Alert>(xSingleWorking<Alert>(this.session.alerts.patch(alert.id, it).pipe(rxTap(undefined, (it: any): void => {
                                    this.alertStack.pop();
                    })), this.isLoading), undefined, (it: Alert): void => {
                        this.alertStack.reset(new AlertStatusActiveVG(alert.id, this.alertStack, this.dialog, this.schoolId, this.session));
                });
            })(changes);
        });
    }
    
    //--- Body End
}
