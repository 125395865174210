// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminUserAddVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { Observable, ObservableInput, SubscriptionLike, zip as rxZip } from 'rxjs'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { FormAddUserVG } from './FormAddUserVG'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { flatMap as rxFlatMap } from 'rxjs/operators'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { AdminUserAddXml } from '../../layout/AdminUserAddXml'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminUserAddVG
export class AdminUserAddVG extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.session = session;
        this.stack = stack;
        this.newUser = new StandardObservableProperty<User>(new User(undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), undefined);
        this.userSchools = new StandardObservableProperty<Array<School>>([], undefined);
        this.submitting = new StandardObservableProperty<boolean>(false, undefined);
        this.form = new FormAddUserVG(this.newUser, this.session, this.userSchools);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminUserAddVG.title
    public get title(): string { return "Admin User Add"; }
    
    
    public readonly newUser: MutableObservableProperty<User>;
    
    public readonly userSchools: MutableObservableProperty<Array<School>>;
    
    public readonly submitting: MutableObservableProperty<boolean>;
    
    public readonly form: FormAddUserVG;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminUserAddXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.addForm.xmlRoot
        //--- Set Up xml.addForm.title
        //--- Set Up xml.addForm.firstName
        //--- Set Up xml.addForm.lastName
        //--- Set Up xml.addForm.cellPhone
        //--- Set Up xml.addForm.email
        //--- Set Up xml.addForm.password
        //--- Set Up xml.addForm.confirmPassword
        //--- Set Up xml.addForm.userType
        //--- Set Up xml.addForm.userSchoolsLoading
        //--- Set Up xml.addForm.userSchools
        //--- Make Subview For xml.addForm.userSchools
        //--- Set Up cellXml.root
        //--- Set Up cellXml.label
        //--- Set Up cellXml.remove
        //--- End Make Subview For xml.addForm.userSchools
        //--- Set Up xml.addForm.newSchool
        //--- Set Up xml.addForm.newSchoolSelect.xmlRoot
        //--- Set Up xml.addForm.newSchoolSelect.schoolFilter
        //--- Set Up xml.addForm.addSite
        this.form.generateFromXml(dependency, xml.addForm);
        
        //--- Set Up xml.submitWorking
        xViewFlipperBindLoading(xml.submitWorking, this.submitting, undefined);
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.submit (overwritten on flow generation)
        xViewOnClick(xml.submit, undefined, (): void => {
            this.submitClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action cellXmlRemoveClick
    //--- Action addFormAddSiteClick
    //--- Action addSiteClick
    
    public cancelClick(): void {
        this.stack.pop();
    }
    public submitClick(): void {
        if (this.userSchools.value.length !== 0) {
            xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<UserSchoolMembership>>(xSingleWorking<Array<UserSchoolMembership>>(this.session.users.post(this.newUser.value).pipe(rxFlatMap((it: User): ObservableInput<Array<UserSchoolMembership>> => {
                                const submissions: Array<Observable<UserSchoolMembership>> = [];
                                
                                for (const school of this.userSchools.value) {
                                    const membership = new UserSchoolMembership(undefined, undefined, it.id, school.id, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                                    
                                    submissions.push(this.session.userSchoolMembership.post(membership));
                                }
                                return rxZip(...submissions);
                })), this.submitting), undefined, (it: Array<UserSchoolMembership>): void => {
                    this.stack.pop();
            }));
        } else {
            showDialogAlert(new ViewStringResource(R._string.select_schools));
        }
    }
    
    //--- Body End
}
