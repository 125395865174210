// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/GuidanceDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { Image, ImageRemoteUrl } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { logVG } from '../../util/LogVG'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { GuidanceDetailXml } from '../../layout/GuidanceDetailXml'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { AlertGuidance } from '../../model/AlertGuidance'
import { AlertType } from '../../model/AlertType'
import { ComponentRowGuidanceDetailXml } from '../../layout/ComponentRowGuidanceDetailXml'

//! Declares com.tresitgroup.android.tresit.vg.settings.GuidanceDetailVG
export class GuidanceDetailVG extends ViewGenerator {
    public readonly alertTypeId: ForeignKey<AlertType>;
    public readonly session: SessionApi;
    public constructor(alertTypeId: ForeignKey<AlertType>, session: SessionApi) {
        super();
        this.alertTypeId = alertTypeId;
        this.session = session;
        this.alertType = xObservablePropertyMap<(AlertType | null), (AlertType | null)>(this.session.alertTypes.observable(this.alertTypeId), (it: (AlertType | null)): (AlertType | null) => it);
    }
    
    
    
    public readonly alertType: ObservableProperty<(AlertType | null)>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.GuidanceDetailVG.title
    public get title(): string { return "Guidance Detail"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new GuidanceDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        
        //--- Set Up xml.alertTypeIcon
        xImageViewBindImage(xml.alertTypeIcon, xObservablePropertyMap<(AlertType | null), (Image | null)>(this.alertType, (it: (AlertType | null)): (Image | null) => ((): (ImageRemoteUrl | null) => {
            if (it !== null) {
                return ((): (ImageRemoteUrl | null) => {
                    if (it!.icon !== null && !(it!.icon === "")) {
                        return new ImageRemoteUrl(it!.icon!);
                    } else {
                        return null;
                    }
                })()
            } else {
                return null;
            }
        })()));
        
        //--- Set Up xml.alertTypeTitle
        xTextViewBindString(xml.alertTypeTitle, xObservablePropertyMap<(AlertType | null), string>(this.session.alertTypes.observable(this.alertTypeId), (it: (AlertType | null)): string => (it?.name ?? null) ?? "Alert Type"));
        
        //--- Set Up xml.guidances
        xLinearLayoutBind<AlertGuidance>(xml.guidances, xObservablePropertyMap<(Array<AlertGuidance> | null), Array<AlertGuidance>>(this.session.alertGuidances.observableListSimple(AlertGuidance.Companion.INSTANCE.forAlertType(this.alertTypeId), undefined, undefined, undefined, undefined, undefined), (it: (Array<AlertGuidance> | null)): Array<AlertGuidance> => it ?? []), new AlertGuidance(0, (-1), "", undefined), (observable: ObservableProperty<AlertGuidance>): HTMLElement => {
            //--- Make Subview For xml.guidances (overwritten on flow generation)
            const cellXml = new ComponentRowGuidanceDetailXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.guidanceTitle
            xTextViewBindString(cellXml.guidanceTitle, xObservablePropertyMap<AlertGuidance, string>(observable, (it: AlertGuidance): string => it.title));
            
            //--- Set Up cellXml.guidancePlan
            xTextViewBindString(cellXml.guidancePlan, xObservablePropertyMap<AlertGuidance, string>(observable, (it: AlertGuidance): string => it.plan ?? "No plan specified"));
            
            //--- End Make Subview For xml.guidances (overwritten on flow generation)
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
