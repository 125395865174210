//
// BoardXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/board.html'
import htmlForDefault from './board.html'
//! Declares com.tresitgroup.android.tresit.layouts.BoardXml
export class BoardXml {
    xmlRoot!: HTMLElement
    mainContent!: HTMLDivElement
    filter!: HTMLInputElement
    postsLoading!: HTMLDivElement
    posts!: HTMLDivElement
    dummy!: HTMLElement
    empty!: HTMLElement
    loadHtmlString(): string {
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [700])
        this.xmlRoot = view
        this.mainContent = getViewById<HTMLDivElement>(view, "mainContent")
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.postsLoading = getViewById<HTMLDivElement>(view, "postsLoading")
        this.posts = getViewById<HTMLDivElement>(view, "posts")
        this.dummy = getViewById<HTMLElement>(view, "dummy")
        this.empty = getViewById<HTMLElement>(view, "empty")
        return view
    }
}
