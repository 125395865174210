// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/CampusMapSvgVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { Floor } from '../../model/Floor'
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { tryCastClass } from 'butterfly-web/dist/Kotlin'
import { xResponseReadText, xSingleReadText } from 'butterfly-web/dist/net/RxHttpAssist'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { Observable, ObservableInput, SubscriptionLike, of as rxOf } from 'rxjs'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { SvgPaints } from './Paints'
import { flatMap as rxFlatMap, tap as rxTap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'
import { School } from '../../model/School'
import { SvgDelegate } from './SvgDelegate'
import { xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xStringSubstringAfter, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xSessionApiBuildingStatusCached } from './statuses'
import { CacheEdge } from '../../api/CacheEdge'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { LiveApi } from '../../api/LiveApi'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { CampusMapSvgXml } from '../../layout/CampusMapSvgXml'
import { HasBackAction, HasBackActionDefaults } from 'butterfly-web/dist/views/HasBackAction'
import { Room } from '../../model/Room'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { BuildingMapSvgVG } from './BuildingMapSvgVG'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.map.CampusMapSvgVG
export class CampusMapSvgVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly mapUrl: string;
    public readonly onRoomTap:  ((a: ForeignKey<Room>) => void);
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(mapUrl: string, onRoomTap:  ((a: ForeignKey<Room>) => void), schoolId: ForeignKey<School>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.mapUrl = mapUrl;
        this.onRoomTap = onRoomTap;
        this.schoolId = schoolId;
        this.session = session;
        this.stack = stack;
        this.loadingMap = new StandardObservableProperty<boolean>(false, undefined);
        this.map = new StandardObservableProperty<(string | null)>(null, undefined);
        this.ongoingAlerts = this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(this.schoolId), undefined, undefined, undefined, undefined, undefined);
        this.hasActiveAlert = xObservablePropertyMap<(Array<Alert> | null), boolean>(this.ongoingAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4565 = it;
            if(temp4565 === null) { return null }
            return temp4565.length === 0
        })() === false);
        this.mapReloadClick();
    }
    
    
    //! Declares com.tresitgroup.android.tresit.vg.map.CampusMapSvgVG.title
    public get title(): string { return "Campus Map Svg"; }
    
    
    public readonly loadingMap: StandardObservableProperty<boolean>;
    
    public readonly map: StandardObservableProperty<(string | null)>;
    
    public readonly ongoingAlerts: CacheEdge<Alert>;
    
    public readonly hasActiveAlert: ObservableProperty<boolean>;
    
    
    public myBuildingId(): ObservableProperty<(ForeignKey<Building> | null)> {
        return xObservablePropertyMap<(Floor | null), (number | null)>(xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyMap<(User | null), (number | null)>(this.session.me, (it: (User | null)): (number | null) => (it?.currentLocation ?? null)), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it)), (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it)), (it: (Floor | null)): (number | null) => (it?.building ?? null));
    }
    
    public mapReloadClick(): void {
        this.map.value = null;
        const withoutProtocol = xStringSubstringAfter(this.mapUrl, ':', undefined);
        
        const protocol = ((): (string | null) => {
            const temp4566 = ((tryCastClass<LiveApi>(this.session.rawApi, LiveApi))?.httpUrl ?? null);
            if(temp4566 !== null) {
                return xStringSubstringBefore(temp4566, ':', undefined)
            } else { return null }
        })() ?? "https";
        
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<string>(xSingleWorking<string>(xSingleReadText(HttpClient.INSTANCE.call(`${protocol}:${withoutProtocol}`, undefined, undefined, undefined, undefined)).pipe(rxTap((it: string): void => {
                            console.debug(`${"CampusMapSVG"}: ${`Got ${it}`}`);
                })).pipe(rxTap(undefined, (it: any): void => {
                    if (it instanceof HttpResponseException) {
                        xResponseReadText((it as HttpResponseException).response).subscribe((it: string): void => {
                            console.debug(`${"CampusMapSVG"}: ${`Got error ${it}`}`);
                        }, (it: any): void => {});
                    }
            })), this.loadingMap), undefined, (it: string): void => {
                this.map.value = it;
        }));
    }
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new CampusMapSvgXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Properties
        const myBuildingId = this.myBuildingId();
        
        
        //--- Set Up xml.title (overwritten on flow generation)
        
        //--- Set Up xml.map
        const paints = new SvgPaints(dependency);
        
        const dg = new SvgDelegate(this.map, (it: number): Array<Paint> => ((): Array<Paint> => {
                    if (this.hasActiveAlert.value) {
                        const status = xSessionApiBuildingStatusCached(this.session, this.schoolId, it).value;
                        
                        const base = status !== null ? paints.forCombinedStatus(status!) : ([] as Array<Paint>);
                        
                        return ((): Array<Paint> => {
                            if (myBuildingId.value === it) {
                                return base.concat(([paints.highlight()] as Array<Paint>));
                            } else {
                                return base;
                            }
                        })()
                    } else { return [] }
            })(), (buildingId: number): void => {
                xSingleCallDisplayingError<Array<Room>>(this.session.floors.getListSimple(Floor.Companion.INSTANCE.forBuilding(buildingId), undefined, undefined, undefined, undefined).pipe(rxFlatMap((it: Array<Floor>): ObservableInput<Array<Room>> => ((): (Observable<Array<Room>> | null) => {
                                    const temp4582 = ((it.length == 1 ? it[0] : null)?.id ?? null);
                                    if(temp4582 === null) { return null }
                                    return ((it: number): Observable<Array<Room>> => this.session.rooms.getListSimple(Room.Companion.INSTANCE.forFloor(it), undefined, undefined, undefined, undefined))(temp4582)
                    })() ?? rxOf([]))), undefined, (it: Array<Room>): void => {
                        if (it.length === 1) {
                            this.onRoomTap(it[0].id);
                        } else {
                            this.stack.push(new BuildingMapSvgVG(buildingId, this.onRoomTap, this.schoolId, this.session, this.stack));
                        }
                });
        });
        
        customViewSetDelegate(xml.map, dg);
        //This keeps the data flowing
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Room> | null)>(this.session.rooms.observableListSimple(Room.Companion.INSTANCE.forSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), undefined, undefined, undefined), xViewRemovedGet(xml.map));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Floor> | null)>(this.session.floors.observableListSimple(Floor.Companion.INSTANCE.forSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), undefined, undefined, undefined), xViewRemovedGet(xml.map));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Building> | null)>(this.session.buildings.observableListSimple(Building.Companion.INSTANCE.belongingToSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), undefined, undefined, undefined), xViewRemovedGet(xml.map));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(number | null)>(myBuildingId, undefined, undefined, (it: (number | null)): void => {
            console.log(`My building id is ${it}`);
        }), xViewRemovedGet(xml.map));
        
        //--- Set Up xml.mapReload
        xViewOnClick(xml.mapReload, undefined, (): void => {
            this.mapReloadClick();
        });
        xViewBindVisible(xml.mapReload, xObservablePropertyCombine<boolean, (string | null), boolean>(this.loadingMap, this.map, (load: boolean, geo: (string | null)): boolean => (!load) && geo === null));
        
        //--- Set Up xml.zoomIn
        xml.zoomIn.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            dg.zoom(1.333333);
        };
        
        //--- Set Up xml.zoomOut
        xml.zoomOut.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            dg.zoom(0.75);
        };
        
        //--- Set Up xml.mapLoading
        xViewFlipperBindLoading(xml.mapLoading, this.loadingMap, undefined);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action zoomInClick
    //--- Action zoomOutClick
    
    
    //--- Body End
    public onBackPressed(): boolean { return HasBackActionDefaults.onBackPressed(this); }
}
