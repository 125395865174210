//
// PinEntryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FancyBackgroundDelegate } from '../vg/FancyBackgroundDelegate'
import htmlForDefault from './pin_entry.html'
//! Declares com.tresitgroup.android.tresit.layouts.PinEntryXml
export class PinEntryXml {
    xmlRoot!: HTMLElement
    background!: HTMLCanvasElement
    pin!: HTMLInputElement
    logOut!: HTMLButtonElement
    backgroundDelegate!: FancyBackgroundDelegate
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.background = getViewById<HTMLCanvasElement>(view, "background")
        this.pin = getViewById<HTMLInputElement>(view, "pin")
        this.logOut = getViewById<HTMLButtonElement>(view, "logOut")
        if(this.background){ this.backgroundDelegate = new FancyBackgroundDelegate(); customViewSetDelegate(this.background, this.backgroundDelegate); }
        return view
    }
}
