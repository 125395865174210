// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Device.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { Floor } from './Floor'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { RemoteFileRef } from './RemoteFileRef'
import { ForeignKey } from './ForeignKey'
import { AlertType } from './AlertType'
import { Building } from './Building'
import { HasId, getNO_ID } from './HasId'
import { Room } from './Room'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Device
export class Device implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public key: string;
    public readonly deviceType: string;
    public readonly ownerType: string;
    public readonly ownerId: number;
    public readonly alertType: (ForeignKey<AlertType> | null);
    public readonly school: (ForeignKey<School> | null);
    public readonly building: (ForeignKey<Building> | null);
    public readonly floor: (ForeignKey<Floor> | null);
    public readonly room: (ForeignKey<Room> | null);
    public readonly longitude: (number | null);
    public readonly latitude: (number | null);
    public readonly image: (RemoteFileRef | null);
    public constructor(id: number = getNO_ID(), key: string = "", deviceType: string, ownerType: string = "", ownerId: number = getNO_ID(), alertType: (ForeignKey<AlertType> | null) = null, school: (ForeignKey<School> | null) = null, building: (ForeignKey<Building> | null) = null, floor: (ForeignKey<Floor> | null) = null, room: (ForeignKey<Room> | null) = null, longitude: (number | null) = null, latitude: (number | null) = null, image: (RemoteFileRef | null) = null) {
        this.id = id;
        this.key = key;
        this.deviceType = deviceType;
        this.ownerType = ownerType;
        this.ownerId = ownerId;
        this.alertType = alertType;
        this.school = school;
        this.building = building;
        this.floor = floor;
        this.room = room;
        this.longitude = longitude;
        this.latitude = latitude;
        this.image = image;
    }
    public static fromJson(obj: any): Device { return new Device(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["key"], [String]) as string,
        parseJsonTyped(obj["device_type"], [String]) as string,
        parseJsonTyped(obj["owner_type"], [String]) as string,
        parseJsonTyped(obj["owner_id"], [Number]) as number,
        parseJsonTyped(obj["alert_type"], [Number]) as (number | null),
        parseJsonTyped(obj["school"], [Number]) as (number | null),
        parseJsonTyped(obj["building"], [Number]) as (number | null),
        parseJsonTyped(obj["floor"], [Number]) as (number | null),
        parseJsonTyped(obj["room"], [Number]) as (number | null),
        parseJsonTyped(obj["longitude"], [Number]) as (number | null),
        parseJsonTyped(obj["latitude"], [Number]) as (number | null),
        parseJsonTyped(obj["image"], [String]) as (string | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        key: this.key,
        device_type: this.deviceType,
        owner_type: this.ownerType,
        owner_id: this.ownerId,
        alert_type: this.alertType,
        school: this.school,
        building: this.building,
        floor: this.floor,
        room: this.room,
        longitude: this.longitude,
        latitude: this.latitude,
        image: this.image
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.key);
        hash = 31 * hash + hashAnything(this.deviceType);
        hash = 31 * hash + hashAnything(this.ownerType);
        hash = 31 * hash + hashAnything(this.ownerId);
        hash = 31 * hash + hashAnything(this.alertType);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.building);
        hash = 31 * hash + hashAnything(this.floor);
        hash = 31 * hash + hashAnything(this.room);
        hash = 31 * hash + hashAnything(this.longitude);
        hash = 31 * hash + hashAnything(this.latitude);
        hash = 31 * hash + hashAnything(this.image);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Device && safeEq(this.id, other.id) && safeEq(this.key, other.key) && safeEq(this.deviceType, other.deviceType) && safeEq(this.ownerType, other.ownerType) && safeEq(this.ownerId, other.ownerId) && safeEq(this.alertType, other.alertType) && safeEq(this.school, other.school) && safeEq(this.building, other.building) && safeEq(this.floor, other.floor) && safeEq(this.room, other.room) && safeEq(this.longitude, other.longitude) && safeEq(this.latitude, other.latitude) && safeEq(this.image, other.image) }
    public toString(): string { return `Device(id=${this.id}, key=${this.key}, deviceType=${this.deviceType}, ownerType=${this.ownerType}, ownerId=${this.ownerId}, alertType=${this.alertType}, school=${this.school}, building=${this.building}, floor=${this.floor}, room=${this.room}, longitude=${this.longitude}, latitude=${this.latitude}, image=${this.image})` }
    public copy(id: number = this.id, key: string = this.key, deviceType: string = this.deviceType, ownerType: string = this.ownerType, ownerId: number = this.ownerId, alertType: (ForeignKey<AlertType> | null) = this.alertType, school: (ForeignKey<School> | null) = this.school, building: (ForeignKey<Building> | null) = this.building, floor: (ForeignKey<Floor> | null) = this.floor, room: (ForeignKey<Room> | null) = this.room, longitude: (number | null) = this.longitude, latitude: (number | null) = this.latitude, image: (RemoteFileRef | null) = this.image): Device { return new Device(id, key, deviceType, ownerType, ownerId, alertType, school, building, floor, room, longitude, latitude, image); }
    
    
    
}
export namespace Device {
    //! Declares com.tresitgroup.android.tresit.model.Device.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public belongingToSchool(school: ForeignKey<School>): ApiFilter<Device> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<Device>(`school=${school}`, undefined, (it: Device): boolean => it.school === school);
        }
    }
}