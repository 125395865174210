// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/PinDeviceVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { xActivityAccessShowAuthPrompt } from './PinAuth'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../api/SessionApi'
import { PinVG } from './PinVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { LandingScreenVG } from './auth/LandingScreenVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xSessionApiLogOut } from '../model/Session.api'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { PinDeviceXml } from '../layout/PinDeviceXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { logVG } from '../util/LogVG'

//! Declares com.tresitgroup.android.tresit.vg.PinDeviceVG
export class PinDeviceVG extends ViewGenerator implements HasBackAction, PinVG {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public static implementsInterfaceComTresitgroupAndroidTresitVgPinVG = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.session = session;
    }
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.PinDeviceVG.title
    public get title(): string { return "Pin Device"; }
    
    
    public onBackPressed(): boolean {
        return true;
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PinDeviceXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        
        //--- Set Up xml.background
        xml.backgroundDelegate.setup(dependency);
        
        //--- Set Up xml.unlock
        xViewOnClick(xml.unlock, undefined, (): void => {
            xActivityAccessShowAuthPrompt(dependency, (it: boolean): void => {
                if (it) { this.dialog.dismiss() }
            });
        });
        post((): void => {
            xActivityAccessShowAuthPrompt(dependency, (it: boolean): void => {
                if (it) { this.dialog.dismiss() }
            });
        });
        
        //--- Set Up xml.logOut (overwritten on flow generation)
        xViewOnClick(xml.logOut, undefined, (): void => {
            this.logOutClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public logOutClick(): void {
        xSessionApiLogOut(this.session);
        this.dialog.reset(new ViewGenerator.Default());
        this.dialog.dismiss();
        this.root.reset(new LandingScreenVG(this.dialog, this.root, this.root));
    }
    
    //--- Body End
}
