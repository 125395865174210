//
// ComponentRowMessageSystemXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_message_system.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowMessageSystemXml
export class ComponentRowMessageSystemXml {
    xmlRoot!: HTMLElement
    alertTypeIcon!: HTMLImageElement
    backgroundView!: HTMLDivElement
    message!: HTMLElement
    name!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.alertTypeIcon = getViewById<HTMLImageElement>(view, "alert_type_icon")
        this.backgroundView = getViewById<HTMLDivElement>(view, "background_view")
        this.message = getViewById<HTMLElement>(view, "message")
        this.name = getViewById<HTMLElement>(view, "name")
        return view
    }
}
