// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/ManageAlertsVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { ApiPartialQuery } from '../../api/ApiPartialQuery'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ManageAlertsXml } from '../../layout/ManageAlertsXml'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { ApiModifier } from '../../api/ApiModifier'
import { ComponentRowAlertCheckboxXml } from '../../layout/ComponentRowAlertCheckboxXml'
import { School } from '../../model/School'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { AlertType } from '../../model/AlertType'

//! Declares com.tresitgroup.android.tresit.vg.settings.ManageAlertsVG
export class ManageAlertsVG extends ViewGenerator {
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.schoolId = schoolId;
        this.session = session;
        this.isLoading = new StandardObservableProperty<boolean>(false, undefined);
        this.subscriptionStates = new StandardObservableProperty<Array<AlertType>>([], undefined);
        this.session.rawApi.alertTypes(this.session.session).getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<AlertType>(AlertType.Companion.INSTANCE.forSchoolNotDeleted(this.schoolId, undefined), undefined, undefined, undefined)).subscribe((onSuccess: Array<AlertType>): void => {
            this.subscriptionStates.value = onSuccess;
            console.log("api call");
        }, undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.ManageAlertsVG.title
    public get title(): string { return "Manage Alerts"; }
    
    
    public isLoading: MutableObservableProperty<boolean>;
    
    public userSchoolMembership(): ObservableProperty<(UserSchoolMembership | null)> {
        return xObservablePropertyMap<(Array<UserSchoolMembership> | null), (UserSchoolMembership | null)>(this.session.userSchoolMembership.observableListSimple(ApiFilter.Companion.INSTANCE.all<UserSchoolMembership>(UserSchoolMembership.Companion.INSTANCE.forUser(this.session.session.userId), UserSchoolMembership.Companion.INSTANCE.forSchool(this.schoolId)), undefined, undefined, undefined, undefined, undefined), (it: (Array<UserSchoolMembership> | null)): (UserSchoolMembership | null) => ((): (UserSchoolMembership | null) => {
            const temp5322 = it;
            if(temp5322 === null) { return null }
            return (temp5322[0] ?? null)
        })());
    }
    
    public readonly subscriptionStates: StandardObservableProperty<Array<AlertType>>;
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ManageAlertsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const userSchoolMembership = this.userSchoolMembership();
        
        
        //--- Set Up xml.internalContainer
        xViewBindExists(xml.internalContainer, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanEscalateAlert ?? null) === true));
        //--- Set Up xml.internal
        xCompoundButtonBind(xml.internal, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<(UserSchoolMembership | null), boolean>(userSchoolMembership, (it: (UserSchoolMembership | null)): boolean => (it?.includeInInternalAlert ?? null) ?? true), (it: boolean): void => {
            if (userSchoolMembership.value !== null) {
                xSingleCallDisplayingError<UserSchoolMembership>(this.session.userSchoolMembership.patch(userSchoolMembership.value!!.id, [ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("included_in_internal", it, (it: UserSchoolMembership): UserSchoolMembership => it)]), undefined, undefined);
            }
        }));
        
        //--- Set Up xml.approvalRequiredContainer
        xViewBindExists(xml.approvalRequiredContainer, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanEscalateAlert ?? null) === true));
        //--- Set Up xml.approvalRequired
        xCompoundButtonBind(xml.approvalRequired, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<(UserSchoolMembership | null), boolean>(userSchoolMembership, (it: (UserSchoolMembership | null)): boolean => (it?.notifyOnApprovalRequired ?? null) ?? true), (it: boolean): void => {
            if (userSchoolMembership.value !== null) {
                xSingleCallDisplayingError<UserSchoolMembership>(this.session.userSchoolMembership.patch(userSchoolMembership.value!!.id, [ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("included_in_approval", it, (it: UserSchoolMembership): UserSchoolMembership => it)]), undefined, undefined);
            }
        }));
        
        //--- Set Up xml.fetchingAlertTypes
        xViewFlipperBindLoading(xml.fetchingAlertTypes, this.isLoading, undefined);
        
        //--- Set Up xml.alertTypeList
        xRecyclerViewBind<AlertType>(xml.alertTypeList, this.subscriptionStates, new AlertType(undefined, undefined, (-1), "Emergency", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<AlertType>): HTMLElement => {
            //--- Make Subview For xml.alertTypeList (overwritten on flow generation)
            const cellXml = new ComponentRowAlertCheckboxXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.alertType
            //cellXml.alertType.bindString(observable.map { it.name })
            
            //--- Set Up cellXml.isChecked
            xTextViewBindString(cellXml.isChecked, xObservablePropertyMap<AlertType, string>(observable, (it: AlertType): string => it.name));
            
            xCompoundButtonBind(cellXml.isChecked, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<AlertType, boolean>(observable, (it: AlertType): boolean => it.subscribed), (valueToWrite: boolean): void => {
                console.error(`${"ManageAlert:"}: ${valueToWrite.toString()}`);
                const target = observable.value;
                
                xSingleCallDisplayingError<AlertType>(this.session.alertTypes.patch(target.id, [ApiModifier.constructorStringAnyFunction1HasIdHasId<AlertType>("subscribed", valueToWrite, (it: AlertType): AlertType => it)]), undefined, (it: AlertType): void => {
                    target.subscribed = valueToWrite;
                });
            }));
            
            //--- End Make Subview For xml.alertTypeList (overwritten on flow generation)
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}

