// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/ImagePreviewVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { DialogRequest, showDialog } from 'butterfly-web/dist/views/showDialog'
import { xActivityAccessDownloadFile } from 'butterfly-web/dist/views/ViewDependency'
import { ImagePreviewXml } from '../../layout/ImagePreviewXml'
import { Image, ImageRemoteUrl } from 'butterfly-web/dist/Image'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xCharSequenceIsBlank, xStringSubstringAfterLast, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ViewStringResource, ViewStringTemplate } from 'butterfly-web/dist/views/ViewString'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { tryCastClass } from 'butterfly-web/dist/Kotlin'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'

//! Declares com.tresitgroup.android.tresit.vg.chat.ImagePreviewVG
export class ImagePreviewVG extends ViewGenerator {
    public readonly image: ObservableProperty<(Image | null)>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(image: ObservableProperty<(Image | null)>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.image = image;
        this.stack = stack;
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.ImagePreviewVG.title
    public get title(): string { return "Image Preview"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ImagePreviewXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Set Up xml.image
        xImageViewBindImage(xml.image, this.image);
        
        //--- Set Up xml.closeButton (overwritten on flow generation)
        xViewOnClick(xml.closeButton, undefined, (): void => {
            this.closeButtonClick();
        });
        
        //--- Set Up xml.download
        xViewOnClick(xml.download, undefined, (): void => {
            this.downloadClick(dependency);
        });
        xViewBindExists(xml.download, xObservablePropertyMap<(Image | null), boolean>(this.image, (it: (Image | null)): boolean => it instanceof ImageRemoteUrl));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeButtonClick(): void {
        this.stack.dismiss();
    }
    public downloadClick(dependency: Window): void {
        const url_4413 = ((tryCastClass<ImageRemoteUrl>(this.image.value, ImageRemoteUrl))?.url ?? null);
        if (url_4413 !== null) {
            if (!xCharSequenceIsBlank(url_4413)) {
                const fileName = xStringSubstringBefore(xStringSubstringAfterLast(url_4413, '/', undefined), '?', undefined);
                
                showDialog(new DialogRequest(new ViewStringTemplate(new ViewStringResource(R._string.download_x), [fileName]), (): void => {
                    xActivityAccessDownloadFile(dependency, url_4413);
                }));
            }
        }
    }
    
    //--- Body End
}
