// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Stats.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Stats
export class Stats {
    public readonly total_users: number;
    public readonly alerts_this_month: number;
    public readonly most_used_alert: string;
    public readonly total_alert_types: number;
    public readonly avg_mess_per_alert: number;
    public constructor(total_users: number = 0, alerts_this_month: number = 0, most_used_alert: string = "", total_alert_types: number = 0, avg_mess_per_alert: number = 0) {
        this.total_users = total_users;
        this.alerts_this_month = alerts_this_month;
        this.most_used_alert = most_used_alert;
        this.total_alert_types = total_alert_types;
        this.avg_mess_per_alert = avg_mess_per_alert;
    }
    public static fromJson(obj: any): Stats { return new Stats(
        parseJsonTyped(obj["total_users"], [Number]) as number,
        parseJsonTyped(obj["alerts_this_month"], [Number]) as number,
        parseJsonTyped(obj["most_used_alert"], [String]) as string,
        parseJsonTyped(obj["total_alert_types"], [Number]) as number,
        parseJsonTyped(obj["avg_mess_per_alert"], [Number]) as number
    ) }
    public toJSON(): object { return {
        total_users: this.total_users,
        alerts_this_month: this.alerts_this_month,
        most_used_alert: this.most_used_alert,
        total_alert_types: this.total_alert_types,
        avg_mess_per_alert: this.avg_mess_per_alert
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.total_users);
        hash = 31 * hash + hashAnything(this.alerts_this_month);
        hash = 31 * hash + hashAnything(this.most_used_alert);
        hash = 31 * hash + hashAnything(this.total_alert_types);
        hash = 31 * hash + hashAnything(this.avg_mess_per_alert);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Stats && safeEq(this.total_users, other.total_users) && safeEq(this.alerts_this_month, other.alerts_this_month) && safeEq(this.most_used_alert, other.most_used_alert) && safeEq(this.total_alert_types, other.total_alert_types) && safeEq(this.avg_mess_per_alert, other.avg_mess_per_alert) }
    public toString(): string { return `Stats(total_users=${this.total_users}, alerts_this_month=${this.alerts_this_month}, most_used_alert=${this.most_used_alert}, total_alert_types=${this.total_alert_types}, avg_mess_per_alert=${this.avg_mess_per_alert})` }
    public copy(total_users: number = this.total_users, alerts_this_month: number = this.alerts_this_month, most_used_alert: string = this.most_used_alert, total_alert_types: number = this.total_alert_types, avg_mess_per_alert: number = this.avg_mess_per_alert): Stats { return new Stats(total_users, alerts_this_month, most_used_alert, total_alert_types, avg_mess_per_alert); }
}