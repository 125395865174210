//
// ComponentChatAttachmentXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_chat_attachment.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentChatAttachmentXml
export class ComponentChatAttachmentXml {
    xmlRoot!: HTMLElement
    image!: HTMLImageElement
    fileName!: HTMLElement
    dummyVideoPreview!: HTMLElement
    dummyImagePreview!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.image = getViewById<HTMLImageElement>(view, "image")
        this.fileName = getViewById<HTMLElement>(view, "fileName")
        this.dummyVideoPreview = getViewById<HTMLElement>(view, "dummyVideoPreview")
        this.dummyImagePreview = getViewById<HTMLElement>(view, "dummyImagePreview")
        return view
    }
}
