// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/UserActivityHistoryVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { UserActivityHistoryXml } from '../../layout/UserActivityHistoryXml'
import { ComponentUserActivityXml } from '../../layout/ComponentUserActivityXml'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { LoginActivity } from '../../model/LoginActivity'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { User } from '../../model/User'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'

//! Declares com.tresitgroup.android.tresit.vg.admin.UserActivityHistoryVG
export class UserActivityHistoryVG extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly userId: ForeignKey<User>;
    public constructor(session: SessionApi, userId: ForeignKey<User>) {
        super();
        this.session = session;
        this.userId = userId;
        this.data = new StandardObservableProperty<Array<LoginActivity>>([], undefined);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<LoginActivity>>(this.session.loginActivity.getListSimple(LoginActivity.Companion.INSTANCE.forUser(this.userId), LoginActivity.Companion.INSTANCE.mostRecent, undefined, undefined, undefined), undefined, (it: Array<LoginActivity>): void => {
            this.data.value = it;
        }));
    }
    
    
    
    public readonly data: MutableObservableProperty<Array<LoginActivity>>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.UserActivityHistoryVG.title
    public get title(): string { return "User Activity History"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new UserActivityHistoryXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.items
        xLinearLayoutBind<LoginActivity>(xml.items, this.data, new LoginActivity(undefined, undefined, undefined, undefined, (-1), undefined), (activity: ObservableProperty<LoginActivity>): HTMLElement => {
            //--- Make Subview For xml.items (overwritten on flow generation)
            const cellXml = new ComponentUserActivityXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.device
            xTextViewBindString(cellXml.device, xObservablePropertyMap<LoginActivity, string>(activity, (it: LoginActivity): string => it.device));
            
            //--- Set Up cellXml.ip
            xTextViewBindString(cellXml.ip, xObservablePropertyMap<LoginActivity, string>(activity, (it: LoginActivity): string => it.ip));
            
            //--- Set Up cellXml.datetime
            xTextViewBindString(cellXml.datetime, xObservablePropertyMap<LoginActivity, string>(activity, (it: LoginActivity): string => xDateFormat(it.updatedAt, ClockPartSize.Short, ClockPartSize.Short)));
            //--- End Make Subview For xml.items (overwritten on flow generation)
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
