// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/ApproveAdminData.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.ApproveAdminData
export class ApproveAdminData {
    public readonly id: (number | null);
    public readonly schoolId: (number | null);
    public readonly name: (number | null);
    public readonly created: (string | null);
    public readonly initiator: (number | null);
    public readonly police_status: (string | null);
    public readonly scoped: (boolean | null);
    public readonly internal: (boolean | null);
    public readonly silent: (boolean | null);
    public readonly pending_approval_status: (string | null);
    public readonly alert_type: (string | null);
    public readonly school_name: string;
    public readonly initiator_name: (string | null);
    public readonly description: (string | null);
    public readonly cellNumber: (string | null);
    public constructor(id: (number | null), schoolId: (number | null), name: (number | null), created: (string | null), initiator: (number | null), police_status: (string | null), scoped: (boolean | null), internal: (boolean | null), silent: (boolean | null), pending_approval_status: (string | null), alert_type: (string | null), school_name: string, initiator_name: (string | null), description: (string | null), cellNumber: (string | null)) {
        this.id = id;
        this.schoolId = schoolId;
        this.name = name;
        this.created = created;
        this.initiator = initiator;
        this.police_status = police_status;
        this.scoped = scoped;
        this.internal = internal;
        this.silent = silent;
        this.pending_approval_status = pending_approval_status;
        this.alert_type = alert_type;
        this.school_name = school_name;
        this.initiator_name = initiator_name;
        this.description = description;
        this.cellNumber = cellNumber;
    }
    public static fromJson(obj: any): ApproveAdminData { return new ApproveAdminData(
        parseJsonTyped(obj["id"], [Number]) as (number | null),
        parseJsonTyped(obj["school"], [Number]) as (number | null),
        parseJsonTyped(obj["name"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [String]) as (string | null),
        parseJsonTyped(obj["initiator"], [Number]) as (number | null),
        parseJsonTyped(obj["police_status"], [String]) as (string | null),
        parseJsonTyped(obj["scoped"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["internal"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["silent"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["pending_approval_status"], [String]) as (string | null),
        parseJsonTyped(obj["alert_type"], [String]) as (string | null),
        parseJsonTyped(obj["school_name"], [String]) as string,
        parseJsonTyped(obj["initiator_name"], [String]) as (string | null),
        parseJsonTyped(obj["description"], [String]) as (string | null),
        parseJsonTyped(obj["initiator_cell_number"], [String]) as (string | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        school: this.schoolId,
        name: this.name,
        created: this.created,
        initiator: this.initiator,
        police_status: this.police_status,
        scoped: this.scoped,
        internal: this.internal,
        silent: this.silent,
        pending_approval_status: this.pending_approval_status,
        alert_type: this.alert_type,
        school_name: this.school_name,
        initiator_name: this.initiator_name,
        description: this.description,
        initiator_cell_number: this.cellNumber
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.schoolId);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.initiator);
        hash = 31 * hash + hashAnything(this.police_status);
        hash = 31 * hash + hashAnything(this.scoped);
        hash = 31 * hash + hashAnything(this.internal);
        hash = 31 * hash + hashAnything(this.silent);
        hash = 31 * hash + hashAnything(this.pending_approval_status);
        hash = 31 * hash + hashAnything(this.alert_type);
        hash = 31 * hash + hashAnything(this.school_name);
        hash = 31 * hash + hashAnything(this.initiator_name);
        hash = 31 * hash + hashAnything(this.description);
        hash = 31 * hash + hashAnything(this.cellNumber);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ApproveAdminData && safeEq(this.id, other.id) && safeEq(this.schoolId, other.schoolId) && safeEq(this.name, other.name) && safeEq(this.created, other.created) && safeEq(this.initiator, other.initiator) && safeEq(this.police_status, other.police_status) && safeEq(this.scoped, other.scoped) && safeEq(this.internal, other.internal) && safeEq(this.silent, other.silent) && safeEq(this.pending_approval_status, other.pending_approval_status) && safeEq(this.alert_type, other.alert_type) && safeEq(this.school_name, other.school_name) && safeEq(this.initiator_name, other.initiator_name) && safeEq(this.description, other.description) && safeEq(this.cellNumber, other.cellNumber) }
    public toString(): string { return `ApproveAdminData(id=${this.id}, schoolId=${this.schoolId}, name=${this.name}, created=${this.created}, initiator=${this.initiator}, police_status=${this.police_status}, scoped=${this.scoped}, internal=${this.internal}, silent=${this.silent}, pending_approval_status=${this.pending_approval_status}, alert_type=${this.alert_type}, school_name=${this.school_name}, initiator_name=${this.initiator_name}, description=${this.description}, cellNumber=${this.cellNumber})` }
    public copy(id: (number | null) = this.id, schoolId: (number | null) = this.schoolId, name: (number | null) = this.name, created: (string | null) = this.created, initiator: (number | null) = this.initiator, police_status: (string | null) = this.police_status, scoped: (boolean | null) = this.scoped, internal: (boolean | null) = this.internal, silent: (boolean | null) = this.silent, pending_approval_status: (string | null) = this.pending_approval_status, alert_type: (string | null) = this.alert_type, school_name: string = this.school_name, initiator_name: (string | null) = this.initiator_name, description: (string | null) = this.description, cellNumber: (string | null) = this.cellNumber): ApproveAdminData { return new ApproveAdminData(id, schoolId, name, created, initiator, police_status, scoped, internal, silent, pending_approval_status, alert_type, school_name, initiator_name, description, cellNumber); }
}

