// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/PoliceStatus.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../R'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.PoliceStatus
export class PoliceStatus {
    public readonly textResource: string;
    private constructor(name: string, jsonName: string, textResource: string) {
        this.name = name;
        this.jsonName = jsonName;
        this.textResource = textResource;
    }
    
    public static NotNotified = new PoliceStatus("NotNotified", "not_notified", R._string.not_notified);
    
    public static Notified = new PoliceStatus("Notified", "notified", R._string.notified);
    
    public static EnRoute = new PoliceStatus("EnRoute", "en_route", R._string.en_route);
    
    public static OnSite = new PoliceStatus("OnSite", "on_site", R._string.on_site);
    
    private static _values: Array<PoliceStatus> = [PoliceStatus.NotNotified, PoliceStatus.Notified, PoliceStatus.EnRoute, PoliceStatus.OnSite];
    public static values(): Array<PoliceStatus> { return PoliceStatus._values; }
    public readonly name: string;
    public readonly jsonName: string;
    public static valueOf(name: string): PoliceStatus { return (PoliceStatus as any)[name]; }
    public toString(): string { return this.name }
    public toJSON(): string { return this.jsonName }
}