// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminAlertTypesVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { District } from '../../model/District'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Observable } from 'rxjs'
import { Image, ImageRemoteUrl } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { SharingObservableProperty, xObservablePropertyShare } from 'butterfly-web/dist/observables/SharingObservableProperty'
import { CacheEdge } from '../../api/CacheEdge'
import { iterFlatMap as iterFlatMap } from 'butterfly-web/dist/kotlin/Collections'
import { logVG } from '../../util/LogVG'
import { xObservablePropertyPlusRx } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { debounceTime as rxDebounceTime } from 'rxjs/operators'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { AdminAlertTypeAddVG } from './AdminAlertTypeAddVG'
import { AlertType } from '../../model/AlertType'
import { ComponentRowAlertTypeFullXml } from '../../layout/ComponentRowAlertTypeFullXml'
import { find as iterFind, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { SessionApi } from '../../api/SessionApi'
import { AdminAlertTypesXml } from '../../layout/AdminAlertTypesXml'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { AdminAlertTypeDetailVG } from './AdminAlertTypeDetailVG'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xIterableGroupBy } from 'butterfly-web/dist/kotlin/Iterables'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypesVG
export class AdminAlertTypesVG extends ViewGenerator {
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, dialog: ObservableStack<ViewGenerator>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.dialog = dialog;
        this.stack = stack;
        this.filter = new StandardObservableProperty<string>("", undefined);
        this.filterSchools = new StandardObservableProperty<Array<School>>([], undefined);
        this.data = new StandardObservableProperty<(Array<AlertType> | null)>(null, undefined);
        this.schools = this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined);
        this.districts = this.session.districts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypesVG.title
    public get title(): string { return "Admin Alert Types"; }
    
    
    public readonly filter: MutableObservableProperty<string>;
    
    public readonly filterSchools: MutableObservableProperty<Array<School>>;
    
    public readonly data: MutableObservableProperty<(Array<AlertType> | null)>;
    
    public readonly schools: CacheEdge<School>;
    
    public readonly districts: CacheEdge<District>;
    
    
    public alertTypesBySchool(): SharingObservableProperty<Array<AdminAlertTypesVG.AlertRegion>> {
        return xObservablePropertyShare<Array<AdminAlertTypesVG.AlertRegion>>(xObservablePropertyFlatMap<Array<ApiFilter<AlertType>>, Array<AdminAlertTypesVG.AlertRegion>>(xObservablePropertyCombine<string, (School | null), Array<ApiFilter<AlertType>>>(xObservablePropertyPlusRx<string>(this.filter, (it: Observable<string>): Observable<string> => it.pipe(rxDebounceTime(1000))), this.schoolFilter, (query: string, school: (School | null)): Array<ApiFilter<AlertType>> => {
                    const filters = ([] as Array<ApiFilter<AlertType>>);
                    
                    if (school !== null) {
                        filters.push(AlertType.Companion.INSTANCE.schoolIn([school!.id]));
                    }
                    filters.push(AlertType.Companion.INSTANCE.textSearch(query));
                    return filters;
            }), (filters: Array<ApiFilter<AlertType>>): ObservableProperty<Array<AdminAlertTypesVG.AlertRegion>> => xObservablePropertyCombine<[(Array<AlertType> | null), (Array<District> | null)], (Array<School> | null), Array<AdminAlertTypesVG.AlertRegion>>(xObservablePropertyCombine<(Array<AlertType> | null), (Array<District> | null), [(Array<AlertType> | null), (Array<District> | null)]>(this.session.alertTypes
                    .observableListSimple(ApiFilter.Companion.INSTANCE.allList<AlertType>(filters), undefined, undefined, undefined, undefined, true), this.districts, (alerts: (Array<AlertType> | null), districts: (Array<District> | null)): [(Array<AlertType> | null), (Array<District> | null)] => [alerts, districts]), this.schools, (item: [(Array<AlertType> | null), (Array<District> | null)], schools: (Array<School> | null)): Array<AdminAlertTypesVG.AlertRegion> => {
                    const alerts = item[0];
                    
                    const districts = item[1];
                    
                    const regionLess = ((): (Array<AdminAlertTypesVG.AlertRegion> | null) => {
                        const temp3434 = ((): (Array<AlertType> | null) => {
                            const temp3436 = alerts;
                            if(temp3436 === null) { return null }
                            return temp3436.filter((alert: AlertType): boolean => (((): (School | null) => {
                                const temp3438 = schools;
                                if(temp3438 === null) { return null }
                                return iterFind(temp3438, (school: School): boolean => school.id === alert.school)
                            })()?.district ?? null) === null)
                        })();
                        if(temp3434 === null) { return null }
                        return temp3434.map((it: AlertType): AdminAlertTypesVG.AlertRegion => new AdminAlertTypesVG.AlertRegion([it], null))
                    })() ?? [];
                    
                    const alertRegions = ((): (Array<AdminAlertTypesVG.AlertRegion> | null) => {
                        const temp3442 = ((): (Array<Array<AdminAlertTypesVG.AlertRegion>> | null) => {
                            const temp3444 = ((): (Map<(number | null), Array<AlertType>> | null) => {
                                const temp3446 = ((): (Array<AlertType> | null) => {
                                    const temp3447 = alerts;
                                    if(temp3447 === null) { return null }
                                    return temp3447.filter((alert: AlertType): boolean => (((): (School | null) => {
                                        const temp3449 = schools;
                                        if(temp3449 === null) { return null }
                                        return iterFind(temp3449, (school: School): boolean => school.id === alert.school)
                                    })()?.district ?? null) !== null)
                                })();
                                if(temp3446 !== null) {
                                    return xIterableGroupBy<AlertType, (number | null)>(temp3446, (alert: AlertType): (number | null) => (((): (School | null) => {
                                        const temp3453 = schools;
                                        if(temp3453 === null) { return null }
                                        return iterFind(temp3453, (school: School): boolean => school.id === alert.school)
                                    })()?.district ?? null))
                                } else { return null }
                            })();
                            if(temp3444 === null) { return null }
                            return iterToArray(iterMap(temp3444, (groupRegion: [(number | null), Array<AlertType>]): Array<AdminAlertTypesVG.AlertRegion> => {
                                return iterToArray(iterMap(xIterableGroupBy<AlertType, string>(groupRegion[1], (it: AlertType): string => it.name), (groupName: [string, Array<AlertType>]): AdminAlertTypesVG.AlertRegion => new AdminAlertTypesVG.AlertRegion(groupName[1], ((): (District | null) => {
                                    if (groupName[1].length > 1) { return ((): (District | null) => {
                                        const temp3461 = districts;
                                        if(temp3461 === null) { return null }
                                        return iterFind(temp3461, (it: District): boolean => it.id === groupRegion[0])
                                    })() } else { return null }
                                })())));
                            }))
                        })();
                        if(temp3442 === null) { return null }
                        return iterToArray(iterFlatMap(temp3442, (it: Array<AdminAlertTypesVG.AlertRegion>): Iterable<AdminAlertTypesVG.AlertRegion> => it))
                    })() ?? [];
                    
                    return alertRegions.concat(regionLess);
        })), undefined);
    }
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminAlertTypesXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.filter);
        
        //--- Set Up xml.schoolSelect.xmlRoot
        const alertTypesBySchool = this.alertTypesBySchool();
        
        
        //--- Set Up xml.alertTypesLoading
        xViewFlipperBindLoading(xml.alertTypesLoading, xObservablePropertyMap<Array<AdminAlertTypesVG.AlertRegion>, boolean>(alertTypesBySchool, (it: Array<AdminAlertTypesVG.AlertRegion>): boolean => it.length === 0), undefined);
        
        //--- Set Up xml.alertTypes
        xRecyclerViewBind<AdminAlertTypesVG.AlertRegion>(xml.alertTypes, alertTypesBySchool, new AdminAlertTypesVG.AlertRegion(([] as Array<AlertType>), null), (alert_type: ObservableProperty<AdminAlertTypesVG.AlertRegion>): HTMLElement => {
            //--- Make Subview For xml.alertTypes
            const cellXml = new ComponentRowAlertTypeFullXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.root, undefined, (): void => {
                this.cellXmlRootClick(alert_type.value.alerts, alert_type.value.region);
            });
            
            //--- Set Up cellXml.icon
            xImageViewBindImage(cellXml.icon, xObservablePropertyMap<AdminAlertTypesVG.AlertRegion, (Image | null)>(alert_type, (it: AdminAlertTypesVG.AlertRegion): (Image | null) => {
                const it_3465 = ((it.alerts[0] ?? null)?.icon ?? null);
                if (it_3465 !== null) {
                    if (!(it_3465 === "")) {
                        return new ImageRemoteUrl(it_3465);
                    }
                }
                return null;
            }));
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<AdminAlertTypesVG.AlertRegion, string>(alert_type, (it: AdminAlertTypesVG.AlertRegion): string => ((it.alerts[0] ?? null)?.name ?? null) ?? ""));
            
            xTextViewBindString(cellXml.schoolName, xObservablePropertyCombine<(Array<School> | null), AdminAlertTypesVG.AlertRegion, string>(this.schools, alert_type, (schools: (Array<School> | null), alert: AdminAlertTypesVG.AlertRegion): string => (((): (School | null) => {
                const temp3469 = schools;
                if(temp3469 === null) { return null }
                return iterFind(temp3469, (it: School): boolean => it.id === ((alert.alerts[0] ?? null)?.school ?? null))
            })()?.name ?? null) ?? ""));
            xViewBindExists(cellXml.schoolName, xObservablePropertyMap<Array<School>, boolean>(this.filterSchools, (it: Array<School>): boolean => !(it.length === 1)));
            //--- Set Up cellXml.schoolName (overwritten on flow generation)
            xTextViewBindString(cellXml.schoolName, new ConstantObservableProperty<string>("Brewer Elementary"));
            //--- End Make Subview For xml.alertTypes (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.addAlertType (overwritten on flow generation)
        xViewOnClick(xml.addAlertType, undefined, (): void => {
            this.addAlertTypeClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action cellXmlDetailDummyAction
    
    public cellXmlRootClick(alertTypes: Array<AlertType>, region: (District | null)): void {
        this.stack.push(new AdminAlertTypeDetailVG(this.dialog, alertTypes.map((it: AlertType): number => it.id), (region?.id ?? null), this.session, this.stack));
    }
    
    public addAlertTypeClick(): void {
        this.stack.push(new AdminAlertTypeAddVG(this.dialog, this.session, this.stack));
    }
    
    //--- Body End
}
export namespace AdminAlertTypesVG {
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertTypesVG.AlertRegion
    export class AlertRegion {
        public readonly alerts: Array<AlertType>;
        public readonly region: (District | null);
        public constructor(alerts: Array<AlertType>, region: (District | null)) {
            this.alerts = alerts;
            this.region = region;
        }
        public hashCode(): number {
            let hash = 17;
            hash = 31 * hash + hashAnything(this.alerts);
            hash = 31 * hash + hashAnything(this.region);
            return hash;
        }
        public equals(other: any): boolean { return other instanceof AlertRegion && safeEq(this.alerts, other.alerts) && safeEq(this.region, other.region) }
        public toString(): string { return `AlertRegion(alerts=${this.alerts}, region=${this.region})` }
        public copy(alerts: Array<AlertType> = this.alerts, region: (District | null) = this.region): AlertRegion { return new AlertRegion(alerts, region); }
    }
}
