//
// AdminUsersXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/admin_users.html'
import htmlForDefault from './admin_users.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminUsersXml
export class AdminUsersXml {
    xmlRoot!: HTMLElement
    filter!: HTMLInputElement
    users!: HTMLDivElement
    addUser!: HTMLButtonElement
    nameHeader: HTMLElement | null = null
    nameHeader2: HTMLDivElement | null = null
    sortNameAscending: HTMLImageElement | null = null
    sortNameDescending: HTMLImageElement | null = null
    dateHeader: HTMLDivElement | null = null
    sortDateAscending: HTMLImageElement | null = null
    sortDateDescending: HTMLImageElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.users = getViewById<HTMLDivElement>(view, "users")
        this.addUser = getViewById<HTMLButtonElement>(view, "addUser")
        this.nameHeader = findViewById<HTMLElement>(view, "nameHeader")
        this.nameHeader2 = findViewById<HTMLDivElement>(view, "nameHeader2")
        this.sortNameAscending = findViewById<HTMLImageElement>(view, "sortNameAscending")
        this.sortNameDescending = findViewById<HTMLImageElement>(view, "sortNameDescending")
        this.dateHeader = findViewById<HTMLDivElement>(view, "dateHeader")
        this.sortDateAscending = findViewById<HTMLImageElement>(view, "sortDateAscending")
        this.sortDateDescending = findViewById<HTMLImageElement>(view, "sortDateDescending")
        return view
    }
}
