// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Session.api.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { FirebaseMessagingToken } from './FirebaseMessagingToken'
import { BuildConfig } from '../BuildConfig'
import { SessionApi } from '../api/SessionApi'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { Notifications } from 'butterfly-fcm-web/dist/Notifications'
import { xSingleCallDisplayingError } from '../util/apicalls'
import { PinCreateVG } from '../vg/PinCreateVG'
import { LandingScreenVG } from '../vg/auth/LandingScreenVG'
import { Platform } from 'butterfly-web/dist/Platform'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { Session } from './Session'

//! Declares com.tresitgroup.android.tresit.model.logOut>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiLogOut(this_: SessionApi): void {
    const it_2224 = Notifications.INSTANCE.notificationToken.value;
    if (it_2224 !== null) {
        xSingleCallDisplayingError<void>(this_.rawApi.postFirebaseToken(this_.session, new FirebaseMessagingToken(undefined, it_2224, Platform.Companion.INSTANCE.current.name, BuildConfig.VERSION_NAME, undefined, undefined, undefined, undefined, undefined)), undefined, undefined);
    }
    Preferences.INSTANCE.remove(Session.Companion.INSTANCE.key);
    this_.close();
    Preferences.INSTANCE.remove(PinCreateVG.Companion.INSTANCE.prefKey);
    this_.root.reset(new LandingScreenVG(this_.dialog, this_.root, this_.root));
}