// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/ConversationDetailHistoryVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { ChatMessage } from '../../model/ChatMessage'
import { Image, xIntAsImage, xStringAsImage } from 'butterfly-web/dist/Image'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Floor } from '../../model/Floor'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { Observable, ObservableInput, SubscriptionLike, zip as rxZip } from 'rxjs'
import { HttpProgress } from 'butterfly-web/dist/net/HttpModels'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { Alert } from '../../model/Alert'
import { ConversationDetailHistoryXml } from '../../layout/ConversationDetailHistoryXml'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { R } from '../../R'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { ComponentRowMessageSentAttachmentsXml } from '../../layout/ComponentRowMessageSentAttachmentsXml'
import { DialogRequest, showDialog, showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { setViewBackgroundClass, xViewOnClick, xViewOnLongClick } from 'butterfly-web/dist/views/View.ext'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { flatMap as rxFlatMap, take as rxTake } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { ViewString, ViewStringRaw, ViewStringResource, ViewStringTemplate } from 'butterfly-web/dist/views/ViewString'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ComponentRowMessageSystemXml } from '../../layout/ComponentRowMessageSystemXml'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { xCharSequenceIsBlank, xStringSubstringAfterLast, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { School } from '../../model/School'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { ComponentRowMessageSentXml } from '../../layout/ComponentRowMessageSentXml'
import { xActivityAccessDownloadFile } from 'butterfly-web/dist/views/ViewDependency'
import { ComponentRowMessageXml } from '../../layout/ComponentRowMessageXml'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { StatusEnum } from '../../model/StatusEnum'
import { xObservableMapNotNull, xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { Video, xStringAsVideo } from 'butterfly-web/dist/Video'
import { ComponentChatAttachmentXml } from '../../layout/ComponentChatAttachmentXml'
import { xRecyclerViewBindMulti, xRecyclerViewReverseDirectionSet } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { Room } from '../../model/Room'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { ComponentRowMessageAttachmentsXml } from '../../layout/ComponentRowMessageAttachmentsXml'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { ConversationOptionsVG } from '../chat/ConversationOptionsVG'
import { ImagePreviewVG } from '../chat/ImagePreviewVG'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ChatAttachment } from '../../model/ChatAttachment'
import { ChatThread } from '../../model/ChatThread'
import { printStackTrace } from 'butterfly-web/dist/kotlin/Language'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { VideoPreviewVG } from '../chat/VideoPreviewVG'

//! Declares com.tresitgroup.android.tresit.vg.admin.ConversationDetailHistoryVG
export class ConversationDetailHistoryVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly threadId: ForeignKey<ChatThread>;
    public readonly threadName: (string | null);
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, threadId: ForeignKey<ChatThread>, threadName: (string | null) = null, schoolFilter: ObservableProperty<(School | null)>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.threadId = threadId;
        this.threadName = threadName;
        this.schoolFilter = schoolFilter;
        this.stack = stack;
        this.newMessage = new StandardObservableProperty<string>("", undefined);
        this.sending = new StandardObservableProperty<boolean>(false, undefined);
        this.thread = xObservablePropertyMap<(ChatThread | null), (ChatThread | null)>(this.session.threads.observable(this.threadId), (it: (ChatThread | null)): (ChatThread | null) => it);
        this.messages = xObservablePropertyMap<(Array<ChatMessage> | null), Array<ChatMessage>>(this.session.messages.observableListSimple(ChatMessage.Companion.INSTANCE.partOfThread(this.threadId), ChatMessage.Companion.INSTANCE.createdDownSort, undefined, undefined, false, undefined), (it: (Array<ChatMessage> | null)): Array<ChatMessage> => it ?? []);
        this.attachments = new StandardObservableProperty<Array<ConversationDetailHistoryVG.LocalChatAttachment>>([], undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.ConversationDetailHistoryVG.titleString
    public get titleString(): ViewString { return new ViewStringResource(R._string.conversation); }
    
    
    private readonly newMessage: MutableObservableProperty<string>;
    
    private readonly sending: MutableObservableProperty<boolean>;
    
    public readonly thread: ObservableProperty<(ChatThread | null)>;
    
    public readonly messages: ObservableProperty<Array<ChatMessage>>;
    
    
    public threadAlert(): ObservableProperty<(Alert | null)> {
        return xObservablePropertyFlatMapNotNull<number, Alert>(xObservablePropertyMap<(ChatThread | null), (number | null)>(this.thread, (it: (ChatThread | null)): (number | null) => (it?.alert ?? null)), (it: number): ObservableProperty<(Alert | null)> => this.session.alerts.observable(it));
    }
    
    
    
    
    
    private readonly attachments: MutableObservableProperty<Array<ConversationDetailHistoryVG.LocalChatAttachment>>;
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ConversationDetailHistoryXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const threadAlert = this.threadAlert();
        
        
        //--- Set Up xml.details
        xViewOnClick(xml.details, undefined, (): void => {
            if (safeEq((this.thread.value?.threadType ?? null), ChatThread.ThreadType.Group)) {
                this.showParticipants();
            }
        });
        
        //--- Set Up xml.typeIcon
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(ChatThread | null)>(this.thread, undefined, undefined, (it: (ChatThread | null)): void => {
            switch((it?.threadType ?? null) ?? ChatThread.ThreadType.Group) {
                case ChatThread.ThreadType.Alert:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_warn_light)
                break;
                case ChatThread.ThreadType.Group:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_group_light)
                break;
                case ChatThread.ThreadType.Contact:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_person_light)
                break;
                default:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_red_flag)
                break;
            }
            
        }), xViewRemovedGet(xml.typeIcon));
        
        //--- Set Up xml.groupName
        xViewBindExists(xml.groupName, xObservablePropertyMap<(ChatThread | null), boolean>(this.session.threads.observable(this.threadId), (it: (ChatThread | null)): boolean => (it?.name ?? null) !== null));
        xTextViewBindString(xml.groupName, xObservablePropertyMap<(ChatThread | null), string>(this.session.threads.observable(this.threadId), (it: (ChatThread | null)): string => (it?.name ?? null) ?? ""));
        
        //--- Set Up xml.participants
        xTextViewBindString(xml.participants, xObservablePropertyMap<(string | null), string>(xObservablePropertyFlatMapNotNull<ChatThread, string>(this.session.threads.observable(this.threadId), (it: ChatThread): ObservableProperty<(string | null)> => {
            const schoolNames = it.schools.map((it: number): ObservableProperty<string> => xObservablePropertyMap<(School | null), string>(this.session.schools.observable(it), (it: (School | null)): string => (it?.name ?? null) ?? "-"));
            
            const userNames = it.userIds.map((it: number): ObservableProperty<string> => xObservablePropertyMap<(User | null), string>(this.session.users.observable(it), (it: (User | null)): string => (it?.name ?? null) ?? "-"));
            
            const result: ObservableProperty<(string | null)> = xObservablePropertyMap<string, (string | null)>(xObservablePropertyMap<Array<string>, string>(xListCombined<string>((schoolNames.concat(userNames))), (it: Array<string>): string => it.filter((it: string): boolean => !(it === (this.session.me.value?.name ?? null))).slice(0, 3).join(", ")), (it: string): (string | null) => it);
            
            return result;
        }), (it: (string | null)): string => it ?? ""));
        
        //--- Set Up xml.moreParticipants
        xViewBindExists(xml.moreParticipants, xObservablePropertyMap<(ChatThread | null), boolean>(this.thread, (it: (ChatThread | null)): boolean => ((): (boolean | null) => {
            const temp3741 = it;
            if(temp3741 === null) { return null }
            return ((thread: ChatThread): boolean => thread.userIds.length > 3)(temp3741)
        })() ?? ((this_: ConversationDetailHistoryVG): boolean => false)(this)));
        
        
        //--- Set Up xml.threadMessages
        //--- Set Up cellXml.image
        //--- Set Up cellXml.initials
        //--- Set Up cellXml.name
        //--- Set Up cellXml.message
        //--- Set Up cellXml.attachments
        
        //--- Make Subview For xml.threadMessages
        //--- End Make Subview For xml.threadMessages
        
        xRecyclerViewBindMulti<ChatMessage>(xml.threadMessages, this.messages, new ChatMessage(undefined, undefined, this.threadId, undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined), (it: ChatMessage): number => it.authorId === this.session.session.userId ? 2 + (it.attachmentsDeep.length !== 0 ? 1 : 0) : it.authorId === null ? 4 : 0 + (it.attachmentsDeep.length !== 0 ? 1 : 0), (type: number, observable: ObservableProperty<ChatMessage>): HTMLElement => {
            const messageAuthor: ObservableProperty<(User | null)> = xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<ChatMessage, (number | null)>(observable, (it: ChatMessage): (number | null) => it.authorId), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it));
            
            
            const authorLocation: ObservableProperty<(Room | null)> = xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyCombine<(number | null), (Alert | null), (number | null)>(xObservablePropertyMap<(User | null), (number | null)>(messageAuthor, (it: (User | null)): (number | null) => (it?.currentLocation ?? null)), threadAlert, (loc: (number | null), alert: (Alert | null)): (number | null) => alert !== null ? loc : null), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it));
            
            
            const roomName: ObservableProperty<string> = xObservablePropertyMap<(Room | null), string>(authorLocation, (it: (Room | null)): string => (it?.name ?? null) ?? "Default");
            
            const floor = xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(authorLocation, (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it));
            
            const floorName: ObservableProperty<string> = xObservablePropertyMap<(Floor | null), string>(floor, (it: (Floor | null)): string => (it?.name ?? null) ?? "Default");
            
            const buildingName: ObservableProperty<string> = xObservablePropertyMap<(Building | null), string>(xObservablePropertyFlatMapNotNull<number, Building>(xObservablePropertyMap<(Floor | null), (number | null)>(floor, (it: (Floor | null)): (number | null) => (it?.building ?? null)), (it: number): ObservableProperty<(Building | null)> => this.session.buildings.observable(it)), (it: (Building | null)): string => (it?.name ?? null) ?? "Default");
            
            const names: ObservableProperty<Array<string>> = xObservablePropertyMap<Array<string>, Array<string>>(xListCombined<string>(([roomName, floorName, buildingName] as Array<ObservableProperty<string>>)), (it: Array<string>): Array<string> => it.filter((it: string): boolean => !(it === "Default")));
            
            const details = xObservablePropertyCombine<string, (User | null), string>(xObservablePropertyCombine<string, Array<string>, string>(xObservablePropertyCombine<string, (Room | null), string>(xObservablePropertyMap<ChatMessage, string>(observable, (message: ChatMessage): string => xDateFormat(message.created, ClockPartSize.None, ClockPartSize.Short)), authorLocation, (message: string, room: (Room | null)): string => ((): (string | null) => {
                            const temp3754 = room;
                            if(temp3754 === null) { return null }
                            return ((it: Room): string => `${message} | ${it.safe ?? StatusEnum.Unknown}`)(temp3754)
                })() ?? message), names, (message: string, locations: Array<string>): string => ((): (string | null) => {
                    const temp3756 = (locations[0] ?? null);
                    if(temp3756 === null) { return null }
                    return ((it: string): string => `${(locations[0] ?? null) ?? "Somewhere"} | ${message}`)(temp3756)
            })() ?? message), messageAuthor, (message: string, author: (User | null)): string => `${(author?.firstName ?? null) ?? "Unknown"} ${(author?.lastName ?? null) ?? "Author"} | ${message}`);
            
            
            
            return ((): HTMLElement => {
                switch(type) {
                    case 0:
                    // Message Received
                    const cellXmlA = new ComponentRowMessageXml();
                    
                    const cellViewA = cellXmlA.setup(dependency);
                    
                    
                    xTextViewBindString(cellXmlA.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3758 = ((): (string | null) => {
                            const temp3759 = author;
                            if(temp3759 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3759)
                        })();
                        if(temp3758 === null) { return null }
                        return temp3758.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlA.name, details)
                    xTextViewBindString(cellXmlA.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    return cellViewA
                    case 1:
                    // Message Received with attachment
                    const cellXmlB = new ComponentRowMessageAttachmentsXml();
                    
                    const cellViewB = cellXmlB.setup(dependency);
                    
                    
                    xTextViewBindString(cellXmlB.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3763 = ((): (string | null) => {
                            const temp3764 = author;
                            if(temp3764 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3764)
                        })();
                        if(temp3763 === null) { return null }
                        return temp3763.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlB.name, details)
                    xTextViewBindString(cellXmlB.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    this.bindAttachments(cellXmlB.attachments, cellXmlB.attachments, dependency, observable)
                    return cellViewB
                    case 2:
                    // Message Sent
                    const cellXmlC = new ComponentRowMessageSentXml();
                    
                    const cellViewC = cellXmlC.setup(dependency);
                    
                    xTextViewBindString(cellXmlC.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3768 = ((): (string | null) => {
                            const temp3769 = author;
                            if(temp3769 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3769)
                        })();
                        if(temp3768 === null) { return null }
                        return temp3768.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlC.name, details)
                    xTextViewBindString(cellXmlC.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    return cellViewC
                    case 3:
                    // Message Sent with attachment
                    const cellXmlD = new ComponentRowMessageSentAttachmentsXml();
                    
                    const cellViewD = cellXmlD.setup(dependency);
                    
                    xTextViewBindString(cellXmlD.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp3773 = ((): (string | null) => {
                            const temp3774 = author;
                            if(temp3774 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp3774)
                        })();
                        if(temp3773 === null) { return null }
                        return temp3773.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlD.name, details)
                    xTextViewBindString(cellXmlD.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    this.bindAttachments(cellXmlD.attachments, cellXmlD.attachments, dependency, observable)
                    return cellViewD
                    default:
                    // Message from system
                    
                    const cellXmlE = new ComponentRowMessageSystemXml();
                    
                    const cellViewE = cellXmlE.setup(dependency);
                    
                    xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(threadAlert, undefined, undefined, (alert: (Alert | null)): void => {
                        if (alert !== null && (!alert!.drill)){
                            setViewBackgroundClass(cellXmlE.backgroundView, R.drawable.card_alert.cssClass);
                        } else if (alert !== null && alert!.drill){
                            setViewBackgroundClass(cellXmlE.backgroundView, R.drawable.card_blue.cssClass);
                        }
                    }), xViewRemovedGet(cellXmlE.xmlRoot))
                    xTextViewBindString(cellXmlE.message, xObservablePropertyCombine<ChatMessage, (Alert | null), string>(observable, threadAlert, (it: ChatMessage, alert: (Alert | null)): string => it.content.replaceAll("[time]", xDateFormat(((alert?.created ?? null) ?? it.created), ClockPartSize.None, ClockPartSize.Short))))
                    xImageViewBindImage(cellXmlE.alertTypeIcon, xObservablePropertyMap<ChatMessage, (Image | null)>(observable, (it: ChatMessage): (Image | null) => ((): (Image | null) => {
                        const temp3786 = ((it.attachmentsDeep[0] ?? null)?.image ?? null);
                        if(temp3786 !== null) {
                            return xStringAsImage(temp3786)
                        } else { return null }
                    })()))
                    xTextViewBindString(cellXmlE.name, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => `System Message | ${xDateFormat(it.created, ClockPartSize.Medium, ClockPartSize.Short)}`))
                    return cellViewE
                }
                
            })()
        });
        xRecyclerViewReverseDirectionSet(xml.threadMessages, true);
        xDisposableUntil<SubscriptionLike>(this.messages.onChange.subscribe((it: Array<ChatMessage>): void => {}, undefined, undefined), xViewRemovedGet(xml.threadMessages));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public detailsClick(): void {
        this.dialog.push(new ConversationOptionsVG(this.dialog, this.session, this.threadId, this.schoolFilter, this.stack));
    }
    
    public showParticipants(): void {
        this.dialog.push(new ConversationOptionsVG(this.dialog, this.session, this.threadId, this.schoolFilter, this.stack));
    }
    
    public newMessageSubmitClick(): void {
        if (xCharSequenceIsBlank(this.newMessage.value)) {
            showDialogAlert(new ViewStringRaw("Please enter a message first."));
            return;
        }
        const makePost = this.session.messages.post(new ChatMessage(undefined, undefined, this.threadId, this.session.session.userId, undefined, this.newMessage.value, undefined, undefined, undefined, undefined, undefined, undefined));
        
        if (this.attachments.value.length !== 0) {
            xSingleCallDisplayingError<void>(xSingleWorking<void>(rxZip(...this.attachments.value.map((it: ConversationDetailHistoryVG.LocalChatAttachment): Observable<ChatAttachment> => xObservableMapNotNull<HttpProgress<ChatAttachment>, ChatAttachment>(it.progress, (it: HttpProgress<ChatAttachment>): (ChatAttachment | null) => it.response).pipe(rxTake(1)))).pipe(rxFlatMap((attachments: Array<ChatAttachment>): ObservableInput<void> => makePost.pipe(rxFlatMap((msg: ChatMessage): ObservableInput<void> => this.session.rawApi.bindAttachments(this.session.session, msg.id, attachments.map((it: ChatAttachment): number => it.id)))))), this.sending), undefined, (it: void): void => {
                this.attachments.value = [];
                this.newMessage.value = "";
            });
        } else {
            xSingleCallDisplayingError<ChatMessage>(xSingleWorking<ChatMessage>(makePost, this.sending), undefined, (it: ChatMessage): void => {
                this.newMessage.value = "";
            });
        }
        
    }
    
    public launchPreview(dependency: Window, attachment: ObservableProperty<ChatAttachment>): void {
        if (attachment.value.video !== null) {
            this.dialog.push(new VideoPreviewVG(this.dialog, xObservablePropertyMap<ChatAttachment, (Video | null)>(attachment, (it: ChatAttachment): (Video | null) => ((): (Video | null) => {
                const temp3800 = it.video;
                if(temp3800 !== null) {
                    return xStringAsVideo(temp3800)
                } else { return null }
            })())))
        } else if (attachment.value.image !== null) {
            this.dialog.push(new ImagePreviewVG(xObservablePropertyMap<ChatAttachment, (Image | null)>(attachment, (it: ChatAttachment): (Image | null) => ((): (Image | null) => {
                const temp3801 = it.image;
                if(temp3801 !== null) {
                    return xStringAsImage(temp3801)
                } else { return null }
            })()), this.dialog))
        } else if (attachment.value.doc !== null){
            this.launchDownload(dependency, attachment.value);
        }
    }
    
    public launchDownload(dependency: Window, attachment: ChatAttachment): void {
        const url = ((it: ChatAttachment): string => ((): string => {
            if (it.video !== null) {
                return it.video!
            } else if (it.image !== null) {
                return it.image!
            } else if (it.doc !== null) {
                return it.doc!
            } else  {
                return ""
            }
        })())(attachment);
        
        if (!xCharSequenceIsBlank(url)) {
            const fileName = xStringSubstringBefore(xStringSubstringAfterLast(url, '/', undefined), '?', undefined);
            
            showDialog(new DialogRequest(new ViewStringTemplate(new ViewStringResource(R._string.download_x), [fileName]), (): void => {
                xActivityAccessDownloadFile(dependency, url);
            }));
        }
    }
    
    
    //--- Helpers
    
    public bindAttachments(section: HTMLElement, toView: HTMLDivElement, dependency: Window, observable: ObservableProperty<ChatMessage>): void {
        xViewBindExists(section, xObservablePropertyMap<ChatMessage, boolean>(observable, (it: ChatMessage): boolean => it.attachmentsDeep.length !== 0));
        xLinearLayoutBind<ChatAttachment>(toView, xObservablePropertyMap<ChatMessage, Array<ChatAttachment>>(observable, (it: ChatMessage): Array<ChatAttachment> => it.attachmentsDeep), new ChatAttachment(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (obs: ObservableProperty<ChatAttachment>): HTMLElement => {
            const attXml = new ComponentChatAttachmentXml();
            
            const attView = attXml.setup(dependency);
            
            
            xViewOnClick(attXml.xmlRoot, undefined, (): void => {
                this.launchPreview(dependency, obs);
            });
            xViewOnLongClick(attXml.xmlRoot, (): void => {
                this.launchDownload(dependency, obs.value);
            });
            
            xImageViewBindImage(attXml.image, xObservablePropertyMap<ChatAttachment, (Image | null)>(obs, (it: ChatAttachment): (Image | null) => ((): (Image | null) => {
                        const temp3804 = it.videoThumbnail;
                        if(temp3804 !== null) {
                            return xStringAsImage(temp3804)
                        } else { return null }
                })() ?? ((): (Image | null) => {
                    const temp3805 = it.imageThumbnail;
                    if(temp3805 !== null) {
                        return xStringAsImage(temp3805)
                    } else { return null }
            })() ?? xIntAsImage(R.drawable.ic_attachment)));
            
            xViewBindExists(attXml.fileName, xObservablePropertyMap<ChatAttachment, boolean>(obs, (it: ChatAttachment): boolean => it.doc !== null));
            xTextViewBindString(attXml.fileName, xObservablePropertyMap<ChatAttachment, string>(obs, (it: ChatAttachment): string => {
                return ((): string => {
                    if (it.video !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.video!, '/', undefined), '?', undefined)
                    } else if (it.image !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.image!, '/', undefined), '?', undefined)
                    } else if (it.doc !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.doc!, '/', undefined), '?', undefined)
                    } else  {
                        return ""
                    }
                })();
            }));
            
            return attView;
        });
    }
    
    //--- Body End
}
export namespace ConversationDetailHistoryVG {
    //! Declares com.tresitgroup.android.tresit.vg.admin.ConversationDetailHistoryVG.Companion
    export class Companion {
        private constructor() {
            this.attachmentsEnabled = new StandardObservableProperty<boolean>(true, undefined);
        }
        public static INSTANCE = new Companion();
        
        public readonly attachmentsEnabled: StandardObservableProperty<boolean>;
        
    }
}
export namespace ConversationDetailHistoryVG {
    //! Declares com.tresitgroup.android.tresit.vg.admin.ConversationDetailHistoryVG.LocalChatAttachment
    export class LocalChatAttachment {
        public readonly uri: (File | null);
        public readonly progress: Observable<HttpProgress<ChatAttachment>>;
        public constructor(uri: (File | null) = null, progress: Observable<HttpProgress<ChatAttachment>>) {
            this.uri = uri;
            this.progress = progress;
            this.sub = this.progress.subscribe(undefined, (e: any): void => {
                printStackTrace(e);
            }, undefined);
        }
        
        public readonly sub: SubscriptionLike;
        
        
        public dispose(): void {
            this.sub.unsubscribe();
        }
    }
}
