// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/board/PostDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.board
// Generated by Khrysalis - this file will be overwritten.
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { SessionApi } from '../../api/SessionApi'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { CacheNode } from '../../api/CacheNode'
import { PostDetailXml } from '../../layout/PostDetailXml'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { BoardPost } from '../../model/BoardPost'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.board.PostDetailVG
export class PostDetailVG extends ViewGenerator {
    public readonly postId: ForeignKey<BoardPost>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(postId: ForeignKey<BoardPost>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.postId = postId;
        this.session = session;
        this.stack = stack;
        this.observable = this.session.posts.observable(this.postId);
    }
    
    
    
    public readonly observable: CacheNode<BoardPost>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.board.PostDetailVG.title
    public get title(): string { return "Post Detail"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PostDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<(BoardPost | null), string>(this.observable, (it: (BoardPost | null)): string => (it?.title ?? null) ?? ""));
        
        //--- Set Up xml.close (overwritten on flow generation)
        xViewOnClick(xml.close, undefined, (): void => {
            this.closeClick();
        });
        
        //--- Set Up xml.name
        xTextViewBindString(xml.name, xObservablePropertyMap<(User | null), string>(xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<(BoardPost | null), (number | null)>(this.observable, (it: (BoardPost | null)): (number | null) => (it?.author ?? null)), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it)), (it: (User | null)): string => {
            if (it !== null) {
                return `${it!.firstName} ${it!.lastName}`;
            } else {
                return "";
            }
        }));
        
        //--- Set Up xml.postDetails
        xViewBindExists(xml.postDetails, xObservablePropertyMap<(BoardPost | null), boolean>(this.observable, (it: (BoardPost | null)): boolean => ((): (boolean | null) => {
            const temp4177 = (it?.content ?? null);
            if(temp4177 === null) { return null }
            return temp4177 !== ""
        })() === true));
        xTextViewBindString(xml.postDetails, xObservablePropertyMap<(BoardPost | null), string>(this.observable, (it: (BoardPost | null)): string => (it?.content ?? null) ?? ""));
        
        //--- Set Up xml.time
        xTextViewBindString(xml.time, xObservablePropertyMap<(Date | null), string>(xObservablePropertyMap<(BoardPost | null), (Date | null)>(this.observable, (it: (BoardPost | null)): (Date | null) => (it?.created ?? null)), (it: (Date | null)): string => ((): (string | null) => {
            if(it !== null) {
                return xDateFormat(it, ClockPartSize.Medium, ClockPartSize.Short)
            } else { return null }
        })() ?? "-"));
        
        //--- Set Up xml.resolveDetails
        xViewBindExists(xml.resolveDetails, xObservablePropertyMap<(BoardPost | null), boolean>(this.observable, (it: (BoardPost | null)): boolean => ((): (boolean | null) => {
            const temp4179 = (it?.resolvedMessage ?? null);
            if(temp4179 === null) { return null }
            return temp4179 !== ""
        })() === true));
        xTextViewBindString(xml.resolveDetails, xObservablePropertyMap<(BoardPost | null), string>(this.observable, (it: (BoardPost | null)): string => (it?.resolvedMessage ?? null) ?? ""));
        
        //--- Set Up xml.resolvedName
        xTextViewBindString(xml.resolvedName, xObservablePropertyMap<(User | null), string>(xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<(BoardPost | null), (number | null)>(this.observable, (it: (BoardPost | null)): (number | null) => (it?.resolvedBy ?? null)), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it)), (it: (User | null)): string => {
            if (it !== null) {
                return `${it!.firstName} ${it!.lastName}`;
            } else {
                return "";
            }
        }));
        
        //--- Set Up xml.resolvedTime
        xTextViewBindString(xml.resolvedTime, xObservablePropertyMap<(Date | null), string>(xObservablePropertyMap<(BoardPost | null), (Date | null)>(this.observable, (it: (BoardPost | null)): (Date | null) => (it?.resolvedAt ?? null)), (it: (Date | null)): string => ((): (string | null) => {
            if(it !== null) {
                return xDateFormat(it, ClockPartSize.Medium, ClockPartSize.Short)
            } else { return null }
        })() ?? "-"));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeClick(): void {
        this.stack.dismiss();
    }
    
    //--- Body End
}
