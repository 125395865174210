// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/MockApiTable.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { drop as iterDrop, filter as iterFilter, map as iterMap, take as iterTake, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { HasId } from '../model/HasId'
import { Session } from '../model/Session'
import { Observable, of as rxOf, throwError as rxThrowError } from 'rxjs'
import { getTotalRequestsMade, setTotalRequestsMade } from './apiProperty'
import { MockTable } from './MockTable'
import { MockDatabase } from './MockDatabase'
import { iterableFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { ApiModifier } from './ApiModifier'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ForeignKey } from '../model/ForeignKey'
import { ApiPartialQuery } from './ApiPartialQuery'
import { ApiTable } from './ApiTable'
import { NoSuchElementException } from './CachedApiTable'

//! Declares com.tresitgroup.android.tresit.api.filterQuery
export function filterQuery<T extends HasId>(items: Array<T>, database: MockDatabase, query: ApiPartialQuery<T>): Array<T> {
    const f_1090 = query.query.filter.mockFilter;
    if (f_1090 !== null) {
        return iterToArray(iterTake(iterDrop(iterToArray(iterFilter(iterFilter(items, query.query.filter.filter), (it: T): boolean => f_1090(database, it))).sort(query.query.sort.comparator ?? query.query.sort.mockComparator!!(database)), query.offset), query.limit));
    } else {
        return iterToArray(iterTake(iterDrop(iterToArray(iterFilter(items, query.query.filter.filter)).sort(query.query.sort.comparator ?? query.query.sort.mockComparator!!(database)), query.offset), query.limit));
    }
}

//! Declares com.tresitgroup.android.tresit.api.MockApiTable
export class MockApiTable<T extends HasId> extends ApiTable<T> {
    public readonly session: Session;
    public readonly database: MockDatabase;
    public readonly table: MockTable<T>;
    public constructor(session: Session, database: MockDatabase, table: MockTable<T>) {
        super();
        this.session = session;
        this.database = database;
        this.table = table;
    }
    
    
    //! Declares com.tresitgroup.android.tresit.api.MockApiTable.canViewDeleted
    public get canViewDeleted(): boolean { return ((this.database.users.objects.get(this.session.userId) ?? null)?.permissionCanViewDeleted ?? null) === true; }
    
    public getImmediate(id: ForeignKey<T>): (T | null) {
        return this.table.get(id, this.canViewDeleted);
    }
    public listImmediate(): Array<T> {
        return this.table.asList(this.canViewDeleted);
    }
    
    public get(id: ForeignKey<T>): Observable<T> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} for ${this.table.typeString} ${id}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        return ((): (Observable<T> | null) => {
            const temp1117 = this.getImmediate(id);
            if(temp1117 === null) { return null }
            return ((it: T): Observable<T> => rxOf(it))(temp1117)
        })() ?? rxThrowError(new NoSuchElementException(`No element exists with id ${id}`, undefined));
    }
    
    public getMultiple(ids: Array<ForeignKey<T>>): Observable<Array<T>> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} for ${this.table.typeString} ${ids}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        return rxOf(iterToArray(iterableFilterNotNull(iterMap(ids, (it: number): (T | null) => this.getImmediate(it)))));
    }
    
    public getList(query: ApiPartialQuery<T>): Observable<Array<T>> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} for list of ${this.table.typeString} ${query}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        return rxOf(filterQuery<T>(this.listImmediate(), this.database, query));
    }
    
    public put(value: T): Observable<T> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} to put ${value}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        this.table.replaceItem(value);
        return rxOf(value);
    }
    
    public post(value: T): Observable<T> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} to post ${value}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        this.table.addItem(value);
        return rxOf(value);
    }
    
    public patch(id: ForeignKey<T>, modifiers: Array<ApiModifier<T>>): Observable<T> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} to patch ${id} with ${modifiers}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        const item = this.getImmediate(id)
        if(item === null) { return rxThrowError(new NoSuchElementException(`No element found with id '${id}'`, undefined)) }
        let modifiedItem: T = item;
        
        for (const mod of modifiers) {
            modifiedItem = mod.setter(modifiedItem);
        }
        this.table.replaceItem(modifiedItem);
        return rxOf(modifiedItem);
    }
    
    public _delete(id: ForeignKey<T>): Observable<void> {
        console.debug(`${"MockApiTable"}: ${`Request #${getTotalRequestsMade()} to delete ${this.table.typeString} ${id}`}`);
        setTotalRequestsMade(getTotalRequestsMade() + 1);
        const value_1141 = this.getImmediate(id);
        if (value_1141 !== null) {
            this.table.deleteItemById(value_1141.id);
        }
        return rxOf(undefined);
    }
}