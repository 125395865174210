// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/DelayedTable.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { ApiModifier } from './ApiModifier'
import { delay as rxDelay } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { ForeignKey } from '../model/ForeignKey'
import { ApiTable } from './ApiTable'
import { ApiPartialQuery } from './ApiPartialQuery'

//! Declares com.tresitgroup.android.tresit.api.DelayedTable
export class DelayedTable<T extends HasId> extends ApiTable<T> {
    public readonly base: ApiTable<T>;
    public readonly delay: number;
    public constructor(base: ApiTable<T>, delay: number) {
        super();
        this.base = base;
        this.delay = delay;
    }
    
    public get(id: ForeignKey<T>): Observable<T> {
        return this.base.get(id).pipe(rxDelay(this.delay));
    }
    
    public getMultiple(ids: Array<ForeignKey<T>>): Observable<Array<T>> {
        return this.base.getMultiple(ids).pipe(rxDelay(this.delay));
    }
    
    public getList(query: ApiPartialQuery<T>): Observable<Array<T>> {
        return this.base.getList(query).pipe(rxDelay(this.delay));
    }
    
    public put(value: T): Observable<T> {
        return this.base.put(value).pipe(rxDelay(this.delay));
    }
    
    public patch(id: ForeignKey<T>, modifiers: Array<ApiModifier<T>>): Observable<T> {
        return this.base.patch(id, modifiers).pipe(rxDelay(this.delay));
    }
    
    public post(value: T): Observable<T> {
        return this.base.post(value).pipe(rxDelay(this.delay));
    }
    
    public _delete(id: ForeignKey<T>): Observable<void> {
        return this.base._delete(id).pipe(rxDelay(this.delay));
    }
    
    //    override fun createOffsite(id: String): Single<T> = base.createOffsite(id)
    //        .subscribeOn(Schedulers.io())
    //        .delay(delay, TimeUnit.MILLISECONDS, Schedulers.io())
    //        .observeOn(AndroidSchedulers.mainThread())
    
}