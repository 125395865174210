//
// AlertStatusSafeXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/alert_status_safe.html'
import htmlForDefault from './alert_status_safe.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertStatusSafeXml
export class AlertStatusSafeXml {
    xmlRoot!: HTMLElement
    idStartMainAlert!: HTMLDivElement
    idStartMainDrill!: HTMLButtonElement
    tvNoPermission!: HTMLElement
    progress: HTMLProgressElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.idStartMainAlert = getViewById<HTMLDivElement>(view, "id_startMainAlert")
        this.idStartMainDrill = getViewById<HTMLButtonElement>(view, "id_startMainDrill")
        this.tvNoPermission = getViewById<HTMLElement>(view, "tvNoPermission")
        this.progress = findViewById<HTMLProgressElement>(view, "progress")
        return view
    }
}
