//
// AdminDashboardXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/admin_dashboard.html'
import htmlForw960dp from '../layout-w960dp/admin_dashboard.html'
import htmlForDefault from './admin_dashboard.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminDashboardXml
export class AdminDashboardXml {
    xmlRoot!: HTMLElement
    users!: HTMLDivElement
    totalUsers!: HTMLElement
    alertTypes!: HTMLDivElement
    mostUsedAlert!: HTMLElement
    totalAlertTypes!: HTMLElement
    alertHistory!: HTMLDivElement
    alertsThisMonth!: HTMLElement
    chat!: HTMLDivElement
    avgMessagesPerAlert!: HTMLElement
    toggleSafeUnsafe!: HTMLDivElement
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960, 700])
        this.xmlRoot = view
        this.users = getViewById<HTMLDivElement>(view, "users")
        this.totalUsers = getViewById<HTMLElement>(view, "total_users")
        this.alertTypes = getViewById<HTMLDivElement>(view, "alert_types")
        this.mostUsedAlert = getViewById<HTMLElement>(view, "most_used_alert")
        this.totalAlertTypes = getViewById<HTMLElement>(view, "total_alert_types")
        this.alertHistory = getViewById<HTMLDivElement>(view, "alert_history")
        this.alertsThisMonth = getViewById<HTMLElement>(view, "alerts_this_month")
        this.chat = getViewById<HTMLDivElement>(view, "chat")
        this.avgMessagesPerAlert = getViewById<HTMLElement>(view, "avg_messages_per_alert")
        this.toggleSafeUnsafe = getViewById<HTMLDivElement>(view, "toggleSafeUnsafe")
        return view
    }
}
