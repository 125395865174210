// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/AlertStatusSafeVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { xViewBindExists, xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { safeEq } from 'butterfly-web/dist/kotlin/Language'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { PermissionsResponse } from '../../model/PermissionsResponse'
import { AdminApprovalListResponse } from '../../model/AdminApprovalListResponse'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { AlertStatusSafeXml } from '../../layout/AlertStatusSafeXml'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { Session } from '../../model/Session'
import { SelectAlertVG } from './SelectAlertVG'
import { School } from '../../model/School'
import { AdminApproveDialogVG } from '../admin/AdminApproveDialogVG'

//! Declares com.tresitgroup.android.tresit.vg.alert.AlertStatusSafeVG
export class AlertStatusSafeVG extends ViewGenerator {
    public readonly alertStack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolId: ForeignKey<School>;
    public readonly drillEnabled: boolean;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public constructor(alertStack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>, schoolId: ForeignKey<School>, drillEnabled: boolean, session: SessionApi, stack: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>) {
        super();
        this.alertStack = alertStack;
        this.dialog = dialog;
        this.schoolId = schoolId;
        this.drillEnabled = drillEnabled;
        this.session = session;
        this.stack = stack;
        this.schoolFilter = schoolFilter;
        this.haDrillEnabled = new StandardObservableProperty<boolean>(false, undefined);
        this.showProgress = new StandardObservableProperty<boolean>(true, undefined);
        this.s = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
        this.alertRequest = ((): (SubscriptionLike | null) => {
            if (xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCanAcceptAlert ?? null) === true).value) {
                return ((): (SubscriptionLike | null) => {
                    const temp3989 = this.s;
                    if(temp3989 === null) { return null }
                    return ((it: Session): SubscriptionLike => xSingleCallDisplayingError<Array<AdminApprovalListResponse>>(this.session.rawApi.adminApproval(it, ""), undefined, (adminApprovalResponse: Array<AdminApprovalListResponse>): void => {
                        if (adminApprovalResponse.length !== 0) {
                            let adminAlertJson = adminApprovalResponse[0];
                            
                            const approveAdminResponse = new AdminApprovalListResponse(adminAlertJson.id, adminAlertJson.schoolId, adminAlertJson.alertType ?? "", adminAlertJson.schoolName ?? "", `${adminAlertJson.initiatorName}`, `${adminAlertJson.description}`, adminAlertJson.initiator, adminAlertJson.cellNumber);
                            
                            
                            console.log(`this is from Alertsafe-----> ${adminAlertJson.cellNumber}`);
                            
                            this.alertStack.reset(new AdminApproveDialogVG(approveAdminResponse, this.session, this.dialog, this.schoolFilter, this.alertStack, this.stack));
                            
                        }
                    }))(temp3989)
                })()
            } else {
                return null;
            }
        })();
    }
    
    
    public readonly haDrillEnabled: MutableObservableProperty<boolean>;
    
    public readonly showProgress: MutableObservableProperty<boolean>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertStatusSafeVG.title
    public get title(): string { return "Alert Status Safe"; }
    
    public readonly s: (Session | null);
    
    
    
    public readonly alertRequest: (SubscriptionLike | null);
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertStatusSafeXml();
        
        const view = xml.setup(dependency);
        
        const hasPermission: MutableObservableProperty<boolean> = new StandardObservableProperty<boolean>(false, undefined);
        
        const updatePermission: MutableObservableProperty<boolean> = new StandardObservableProperty<boolean>(false, undefined);
        
        //--- Log
        logVG(this, this.session);
        const temp3993 = xml.progress;
        if(temp3993 !== null) {
            xViewBindExists(temp3993, this.showProgress)
        };
        //val s: Session? = Preferences.get(Session.key)
        
        
        /*  session.users.patch(session.session.userId, listOf(User.removeLocation))
            .callDisplayingError {
            hasPermission.value = it.permissions.contains("schools.add_alert")
            updatePermission.value = !hasPermission.value
            haDrillEnabled.value = drillEnabled
            showProgress.value  = false
        }*/
        
        xSingleCallDisplayingError<PermissionsResponse>(this.session.rawApi.getPermissions(this.session.session, this.session.session.userId), undefined, (it: PermissionsResponse): void => {
            hasPermission.value = ((): (boolean | null) => {
                const temp3995 = it.permissions;
                if(temp3995 === null) { return null }
                return temp3995.some((x) => safeEq("schools.add_alert", x))
            })() === true;
            updatePermission.value = (!hasPermission.value);
            this.haDrillEnabled.value = this.drillEnabled;
            this.showProgress.value = false;
        });
        
        
        
        
        xViewOnClick(xml.idStartMainAlert, undefined, (): void => {
            this.startAlertClick();
        });
        
        if (((): (boolean | null) => {
            const temp3998 = (this.schoolFilter.value?.name ?? null);
            if(temp3998 === null) { return null }
            return (temp3998.indexOf("Default Test Site") != -1)
        })()!!) {
            showDialogAlert(new ViewStringResource(R._string.contact_your_rep_to_get_assigned_to_site));
        }
        
        /*   Log.e("ayush:","${session.me.map { it?.permissionStartAlert == true }.value}")
        Log.e("Shrestha:","${session.me.map { it?.permissionStartAlert == false }.value}")
        hasPermission.value = session.me.map { it?.permissionStartAlert == true }.value
        updatePermission.value = !hasPermission.value*/
        
        //--- Set Up xml.startDrill
        //  xml.startDrill.bindVisible(session.me.map { it?.permissionCanStartInternal == true })
        xViewBindVisible(xml.idStartMainAlert, hasPermission);
        xViewBindVisible(xml.idStartMainDrill, hasPermission);
        
        xViewBindVisible(xml.tvNoPermission, updatePermission);
        console.log(`value:--->${this.haDrillEnabled.value}`);
        xViewBindVisible(xml.idStartMainDrill, this.haDrillEnabled);
        
        
        xViewOnClick(xml.idStartMainDrill, undefined, (): void => {
            this.startDrillClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public startAlertClick(): void {
        this.stack.push(new SelectAlertVG((-1), this.alertStack, this.dialog, false, this.schoolId, this.session));
    }
    
    public startDrillClick(): void {
        this.stack.push(new SelectAlertVG((-1), this.alertStack, this.dialog, true, this.schoolId, this.session));
    }
    
    //--- Body End
}
