// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AlertHistoryVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { DateAlone } from 'butterfly-web/dist/time/DateAlone'
import { Time } from '../../util/time'
import { ApiSort } from '../../api/ApiSort'
import { AlertHistoryChild, AlertHistoryDetailed } from '../../model/AlertHistoryDetailed'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { AlertHistoryXml } from '../../layout/AlertHistoryXml'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { xObservablePropertyPlusRx } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { PagingHelper } from '../../util/PagingHelper'
import { debounceTime as rxDebounceTime } from 'rxjs/operators'
import { xViewBindExists, xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { R } from '../../R'
import { xDateButtonBindDateAloneNull } from 'butterfly-web/dist/observables/binding/DateButton.binding'
import { listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyObservableNNGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { ExportOptionsVG } from './ExportOptionsVG'
import { xRecyclerViewBind, xRecyclerViewBindRefresh, xRecyclerViewWhenScrolledToEnd } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { ForeignKey } from '../../model/ForeignKey'
import { ApiQuery } from '../../api/ApiQuery'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { spinnerBind } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { School } from '../../model/School'
import { AlertHistory } from '../../model/AlertHistory'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { Observable } from 'rxjs'
import { AlertHistoryDetailVG } from './AlertHistoryDetailVG'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { SessionApi } from '../../api/SessionApi'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { CombineManyObservableProperty } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ComponentRowAlertHistoryXml } from '../../layout/ComponentRowAlertHistoryXml'
import { ApiPartialQuery } from '../../api/ApiPartialQuery'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.admin.AlertHistoryVG
export class AlertHistoryVG extends ViewGenerator {
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>) {
        super();
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.dialog = dialog;
        this.childList = new StandardObservableProperty<Array<AlertHistoryChild>>([], undefined);
        this.query = new StandardObservableProperty<string>("", undefined);
        this.startDateFilter = new StandardObservableProperty<(DateAlone | null)>(null, undefined);
        this.endDateFilter = new StandardObservableProperty<(DateAlone | null)>(null, undefined);
        this.drillFilter = new StandardObservableProperty<(string | null)>("All", undefined);
        this.userDefinedSort = new StandardObservableProperty<ApiSort<AlertHistoryDetailed>>(AlertHistoryDetailed.Companion.INSTANCE.createdDownSort, undefined);
        this.debouncedApiFilter = xObservablePropertyMap<string, (ApiFilter<AlertHistoryDetailed> | null)>(xObservablePropertyPlusRx<string>(this.query, (it: Observable<string>): Observable<string> => it.pipe(rxDebounceTime(1000))), (it: string): (ApiFilter<AlertHistoryDetailed> | null) => xCharSequenceIsBlank(it) ? null : AlertHistoryDetailed.Companion.INSTANCE.textSearch(it));
        this.startDateApiFilter = xObservablePropertyMap<(DateAlone | null), (ApiFilter<AlertHistoryDetailed> | null)>(this.startDateFilter, (it: (DateAlone | null)): (ApiFilter<AlertHistoryDetailed> | null) => ((): (ApiFilter<AlertHistoryDetailed> | null) => {
            const temp3662 = it;
            if(temp3662 === null) { return null }
            return ((it: DateAlone): ApiFilter<AlertHistoryDetailed> => AlertHistoryDetailed.Companion.INSTANCE.startDateFilter(it))(temp3662)
        })());
        this.endDateApiFilter = xObservablePropertyMap<(DateAlone | null), (ApiFilter<AlertHistoryDetailed> | null)>(this.endDateFilter, (it: (DateAlone | null)): (ApiFilter<AlertHistoryDetailed> | null) => ((): (ApiFilter<AlertHistoryDetailed> | null) => {
            const temp3664 = it;
            if(temp3664 === null) { return null }
            return ((it: DateAlone): ApiFilter<AlertHistoryDetailed> => AlertHistoryDetailed.Companion.INSTANCE.endDateFilter(it))(temp3664)
        })());
        this.drillApiFilter = xObservablePropertyMap<(string | null), (ApiFilter<AlertHistoryDetailed> | null)>(this.drillFilter, (it: (string | null)): (ApiFilter<AlertHistoryDetailed> | null) => ((): (ApiFilter<AlertHistoryDetailed> | null) => {
            const temp3666 = it;
            if(temp3666 === null) { return null }
            return ((it: string): ApiFilter<AlertHistoryDetailed> => AlertHistoryDetailed.Companion.INSTANCE.drillFilter(it))(temp3666)
        })());
        this.schoolApiFilter = xObservablePropertyMap<(School | null), (ApiFilter<AlertHistoryDetailed> | null)>(this.schoolFilter, (it: (School | null)): (ApiFilter<AlertHistoryDetailed> | null) => ((): (ApiFilter<AlertHistoryDetailed> | null) => {
            const temp3668 = it;
            if(temp3668 === null) { return null }
            return ((it: School): ApiFilter<AlertHistoryDetailed> => AlertHistoryDetailed.Companion.INSTANCE.schoolIn([it.id]))(temp3668)
        })());
        this.apiFilters = xObservablePropertyMap<Array<(ApiFilter<AlertHistoryDetailed> | null)>, Array<ApiFilter<AlertHistoryDetailed>>>(new CombineManyObservableProperty<(ApiFilter<AlertHistoryDetailed> | null)>([this.debouncedApiFilter, this.startDateApiFilter, this.endDateApiFilter, this.schoolApiFilter, this.drillApiFilter]), (it: Array<(ApiFilter<AlertHistoryDetailed> | null)>): Array<ApiFilter<AlertHistoryDetailed>> => listFilterNotNull(it));
        this.queryObs = xObservablePropertyObservableNNGet(xObservablePropertyCombine<Array<ApiFilter<AlertHistoryDetailed>>, ApiSort<AlertHistoryDetailed>, ApiQuery<AlertHistoryDetailed>>(this.apiFilters, this.userDefinedSort, (filters: Array<ApiFilter<AlertHistoryDetailed>>, sort: ApiSort<AlertHistoryDetailed>): ApiQuery<AlertHistoryDetailed> => new ApiQuery<AlertHistoryDetailed>(ApiFilter.Companion.INSTANCE.allList<AlertHistoryDetailed>(filters), sort)));
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AlertHistoryVG.title
    public get title(): string { return "Alert History"; }
    
    
    public childList: MutableObservableProperty<Array<AlertHistoryChild>>;
    
    
    public readonly query: MutableObservableProperty<string>;
    
    
    public readonly startDateFilter: MutableObservableProperty<(DateAlone | null)>;
    
    public readonly endDateFilter: MutableObservableProperty<(DateAlone | null)>;
    
    public readonly drillFilter: MutableObservableProperty<(string | null)>;
    
    public readonly userDefinedSort: StandardObservableProperty<ApiSort<AlertHistoryDetailed>>;
    
    public readonly debouncedApiFilter: ObservableProperty<(ApiFilter<AlertHistoryDetailed> | null)>;
    
    public readonly startDateApiFilter: ObservableProperty<(ApiFilter<AlertHistoryDetailed> | null)>;
    
    public readonly endDateApiFilter: ObservableProperty<(ApiFilter<AlertHistoryDetailed> | null)>;
    
    public readonly drillApiFilter: ObservableProperty<(ApiFilter<AlertHistoryDetailed> | null)>;
    
    public readonly schoolApiFilter: ObservableProperty<(ApiFilter<AlertHistoryDetailed> | null)>;
    
    public readonly apiFilters: ObservableProperty<Array<ApiFilter<AlertHistoryDetailed>>>;
    
    public readonly queryObs: Observable<ApiQuery<AlertHistoryDetailed>>;
    
    
    public paging(): PagingHelper<AlertHistoryDetailed> {
        return new PagingHelper<AlertHistoryDetailed>(this.queryObs, (q: ApiQuery<AlertHistoryDetailed>, p: number): Observable<Array<AlertHistoryDetailed>> => this.session.rawApi.alertHistoryDetailed(this.session.session, new ApiPartialQuery<AlertHistoryDetailed>(q, 10, 10 * p)));
    }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertHistoryXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const paging = this.paging();
        
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.query);
        
        //--- Set Up xml.clearDateFilters
        const temp3670 = xml.clearDateFilters;
        if(temp3670 !== null) {
            xViewOnClick(temp3670, undefined, (): void => {
                this.startDateFilter.value = null;
                this.endDateFilter.value = null;
            })
        };
        
        const currentAlert: MutableObservableProperty<string> = new StandardObservableProperty<string>("All", undefined);
        
        const drills: ObservableProperty<Array<string>> = new StandardObservableProperty<Array<string>>(["Alert", "Drill", "All"], undefined);
        
        spinnerBind<string>(xml.drillType, drills, currentAlert, (it: string): string => it);
        
        xObservablePropertySubscribeBy<string>(currentAlert, undefined, undefined, (it: string): void => {
            this.drillFilter.value = it;
        });
        
        xViewBindExists(xml.btnExport, new StandardObservableProperty<boolean>(false, undefined));
        //  xml.tvDrillType.bindExists(StandardObservableProperty(false))
        //  xml.drillType.bindExists(StandardObservableProperty(false))
        
        
        xml.btnExport.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            this.dialog.push(new ExportOptionsVG(this.dialog));
        };
        
        //--- Set Up xml.startDateFilter
        const temp3672 = xml.startDateFilter;
        if(temp3672 !== null) {
            xDateButtonBindDateAloneNull(temp3672, dependency, this.startDateFilter, new ViewStringResource(R._string.start_date))
        };
        
        //--- Set Up xml.endDateFilter
        const temp3673 = xml.endDateFilter;
        if(temp3673 !== null) {
            xDateButtonBindDateAloneNull(temp3673, dependency, this.endDateFilter, new ViewStringResource(R._string.end_date))
        };
        
        //--- Set Up xml.historyLoading
        
        //--- Set Up xml.alertHistories
        xRecyclerViewBindRefresh(xml.alertHistories, paging.loading, (): void => {
            paging.refresh();
        });
        xRecyclerViewWhenScrolledToEnd(xml.alertHistories, (): void => {
            paging.loadNewPage();
        });
        xRecyclerViewBind<AlertHistoryDetailed>(xml.alertHistories, paging, new AlertHistoryDetailed(undefined, (-1), undefined, Time.INSTANCE.date(), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (history: ObservableProperty<AlertHistoryDetailed>): HTMLElement => {
            //--- Make Subview For xml.alertHistories
            const cellXml = new ComponentRowAlertHistoryXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //this was added to show change log but michel changed his mind
            /*   if (history.map { it.childAlertHistory }.value != null){
                childList.value =
                history.map { it.childAlertHistory }.value!!
            }*/
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.xmlRoot, undefined, (): void => {
                this.cellXmlRootClick(history.value.id, history.value.cancelled, `${history.value.cancellerFirstName ?? ""} ${history.value.cancellerLastName ?? ""}`, history.value);
                //this was added to show change log but michel changed his mind
                //  this.cellXmlRootClick(history.value.id, history.map { it.childAlertHistory?: listOf() })
            });
            
            //--- Set Up cellXml.alertTypeIcon
            xImageViewBindImage(cellXml.alertTypeIcon, xObservablePropertyMap<AlertHistoryDetailed, (Image | null)>(history, (it: AlertHistoryDetailed): (Image | null) => ((): (Image | null) => {
                const temp3674 = it.alertTypeIcon;
                if(temp3674 !== null) {
                    return xStringAsImage(temp3674)
                } else { return null }
            })()));
            
            //--- Set Up cellXml.tvCount
            xTextViewBindString(cellXml.tvCount, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => `+ ${it.childAlertHistoryCount}`));
            xViewBindVisible(cellXml.tvCount, xObservablePropertyMap<AlertHistoryDetailed, boolean>(history, (it: AlertHistoryDetailed): boolean => !(it.childAlertHistoryCount === 0)));
            
            //--- Set Up cellXml.alertType
            xTextViewBindString(cellXml.alertType, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => ((): (string | null) => {
                const temp3676 = it.alertTypeName;
                if(temp3676 === null) { return null }
                return temp3676.toUpperCase()
            })() ?? "Loading..."));
            
            //--- Set Up cellXml.site
            xTextViewBindString(cellXml.site, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => ((): (string | null) => {
                const temp3677 = it.siteName;
                if(temp3677 === null) { return null }
                return temp3677.toUpperCase()
            })() ?? "Loading..."));
            
            //--- Set Up cellXml.initiator
            const temp3678 = cellXml.initiator;
            if(temp3678 !== null) {
                xTextViewBindString(temp3678, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => ((): string => {
                    if (it.initiatorFirstName !== null && it.initiatorLastName !== null) {
                        return `${it.initiatorFirstName!.toUpperCase()} ${it.initiatorLastName!.toUpperCase()}`;
                    } else { return "Loading..." }
                })()))
            };
            
            //--- Set Up cellXml.startTime
            xTextViewBindString(cellXml.startTime, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => xDateFormat(it.created, ClockPartSize.Short, ClockPartSize.Short)));
            
            //--- Set Up cellXml.canceller
            const temp3681 = cellXml.canceller;
            if(temp3681 !== null) {
                xTextViewBindString(temp3681, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => ((): string => {
                    if (it.cancellerFirstName !== null && it.cancellerLastName !== null) {
                        return `${it.cancellerFirstName!.toUpperCase()} ${it.cancellerLastName!.toUpperCase()}`;
                    } else { return "--" }
                })()))
            };
            
            //--- Set Up cellXml.endTime
            xTextViewBindString(cellXml.endTime, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => ((): (string | null) => {
                const temp3684 = it.cancelled;
                if(temp3684 !== null) {
                    return xDateFormat(temp3684, ClockPartSize.Short, ClockPartSize.Short)
                } else { return null }
            })() ?? "--"));
            
            //--- Set Up cellXml.reason
            const temp3685 = cellXml.reason;
            if(temp3685 !== null) {
                xTextViewBindString(temp3685, xObservablePropertyMap<AlertHistoryDetailed, string>(history, (it: AlertHistoryDetailed): string => it.cancelReason.toUpperCase()))
            };
            
            
            xCompoundButtonBind(cellXml.drillBox, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<AlertHistoryDetailed, boolean>(history, (it: AlertHistoryDetailed): boolean => it.internal), (valueToWrite: boolean): void => {}));
            //--- End Make Subview For xml.alertHistories (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.alertHeader
        const temp3687 = xml.alertHeader;
        if(temp3687 !== null) {
            xViewOnClick(temp3687, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.alertSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.alertDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.alertSort;
                }
            })
        };
        const temp3688 = xml.sortAlertAscending;
        if(temp3688 !== null) {
            xViewBindVisible(temp3688, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.alertDownSort)))
        };
        const temp3689 = xml.sortAlertDescending;
        if(temp3689 !== null) {
            xViewBindVisible(temp3689, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.alertSort)))
        };
        
        //--- Set Up xml.siteHeader
        const temp3690 = xml.siteHeader;
        if(temp3690 !== null) {
            xViewOnClick(temp3690, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.siteSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.siteDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.siteSort;
                }
            })
        };
        const temp3691 = xml.sortSiteAscending;
        if(temp3691 !== null) {
            xViewBindVisible(temp3691, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.siteDownSort)))
        };
        const temp3692 = xml.sortSiteDescending;
        if(temp3692 !== null) {
            xViewBindVisible(temp3692, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.siteSort)))
        };
        
        //--- Set Up xml.initiatorHeader
        const temp3693 = xml.initiatorHeader;
        if(temp3693 !== null) {
            xViewOnClick(temp3693, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.initiatorSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.initiatorDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.initiatorSort;
                }
            })
        };
        const temp3694 = xml.sortInitiatorAscending;
        if(temp3694 !== null) {
            xViewBindVisible(temp3694, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.initiatorDownSort)))
        };
        const temp3695 = xml.sortInitiatorDescending;
        if(temp3695 !== null) {
            xViewBindVisible(temp3695, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.initiatorSort)))
        };
        
        //--- Set Up xml.startHeader
        const temp3696 = xml.startHeader;
        if(temp3696 !== null) {
            xViewOnClick(temp3696, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.createdSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.createdDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.createdSort;
                }
            })
        };
        const temp3697 = xml.sortStartTimeAscending;
        if(temp3697 !== null) {
            xViewBindVisible(temp3697, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.createdDownSort)))
        };
        const temp3698 = xml.sortStartTimeDescending;
        if(temp3698 !== null) {
            xViewBindVisible(temp3698, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.createdSort)))
        };
        
        //--- Set Up xml.cancellerHeader
        const temp3699 = xml.cancellerHeader;
        if(temp3699 !== null) {
            xViewOnClick(temp3699, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.cancellerSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.cancellerDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.cancellerSort;
                }
            })
        };
        const temp3700 = xml.sortCancellerAscending;
        if(temp3700 !== null) {
            xViewBindVisible(temp3700, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.cancellerDownSort)))
        };
        const temp3701 = xml.sortCancellerDescending;
        if(temp3701 !== null) {
            xViewBindVisible(temp3701, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.cancellerSort)))
        };
        
        //--- Set Up xml.endHeader
        const temp3702 = xml.endHeader;
        if(temp3702 !== null) {
            xViewOnClick(temp3702, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.cancelledSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.cancelledDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.cancelledSort;
                }
            })
        };
        const temp3703 = xml.sortEndTimeAscending;
        if(temp3703 !== null) {
            xViewBindVisible(temp3703, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.cancelledDownSort)))
        };
        const temp3704 = xml.sortEndTimeDescending;
        if(temp3704 !== null) {
            xViewBindVisible(temp3704, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.cancelledSort)))
        };
        
        //--- Set Up xml.cancelReasonHeader
        const temp3705 = xml.cancelReasonHeader;
        if(temp3705 !== null) {
            xViewOnClick(temp3705, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, AlertHistoryDetailed.Companion.INSTANCE.cancelReasonSort)) {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.cancelReasonDownSort;
                } else {
                    this.userDefinedSort.value = AlertHistoryDetailed.Companion.INSTANCE.cancelReasonSort;
                }
            })
        };
        const temp3706 = xml.sortCancelReasonAscending;
        if(temp3706 !== null) {
            xViewBindVisible(temp3706, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.cancelReasonDownSort)))
        };
        const temp3707 = xml.sortCancelReasonDescending;
        if(temp3707 !== null) {
            xViewBindVisible(temp3707, xObservablePropertyMap<ApiSort<AlertHistoryDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<AlertHistoryDetailed>): boolean => !safeEq(it, AlertHistoryDetailed.Companion.INSTANCE.cancelReasonSort)))
        };
        
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cellXmlRootClick(historyId: ForeignKey<AlertHistory>, cancelled: (Date | null), canceller: string, value: AlertHistoryDetailed): void {
        this.stack.push(new AlertHistoryDetailVG(historyId, this.session, this.stack, cancelled, canceller, value));
    }
    
    //--- Body End
}
