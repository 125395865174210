// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/schoolsList.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { setViewBackgroundColor, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { R } from '../R'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { getNO_ID } from '../model/HasId'
import { AlertClassificationEnum, xAlertClassificationEnumBackground2ColorGet } from '../model/AlertClassificationEnum'
import { DrawableResource } from 'butterfly-web/dist/views/DrawableResource'
import { xRecyclerViewBindMulti } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { ComponentRowSchoolTypeXml } from '../layout/ComponentRowSchoolTypeXml'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { unreadSchoolObs, xSessionApiUnreadAny } from '../api/Session.read'
import { Alert } from '../model/Alert'
import { SessionApi } from '../api/SessionApi'
import { numberToColor } from 'butterfly-web/dist/views/Colors'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { SubscriptionLike } from 'rxjs'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { School } from '../model/School'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { ComponentRowSchoolNameXml } from '../layout/ComponentRowSchoolNameXml'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.SchoolRow
export class SchoolRow {
    public readonly header: (SchoolHeader | null);
    public readonly school: (School | null);
    public constructor(header: (SchoolHeader | null) = null, school: (School | null) = null) {
        this.header = header;
        this.school = school;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.header);
        hash = 31 * hash + hashAnything(this.school);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof SchoolRow && safeEq(this.header, other.header) && safeEq(this.school, other.school) }
    public toString(): string { return `SchoolRow(header=${this.header}, school=${this.school})` }
    public copy(header: (SchoolHeader | null) = this.header, school: (School | null) = this.school): SchoolRow { return new SchoolRow(header, school); }
}

//! Declares com.tresitgroup.android.tresit.vg.SchoolHeader
export class SchoolHeader {
    public readonly title: string;
    public readonly icon: DrawableResource;
    public readonly textColor: string;
    public constructor(title: string, icon: DrawableResource, textColor: string) {
        this.title = title;
        this.icon = icon;
        this.textColor = textColor;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.title);
        hash = 31 * hash + hashAnything(this.icon);
        hash = 31 * hash + hashAnything(this.textColor);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof SchoolHeader && safeEq(this.title, other.title) && safeEq(this.icon, other.icon) && safeEq(this.textColor, other.textColor) }
    public toString(): string { return `SchoolHeader(title=${this.title}, icon=${this.icon}, textColor=${this.textColor})` }
    public copy(title: string = this.title, icon: DrawableResource = this.icon, textColor: string = this.textColor): SchoolHeader { return new SchoolHeader(title, icon, textColor); }
}

//! Declares com.tresitgroup.android.tresit.vg.bindSchoolSelect>com.lightningkite.butterfly.views.widget.VerticalRecyclerView
export function xVerticalRecyclerViewBindSchoolSelect(this_: HTMLDivElement, dependency: Window, session: SessionApi, filter: ObservableProperty<string>, highlight: ObservableProperty<(School | null)>, backingColor: ObservableProperty<AlertClassificationEnum>, onSelect:  ((a: (School | null)) => void)): void {
    const allAlerts = session.alerts.observableListSimple(undefined, Alert.Companion.INSTANCE.createdSort, undefined, undefined, undefined, undefined);
    
    const schools = xObservablePropertyCombine<Array<School>, string, Array<School>>(xObservablePropertyMap<(Array<School> | null), Array<School>>(session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => ((): (Array<School> | null) => {
        const temp5221 = it;
        if(temp5221 === null) { return null }
        const temp5222 = (it: School): (string | null) => it.name;
        return temp5221.slice().sort((a, b) => safeCompare(temp5222(a), temp5222(b)))
    })() ?? []), filter, (it: Array<School>, f: string): Array<School> => it.filter((it: School): boolean => (it.name.toLowerCase().indexOf(f.toLowerCase()) != -1)));
    
    const schoolsWithAlert = xObservablePropertyCombine<Array<School>, (Array<Alert> | null), Array<School>>(schools, allAlerts, (schoolList: Array<School>, alerts: (Array<Alert> | null)): Array<School> => schoolList.filter((s: School): boolean => ((): (boolean | null) => {
        const temp5229 = alerts;
        if(temp5229 === null) { return null }
        return temp5229.some((it: Alert): boolean => (!it.drill) && it.school === s.id)
    })() === true));
    
    const schoolsWithDrill = xObservablePropertyCombine<Array<School>, (Array<Alert> | null), Array<School>>(schools, allAlerts, (schoolList: Array<School>, alerts: (Array<Alert> | null)): Array<School> => schoolList.filter((s: School): boolean => ((): (boolean | null) => {
        const temp5236 = alerts;
        if(temp5236 === null) { return null }
        return temp5236.some((it: Alert): boolean => it.drill && it.school === s.id)
    })() === true));
    
    const schoolsWithNone = xObservablePropertyCombine<Array<School>, (Array<Alert> | null), Array<School>>(schools, allAlerts, (schoolList: Array<School>, alerts: (Array<Alert> | null)): Array<School> => schoolList.filter((s: School): boolean => ((): (boolean | null) => {
        const temp5242 = alerts;
        if(temp5242 === null) { return null }
        return (!temp5242.some((it: Alert): boolean => it.school === s.id))
    })() === true));
    
    const schoolsWithAlertRows = xObservablePropertyMap<Array<School>, Array<SchoolRow>>(schoolsWithAlert, (it: Array<School>): Array<SchoolRow> => it.length === 0 ? [] : [new SchoolRow(new SchoolHeader(R._string.alert_in_progress, R.drawable.ic_red_flag, R.color.unsafe), undefined)].concat(it.map((it: School): SchoolRow => new SchoolRow(undefined, it))));
    
    const schoolsWithDrillRows = xObservablePropertyMap<Array<School>, Array<SchoolRow>>(schoolsWithDrill, (it: Array<School>): Array<SchoolRow> => it.length === 0 ? [] : [new SchoolRow(new SchoolHeader(R._string.drill_in_progress, R.drawable.ic_blue_flag, R.color.drill), undefined)].concat(it.map((it: School): SchoolRow => new SchoolRow(undefined, it))));
    
    const schoolsWithNoneRows = xObservablePropertyMap<Array<School>, Array<SchoolRow>>(schoolsWithNone, (it: Array<School>): Array<SchoolRow> => it.length === 0 ? [] : [new SchoolRow(new SchoolHeader(R._string.all_is_well, R.drawable.ic_green_flag, R.color.safe), undefined)].concat(it.map((it: School): SchoolRow => new SchoolRow(undefined, it))));
    
    const allRows = xObservablePropertyCombine<Array<SchoolRow>, Array<SchoolRow>, Array<SchoolRow>>(xObservablePropertyCombine<Array<SchoolRow>, Array<SchoolRow>, Array<SchoolRow>>(xObservablePropertyCombine<Array<SchoolRow>, Array<SchoolRow>, Array<SchoolRow>>(new ConstantObservableProperty<Array<SchoolRow>>([new SchoolRow(undefined, undefined)]), schoolsWithAlertRows, (a: Array<SchoolRow>, b: Array<SchoolRow>): Array<SchoolRow> => a.concat(b)), schoolsWithDrillRows, (a: Array<SchoolRow>, b: Array<SchoolRow>): Array<SchoolRow> => a.concat(b)), schoolsWithNoneRows, (a: Array<SchoolRow>, b: Array<SchoolRow>): Array<SchoolRow> => a.concat(b));
    
    
    xRecyclerViewBindMulti<SchoolRow>(this_, allRows, new SchoolRow(undefined, undefined), (it: SchoolRow): number => it.header !== null ? 0 : it.school !== null ? 1 : 2, (type: number, obs: ObservableProperty<SchoolRow>): HTMLElement => ((): HTMLElement => {
        if (type === 0) {
            const xml = new ComponentRowSchoolTypeXml();
            
            const view = xml.setup(dependency);
            
            
            const typedObs = xObservablePropertyMap<SchoolRow, SchoolHeader>(obs, (it: SchoolRow): SchoolHeader => it.header ?? new SchoolHeader(R._string.all_is_well, R.drawable.ic_green_flag, R.color.safe));
            
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<SchoolHeader>(typedObs, undefined, undefined, (it: SchoolHeader): void => {
                imageViewSetImageResource(xml.icon, it.icon);
            }), xViewRemovedGet(xml.icon));
            
            xTextViewBindString(xml.title, xObservablePropertyMap<SchoolHeader, string>(typedObs, (it: SchoolHeader): string => it.title));
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<SchoolHeader>(typedObs, undefined, undefined, (it: SchoolHeader): void => {
                xml.title.style.color = it.textColor;
            }), xViewRemovedGet(xml.title));
            
            return view;
        } else if (type === 1) {
            const xml = new ComponentRowSchoolNameXml();
            
            const view = xml.setup(dependency);
            
            
            const typedObs = xObservablePropertyMap<SchoolRow, School>(obs, (it: SchoolRow): School => it.school ?? new School(undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
            
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<[boolean, AlertClassificationEnum]>(xObservablePropertyCombine<boolean, AlertClassificationEnum, [boolean, AlertClassificationEnum]>(xObservablePropertyCombine<(School | null), School, boolean>(highlight, typedObs, (h: (School | null), s: School): boolean => safeEq(h, s)), backingColor, (s: boolean, col: AlertClassificationEnum): [boolean, AlertClassificationEnum] => [s, col]), undefined, undefined, (pair: [boolean, AlertClassificationEnum]): void => {
                const selected = pair[0];
                
                const backColor = pair[1];
                
                if (selected) {
                    setViewBackgroundColor(view, R.color.barForeground);
                    xml.schoolName.style.color = xAlertClassificationEnumBackground2ColorGet(backColor);
                } else {
                    setViewBackgroundColor(view, R.color.none);
                    xml.schoolName.style.color = R.color.barForeground;
                }
            }), xViewRemovedGet(view));
            xTextViewBindString(xml.schoolName, xObservablePropertyMap<School, string>(typedObs, (it: School): string => it.name));
            xViewBindExists(xml.unreadIndicator, unreadSchoolObs(session, xObservablePropertyMap<SchoolRow, number>(obs, (it: SchoolRow): number => (it.school?.id ?? null) ?? getNO_ID())));
            
            xViewOnClick(view, undefined, (): void => {
                onSelect(typedObs.value);
            });
            return view;
        } else {
            const xml = new ComponentRowSchoolNameXml();
            
            const view = xml.setup(dependency);
            
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<[boolean, AlertClassificationEnum]>(xObservablePropertyCombine<boolean, AlertClassificationEnum, [boolean, AlertClassificationEnum]>(xObservablePropertyMap<(School | null), boolean>(highlight, (it: (School | null)): boolean => it === null), backingColor, (s: boolean, col: AlertClassificationEnum): [boolean, AlertClassificationEnum] => [s, col]), undefined, undefined, (pair: [boolean, AlertClassificationEnum]): void => {
                const selected = pair[0];
                
                const backColor = pair[1];
                
                if (selected) {
                    setViewBackgroundColor(view, R.color.barForeground);
                    xml.schoolName.style.color = xAlertClassificationEnumBackground2ColorGet(backColor);
                } else {
                    setViewBackgroundColor(view, R.color.none);
                    xml.schoolName.style.color = R.color.barForeground;
                }
            }), xViewRemovedGet(view));
            
            setViewText(xml.schoolName, R._string.all_sites);
            xViewBindExists(xml.unreadIndicator, xSessionApiUnreadAny(session));
            
            xViewOnClick(view, undefined, (): void => {
                onSelect(null);
            });
            return view;
        }
    })());
    
    //    val schoolsWithDrill = drills.combine(schools) { drills, schools ->
        //        val schoolIds = drills?.map { it.school }
        //        schools.filter { schoolIds?.contains(it?.id ?: 0L) ?: false }
    //    }
    //    val otherSchools = allAlerts.combine(session.schools.observableList(
        //        filter = School.userBelongsTo(session.userId)
    //    ).map { it?.sortedBy { it.name } ?: listOf() }) { alerts, schools ->
        //        if (alerts != null) schools.filter { s -> alerts.none { it.school == s.id } }
        //        else schools
    //    }.combine(filter) { list, filter ->
        //        list.filter {
            //            it.name.contains(
                //                filter,
                //                true
            //            )
        //        }
    //    }
    //    val rows = session.schools.observableList(
        //        filter = School.userBelongsTo(session.userId),
        //        limit = 300
    //    ).map {
        //
    //    }
    //
    //    //--- Set Up xml.allSchools
    //    //--- Set Up xml.allSchoolsText
    //    xml.allSchools?.let { xml ->
        //        xml.schoolName.textResource = R.string.all_sites
        //        schoolFilter
        //            .combine(activeAlerts) { c, d -> c to d }
        //            .subscribeBy {
            //                val chosen = it.first == null
            //                val alerts = it.second
            //                if (chosen) {
                //                    xml.xmlRoot.alpha = 1.0f
                //                    xml.xmlRoot.setBackgroundColorResource(R.color.barForeground)
                //                    when {
                    //                        alerts != null && alerts.any { !it.drill } -> xml.schoolName.setTextColor(
                        //                            dependency.getColor(
                            //                                R.color.barBackgroundAlertFade2
                        //                            )
                    //                        )
                    //                        alerts != null && alerts.isNotEmpty() -> xml.schoolName.setTextColor(
                        //                            dependency.getColor(
                            //                                R.color.barBackgroundDrillFade2
                        //                            )
                    //                        )
                    //                        else -> xml.schoolName.setTextColor(dependency.getColor(R.color.barBackgroundFade2))
                //                    }
            //                } else {
                //                    xml.xmlRoot.setBackgroundColorResource(R.color.none)
                //                    xml.schoolName.setTextColor(dependency.getColor(R.color.barForeground))
            //                }
        //            }.until(xml.xmlRoot.removed)
        //        xml.xmlRoot.onClick {
            //            schoolFilter.value = null
        //        }
        //
        ////            xml.unreadIndicator.bindExists(unreadIndicator(null))
    //    }
    //
    //    //--- Set Up xml.alertsHeader
    //    xml.alertsHeader?.bindExists(realAlerts.map { it?.isNotEmpty() == true })
    //
    //    //--- Set Up xml.alertSchools
    //
    //    xml.alertSchools?.bind(
        //        data = schoolsWithAlert.combine(filter) { list, filter ->
            //            list.filter {
                //                it == null || it.name.contains(
                    //                    filter,
                    //                    true
                //                )
            //            }
        //        },
        //        defaultValue = null,
        //        makeView = label@{ observable ->
            //            //--- Make Subview For xml.schools
            //            val cellXml = ComponentRowSchoolNameXml()
            //            val cellView = cellXml.setup(dependency)
            //
            //            //--- Set Up cellXml.root
            //            cellXml.xmlRoot.onClick {
                //                selectedIndex.value = observable.value?.name ?: ""
                //                this.schoolsClick(observable.value)
                //                this.messagesTabClick()
            //            }
            //
            ////                //--- Set Up cellXml.unreadIndicator
            ////                cellXml.unreadIndicator.bindExists(observable.flatMap { unreadIndicator(it?.id) })
            //
            //            //--- Set Up cellXml.schoolName
            //            cellXml.schoolName.bindString(observable.map { it?.name ?: "" })
            //
            //            schoolFilter.combine(observable) { a, b -> a?.id == b?.id }
            //                .combine(activeAlerts) { c, d -> c to d }
            //                .subscribeBy {
                //                    val chosen = it.first
                //                    val alerts = it.second
                //                    if (chosen) {
                    //                        cellView.alpha = 1.0f
                    //                        cellView.setBackgroundColorResource(R.color.barForeground)
                    //                        when {
                        //                            alerts != null && alerts.any { !it.drill } -> cellXml.schoolName.setTextColor(
                            //                                dependency.getColor(
                                //                                    R.color.barBackgroundAlertFade2
                            //                                )
                        //                            )
                        //                            alerts != null && alerts.isNotEmpty() -> cellXml.schoolName.setTextColor(
                            //                                dependency.getColor(
                                //                                    R.color.barBackgroundDrillFade2
                            //                                )
                        //                            )
                        //                            else -> cellXml.schoolName.setTextColor(dependency.getColor(R.color.barBackgroundFade2))
                    //                        }
                //                    } else {
                    //                        cellView.setBackgroundColorResource(R.color.none)
                    //
                    //                        cellXml.schoolName.setTextColor(dependency.getColor(R.color.barForeground))
                //                    }
            //                }
            //
            //            //--- End Make Subview For xml.schools
            //            return@label cellView
        //        }
    //    )
    //
    //    //--- Set Up xml.drillsHeader
    //    xml.drillsHeader?.bindExists(drills.map { it?.isNotEmpty() == true })
    //
    //    //--- Set Up xml.alertTabSchools
    //
    //    //--- Set Up xml.drillSchools
    //    xml.drillSchools?.bind(
        //        data = schoolsWithDrill.combine(filter) { list, filter ->
            //            list.filter {
                //                it == null || it.name.contains(
                    //                    filter,
                    //                    true
                //                )
            //            }
        //        },
        //        defaultValue = null,
        //        makeView = label@{ observable ->
            //            //--- Make Subview For xml.schools
            //            val cellXml = ComponentRowSchoolNameXml()
            //            val cellView = cellXml.setup(dependency)
            //
            //            //--- Set Up cellXml.root
            //            cellXml.xmlRoot.onClick {
                //                selectedIndex.value = observable.value?.name ?: ""
                //                this.schoolsClick(observable.value)
                //                alertTabClick()
            //            }
            //
            ////                //--- Set Up cellXml.unreadIndicator
            ////                cellXml.unreadIndicator.bindExists(observable.flatMap { unreadIndicator(it?.id) })
            //
            //            //--- Set Up cellXml.schoolName
            //            cellXml.schoolName.bindString(observable.map { it?.name ?: "" })
            //
            //            schoolFilter.combine(observable) { a, b -> a?.id == b?.id }
            //                .combine(activeAlerts) { c, d -> c to d }
            //                .subscribeBy {
                //                    val chosen = it.first
                //                    val alerts = it.second
                //                    if (chosen) {
                    //                        cellView.alpha = 1.0f
                    //                        cellView.setBackgroundColorResource(R.color.barForeground)
                    //                        when {
                        //                            alerts != null && alerts.any { !it.drill } -> cellXml.schoolName.setTextColor(
                            //                                dependency.getColor(
                                //                                    R.color.barBackgroundAlertFade2
                            //                                )
                        //                            )
                        //                            alerts != null && alerts.isNotEmpty() -> cellXml.schoolName.setTextColor(
                            //                                dependency.getColor(
                                //                                    R.color.barBackgroundDrillFade2
                            //                                )
                        //                            )
                        //                            else -> cellXml.schoolName.setTextColor(dependency.getColor(R.color.barBackgroundFade2))
                    //                        }
                //                    } else {
                    //                        cellView.setBackgroundColorResource(R.color.none)
                    //
                    //                        cellXml.schoolName.setTextColor(dependency.getColor(R.color.barForeground))
                    ////                            cellXml.schoolName.setTypeface(null, Typeface.NORMAL)
                    //
                //                    }
            //                }
            //
            //            //--- End Make Subview For xml.schools
            //            return@label cellView
        //        }
    //    )
    //
    //    //--- Set Up xml.schools
    //    xml.schoolNames?.bind(
        //        data = allAlerts.combine(session.schools.observableList(
            //            filter = School.userBelongsTo(session.userId)
        //        ).map { it?.sortedBy { it.name } ?: listOf() }) { alerts, schools ->
            //            if (alerts != null) schools.filter { s -> alerts.none { it.school == s.id } }
            //            else schools
        //        }.combine(filter) { list, filter ->
            //            list.filter {
                //                it.name.contains(
                    //                    filter,
                    //                    true
                //                )
            //            }
        //        },
        //        defaultValue = School(name = "Generic School"),
        //        makeView = label@{ observable ->
            //            //--- Make Subview For xml.schools
            //            val cellXml = ComponentRowSchoolNameXml()
            //            val cellView = cellXml.setup(dependency)
            //
            //            //--- Set Up cellXml.root
            //            cellXml.xmlRoot.onClick {
                //                selectedIndex.value = observable.value.name
                //                this.schoolsClick(observable.value)
                //                alertTabClick()
            //            }
            //
            ////                //--- Set Up cellXml.unreadIndicator
            ////                cellXml.unreadIndicator.bindExists(observable.flatMap { unreadIndicator(it.id) })
            //
            //            //--- Set Up cellXml.schoolName
            //            cellXml.schoolName.bindString(observable.map { it.name })
            //
            //            schoolFilter.combine(observable) { a, b -> a?.id == b.id }
            //                .combine(activeAlerts) { c, d -> c to d }
            //                .subscribeBy {
                //                    val chosen = it.first
                //                    val alerts = it.second
                //                    if (chosen) {
                    //                        cellView.alpha = 1.0f
                    //                        cellView.setBackgroundColorResource(R.color.barForeground)
                    //                        when {
                        //                            alerts != null && alerts.any { !it.drill } -> cellXml.schoolName.setTextColor(
                            //                                dependency.getColor(
                                //                                    R.color.barBackgroundAlertFade2
                            //                                )
                        //                            )
                        //                            alerts != null && alerts.isNotEmpty() -> cellXml.schoolName.setTextColor(
                            //                                dependency.getColor(
                                //                                    R.color.barBackgroundDrillFade2
                            //                                )
                        //                            )
                        //                            else -> cellXml.schoolName.setTextColor(dependency.getColor(R.color.barBackgroundFade2))
                    //                        }
                //                    } else {
                    //                        cellView.setBackgroundColorResource(R.color.none)
                    //
                    //                        cellXml.schoolName.setTextColor(dependency.getColor(R.color.barForeground))
                    ////                            cellXml.schoolName.setTypeface(null, Typeface.NORMAL)
                    //
                //                    }
            //                }
            //
            //            //--- End Make Subview For xml.schools
            //            return@label cellView
        //        }
    //    )
}