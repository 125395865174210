// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/FormAlertTypeVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Image, ImageReference, ImageRemoteUrl, xUriAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xActivityAccessRequestFile, xActivityAccessRequestImageGallery } from 'butterfly-web/dist/views/ViewDependency'
import { CacheEdge } from '../../api/CacheEdge'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { getNO_ID } from '../../model/HasId'
import { FormAlertTypeXml } from '../../layout/FormAlertTypeXml'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { ComponentGuidanceXml } from '../../layout/ComponentGuidanceXml'
import { AlertType } from '../../model/AlertType'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xAutoCompleteTextViewBindList } from 'butterfly-web/dist/observables/binding/AutoCompleteTextView.binding'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { Platform } from 'butterfly-web/dist/Platform'
import { ApiFilter } from '../../api/ApiFilter'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { xCharSequenceIsBlank, xStringSubstringAfterLast, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { SelectGroupVg } from '../SelectGroupVg'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { listRemoveItem } from 'butterfly-web/dist/KotlinCollections'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { DialogWrapperVG } from '../DialogWrapperVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { AlertGuidance } from '../../model/AlertGuidance'
import { xIterableDistinctBy } from 'butterfly-web/dist/kotlin/Iterables'
import { xMutableObservablePropertyMapWithExisting } from 'butterfly-web/dist/observables/TransformedMutableObservableProperty2'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.FormAlertTypeVG
export class FormAlertTypeVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly alertType: MutableObservableProperty<AlertType>;
    public readonly guidances: MutableObservableProperty<Array<AlertGuidance>>;
    public readonly session: SessionApi;
    public readonly editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>;
    public readonly alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>;
    public readonly isAdd: boolean;
    public constructor(dialog: ObservableStack<ViewGenerator>, alertType: MutableObservableProperty<AlertType>, guidances: MutableObservableProperty<Array<AlertGuidance>>, session: SessionApi, editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>, alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>, isAdd: boolean = false) {
        super();
        this.dialog = dialog;
        this.alertType = alertType;
        this.guidances = guidances;
        this.session = session;
        this.editedAllGroupsObservable = editedAllGroupsObservable;
        this.alreadySelectedGroupObservable = alreadySelectedGroupObservable;
        this.isAdd = isAdd;
        this.schoolFilter = new StandardObservableProperty<(School | null)>(null, undefined);
        this.isFetched = false;
        this.hasPermission = new StandardObservableProperty<boolean>(true, undefined);
        this.selectedGroupName = new StandardObservableProperty<string>("", undefined);
        this.icon = new StandardObservableProperty<(File | null)>(null, undefined);
        this.mp3 = new StandardObservableProperty<(File | null)>(null, undefined);
        this.ogg = new StandardObservableProperty<(File | null)>(null, undefined);
        this.iconActual = xObservablePropertyCombine<AlertType, (File | null), (Image | null)>(this.alertType, this.icon, (alertType: AlertType, icon: (File | null)): (Image | null) => ((): (Image | null) => {
            if (icon === null) {
                return ((): (ImageRemoteUrl | null) => {
                    if (((): (boolean | null) => {
                        const temp3861 = alertType.icon;
                        if(temp3861 === null) { return null }
                        return !xCharSequenceIsBlank(temp3861)
                    })() === true) {
                        return new ImageRemoteUrl(alertType.icon!);
                    } else {
                        return null;
                    }
                })()
            } else {
                return new ImageReference(icon!);
            }
        })());
        this.newGuidance = new StandardObservableProperty<(AlertGuidance | null)>(null, undefined);
        this.selectedGroup = Array.from(this.alreadySelectedGroupObservable.value);
        this.allgroups = Array.from(this.editedAllGroupsObservable.value);
        this.editedAllGroups = Array.from(this.editedAllGroupsObservable.value);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<School>>(this.session.schools.getListSimple(undefined, undefined, undefined, undefined, undefined), undefined, (it: Array<School>): void => {
            if (it.length === 1) {
                this.schoolFilter.value = it[0];
            }
        }));
        this.selectedGroup = Array.from(this.alreadySelectedGroupObservable.value);
    }
    
    
    public readonly schoolFilter: StandardObservableProperty<(School | null)>;
    
    public isFetched: boolean;
    
    
    public readonly hasPermission: MutableObservableProperty<boolean>;
    
    
    public readonly selectedGroupName: MutableObservableProperty<string>;
    
    
    public readonly icon: MutableObservableProperty<(File | null)>;
    
    public readonly mp3: MutableObservableProperty<(File | null)>;
    
    public readonly ogg: MutableObservableProperty<(File | null)>;
    
    public readonly iconActual: ObservableProperty<(Image | null)>;
    
    
    public readonly newGuidance: MutableObservableProperty<(AlertGuidance | null)>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.FormAlertTypeVG.title
    public get title(): string { return "Form Alert Type"; }
    
    public recentUserThreads(school: ForeignKey<School>): CacheEdge<ChatThread> {
        return this.session.threads.observableListSimple(ApiFilter.Companion.INSTANCE.all<ChatThread>(ChatThread.Companion.INSTANCE.isGroupThread(), ChatThread.Companion.INSTANCE.forSchool(school)), undefined, undefined, undefined, undefined, undefined);
    }
    
    public selectedGroup: Array<ChatThread>;
    
    
    public allgroups: Array<ChatThread>;
    
    public editedAllGroups: Array<ChatThread>;
    
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new FormAlertTypeXml();
        
        const view = xml.setup(dependency);
        
        
        
        //--- From XMl
        this.generateFromXml(dependency, xml);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public generateFromXml(dependency: Window, xml: FormAlertTypeXml): void {
        xAutoCompleteTextViewBindList<string>(xml.actType, xObservablePropertyMap<Array<string>, Array<string>>(new StandardObservableProperty<Array<string>>(["attention", "emergency"], undefined), (it: Array<string>): Array<string> => it), (it: string): string => it, (it: string): void => {
            this.alertType.value = this.alertType.value.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, it, undefined);
            xEditTextBindString(xml.actType, new StandardObservableProperty<string>(this.alertType.value.urgency, undefined));
        });
        xEditTextBindString(xml.actType, new StandardObservableProperty<string>(this.alertType.value.urgency, undefined));
        
        
        xObservablePropertySubscribeBy<(Array<ChatThread> | null)>(this.recentUserThreads(this.alertType.value.school), undefined, undefined, (it: (Array<ChatThread> | null)): void => {
            if (it !== null) {
                this.allgroups = Array.from(it!);
            }
            if (it !== null) {
                this.editedAllGroups = Array.from(it!);
            }
            if (it !== null) {
                for (const chatThread of it!) {
                    if (((): (boolean | null) => {
                        const temp3868 = this.alertType.value.groups;
                        if(temp3868 === null) { return null }
                        return temp3868.some((x) => safeEq(chatThread.id, x))
                    })() === true) {
                        this.selectedGroup.push(chatThread);
                        
                        const newItem = chatThread.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
                        
                        listRemoveItem(this.editedAllGroups, chatThread);
                        this.editedAllGroups.push(newItem);
                    }
                }
            }
            this.editedAllGroupsObservable.value = this.editedAllGroups;
            this.alreadySelectedGroupObservable.value = this.selectedGroup;
            const uniqueValue = xIterableDistinctBy<ChatThread, number>(this.selectedGroup, (it: ChatThread): number => it.id);
            
            this.selectedGroupName.value = uniqueValue.map((it: ChatThread): string => it.name ?? "").toString();
            
            xTextViewBindString(xml.tvGroups, this.selectedGroupName);
            xViewBindExists(xml.showToAllGroup, new StandardObservableProperty<boolean>(!(this.selectedGroupName.value === "[]"), undefined));
        });
        
        //--- Set Up xml.alertName
        xEditTextBindString(xml.alertName, xMutableObservablePropertyMapWithExisting<AlertType, string>(this.alertType, (it: AlertType): string => it.name, (alert: AlertType, value: string): AlertType => alert.copy(undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        //  alreadySelected = alertType.value.groups.orEmpty().toMutableList()
        //--- Set Up xml.iconImage
        if (safeEq(Platform.Companion.INSTANCE.current, Platform.Web)) {
            xImageViewBindImage(xml.iconImage, xObservablePropertyCombine<AlertType, (File | null), (Image | null)>(this.alertType, this.icon, (alertType: AlertType, icon: (File | null)): (Image | null) => ((): (Image | null) => {
                if(icon !== null) {
                    return xUriAsImage(icon)
                } else { return null }
            })()));
        } else {
            xImageViewBindImage(xml.iconImage, this.iconActual);
        }
        
        
        //--- Set Up xml.iconChange
        xViewOnClick(xml.iconChange, undefined, (): void => {
            this.iconChangeClick(dependency);
        });
        
        //--- Set Up xml.mp3Change
        xTextViewBindString(xml.mp3Change, xObservablePropertyCombine<(File | null), AlertType, string>(this.mp3, this.alertType, (uri: (File | null), alert: AlertType): string => ((): string => {
            if (uri !== null || alert.mp3File !== null) {
                return R._string.change;
            } else {
                return R._string.add_sound;
            }
        })()));
        xViewOnClick(xml.mp3Change, undefined, (): void => {
            this.mp3ChangeClick(dependency);
        });
        
        //--- Set Up xml.mp3Name
        xTextViewBindString(xml.mp3Name, xObservablePropertyCombine<(File | null), AlertType, string>(this.mp3, this.alertType, (uri: (File | null), alert: AlertType): string => ((): (string | null) => {
                    const temp3878 = uri;
                    if(temp3878 === null) { return null }
                    return temp3878.name
            })() ?? ((): (string | null) => {
                const temp3879 = ((): (string | null) => {
                    const temp3880 = alert.mp3File;
                    if(temp3880 !== null) {
                        return xStringSubstringAfterLast(temp3880, "/", undefined)
                    } else { return null }
                })();
                if(temp3879 !== null) {
                    return xStringSubstringBefore(temp3879, ".mp3", undefined)
                } else { return null }
        })() ?? ""));
        xViewBindExists(xml.mp3Name, xObservablePropertyCombine<(File | null), AlertType, boolean>(this.mp3, this.alertType, (uri: (File | null), alert: AlertType): boolean => uri !== null || alert.mp3File !== null));
        
        //--- Set Up xml.oggChange
        xTextViewBindString(xml.oggChange, xObservablePropertyCombine<(File | null), AlertType, string>(this.ogg, this.alertType, (uri: (File | null), alert: AlertType): string => ((): string => {
            if (uri !== null || alert.oggFile !== null) {
                return R._string.change;
            } else {
                return R._string.add_sound;
            }
        })()));
        xViewOnClick(xml.oggChange, undefined, (): void => {
            this.oggChangeClick(dependency);
        });
        
        //--- Set Up xml.oggName
        xTextViewBindString(xml.oggName, xObservablePropertyCombine<(File | null), AlertType, string>(this.ogg, this.alertType, (uri: (File | null), alert: AlertType): string => ((): (string | null) => {
                    const temp3883 = uri;
                    if(temp3883 === null) { return null }
                    return temp3883.name
            })() ?? ((): (string | null) => {
                const temp3884 = ((): (string | null) => {
                    const temp3885 = alert.oggFile;
                    if(temp3885 !== null) {
                        return xStringSubstringAfterLast(temp3885, "/", undefined)
                    } else { return null }
                })();
                if(temp3884 !== null) {
                    return xStringSubstringBefore(temp3884, ".ogg", undefined)
                } else { return null }
        })() ?? ""));
        xViewBindExists(xml.oggName, xObservablePropertyCombine<(File | null), AlertType, boolean>(this.ogg, this.alertType, (uri: (File | null), alert: AlertType): boolean => uri !== null || alert.oggFile !== null));
        
        //--- Set Up xml.guidancesLoading
        //        xml.guidancesLoading.bindLoading(this.guidancesLoading)
        
        //--- Set Up xml.guidances
        xLinearLayoutBind<AlertGuidance>(xml.guidances, this.guidances, new AlertGuidance(undefined, getNO_ID(), "", undefined), (guidance: ObservableProperty<AlertGuidance>): HTMLElement => {
            //--- Make Subview For xml.guidances (overwritten on flow generation)
            const cellXml = new ComponentGuidanceXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root (overwritten on flow generation)
            
            //--- Set Up cellXml.title
            xTextViewBindString(cellXml.title, xObservablePropertyMap<AlertGuidance, string>(guidance, (it: AlertGuidance): string => it.title));
            
            //--- Set Up cellXml.body
            xTextViewBindString(cellXml.body, xObservablePropertyMap<AlertGuidance, string>(guidance, (it: AlertGuidance): string => it.plan ?? ""));
            
            //--- Set Up cellXml.remove
            xViewOnClick(cellXml.remove, undefined, (): void => {
                this.cellXmlRemoveClick(guidance.value.id);
            });
            //--- End Make Subview For xml.guidances (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.newGuidance
        xViewBindExists(xml.newGuidance, xObservablePropertyMap<(AlertGuidance | null), boolean>(this.newGuidance, (it: (AlertGuidance | null)): boolean => it !== null));
        
        //--- Set Up xml.newTitle
        xEditTextBindString(xml.newTitle, xMutableObservablePropertyMapWithExisting<(AlertGuidance | null), string>(this.newGuidance, (it: (AlertGuidance | null)): string => (it?.title ?? null) ?? "", (guidance: (AlertGuidance | null), value: string): (AlertGuidance | null) => (guidance?.copy(undefined, undefined, value, undefined) ?? null)));
        
        //--- Set Up xml.newPlan
        xEditTextBindString(xml.newPlan, xMutableObservablePropertyMapWithExisting<(AlertGuidance | null), string>(this.newGuidance, (it: (AlertGuidance | null)): string => (it?.plan ?? null) ?? "", (guidance: (AlertGuidance | null), value: string): (AlertGuidance | null) => (guidance?.copy(undefined, undefined, undefined, value) ?? null)));
        
        //--- Set Up xml.saveGuidance (overwritten on flow generation)
        xViewOnClick(xml.saveGuidance, undefined, (): void => {
            this.saveGuidanceClick();
        });
        
        //--- Set Up xml.cancelGuidance (overwritten on flow generation)
        xViewOnClick(xml.cancelGuidance, undefined, (): void => {
            this.cancelGuidanceClick();
        });
        
        //--- Set Up xml.addGuidance
        xViewBindExists(xml.addGuidance, xObservablePropertyMap<(AlertGuidance | null), boolean>(this.newGuidance, (it: (AlertGuidance | null)): boolean => it === null));
        xViewOnClick(xml.addGuidance, undefined, (): void => {
            this.addGuidanceClick();
        });
        //--- Set Up xml.adminApproval
        xCompoundButtonBind(xml.adminApproval, xMutableObservablePropertyMapWithExisting<AlertType, boolean>(this.alertType, (it: AlertType): boolean => it.requiresAdminApproval, (alert: AlertType, value: boolean): AlertType => alert.copy(undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        xCompoundButtonBind(xml.showToAllGroup, xMutableObservablePropertyMapWithExisting<AlertType, boolean>(this.alertType, (it: AlertType): boolean => it.canStartOthersGroupAlert, (alert: AlertType, value: boolean): AlertType => alert.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, value, undefined, undefined, undefined, undefined, undefined, undefined)));
        
        xViewBindExists(xml.tvSelectGroups, this.hasPermission);
        xViewBindExists(xml.tvSelectedGroup, this.hasPermission);
        xViewBindExists(xml.tvGroups, this.hasPermission);
        
        xViewOnClick(xml.tvSelectGroups, undefined, (): void => {
            // https://demo.dirs.app/api/v2/alert-type/
            this.dialog.push(new DialogWrapperVG(new SelectGroupVg(this.session, (it: Array<ChatThread>): void => {
                this.dialog.dismiss();
                //  android.util.Log.e("SelectedUsers:","${it.map { it.name }}")
                this.selectedGroup = Array.from(it);
                this.alertType.value.groups = this.selectedGroup.map((it: ChatThread): number => it.id);
                this.selectedGroupName.value = this.selectedGroup.filter((it: ChatThread): boolean => it.subscribed).map((it: ChatThread): string => it.name ?? "").toString();
                xTextViewBindString(xml.tvGroups, this.selectedGroupName);
                const hasGroup = it.length === 0;
                
                xViewBindExists(xml.showToAllGroup, new StandardObservableProperty<boolean>((!hasGroup), undefined));
            }, this.selectedGroup, Array.from(this.editedAllGroupsObservable.value), this.isAdd), this.dialog));
        });
    }
    
    
    //--- Init
    
    
    
    //--- Actions
    
    
    public iconChangeClick(dependency: Window): void {
        xActivityAccessRequestImageGallery(dependency, (uri: File): void => {
            this.icon.value = uri;
        });
    }
    
    public mp3ChangeClick(dependency: Window): void {
        xActivityAccessRequestFile(dependency, (it: File): void => {
            this.mp3.value = it;
        });
    }
    
    public oggChangeClick(dependency: Window): void {
        xActivityAccessRequestFile(dependency, (it: File): void => {
            this.ogg.value = it;
        });
    }
    
    public cellXmlRemoveClick(guidanceId: ForeignKey<AlertGuidance>): void {
        this.guidances.value = this.guidances.value.filter((it: AlertGuidance): boolean => !(it.id === guidanceId));
    }
    
    public saveGuidanceClick(): void {
        let ng = this.newGuidance.value!!;
        
        if (xCharSequenceIsBlank(ng.title)) { ng = ng.copy(undefined, undefined, "Guidance", undefined) }
        this.guidances.value = this.guidances.value.concat([ng]);
        this.newGuidance.value = null;
        this.cancelGuidanceClick();
    }
    
    public cancelGuidanceClick(): void {
        this.newGuidance.value = null;
    }
    
    public addGuidanceClick(): void {
        this.newGuidance.value = new AlertGuidance(undefined, getNO_ID(), "", undefined);
    }
    
    //--- Body End
}