// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiPartialQuery.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { ApiFilter } from './ApiFilter'
import { ApiQuery } from './ApiQuery'
import { ApiSort } from './ApiSort'

//! Declares com.tresitgroup.android.tresit.api.ApiPartialQuery
export class ApiPartialQuery<T extends HasId> {
    public readonly query: ApiQuery<T>;
    public readonly limit: number;
    public readonly offset: number;
    public constructor(query: ApiQuery<T> = new ApiQuery<T>(undefined, undefined), limit: number = 100, offset: number = 0) {
        this.query = query;
        this.limit = limit;
        this.offset = offset;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.query);
        hash = 31 * hash + hashAnything(this.limit);
        hash = 31 * hash + hashAnything(this.offset);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ApiPartialQuery && safeEq(this.query, other.query) && safeEq(this.limit, other.limit) && safeEq(this.offset, other.offset) }
    public toString(): string { return `ApiPartialQuery(query=${this.query}, limit=${this.limit}, offset=${this.offset})` }
    public copy(query: ApiQuery<T> = this.query, limit: number = this.limit, offset: number = this.offset): ApiPartialQuery<T> { return new ApiPartialQuery(query, limit, offset); }
    
    
    public static constructorApiFilterHasIdApiSortHasIdIntInt<T extends HasId>(
        filter: ApiFilter<T> = ApiFilter.Companion.INSTANCE.always<T>(),
        sort: ApiSort<T> = ApiSort.Companion.INSTANCE.byId<T>(),
        limit: number = 100,
        offset: number = 0
    ) {
        let result = new ApiPartialQuery<T>(new ApiQuery<T>(filter, sort), limit, offset);
        
        return result;
    }
    
    public queryString(): string {
        return `?limit=${this.limit}&offset=${this.offset}&${this.query.queryString()}`;
    }
    
}
export namespace ApiPartialQuery {
    //! Declares com.tresitgroup.android.tresit.api.ApiPartialQuery.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public _default<T extends HasId>(): ApiQuery<T> {
            return new ApiQuery<T>(undefined, undefined);
        }
    }
}