//
// DialogWrapperXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './dialog_wrapper.html'
//! Declares com.tresitgroup.android.tresit.layouts.DialogWrapperXml
export class DialogWrapperXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    close!: HTMLButtonElement
    substack!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.close = getViewById<HTMLButtonElement>(view, "close")
        this.substack = getViewById<HTMLDivElement>(view, "substack")
        return view
    }
}
