// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/map/maker/SketchSvgFormat.kt
// Package: com.tresitgroup.android.tresit.map.maker
// Generated by Khrysalis - this file will be overwritten.
import { xCharIsDigit, xCharIsLetter, xCharIsUpperCase, xStringSubstringAfter } from 'butterfly-web/dist/kotlin/kotlin.text'
import { GeoCoordinate } from 'butterfly-web/dist/location/GeoCoordinate'
import { Point } from '../../svg/Point2D'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.map.maker.FullIdentifier
export class FullIdentifier {
    public readonly type: FullIdentifierType;
    public readonly name: string;
    public constructor(type: FullIdentifierType, name: string) {
        this.type = type;
        this.name = name;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.type);
        hash = 31 * hash + hashAnything(this.name);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof FullIdentifier && safeEq(this.type, other.type) && safeEq(this.name, other.name) }
    public toString(): string { return `FullIdentifier(type=${this.type}, name=${this.name})` }
    public copy(type: FullIdentifierType = this.type, name: string = this.name): FullIdentifier { return new FullIdentifier(type, name); }
    
    public toId(): string {
        const cleanName = this.name.replaceAll("-", "!!dash!!").replaceAll(' ', '-').replaceAll("!!dash!!", "-dash-");
        
        return ((): string => {
            switch(this.type) {
                case FullIdentifierType.Identifier:
                return `id-${cleanName}`
                case FullIdentifierType.Label:
                return `label-${cleanName}`
                case FullIdentifierType.Bounds:
                return `bounds-${cleanName}`
                default:
                throw "Seemingly impossible error occurred"
                break;
            }
            
        })();
    }
}
//! Declares com.tresitgroup.android.tresit.map.maker.generatedPath
export const _generatedPath = new RegExp("path[0-9]+");
export function getGeneratedPath(): RegExp { return _generatedPath; }

//! Declares com.tresitgroup.android.tresit.map.maker.toFullIdentifier>kotlin.String
export function xStringToFullIdentifier(this_: string): (FullIdentifier | null) {
    return xStringToStrictIdentifier(this_);
}
//! Declares com.tresitgroup.android.tresit.map.maker.toStrictIdentifier>kotlin.String
export function xStringToStrictIdentifier(this_: string): (FullIdentifier | null) {
    if (this_.startsWith("pin(")
                || this_.startsWith("label(")
                    || getGeneratedPath().test(this_)
                || this_.startsWith("layer-")) { return null }
            if (this_.startsWith("label-")) {
                return new FullIdentifier(FullIdentifierType.Label, xStringSubstringAfter(this_, "label-", undefined).replaceAll("-dash-", "!!dash!!").replaceAll('-', ' ').replaceAll("!!dash!!", "-"));
            }
            if (this_.startsWith("bounds-")) {
                return new FullIdentifier(FullIdentifierType.Bounds, xStringSubstringAfter(this_, "bounds-", undefined).replaceAll("-dash-", "!!dash!!").replaceAll('-', ' ').replaceAll("!!dash!!", "-"));
            }
            if (this_.startsWith("id-")) {
                return new FullIdentifier(FullIdentifierType.Identifier, xStringSubstringAfter(this_, "id-", undefined).replaceAll("-dash-", "!!dash!!").replaceAll('-', ' ').replaceAll("!!dash!!", "-"));
            }
            if (this_.startsWith("campus-")) {
                return new FullIdentifier(FullIdentifierType.Identifier, xStringSubstringAfter(this_, "campus-", undefined).replaceAll("-dash-", "!!dash!!").replaceAll('-', ' ').replaceAll("!!dash!!", "-"));
            }
            const firstLetter = (this_[0] ?? null) ?? ' ';
            
            const startUpper = xCharIsLetter(firstLetter) && xCharIsUpperCase(firstLetter);
            
            const numberAndUpper = xCharIsDigit(firstLetter);
            
            if ((startUpper || numberAndUpper) && (!this_.startsWith("Page")) && (!this_.startsWith("Path")) && (!this_.startsWith("Bitmap"))) {
                return new FullIdentifier(FullIdentifierType.Identifier, this_.replaceAll("-dash-", "!!dash!!").replaceAll('-', ' ').replaceAll("!!dash!!", "-"));
            }
            return null;
        }
        
        //! Declares com.tresitgroup.android.tresit.map.maker.FullIdentifierType
        export class FullIdentifierType {
            private constructor(name: string, jsonName: string) {
                this.name = name;
                this.jsonName = jsonName;
            }
            
            public static Identifier = new FullIdentifierType("Identifier", "Identifier");
            public static Label = new FullIdentifierType("Label", "Label");
            public static Bounds = new FullIdentifierType("Bounds", "Bounds");
            
            private static _values: Array<FullIdentifierType> = [FullIdentifierType.Identifier, FullIdentifierType.Label, FullIdentifierType.Bounds];
            public static values(): Array<FullIdentifierType> { return FullIdentifierType._values; }
            public readonly name: string;
            public readonly jsonName: string;
            public static valueOf(name: string): FullIdentifierType { return (FullIdentifierType as any)[name]; }
            public toString(): string { return this.name }
            public toJSON(): string { return this.jsonName }
        }
        
        //! Declares com.tresitgroup.android.tresit.map.maker.Pin
        export class Pin {
            public readonly point: Point;
            public readonly latLng: GeoCoordinate;
            public constructor(point: Point, latLng: GeoCoordinate) {
                this.point = point;
                this.latLng = latLng;
            }
            public static fromJson(obj: any): Pin { return new Pin(
                parseJsonTyped(obj["point"], [Point]) as Point,
                parseJsonTyped(obj["latLng"], [GeoCoordinate]) as GeoCoordinate
            ) }
            public toJSON(): object { return {
                point: this.point,
                latLng: this.latLng
            } }
            public hashCode(): number {
                let hash = 17;
                hash = 31 * hash + hashAnything(this.point);
                hash = 31 * hash + hashAnything(this.latLng);
                return hash;
            }
            public equals(other: any): boolean { return other instanceof Pin && safeEq(this.point, other.point) && safeEq(this.latLng, other.latLng) }
            public toString(): string { return `Pin(point=${this.point}, latLng=${this.latLng})` }
            public copy(point: Point = this.point, latLng: GeoCoordinate = this.latLng): Pin { return new Pin(point, latLng); }
        }
        //! Declares com.tresitgroup.android.tresit.map.maker.MercatorProjection
        export class MercatorProjection {
            public readonly center: GeoCoordinate;
            public readonly zoomLevel: number;
            public readonly offsetPixels: Point;
            public constructor(center: GeoCoordinate, zoomLevel: number, offsetPixels: Point) {
                this.center = center;
                this.zoomLevel = zoomLevel;
                this.offsetPixels = offsetPixels;
                this.centerOffset = MercatorProjection.Companion.INSTANCE.convertToGlobalPixel(this.center, this.zoomLevel);
            }
            public static fromJson(obj: any): MercatorProjection { return new MercatorProjection(
                parseJsonTyped(obj["center"], [GeoCoordinate]) as GeoCoordinate,
                parseJsonTyped(obj["zoom_level"], [Number]) as number,
                parseJsonTyped(obj["offset_pixels"], [Point]) as Point
            ) }
            public toJSON(): object { return {
                center: this.center,
                zoom_level: this.zoomLevel,
                offset_pixels: this.offsetPixels
            } }
            public hashCode(): number {
                let hash = 17;
                hash = 31 * hash + hashAnything(this.center);
                hash = 31 * hash + hashAnything(this.zoomLevel);
                hash = 31 * hash + hashAnything(this.offsetPixels);
                return hash;
            }
            public equals(other: any): boolean { return other instanceof MercatorProjection && safeEq(this.center, other.center) && safeEq(this.zoomLevel, other.zoomLevel) && safeEq(this.offsetPixels, other.offsetPixels) }
            public toString(): string { return `MercatorProjection(center=${this.center}, zoomLevel=${this.zoomLevel}, offsetPixels=${this.offsetPixels})` }
            public copy(center: GeoCoordinate = this.center, zoomLevel: number = this.zoomLevel, offsetPixels: Point = this.offsetPixels): MercatorProjection { return new MercatorProjection(center, zoomLevel, offsetPixels); }
            
            
            
            private readonly centerOffset: Point;
            
            
            public unproject(point: Point): GeoCoordinate {
                const shift = (MercatorProjection.Companion.INSTANCE.GLOBE_WIDTH << this.zoomLevel);
                
                const latInner: number = Math.exp(Math.PI - (point.y - this.offsetPixels.y + this.centerOffset.y) / (shift / Math.PI));
                
                const latitude: number = 2 * (Math.atan(latInner) - Math.PI / 4) * 180 / Math.PI;
                
                const longitude: number = ((point.x - this.offsetPixels.x + this.centerOffset.x) / (shift / 180) - 180);
                
                return new GeoCoordinate(latitude, longitude);
            }
            
            public project(latLng: GeoCoordinate): Point {
                const raw = MercatorProjection.Companion.INSTANCE.convertToGlobalPixel(latLng, this.zoomLevel);
                
                return new Point(this.offsetPixels.x + raw.x - this.centerOffset.x, this.offsetPixels.y + raw.y - this.centerOffset.y);
            }
        }
        export namespace MercatorProjection {
            //! Declares com.tresitgroup.android.tresit.map.maker.MercatorProjection.Companion
            export class Companion {
                private constructor() {
                    this.GLOBE_WIDTH = 256;
                }
                public static INSTANCE = new Companion();
                
                public readonly GLOBE_WIDTH: number;
                
                
                public convertToGlobalPixel(latLng: GeoCoordinate, zoomLevel: number): Point {
                    const shift = (this.GLOBE_WIDTH << zoomLevel);
                    
                    const x: number = (latLng.longitude + 180) * (shift / 180);
                    
                    const y: number = (Math.PI - Math.log(Math.tan(Math.PI / 4 + (latLng.latitude * Math.PI / 180.0) / 2))) * (shift / Math.PI);
                    
                    return new Point(x, y);
                }
            }
        }