// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminApproveDialogVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { ConversationDetailVG } from '../chat/ConversationDetailVG'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xDisposableForever, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { AlertVG } from '../alert/AlertVG'
import { ViewString, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { AdminApprovalListResponse } from '../../model/AdminApprovalListResponse'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { DisposeCondition } from 'butterfly-web/dist/rx/DisposeCondition'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xActivityAccessCall } from '../../PhoneCall'
import { ObservableInput, SubscriptionLike, of as rxOf } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { StringBuilder } from 'butterfly-web/dist/kotlin/kotlin.text'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { flatMap as rxFlatMap } from 'rxjs/operators'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { Session } from '../../model/Session'
import { LayoutAdminApproveDialogXml } from '../../layout/LayoutAdminApproveDialogXml'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminApproveDialogVG
export class AdminApproveDialogVG extends ViewGenerator {
    public readonly data: AdminApprovalListResponse;
    public readonly session: SessionApi;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly alertStack: ObservableStack<ViewGenerator>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(data: AdminApprovalListResponse, session: SessionApi, dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, alertStack: ObservableStack<ViewGenerator>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.data = data;
        this.session = session;
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.alertStack = alertStack;
        this.stack = stack;
        this.fetching = new StandardObservableProperty<boolean>(false, undefined);
        this.s = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
    }
    
    public readonly fetching: StandardObservableProperty<boolean>;
    
    public readonly s: (Session | null);
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LayoutAdminApproveDialogXml();
        
        const view = xml.setup(dependency);
        
        const type: MutableObservableProperty<string> = new StandardObservableProperty<string>(this.data.alertType ?? "", undefined);
        
        
        
        const schoolName: MutableObservableProperty<string> = new StandardObservableProperty<string>(this.data.schoolName ?? "", undefined);
        
        
        const visibleProgress: MutableObservableProperty<boolean> = new StandardObservableProperty<boolean>(false, undefined);
        
        
        const isValidNumber: MutableObservableProperty<boolean> = new StandardObservableProperty<boolean>(false, undefined);
        
        
        const descriptionString: string = ((): string => {
            if (((): (boolean | null) => {
                const temp3477 = this.data.description;
                if(temp3477 === null) { return null }
                return temp3477 === ""
            })() === true) { return "N/A" } else { return this.data.description ?? "" }
        })();
        
        const description: MutableObservableProperty<string> = new StandardObservableProperty<string>(descriptionString, undefined);
        
        const initiatorName: MutableObservableProperty<string> = new StandardObservableProperty<string>(this.data.initiatorName ?? "", undefined);
        
        
        let cellNumberString = this.data.cellNumber;
        
        
        
        if (cellNumberString !== null) {
            if (cellNumberString!.length >= 10) {
                const formattedNumber = new StringBuilder();
                
                isValidNumber.value = true;
                if (cellNumberString!.length >= 3) {
                    formattedNumber.value += `(${cellNumberString!.substring(0, 3)}) `;
                }
                
                if (cellNumberString!.length >= 6) {
                    formattedNumber.value += `${cellNumberString!.substring(3, 6)}-`;
                }
                
                if (cellNumberString!.length >= 10) {
                    formattedNumber.value += cellNumberString!.substring(6, 10);
                }
                
                if (cellNumberString!.length > 10) {
                    formattedNumber.value += cellNumberString!.substring(10);
                }
                cellNumberString = formattedNumber.toString();
            } else {
                isValidNumber.value = false;
            }
        }
        
        const cellNumber: MutableObservableProperty<string> = new StandardObservableProperty<string>(cellNumberString ?? "", undefined);
        
        
        xViewBindExists(xml.ivCall, isValidNumber);
        xViewBindExists(xml.cellPhone, isValidNumber);
        
        xTextViewBindString(xml.tvSchoolName, schoolName);
        xTextViewBindString(xml.tvAlertCreatedBy, initiatorName);
        xTextViewBindString(xml.description, description);
        xTextViewBindString(xml.tvAlertType, type);
        xTextViewBindString(xml.cellPhone, cellNumber);
        xViewBindExists(xml.progress, visibleProgress);
        
        xViewOnClick(xml.btnApprove, undefined, (): void => {
            this.postAdminApprove("approved", xViewRemovedGet(xml.btnApprove));
        });
        xViewOnClick(xml.btnDecline, undefined, (): void => {
            this.postAdminApprove("rejected", xViewRemovedGet(xml.btnDecline));
        });
        xViewOnClick(xml.ivCall, undefined, (): void => {
            xActivityAccessCall(dependency, `${cellNumberString}`);
        });
        xViewOnClick(xml.ivMessage, undefined, (): void => {
            if ((this.session.me.value?.permissionCreateConversation ?? null) === true) {
                visibleProgress.value = true;
                const userId = ((): (number | null) => {
                    const temp3492 = this.data.initiator;
                    if(temp3492 === null) { return null }
                    return temp3492
                })();
                
                if (userId !== null) {
                    const userIds = [this.session.session.userId, userId!];
                    
                    xSingleCallDisplayingError<ChatThread>(this.session.threads.getListSimple(ChatThread.Companion.INSTANCE.exactlyUsers(userIds), undefined, undefined, undefined, undefined).pipe(rxFlatMap((it: Array<ChatThread>): ObservableInput<ChatThread> => {
                                    const result = (it[0] ?? null);
                                    
                                    if (result !== null) { return rxOf(result) } else { return this.session.threads.post(new ChatThread(undefined, undefined, undefined, undefined, userIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) }
                        })), undefined, (it: ChatThread): void => {
                            visibleProgress.value = false;
                            
                            this.stack.push(new ConversationDetailVG(this.dialog, this.session, it.id, "", this.schoolFilter, this.stack));
                    });
                } else {
                    console.log(`initiator else ---------------> ${((): (number | null) => {
                        const temp3496 = this.data.initiator;
                        if(temp3496 === null) { return null }
                        return temp3496
                    })()}`);
                    
                }
            } else {
                console.log(`initiator id ---------------> ${((): (number | null) => {
                    const temp3498 = this.data.initiator;
                    if(temp3498 === null) { return null }
                    return temp3498
                })()}`);
                
            }
        });
        return view;
    }
    
    private postAdminApprove(status: string, disposeCondition: DisposeCondition): void {
        /*  session.updateAdminApproval(data.id.toInt(), status).working(fetching).callDisplayingError {it->
            alertStack.reset(
                AlertVG(
                    dialog = this.dialog,
                    schoolFilter = this.schoolFilter,
                    session = this.session
                )
            )
        }.until(disposeCondition)*/
        
        if (this.s !== null) {
            xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<void>(this.session.rawApi.submitAdminApproval(this.s!, status, this.data.id), (_int: number, _1: string): (ViewString | null) => {
                    return ((): (ViewString | null) => {
                        if (_int === 404 || _int === 400) {
                            this.alertStack.reset(new AlertVG(this.dialog, this.schoolFilter, this.session));
                            return new ViewStringResource(R._string.this_alert_is_already_accepted);
                            
                        } else if (_int === 403) {
                            return new ViewStringResource(R._string.you_dont_have_permission);
                            
                        } else { return new ViewStringResource(R._string.this_alert_is_already_accepted) }
                    })();
                }, (it: void): void => {
                    this.alertStack.reset(new AlertVG(this.dialog, this.schoolFilter, this.session));
            }));
            /*    session.rawApi.submitAdminApproval(s, status, data.id.toInt()).subscribeBy(
                onError = {
                    showDialog(
                        message = ViewStringResource(R.string.something_went_wrong)
                    )
                },
                onSuccess = {
                    alertStack.reset(
                        AlertVG(
                            dialog = this.dialog,
                            schoolFilter = this.schoolFilter,
                            session = this.session
                        )
                    )
                }
            ).forever()*/
        }
    }
    
    
}

/*
alertStack.reset(
    AlertVG(
        dialog = this.dialog,
        schoolFilter = this.schoolFilter,
        session = this.session
    )
)
*/