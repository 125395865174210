// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Session.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Session
export class Session {
    public readonly token: string;
    public readonly userId: ForeignKey<User>;
    public constructor(token: string, userId: ForeignKey<User>) {
        this.token = token;
        this.userId = userId;
    }
    public static fromJson(obj: any): Session { return new Session(
        parseJsonTyped(obj["token"], [String]) as string,
        parseJsonTyped(obj["userId"], [Number]) as number
    ) }
    public toJSON(): object { return {
        token: this.token,
        userId: this.userId
    } }
    
    
}
export namespace Session {
    //! Declares com.tresitgroup.android.tresit.model.Session.Companion
    export class Companion {
        private constructor() {
            this.key = "session";
        }
        public static INSTANCE = new Companion();
        
        public readonly key: string;
        
    }
}