// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/SocketInfo.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { Time } from '../util/time'

//! Declares com.tresitgroup.android.tresit.api.SocketInfo
export interface SocketInfo {
    
    socketOpenAt: number;
    
    socketClosedAt: number;
    
}


//! Declares com.tresitgroup.android.tresit.api.upToDate>com.tresitgroup.android.tresit.api.SocketInfo
export function xSocketInfoUpToDate(this_: SocketInfo, lastUpdated: number, expiration: number): boolean {
    if (lastUpdated === 0) { return false }
    if (this_.socketOpenAt > this_.socketClosedAt) {//socket active
        return lastUpdated >= this_.socketOpenAt; //after socket open
    } else {
        return Time.INSTANCE.epochMilliseconds() - lastUpdated < expiration;
    }
}