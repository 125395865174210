//
// SelectLocationXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './select_location.html'
//! Declares com.tresitgroup.android.tresit.layouts.SelectLocationXml
export class SelectLocationXml {
    xmlRoot!: HTMLElement
    optionsLoading!: HTMLDivElement
    options!: HTMLDivElement
    offsite!: HTMLButtonElement
    map!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.optionsLoading = getViewById<HTMLDivElement>(view, "optionsLoading")
        this.options = getViewById<HTMLDivElement>(view, "options")
        this.offsite = getViewById<HTMLButtonElement>(view, "offsite")
        this.map = getViewById<HTMLDivElement>(view, "map")
        return view
    }
}
