// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/VideoPreviewVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { VideoPreviewXml } from '../../layout/VideoPreviewXml'
import { Video, VideoRemoteUrl } from 'butterfly-web/dist/Video'
import { DialogRequest, showDialog } from 'butterfly-web/dist/views/showDialog'
import { xActivityAccessDownloadFile } from 'butterfly-web/dist/views/ViewDependency'
import { xVideoPlayerBindAndStart } from 'butterfly-web/dist/observables/binding/VideoPlayer.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xCharSequenceIsBlank, xStringSubstringAfterLast, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ViewStringResource, ViewStringTemplate } from 'butterfly-web/dist/views/ViewString'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { tryCastClass } from 'butterfly-web/dist/Kotlin'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'

//! Declares com.tresitgroup.android.tresit.vg.chat.VideoPreviewVG
export class VideoPreviewVG extends ViewGenerator {
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly video: ObservableProperty<(Video | null)>;
    public constructor(stack: ObservableStack<ViewGenerator>, video: ObservableProperty<(Video | null)>) {
        super();
        this.stack = stack;
        this.video = video;
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.VideoPreviewVG.title
    public get title(): string { return "Video Preview"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new VideoPreviewXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Set Up xml.videoPlayer
        xVideoPlayerBindAndStart(xml.videoPlayer, this.video);
        
        //--- Set Up xml.closeButton (overwritten on flow generation)
        xViewOnClick(xml.closeButton, undefined, (): void => {
            this.closeButtonClick();
        });
        
        //--- Set Up xml.download
        xViewOnClick(xml.download, undefined, (): void => {
            this.downloadClick(dependency);
        });
        xViewBindExists(xml.download, xObservablePropertyMap<(Video | null), boolean>(this.video, (it: (Video | null)): boolean => it instanceof VideoRemoteUrl));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeButtonClick(): void {
        this.stack.dismiss();
    }
    public downloadClick(dependency: Window): void {
        const url_4536 = ((tryCastClass<VideoRemoteUrl>(this.video.value, VideoRemoteUrl))?.url ?? null);
        if (url_4536 !== null) {
            if (!xCharSequenceIsBlank(url_4536)) {
                const fileName = xStringSubstringBefore(xStringSubstringAfterLast(url_4536, '/', undefined), '?', undefined);
                
                showDialog(new DialogRequest(new ViewStringTemplate(new ViewStringResource(R._string.download_x), [fileName]), (): void => {
                    xActivityAccessDownloadFile(dependency, url_4536);
                }));
            }
        }
    }
    
    //--- Body End
}
