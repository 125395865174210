// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/LogVG.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.
import { tryCastClass } from 'butterfly-web/dist/Kotlin'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { SessionApi } from '../api/SessionApi'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { Platform } from 'butterfly-web/dist/Platform'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ViewStringRaw } from 'butterfly-web/dist/views/ViewString'

//! Declares com.tresitgroup.android.tresit.util.logVG
export function logVG(vg: ViewGenerator, session: SessionApi): void {
    const title_2931 = (tryCastClass<ViewStringRaw>(vg.titleString, ViewStringRaw));
    if (title_2931 !== null) {
        xDisposableForever<SubscriptionLike>(session.rawApi.postLog(session.session, `${Platform.Companion.INSTANCE.current} visited ${title_2931._string}`).subscribe((it: void): void => {}, (it: any): void => {}));
    }
}