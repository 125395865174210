// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/SelectGroupVg.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../api/SessionApi'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { School } from '../model/School'
import { ItemGroupNameXml } from '../layout/ItemGroupNameXml'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { listRemoveItem } from 'butterfly-web/dist/KotlinCollections'
import { ChatThread } from '../model/ChatThread'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { LayoutSelectGroupXml } from '../layout/LayoutSelectGroupXml'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'

//! Declares com.tresitgroup.android.tresit.vg.SelectGroupVg
export class SelectGroupVg extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly onSelected:  ((a: Array<ChatThread>) => void);
    public selectedGroup: Array<ChatThread>;
    public allgroups: Array<ChatThread>;
    public readonly isAdd: boolean;
    public constructor(session: SessionApi, onSelected:  ((a: Array<ChatThread>) => void), selectedGroup: Array<ChatThread> = ([] as Array<ChatThread>), allgroups: Array<ChatThread> = ([] as Array<ChatThread>), isAdd: boolean = false) {
        super();
        this.session = session;
        this.onSelected = onSelected;
        this.selectedGroup = selectedGroup;
        this.allgroups = allgroups;
        this.isAdd = isAdd;
        this.groupNameList = new StandardObservableProperty<Array<ChatThread>>([], undefined);
        this.schoolFilter = new StandardObservableProperty<(School | null)>(null, undefined);
        this.updateAllGroupsWithSelectedObservable = new StandardObservableProperty<Array<ChatThread>>([], undefined);
        this.selectedGroupObservable = new StandardObservableProperty<Array<ChatThread>>([], undefined);
        this.updateAllGroupsWithSelectedObservable.value = this.allgroups;
        this.selectedGroupObservable.value = this.selectedGroup.filter((it: ChatThread): boolean => it.subscribed);
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.SelectGroupVg.title
    public get title(): string { return "Select Groups"; }
    
    public readonly groupNameList: StandardObservableProperty<Array<ChatThread>>;
    
    public readonly schoolFilter: StandardObservableProperty<(School | null)>;
    
    public readonly updateAllGroupsWithSelectedObservable: MutableObservableProperty<Array<ChatThread>>;
    
    
    public readonly selectedGroupObservable: MutableObservableProperty<Array<ChatThread>>;
    
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LayoutSelectGroupXml();
        
        const view = xml.setup(dependency);
        
        
        
        //   allGroups.value = recentUserThreads(schoolFilter.value).value
        
        xRecyclerViewBind<ChatThread>(xml.rvGroupName, this.updateAllGroupsWithSelectedObservable, new ChatThread(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<ChatThread>): HTMLElement => {
            //--- Make Subview For xml.list (overwritten on flow generation)
            const cellXml = new ItemGroupNameXml();
            
            const cellView = cellXml.setup(dependency);
            
            if (this.selectedGroup.some((x) => safeEq(observable.value, x))) {
                observable.value.subscribed = true;
            }
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.isChecked, xObservablePropertyMap<ChatThread, string>(observable, (it: ChatThread): string => it.name ?? ""));
            
            xCompoundButtonBind(cellXml.isChecked, xObservablePropertyWithWrite<boolean>(xObservablePropertyCombine<ChatThread, Array<ChatThread>, boolean>(observable, this.selectedGroupObservable, (it: ChatThread, list: Array<ChatThread>): boolean => list.some((x) => safeEq(it, x))), (valueToWrite: boolean): void => {
                const target = observable.value;
                
                if (valueToWrite) {
                    if ((!this.selectedGroup.some((x) => safeEq(target, x)))) {
                        target.subscribed = valueToWrite;
                        this.selectedGroup.push(target);
                    }
                } else {
                    if (this.selectedGroup.some((x) => safeEq(target, x))) {
                        listRemoveItem(this.selectedGroup, target);
                    }
                }
                this.selectedGroup = Array.from(this.selectedGroup.filter((it: ChatThread): boolean => it.subscribed));
                this.groupNameList.value = this.selectedGroup;
            }));
            
            //--- Click
            cellView.onclick = (_ev) => { _ev.stopPropagation();
                const it = _ev.target as HTMLElement;
                //  this.onSelected(observable.value, openingUserConversation)
            };
            
            //--- End Make Subview For xml.list
            return cellView;
        });
        
        xViewOnClick(xml.btnAddGroup, undefined, (): void => {
            this.onSelected(this.selectedGroup);
        });
        
        return view;
        
    }
}

//! Declares com.tresitgroup.android.tresit.vg.GroupName
export class GroupName {
    public readonly name: string;
    public subscribed: boolean;
    public constructor(name: string, subscribed: boolean = false) {
        this.name = name;
        this.subscribed = subscribed;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.subscribed);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof GroupName && safeEq(this.name, other.name) && safeEq(this.subscribed, other.subscribed) }
    public toString(): string { return `GroupName(name=${this.name}, subscribed=${this.subscribed})` }
    public copy(name: string = this.name, subscribed: boolean = this.subscribed): GroupName { return new GroupName(name, subscribed); }
}