//
// LayoutAdminRequestHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './layout_admin_request_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.LayoutAdminRequestHistoryXml
export class LayoutAdminRequestHistoryXml {
    xmlRoot!: HTMLElement
    loadingList!: HTMLDivElement
    rvGroupName!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.loadingList = getViewById<HTMLDivElement>(view, "loadingList")
        this.rvGroupName = getViewById<HTMLDivElement>(view, "rvGroupName")
        return view
    }
}
