//
// ComponentRowGuidanceDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_guidance_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowGuidanceDetailXml
export class ComponentRowGuidanceDetailXml {
    xmlRoot!: HTMLElement
    guidanceTitle!: HTMLElement
    guidancePlan!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.guidanceTitle = getViewById<HTMLElement>(view, "guidanceTitle")
        this.guidancePlan = getViewById<HTMLElement>(view, "guidancePlan")
        return view
    }
}
