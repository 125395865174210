// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/PlaceholderSelectConversationVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { PlaceholderSelectConversationXml } from '../../layout/PlaceholderSelectConversationXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'

//! Declares com.tresitgroup.android.tresit.vg.chat.PlaceholderSelectConversationVG
export class PlaceholderSelectConversationVG extends ViewGenerator {
    public constructor() {
        super();
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.PlaceholderSelectConversationVG.title
    public get title(): string { return "Placeholder Select Conversation"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PlaceholderSelectConversationXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
