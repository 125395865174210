//
// FormAddAlertTypeXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FormAlertTypeXml } from './FormAlertTypeXml'
import htmlForDefault from './form_add_alert_type.html'
//! Declares com.tresitgroup.android.tresit.layouts.FormAddAlertTypeXml
export class FormAddAlertTypeXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    schoolFilter!: HTMLInputElement
    region!: HTMLInputElement
    form!: FormAlertTypeXml
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.schoolFilter = getViewById<HTMLInputElement>(view, "schoolFilter")
        this.region = getViewById<HTMLInputElement>(view, "region")
        replaceViewWithId(view, ()=>{ 
                this.form = new FormAlertTypeXml()
                return this.form.setup(dependency)
        }, "form")
        return view
    }
}
