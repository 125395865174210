//
// DescriptionDialogXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FancyBackgroundDelegate } from '../vg/FancyBackgroundDelegate'
import htmlForDefault from './description_dialog.html'
//! Declares com.tresitgroup.android.tresit.layouts.DescriptionDialogXml
export class DescriptionDialogXml {
    xmlRoot!: HTMLElement
    background!: HTMLCanvasElement
    description!: HTMLTextAreaElement
    submitWorking!: HTMLDivElement
    submit!: HTMLButtonElement
    backgroundDelegate!: FancyBackgroundDelegate
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.background = getViewById<HTMLCanvasElement>(view, "background")
        this.description = getViewById<HTMLTextAreaElement>(view, "description")
        this.submitWorking = getViewById<HTMLDivElement>(view, "submitWorking")
        this.submit = getViewById<HTMLButtonElement>(view, "submit")
        if(this.background){ this.backgroundDelegate = new FancyBackgroundDelegate(); customViewSetDelegate(this.background, this.backgroundDelegate); }
        return view
    }
}
