// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertHistory.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { Comparator, safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { DateAlone, xDateAloneIso8601 } from 'butterfly-web/dist/time/DateAlone'
import { School } from './School'
import { PoliceStatus } from './PoliceStatus'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { TimeAlone } from 'butterfly-web/dist/time/TimeAlone'
import { User } from './User'
import { dateFrom } from 'butterfly-web/dist/time/Date'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { AlertType } from './AlertType'
import { HasId, getNO_ID } from './HasId'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertHistory
export class AlertHistory implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly school: ForeignKey<School>;
    public readonly name: (ForeignKey<AlertType> | null);
    public readonly created: Date;
    public readonly cancelled: (Date | null);
    public readonly initiator: (ForeignKey<User> | null);
    public readonly canceller: (ForeignKey<User> | null);
    public readonly lastPoliceStatus: PoliceStatus;
    public readonly scoped: boolean;
    public readonly silent: boolean;
    public readonly internal: boolean;
    public readonly pendingApproval: boolean;
    public readonly requiresAdminApproval: boolean;
    public readonly cancelReason: string;
    public constructor(id: number = getNO_ID(), school: ForeignKey<School>, name: (ForeignKey<AlertType> | null) = null, created: Date, cancelled: (Date | null) = null, initiator: (ForeignKey<User> | null) = null, canceller: (ForeignKey<User> | null) = null, lastPoliceStatus: PoliceStatus = PoliceStatus.NotNotified, scoped: boolean = false, silent: boolean = false, internal: boolean = false, pendingApproval: boolean = false, requiresAdminApproval: boolean = false, cancelReason: string = "") {
        this.id = id;
        this.school = school;
        this.name = name;
        this.created = created;
        this.cancelled = cancelled;
        this.initiator = initiator;
        this.canceller = canceller;
        this.lastPoliceStatus = lastPoliceStatus;
        this.scoped = scoped;
        this.silent = silent;
        this.internal = internal;
        this.pendingApproval = pendingApproval;
        this.requiresAdminApproval = requiresAdminApproval;
        this.cancelReason = cancelReason;
    }
    public static fromJson(obj: any): AlertHistory { return new AlertHistory(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as number,
        parseJsonTyped(obj["name"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["cancelled"], [Date]) as (Date | null),
        parseJsonTyped(obj["initiator"], [Number]) as (number | null),
        parseJsonTyped(obj["canceller"], [Number]) as (number | null),
        parseJsonTyped(obj["last_police_status"], [PoliceStatus]) as PoliceStatus,
        parseJsonTyped(obj["scoped"], [Boolean]) as boolean,
        parseJsonTyped(obj["silent"], [Boolean]) as boolean,
        parseJsonTyped(obj["internal"], [Boolean]) as boolean,
        parseJsonTyped(obj["pending_approval"], [Boolean]) as boolean,
        parseJsonTyped(obj["requires_admin_approval"], [Boolean]) as boolean,
        parseJsonTyped(obj["cancel_reason"], [String]) as string
    ) }
    public toJSON(): object { return {
        id: this.id,
        school: this.school,
        name: this.name,
        created: this.created,
        cancelled: this.cancelled,
        initiator: this.initiator,
        canceller: this.canceller,
        last_police_status: this.lastPoliceStatus,
        scoped: this.scoped,
        silent: this.silent,
        internal: this.internal,
        pending_approval: this.pendingApproval,
        requires_admin_approval: this.requiresAdminApproval,
        cancel_reason: this.cancelReason
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.cancelled);
        hash = 31 * hash + hashAnything(this.initiator);
        hash = 31 * hash + hashAnything(this.canceller);
        hash = 31 * hash + hashAnything(this.lastPoliceStatus);
        hash = 31 * hash + hashAnything(this.scoped);
        hash = 31 * hash + hashAnything(this.silent);
        hash = 31 * hash + hashAnything(this.internal);
        hash = 31 * hash + hashAnything(this.pendingApproval);
        hash = 31 * hash + hashAnything(this.requiresAdminApproval);
        hash = 31 * hash + hashAnything(this.cancelReason);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertHistory && safeEq(this.id, other.id) && safeEq(this.school, other.school) && safeEq(this.name, other.name) && safeEq(this.created, other.created) && safeEq(this.cancelled, other.cancelled) && safeEq(this.initiator, other.initiator) && safeEq(this.canceller, other.canceller) && safeEq(this.lastPoliceStatus, other.lastPoliceStatus) && safeEq(this.scoped, other.scoped) && safeEq(this.silent, other.silent) && safeEq(this.internal, other.internal) && safeEq(this.pendingApproval, other.pendingApproval) && safeEq(this.requiresAdminApproval, other.requiresAdminApproval) && safeEq(this.cancelReason, other.cancelReason) }
    public toString(): string { return `AlertHistory(id=${this.id}, school=${this.school}, name=${this.name}, created=${this.created}, cancelled=${this.cancelled}, initiator=${this.initiator}, canceller=${this.canceller}, lastPoliceStatus=${this.lastPoliceStatus}, scoped=${this.scoped}, silent=${this.silent}, internal=${this.internal}, pendingApproval=${this.pendingApproval}, requiresAdminApproval=${this.requiresAdminApproval}, cancelReason=${this.cancelReason})` }
    public copy(id: number = this.id, school: ForeignKey<School> = this.school, name: (ForeignKey<AlertType> | null) = this.name, created: Date = this.created, cancelled: (Date | null) = this.cancelled, initiator: (ForeignKey<User> | null) = this.initiator, canceller: (ForeignKey<User> | null) = this.canceller, lastPoliceStatus: PoliceStatus = this.lastPoliceStatus, scoped: boolean = this.scoped, silent: boolean = this.silent, internal: boolean = this.internal, pendingApproval: boolean = this.pendingApproval, requiresAdminApproval: boolean = this.requiresAdminApproval, cancelReason: string = this.cancelReason): AlertHistory { return new AlertHistory(id, school, name, created, cancelled, initiator, canceller, lastPoliceStatus, scoped, silent, internal, pendingApproval, requiresAdminApproval, cancelReason); }
    
    
}
export namespace AlertHistory {
    //! Declares com.tresitgroup.android.tresit.model.AlertHistory.Companion
    export class Companion {
        private constructor() {
            this.initiatorSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=initiator", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((): (User | null) => {
                    const temp1929 = o1.initiator;
                    if(temp1929 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1929)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp1932 = o2.initiator;
                    if(temp1932 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1932)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.initiatorDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=-initiator", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((): (User | null) => {
                    const temp1936 = o1.initiator;
                    if(temp1936 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1936)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp1939 = o2.initiator;
                    if(temp1939 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1939)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.createdSort = ApiSort.constructorStringComparatorHasId<AlertHistory>("sort=created", (o1: AlertHistory, o2: AlertHistory): number => safeCompare(o1.created, o2.created));
            this.createdDownSort = ApiSort.constructorStringComparatorHasId<AlertHistory>("sort=-created", (o1: AlertHistory, o2: AlertHistory): number => safeCompare(o2.created, o1.created));
            this.cancellerSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=canceller", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((): (User | null) => {
                    const temp1949 = o1.canceller;
                    if(temp1949 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1949)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp1952 = o2.canceller;
                    if(temp1952 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1952)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.cancellerDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=-canceller", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((): (User | null) => {
                    const temp1956 = o1.canceller;
                    if(temp1956 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1956)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp1959 = o2.canceller;
                    if(temp1959 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp1959)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.alertSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=name", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((): (AlertType | null) => {
                    const temp1963 = o1.name;
                    if(temp1963 === null) { return null }
                    return ((it: number): (AlertType | null) => (db.alertTypes.objects.get(it) ?? null))(temp1963)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (AlertType | null) => {
                    const temp1966 = o2.name;
                    if(temp1966 === null) { return null }
                    return ((it: number): (AlertType | null) => (db.alertTypes.objects.get(it) ?? null))(temp1966)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.alertDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=-name", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((): (AlertType | null) => {
                    const temp1970 = o1.name;
                    if(temp1970 === null) { return null }
                    return ((it: number): (AlertType | null) => (db.alertTypes.objects.get(it) ?? null))(temp1970)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (AlertType | null) => {
                    const temp1973 = o2.name;
                    if(temp1973 === null) { return null }
                    return ((it: number): (AlertType | null) => (db.alertTypes.objects.get(it) ?? null))(temp1973)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.siteSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=school", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o1.school)?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o2.school)?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.siteDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistory>("sort=-school", (db: MockDatabase): Comparator<AlertHistory> => (o1: AlertHistory, o2: AlertHistory): number => {
                const name1 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o1.school)?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o2.school)?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.cancelledSort = ApiSort.constructorStringComparatorHasId<AlertHistory>("sort=cancelled", (o1: AlertHistory, o2: AlertHistory): number => {
                const prop1 = o1.cancelled
                if(prop1 === null) { return 1 }
                const prop2 = o2.cancelled
                if(prop2 === null) { return (-1) }
                return safeCompare(prop1, prop2);
            });
            this.cancelledDownSort = ApiSort.constructorStringComparatorHasId<AlertHistory>("sort=-cancelled", (o1: AlertHistory, o2: AlertHistory): number => {
                const prop1 = o1.cancelled
                if(prop1 === null) { return 1 }
                const prop2 = o2.cancelled
                if(prop2 === null) { return (-1) }
                return safeCompare(prop2, prop1);
            });
            this.cancelReasonSort = ApiSort.constructorStringComparatorHasId<AlertHistory>("sort=cancel_reason", (o1: AlertHistory, o2: AlertHistory): number => safeCompare(o1.cancelReason, o2.cancelReason));
            this.cancelReasonDownSort = ApiSort.constructorStringComparatorHasId<AlertHistory>("sort=-cancel_reason", (o1: AlertHistory, o2: AlertHistory): number => safeCompare(o2.cancelReason, o1.cancelReason));
        }
        public static INSTANCE = new Companion();
        
        public schoolIn(schools: Array<ForeignKey<School>>): ApiFilter<AlertHistory> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistory>(`schools=${schools.join(",")}`, undefined, (it: AlertHistory): boolean => schools.length === 0 || schools.some((x) => safeEq(it.school, x)));
        }
        
        //        fun notCancelledByMe(myId: ForeignKey<User>): ApiSort<AlertHistory> = ApiFilter(params="",filter = { it.canceller != myId })
        
        public textSearch(query: string): ApiFilter<AlertHistory> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistory>(`query=${query}`, (db: MockDatabase, hist: AlertHistory): boolean => true, undefined);
        }
        
        public startDateFilter(date: DateAlone): ApiFilter<AlertHistory> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistory>(`created=${xDateAloneIso8601(date)}`, undefined, (it: AlertHistory): boolean => (it.created > dateFrom(date, TimeAlone.Companion.INSTANCE.midnight, undefined)));
        }
        
        public endDateFilter(date: DateAlone): ApiFilter<AlertHistory> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistory>(`cancelled=${xDateAloneIso8601(date)}`, undefined, (it: AlertHistory): boolean => ((): (boolean | null) => {
                const temp2002 = it.cancelled;
                if(temp2002 === null) { return null }
                return (temp2002 > dateFrom(date, TimeAlone.Companion.INSTANCE.midnight, undefined))
            })() ?? true);
        }
        
        public readonly initiatorSort: ApiSort<AlertHistory>;
        
        public readonly initiatorDownSort: ApiSort<AlertHistory>;
        
        
        public readonly createdSort: ApiSort<AlertHistory>;
        
        public readonly createdDownSort: ApiSort<AlertHistory>;
        
        
        public readonly cancellerSort: ApiSort<AlertHistory>;
        
        public readonly cancellerDownSort: ApiSort<AlertHistory>;
        
        
        public readonly alertSort: ApiSort<AlertHistory>;
        
        public readonly alertDownSort: ApiSort<AlertHistory>;
        
        
        public readonly siteSort: ApiSort<AlertHistory>;
        
        public readonly siteDownSort: ApiSort<AlertHistory>;
        
        
        public readonly cancelledSort: ApiSort<AlertHistory>;
        
        public readonly cancelledDownSort: ApiSort<AlertHistory>;
        
        
        public readonly cancelReasonSort: ApiSort<AlertHistory>;
        
        public readonly cancelReasonDownSort: ApiSort<AlertHistory>;
        
        
    }
}
