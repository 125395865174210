// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/ChatMessageDetailed.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { RemoteFileRef } from './RemoteFileRef'
import { Time } from '../util/time'
import { User } from './User'
import { ChatThread } from './ChatThread'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { ChatAttachment } from './ChatAttachment'
import { HasId, getNO_ID } from './HasId'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.ChatMessageDetailed
export class ChatMessageDetailed implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly thread: ForeignKey<ChatThread>;
    public readonly authorId: (ForeignKey<User> | null);
    public readonly created: Date;
    public readonly content: string;
    public readonly timestamp: Date;
    public deletedReason: string;
    public readonly attachmentsDeep: Array<ChatAttachment>;
    public readonly userFirstName: (string | null);
    public readonly userLastName: (string | null);
    public readonly alertTypeName: (string | null);
    public readonly alertTypeIcon: (RemoteFileRef | null);
    public readonly siteName: (string | null);
    public readonly fromOldChat: boolean;
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, thread: ForeignKey<ChatThread>, authorId: (ForeignKey<User> | null) = null, created: Date = Time.INSTANCE.date(), content: string, timestamp: Date = Time.INSTANCE.date(), deletedReason: string = "", attachmentsDeep: Array<ChatAttachment> = [], userFirstName: (string | null) = null, userLastName: (string | null) = null, alertTypeName: (string | null) = null, alertTypeIcon: (RemoteFileRef | null) = null, siteName: (string | null) = null, fromOldChat: boolean = false) {
        this.id = id;
        this.deleted = deleted;
        this.thread = thread;
        this.authorId = authorId;
        this.created = created;
        this.content = content;
        this.timestamp = timestamp;
        this.deletedReason = deletedReason;
        this.attachmentsDeep = attachmentsDeep;
        this.userFirstName = userFirstName;
        this.userLastName = userLastName;
        this.alertTypeName = alertTypeName;
        this.alertTypeIcon = alertTypeIcon;
        this.siteName = siteName;
        this.fromOldChat = fromOldChat;
    }
    public static fromJson(obj: any): ChatMessageDetailed { return new ChatMessageDetailed(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["thread"], [Number]) as number,
        parseJsonTyped(obj["author_id"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["content"], [String]) as string,
        parseJsonTyped(obj["timestamp"], [Date]) as Date,
        parseJsonTyped(obj["deleted_reason"], [String]) as string,
        parseJsonTyped(obj["attachments_deep"], [Array, [ChatAttachment]]) as Array<ChatAttachment>,
        parseJsonTyped(obj["user_first_name"], [String]) as (string | null),
        parseJsonTyped(obj["user_last_name"], [String]) as (string | null),
        parseJsonTyped(obj["alert_type_name"], [String]) as (string | null),
        parseJsonTyped(obj["alert_type_icon"], [String]) as (string | null),
        parseJsonTyped(obj["site_name"], [String]) as (string | null),
        parseJsonTyped(obj["from_old_chat"], [Boolean]) as boolean
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        thread: this.thread,
        author_id: this.authorId,
        created: this.created,
        content: this.content,
        timestamp: this.timestamp,
        deleted_reason: this.deletedReason,
        attachments_deep: this.attachmentsDeep,
        user_first_name: this.userFirstName,
        user_last_name: this.userLastName,
        alert_type_name: this.alertTypeName,
        alert_type_icon: this.alertTypeIcon,
        site_name: this.siteName,
        from_old_chat: this.fromOldChat
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.thread);
        hash = 31 * hash + hashAnything(this.authorId);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.content);
        hash = 31 * hash + hashAnything(this.timestamp);
        hash = 31 * hash + hashAnything(this.deletedReason);
        hash = 31 * hash + hashAnything(this.attachmentsDeep);
        hash = 31 * hash + hashAnything(this.userFirstName);
        hash = 31 * hash + hashAnything(this.userLastName);
        hash = 31 * hash + hashAnything(this.alertTypeName);
        hash = 31 * hash + hashAnything(this.alertTypeIcon);
        hash = 31 * hash + hashAnything(this.siteName);
        hash = 31 * hash + hashAnything(this.fromOldChat);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ChatMessageDetailed && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.thread, other.thread) && safeEq(this.authorId, other.authorId) && safeEq(this.created, other.created) && safeEq(this.content, other.content) && safeEq(this.timestamp, other.timestamp) && safeEq(this.deletedReason, other.deletedReason) && safeEq(this.attachmentsDeep, other.attachmentsDeep) && safeEq(this.userFirstName, other.userFirstName) && safeEq(this.userLastName, other.userLastName) && safeEq(this.alertTypeName, other.alertTypeName) && safeEq(this.alertTypeIcon, other.alertTypeIcon) && safeEq(this.siteName, other.siteName) && safeEq(this.fromOldChat, other.fromOldChat) }
    public toString(): string { return `ChatMessageDetailed(id=${this.id}, deleted=${this.deleted}, thread=${this.thread}, authorId=${this.authorId}, created=${this.created}, content=${this.content}, timestamp=${this.timestamp}, deletedReason=${this.deletedReason}, attachmentsDeep=${this.attachmentsDeep}, userFirstName=${this.userFirstName}, userLastName=${this.userLastName}, alertTypeName=${this.alertTypeName}, alertTypeIcon=${this.alertTypeIcon}, siteName=${this.siteName}, fromOldChat=${this.fromOldChat})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, thread: ForeignKey<ChatThread> = this.thread, authorId: (ForeignKey<User> | null) = this.authorId, created: Date = this.created, content: string = this.content, timestamp: Date = this.timestamp, deletedReason: string = this.deletedReason, attachmentsDeep: Array<ChatAttachment> = this.attachmentsDeep, userFirstName: (string | null) = this.userFirstName, userLastName: (string | null) = this.userLastName, alertTypeName: (string | null) = this.alertTypeName, alertTypeIcon: (RemoteFileRef | null) = this.alertTypeIcon, siteName: (string | null) = this.siteName, fromOldChat: boolean = this.fromOldChat): ChatMessageDetailed { return new ChatMessageDetailed(id, deleted, thread, authorId, created, content, timestamp, deletedReason, attachmentsDeep, userFirstName, userLastName, alertTypeName, alertTypeIcon, siteName, fromOldChat); }
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace ChatMessageDetailed {
    //! Declares com.tresitgroup.android.tresit.model.ChatMessageDetailed.Companion
    export class Companion {
        private constructor() {
            this.createdSort = ApiSort.constructorStringComparatorHasId<ChatMessageDetailed>("sort=created", (o1: ChatMessageDetailed, o2: ChatMessageDetailed): number => safeCompare(o1.created, o2.created));
            this.createdDownSort = ApiSort.constructorStringComparatorHasId<ChatMessageDetailed>("sort=-created", (o1: ChatMessageDetailed, o2: ChatMessageDetailed): number => safeCompare(o2.created, o1.created));
            this.contentSort = ApiSort.constructorStringComparatorHasId<ChatMessageDetailed>("sort=content", (o1: ChatMessageDetailed, o2: ChatMessageDetailed): number => safeCompare(o1.content, o2.content));
            this.contentDownSort = ApiSort.constructorStringComparatorHasId<ChatMessageDetailed>("sort=-content", (o1: ChatMessageDetailed, o2: ChatMessageDetailed): number => safeCompare(o2.content, o1.content));
        }
        public static INSTANCE = new Companion();
        
        public partOfThread(threadId: ForeignKey<ChatThread>): ApiFilter<ChatMessageDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessageDetailed>(`thread=${threadId}`, undefined, (it: ChatMessageDetailed): boolean => it.thread === threadId);
        }
        public belongsToSchool(schoolId: ForeignKey<School>): ApiFilter<ChatMessageDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessageDetailed>(`school=${schoolId}`, (db: MockDatabase, msg: ChatMessageDetailed): boolean => ((): (boolean | null) => {
                const temp2124 = ((db.threads.objects.get(msg.thread) ?? null)?.schools ?? null);
                if(temp2124 === null) { return null }
                return temp2124.some((x) => safeEq(schoolId, x))
            })() === true, undefined);
        }
        public belongsToAlertThread(): ApiFilter<ChatMessageDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessageDetailed>("in_alert_thread=true", (db: MockDatabase, msg: ChatMessageDetailed): boolean => ((db.threads.objects.get(msg.thread) ?? null)?.alert ?? null) !== null, undefined);
        }
        public textSearch(query: string): ApiFilter<ChatMessageDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatMessageDetailed>(`query=${query}`, (db: MockDatabase, hist: ChatMessageDetailed): boolean => true, undefined);
        }
        
        public readonly createdSort: ApiSort<ChatMessageDetailed>;
        
        public readonly createdDownSort: ApiSort<ChatMessageDetailed>;
        
        
        //        val userSort:           ApiSort<ChatMessage> = ApiSort("sort=author_name", Comparator { o1, o2 -> o1.authorId?.compareTo(o2.authorId ?: NO_ID) ?: -1 })
        //        val userDownSort:       ApiSort<ChatMessage> = ApiSort("sort=-author_name", Comparator { o1, o2 -> o2.authorId?.compareTo(o1.authorId ?: NO_ID) ?: -1 })
        
        public readonly contentSort: ApiSort<ChatMessageDetailed>;
        
        public readonly contentDownSort: ApiSort<ChatMessageDetailed>;
        
    }
}