// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/AlertsVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { ComponentRowAlertXml } from '../../layout/ComponentRowAlertXml'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { CacheEdge } from '../../api/CacheEdge'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { xObservablePropertyFlatMap, xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { AlertType } from '../../model/AlertType'
import { SessionApi } from '../../api/SessionApi'
import { AlertsXml } from '../../layout/AlertsXml'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ComponentRowSchoolNameDrillXml } from '../../layout/ComponentRowSchoolNameDrillXml'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.alert.AlertsVG
export class AlertsVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.allAlerts = this.session.alerts.observableListSimple(undefined, Alert.Companion.INSTANCE.createdSort, undefined, undefined, undefined, undefined);
        this.drills = xObservablePropertyMap<(Array<Alert> | null), (Array<Alert> | null)>(this.allAlerts, (it: (Array<Alert> | null)): (Array<Alert> | null) => ((): (Array<Alert> | null) => {
            const temp4017 = it;
            if(temp4017 === null) { return null }
            return temp4017.filter((it: Alert): boolean => it.drill)
        })());
        this.realAlerts = xObservablePropertyMap<(Array<Alert> | null), (Array<Alert> | null)>(this.allAlerts, (it: (Array<Alert> | null)): (Array<Alert> | null) => ((): (Array<Alert> | null) => {
            const temp4019 = it;
            if(temp4019 === null) { return null }
            return temp4019.filter((it: Alert): boolean => (!it.drill))
        })());
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.AlertsVG.title
    public get title(): string { return "Alerts"; }
    
    
    public readonly allAlerts: CacheEdge<Alert>;
    
    public readonly drills: ObservableProperty<(Array<Alert> | null)>;
    
    public readonly realAlerts: ObservableProperty<(Array<Alert> | null)>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AlertsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.alertsHeader
        xViewBindExists(xml.alertsHeader, xObservablePropertyMap<(Array<Alert> | null), boolean>(this.realAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4023 = it;
            if(temp4023 === null) { return null }
            return temp4023.length !== 0
        })() === true));
        //--- Set Up xml.alertsSeparator
        xViewBindExists(xml.alertsSeparator, xObservablePropertyMap<(Array<Alert> | null), boolean>(this.realAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4025 = it;
            if(temp4025 === null) { return null }
            return temp4025.length !== 0
        })() === true));
        //--- Set Up xml.alerts
        xLinearLayoutBind<Alert>(xml.alerts, xObservablePropertyMap<(Array<Alert> | null), Array<Alert>>(this.realAlerts, (it: (Array<Alert> | null)): Array<Alert> => it ?? []), new Alert(undefined, (-1), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<Alert>): HTMLElement => {
            //--- Make Subview For xml.alerts
            const cellXml = new ComponentRowAlertXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Assist
            const type = xObservablePropertyFlatMapNotNull<number, AlertType>(xObservablePropertyMap<Alert, (number | null)>(observable, (it: Alert): (number | null) => it.name), (it: number): ObservableProperty<(AlertType | null)> => this.session.alertTypes.observable(it));
            
            const school = xObservablePropertyFlatMap<Alert, (School | null)>(observable, (alert: Alert): ObservableProperty<(School | null)> => this.session.schools.observable(alert.school));
            
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.root, undefined, (): void => {
                const it_4026 = school.value;
                if (it_4026 !== null) {
                    this.schoolFilter.value = it_4026;
                }
            });
            
            //--- Set Up cellXml.alertImage
            xImageViewBindImage(cellXml.alertImage, xObservablePropertyMap<(AlertType | null), (Image | null)>(type, (it: (AlertType | null)): (Image | null) => ((): (Image | null) => {
                const temp4027 = (it?.icon ?? null);
                if(temp4027 !== null) {
                    return xStringAsImage(temp4027)
                } else { return null }
            })()));
            
            //--- Set Up cellXml.schoolName
            xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<(School | null), string>(school, (it: (School | null)): string => (it?.name ?? null) ?? ""));
            
            //--- Set Up cellXml.alertType
            xTextViewBindString(cellXml.alertType, xObservablePropertyMap<(AlertType | null), string>(type, (it: (AlertType | null)): string => (it?.name ?? null) ?? "Alert"));
            
            //--- End Make Subview For xml.alerts
            return cellView;
        });
        
        //--- Set Up xml.drillsHeader
        xViewBindExists(xml.drillsHeader, xObservablePropertyMap<(Array<Alert> | null), boolean>(this.drills, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4029 = it;
            if(temp4029 === null) { return null }
            return temp4029.length !== 0
        })() === true));
        //--- Set Up xml.drillsSeparator
        xViewBindExists(xml.drillsSeparator, xObservablePropertyMap<(Array<Alert> | null), boolean>(this.drills, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4031 = it;
            if(temp4031 === null) { return null }
            return temp4031.length !== 0
        })() === true));
        //--- Set Up xml.drills
        xLinearLayoutBind<Alert>(xml.drills, xObservablePropertyMap<(Array<Alert> | null), Array<Alert>>(this.drills, (it: (Array<Alert> | null)): Array<Alert> => it ?? []), new Alert(undefined, (-1), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<Alert>): HTMLElement => {
            //--- Make Subview For xml.drills
            const cellXml = new ComponentRowAlertXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Assist
            const type = xObservablePropertyFlatMapNotNull<number, AlertType>(xObservablePropertyMap<Alert, (number | null)>(observable, (it: Alert): (number | null) => it.name), (it: number): ObservableProperty<(AlertType | null)> => this.session.alertTypes.observable(it));
            
            const school = xObservablePropertyFlatMap<Alert, (School | null)>(observable, (alert: Alert): ObservableProperty<(School | null)> => this.session.schools.observable(alert.school));
            
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.root, undefined, (): void => {
                const it_4032 = school.value;
                if (it_4032 !== null) {
                    this.schoolFilter.value = it_4032;
                }
            });
            
            //--- Set Up cellXml.alertImage
            xImageViewBindImage(cellXml.alertImage, xObservablePropertyMap<(AlertType | null), (Image | null)>(type, (it: (AlertType | null)): (Image | null) => ((): (Image | null) => {
                const temp4033 = (it?.icon ?? null);
                if(temp4033 !== null) {
                    return xStringAsImage(temp4033)
                } else { return null }
            })()));
            
            //--- Set Up cellXml.schoolName
            xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<(School | null), string>(school, (it: (School | null)): string => (it?.name ?? null) ?? ""));
            
            //--- Set Up cellXml.alertType
            xTextViewBindString(cellXml.alertType, xObservablePropertyMap<(AlertType | null), string>(type, (it: (AlertType | null)): string => (it?.name ?? null) ?? "Drill"));
            
            //--- End Make Subview For xml.drills
            return cellView;
        });
        
        //--- Set Up xml.allClear
        //--- Set Up xml.schoolNames
        xLinearLayoutBind<School>(xml.schoolNames, xObservablePropertyCombine<(Array<Alert> | null), Array<School>, Array<School>>(this.allAlerts, xObservablePropertyMap<(Array<School> | null), Array<School>>(this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => ((): (Array<School> | null) => {
                            const temp4034 = it;
                            if(temp4034 === null) { return null }
                            const temp4035 = (it: School): (string | null) => it.name;
                            return temp4034.slice().sort((a, b) => safeCompare(temp4035(a), temp4035(b)))
            })() ?? []), (alerts: (Array<Alert> | null), schools: Array<School>): Array<School> => alerts !== null ? schools.filter((s: School): boolean => (!alerts!.some((it: Alert): boolean => it.school === s.id))) : schools), new School(undefined, "fake school", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<School>): HTMLElement => {
                //--- Make Subview For xml.schoolNames (overwritten on flow generation)
                const cellXml = new ComponentRowSchoolNameDrillXml();
                
                const cellView = cellXml.setup(dependency);
                
                
                //--- Set Up cellXml.root
                xViewOnClick(cellXml.root, undefined, (): void => {
                    this.schoolFilter.value = observable.value;
                });
                
                //--- Set Up cellXml.schoolName
                xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<School, string>(observable, (it: School): string => it.name));
                
                //--- Set Up cellXml.drillIcon
                const alert = xObservablePropertyFlatMap<School, (Alert | null)>(observable, (it: School): ObservableProperty<(Alert | null)> => xObservablePropertyMap<(Array<Alert> | null), (Alert | null)>(this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(it.id), undefined, undefined, undefined, undefined, undefined), (it: (Array<Alert> | null)): (Alert | null) => ((): (Alert | null) => {
                    const temp4040 = it;
                    if(temp4040 === null) { return null }
                    return (temp4040[0] ?? null)
                })()));
                
                
                xViewBindExists(cellXml.drillIcon, xObservablePropertyMap<(Alert | null), boolean>(alert, (it: (Alert | null)): boolean => it !== null && it!.drill));
                //--- End Make Subview For xml.schoolNames (overwritten on flow generation)
                return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action cellXmlRootClick
    //--- Action cellXmlXmlRootClick
    //--- Action drillsClick
    
    //--- Action alertsClick
    
    
    //--- Body End
}
