import { StandardObservableProperty } from "butterfly-web/dist/observables/StandardObservableProperty"
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'

export class AlertSound {
    readonly element = document.createElement("audio")
    readonly audioUrl = new StandardObservableProperty<string | null>(null)
    readonly playing = new StandardObservableProperty(false)

    static INSTANCE = new AlertSound()

    constructor() {
        this.element.hidden = true
        this.element.loop = true
        document.body.appendChild(this.element)
        xObservablePropertySubscribeBy(this.audioUrl, undefined, undefined, (e) => {
            if(e){
                this.element.src = e
            } else {
                this.element.src = "audio/alert.mp3"
            }
        })
        xObservablePropertySubscribeBy(this.playing, undefined, undefined, (e) => {
            if(e){
                this.element.play()
                    .catch((x) => {
                        console.error(`Sound failed to play due to ${x}`)
                    })
            } else {
                this.element.pause()
            }
        })
    }
}