// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/map/maker/svgExport.kt
// Package: com.tresitgroup.android.tresit.map.maker
// Generated by Khrysalis - this file will be overwritten.
import { CampusGeoShape, GeoShape } from '../Models'
import { GeoCoordinateBounds } from '../Geometry'
import { StringBuilder, buildString } from 'butterfly-web/dist/kotlin/kotlin.text'
import { xAnyToJsonString } from 'butterfly-web/dist/Codable'
import { FullIdentifier, FullIdentifierType, MercatorProjection, Pin } from './SketchSvgFormat'
import { GeoCoordinate } from 'butterfly-web/dist/location/GeoCoordinate'
import { Point } from '../../svg/Point2D'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { first as iterFirst } from 'butterfly-web/dist/kotlin/lazyOp'
import { also } from 'butterfly-web/dist/Kotlin'

const mapBoxApiKey = "pk.eyJ1IjoibWFkc2NpZW50aXN0bW9zZXMiLCJhIjoiY2p3aWdvOHp0MDlreTQ5cDc5Z3NwM2NhNyJ9.QG2zb8s1xwOnJBTWNJREpQ";


//! Declares com.tresitgroup.android.tresit.map.maker.calculateMercatorProjection>com.tresitgroup.android.tresit.map.GeoCoordinateBounds
export function xGeoCoordinateBoundsCalculateMercatorProjection(this_: GeoCoordinateBounds, size: Point): MercatorProjection {
    let proj = new MercatorProjection(new GeoCoordinate((this_.southwest.latitude + this_.northeast.latitude) / 2, (this_.southwest.longitude + this_.northeast.longitude) / 2), 22, new Point(size.x / 2, size.y / 2));
    
    const topLeft = this_.northeast;
    
    while (true) {
        const topLeftOffset = proj.project(topLeft);
        
        if (topLeftOffset.x >= 0 && topLeftOffset.y >= 0) { break  }
        if (proj.zoomLevel === 2) { break  }
        proj = proj.copy(undefined, proj.zoomLevel - 1, undefined);
    }
    return proj;
}

//! Declares com.tresitgroup.android.tresit.map.maker.generateSketchSvg>com.tresitgroup.android.tresit.map.CampusGeoShape
export function xCampusGeoShapeGenerateSketchSvg(this_: CampusGeoShape): string {
    const size = new Point(1024.0, 1024.0);
    
    const proj = xGeoCoordinateBoundsCalculateMercatorProjection(this_.calculatedBounds, size);
    
    const satelliteUrl = also(buildString((this_1: StringBuilder): void => {
                this_1.value += "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/";
                this_1.value += `pin-l(${this_.calculatedBounds.southwest.longitude},${this_.calculatedBounds.southwest.latitude}),`;
                this_1.value += `pin-l(${this_.calculatedBounds.northeast.longitude},${this_.calculatedBounds.southwest.latitude}),`;
                this_1.value += `pin-l(${this_.calculatedBounds.northeast.longitude},${this_.calculatedBounds.northeast.latitude}),`;
                this_1.value += `pin-l(${this_.calculatedBounds.southwest.longitude},${this_.calculatedBounds.northeast.latitude})`;
                this_1.value += "/";
                this_1.value += proj.center.longitude.toString();
                this_1.value += ",";
                this_1.value += proj.center.latitude.toString();
                this_1.value += ",";
                this_1.value += proj.zoomLevel.toString();
                this_1.value += "/";
                this_1.value += Math.floor(size.x).toString();
                this_1.value += 'x';
                this_1.value += Math.floor(size.y).toString();
                this_1.value += `?access_token=${mapBoxApiKey}`;
        }), (it: string): void => {
            console.log(`Loading from ${it}`);
    });
    
    const pins = [this_.calculatedBounds.southwest, this_.calculatedBounds.northeast, this_.calculatedBounds.southwest.copy(undefined, this_.calculatedBounds.northeast.longitude), this_.calculatedBounds.southwest.copy(this_.calculatedBounds.northeast.latitude, undefined)].map((it: GeoCoordinate): Pin => new Pin(proj.project(it), it));
    
    const writer = new SvgWriter(proj);
    
    writer.node("svg", new Map([["xmlns", "http://www.w3.org/2000/svg"], ["xmlns:xlink", "http://www.w3.org/1999/xlink"], ["width", `${size.y}`], ["height", `${size.x}`]]), (): void => {
        writer.backgroundLayer(satelliteUrl, size);
        writer.node("text", new Map([["x", "0"], ["y", "12"], ["style", "font: 8px"]]), (): void => {
            writer.out.value += xAnyToJsonString(proj);
        });
        
        writer.campus(this_);
    });
    return writer.out.toString();
}

//! Declares com.tresitgroup.android.tresit.map.maker.generateEmptySketchSvg>com.tresitgroup.android.tresit.map.GeoCoordinateBounds
export function xGeoCoordinateBoundsGenerateEmptySketchSvg(this_: GeoCoordinateBounds): string {
    const calculatedBounds = this_;
    
    const size = new Point(1024.0, 1024.0);
    
    const proj = xGeoCoordinateBoundsCalculateMercatorProjection(calculatedBounds, size);
    
    const satelliteUrl = also(buildString((this_1: StringBuilder): void => {
                this_1.value += "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/";
                this_1.value += `pin-l(${calculatedBounds.southwest.longitude},${calculatedBounds.southwest.latitude}),`;
                this_1.value += `pin-l(${calculatedBounds.northeast.longitude},${calculatedBounds.southwest.latitude}),`;
                this_1.value += `pin-l(${calculatedBounds.northeast.longitude},${calculatedBounds.northeast.latitude}),`;
                this_1.value += `pin-l(${calculatedBounds.southwest.longitude},${calculatedBounds.northeast.latitude})`;
                this_1.value += "/";
                this_1.value += proj.center.longitude.toString();
                this_1.value += ",";
                this_1.value += proj.center.latitude.toString();
                this_1.value += ",";
                this_1.value += proj.zoomLevel.toString();
                this_1.value += "/";
                this_1.value += Math.floor(size.x).toString();
                this_1.value += 'x';
                this_1.value += Math.floor(size.y).toString();
                this_1.value += `?access_token=${mapBoxApiKey}`;
        }), (it: string): void => {
            console.log(`Loading from ${it}`);
    });
    
    const pins = [calculatedBounds.southwest, calculatedBounds.northeast, calculatedBounds.southwest.copy(undefined, calculatedBounds.northeast.longitude), calculatedBounds.southwest.copy(calculatedBounds.northeast.latitude, undefined)].map((it: GeoCoordinate): Pin => new Pin(proj.project(it), it));
    
    const writer = new SvgWriter(proj);
    
    writer.node("svg", new Map([["xmlns", "http://www.w3.org/2000/svg"], ["xmlns:xlink", "http://www.w3.org/1999/xlink"], ["width", `${size.y}`], ["height", `${size.x}`]]), (): void => {
        writer.backgroundLayer(satelliteUrl, size);
        writer.node("text", new Map([["x", "0"], ["y", "12"], ["style", "font: 8px"]]), (): void => {
            writer.out.value += xAnyToJsonString(proj);
        });
    });
    return writer.out.toString();
}

//! Declares com.tresitgroup.android.tresit.map.maker.SvgWriter
export class SvgWriter {
    public readonly proj: MercatorProjection;
    public constructor(proj: MercatorProjection) {
        this.proj = proj;
        this.out = new StringBuilder();
        this.out.value += "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?> " + '\n';
    }
    
    public readonly out: StringBuilder;
    
    
    public backgroundLayer(url: string, size: Point): void {
        this.node("g", new Map([["id", "layer-background"]]), (): void => {
            this.node("image", new Map([["x", "0"], ["y", "0"], ["width", size.x.toString()], ["height", size.y.toString()], ["style", "opacity:0.5"], ["preserveAspectRatio", "none"], ["xlink:href", url]]), undefined);
        });
    }
    public node(type: string, attributes: Map<string, string>, writeChildren: (() => void) = (): void => {}): void {
        this.out.value += `<${type} `;
        for (const toDestructure of attributes) {
            const key = toDestructure[0]
            const value = toDestructure[1]
            
            this.out.value += `${key}=\"${value}\" `
            
        }
        this.out.value += ">";
        writeChildren();
        this.out.value += `</${type}>`;
    }
    public bounds(shape: GeoShape): void {
        const points = shape.parsedGeometry.map((it: GeoCoordinate): string => {
            const fixed = this.proj.project(it);
            
            return ((this_: Point): string => `${this_.x},${this_.y}`)(fixed);
        }).join(" L ");
        
        this.node("path", new Map([["d", `M ${points} Z`], ["style", "stroke: black; fill: none; stroke-width: 0.5"], ["id", new FullIdentifier(FullIdentifierType.Bounds, shape.name).toId()]]), undefined);
        this.label(shape);
    }
    public shape(shape: GeoShape): void {
        const points = shape.parsedGeometry.map((it: GeoCoordinate): string => {
            const fixed = this.proj.project(it);
            
            return ((this_: Point): string => `${this_.x},${this_.y}`)(fixed);
        }).join(" L ");
        
        this.node("path", new Map([["d", `M ${points} Z`], ["style", "stroke: black; fill: none; stroke-width: 0.5"], ["id", new FullIdentifier(FullIdentifierType.Identifier, shape.name).toId()]]), undefined);
        this.label(shape);
    }
    public label(shape: GeoShape): void {
        const location_1836 = shape.labelLocation;
        if (location_1836 !== null) {
            const fixed = this.proj.project(location_1836);
            
            this.node("circle", new Map([["cx", fixed.x.toString()], ["cy", fixed.y.toString()], ["r", "2"], ["fill", "green"], ["id", new FullIdentifier(FullIdentifierType.Label, shape.name).toId()]]), undefined);
        }
    }
    
    public container(shape: GeoShape, contents: (() => void)): void {
        this.node("g", new Map([["id", new FullIdentifier(FullIdentifierType.Identifier, shape.name).toId()]]), (): void => {
            contents();
            this.bounds(shape);
        });
    }
    
    public campus(campus: CampusGeoShape): void {
        this.container(campus, (): void => {
            for (const building of campus.buildings) {
                if (building.floors.length <= 1 && ((((): (number | null) => {
                    const temp1846 = ((building.floors[0] ?? null)?.rooms ?? null);
                    if(temp1846 === null) { return null }
                    return temp1846.length
                })() ?? 0) <= 1)) {
                    this.shape(building);
                } else {
                    this.container(building, (): void => {
                        if (!(building.floors.length === 1)) {
                            for (const floor of building.floors) {
                                this.node("g", new Map([["id", new FullIdentifier(FullIdentifierType.Identifier, floor.name).toId()]]), (): void => {
                                    for (const room of floor.rooms) {
                                        this.shape(room);
                                    }
                                });
                            }
                        } else {
                            for (const room of iterFirst(building.floors).rooms) {
                                this.shape(room);
                            }
                        }
                    });
                }
            }
        });
    }
}
