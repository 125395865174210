//
// PostCreateXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './post_create.html'
//! Declares com.tresitgroup.android.tresit.layouts.PostCreateXml
export class PostCreateXml {
    xmlRoot!: HTMLElement
    school!: HTMLSelectElement
    editTitle!: HTMLInputElement
    editContent!: HTMLTextAreaElement
    cancel!: HTMLButtonElement
    completeWorking!: HTMLDivElement
    post!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.school = getViewById<HTMLSelectElement>(view, "school")
        this.editTitle = getViewById<HTMLInputElement>(view, "edit_title")
        this.editContent = getViewById<HTMLTextAreaElement>(view, "edit_content")
        this.cancel = getViewById<HTMLButtonElement>(view, "cancel")
        this.completeWorking = getViewById<HTMLDivElement>(view, "completeWorking")
        this.post = getViewById<HTMLButtonElement>(view, "post")
        return view
    }
}
