// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/MoreSettingVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { RowSchoolSafeUnsafeXml } from '../../layout/RowSchoolSafeUnsafeXml'
import { ApiModifier } from '../../api/ApiModifier'
import { LayoutMoreSettingXml } from '../../layout/LayoutMoreSettingXml'
import { School } from '../../model/School'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'

//! Declares com.tresitgroup.android.tresit.vg.admin.MoreSettingVG
export class MoreSettingVG extends ViewGenerator {
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public constructor(schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.isLoading = new StandardObservableProperty<boolean>(true, undefined);
        this.schools = new StandardObservableProperty<Array<School>>([new School(undefined, "School", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)], undefined);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<School>>(this.session.schools.getListSimple(undefined, undefined, undefined, undefined, undefined), undefined, (schoolList: Array<School>): void => {
            this.schools.value = schoolList;
            this.isLoading.value = false;
            this.schools.update();
        }));
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.MoreSettingVG.title
    public get title(): string { return "More Settings"; }
    
    public isLoading: MutableObservableProperty<boolean>;
    
    public schools: MutableObservableProperty<Array<School>>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LayoutMoreSettingXml();
        
        const view = xml.setup(dependency);
        
        xViewFlipperBindLoading(xml.schoolLoading, this.isLoading, undefined);
        
        xRecyclerViewBind<School>(xml.schools, this.schools, new School(undefined, "School", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<School>): HTMLElement => {
            const cellXml = new RowSchoolSafeUnsafeXml();
            
            const cellView = cellXml.setup(dependency);
            
            this.isLoading.value = false;
            
            xCompoundButtonBind(cellXml.cbSafeUnsafe, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<School, boolean>(observable, (it: School): boolean => it.show_safe_unsafe), (valueToWrite: boolean): void => {
                const target = observable.value;
                
                xSingleCallDisplayingError<School>(this.session.schools.patch(target.id, [ApiModifier.constructorStringAnyFunction1HasIdHasId<School>("show_safe_unsafe", valueToWrite, (it: School): School => it)]), undefined, (it: School): void => {
                    target.show_safe_unsafe = (!valueToWrite);
                });
            }));
            xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<School, string>(observable, (it: School): string => it.name));
            return cellView;
        });
        return view;
        
    }
    
}