// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/District.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.District
export class District implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly name: string;
    public constructor(id: number = getNO_ID(), name: string) {
        this.id = id;
        this.name = name;
    }
    public static fromJson(obj: any): District { return new District(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string
    ) }
    public toJSON(): object { return {
        id: this.id,
        name: this.name
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.name);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof District && safeEq(this.id, other.id) && safeEq(this.name, other.name) }
    public toString(): string { return `District(id=${this.id}, name=${this.name})` }
    public copy(id: number = this.id, name: string = this.name): District { return new District(id, name); }
    
    
}
export namespace District {
    //! Declares com.tresitgroup.android.tresit.model.District.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public textSearch(query: string): ApiFilter<District> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<District>(`query=${query}`, undefined, (it: District): boolean => (it.toString().toLowerCase().indexOf(query.toLowerCase()) != -1));
        }
    }
}