// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/UserSchoolMembership.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { HasId, getNO_ID } from './HasId'
import { Room } from './Room'
import { ApiModifier } from '../api/ApiModifier'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.UserSchoolMembership
export class UserSchoolMembership implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly user: ForeignKey<User>;
    public readonly school: ForeignKey<School>;
    public readonly includeInInternalAlert: boolean;
    public readonly notifyOnApprovalRequired: boolean;
    public readonly defaultLocation1: (ForeignKey<Room> | null);
    public readonly defaultLocation2: (ForeignKey<Room> | null);
    public readonly defaultLocation3: (ForeignKey<Room> | null);
    public readonly defaultLocation4: (ForeignKey<Room> | null);
    public readonly defaultLocation5: (ForeignKey<Room> | null);
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, user: ForeignKey<User>, school: ForeignKey<School>, includeInInternalAlert: boolean = false, notifyOnApprovalRequired: boolean = false, defaultLocation1: (ForeignKey<Room> | null) = null, defaultLocation2: (ForeignKey<Room> | null) = null, defaultLocation3: (ForeignKey<Room> | null) = null, defaultLocation4: (ForeignKey<Room> | null) = null, defaultLocation5: (ForeignKey<Room> | null) = null) {
        this.id = id;
        this.deleted = deleted;
        this.user = user;
        this.school = school;
        this.includeInInternalAlert = includeInInternalAlert;
        this.notifyOnApprovalRequired = notifyOnApprovalRequired;
        this.defaultLocation1 = defaultLocation1;
        this.defaultLocation2 = defaultLocation2;
        this.defaultLocation3 = defaultLocation3;
        this.defaultLocation4 = defaultLocation4;
        this.defaultLocation5 = defaultLocation5;
    }
    public static fromJson(obj: any): UserSchoolMembership { return new UserSchoolMembership(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["baseuser"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as number,
        parseJsonTyped(obj["included_in_internal"], [Boolean]) as boolean,
        parseJsonTyped(obj["included_in_approval"], [Boolean]) as boolean,
        parseJsonTyped(obj["default_location1"], [Number]) as (number | null),
        parseJsonTyped(obj["default_location2"], [Number]) as (number | null),
        parseJsonTyped(obj["default_location3"], [Number]) as (number | null),
        parseJsonTyped(obj["default_location4"], [Number]) as (number | null),
        parseJsonTyped(obj["default_location5"], [Number]) as (number | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        baseuser: this.user,
        school: this.school,
        included_in_internal: this.includeInInternalAlert,
        included_in_approval: this.notifyOnApprovalRequired,
        default_location1: this.defaultLocation1,
        default_location2: this.defaultLocation2,
        default_location3: this.defaultLocation3,
        default_location4: this.defaultLocation4,
        default_location5: this.defaultLocation5
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.user);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.includeInInternalAlert);
        hash = 31 * hash + hashAnything(this.notifyOnApprovalRequired);
        hash = 31 * hash + hashAnything(this.defaultLocation1);
        hash = 31 * hash + hashAnything(this.defaultLocation2);
        hash = 31 * hash + hashAnything(this.defaultLocation3);
        hash = 31 * hash + hashAnything(this.defaultLocation4);
        hash = 31 * hash + hashAnything(this.defaultLocation5);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof UserSchoolMembership && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.user, other.user) && safeEq(this.school, other.school) && safeEq(this.includeInInternalAlert, other.includeInInternalAlert) && safeEq(this.notifyOnApprovalRequired, other.notifyOnApprovalRequired) && safeEq(this.defaultLocation1, other.defaultLocation1) && safeEq(this.defaultLocation2, other.defaultLocation2) && safeEq(this.defaultLocation3, other.defaultLocation3) && safeEq(this.defaultLocation4, other.defaultLocation4) && safeEq(this.defaultLocation5, other.defaultLocation5) }
    public toString(): string { return `UserSchoolMembership(id=${this.id}, deleted=${this.deleted}, user=${this.user}, school=${this.school}, includeInInternalAlert=${this.includeInInternalAlert}, notifyOnApprovalRequired=${this.notifyOnApprovalRequired}, defaultLocation1=${this.defaultLocation1}, defaultLocation2=${this.defaultLocation2}, defaultLocation3=${this.defaultLocation3}, defaultLocation4=${this.defaultLocation4}, defaultLocation5=${this.defaultLocation5})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, user: ForeignKey<User> = this.user, school: ForeignKey<School> = this.school, includeInInternalAlert: boolean = this.includeInInternalAlert, notifyOnApprovalRequired: boolean = this.notifyOnApprovalRequired, defaultLocation1: (ForeignKey<Room> | null) = this.defaultLocation1, defaultLocation2: (ForeignKey<Room> | null) = this.defaultLocation2, defaultLocation3: (ForeignKey<Room> | null) = this.defaultLocation3, defaultLocation4: (ForeignKey<Room> | null) = this.defaultLocation4, defaultLocation5: (ForeignKey<Room> | null) = this.defaultLocation5): UserSchoolMembership { return new UserSchoolMembership(id, deleted, user, school, includeInInternalAlert, notifyOnApprovalRequired, defaultLocation1, defaultLocation2, defaultLocation3, defaultLocation4, defaultLocation5); }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserSchoolMembership.defaultLocations
    public get defaultLocations(): Array<ForeignKey<Room>> { return listFilterNotNull([this.defaultLocation1, this.defaultLocation2, this.defaultLocation3, this.defaultLocation4, this.defaultLocation5]); }
    
    
    private getDefault(index: number = 0): (ForeignKey<Room> | null) {
        return ((): (number | null) => {
            switch(index) {
                case 0:
                return this.defaultLocation1
                case 1:
                return this.defaultLocation2
                case 2:
                return this.defaultLocation3
                case 3:
                return this.defaultLocation4
                case 4:
                return this.defaultLocation5
                default:
                return null
            }
            
        })();
    }
    private copyChangingDefault(index: number = 0, roomId: (ForeignKey<Room> | null)): UserSchoolMembership {
        return ((): UserSchoolMembership => {
            switch(index) {
                case 0:
                return this.copy(undefined, undefined, undefined, undefined, undefined, undefined, roomId, undefined, undefined, undefined, undefined)
                case 1:
                return this.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, roomId, undefined, undefined, undefined)
                case 2:
                return this.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, roomId, undefined, undefined)
                case 3:
                return this.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, roomId, undefined)
                case 4:
                return this.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, roomId)
                default:
                throw "We cap out at 5 default locations.  How did you do this?"
                break;
            }
            
        })();
    }
    private findFreeDefaultIndex(): (number | null) {
        return ((): (number | null) => {
            if (this.defaultLocation1 === null) {
                return 0
            } else if (this.defaultLocation2 === null) {
                return 1
            } else if (this.defaultLocation3 === null) {
                return 2
            } else if (this.defaultLocation4 === null) {
                return 3
            } else if (this.defaultLocation5 === null) {
                return 4
            } else  {
                return null
            }
        })();
    }
    public removeDefaultLocation(roomId: ForeignKey<Room>): (ApiModifier<UserSchoolMembership> | null) {
        const index = this.defaultLocations.findIndex((it: number): boolean => it === roomId);
        
        if (index === (-1)) { return null }
        return ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>(`default_location${index + 1}`, null, (it: UserSchoolMembership): UserSchoolMembership => it.copyChangingDefault(index, null));
    }
    public addDefaultLocation(roomId: ForeignKey<Room>): (ApiModifier<UserSchoolMembership> | null) {
        const index = this.findFreeDefaultIndex()
        if(index === null) { return null }
        return ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>(`default_location${index + 1}`, roomId, (it: UserSchoolMembership): UserSchoolMembership => it.copyChangingDefault(index, roomId));
    }
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace UserSchoolMembership {
    //! Declares com.tresitgroup.android.tresit.model.UserSchoolMembership.Companion
    export class Companion {
        private constructor() {
            this.defaultLocationCap = 5;
        }
        public static INSTANCE = new Companion();
        
        public readonly defaultLocationCap: number;
        
        public forUser(user: ForeignKey<User>): ApiFilter<UserSchoolMembership> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserSchoolMembership>(`baseuser=${user}`, undefined, (it: UserSchoolMembership): boolean => it.user === user);
        }
        public forSchool(school: ForeignKey<School>): ApiFilter<UserSchoolMembership> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserSchoolMembership>(`school=${school}`, undefined, (it: UserSchoolMembership): boolean => it.school === school);
        }
    }
}
