// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/SelectStatusVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { SelectStatusXml } from '../../layout/SelectStatusXml'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { StatusEnum } from '../../model/StatusEnum'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { Room } from '../../model/Room'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.alert.SelectStatusVG
export class SelectStatusVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly alertId: (number | null);
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, alertId: (number | null) = null) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.alertId = alertId;
        this.selecting = new StandardObservableProperty<boolean>(false, undefined);
        this.needMedicalAttention = new StandardObservableProperty<boolean>(false, undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.SelectStatusVG.title
    public get title(): string { return "Select Status"; }
    
    
    public readonly selecting: StandardObservableProperty<boolean>;
    
    public needMedicalAttention: MutableObservableProperty<boolean>;
    
    public currentRoom(): ObservableProperty<(Room | null)> {
        return xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyMap<(User | null), (number | null)>(this.session.me, (it: (User | null)): (number | null) => (it?.currentLocation ?? null)), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it));
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new SelectStatusXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const currentRoom = this.currentRoom();
        
        
        //--- Current room match
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(currentRoom, undefined, undefined, (it: (Room | null)): void => {
            if (it !== null) {
                this.needMedicalAttention.value = it!.needsMedicalAttention;
            }
        }), xViewRemovedGet(view));
        
        //--- Set Up xml.selectingStatus
        xViewFlipperBindLoading(xml.selectingStatus, this.selecting, undefined);
        
        //--- Set Up xml.needMedicalAttention
        xCompoundButtonBind(xml.needMedicalAttention, this.needMedicalAttention);
        
        //--- Set Up xml.safe
        xViewOnClick(xml.safe, undefined, (): void => {
            this.statusClick(StatusEnum.Safe);
        });
        
        //--- Set Up xml.unsafe
        xViewOnClick(xml.unsafe, undefined, (): void => {
            this.statusClick(StatusEnum.Unsafe);
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public statusClick(status: StatusEnum): void {
        const id = (this.session.me.value?.currentLocation ?? null);
        
        if (id !== null) {
            xSingleCallDisplayingError<Room>(xSingleWorking<Room>(this.session.rooms.patch(id!, [Room.Companion.INSTANCE.setStatus(status), Room.Companion.INSTANCE.setNeedsMedical(this.needMedicalAttention.value), Room.Companion.INSTANCE.setAlertId(this.alertId)]), this.selecting), undefined, (it: Room): void => {
                this.dialog.dismiss();
            });
        } else {
            this.dialog.dismiss();
        }
    }
    
    //--- Body End
}
