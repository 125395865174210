//
// MapsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './maps.html'
//! Declares com.tresitgroup.android.tresit.layouts.MapsXml
export class MapsXml {
    xmlRoot!: HTMLElement
    dummy2!: HTMLElement
    dummy1!: HTMLElement
    dummy3!: HTMLElement
    content!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.dummy2 = getViewById<HTMLElement>(view, "dummy2")
        this.dummy1 = getViewById<HTMLElement>(view, "dummy1")
        this.dummy3 = getViewById<HTMLElement>(view, "dummy3")
        this.content = getViewById<HTMLDivElement>(view, "content")
        return view
    }
}
