//
// AdminPasswordResetXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './admin_password_reset.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminPasswordResetXml
export class AdminPasswordResetXml {
    xmlRoot!: HTMLElement
    password!: HTMLInputElement
    confirmPassword!: HTMLInputElement
    loading!: HTMLDivElement
    cancel!: HTMLButtonElement
    save!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.password = getViewById<HTMLInputElement>(view, "password")
        this.confirmPassword = getViewById<HTMLInputElement>(view, "confirmPassword")
        this.loading = getViewById<HTMLDivElement>(view, "loading")
        this.cancel = getViewById<HTMLButtonElement>(view, "cancel")
        this.save = getViewById<HTMLButtonElement>(view, "save")
        return view
    }
}
