// File: launchTestNotification.kt
// Package: com.tresitgroup.android.tresit

//! Declares com.tresitgroup.android.tresit.launchTestNotification
export function launchTestNotification(): void {
}

//! Declares com.tresitgroup.android.tresit.cleanAlertNotifications
export function cleanAlertNotifications(window: Window, ongoing: Array<number>) {
    //Do nothing; notifications do not stick on web
}
