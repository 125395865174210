// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/ExportOptionsVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xActivityAccessDownloadFile } from 'butterfly-web/dist/views/ViewDependency'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { PinVG } from '../PinVG'
import { ExportOptionLayoutXml } from '../../layout/ExportOptionLayoutXml'

//! Declares com.tresitgroup.android.tresit.vg.admin.ExportOptionsVG
export class ExportOptionsVG extends ViewGenerator implements HasBackAction, PinVG {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public static implementsInterfaceComTresitgroupAndroidTresitVgPinVG = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
    }
    
    
    
    public onBackPressed(): boolean {
        return true;
    }
    
    //--- State
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ExportOptionLayoutXml();
        
        const view = xml.setup(dependency);
        
        
        xml.btnClose.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            this.dialog.dismiss();
        };
        
        xml.summaryPDF.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            xActivityAccessDownloadFile(dependency, "https://www.africau.edu/images/default/sample.pdf");
        };
        
        return view;
    }
    
    //--- Init
    
    
    
    
}
