//
// DemoSessionXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './demo_session.html'
//! Declares com.tresitgroup.android.tresit.layouts.DemoSessionXml
export class DemoSessionXml {
    xmlRoot!: HTMLElement
    user!: HTMLSelectElement
    demo!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.user = getViewById<HTMLSelectElement>(view, "user")
        this.demo = getViewById<HTMLDivElement>(view, "demo")
        return view
    }
}
