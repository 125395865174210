//
// SettingsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/settings.html'
import htmlForDefault from './settings.html'
//! Declares com.tresitgroup.android.tresit.layouts.SettingsXml
export class SettingsXml {
    xmlRoot!: HTMLElement
    userName!: HTMLElement
    userEmail!: HTMLElement
    appVersion!: HTMLElement
    refresh!: HTMLButtonElement
    checkIn!: HTMLButtonElement
    admin!: HTMLButtonElement
    editUser!: HTMLButtonElement
    guidances!: HTMLButtonElement
    alertHistory!: HTMLButtonElement
    adminAlertHistory!: HTMLButtonElement
    manageAlerts!: HTMLButtonElement
    manageDefaultLocations!: HTMLButtonElement
    editGoogleMap!: HTMLButtonElement
    logOut!: HTMLButtonElement
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.userName = getViewById<HTMLElement>(view, "userName")
        this.userEmail = getViewById<HTMLElement>(view, "userEmail")
        this.appVersion = getViewById<HTMLElement>(view, "appVersion")
        this.refresh = getViewById<HTMLButtonElement>(view, "refresh")
        this.checkIn = getViewById<HTMLButtonElement>(view, "checkIn")
        this.admin = getViewById<HTMLButtonElement>(view, "admin")
        this.editUser = getViewById<HTMLButtonElement>(view, "edit_user")
        this.guidances = getViewById<HTMLButtonElement>(view, "guidances")
        this.alertHistory = getViewById<HTMLButtonElement>(view, "alert_history")
        this.adminAlertHistory = getViewById<HTMLButtonElement>(view, "admin_alert_history")
        this.manageAlerts = getViewById<HTMLButtonElement>(view, "manage_alerts")
        this.manageDefaultLocations = getViewById<HTMLButtonElement>(view, "manage_default_locations")
        this.editGoogleMap = getViewById<HTMLButtonElement>(view, "edit_google_map")
        this.logOut = getViewById<HTMLButtonElement>(view, "log_out")
        return view
    }
}
