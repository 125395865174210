// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/apicalls.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.
import { Exception } from 'butterfly-web/dist/Kotlin'
import { R } from '../R'
import { Observable, SubscriptionLike, TimeoutError } from 'rxjs'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ViewString, ViewStringRaw, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xResponseCodeGet } from 'butterfly-web/dist/net/HttpResponse'
import { xStringParseAsErrors } from './String.extentions'
import { xResponseReadText } from 'butterfly-web/dist/net/RxHttpAssist'
import { printStackTrace } from 'butterfly-web/dist/kotlin/Language'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'

//! Declares com.tresitgroup.android.tresit.util.callDisplayingError>io.reactivex.Single<kotlin.Any>
export function xSingleCallDisplayingError<Element extends any>(this_: Observable<Element>, knownErrorChecks:  ((a: number, b: string) => (ViewString | null)) = (_0: number, _1: string): (ViewString | null) => {
    return null;
}, onSuccess:  ((a: Element) => void) = (it: Element): void => {}): SubscriptionLike {
    return this_.subscribe((it: Element): void => {
            onSuccess(it);
        }, (error: any): void => {
            if (error instanceof HttpResponseException){
                xResponseReadText((error as HttpResponseException).response).subscribe((it: string): void => {
                        const message = it;
                        
                        const knownResponse = knownErrorChecks(xResponseCodeGet((error as HttpResponseException).response), message);
                        
                        if (knownResponse !== null) {
                            showDialogAlert(knownResponse!);
                        } else {
                            console.log(`!!! ERROR FROM SERVER WITH CODE ${xResponseCodeGet((error as HttpResponseException).response)}: ${message}`);
                            switch(xResponseCodeGet((error as HttpResponseException).response)) {
                                case 400:
                                showDialogAlert(((): (ViewStringRaw | null) => {
                                    const temp2993 = xStringParseAsErrors(message);
                                    if(temp2993 === null) { return null }
                                    return ((errors: string): ViewStringRaw => new ViewStringRaw(errors))(temp2993)
                                })() ?? new ViewStringResource(R._string.error_bad_request))
                                break;
                                case 401:
                                showDialogAlert(new ViewStringResource(R._string.error_unauthorized))
                                break;
                                case 403:
                                showDialogAlert(new ViewStringResource(R._string.error_forbidden))
                                break;
                                case 404:
                                showDialogAlert(new ViewStringResource(R._string.error_not_found))
                                break;
                                case 500:
                                showDialogAlert(new ViewStringResource(R._string.error_server))
                                break;
                                case 502:
                                case 503:
                                case 504:
                                showDialogAlert(new ViewStringResource(R._string.error_no_server))
                                break;
                                default:
                                showDialogAlert(new ViewStringResource(R._string.error_unknown))
                                break;
                            }
                            
                        }
                    }, (it: any): void => {
                        showDialogAlert(new ViewStringRaw(error.message ?? ""));
                });
            } else if (error instanceof TimeoutError){
                showDialogAlert(new ViewStringResource(R._string.error_no_server));
            } else if (error instanceof Exception){
                if (error.message === "No offsite room found.") {
                    
                } else {
                    showDialogAlert(new ViewStringResource(R._string.error_local));
                }
                printStackTrace(error);
            } else {
                showDialogAlert(new ViewStringResource(R._string.error_local));
                console.log(`!!! A special kind of error occurred: ${error}`);
            }
    });
}

//! Declares com.tresitgroup.android.tresit.util.callDisplayingError>io.reactivex.Observable<kotlin.Any>
export function xObservableCallDisplayingError<Element extends any>(this_: Observable<Element>, knownErrorChecks:  ((a: number, b: string) => (ViewString | null)) = (_0: number, _1: string): (ViewString | null) => {
    return null;
}, onNext:  ((a: Element) => void) = (it: Element): void => {}): SubscriptionLike {
    return this_.subscribe((it: Element): void => {
            onNext(it);
        }, (error: any): void => {
            if (error instanceof HttpResponseException){
                xResponseReadText((error as HttpResponseException).response).subscribe((it: string): void => {
                        const message = it;
                        
                        const knownResponse = knownErrorChecks(xResponseCodeGet((error as HttpResponseException).response), message);
                        
                        if (knownResponse !== null) {
                            showDialogAlert(knownResponse!);
                        } else {
                            console.log(`!!! ERROR FROM SERVER WITH CODE ${xResponseCodeGet((error as HttpResponseException).response)}: ${message}`);
                            switch(xResponseCodeGet((error as HttpResponseException).response)) {
                                case 400:
                                showDialogAlert(new ViewStringResource(R._string.error_bad_request))
                                break;
                                case 401:
                                showDialogAlert(new ViewStringResource(R._string.error_unauthorized))
                                break;
                                case 403:
                                showDialogAlert(new ViewStringResource(R._string.error_forbidden))
                                break;
                                case 404:
                                showDialogAlert(new ViewStringResource(R._string.error_not_found))
                                break;
                                case 500:
                                showDialogAlert(new ViewStringResource(R._string.error_server))
                                break;
                                case 502:
                                case 503:
                                case 504:
                                showDialogAlert(new ViewStringResource(R._string.error_no_server))
                                break;
                                default:
                                showDialogAlert(new ViewStringResource(R._string.error_unknown))
                                break;
                            }
                            
                        }
                    }, (it: any): void => {
                        showDialogAlert(new ViewStringRaw(error.message ?? ""));
                });
            } else if (error instanceof Exception){
                showDialogAlert(new ViewStringResource(R._string.error_local));
                printStackTrace(error);
            } else {
                showDialogAlert(new ViewStringResource(R._string.error_local));
                console.log(`!!! A special kind of error occurred: ${error}`);
            }
    }, undefined);
}

//! Declares com.tresitgroup.android.tresit.util.callWithError>io.reactivex.Single<kotlin.Any>
export function xSingleCallWithError<Element extends any>(this_: Observable<Element>, knownErrorChecks:  ((a: number, b: string) => (ViewString | null)) = (_0: number, _1: string): (ViewString | null) => {
    return null;
}, onSuccess:  ((a: Element) => void) = (it: Element): void => {}, onError:  (() => void) = (): void => {}): SubscriptionLike {
    return this_.subscribe((it: Element): void => {
            onSuccess(it);
        }, (error: any): void => {
            onError();
            if (error instanceof HttpResponseException){
                xResponseReadText((error as HttpResponseException).response).subscribe((it: string): void => {
                        const message = it;
                        
                        const knownResponse = knownErrorChecks(xResponseCodeGet((error as HttpResponseException).response), message);
                        
                        if (knownResponse !== null) {
                            showDialogAlert(knownResponse!);
                        } else {
                            console.log(`!!! ERROR FROM SERVER WITH CODE ${xResponseCodeGet((error as HttpResponseException).response)}: ${message}`);
                            switch(xResponseCodeGet((error as HttpResponseException).response)) {
                                case 400:
                                showDialogAlert(((): (ViewStringRaw | null) => {
                                    const temp3011 = xStringParseAsErrors(message);
                                    if(temp3011 === null) { return null }
                                    return ((errors: string): ViewStringRaw => new ViewStringRaw(errors))(temp3011)
                                })() ?? new ViewStringResource(R._string.error_bad_request))
                                break;
                                case 401:
                                showDialogAlert(new ViewStringResource(R._string.error_unauthorized))
                                break;
                                case 403:
                                showDialogAlert(new ViewStringResource(R._string.error_forbidden))
                                break;
                                case 404:
                                showDialogAlert(new ViewStringResource(R._string.error_not_found))
                                break;
                                case 500:
                                showDialogAlert(new ViewStringResource(R._string.error_server))
                                break;
                                case 502:
                                case 503:
                                case 504:
                                showDialogAlert(new ViewStringResource(R._string.error_no_server))
                                break;
                                default:
                                showDialogAlert(new ViewStringResource(R._string.error_unknown))
                                break;
                            }
                            
                        }
                    }, (it: any): void => {
                        showDialogAlert(new ViewStringRaw(error.message ?? ""));
                });
            } else if (error instanceof TimeoutError){
                showDialogAlert(new ViewStringResource(R._string.error_no_server));
            } else if (error instanceof Exception){
                if (error.message === "No offsite room found.") {
                    
                } else {
                    showDialogAlert(new ViewStringResource(R._string.error_local));
                }
                printStackTrace(error);
            } else {
                showDialogAlert(new ViewStringResource(R._string.error_local));
                console.log(`!!! A special kind of error occurred: ${error}`);
            }
    });
}