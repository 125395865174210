// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/GuidancesVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { Observable } from 'rxjs'
import { Image, ImageRemoteUrl } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { GuidancesXml } from '../../layout/GuidancesXml'
import { GuidanceDetailVG } from './GuidanceDetailVG'
import { logVG } from '../../util/LogVG'
import { xObservablePropertyPlusRx } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { debounceTime as rxDebounceTime } from 'rxjs/operators'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { AlertType } from '../../model/AlertType'
import { SessionApi } from '../../api/SessionApi'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ComponentRowAtWithSchoolXml } from '../../layout/ComponentRowAtWithSchoolXml'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { ForeignKey } from '../../model/ForeignKey'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { find as iterFind } from 'butterfly-web/dist/kotlin/lazyOp'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.settings.GuidancesVG
export class GuidancesVG extends ViewGenerator {
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.filter = new StandardObservableProperty<string>("", undefined);
        this.debouncedFilter = xObservablePropertyPlusRx<string>(this.filter, (it: Observable<string>): Observable<string> => it.pipe(rxDebounceTime(1000)));
    }
    
    
    
    public readonly filter: MutableObservableProperty<string>;
    
    public readonly debouncedFilter: ObservableProperty<string>;
    
    public alertTypesBySchool(): ObservableProperty<(Array<AlertType> | null)> {
        return xObservablePropertyFlatMap<Array<ApiFilter<AlertType>>, (Array<AlertType> | null)>(xObservablePropertyCombine<string, (School | null), Array<ApiFilter<AlertType>>>(this.debouncedFilter, this.schoolFilter, (query: string, school: (School | null)): Array<ApiFilter<AlertType>> => {
            const filters = ([] as Array<ApiFilter<AlertType>>);
            
            if (school !== null) {
                filters.push(AlertType.Companion.INSTANCE.schoolIn([school!.id]));
            }
            filters.push(AlertType.Companion.INSTANCE.textSearch(query));
            return filters;
        }), (filters: Array<ApiFilter<AlertType>>): ObservableProperty<(Array<AlertType> | null)> => this.session.alertTypes.observableListSimple(ApiFilter.Companion.INSTANCE.allList<AlertType>(filters), undefined, undefined, undefined, undefined, true));
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.GuidancesVG.title
    public get title(): string { return "Guidances"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new GuidancesXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const alertTypesBySchool = this.alertTypesBySchool();
        
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.filter);
        
        //--- Set Up xml.alertTypesLoading
        xViewFlipperBindLoading(xml.alertTypesLoading, xObservablePropertyMap<(Array<AlertType> | null), boolean>(alertTypesBySchool, (it: (Array<AlertType> | null)): boolean => it === null), undefined);
        
        //--- Set Up xml.alertTypes
        xRecyclerViewBind<AlertType>(xml.alertTypes, xObservablePropertyMap<(Array<AlertType> | null), Array<AlertType>>(alertTypesBySchool, (it: (Array<AlertType> | null)): Array<AlertType> => ((): (Array<AlertType> | null) => {
                        const temp5302 = it;
                        if(temp5302 === null) { return null }
                        return temp5302.slice().sort((a: AlertType, b: AlertType): number => {
                            const first = safeCompare(a.school, b.school);
                            
                            return ((): number => {
                                if (!(first === 0)) { return first } else { return safeCompare(a.name, b.name) }
                            })()
                        })
            })() ?? []), new AlertType(undefined, undefined, 0, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (alert_type: ObservableProperty<AlertType>): HTMLElement => {
                //--- Make Subview For xml.alertTypes (overwritten on flow generation)
                const cellXml = new ComponentRowAtWithSchoolXml();
                
                const cellView = cellXml.setup(dependency);
                
                
                //--- Set Up cellXml.root
                xViewOnClick(cellXml.root, undefined, (): void => {
                    this.cellXmlRootClick(alert_type.value.id);
                });
                
                //--- Set Up cellXml.icon
                xImageViewBindImage(cellXml.icon, xObservablePropertyMap<AlertType, (Image | null)>(alert_type, (it: AlertType): (Image | null) => ((): (ImageRemoteUrl | null) => {
                    if (it.icon !== null && !(it.icon === "")) {
                        return new ImageRemoteUrl(it.icon!);
                    } else {
                        return null;
                    }
                })()));
                
                //--- Set Up cellXml.name
                xTextViewBindString(cellXml.name, xObservablePropertyMap<AlertType, string>(alert_type, (it: AlertType): string => it.name));
                
                //--- Set Up cellXml.schoolName
                const school = xObservablePropertyFlatMap<AlertType, (School | null)>(alert_type, (it: AlertType): ObservableProperty<(School | null)> => xObservablePropertyMap<(Array<School> | null), (School | null)>(this.session.schools.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), (schools: (Array<School> | null)): (School | null) => ((): (School | null) => {
                    const temp5311 = schools;
                    if(temp5311 === null) { return null }
                    return iterFind(temp5311, (school: School): boolean => school.id === it.school)
                })()));
                
                xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<(School | null), string>(school, (it: (School | null)): string => (it?.name ?? null) ?? ""));
                xViewBindExists(cellXml.schoolName, xObservablePropertyMap<(Array<AlertType> | null), boolean>(alertTypesBySchool, (it: (Array<AlertType> | null)): boolean => !(((): (number | null) => {
                    const temp5316 = ((): (Array<number> | null) => {
                        const temp5317 = it;
                        if(temp5317 === null) { return null }
                        return temp5317.map((it: AlertType): number => it.school)
                    })();
                    if(temp5316 === null) { return null }
                    return temp5316.length
                })() === 1)));
                
                //--- End Make Subview For xml.alertTypes (overwritten on flow generation)
                return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cellXmlRootClick(alertTypeId: ForeignKey<AlertType>): void {
        this.stack.push(new GuidanceDetailVG(alertTypeId, this.session));
    }
    
    //--- Body End
}
