// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AlertHistoryDetailed.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { Comparator, safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { DateAlone } from 'butterfly-web/dist/time/DateAlone'
import { School } from './School'
import { PoliceStatus } from './PoliceStatus'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { RemoteFileRef } from './RemoteFileRef'
import { TimeAlone } from 'butterfly-web/dist/time/TimeAlone'
import { User } from './User'
import { dateFrom } from 'butterfly-web/dist/time/Date'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { AlertType } from './AlertType'
import { HasId, getNO_ID } from './HasId'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AlertHistoryDetailed
export class AlertHistoryDetailed implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly school: ForeignKey<School>;
    public readonly name: (ForeignKey<AlertType> | null);
    public readonly created: Date;
    public readonly cancelled: (Date | null);
    public readonly initiator: (ForeignKey<User> | null);
    public readonly canceller: (ForeignKey<User> | null);
    public readonly lastPoliceStatus: PoliceStatus;
    public readonly scoped: boolean;
    public readonly silent: boolean;
    public readonly internal: boolean;
    public readonly pendingApproval: boolean;
    public readonly requiresAdminApproval: boolean;
    public readonly cancelReason: string;
    public readonly alertTypeName: (string | null);
    public readonly alertTypeIcon: (RemoteFileRef | null);
    public readonly siteName: (string | null);
    public readonly initiatorFirstName: (string | null);
    public readonly cancellerFirstName: (string | null);
    public readonly initiatorLastName: (string | null);
    public readonly cancellerLastName: (string | null);
    public readonly childAlertHistoryCount: number;
    public readonly threadId: number;
    public readonly originalAlertId: number;
    public constructor(id: number = getNO_ID(), school: ForeignKey<School>, name: (ForeignKey<AlertType> | null) = null, created: Date, cancelled: (Date | null) = null, initiator: (ForeignKey<User> | null) = null, canceller: (ForeignKey<User> | null) = null, lastPoliceStatus: PoliceStatus = PoliceStatus.NotNotified, scoped: boolean = false, silent: boolean = false, internal: boolean = false, pendingApproval: boolean = false, requiresAdminApproval: boolean = false, cancelReason: string = "", alertTypeName: (string | null) = null, alertTypeIcon: (RemoteFileRef | null) = null, siteName: (string | null) = null, initiatorFirstName: (string | null) = null, cancellerFirstName: (string | null) = null, initiatorLastName: (string | null) = null, cancellerLastName: (string | null) = null, childAlertHistoryCount: number = 0, threadId: number = 0, originalAlertId: number = 0) {
        this.id = id;
        this.school = school;
        this.name = name;
        this.created = created;
        this.cancelled = cancelled;
        this.initiator = initiator;
        this.canceller = canceller;
        this.lastPoliceStatus = lastPoliceStatus;
        this.scoped = scoped;
        this.silent = silent;
        this.internal = internal;
        this.pendingApproval = pendingApproval;
        this.requiresAdminApproval = requiresAdminApproval;
        this.cancelReason = cancelReason;
        this.alertTypeName = alertTypeName;
        this.alertTypeIcon = alertTypeIcon;
        this.siteName = siteName;
        this.initiatorFirstName = initiatorFirstName;
        this.cancellerFirstName = cancellerFirstName;
        this.initiatorLastName = initiatorLastName;
        this.cancellerLastName = cancellerLastName;
        this.childAlertHistoryCount = childAlertHistoryCount;
        this.threadId = threadId;
        this.originalAlertId = originalAlertId;
    }
    public static fromJson(obj: any): AlertHistoryDetailed { return new AlertHistoryDetailed(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as number,
        parseJsonTyped(obj["name"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["cancelled"], [Date]) as (Date | null),
        parseJsonTyped(obj["initiator"], [Number]) as (number | null),
        parseJsonTyped(obj["canceller"], [Number]) as (number | null),
        parseJsonTyped(obj["last_police_status"], [PoliceStatus]) as PoliceStatus,
        parseJsonTyped(obj["scoped"], [Boolean]) as boolean,
        parseJsonTyped(obj["silent"], [Boolean]) as boolean,
        parseJsonTyped(obj["internal"], [Boolean]) as boolean,
        parseJsonTyped(obj["pending_approval"], [Boolean]) as boolean,
        parseJsonTyped(obj["requires_admin_approval"], [Boolean]) as boolean,
        parseJsonTyped(obj["cancel_reason"], [String]) as string,
        parseJsonTyped(obj["alert_type_name"], [String]) as (string | null),
        parseJsonTyped(obj["alert_type_icon"], [String]) as (string | null),
        parseJsonTyped(obj["site_name"], [String]) as (string | null),
        parseJsonTyped(obj["initiator_first_name"], [String]) as (string | null),
        parseJsonTyped(obj["canceller_first_name"], [String]) as (string | null),
        parseJsonTyped(obj["initiator_last_name"], [String]) as (string | null),
        parseJsonTyped(obj["canceller_last_name"], [String]) as (string | null),
        parseJsonTyped(obj["child_alert_history_count"], [Number]) as number,
        parseJsonTyped(obj["thread_id"], [Number]) as number,
        parseJsonTyped(obj["original_alert_id"], [Number]) as number
    ) }
    public toJSON(): object { return {
        id: this.id,
        school: this.school,
        name: this.name,
        created: this.created,
        cancelled: this.cancelled,
        initiator: this.initiator,
        canceller: this.canceller,
        last_police_status: this.lastPoliceStatus,
        scoped: this.scoped,
        silent: this.silent,
        internal: this.internal,
        pending_approval: this.pendingApproval,
        requires_admin_approval: this.requiresAdminApproval,
        cancel_reason: this.cancelReason,
        alert_type_name: this.alertTypeName,
        alert_type_icon: this.alertTypeIcon,
        site_name: this.siteName,
        initiator_first_name: this.initiatorFirstName,
        canceller_first_name: this.cancellerFirstName,
        initiator_last_name: this.initiatorLastName,
        canceller_last_name: this.cancellerLastName,
        child_alert_history_count: this.childAlertHistoryCount,
        thread_id: this.threadId,
        original_alert_id: this.originalAlertId
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.cancelled);
        hash = 31 * hash + hashAnything(this.initiator);
        hash = 31 * hash + hashAnything(this.canceller);
        hash = 31 * hash + hashAnything(this.lastPoliceStatus);
        hash = 31 * hash + hashAnything(this.scoped);
        hash = 31 * hash + hashAnything(this.silent);
        hash = 31 * hash + hashAnything(this.internal);
        hash = 31 * hash + hashAnything(this.pendingApproval);
        hash = 31 * hash + hashAnything(this.requiresAdminApproval);
        hash = 31 * hash + hashAnything(this.cancelReason);
        hash = 31 * hash + hashAnything(this.alertTypeName);
        hash = 31 * hash + hashAnything(this.alertTypeIcon);
        hash = 31 * hash + hashAnything(this.siteName);
        hash = 31 * hash + hashAnything(this.initiatorFirstName);
        hash = 31 * hash + hashAnything(this.cancellerFirstName);
        hash = 31 * hash + hashAnything(this.initiatorLastName);
        hash = 31 * hash + hashAnything(this.cancellerLastName);
        hash = 31 * hash + hashAnything(this.childAlertHistoryCount);
        hash = 31 * hash + hashAnything(this.threadId);
        hash = 31 * hash + hashAnything(this.originalAlertId);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertHistoryDetailed && safeEq(this.id, other.id) && safeEq(this.school, other.school) && safeEq(this.name, other.name) && safeEq(this.created, other.created) && safeEq(this.cancelled, other.cancelled) && safeEq(this.initiator, other.initiator) && safeEq(this.canceller, other.canceller) && safeEq(this.lastPoliceStatus, other.lastPoliceStatus) && safeEq(this.scoped, other.scoped) && safeEq(this.silent, other.silent) && safeEq(this.internal, other.internal) && safeEq(this.pendingApproval, other.pendingApproval) && safeEq(this.requiresAdminApproval, other.requiresAdminApproval) && safeEq(this.cancelReason, other.cancelReason) && safeEq(this.alertTypeName, other.alertTypeName) && safeEq(this.alertTypeIcon, other.alertTypeIcon) && safeEq(this.siteName, other.siteName) && safeEq(this.initiatorFirstName, other.initiatorFirstName) && safeEq(this.cancellerFirstName, other.cancellerFirstName) && safeEq(this.initiatorLastName, other.initiatorLastName) && safeEq(this.cancellerLastName, other.cancellerLastName) && safeEq(this.childAlertHistoryCount, other.childAlertHistoryCount) && safeEq(this.threadId, other.threadId) && safeEq(this.originalAlertId, other.originalAlertId) }
    public toString(): string { return `AlertHistoryDetailed(id=${this.id}, school=${this.school}, name=${this.name}, created=${this.created}, cancelled=${this.cancelled}, initiator=${this.initiator}, canceller=${this.canceller}, lastPoliceStatus=${this.lastPoliceStatus}, scoped=${this.scoped}, silent=${this.silent}, internal=${this.internal}, pendingApproval=${this.pendingApproval}, requiresAdminApproval=${this.requiresAdminApproval}, cancelReason=${this.cancelReason}, alertTypeName=${this.alertTypeName}, alertTypeIcon=${this.alertTypeIcon}, siteName=${this.siteName}, initiatorFirstName=${this.initiatorFirstName}, cancellerFirstName=${this.cancellerFirstName}, initiatorLastName=${this.initiatorLastName}, cancellerLastName=${this.cancellerLastName}, childAlertHistoryCount=${this.childAlertHistoryCount}, threadId=${this.threadId}, originalAlertId=${this.originalAlertId})` }
    public copy(id: number = this.id, school: ForeignKey<School> = this.school, name: (ForeignKey<AlertType> | null) = this.name, created: Date = this.created, cancelled: (Date | null) = this.cancelled, initiator: (ForeignKey<User> | null) = this.initiator, canceller: (ForeignKey<User> | null) = this.canceller, lastPoliceStatus: PoliceStatus = this.lastPoliceStatus, scoped: boolean = this.scoped, silent: boolean = this.silent, internal: boolean = this.internal, pendingApproval: boolean = this.pendingApproval, requiresAdminApproval: boolean = this.requiresAdminApproval, cancelReason: string = this.cancelReason, alertTypeName: (string | null) = this.alertTypeName, alertTypeIcon: (RemoteFileRef | null) = this.alertTypeIcon, siteName: (string | null) = this.siteName, initiatorFirstName: (string | null) = this.initiatorFirstName, cancellerFirstName: (string | null) = this.cancellerFirstName, initiatorLastName: (string | null) = this.initiatorLastName, cancellerLastName: (string | null) = this.cancellerLastName, childAlertHistoryCount: number = this.childAlertHistoryCount, threadId: number = this.threadId, originalAlertId: number = this.originalAlertId): AlertHistoryDetailed { return new AlertHistoryDetailed(id, school, name, created, cancelled, initiator, canceller, lastPoliceStatus, scoped, silent, internal, pendingApproval, requiresAdminApproval, cancelReason, alertTypeName, alertTypeIcon, siteName, initiatorFirstName, cancellerFirstName, initiatorLastName, cancellerLastName, childAlertHistoryCount, threadId, originalAlertId); }
    
    
}
export namespace AlertHistoryDetailed {
    //! Declares com.tresitgroup.android.tresit.model.AlertHistoryDetailed.Companion
    export class Companion {
        private constructor() {
            this.initiatorSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistoryDetailed>("sort=initiator", (db: MockDatabase): Comparator<AlertHistoryDetailed> => (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = (((): (User | null) => {
                    const temp2005 = o1.initiator;
                    if(temp2005 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2005)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp2008 = o2.initiator;
                    if(temp2008 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2008)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.initiatorDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistoryDetailed>("sort=-initiator", (db: MockDatabase): Comparator<AlertHistoryDetailed> => (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = (((): (User | null) => {
                    const temp2012 = o1.initiator;
                    if(temp2012 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2012)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp2015 = o2.initiator;
                    if(temp2015 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2015)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.createdSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=created", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => safeCompare(o1.created, o2.created));
            this.createdDownSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=-created", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => safeCompare(o2.created, o1.created));
            this.cancellerSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistoryDetailed>("sort=canceller", (db: MockDatabase): Comparator<AlertHistoryDetailed> => (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = (((): (User | null) => {
                    const temp2025 = o1.canceller;
                    if(temp2025 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2025)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp2028 = o2.canceller;
                    if(temp2028 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2028)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.cancellerDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistoryDetailed>("sort=-canceller", (db: MockDatabase): Comparator<AlertHistoryDetailed> => (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = (((): (User | null) => {
                    const temp2032 = o1.canceller;
                    if(temp2032 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2032)
                })()?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((): (User | null) => {
                    const temp2035 = o2.canceller;
                    if(temp2035 === null) { return null }
                    return ((it: number): (User | null) => (db.users.objects.get(it) ?? null))(temp2035)
                })()?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.alertSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=name", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = o1.alertTypeName
                if(name1 === null) { return 1 }
                const name2 = o2.alertTypeName
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.alertDownSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=-name", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = o1.alertTypeName
                if(name1 === null) { return 1 }
                const name2 = o2.alertTypeName
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.siteSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistoryDetailed>("sort=school", (db: MockDatabase): Comparator<AlertHistoryDetailed> => (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o1.school)?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o2.school)?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name1, name2);
            });
            this.siteDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<AlertHistoryDetailed>("sort=-school", (db: MockDatabase): Comparator<AlertHistoryDetailed> => (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const name1 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o1.school)?.name ?? null)
                if(name1 === null) { return 1 }
                const name2 = (((it: number): (School | null) => (db.schools.objects.get(it) ?? null))(o2.school)?.name ?? null)
                if(name2 === null) { return (-1) }
                return safeCompare(name2, name1);
            });
            this.cancelledSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=cancelled", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const prop1 = o1.cancelled
                if(prop1 === null) { return 1 }
                const prop2 = o2.cancelled
                if(prop2 === null) { return (-1) }
                return safeCompare(prop1, prop2);
            });
            this.cancelledDownSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=-cancelled", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => {
                const prop1 = o1.cancelled
                if(prop1 === null) { return 1 }
                const prop2 = o2.cancelled
                if(prop2 === null) { return (-1) }
                return safeCompare(prop2, prop1);
            });
            this.cancelReasonSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=cancel_reason", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => safeCompare(o1.cancelReason, o2.cancelReason));
            this.cancelReasonDownSort = ApiSort.constructorStringComparatorHasId<AlertHistoryDetailed>("sort=-cancel_reason", (o1: AlertHistoryDetailed, o2: AlertHistoryDetailed): number => safeCompare(o2.cancelReason, o1.cancelReason));
        }
        public static INSTANCE = new Companion();
        
        public schoolIn(schools: Array<ForeignKey<School>>): ApiFilter<AlertHistoryDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistoryDetailed>(`schools=${schools.join(",")}`, undefined, (it: AlertHistoryDetailed): boolean => schools.length === 0 || schools.some((x) => safeEq(it.school, x)));
        }
        
        
        //        fun notCancelledByMe(myId: ForeignKey<User>): ApiSort<AlertHistory> = ApiFilter(params="",filter = { it.canceller != myId })
        
        public textSearch(query: string): ApiFilter<AlertHistoryDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistoryDetailed>(`query=${query}`, (db: MockDatabase, hist: AlertHistoryDetailed): boolean => true, undefined);
        }
        
        
        public startDateFilter(date: DateAlone): ApiFilter<AlertHistoryDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistoryDetailed>(`created_gte=${dateFrom(date, TimeAlone.Companion.INSTANCE.min, undefined).toISOString()}`, undefined, (it: AlertHistoryDetailed): boolean => (it.created > dateFrom(date, TimeAlone.Companion.INSTANCE.midnight, undefined)));
        }
        
        
        public endDateFilter(date: DateAlone): ApiFilter<AlertHistoryDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistoryDetailed>(`cancelled_lte=${dateFrom(date, TimeAlone.Companion.INSTANCE.max, undefined).toISOString()}`, undefined, (it: AlertHistoryDetailed): boolean => ((): (boolean | null) => {
                const temp2068 = it.cancelled;
                if(temp2068 === null) { return null }
                return (temp2068 > dateFrom(date, TimeAlone.Companion.INSTANCE.midnight, undefined))
            })() ?? true);
        }
        
        public drillFilter(type: string): ApiFilter<AlertHistoryDetailed> {
            let isDrill = false;
            
            switch(type) {
                case "Alert":
                isDrill = false
                break;
                case "Drill":
                isDrill = true
                break;
                default:
                return new ApiFilter<AlertHistoryDetailed>("internal=")
            }
            
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<AlertHistoryDetailed>(`internal=${isDrill}`, undefined, (it: AlertHistoryDetailed): boolean => it.internal);
        }
        
        
        public readonly initiatorSort: ApiSort<AlertHistoryDetailed>;
        
        public readonly initiatorDownSort: ApiSort<AlertHistoryDetailed>;
        
        
        public readonly createdSort: ApiSort<AlertHistoryDetailed>;
        
        public readonly createdDownSort: ApiSort<AlertHistoryDetailed>;
        
        
        public readonly cancellerSort: ApiSort<AlertHistoryDetailed>;
        
        public readonly cancellerDownSort: ApiSort<AlertHistoryDetailed>;
        
        
        public readonly alertSort: ApiSort<AlertHistoryDetailed>;
        
        
        
        public readonly alertDownSort: ApiSort<AlertHistoryDetailed>;
        
        
        public readonly siteSort: ApiSort<AlertHistoryDetailed>;
        
        public readonly siteDownSort: ApiSort<AlertHistoryDetailed>;
        
        
        public readonly cancelledSort: ApiSort<AlertHistoryDetailed>;
        
        public readonly cancelledDownSort: ApiSort<AlertHistoryDetailed>;
        
        
        public readonly cancelReasonSort: ApiSort<AlertHistoryDetailed>;
        
        public readonly cancelReasonDownSort: ApiSort<AlertHistoryDetailed>;
        
        
    }
}


//! Declares com.tresitgroup.android.tresit.model.AlertHistoryChild
export class AlertHistoryChild {
    public readonly pendingApproval: boolean;
    public readonly requiresAdminApproval: boolean;
    public readonly cancelReason: string;
    public readonly alertTypeName: (string | null);
    public readonly siteName: (string | null);
    public readonly initiatorFirstName: (string | null);
    public readonly cancellerFirstName: (string | null);
    public readonly initiatorLastName: (string | null);
    public readonly cancellerLastName: (string | null);
    public readonly alertTypeIcon: (RemoteFileRef | null);
    public readonly created: (Date | null);
    public readonly cancelled: (Date | null);
    public constructor(pendingApproval: boolean = false, requiresAdminApproval: boolean = false, cancelReason: string = "", alertTypeName: (string | null) = null, siteName: (string | null) = null, initiatorFirstName: (string | null) = null, cancellerFirstName: (string | null) = null, initiatorLastName: (string | null) = null, cancellerLastName: (string | null) = null, alertTypeIcon: (RemoteFileRef | null) = null, created: (Date | null) = null, cancelled: (Date | null) = null) {
        this.pendingApproval = pendingApproval;
        this.requiresAdminApproval = requiresAdminApproval;
        this.cancelReason = cancelReason;
        this.alertTypeName = alertTypeName;
        this.siteName = siteName;
        this.initiatorFirstName = initiatorFirstName;
        this.cancellerFirstName = cancellerFirstName;
        this.initiatorLastName = initiatorLastName;
        this.cancellerLastName = cancellerLastName;
        this.alertTypeIcon = alertTypeIcon;
        this.created = created;
        this.cancelled = cancelled;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.pendingApproval);
        hash = 31 * hash + hashAnything(this.requiresAdminApproval);
        hash = 31 * hash + hashAnything(this.cancelReason);
        hash = 31 * hash + hashAnything(this.alertTypeName);
        hash = 31 * hash + hashAnything(this.siteName);
        hash = 31 * hash + hashAnything(this.initiatorFirstName);
        hash = 31 * hash + hashAnything(this.cancellerFirstName);
        hash = 31 * hash + hashAnything(this.initiatorLastName);
        hash = 31 * hash + hashAnything(this.cancellerLastName);
        hash = 31 * hash + hashAnything(this.alertTypeIcon);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.cancelled);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AlertHistoryChild && safeEq(this.pendingApproval, other.pendingApproval) && safeEq(this.requiresAdminApproval, other.requiresAdminApproval) && safeEq(this.cancelReason, other.cancelReason) && safeEq(this.alertTypeName, other.alertTypeName) && safeEq(this.siteName, other.siteName) && safeEq(this.initiatorFirstName, other.initiatorFirstName) && safeEq(this.cancellerFirstName, other.cancellerFirstName) && safeEq(this.initiatorLastName, other.initiatorLastName) && safeEq(this.cancellerLastName, other.cancellerLastName) && safeEq(this.alertTypeIcon, other.alertTypeIcon) && safeEq(this.created, other.created) && safeEq(this.cancelled, other.cancelled) }
    public toString(): string { return `AlertHistoryChild(pendingApproval=${this.pendingApproval}, requiresAdminApproval=${this.requiresAdminApproval}, cancelReason=${this.cancelReason}, alertTypeName=${this.alertTypeName}, siteName=${this.siteName}, initiatorFirstName=${this.initiatorFirstName}, cancellerFirstName=${this.cancellerFirstName}, initiatorLastName=${this.initiatorLastName}, cancellerLastName=${this.cancellerLastName}, alertTypeIcon=${this.alertTypeIcon}, created=${this.created}, cancelled=${this.cancelled})` }
    public copy(pendingApproval: boolean = this.pendingApproval, requiresAdminApproval: boolean = this.requiresAdminApproval, cancelReason: string = this.cancelReason, alertTypeName: (string | null) = this.alertTypeName, siteName: (string | null) = this.siteName, initiatorFirstName: (string | null) = this.initiatorFirstName, cancellerFirstName: (string | null) = this.cancellerFirstName, initiatorLastName: (string | null) = this.initiatorLastName, cancellerLastName: (string | null) = this.cancellerLastName, alertTypeIcon: (RemoteFileRef | null) = this.alertTypeIcon, created: (Date | null) = this.created, cancelled: (Date | null) = this.cancelled): AlertHistoryChild { return new AlertHistoryChild(pendingApproval, requiresAdminApproval, cancelReason, alertTypeName, siteName, initiatorFirstName, cancellerFirstName, initiatorLastName, cancellerLastName, alertTypeIcon, created, cancelled); }
}

