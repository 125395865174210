// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/String.extentions.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.
import { xIterableJoinToString } from 'butterfly-web/dist/kotlin/Iterables'
import { xStringFromJsonString } from 'butterfly-web/dist/Codable'

//! Declares com.tresitgroup.android.tresit.util.parseAsErrors>kotlin.String
export function xStringParseAsErrors(this_: string): (string | null) {
    const errorsMap = xStringFromJsonString<Map<string, string>>(this_, [Map, [String], [String]]);
    
    if (errorsMap !== null) {
        return `Error(s):\n${xIterableJoinToString<string>(errorsMap!.keys(), `\n`, undefined, undefined, undefined, undefined, (key: string): string => `${key}: ${(errorsMap!.get(key) ?? null) ?? ""}`)}`;
    }
    const errorsMapList = xStringFromJsonString<Map<string, Array<string>>>(this_, [Map, [String], [Array, [String]]]);
    
    if (errorsMapList !== null) {
        return `Error(s):\n${xIterableJoinToString<string>(errorsMapList!.keys(), `\n`, undefined, undefined, undefined, undefined, (key: string): string => `${key}: ${((): (string | null) => {
            const temp2974 = (errorsMapList!.get(key) ?? null);
            if(temp2974 === null) { return null }
            return temp2974.map((it: string): string => it).join(", ")
        })() ?? ""}`)}`;
    }
    const errorsList = xStringFromJsonString<Array<string>>(this_, [Array, [String]]);
    
    if (errorsList !== null) {
        return `Error(s):\n${errorsList!.join(`\n`)}`;
    }
    return null;
}

