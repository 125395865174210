// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AdminRequestHistory.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AdminRequestHistory
export class AdminRequestHistory implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly text: string;
    public readonly created: Date;
    public readonly alert: (string | null);
    public readonly action_performer: number;
    public readonly school: number;
    public constructor(id: number = getNO_ID(), text: string, created: Date, alert: (string | null) = null, action_performer: number, school: number) {
        this.id = id;
        this.text = text;
        this.created = created;
        this.alert = alert;
        this.action_performer = action_performer;
        this.school = school;
    }
    public static fromJson(obj: any): AdminRequestHistory { return new AdminRequestHistory(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["text"], [String]) as string,
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["alert"], [String]) as (string | null),
        parseJsonTyped(obj["action_performer"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as number
    ) }
    public toJSON(): object { return {
        id: this.id,
        text: this.text,
        created: this.created,
        alert: this.alert,
        action_performer: this.action_performer,
        school: this.school
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.text);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.alert);
        hash = 31 * hash + hashAnything(this.action_performer);
        hash = 31 * hash + hashAnything(this.school);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AdminRequestHistory && safeEq(this.id, other.id) && safeEq(this.text, other.text) && safeEq(this.created, other.created) && safeEq(this.alert, other.alert) && safeEq(this.action_performer, other.action_performer) && safeEq(this.school, other.school) }
    public toString(): string { return `AdminRequestHistory(id=${this.id}, text=${this.text}, created=${this.created}, alert=${this.alert}, action_performer=${this.action_performer}, school=${this.school})` }
    public copy(id: number = this.id, text: string = this.text, created: Date = this.created, alert: (string | null) = this.alert, action_performer: number = this.action_performer, school: number = this.school): AdminRequestHistory { return new AdminRequestHistory(id, text, created, alert, action_performer, school); }
}
