// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/UserDetailed.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { Comparator, safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { DeviceEnum } from './DeviceEnum'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { UserSchoolMembership } from './UserSchoolMembership'
import { Group } from './Group'
import { ApiModifier } from '../api/ApiModifier'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'
import { filter as iterFilter, map as iterMap, some as iterSome, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { EqualOverrideSet, listFilterNotNull, setAddCausedChange } from 'butterfly-web/dist/KotlinCollections'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { LoginActivity } from './LoginActivity'
import { HasId, getNO_ID } from './HasId'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { iterMaxBy } from 'butterfly-web/dist/kotlin/Collections'
import { Room } from './Room'
import { ApiFilter } from '../api/ApiFilter'

//! Declares com.tresitgroup.android.tresit.model.UserDetailed
export class UserDetailed implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly email: string;
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly isStaff: (boolean | null);
    public readonly currentLocation: (ForeignKey<Room> | null);
    public readonly cellPhone: (string | null);
    public readonly lastAnswerUpdate: (Date | null);
    public readonly permissions: Array<string>;
    public readonly groups: Array<ForeignKey<Group>>;
    public readonly password: (string | null);
    public readonly confirmPassword: (string | null);
    public readonly groupNames: (Array<(string | null)> | null);
    public readonly associatedSite: (string | null);
    public readonly associatedSitesCount: (number | null);
    public readonly lastActivity: (Date | null);
    public readonly fcmDevicesInfo: (Array<(string | null)> | null);
    public readonly isVip: (boolean | null);
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, email: string = "", firstName: string = "", lastName: string = "", isStaff: (boolean | null) = false, currentLocation: (ForeignKey<Room> | null) = null, cellPhone: (string | null) = null, lastAnswerUpdate: (Date | null) = null, permissions: Array<string> = [], groups: Array<ForeignKey<Group>> = [], password: (string | null) = null, confirmPassword: (string | null) = null, groupNames: (Array<(string | null)> | null) = null, associatedSite: (string | null) = null, associatedSitesCount: (number | null) = null, lastActivity: (Date | null) = null, fcmDevicesInfo: (Array<(string | null)> | null) = null, isVip: (boolean | null) = false) {
        this.id = id;
        this.deleted = deleted;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.isStaff = isStaff;
        this.currentLocation = currentLocation;
        this.cellPhone = cellPhone;
        this.lastAnswerUpdate = lastAnswerUpdate;
        this.permissions = permissions;
        this.groups = groups;
        this.password = password;
        this.confirmPassword = confirmPassword;
        this.groupNames = groupNames;
        this.associatedSite = associatedSite;
        this.associatedSitesCount = associatedSitesCount;
        this.lastActivity = lastActivity;
        this.fcmDevicesInfo = fcmDevicesInfo;
        this.isVip = isVip;
        this.isSuper = false;
    }
    public static fromJson(obj: any): UserDetailed { return new UserDetailed(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["email"], [String]) as string,
        parseJsonTyped(obj["first_name"], [String]) as string,
        parseJsonTyped(obj["last_name"], [String]) as string,
        parseJsonTyped(obj["staff"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["current_location"], [Number]) as (number | null),
        parseJsonTyped(obj["cell_phone"], [String]) as (string | null),
        parseJsonTyped(obj["last_answer_update"], [Date]) as (Date | null),
        parseJsonTyped(obj["permissions"], [Array, [String]]) as Array<string>,
        parseJsonTyped(obj["groups"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["password"], [String]) as (string | null),
        parseJsonTyped(obj["confirm_password"], [String]) as (string | null),
        parseJsonTyped(obj["group_names"], [Array, [String]]) as (Array<(string | null)> | null),
        parseJsonTyped(obj["associated_site"], [String]) as (string | null),
        parseJsonTyped(obj["associated_sites_count"], [Number]) as (number | null),
        parseJsonTyped(obj["last_activity"], [Date]) as (Date | null),
        parseJsonTyped(obj["fcm_devices_info"], [Array, [String]]) as (Array<(string | null)> | null),
        parseJsonTyped(obj["is_vip"], [Boolean]) as (boolean | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        staff: this.isStaff,
        current_location: this.currentLocation,
        cell_phone: this.cellPhone,
        last_answer_update: this.lastAnswerUpdate,
        permissions: this.permissions,
        groups: this.groups,
        password: this.password,
        confirm_password: this.confirmPassword,
        group_names: this.groupNames,
        associated_site: this.associatedSite,
        associated_sites_count: this.associatedSitesCount,
        last_activity: this.lastActivity,
        fcm_devices_info: this.fcmDevicesInfo,
        is_vip: this.isVip
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.email);
        hash = 31 * hash + hashAnything(this.firstName);
        hash = 31 * hash + hashAnything(this.lastName);
        hash = 31 * hash + hashAnything(this.isStaff);
        hash = 31 * hash + hashAnything(this.currentLocation);
        hash = 31 * hash + hashAnything(this.cellPhone);
        hash = 31 * hash + hashAnything(this.lastAnswerUpdate);
        hash = 31 * hash + hashAnything(this.permissions);
        hash = 31 * hash + hashAnything(this.groups);
        hash = 31 * hash + hashAnything(this.password);
        hash = 31 * hash + hashAnything(this.confirmPassword);
        hash = 31 * hash + hashAnything(this.groupNames);
        hash = 31 * hash + hashAnything(this.associatedSite);
        hash = 31 * hash + hashAnything(this.associatedSitesCount);
        hash = 31 * hash + hashAnything(this.lastActivity);
        hash = 31 * hash + hashAnything(this.fcmDevicesInfo);
        hash = 31 * hash + hashAnything(this.isVip);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof UserDetailed && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.email, other.email) && safeEq(this.firstName, other.firstName) && safeEq(this.lastName, other.lastName) && safeEq(this.isStaff, other.isStaff) && safeEq(this.currentLocation, other.currentLocation) && safeEq(this.cellPhone, other.cellPhone) && safeEq(this.lastAnswerUpdate, other.lastAnswerUpdate) && safeEq(this.permissions, other.permissions) && safeEq(this.groups, other.groups) && safeEq(this.password, other.password) && safeEq(this.confirmPassword, other.confirmPassword) && safeEq(this.groupNames, other.groupNames) && safeEq(this.associatedSite, other.associatedSite) && safeEq(this.associatedSitesCount, other.associatedSitesCount) && safeEq(this.lastActivity, other.lastActivity) && safeEq(this.fcmDevicesInfo, other.fcmDevicesInfo) && safeEq(this.isVip, other.isVip) }
    public toString(): string { return `UserDetailed(id=${this.id}, deleted=${this.deleted}, email=${this.email}, firstName=${this.firstName}, lastName=${this.lastName}, isStaff=${this.isStaff}, currentLocation=${this.currentLocation}, cellPhone=${this.cellPhone}, lastAnswerUpdate=${this.lastAnswerUpdate}, permissions=${this.permissions}, groups=${this.groups}, password=${this.password}, confirmPassword=${this.confirmPassword}, groupNames=${this.groupNames}, associatedSite=${this.associatedSite}, associatedSitesCount=${this.associatedSitesCount}, lastActivity=${this.lastActivity}, fcmDevicesInfo=${this.fcmDevicesInfo}, isVip=${this.isVip})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, email: string = this.email, firstName: string = this.firstName, lastName: string = this.lastName, isStaff: (boolean | null) = this.isStaff, currentLocation: (ForeignKey<Room> | null) = this.currentLocation, cellPhone: (string | null) = this.cellPhone, lastAnswerUpdate: (Date | null) = this.lastAnswerUpdate, permissions: Array<string> = this.permissions, groups: Array<ForeignKey<Group>> = this.groups, password: (string | null) = this.password, confirmPassword: (string | null) = this.confirmPassword, groupNames: (Array<(string | null)> | null) = this.groupNames, associatedSite: (string | null) = this.associatedSite, associatedSitesCount: (number | null) = this.associatedSitesCount, lastActivity: (Date | null) = this.lastActivity, fcmDevicesInfo: (Array<(string | null)> | null) = this.fcmDevicesInfo, isVip: (boolean | null) = this.isVip): UserDetailed { return new UserDetailed(id, deleted, email, firstName, lastName, isStaff, currentLocation, cellPhone, lastAnswerUpdate, permissions, groups, password, confirmPassword, groupNames, associatedSite, associatedSitesCount, lastActivity, fcmDevicesInfo, isVip); }
    
    
    public readonly isSuper: boolean;
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.name
    public get name(): string { return `${this.firstName} ${this.lastName}`; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.localUser
    public get localUser(): boolean { return this.permissions.some((x) => safeEq("accounts.has_default_locations", x)); }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCleared
    public get permissionCleared(): boolean { return this.permissions.some((x) => safeEq("schools.can_set_cleared", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanUpdatePoliceStatus
    public get permissionCanUpdatePoliceStatus(): boolean { return this.permissions.some((x) => safeEq("schools.can_update_status", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanUpdateAlert
    public get permissionCanUpdateAlert(): boolean { return this.permissions.some((x) => safeEq("schools.change_alert", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanEscalateAlert
    public get permissionCanEscalateAlert(): boolean { return (this.permissions.some((x) => safeEq("schools.can_escalate_alert", x)) && this.permissions.some((x) => safeEq("schools.change_alert", x))) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanSeePending
    public get permissionCanSeePending(): boolean { return (this.permissions.some((x) => safeEq("schools.can_see_pending_approval", x))) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanApproveAlert
    public get permissionCanApproveAlert(): boolean { return (this.permissions.some((x) => safeEq("schools.can_recieve_approval", x))) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanStartInternal
    public get permissionCanStartInternal(): boolean { return this.permissions.some((x) => safeEq("schools.can_start_internal", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionAdmin
    public get permissionAdmin(): boolean { return this.permissions.some((x) => safeEq("schools.change_alerttype", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCreateConversation
    public get permissionCreateConversation(): boolean { return this.permissions.some((x) => safeEq("chat.add_thread", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCreateMultiConversation
    public get permissionCreateMultiConversation(): boolean { return this.permissions.some((x) => safeEq("chat.multi_threads", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionEditConversation
    public get permissionEditConversation(): boolean { return this.permissions.some((x) => safeEq("chat.change_thread", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionDeleteThread
    public get permissionDeleteThread(): boolean { return this.permissions.some((x) => safeEq("chat.delete_thread", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionEditUser
    public get permissionEditUser(): boolean { return this.permissions.some((x) => safeEq("accounts.change_baseuser", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanChat
    public get permissionCanChat(): boolean { return this.permissions.some((x) => safeEq("chat.add_message", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.permissionCanTopLevelPost
    public get permissionCanTopLevelPost(): boolean { return this.permissions.some((x) => safeEq("chat.top_level_post", x)) || this.isSuper; }
    
    
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.fcmDeviceTypes
    public get fcmDeviceTypes(): Set<DeviceEnum> {
        const types = new EqualOverrideSet<DeviceEnum>([]);
        
        const temp2442 = this.fcmDevicesInfo;
        if(temp2442 !== null) {
            for(const _x of temp2442) {
                const it = _x;
                if (it !== null) {
                    const info = it!.toLowerCase();
                    
                    const parts = info.split("|");
                    
                    if ((info.indexOf("android") != -1)) { setAddCausedChange(types, DeviceEnum.ANDROID) }
                    if ((info.indexOf("ios") != -1)) { setAddCausedChange(types, DeviceEnum.IOS) }
                    if ((info.indexOf("windows") != -1)) { setAddCausedChange(types, DeviceEnum.WINDOWS) }
                    if ((info.indexOf("mac") != -1)) { setAddCausedChange(types, DeviceEnum.MAC) }
                    if ((parts[0].indexOf("web") != -1) && !xCharSequenceIsBlank(parts[1]) && !((parts[1].indexOf("windows") != -1)) && !((parts[1].indexOf("mac") != -1))) { setAddCausedChange(types, DeviceEnum.OTHER) }
                }
            }
        };
        return types;
    }
    
    
    
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace UserDetailed {
    //! Declares com.tresitgroup.android.tresit.model.UserDetailed.Companion
    export class Companion {
        private constructor() {
            this.removeLocation = ApiModifier.constructorStringAnyFunction1HasIdHasId<UserDetailed>("current_location", null, (it: UserDetailed): UserDetailed => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, null, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
            this.nameSort = ApiSort.constructorStringComparatorHasId<UserDetailed>("ordering=name", (o1: UserDetailed, o2: UserDetailed): number => safeCompare(o1.name, o2.name));
            this.nameDownSort = ApiSort.constructorStringComparatorHasId<UserDetailed>("ordering=-name", (o1: UserDetailed, o2: UserDetailed): number => safeCompare(o2.name, o1.name));
            this.siteSort = ApiSort.constructorStringFunction1MockDatabaseComparator<UserDetailed>("ordering=site", (db: MockDatabase): Comparator<UserDetailed> => (o1: UserDetailed, o2: UserDetailed): number => {
                const siteListO1 = iterToArray(iterFilter(db.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === o1.id)).map((membership: UserSchoolMembership): (School | null) => (iterToArray(iterFilter(db.schools.objects.values(), (it: School): boolean => it.id === membership.school))[0] ?? null));
                
                const siteListO2 = iterToArray(iterFilter(db.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === o2.id)).map((membership: UserSchoolMembership): (School | null) => (iterToArray(iterFilter(db.schools.objects.values(), (it: School): boolean => it.id === membership.school))[0] ?? null));
                
                
                const siteNameO1 = ((siteListO1.length == 1 ? siteListO1[0] : null)?.name ?? null);
                
                const siteNameO2 = ((siteListO2.length == 1 ? siteListO2[0] : null)?.name ?? null);
                
                
                return ((): number => {
                    if (siteNameO1 !== null && siteNameO2 !== null) {
                        return safeCompare(siteNameO1!, siteNameO2!);
                    } else {
                        return safeCompare(siteListO1.length, siteListO2.length);
                    }
                })()
            });
            this.siteDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<UserDetailed>("ordering=-site", (db: MockDatabase): Comparator<UserDetailed> => (o1: UserDetailed, o2: UserDetailed): number => {
                const siteListO1 = iterToArray(iterFilter(db.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === o1.id)).map((membership: UserSchoolMembership): (School | null) => (iterToArray(iterFilter(db.schools.objects.values(), (it: School): boolean => it.id === membership.school))[0] ?? null));
                
                const siteListO2 = iterToArray(iterFilter(db.userSchoolMemberships.objects.values(), (it: UserSchoolMembership): boolean => it.user === o2.id)).map((membership: UserSchoolMembership): (School | null) => (iterToArray(iterFilter(db.schools.objects.values(), (it: School): boolean => it.id === membership.school))[0] ?? null));
                
                
                const siteNameO1 = ((siteListO1.length == 1 ? siteListO1[0] : null)?.name ?? null);
                
                const siteNameO2 = ((siteListO2.length == 1 ? siteListO2[0] : null)?.name ?? null);
                
                
                return ((): number => {
                    if (siteNameO1 !== null && siteNameO2 !== null) {
                        return safeCompare(siteNameO2!, siteNameO1!);
                    } else {
                        return safeCompare(siteListO2.length, siteListO1.length);
                    }
                })()
            });
            this.latestActivitySort = ApiSort.constructorStringFunction1MockDatabaseComparator<UserDetailed>("ordering=activity", (db: MockDatabase): Comparator<UserDetailed> => (a: UserDetailed, b: UserDetailed): number => {
                const latestActivityA = iterMaxBy(iterMap(iterFilter(db.loginActivity.objects.values(), (it: LoginActivity): boolean => it.user === a.id), (it: LoginActivity): Date => it.createdAt), x => x);
                
                const latestActivityB = iterMaxBy(iterMap(iterFilter(db.loginActivity.objects.values(), (it: LoginActivity): boolean => it.user === b.id), (it: LoginActivity): Date => it.createdAt), x => x);
                
                if (latestActivityA === null) { return 1 }
                if (latestActivityB === null) { return (-1) }
                return safeCompare(latestActivityA!, latestActivityB!!);
            });
            this.latestActivityDownSort = ApiSort.constructorStringFunction1MockDatabaseComparator<UserDetailed>("ordering=-activity", (db: MockDatabase): Comparator<UserDetailed> => (a: UserDetailed, b: UserDetailed): number => {
                const latestActivityA = iterMaxBy(iterMap(iterFilter(db.loginActivity.objects.values(), (it: LoginActivity): boolean => it.user === a.id), (it: LoginActivity): Date => it.createdAt), x => x);
                
                const latestActivityB = iterMaxBy(iterMap(iterFilter(db.loginActivity.objects.values(), (it: LoginActivity): boolean => it.user === b.id), (it: LoginActivity): Date => it.createdAt), x => x);
                
                if (latestActivityA === null) { return 1 }
                if (latestActivityB === null) { return (-1) }
                return (-safeCompare(latestActivityA!, latestActivityB!!));
            });
        }
        public static INSTANCE = new Companion();
        
        public textSearch(query: string): ApiFilter<UserDetailed> {
            const parts = query.split(' ');
            
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserDetailed>(`query=${query.replaceAll(" ", "%20")}`, undefined, (it: UserDetailed): boolean => parts.every((part: string): boolean => (it.firstName.toLowerCase().indexOf(part.toLowerCase()) != -1) ||
                (it.lastName.toLowerCase().indexOf(part.toLowerCase()) != -1) ||
                (it.email.toLowerCase().indexOf(part.toLowerCase()) != -1) ||
                ((): (boolean | null) => {
                    const temp2577 = ((): (Array<string> | null) => {
                        const temp2579 = it.groupNames;
                        if(temp2579 === null) { return null }
                        return listFilterNotNull(temp2579)
                    })();
                    if(temp2577 === null) { return null }
                    return temp2577.some((it: string): boolean => (it.indexOf(part.toLowerCase()) != -1))
                })() === true ||
                ((): (boolean | null) => {
                    const temp2582 = ((): (string | null) => {
                        const temp2584 = it.associatedSite;
                        if(temp2584 === null) { return null }
                        return temp2584.toLowerCase()
                    })();
                    if(temp2582 === null) { return null }
                    return (temp2582.indexOf(part.toLowerCase()) != -1)
            })() === true));
        }
        
        
        public belongingToSchool(school: ForeignKey<School>): ApiFilter<UserDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserDetailed>(`schools=${school}`, (db: MockDatabase, it: UserDetailed): boolean => iterSome(db.userSchoolMemberships.objects.values(), (m: UserSchoolMembership): boolean => m.user === it.id && m.school === school), undefined);
        }
        
        public belongingToAnySchool(schools: Array<ForeignKey<School>>): ApiFilter<UserDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserDetailed>(`schools=${schools.join(",")}`, (db: MockDatabase, it: UserDetailed): boolean => iterSome(db.userSchoolMemberships.objects.values(), (m: UserSchoolMembership): boolean => m.user === it.id && (schools.length === 0 || schools.some((x) => safeEq(m.school, x)))), undefined);
        }
        
        public deletedAfter(date: Date): ApiFilter<UserDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserDetailed>(`deleted_after=${date.toISOString()}`, undefined, (it: UserDetailed): boolean => ((): (boolean | null) => {
                const temp2598 = it.deleted;
                if(temp2598 === null) { return null }
                return (temp2598 > date)
            })() ?? true);
        }
        
        public setLocation(room: ForeignKey<Room>): ApiModifier<UserDetailed> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<UserDetailed>("current_location", room, (it: UserDetailed): UserDetailed => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, room, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public readonly removeLocation: ApiModifier<UserDetailed>;
        
        
        public inLocation(roomId: ForeignKey<Room>): ApiFilter<UserDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserDetailed>(`current_location=${roomId}`, undefined, (user: UserDetailed): boolean => user.currentLocation === roomId);
        }
        
        public notResponders(): ApiFilter<UserDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<UserDetailed>("without_permission=schools.can_set_cleared", undefined, (it: UserDetailed): boolean => (!it.permissionCleared));
        }
        
        public readonly nameSort: ApiSort<UserDetailed>;
        
        public readonly nameDownSort: ApiSort<UserDetailed>;
        
        
        public readonly siteSort: ApiSort<UserDetailed>;
        
        
        public readonly siteDownSort: ApiSort<UserDetailed>;
        
        
        
        public readonly latestActivitySort: ApiSort<UserDetailed>;
        
        public readonly latestActivityDownSort: ApiSort<UserDetailed>;
        
    }
}


