// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/deadBuildCheck.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { BuildConfig } from '../BuildConfig'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { ApiOption } from './ApiOption'
import { Platform } from 'butterfly-web/dist/Platform'
import { SubscriptionLike } from 'rxjs'
import { onDeadBuild } from '../onDeadBuild'

//! Declares com.tresitgroup.android.tresit.api.deadBuildCheck
export function deadBuildCheck(): void {
    xDisposableForever<SubscriptionLike>(ApiOption.Companion.INSTANCE.current.checkIsOkBuild(Platform.Companion.INSTANCE.current.name.toLowerCase() + "-" + BuildConfig.VERSION_NAME).subscribe((it: boolean): void => {
        if ((!it)) {
            onDeadBuild();
        }
    }, (it: any): void => {}));
}