// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiSort.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { Comparator, safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { HasId } from '../model/HasId'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { MockDatabase } from './MockDatabase'

//! Declares com.tresitgroup.android.tresit.api.ApiSort
export class ApiSort<T extends HasId> {
    public readonly params: string;
    public constructor(params: string) {
        this.params = params;
        this.comparator = null;
        this.mockComparator = null;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.params);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ApiSort && safeEq(this.params, other.params) }
    public toString(): string { return `ApiSort(params=${this.params})` }
    public copy(params: string = this.params): ApiSort<T> { return new ApiSort(params); }
    
    public comparator: (Comparator<T> | null);
    
    public mockComparator: (((a: MockDatabase) => Comparator<T>) | null);
    
    
    public static constructorStringComparatorHasId<T extends HasId>(params: string, sortBy:  Comparator<T>) {
        let result = new ApiSort<T>(params);
        
        result.comparator = sortBy
        
        return result;
    }
    
    public static constructorStringFunction1MockDatabaseComparator<T extends HasId>(params: string, sortByMock:  ((a: MockDatabase) => Comparator<T>)) {
        let result = new ApiSort<T>(params);
        
        result.mockComparator = sortByMock
        
        return result;
    }
    
    
}
export namespace ApiSort {
    //! Declares com.tresitgroup.android.tresit.api.ApiSort.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public byId<T extends HasId>(): ApiSort<T> {
            return ApiSort.constructorStringComparatorHasId<T>("", (a: T, b: T): number => safeCompare(a.id, b.id));
        }
    }
}