// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/RegisteredDevice.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.RegisteredDevice
export class RegisteredDevice implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly hasMac: (boolean | null);
    public readonly hasWindows: (boolean | null);
    public readonly hasAndroid: (boolean | null);
    public readonly hasIos: (boolean | null);
    public readonly others: (boolean | null);
    public constructor(id: number = getNO_ID(), hasMac: (boolean | null) = false, hasWindows: (boolean | null) = false, hasAndroid: (boolean | null) = false, hasIos: (boolean | null) = false, others: (boolean | null) = false) {
        this.id = id;
        this.hasMac = hasMac;
        this.hasWindows = hasWindows;
        this.hasAndroid = hasAndroid;
        this.hasIos = hasIos;
        this.others = others;
    }
    public static fromJson(obj: any): RegisteredDevice { return new RegisteredDevice(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["has_mac"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["has_windows"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["has_android"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["has_ios"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["has_other"], [Boolean]) as (boolean | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        has_mac: this.hasMac,
        has_windows: this.hasWindows,
        has_android: this.hasAndroid,
        has_ios: this.hasIos,
        has_other: this.others
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.hasMac);
        hash = 31 * hash + hashAnything(this.hasWindows);
        hash = 31 * hash + hashAnything(this.hasAndroid);
        hash = 31 * hash + hashAnything(this.hasIos);
        hash = 31 * hash + hashAnything(this.others);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof RegisteredDevice && safeEq(this.id, other.id) && safeEq(this.hasMac, other.hasMac) && safeEq(this.hasWindows, other.hasWindows) && safeEq(this.hasAndroid, other.hasAndroid) && safeEq(this.hasIos, other.hasIos) && safeEq(this.others, other.others) }
    public toString(): string { return `RegisteredDevice(id=${this.id}, hasMac=${this.hasMac}, hasWindows=${this.hasWindows}, hasAndroid=${this.hasAndroid}, hasIos=${this.hasIos}, others=${this.others})` }
    public copy(id: number = this.id, hasMac: (boolean | null) = this.hasMac, hasWindows: (boolean | null) = this.hasWindows, hasAndroid: (boolean | null) = this.hasAndroid, hasIos: (boolean | null) = this.hasIos, others: (boolean | null) = this.others): RegisteredDevice { return new RegisteredDevice(id, hasMac, hasWindows, hasAndroid, hasIos, others); }
}