// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/GoogleMapEditVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { flatMap as rxFlatMap, map as rxMap, tap as rxTap } from 'rxjs/operators'
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { Observable, ObservableInput, SubscriptionLike, of as rxOf } from 'rxjs'
import { takeUnless } from 'butterfly-web/dist/Kotlin'
import { xActivityAccessDownloadFileData, xActivityAccessRequestFile } from 'butterfly-web/dist/views/ViewDependency'
import { GoogleMapEditXml } from '../../layout/GoogleMapEditXml'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xResponseReadText, xSingleReadJsonDebug } from 'butterfly-web/dist/net/RxHttpAssist'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { GoogleMapVG } from './GoogleMapVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xXmlNodeToSvgNode } from '../../svg/SchoolSvgRender'
import { R } from '../../R'
import { xCampusGeoShapeGenerateSketchSvg, xGeoCoordinateBoundsGenerateEmptySketchSvg } from '../../map/maker/svgExport'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { parseXml } from '../../svg/XmlParser'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ForeignKey } from '../../model/ForeignKey'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { printStackTrace, runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'
import { xUriReadData } from 'butterfly-web/dist/files'
import { generateCampus } from '../../map/maker/svgImport'
import { School } from '../../model/School'
import { CampusGeoShape } from '../../map/Models'

//! Declares com.tresitgroup.android.tresit.vg.map.GoogleMapEditVG
export class GoogleMapEditVG extends ViewGenerator {
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>, schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.stack = stack;
        this.dialog = dialog;
        this.schoolId = schoolId;
        this.session = session;
        this.map = new GoogleMapVG(this.stack, this.dialog, "", (it: number): void => {}, (it: number): void => {}, this.schoolId, this.session);
        this.map.useConstrainMap = false;
        this.map.editMode = true;
        this.editMapStack = new ObservableStack<ViewGenerator>();
        this.saving = new StandardObservableProperty<boolean>(false, undefined);
        this.editMapStack.reset(this.map);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<any>(this.session.schools.get(this.schoolId).pipe(rxFlatMap((it: School): ObservableInput<any> => ((): (Observable<CampusGeoShape> | null) => {
            const temp4592 = ((): (string | null) => {
                const temp4594 = it.jsonMap;
                if(temp4594 === null) { return null }
                return takeUnless(temp4594, (it: string): boolean => it === "")
            })();
            if(temp4592 === null) { return null }
            return ((mapUrl: string): Observable<CampusGeoShape> => xSingleReadJsonDebug<CampusGeoShape>(HttpClient.INSTANCE.call(mapUrl, undefined, undefined, undefined, undefined), [CampusGeoShape]).pipe(rxTap((it: CampusGeoShape): void => {
                            console.debug(`${"GoogleMapSVG"}: ${`Got ${it}`}`);
                            this.map.geometry.value = it.clean();
                })).pipe(rxTap(undefined, (it: any): void => {
                    if (it instanceof HttpResponseException) {
                        xResponseReadText((it as HttpResponseException).response).subscribe((it: string): void => {
                            console.debug(`${"GoogleMapSVG"}: ${`Got error ${it}`}`);
                        }, (it: any): void => {});
                    }
            })).pipe(rxMap((it: CampusGeoShape): CampusGeoShape => it.clean())))(temp4592)
        })() ?? rxOf(undefined))), undefined, (it: any): void => {}));
    }
    
    
    public readonly map: GoogleMapVG;
    
    
    public readonly editMapStack: ObservableStack<ViewGenerator>;
    
    public readonly saving: StandardObservableProperty<boolean>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.map.GoogleMapEditVG.title
    public get title(): string { return "Google Map Edit"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new GoogleMapEditXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.exportSvgWorking (overwritten on flow generation)
        
        //--- Set Up xml.exportSvg
        xViewOnClick(xml.exportSvg, undefined, (): void => {
            this.exportSvgClick((it: string): void => {
                xActivityAccessDownloadFileData(dependency, new Int8Array(new TextEncoder().encode(it)), `school-${this.schoolId}.svg`, "image/svg"!!);
            });
        });
        
        //--- Set Up xml.importSvgWorking (overwritten on flow generation)
        
        //--- Set Up xml.importSvg
        xViewOnClick(xml.importSvg, undefined, (): void => {
            xActivityAccessRequestFile(dependency, (it: File): void => {
                //uhhh read the file?
                xUriReadData(it).subscribe((it: Int8Array): void => {
                        this.importSvgClick(new TextDecoder("utf-8").decode(it));
                    }, (it: any): void => {
                        printStackTrace(it);
                        showDialogAlert(new ViewStringResource(R._string.error_local));
                });
            });
        });
        
        //--- Set Up xml.saveWorking
        xViewFlipperBindLoading(xml.saveWorking, this.saving, undefined);
        
        //--- Set Up xml.save (overwritten on flow generation)
        xViewOnClick(xml.save, undefined, (): void => {
            this.saveClick();
        });
        
        //--- Set Up xml.editMapStackView (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.editMapStackView, dependency, this.editMapStack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public exportSvgClick(onGenerated: ((a: string) => void)): void {
        const it_4615 = this.map.geometry.value;
        if (it_4615 !== null) {
            const svg = xCampusGeoShapeGenerateSketchSvg(it_4615);
            
            onGenerated(svg);
        } else {
            const bound_4616 = this.map.currentViewBounds.value;
            if (bound_4616 !== null) {
                const svg = xGeoCoordinateBoundsGenerateEmptySketchSvg(bound_4616);
                
                onGenerated(svg);
            }
        }
    }
    
    public importSvgClick(svgText: string): void {
        try {
            this.map.geometry.value = generateCampus(xXmlNodeToSvgNode(parseXml(svgText))).clean();
        } catch (_e) { let e = _e as any; printStackTrace(e)}
    }
    
    public saveClick(): void {
        const it_4617 = this.map.geometry.value;
        if (it_4617 !== null) {
            xSingleCallDisplayingError<void>(xSingleWorking<void>(this.session.rawApi.updateGoogleMap(this.session.session, this.schoolId, it_4617), this.saving), undefined, (it: void): void => {
                showDialogAlert(new ViewStringResource(R._string.success));
            });
        }
    }
    
    //--- Body End
}

