//
// LandingScreenXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FancyBackgroundDelegate } from '../vg/FancyBackgroundDelegate'
import htmlForDefault from './landing_screen.html'
//! Declares com.tresitgroup.android.tresit.layouts.LandingScreenXml
export class LandingScreenXml {
    xmlRoot!: HTMLElement
    background!: HTMLCanvasElement
    email!: HTMLInputElement
    firstName!: HTMLInputElement
    lastName!: HTMLInputElement
    cellPhone!: HTMLInputElement
    password!: HTMLInputElement
    submitWorking!: HTMLDivElement
    submit!: HTMLButtonElement
    demo!: HTMLButtonElement
    forgotWorking!: HTMLDivElement
    forgotPassword!: HTMLButtonElement
    cleverWorking!: HTMLDivElement
    clever!: HTMLButtonElement
    manualLogin!: HTMLButtonElement
    secret!: HTMLElement
    server!: HTMLSelectElement
    backgroundDelegate!: FancyBackgroundDelegate
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.background = getViewById<HTMLCanvasElement>(view, "background")
        this.email = getViewById<HTMLInputElement>(view, "email")
        this.firstName = getViewById<HTMLInputElement>(view, "firstName")
        this.lastName = getViewById<HTMLInputElement>(view, "lastName")
        this.cellPhone = getViewById<HTMLInputElement>(view, "cellPhone")
        this.password = getViewById<HTMLInputElement>(view, "password")
        this.submitWorking = getViewById<HTMLDivElement>(view, "submitWorking")
        this.submit = getViewById<HTMLButtonElement>(view, "submit")
        this.demo = getViewById<HTMLButtonElement>(view, "demo")
        this.forgotWorking = getViewById<HTMLDivElement>(view, "forgotWorking")
        this.forgotPassword = getViewById<HTMLButtonElement>(view, "forgotPassword")
        this.cleverWorking = getViewById<HTMLDivElement>(view, "cleverWorking")
        this.clever = getViewById<HTMLButtonElement>(view, "clever")
        this.manualLogin = getViewById<HTMLButtonElement>(view, "manualLogin")
        this.secret = getViewById<HTMLElement>(view, "secret")
        this.server = getViewById<HTMLSelectElement>(view, "server")
        if(this.background){ this.backgroundDelegate = new FancyBackgroundDelegate(); customViewSetDelegate(this.background, this.backgroundDelegate); }
        return view
    }
}
