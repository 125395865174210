//
// SessionXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/session.html'
import htmlForw960dp from '../layout-w960dp/session.html'
import htmlForDefault from './session.html'
//! Declares com.tresitgroup.android.tresit.layouts.SessionXml
export class SessionXml {
    xmlRoot!: HTMLElement
    topBar!: HTMLDivElement
    back!: HTMLButtonElement
    viewTitle!: HTMLDivElement
    header!: HTMLElement
    canChangeSchoolFilter: HTMLImageElement | null = null
    subheader!: HTMLElement
    connectivityIndicator!: HTMLImageElement
    connectedIndicator: HTMLImageElement | null = null
    mute!: HTMLDivElement
    muteImage!: HTMLImageElement
    muteLabel!: HTMLElement
    statusWorking!: HTMLDivElement
    statusLayout!: HTMLDivElement
    statusButton!: HTMLImageElement
    statusText!: HTMLElement
    backButtonMirror!: HTMLElement
    content!: HTMLDivElement
    bottomBar!: HTMLDivElement
    alertTab!: HTMLInputElement
    boardTab!: HTMLInputElement
    messagesTab!: HTMLInputElement
    unreadIndicator!: HTMLElement
    mapTab!: HTMLInputElement
    settingsTab!: HTMLInputElement
    logo: HTMLImageElement | null = null
    schoolSelectorSpace: HTMLElement | null = null
    home: HTMLDivElement | null = null
    homeImage: HTMLImageElement | null = null
    homeLabel: HTMLElement | null = null
    history: HTMLDivElement | null = null
    historyImage: HTMLImageElement | null = null
    historyLabel: HTMLElement | null = null
    statusTextSafe: HTMLElement | null = null
    horizontalDivider: HTMLElement | null = null
    collapseLayout: HTMLDivElement | null = null
    filterBySchoolLayout: HTMLDivElement | null = null
    filter: HTMLInputElement | null = null
    schools: HTMLDivElement | null = null
    collapseButton: HTMLButtonElement | null = null
    collapseLabel: HTMLImageElement | null = null
    verticalDivider2: HTMLElement | null = null
    verticalDivider: HTMLElement | null = null
    landscapeIndicator: HTMLElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960, 700])
        this.xmlRoot = view
        this.topBar = getViewById<HTMLDivElement>(view, "topBar")
        this.back = getViewById<HTMLButtonElement>(view, "back")
        this.viewTitle = getViewById<HTMLDivElement>(view, "view_title")
        this.header = getViewById<HTMLElement>(view, "header")
        this.canChangeSchoolFilter = findViewById<HTMLImageElement>(view, "canChangeSchoolFilter")
        this.subheader = getViewById<HTMLElement>(view, "subheader")
        this.connectivityIndicator = getViewById<HTMLImageElement>(view, "connectivityIndicator")
        this.connectedIndicator = findViewById<HTMLImageElement>(view, "connectedIndicator")
        this.mute = getViewById<HTMLDivElement>(view, "mute")
        this.muteImage = getViewById<HTMLImageElement>(view, "muteImage")
        this.muteLabel = getViewById<HTMLElement>(view, "muteLabel")
        this.statusWorking = getViewById<HTMLDivElement>(view, "statusWorking")
        this.statusLayout = getViewById<HTMLDivElement>(view, "statusLayout")
        this.statusButton = getViewById<HTMLImageElement>(view, "statusButton")
        this.statusText = getViewById<HTMLElement>(view, "statusText")
        this.backButtonMirror = getViewById<HTMLElement>(view, "backButtonMirror")
        this.content = getViewById<HTMLDivElement>(view, "content")
        this.bottomBar = getViewById<HTMLDivElement>(view, "bottomBar")
        this.alertTab = getViewById<HTMLInputElement>(view, "alertTab")
        this.boardTab = getViewById<HTMLInputElement>(view, "boardTab")
        this.messagesTab = getViewById<HTMLInputElement>(view, "messagesTab")
        this.unreadIndicator = getViewById<HTMLElement>(view, "unreadIndicator")
        this.mapTab = getViewById<HTMLInputElement>(view, "mapTab")
        this.settingsTab = getViewById<HTMLInputElement>(view, "settingsTab")
        this.logo = findViewById<HTMLImageElement>(view, "logo")
        this.schoolSelectorSpace = findViewById<HTMLElement>(view, "schoolSelectorSpace")
        this.home = findViewById<HTMLDivElement>(view, "home")
        this.homeImage = findViewById<HTMLImageElement>(view, "homeImage")
        this.homeLabel = findViewById<HTMLElement>(view, "homeLabel")
        this.history = findViewById<HTMLDivElement>(view, "history")
        this.historyImage = findViewById<HTMLImageElement>(view, "historyImage")
        this.historyLabel = findViewById<HTMLElement>(view, "historyLabel")
        this.statusTextSafe = findViewById<HTMLElement>(view, "statusTextSafe")
        this.horizontalDivider = findViewById<HTMLElement>(view, "horizontalDivider")
        this.collapseLayout = findViewById<HTMLDivElement>(view, "collapseLayout")
        this.filterBySchoolLayout = findViewById<HTMLDivElement>(view, "filter_by_school_layout")
        this.filter = findViewById<HTMLInputElement>(view, "filter")
        this.schools = findViewById<HTMLDivElement>(view, "schools")
        this.collapseButton = findViewById<HTMLButtonElement>(view, "collapseButton")
        this.collapseLabel = findViewById<HTMLImageElement>(view, "collapseLabel")
        this.verticalDivider2 = findViewById<HTMLElement>(view, "verticalDivider2")
        this.verticalDivider = findViewById<HTMLElement>(view, "verticalDivider")
        this.landscapeIndicator = findViewById<HTMLElement>(view, "landscapeIndicator")
        return view
    }
}
