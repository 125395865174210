//
// SelectStatusXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './select_status.html'
//! Declares com.tresitgroup.android.tresit.layouts.SelectStatusXml
export class SelectStatusXml {
    xmlRoot!: HTMLElement
    selectingStatus!: HTMLDivElement
    safe!: HTMLButtonElement
    unsafe!: HTMLButtonElement
    needMedicalAttention!: HTMLInputElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.selectingStatus = getViewById<HTMLDivElement>(view, "selectingStatus")
        this.safe = getViewById<HTMLButtonElement>(view, "safe")
        this.unsafe = getViewById<HTMLButtonElement>(view, "unsafe")
        this.needMedicalAttention = getViewById<HTMLInputElement>(view, "needMedicalAttention")
        return view
    }
}
