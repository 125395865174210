// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/StartLoginResponse.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.StartLoginResponse
export class StartLoginResponse {
    public readonly url: (string | null);
    public readonly isLocationLogin: (boolean | null);
    public readonly hasTempDetails: (boolean | null);
    public constructor(url: (string | null) = null, isLocationLogin: (boolean | null) = false, hasTempDetails: (boolean | null) = false) {
        this.url = url;
        this.isLocationLogin = isLocationLogin;
        this.hasTempDetails = hasTempDetails;
    }
    public static fromJson(obj: any): StartLoginResponse { return new StartLoginResponse(
        parseJsonTyped(obj["url"], [String]) as (string | null),
        parseJsonTyped(obj["isLocationLogin"], [Boolean]) as (boolean | null),
        parseJsonTyped(obj["hasTempDetails"], [Boolean]) as (boolean | null)
    ) }
    public toJSON(): object { return {
        url: this.url,
        isLocationLogin: this.isLocationLogin,
        hasTempDetails: this.hasTempDetails
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.url);
        hash = 31 * hash + hashAnything(this.isLocationLogin);
        hash = 31 * hash + hashAnything(this.hasTempDetails);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof StartLoginResponse && safeEq(this.url, other.url) && safeEq(this.isLocationLogin, other.isLocationLogin) && safeEq(this.hasTempDetails, other.hasTempDetails) }
    public toString(): string { return `StartLoginResponse(url=${this.url}, isLocationLogin=${this.isLocationLogin}, hasTempDetails=${this.hasTempDetails})` }
    public copy(url: (string | null) = this.url, isLocationLogin: (boolean | null) = this.isLocationLogin, hasTempDetails: (boolean | null) = this.hasTempDetails): StartLoginResponse { return new StartLoginResponse(url, isLocationLogin, hasTempDetails); }
}