//
// AlertStatusActiveXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/alert_status_active.html'
import htmlForDefault from './alert_status_active.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertStatusActiveXml
export class AlertStatusActiveXml {
    xmlRoot!: HTMLElement
    statusImage!: HTMLImageElement
    statusText!: HTMLElement
    typeText!: HTMLElement
    policeStatusSending!: HTMLDivElement
    policeStatusDropdown!: HTMLSelectElement
    policeStatusView!: HTMLElement
    modifyBar!: HTMLDivElement
    approveWorking!: HTMLDivElement
    approve!: HTMLButtonElement
    changeBox!: HTMLDivElement
    change!: HTMLButtonElement
    endWorking!: HTMLDivElement
    end!: HTMLButtonElement
    guidances!: HTMLDivElement
    loadHtmlString(): string {
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [700])
        this.xmlRoot = view
        this.statusImage = getViewById<HTMLImageElement>(view, "status_image")
        this.statusText = getViewById<HTMLElement>(view, "status_text")
        this.typeText = getViewById<HTMLElement>(view, "type_text")
        this.policeStatusSending = getViewById<HTMLDivElement>(view, "policeStatusSending")
        this.policeStatusDropdown = getViewById<HTMLSelectElement>(view, "policeStatusDropdown")
        this.policeStatusView = getViewById<HTMLElement>(view, "policeStatusView")
        this.modifyBar = getViewById<HTMLDivElement>(view, "modifyBar")
        this.approveWorking = getViewById<HTMLDivElement>(view, "approveWorking")
        this.approve = getViewById<HTMLButtonElement>(view, "approve")
        this.changeBox = getViewById<HTMLDivElement>(view, "changeBox")
        this.change = getViewById<HTMLButtonElement>(view, "change")
        this.endWorking = getViewById<HTMLDivElement>(view, "endWorking")
        this.end = getViewById<HTMLButtonElement>(view, "end")
        this.guidances = getViewById<HTMLDivElement>(view, "guidances")
        return view
    }
}
