// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminPasswordResetVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ViewString, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { AdminPasswordResetXml } from '../../layout/AdminPasswordResetXml'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { User } from '../../model/User'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { logVG } from '../../util/LogVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { ApiModifier } from '../../api/ApiModifier'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminPasswordResetVG
export class AdminPasswordResetVG extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly userId: ForeignKey<User>;
    public constructor(session: SessionApi, stack: ObservableStack<ViewGenerator>, userId: ForeignKey<User>) {
        super();
        this.session = session;
        this.stack = stack;
        this.userId = userId;
        this.newPassword = new StandardObservableProperty<string>("", undefined);
        this.confirmPassword = new StandardObservableProperty<string>("", undefined);
        this.loading = new StandardObservableProperty<boolean>(false, undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.AdminPasswordResetVG.title
    public get title(): string { return "Admin Password Reset"; }
    
    
    public readonly newPassword: MutableObservableProperty<string>;
    
    public readonly confirmPassword: MutableObservableProperty<string>;
    
    public readonly loading: MutableObservableProperty<boolean>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new AdminPasswordResetXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.password
        xEditTextBindString(xml.password, this.newPassword);
        
        //--- Set Up xml.confirmPassword
        xEditTextBindString(xml.confirmPassword, this.confirmPassword);
        
        //--- Set Up xml.loading
        xViewFlipperBindLoading(xml.loading, this.loading, undefined);
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.save (overwritten on flow generation)
        xViewOnClick(xml.save, undefined, (): void => {
            this.saveClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cancelClick(): void {
        this.stack.dismiss();
    }
    public saveClick(): void {
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(this.userId, [new ApiModifier<User>("password", this.newPassword.value), new ApiModifier<User>("confirm_password", this.confirmPassword.value)]), this.loading), (_int: number, _1: string): (ViewString | null) => {
                return ((): (ViewString | null) => {
                    if (_int === 400) {
                        return new ViewStringResource(R._string.bad_passwords);
                    } else {
                        return null;
                    }
                })();
            }, (it: User): void => {
                this.stack.dismiss();
        }));
    }
    
    //--- Body End
}
