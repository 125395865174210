//
// ComponentRowAlertXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_alert.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowAlertXml
export class ComponentRowAlertXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    alertImage!: HTMLImageElement
    schoolName!: HTMLElement
    alertType!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.alertImage = getViewById<HTMLImageElement>(view, "alert_image")
        this.schoolName = getViewById<HTMLElement>(view, "schoolName")
        this.alertType = getViewById<HTMLElement>(view, "alertType")
        return view
    }
}
