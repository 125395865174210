// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/ConversationDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { ChatMessage } from '../../model/ChatMessage'
import { ComponentChatAttachmentPreviewXml } from '../../layout/ComponentChatAttachmentPreviewXml'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { Image, xIntAsImage, xStringAsImage, xUriAsImage } from 'butterfly-web/dist/Image'
import { flatMap as rxFlatMap, map as rxMap, switchMap as rxSwitchMap, take as rxTake } from 'rxjs/operators'
import { ConversationOptionsVG } from './ConversationOptionsVG'
import { Observable, ObservableInput, SubscriptionLike, concat as rxConcat, NEVER as rxNEVER, of as rxOf, zip as rxZip } from 'rxjs'
import { HttpPhase, HttpProgress } from 'butterfly-web/dist/net/HttpModels'
import { xVideoThumbnail } from 'butterfly-web/dist/Video.ext'
import { xActivityAccessDownloadFile, xActivityAccessGetFileName, xActivityAccessGetMimeType, xActivityAccessRequestFiles } from 'butterfly-web/dist/views/ViewDependency'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { DialogRequest, showDialog, showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xObservablePropertyObservableGet, xObservablePropertyObservableNNGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { setViewBackgroundClass, setViewVisibility, xViewOnClick, xViewOnLongClick } from 'butterfly-web/dist/views/View.ext'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { ViewString, ViewStringRaw, ViewStringResource, ViewStringTemplate } from 'butterfly-web/dist/views/ViewString'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { ComponentRowMessageSentXml } from '../../layout/ComponentRowMessageSentXml'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { StatusEnum } from '../../model/StatusEnum'
import { xObservableMapNotNull, xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { Video, xStringAsVideo, xUriAsVideo } from 'butterfly-web/dist/Video'
import { ComponentChatAttachmentXml } from '../../layout/ComponentChatAttachmentXml'
import { xRecyclerViewBindMulti, xRecyclerViewReverseDirectionSet, xRecyclerViewWhenScrolledToEnd } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xObservableAsObservableProperty } from 'butterfly-web/dist/observables/EventToObservableProperty'
import { xObservablePropertyFlatMap, xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ChatThread } from '../../model/ChatThread'
import { xCharSequenceIsBlank, xStringSubstringAfterLast, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Floor } from '../../model/Floor'
import { xEditTextSetOnDoneClick } from 'butterfly-web/dist/views/EditText'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { Alert } from '../../model/Alert'
import { ConversationDetailXml } from '../../layout/ConversationDetailXml'
import { R } from '../../R'
import { xProgressBarBindFloat } from 'butterfly-web/dist/observables/binding/ProgressBar.binding'
import { ComponentRowMessageSentAttachmentsXml } from '../../layout/ComponentRowMessageSentAttachmentsXml'
import { ForeignKey } from '../../model/ForeignKey'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ComponentRowMessageSystemXml } from '../../layout/ComponentRowMessageSystemXml'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { PointF } from 'butterfly-web/dist/views/geometry/PointF'
import { School } from '../../model/School'
import { ComponentRowMessageXml } from '../../layout/ComponentRowMessageXml'
import { xObservablePropertyShare } from 'butterfly-web/dist/observables/SharingObservableProperty'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { Room } from '../../model/Room'
import { ImagePreviewVG } from './ImagePreviewVG'
import { ComponentRowMessageAttachmentsXml } from '../../layout/ComponentRowMessageAttachmentsXml'
import { Platform } from 'butterfly-web/dist/Platform'
import { VideoPreviewVG } from './VideoPreviewVG'
import { xSessionApiReading } from '../../api/Session.read'
import { ChatAttachment } from '../../model/ChatAttachment'
import { printStackTrace } from 'butterfly-web/dist/kotlin/Language'
import { scrollChildIntoView } from 'butterfly-web/dist/views/RecyclerView'

//! Declares com.tresitgroup.android.tresit.vg.chat.ConversationDetailVG
export class ConversationDetailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly threadId: ForeignKey<ChatThread>;
    public readonly threadName: (string | null);
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, session: SessionApi, threadId: ForeignKey<ChatThread>, threadName: (string | null) = null, schoolFilter: ObservableProperty<(School | null)>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.session = session;
        this.threadId = threadId;
        this.threadName = threadName;
        this.schoolFilter = schoolFilter;
        this.stack = stack;
        this.newMessage = new StandardObservableProperty<string>("", undefined);
        this.sending = new StandardObservableProperty<boolean>(false, undefined);
        this.thread = xObservablePropertyMap<(ChatThread | null), (ChatThread | null)>(this.session.threads.observable(this.threadId), (it: (ChatThread | null)): (ChatThread | null) => it);
        this.messages = xObservablePropertyMap<(Array<ChatMessage> | null), Array<ChatMessage>>(this.session.messages.observableListSimple(ChatMessage.Companion.INSTANCE.partOfThread(this.threadId), ChatMessage.Companion.INSTANCE.createdDownSort, undefined, undefined, undefined, undefined), (it: (Array<ChatMessage> | null)): Array<ChatMessage> => it ?? []);
        this.attachments = new StandardObservableProperty<Array<ConversationDetailVG.LocalChatAttachment>>([], undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.ConversationDetailVG.titleString
    public get titleString(): ViewString { return new ViewStringResource(R._string.conversation); }
    
    
    public readonly newMessage: MutableObservableProperty<string>;
    
    public readonly sending: MutableObservableProperty<boolean>;
    
    
    
    
    //    var messageCount = 0
    
    public readonly thread: ObservableProperty<(ChatThread | null)>;
    
    public readonly messages: ObservableProperty<Array<ChatMessage>>;
    
    
    
    public threadAlert(): ObservableProperty<(Alert | null)> {
        return xObservablePropertyFlatMapNotNull<number, Alert>(xObservablePropertyMap<(ChatThread | null), (number | null)>(this.thread, (it: (ChatThread | null)): (number | null) => (it?.alert ?? null)), (it: number): ObservableProperty<(Alert | null)> => this.session.alerts.observable(it));
    }
    
    
    private readonly attachments: MutableObservableProperty<Array<ConversationDetailVG.LocalChatAttachment>>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ConversationDetailXml();
        
        const view = xml.setup(dependency);
        
        //--- Log
        logVG(this, this.session);
        
        if (safeEq(Platform.Companion.INSTANCE.current, Platform.Web)) {
            const groupThreads = xObservablePropertyShare<boolean>(xObservablePropertyFlatMap<(School | null), boolean>(this.schoolFilter, (it: (School | null)): ObservableProperty<boolean> => this.recentGroupThreads()), true);
            
        }
        
        
        //--- Watching for red dot
        xDisposableUntil<SubscriptionLike>(xSessionApiReading(this.session, this.threadId), xViewRemovedGet(view));
        
        setViewVisibility(xml.goToLatest, "gone");
        
        //--- Shortcuts
        const threadAlert = this.threadAlert();
        
        
        
        //--- Set Up xml.details
        xViewOnClick(xml.details, undefined, (): void => {
            if (safeEq((this.thread.value?.threadType ?? null), ChatThread.ThreadType.Group)) {
                this.showParticipants();
            }
        });
        
        
        
        //--- Set Up xml.typeIcon
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(ChatThread | null)>(this.thread, undefined, undefined, (it: (ChatThread | null)): void => {
            switch((it?.threadType ?? null) ?? ChatThread.ThreadType.Group) {
                case ChatThread.ThreadType.Alert:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_warn_light)
                break;
                case ChatThread.ThreadType.Group:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_group_light)
                break;
                case ChatThread.ThreadType.Contact:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_person_light)
                break;
                default:
                imageViewSetImageResource(xml.typeIcon, R.drawable.ic_red_flag)
                break;
            }
            
        }), xViewRemovedGet(xml.typeIcon));
        
        //--- Set Up xml.groupName
        xViewBindExists(xml.groupName, xObservablePropertyMap<(ChatThread | null), boolean>(this.session.threads.observable(this.threadId), (it: (ChatThread | null)): boolean => (it?.name ?? null) !== null));
        xTextViewBindString(xml.groupName, xObservablePropertyMap<(ChatThread | null), string>(this.session.threads.observable(this.threadId), (it: (ChatThread | null)): string => (it?.name ?? null) ?? ""));
        
        //--- Set Up xml.participants
        xTextViewBindString(xml.participants, xObservablePropertyMap<(string | null), string>(xObservablePropertyFlatMapNotNull<ChatThread, string>(this.session.threads.observable(this.threadId), (it: ChatThread): ObservableProperty<(string | null)> => {
            const schoolNames = it.schools.map((it: number): ObservableProperty<string> => xObservablePropertyMap<(School | null), string>(this.session.schools.observable(it), (it: (School | null)): string => (it?.name ?? null) ?? "-"));
            
            const userNames = it.userIds.map((it: number): ObservableProperty<string> => xObservablePropertyMap<(User | null), string>(this.session.users.observable(it), (it: (User | null)): string => (it?.name ?? null) ?? "-"));
            
            const result: ObservableProperty<(string | null)> = xObservablePropertyMap<string, (string | null)>(xObservablePropertyMap<Array<string>, string>(xListCombined<string>((schoolNames.concat(userNames))), (it: Array<string>): string => it.filter((it: string): boolean => !(it === (this.session.me.value?.name ?? null))).slice(0, 3).join(", ")), (it: string): (string | null) => it);
            
            return result;
        }), (it: (string | null)): string => it ?? ""));
        
        //--- Set Up xml.moreParticipants
        xViewBindExists(xml.moreParticipants, xObservablePropertyMap<(ChatThread | null), boolean>(this.thread, (it: (ChatThread | null)): boolean => ((): (boolean | null) => {
            const temp4295 = it;
            if(temp4295 === null) { return null }
            return ((thread: ChatThread): boolean => thread.userIds.length > 3)(temp4295)
        })() ?? ((this_: ConversationDetailVG): boolean => false)(this)));
        
        
        //--- Set Up xml.threadMessages
        //--- Set Up cellXml.image
        //--- Set Up cellXml.initials
        //--- Set Up cellXml.name
        //--- Set Up cellXml.message
        //--- Set Up cellXml.attachments
        
        //--- Make Subview For xml.threadMessages
        //--- End Make Subview For xml.threadMessages
        xRecyclerViewBindMulti<ChatMessage>(xml.threadMessages, this.messages, new ChatMessage(undefined, undefined, this.threadId, undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined), (it: ChatMessage): number => it.authorId === this.session.session.userId ? 2 + (it.attachmentsDeep.length !== 0 ? 1 : 0) : it.authorId === null ? 4 : 0 + (it.attachmentsDeep.length !== 0 ? 1 : 0), (type: number, observable: ObservableProperty<ChatMessage>): HTMLElement => {
            const messageAuthor: ObservableProperty<(User | null)> = xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<ChatMessage, (number | null)>(observable, (it: ChatMessage): (number | null) => it.authorId), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it));
            
            
            const authorLocation: ObservableProperty<(Room | null)> = xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyCombine<(number | null), (Alert | null), (number | null)>(xObservablePropertyMap<(User | null), (number | null)>(messageAuthor, (it: (User | null)): (number | null) => (it?.currentLocation ?? null)), threadAlert, (loc: (number | null), alert: (Alert | null)): (number | null) => alert !== null ? loc : null), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it));
            
            
            const roomName: ObservableProperty<string> = xObservablePropertyMap<(Room | null), string>(authorLocation, (it: (Room | null)): string => (it?.name ?? null) ?? "Default");
            
            const floor = xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(authorLocation, (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it));
            
            const floorName: ObservableProperty<string> = xObservablePropertyMap<(Floor | null), string>(floor, (it: (Floor | null)): string => (it?.name ?? null) ?? "Default");
            
            const buildingName: ObservableProperty<string> = xObservablePropertyMap<(Building | null), string>(xObservablePropertyFlatMapNotNull<number, Building>(xObservablePropertyMap<(Floor | null), (number | null)>(floor, (it: (Floor | null)): (number | null) => (it?.building ?? null)), (it: number): ObservableProperty<(Building | null)> => this.session.buildings.observable(it)), (it: (Building | null)): string => (it?.name ?? null) ?? "Default");
            
            const names: ObservableProperty<Array<string>> = xObservablePropertyMap<Array<string>, Array<string>>(xListCombined<string>(([roomName, floorName, buildingName] as Array<ObservableProperty<string>>)), (it: Array<string>): Array<string> => it.filter((it: string): boolean => !(it === "Default")));
            
            const details = xObservablePropertyCombine<string, (User | null), string>(xObservablePropertyCombine<string, Array<string>, string>(xObservablePropertyCombine<string, (Room | null), string>(xObservablePropertyMap<ChatMessage, string>(observable, (message: ChatMessage): string => xDateFormat(message.created, ClockPartSize.None, ClockPartSize.Short)), authorLocation, (message: string, room: (Room | null)): string => ((): (string | null) => {
                            const temp4308 = room;
                            if(temp4308 === null) { return null }
                            return ((it: Room): string => `${message} | ${it.safe ?? StatusEnum.Unknown}`)(temp4308)
                })() ?? message), names, (message: string, locations: Array<string>): string => ((): (string | null) => {
                    const temp4310 = (locations[0] ?? null);
                    if(temp4310 === null) { return null }
                    return ((it: string): string => `${(locations[0] ?? null) ?? "Somewhere"} | ${message}`)(temp4310)
            })() ?? message), messageAuthor, (message: string, author: (User | null)): string => `${(author?.firstName ?? null) ?? "Unknown"} ${(author?.lastName ?? null) ?? "Author"} | ${message}`);
            
            
            
            this.session.shouldShowChatIcon.value = false;
            return ((): HTMLElement => {
                switch(type) {
                    case 0:
                    // Message Received
                    const cellXmlA = new ComponentRowMessageXml();
                    
                    const cellViewA = cellXmlA.setup(dependency);
                    
                    
                    xTextViewBindString(cellXmlA.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp4312 = ((): (string | null) => {
                            const temp4313 = author;
                            if(temp4313 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp4313)
                        })();
                        if(temp4312 === null) { return null }
                        return temp4312.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlA.name, details)
                    xTextViewBindString(cellXmlA.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    return cellViewA
                    case 1:
                    // Message Received with attachment
                    const cellXmlB = new ComponentRowMessageAttachmentsXml();
                    
                    const cellViewB = cellXmlB.setup(dependency);
                    
                    
                    xTextViewBindString(cellXmlB.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp4317 = ((): (string | null) => {
                            const temp4318 = author;
                            if(temp4318 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp4318)
                        })();
                        if(temp4317 === null) { return null }
                        return temp4317.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlB.name, details)
                    xTextViewBindString(cellXmlB.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    this.bindAttachments(cellXmlB.attachments, cellXmlB.attachments, dependency, observable)
                    return cellViewB
                    case 2:
                    // Message Sent
                    const cellXmlC = new ComponentRowMessageSentXml();
                    
                    const cellViewC = cellXmlC.setup(dependency);
                    
                    xTextViewBindString(cellXmlC.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp4322 = ((): (string | null) => {
                            const temp4323 = author;
                            if(temp4323 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp4323)
                        })();
                        if(temp4322 === null) { return null }
                        return temp4322.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlC.name, details)
                    xTextViewBindString(cellXmlC.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    return cellViewC
                    case 3:
                    // Message Sent with attachment
                    const cellXmlD = new ComponentRowMessageSentAttachmentsXml();
                    
                    const cellViewD = cellXmlD.setup(dependency);
                    
                    xTextViewBindString(cellXmlD.initials, xObservablePropertyMap<(User | null), string>(messageAuthor, (author: (User | null)): string => ((): (string | null) => {
                        const temp4327 = ((): (string | null) => {
                            const temp4328 = author;
                            if(temp4328 === null) { return null }
                            return ((it: User): string => `${(it.firstName[0] ?? null) ?? "?"}${(it.lastName[0] ?? null) ?? "?"}`)(temp4328)
                        })();
                        if(temp4327 === null) { return null }
                        return temp4327.toUpperCase()
                    })() ?? "??"))
                    xTextViewBindString(cellXmlD.name, details)
                    xTextViewBindString(cellXmlD.message, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => it.content))
                    this.bindAttachments(cellXmlD.attachments, cellXmlD.attachments, dependency, observable)
                    return cellViewD
                    default:
                    // Message from system
                    
                    const cellXmlE = new ComponentRowMessageSystemXml();
                    
                    const cellViewE = cellXmlE.setup(dependency);
                    
                    xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Alert | null)>(threadAlert, undefined, undefined, (alert: (Alert | null)): void => {
                        if (alert !== null && (!alert!.drill)){
                            setViewBackgroundClass(cellXmlE.backgroundView, R.drawable.card_alert.cssClass);
                        } else if (alert !== null && alert!.drill){
                            setViewBackgroundClass(cellXmlE.backgroundView, R.drawable.card_blue.cssClass);
                        }
                    }), xViewRemovedGet(cellXmlE.xmlRoot))
                    xTextViewBindString(cellXmlE.message, xObservablePropertyCombine<ChatMessage, (Alert | null), string>(observable, threadAlert, (it: ChatMessage, alert: (Alert | null)): string => it.content.replaceAll("[time]", xDateFormat(((alert?.created ?? null) ?? it.created), ClockPartSize.None, ClockPartSize.Short))))
                    xImageViewBindImage(cellXmlE.alertTypeIcon, xObservablePropertyMap<ChatMessage, (Image | null)>(observable, (it: ChatMessage): (Image | null) => ((): (Image | null) => {
                        const temp4340 = ((it.attachmentsDeep[0] ?? null)?.image ?? null);
                        if(temp4340 !== null) {
                            return xStringAsImage(temp4340)
                        } else { return null }
                    })()))
                    xTextViewBindString(cellXmlE.name, xObservablePropertyMap<ChatMessage, string>(observable, (it: ChatMessage): string => `System Message | ${xDateFormat(it.created, ClockPartSize.Medium, ClockPartSize.Short)}`))
                    return cellViewE
                }
                
            })()
        });
        xRecyclerViewReverseDirectionSet(xml.threadMessages, true);
        
        xml.goToLatest.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            setViewVisibility(xml.goToLatest, "gone");
            scrollChildIntoView(xml.threadMessages, 0, { behavior: "smooth", block: "nearest", inline: "nearest"});
        };
        
        
        
        xRecyclerViewWhenScrolledToEnd(xml.threadMessages, (): void => {});
        
        xObservablePropertyObservableGet(this.session.shouldShowChatIcon).subscribe((it: boolean): void => {
            if (it) {
                setViewVisibility(xml.goToLatest, "visible");
            }
        }, undefined, undefined);
        
        
        xDisposableUntil<SubscriptionLike>(this.messages.onChange.subscribe((it: Array<ChatMessage>): void => {}, undefined, undefined), xViewRemovedGet(xml.threadMessages));
        
        
        //--- Set Up xml.attachmentsSection
        xViewBindExists(xml.attachmentsSection, xObservablePropertyMap<Array<ConversationDetailVG.LocalChatAttachment>, boolean>(this.attachments, (it: Array<ConversationDetailVG.LocalChatAttachment>): boolean => it.length !== 0));
        
        //--- Set Up xml.attachments
        xLinearLayoutBind<ConversationDetailVG.LocalChatAttachment>(xml.attachments, this.attachments, new ConversationDetailVG.LocalChatAttachment(null, rxNEVER), (obs: ObservableProperty<ConversationDetailVG.LocalChatAttachment>): HTMLElement => {
            const xml = new ComponentChatAttachmentPreviewXml();
            
            const view = xml.setup(dependency);
            
            
            xViewOnClick(xml.remove, undefined, (): void => {
                obs.value.dispose();
                this.attachments.value = this.attachments.value.filter((it: ConversationDetailVG.LocalChatAttachment): boolean => it !== obs.value);
            });
            
            xImageViewBindImage(xml.image, xObservableAsObservableProperty<Image>(xObservablePropertyObservableNNGet(obs).pipe(rxSwitchMap((it: ConversationDetailVG.LocalChatAttachment): ObservableInput<Image> => ((): (Observable<Image> | null) => {
                const temp4356 = it.uri;
                if(temp4356 === null) { return null }
                return ((uri: File): (Observable<Image> | null) => ((): (Observable<Image> | null) => {
                    const temp4358 = xActivityAccessGetMimeType(dependency, uri);
                    if(temp4358 === null) { return null }
                    return ((type: string): Observable<Image> => ((): Observable<Image> => {
                        if (type.startsWith("image/")) {
                            return rxOf(xUriAsImage(uri))
                        } else if (type.startsWith("video/")) {
                            return rxConcat(rxOf(xIntAsImage(R.drawable.ic_attachment)), xVideoThumbnail(xUriAsVideo(uri), 0, new PointF(500, 500)))
                        } else  {
                            return rxOf(xIntAsImage(R.drawable.ic_attachment))
                        }
                    })())(temp4358)
                })())(temp4356)
            })() ?? rxNEVER)), xIntAsImage(R.drawable.ic_attachment)));
            
            xTextViewBindString(xml.fileName, xObservablePropertyMap<ConversationDetailVG.LocalChatAttachment, string>(obs, (it: ConversationDetailVG.LocalChatAttachment): string => ((): (string | null) => {
                const temp4361 = it.uri;
                if(temp4361 === null) { return null }
                return ((it: File): (string | null) => xActivityAccessGetFileName(dependency, it))(temp4361)
            })() ?? "File"));
            
            xViewBindExists(xml.progress, xObservablePropertyFlatMap<ConversationDetailVG.LocalChatAttachment, boolean>(obs, (it: ConversationDetailVG.LocalChatAttachment): ObservableProperty<boolean> => xObservableAsObservableProperty<boolean>(it.progress.pipe(rxMap((it: HttpProgress<ChatAttachment>): boolean => !safeEq(it.phase, HttpPhase.Done))), false)));
            xProgressBarBindFloat(xml.progress, xObservablePropertyFlatMap<ConversationDetailVG.LocalChatAttachment, number>(obs, (it: ConversationDetailVG.LocalChatAttachment): ObservableProperty<number> => xObservableAsObservableProperty<number>(it.progress.pipe(rxMap((it: HttpProgress<ChatAttachment>): number => it.approximate)), 0)));
            
            xViewBindExists(xml.error, xObservablePropertyFlatMap<ConversationDetailVG.LocalChatAttachment, boolean>(obs, (it: ConversationDetailVG.LocalChatAttachment): ObservableProperty<boolean> => xObservableAsObservableProperty<boolean>(it.progress.pipe(rxMap((it: HttpProgress<ChatAttachment>): boolean => safeEq(it.phase, HttpPhase.Done) && it.response === null)), false)));
            
            return view;
        });
        
        //--- Set Up xml.addAttachments
        xViewBindExists(xml.addAttachments, ConversationDetailVG.Companion.INSTANCE.attachmentsEnabled);
        xViewOnClick(xml.addAttachments, undefined, (): void => {
            this.addAttachmentsClick(dependency);
        });
        
        //--- Set Up xml.newMessageEntry
        xEditTextBindString(xml.newMessageEntry, this.newMessage);
        xEditTextSetOnDoneClick(xml.newMessageEntry, (): void => {
            xml.newMessageSubmit.click();
        });
        
        //--- Set Up xml.newMessageSending
        xViewFlipperBindLoading(xml.newMessageSending, this.sending, undefined);
        
        //--- Set Up xml.newMessageSubmit
        xViewOnClick(xml.newMessageSubmit, undefined, (): void => {
            this.newMessageSubmitClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    private recentGroupThreads(): StandardObservableProperty<boolean> {
        return new StandardObservableProperty<boolean>(true, undefined);
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public showParticipants(): void {
        this.dialog.push(new ConversationOptionsVG(this.dialog, this.session, this.threadId, this.schoolFilter, this.stack));
    }
    
    public newMessageSubmitClick(): void {
        if (xCharSequenceIsBlank(this.newMessage.value)) {
            showDialogAlert(new ViewStringRaw("Please enter a message first."));
            return;
        }
        const makePost = this.session.messages.post(new ChatMessage(undefined, undefined, this.threadId, this.session.session.userId, undefined, this.newMessage.value, undefined, undefined, undefined, undefined, undefined, undefined));
        
        if (this.attachments.value.length !== 0) {
            xSingleCallDisplayingError<void>(xSingleWorking<void>(rxZip(...this.attachments.value.map((it: ConversationDetailVG.LocalChatAttachment): Observable<ChatAttachment> => xObservableMapNotNull<HttpProgress<ChatAttachment>, ChatAttachment>(it.progress, (it: HttpProgress<ChatAttachment>): (ChatAttachment | null) => it.response).pipe(rxTake(1)))).pipe(rxFlatMap((attachments: Array<ChatAttachment>): ObservableInput<void> => makePost.pipe(rxFlatMap((msg: ChatMessage): ObservableInput<void> => this.session.rawApi.bindAttachments(this.session.session, msg.id, attachments.map((it: ChatAttachment): number => it.id)))))), this.sending), undefined, (it: void): void => {
                this.attachments.value = [];
                this.newMessage.value = "";
            });
        } else {
            xSingleCallDisplayingError<ChatMessage>(xSingleWorking<ChatMessage>(makePost, this.sending), undefined, (it: ChatMessage): void => {
                this.newMessage.value = "";
            });
        }
        
    }
    
    public launchPreview(dependency: Window, attachment: ObservableProperty<ChatAttachment>): void {
        if (attachment.value.video !== null) {
            this.dialog.push(new VideoPreviewVG(this.dialog, xObservablePropertyMap<ChatAttachment, (Video | null)>(attachment, (it: ChatAttachment): (Video | null) => ((): (Video | null) => {
                const temp4380 = it.video;
                if(temp4380 !== null) {
                    return xStringAsVideo(temp4380)
                } else { return null }
            })())))
        } else if (attachment.value.image !== null) {
            this.dialog.push(new ImagePreviewVG(xObservablePropertyMap<ChatAttachment, (Image | null)>(attachment, (it: ChatAttachment): (Image | null) => ((): (Image | null) => {
                const temp4381 = it.image;
                if(temp4381 !== null) {
                    return xStringAsImage(temp4381)
                } else { return null }
            })()), this.dialog))
        } else if (attachment.value.doc !== null){
            this.launchDownload(dependency, attachment.value);
        }
    }
    
    public launchDownload(dependency: Window, attachment: ChatAttachment): void {
        const url = ((it: ChatAttachment): string => ((): string => {
            if (it.video !== null) {
                return it.video!
            } else if (it.image !== null) {
                return it.image!
            } else if (it.doc !== null) {
                return it.doc!
            } else  {
                return ""
            }
        })())(attachment);
        
        if (!xCharSequenceIsBlank(url)) {
            const fileName = xStringSubstringBefore(xStringSubstringAfterLast(url, '/', undefined), '?', undefined);
            
            showDialog(new DialogRequest(new ViewStringTemplate(new ViewStringResource(R._string.download_x), [fileName]), (): void => {
                xActivityAccessDownloadFile(dependency, url);
            }));
        }
    }
    
    public addAttachmentsClick(dependency: Window): void {
        xActivityAccessRequestFiles(dependency, (it: Array<File>): void => {
            this.attachments.value = this.attachments.value.concat(it.map((it: File): ConversationDetailVG.LocalChatAttachment => {
                return new ConversationDetailVG.LocalChatAttachment(it, this.session.rawApi.uploadAttachment(this.session.session, it, xActivityAccessGetFileName(dependency, it) ?? "name.jpg", xActivityAccessGetMimeType(dependency, it) ?? "application/octet-stream"));
            }));
        });
    }
    
    //--- Helpers
    
    public getNames(observable: ObservableProperty<(Room | null)>): ObservableProperty<Array<string>> {
        const roomName = xObservablePropertyMap<(Room | null), string>(observable, (it: (Room | null)): string => (it?.name ?? null) ?? "Default");
        
        const floor = xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(observable, (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it));
        
        const floorName = xObservablePropertyMap<(Floor | null), string>(floor, (it: (Floor | null)): string => (it?.name ?? null) ?? "Default");
        
        const buildingName = xObservablePropertyMap<(Building | null), string>(xObservablePropertyFlatMapNotNull<number, Building>(xObservablePropertyMap<(Floor | null), (number | null)>(floor, (it: (Floor | null)): (number | null) => (it?.building ?? null)), (it: number): ObservableProperty<(Building | null)> => this.session.buildings.observable(it)), (it: (Building | null)): string => (it?.name ?? null) ?? "Default");
        
        return xObservablePropertyMap<Array<string>, Array<string>>(xListCombined<string>([roomName, floorName, buildingName]), (it: Array<string>): Array<string> => it.filter((it: string): boolean => !(it === "Default")));
    }
    
    public bindAttachments(section: HTMLElement, toView: HTMLDivElement, dependency: Window, observable: ObservableProperty<ChatMessage>): void {
        xViewBindExists(section, xObservablePropertyMap<ChatMessage, boolean>(observable, (it: ChatMessage): boolean => it.attachmentsDeep.length !== 0));
        xLinearLayoutBind<ChatAttachment>(toView, xObservablePropertyMap<ChatMessage, Array<ChatAttachment>>(observable, (it: ChatMessage): Array<ChatAttachment> => it.attachmentsDeep), new ChatAttachment(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (obs: ObservableProperty<ChatAttachment>): HTMLElement => {
            const attXml = new ComponentChatAttachmentXml();
            
            const attView = attXml.setup(dependency);
            
            
            xViewOnClick(attXml.xmlRoot, undefined, (): void => {
                this.launchPreview(dependency, obs);
            });
            xViewOnLongClick(attXml.xmlRoot, (): void => {
                this.launchDownload(dependency, obs.value);
            });
            
            xImageViewBindImage(attXml.image, xObservablePropertyMap<ChatAttachment, (Image | null)>(obs, (it: ChatAttachment): (Image | null) => ((): (Image | null) => {
                        const temp4389 = it.videoThumbnail;
                        if(temp4389 !== null) {
                            return xStringAsImage(temp4389)
                        } else { return null }
                })() ?? ((): (Image | null) => {
                    const temp4390 = it.imageThumbnail;
                    if(temp4390 !== null) {
                        return xStringAsImage(temp4390)
                    } else { return null }
            })() ?? xIntAsImage(R.drawable.ic_attachment)));
            
            xViewBindExists(attXml.fileName, xObservablePropertyMap<ChatAttachment, boolean>(obs, (it: ChatAttachment): boolean => it.doc !== null));
            xTextViewBindString(attXml.fileName, xObservablePropertyMap<ChatAttachment, string>(obs, (it: ChatAttachment): string => {
                return ((): string => {
                    if (it.video !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.video!, '/', undefined), '?', undefined)
                    } else if (it.image !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.image!, '/', undefined), '?', undefined)
                    } else if (it.doc !== null) {
                        return xStringSubstringBefore(xStringSubstringAfterLast(it.doc!, '/', undefined), '?', undefined)
                    } else  {
                        return ""
                    }
                })();
            }));
            
            return attView;
        });
    }
    
    //--- Body End
}
export namespace ConversationDetailVG {
    //! Declares com.tresitgroup.android.tresit.vg.chat.ConversationDetailVG.Companion
    export class Companion {
        private constructor() {
            this.attachmentsEnabled = new StandardObservableProperty<boolean>(true, undefined);
        }
        public static INSTANCE = new Companion();
        
        public readonly attachmentsEnabled: StandardObservableProperty<boolean>;
        
    }
}
export namespace ConversationDetailVG {
    //! Declares com.tresitgroup.android.tresit.vg.chat.ConversationDetailVG.LocalChatAttachment
    export class LocalChatAttachment {
        public readonly uri: (File | null);
        public readonly progress: Observable<HttpProgress<ChatAttachment>>;
        public constructor(uri: (File | null) = null, progress: Observable<HttpProgress<ChatAttachment>>) {
            this.uri = uri;
            this.progress = progress;
            this.sub = this.progress.subscribe((it: HttpProgress<ChatAttachment>): void => {}, (e: any): void => {
                printStackTrace(e);
            }, (): void => {});
        }
        
        public readonly sub: SubscriptionLike;
        
        
        public dispose(): void {
            this.sub.unsubscribe();
        }
    }
}