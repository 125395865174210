//
// AlertHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/alert_history.html'
import htmlForDefault from './alert_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertHistoryXml
export class AlertHistoryXml {
    xmlRoot!: HTMLElement
    filter!: HTMLInputElement
    btnExport!: HTMLImageElement
    drillType!: HTMLSelectElement
    alertHistories!: HTMLDivElement
    clearDateFilters: HTMLButtonElement | null = null
    startDateFilter: HTMLInputElement | null = null
    endDateFilter: HTMLInputElement | null = null
    alertHeader: HTMLDivElement | null = null
    sortAlertAscending: HTMLImageElement | null = null
    sortAlertDescending: HTMLImageElement | null = null
    siteHeader: HTMLDivElement | null = null
    sortSiteAscending: HTMLImageElement | null = null
    sortSiteDescending: HTMLImageElement | null = null
    initiatorHeader: HTMLDivElement | null = null
    sortInitiatorAscending: HTMLImageElement | null = null
    sortInitiatorDescending: HTMLImageElement | null = null
    cancellerHeader: HTMLDivElement | null = null
    sortCancellerAscending: HTMLImageElement | null = null
    sortCancellerDescending: HTMLImageElement | null = null
    cancelReasonHeader: HTMLDivElement | null = null
    sortCancelReasonAscending: HTMLImageElement | null = null
    sortCancelReasonDescending: HTMLImageElement | null = null
    startHeader: HTMLDivElement | null = null
    sortStartTimeAscending: HTMLImageElement | null = null
    sortStartTimeDescending: HTMLImageElement | null = null
    endHeader: HTMLDivElement | null = null
    sortEndTimeAscending: HTMLImageElement | null = null
    sortEndTimeDescending: HTMLImageElement | null = null
    drillHeader: HTMLDivElement | null = null
    sortDrill: HTMLImageElement | null = null
    sortAllAlert: HTMLImageElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.btnExport = getViewById<HTMLImageElement>(view, "btnExport")
        this.drillType = getViewById<HTMLSelectElement>(view, "drillType")
        this.alertHistories = getViewById<HTMLDivElement>(view, "alert_histories")
        this.clearDateFilters = findViewById<HTMLButtonElement>(view, "clearDateFilters")
        this.startDateFilter = findViewById<HTMLInputElement>(view, "startDateFilter")
        this.endDateFilter = findViewById<HTMLInputElement>(view, "endDateFilter")
        this.alertHeader = findViewById<HTMLDivElement>(view, "alertHeader")
        this.sortAlertAscending = findViewById<HTMLImageElement>(view, "sortAlertAscending")
        this.sortAlertDescending = findViewById<HTMLImageElement>(view, "sortAlertDescending")
        this.siteHeader = findViewById<HTMLDivElement>(view, "siteHeader")
        this.sortSiteAscending = findViewById<HTMLImageElement>(view, "sortSiteAscending")
        this.sortSiteDescending = findViewById<HTMLImageElement>(view, "sortSiteDescending")
        this.initiatorHeader = findViewById<HTMLDivElement>(view, "initiatorHeader")
        this.sortInitiatorAscending = findViewById<HTMLImageElement>(view, "sortInitiatorAscending")
        this.sortInitiatorDescending = findViewById<HTMLImageElement>(view, "sortInitiatorDescending")
        this.cancellerHeader = findViewById<HTMLDivElement>(view, "cancellerHeader")
        this.sortCancellerAscending = findViewById<HTMLImageElement>(view, "sortCancellerAscending")
        this.sortCancellerDescending = findViewById<HTMLImageElement>(view, "sortCancellerDescending")
        this.cancelReasonHeader = findViewById<HTMLDivElement>(view, "cancelReasonHeader")
        this.sortCancelReasonAscending = findViewById<HTMLImageElement>(view, "sortCancelReasonAscending")
        this.sortCancelReasonDescending = findViewById<HTMLImageElement>(view, "sortCancelReasonDescending")
        this.startHeader = findViewById<HTMLDivElement>(view, "startHeader")
        this.sortStartTimeAscending = findViewById<HTMLImageElement>(view, "sortStartTimeAscending")
        this.sortStartTimeDescending = findViewById<HTMLImageElement>(view, "sortStartTimeDescending")
        this.endHeader = findViewById<HTMLDivElement>(view, "endHeader")
        this.sortEndTimeAscending = findViewById<HTMLImageElement>(view, "sortEndTimeAscending")
        this.sortEndTimeDescending = findViewById<HTMLImageElement>(view, "sortEndTimeDescending")
        this.drillHeader = findViewById<HTMLDivElement>(view, "drillHeader")
        this.sortDrill = findViewById<HTMLImageElement>(view, "sortDrill")
        this.sortAllAlert = findViewById<HTMLImageElement>(view, "sortAllAlert")
        return view
    }
}
