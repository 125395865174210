//
// GoogleMapXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './google_map.html'
//! Declares com.tresitgroup.android.tresit.layouts.GoogleMapXml
export class GoogleMapXml {
    xmlRoot!: HTMLElement
    mapLoading!: HTMLDivElement
    insertMapHere!: HTMLDivElement
    floors!: HTMLDivElement
    settingOffsite!: HTMLDivElement
    offsite!: HTMLButtonElement
    myLocation!: HTMLButtonElement
    mapReload!: HTMLButtonElement
    search!: HTMLInputElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.mapLoading = getViewById<HTMLDivElement>(view, "mapLoading")
        this.insertMapHere = getViewById<HTMLDivElement>(view, "insertMapHere")
        this.floors = getViewById<HTMLDivElement>(view, "floors")
        this.settingOffsite = getViewById<HTMLDivElement>(view, "settingOffsite")
        this.offsite = getViewById<HTMLButtonElement>(view, "offsite")
        this.myLocation = getViewById<HTMLButtonElement>(view, "myLocation")
        this.mapReload = getViewById<HTMLButtonElement>(view, "mapReload")
        this.search = getViewById<HTMLInputElement>(view, "search")
        return view
    }
}
