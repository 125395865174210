//! Declares com.tresitgroup.android.tresit.showToast
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export function xActivityAccessShowToast(dependency: any, message: string) {
  // creates a toast to notify user that the admin will process their request for admin approval required alert
  Toastify({
    text: message,
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "center", // `left`, `center` or `right`
    stopOnFocus: true, // Prevents dismissing of toast on hover
    style: {
      //   background: "#395FE6",
      height: "30px",
      width: "300px",
    },

    onClick: function () {}, // Callback after click

  }).showToast();
}