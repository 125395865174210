// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/PinEntryVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../api/SessionApi'
import { PinCreateVG } from './PinCreateVG'
import { PinVG } from './PinVG'
import { PinEntryXml } from '../layout/PinEntryXml'
import { LandingScreenVG } from './auth/LandingScreenVG'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { logVG } from '../util/LogVG'
import { ApplicationAccess } from 'butterfly-web/dist/ApplicationAccess'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xSessionApiLogOut } from '../model/Session.api'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { Preferences } from 'butterfly-web/dist/Preferences'

//! Declares com.tresitgroup.android.tresit.vg.PinEntryVG
export class PinEntryVG extends ViewGenerator implements HasBackAction, PinVG {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public static implementsInterfaceComTresitgroupAndroidTresitVgPinVG = true;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.session = session;
        this.currentPin = new StandardObservableProperty<string>("", undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.PinEntryVG.title
    public get title(): string { return "Pin Entry"; }
    
    
    public readonly currentPin: StandardObservableProperty<string>;
    
    
    public onBackPressed(): boolean {
        return true;
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PinEntryXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.background
        xml.backgroundDelegate.setup(dependency);
        
        //--- Set Up xml.pin
        xEditTextBindString(xml.pin, this.currentPin);
        const existingPin: (string | null) = Preferences.INSTANCE.get<string>([String], PinCreateVG.Companion.INSTANCE.prefKey);
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<string>(this.currentPin, undefined, undefined, (it: string): void => {
            if (it === existingPin) {
                this.dialog.dismiss();
                ApplicationAccess.INSTANCE.softInputActive.value = false;
            } else if (it.length >= 4) {
                post((): void => {
                    this.currentPin.value = "";
                });
            }
        }), xViewRemovedGet(xml.pin));
        post((): void => {
            xml.pin.focus();
            ApplicationAccess.INSTANCE.softInputActive.value = true;
        });
        
        //--- Set Up xml.logOut (overwritten on flow generation)
        xViewOnClick(xml.logOut, undefined, (): void => {
            this.logOutClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    public logOutClick(): void {
        xSessionApiLogOut(this.session);
        this.dialog.reset(new ViewGenerator.Default());
        this.dialog.dismiss();
        this.root.reset(new LandingScreenVG(this.dialog, this.root, this.root));
    }
    
    //--- Body End
}
