//
// FormAlertTypeXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './form_alert_type.html'
//! Declares com.tresitgroup.android.tresit.layouts.FormAlertTypeXml
export class FormAlertTypeXml {
    xmlRoot!: HTMLElement
    alertName!: HTMLInputElement
    actType!: HTMLInputElement
    tvSelectGroups!: HTMLElement
    tvSelectedGroup!: HTMLElement
    tvGroups!: HTMLElement
    showToAllGroup!: HTMLInputElement
    iconImage!: HTMLImageElement
    iconChange!: HTMLButtonElement
    mp3Change!: HTMLButtonElement
    mp3Name!: HTMLElement
    oggChange!: HTMLButtonElement
    oggName!: HTMLElement
    guidances!: HTMLDivElement
    newGuidance!: HTMLDivElement
    newTitle!: HTMLInputElement
    newPlan!: HTMLInputElement
    cancelGuidance!: HTMLButtonElement
    saveGuidance!: HTMLButtonElement
    addGuidance!: HTMLButtonElement
    adminApproval!: HTMLInputElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.alertName = getViewById<HTMLInputElement>(view, "alertName")
        this.actType = getViewById<HTMLInputElement>(view, "actType")
        this.tvSelectGroups = getViewById<HTMLElement>(view, "tvSelectGroups")
        this.tvSelectedGroup = getViewById<HTMLElement>(view, "tvSelectedGroup")
        this.tvGroups = getViewById<HTMLElement>(view, "tvGroups")
        this.showToAllGroup = getViewById<HTMLInputElement>(view, "showToAllGroup")
        this.iconImage = getViewById<HTMLImageElement>(view, "icon_image")
        this.iconChange = getViewById<HTMLButtonElement>(view, "icon_change")
        this.mp3Change = getViewById<HTMLButtonElement>(view, "mp3Change")
        this.mp3Name = getViewById<HTMLElement>(view, "mp3Name")
        this.oggChange = getViewById<HTMLButtonElement>(view, "oggChange")
        this.oggName = getViewById<HTMLElement>(view, "oggName")
        this.guidances = getViewById<HTMLDivElement>(view, "guidances")
        this.newGuidance = getViewById<HTMLDivElement>(view, "newGuidance")
        this.newTitle = getViewById<HTMLInputElement>(view, "newTitle")
        this.newPlan = getViewById<HTMLInputElement>(view, "newPlan")
        this.cancelGuidance = getViewById<HTMLButtonElement>(view, "cancelGuidance")
        this.saveGuidance = getViewById<HTMLButtonElement>(view, "saveGuidance")
        this.addGuidance = getViewById<HTMLButtonElement>(view, "addGuidance")
        this.adminApproval = getViewById<HTMLInputElement>(view, "adminApproval")
        return view
    }
}
