// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/RootVG.kt
// Package: com.tresitgroup.android.tresit.vg
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { Session } from '../model/Session'
import { safeEq, tryCastClass } from 'butterfly-web/dist/Kotlin'
import { ForegroundNotificationHandler, ForegroundNotificationHandlerDefaults, ForegroundNotificationHandlerResult } from 'butterfly-fcm-web/dist/ForegroundNotificationHandler'
import { DeepLink } from './DeepLinks'
import { SessionVG } from './SessionVG'
import { deadBuildCheck } from '../api/deadBuildCheck'
import { xSingleCallDisplayingError } from '../util/apicalls'
import { ChatMessage } from '../model/ChatMessage'
import { EntryPoint, EntryPointDefaults } from 'butterfly-web/dist/views/EntryPoint'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { RootXml } from '../layout/RootXml'
import { ApiOption } from '../api/ApiOption'
import { SessionApi } from '../api/SessionApi'
import { LandingScreenVG } from './auth/LandingScreenVG'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { School } from '../model/School'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ConversationDetailVG } from './chat/ConversationDetailVG'

//! Declares com.tresitgroup.android.tresit.vg.RootVG
export class RootVG extends ViewGenerator implements EntryPoint, ForegroundNotificationHandler {
    public static implementsInterfaceComLightningkiteButterflyViewsEntryPoint = true;
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public static implementsInterfaceComLightningkiteButterflyFcmForegroundNotificationHandler = true;
    public constructor() {
        super();
        this.dialog = new ObservableStack<ViewGenerator>();
        this.root = new ObservableStack<ViewGenerator>();
    }
    
    
    public readonly dialog: ObservableStack<ViewGenerator>;
    
    public readonly root: ObservableStack<ViewGenerator>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.RootVG.title
    public get title(): string { return "Root"; }
    
    
    public handleDeepLink(schema: string, host: string, path: string, params: Map<string, string>): void {
        const getSessionVg = (): (SessionVG | null) => {
            const currentSessionVg = tryCastClass<SessionVG>((this.root.stack[0] ?? null), SessionVG);
            
            if (currentSessionVg !== null) {
                if (this.root.stack.length > 1) {
                    this.root.reset(currentSessionVg!);
                }
                return currentSessionVg;
            }
            const s: (Session | null) = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
            
            if (s !== null) {
                const newSessionVg = new SessionVG(this.dialog, this.root, new SessionApi(s!, ApiOption.Companion.INSTANCE.current, this.root, this.dialog), this.root);
                
                this.root.reset(newSessionVg);
                return newSessionVg;
            }
            return null;
        }
        
        const deepLink = DeepLink.Companion.INSTANCE.parse(schema, host, path, params);
        
        if (deepLink instanceof DeepLink.LogIn){
            xSingleCallDisplayingError<Session>(ApiOption.Companion.INSTANCE.current.refreshSession((deepLink as DeepLink.LogIn).token), undefined, (s: Session): void => {
                this.root.reset(new SessionVG(this.dialog, this.root, new SessionApi(s, ApiOption.Companion.INSTANCE.current, this.root, this.dialog), this.root));
            });
        } else if (deepLink instanceof DeepLink.OpenSchool){
            const sessionVg_3095 = getSessionVg();
            if (sessionVg_3095 !== null) {
                xSingleCallDisplayingError<School>(sessionVg_3095.session.schools.get((deepLink as DeepLink.OpenSchool).id), undefined, (it: School): void => {
                    sessionVg_3095.schoolFilter.value = it;
                });
            }
        } else if (deepLink instanceof DeepLink.OpenThread){
            const sessionVg_3096 = getSessionVg();
            if (sessionVg_3096 !== null) {
                sessionVg_3096.sessionStack.push(new ConversationDetailVG(this.dialog, sessionVg_3096.session, (deepLink as DeepLink.OpenThread).id, undefined, sessionVg_3096.schoolFilter, sessionVg_3096.sessionStack));
            }
        } else if (deepLink instanceof DeepLink.OpenMessage){
            const sessionVg_3097 = getSessionVg();
            if (sessionVg_3097 !== null) {
                xSingleCallDisplayingError<ChatMessage>(sessionVg_3097.session.messages.get((deepLink as DeepLink.OpenMessage).id), undefined, (it: ChatMessage): void => {
                    sessionVg_3097.sessionStack.push(new ConversationDetailVG(this.dialog, sessionVg_3097.session, it.thread, undefined, sessionVg_3097.schoolFilter, sessionVg_3097.sessionStack));
                });
            }
        }
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new RootXml();
        
        const view = xml.setup(dependency);
        
        
        //--- bind to prefs
        deadBuildCheck();
        ApiOption.Companion.INSTANCE.bindToPreferences();
        
        //--- Set Initial View for root
        if (this.root.stack.length === 0) {
            const currentApiVersion = ApiOption.Companion.INSTANCE.choice.value;
            
            if (!safeEq(currentApiVersion, Preferences.INSTANCE.get<any>([null], "api-option-credentials"))) {
                Preferences.INSTANCE.set<ApiOption>([ApiOption], "api-option-credentials", currentApiVersion);
                console.log("Clearing credentials due to API option change");
            }
            const s: (Session | null) = Preferences.INSTANCE.get<Session>([Session], Session.Companion.INSTANCE.key);
            
            if (s !== null) {
                this.root.reset(new SessionVG(this.dialog, this.root, new SessionApi(s!, ApiOption.Companion.INSTANCE.current, this.root, this.dialog), this.root));
            } else {
                this.root.reset(new LandingScreenVG(this.dialog, this.root, this.root));
            }
        }
        
        //--- Set Up xml.content (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.content, dependency, this.root);
        
        //--- Set Up xml.dialogFade
        xViewBindExists(xml.dialogFade, xObservablePropertyMap<Array<ViewGenerator>, boolean>(this.dialog, (it: Array<ViewGenerator>): boolean => it.length !== 0));
        xml.dialogFade.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            /*capture*/
        };
        
        //--- Set Up xml.dialog
        xSwapViewBindStack<ViewGenerator>(xml.dialog, dependency, this.dialog);
        xml.dialog.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            /*capture*/
        };
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public onBackPressed(): boolean {
        return this.dialog.backPressDismiss() || this.root.backPressPop();
    }
    
    //--- Body End
    public get mainStack(): (ObservableStack<ViewGenerator> | null) { return EntryPointDefaults.getMainStack(this); }
    public handleNotificationInForeground(map: Map<string, string>): ForegroundNotificationHandlerResult { return ForegroundNotificationHandlerDefaults.handleNotificationInForeground(this, map); }
}
