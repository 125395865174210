// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/FormAddAlertTypeVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { District } from '../../model/District'
import { SessionApi } from '../../api/SessionApi'
import { FormAddAlertTypeXml } from '../../layout/FormAddAlertTypeXml'
import { xAutoCompleteTextViewBindList } from 'butterfly-web/dist/observables/binding/AutoCompleteTextView.binding'
import { FormAlertTypeVG } from './FormAlertTypeVG'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { ApiFilter } from '../../api/ApiFilter'
import { safeEq } from 'butterfly-web/dist/kotlin/Language'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { listRemoveItem } from 'butterfly-web/dist/KotlinCollections'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { AlertGuidance } from '../../model/AlertGuidance'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { School } from '../../model/School'
import { AlertType } from '../../model/AlertType'

//! Declares com.tresitgroup.android.tresit.vg.admin.FormAddAlertTypeVG
export class FormAddAlertTypeVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly alertType: MutableObservableProperty<AlertType>;
    public readonly guidances: MutableObservableProperty<Array<AlertGuidance>>;
    public readonly selectedRegion: MutableObservableProperty<(District | null)>;
    public readonly selectedSchool: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>;
    public readonly alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>;
    public constructor(dialog: ObservableStack<ViewGenerator>, alertType: MutableObservableProperty<AlertType>, guidances: MutableObservableProperty<Array<AlertGuidance>>, selectedRegion: MutableObservableProperty<(District | null)>, selectedSchool: MutableObservableProperty<(School | null)>, session: SessionApi, editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>, alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>) {
        super();
        this.dialog = dialog;
        this.alertType = alertType;
        this.guidances = guidances;
        this.selectedRegion = selectedRegion;
        this.selectedSchool = selectedSchool;
        this.session = session;
        this.editedAllGroupsObservable = editedAllGroupsObservable;
        this.alreadySelectedGroupObservable = alreadySelectedGroupObservable;
        this.form = new FormAlertTypeVG(this.dialog, this.alertType, this.guidances, this.session, this.editedAllGroupsObservable, this.alreadySelectedGroupObservable, true);
        this.schoolQuery = new StandardObservableProperty<string>("", undefined);
        this.regionQuery = new StandardObservableProperty<string>("", undefined);
        this.selectedGroup = Array.from(this.alreadySelectedGroupObservable.value);
        this.allgroups = Array.from(this.editedAllGroupsObservable.value);
        this.editedAllGroups = Array.from(this.editedAllGroupsObservable.value);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.FormAddAlertTypeVG.title
    public get title(): string { return "Form Add Alert Type"; }
    
    
    public readonly form: FormAlertTypeVG;
    
    public readonly schoolQuery: MutableObservableProperty<string>;
    
    public readonly regionQuery: MutableObservableProperty<string>;
    
    
    public recentUserThreads(school: (School | null)): ObservableProperty<Array<ChatThread>> {
        return xObservablePropertyMap<(Array<ChatThread> | null), Array<ChatThread>>(this.session.threads.observableListSimple(((): (ApiFilter<ChatThread> | null) => {
            const temp3814 = (school?.id ?? null);
            if(temp3814 === null) { return null }
            return ((it: number): ApiFilter<ChatThread> => ApiFilter.Companion.INSTANCE.all<ChatThread>(ChatThread.Companion.INSTANCE.isGroupThread(), ChatThread.Companion.INSTANCE.forSchool(it), ChatThread.Companion.INSTANCE.forAlertType()))(temp3814)
        })() ?? ChatThread.Companion.INSTANCE.isGroupThread(), undefined, undefined, undefined, undefined, undefined), (it: (Array<ChatThread> | null)): Array<ChatThread> => it ?? []);
    }
    public generate(dependency: Window): HTMLElement {
        const xml = new FormAddAlertTypeXml();
        
        const view = xml.setup(dependency);
        
        
        //--- From XMl
        this.generateFromXml(dependency, xml);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public generateFromXml(dependency: Window, xml: FormAddAlertTypeXml): void {
        
        //--- Set Up xml.title (overwritten on flow generation)
        xTextViewBindString(xml.title, new ConstantObservableProperty<string>("Add Alert Type"));
        
        //--- Set Up xml.schoolFilter
        xEditTextBindString(xml.schoolFilter, this.schoolQuery);
        
        xAutoCompleteTextViewBindList<School>(xml.schoolFilter, xObservablePropertyMap<(Array<School> | null), Array<School>>(xObservablePropertyFlatMap<string, (Array<School> | null)>(this.schoolQuery, (it: string): ObservableProperty<(Array<School> | null)> => this.session.allSchools.observableListSimple(School.Companion.INSTANCE.textSearch(it), undefined, undefined, undefined, undefined, undefined)), (it: (Array<School> | null)): Array<School> => it ?? []), (it: School): string => it.name, (it: School): void => {
            this.schoolQuery.value = it.name;
            this.selectedSchool.value = it;
            this.selectedRegion.value = null;
            this.regionQuery.value = "";
            this.form.selectedGroupName.value = "";
            this.form.selectedGroup.length = 0;
            this.fetchData(it);
        });
        
        //--- Set Up xml.region
        xEditTextBindString(xml.region, this.regionQuery);
        xAutoCompleteTextViewBindList<District>(xml.region, xObservablePropertyMap<(Array<District> | null), Array<District>>(xObservablePropertyFlatMap<string, (Array<District> | null)>(this.regionQuery, (it: string): ObservableProperty<(Array<District> | null)> => this.session.districts.observableListSimple(District.Companion.INSTANCE.textSearch(it), undefined, undefined, undefined, undefined, undefined)), (it: (Array<District> | null)): Array<District> => it ?? []), (it: District): string => it.name, (it: District): void => {
            this.regionQuery.value = it.name;
            this.selectedRegion.value = it;
            this.selectedSchool.value = null;
            this.schoolQuery.value = "";
        });
        
        //--- Set Up xml.form.xmlRoot
        //--- Set Up xml.form.alertName
        //--- Set Up xml.form.iconImage
        //--- Set Up xml.form.iconChange
        //--- Set Up xml.form.mp3Change
        //--- Set Up xml.form.mp3Name
        //--- Set Up xml.form.oggChange
        //--- Set Up xml.form.oggName
        //--- Set Up xml.form.guidances
        //--- Make Subview For xml.form.guidances
        //--- Set Up cellXml.root
        //--- Set Up cellXml.title
        //--- Set Up cellXml.body
        //--- Set Up cellXml.remove
        //--- End Make Subview For xml.form.guidances
        //--- Set Up xml.form.newGuidance
        //--- Set Up xml.form.newTitle
        //--- Set Up xml.form.newPlan
        //--- Set Up xml.form.cancelGuidance
        //--- Set Up xml.form.saveGuidance
        //--- Set Up xml.form.addGuidance
        //--- Set Up xml.form.adminApproval
        this.form.generateFromXml(dependency, xml.form);
        
        //--- End from xml
    }
    public selectedGroup: Array<ChatThread>;
    
    
    public allgroups: Array<ChatThread>;
    
    public editedAllGroups: Array<ChatThread>;
    
    
    private fetchData(id: School): void {
        xObservablePropertySubscribeBy<Array<ChatThread>>(this.recentUserThreads(id), undefined, undefined, (it: Array<ChatThread>): void => {
            this.allgroups = Array.from(it);
            this.editedAllGroups = Array.from(it);
            for (const chatThread of it) {
                if (((): (boolean | null) => {
                    const temp3818 = this.alertType.value.groups;
                    if(temp3818 === null) { return null }
                    return temp3818.some((x) => safeEq(chatThread.id, x))
                })() === true) {
                    this.selectedGroup.push(chatThread);
                    
                    const newItem = chatThread.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
                    
                    listRemoveItem(this.editedAllGroups, chatThread);
                    this.editedAllGroups.push(newItem);
                }
            }
            this.editedAllGroupsObservable.value = this.editedAllGroups;
            this.alreadySelectedGroupObservable.value = this.selectedGroup;
        });
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action formIconChangeClick
    //--- Action formMp3ChangeClick
    //--- Action formOggChangeClick
    //--- Action cellXmlRemoveClick
    //--- Action formCancelGuidanceClick
    //--- Action formSaveGuidanceClick
    //--- Action formAddGuidanceClick
    
    //--- Body End
}
