//
// AdminAlertTypesXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/admin_alert_types.html'
import htmlForDefault from './admin_alert_types.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminAlertTypesXml
export class AdminAlertTypesXml {
    xmlRoot!: HTMLElement
    filter!: HTMLInputElement
    alertTypesLoading!: HTMLDivElement
    alertTypes!: HTMLDivElement
    addAlertType!: HTMLButtonElement
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.alertTypesLoading = getViewById<HTMLDivElement>(view, "alertTypesLoading")
        this.alertTypes = getViewById<HTMLDivElement>(view, "alert_types")
        this.addAlertType = getViewById<HTMLButtonElement>(view, "add_alert_type")
        return view
    }
}
