// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/rxHelp.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { FlatMappedObservableProperty } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'

//! Declares com.tresitgroup.android.tresit.util.flatMapNotNull2>com.lightningkite.butterfly.observables.ObservableProperty<kotlin.Any>
export function xObservablePropertyFlatMapNotNull2<T extends any, B extends any>(this_: ObservableProperty<(T | null)>, transformation:  ((a: T) => ObservableProperty<B>)): FlatMappedObservableProperty<(T | null), (B | null)> {
    return new FlatMappedObservableProperty<(T | null), (B | null)>(this_, (item: (T | null)): ObservableProperty<(B | null)> => {
        if (item !== null) { return xObservablePropertyMap<B, (B | null)>(transformation(item!), (it: B): (B | null) => it) } else { return new ConstantObservableProperty<(B | null)>(null) }
    });
}