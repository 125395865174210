//
// ConversationOptionsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './conversation_options.html'
//! Declares com.tresitgroup.android.tresit.layouts.ConversationOptionsXml
export class ConversationOptionsXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    close!: HTMLButtonElement
    participantsLoading!: HTMLDivElement
    participants!: HTMLDivElement
    edit!: HTMLButtonElement
    leavingGroup!: HTMLDivElement
    leaveGroup!: HTMLButtonElement
    _delete!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.close = getViewById<HTMLButtonElement>(view, "close")
        this.participantsLoading = getViewById<HTMLDivElement>(view, "participantsLoading")
        this.participants = getViewById<HTMLDivElement>(view, "participants")
        this.edit = getViewById<HTMLButtonElement>(view, "edit")
        this.leavingGroup = getViewById<HTMLDivElement>(view, "leavingGroup")
        this.leaveGroup = getViewById<HTMLButtonElement>(view, "leaveGroup")
        this._delete = getViewById<HTMLButtonElement>(view, "delete")
        return view
    }
}
