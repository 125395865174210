//
// AdminUserDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FormUserDetailXml } from './FormUserDetailXml'
import htmlForw960dp from '../layout-w960dp/admin_user_detail.html'
import htmlForDefault from './admin_user_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminUserDetailXml
export class AdminUserDetailXml {
    xmlRoot!: HTMLElement
    registeredDevices!: HTMLDivElement
    deviceMac!: HTMLImageElement
    deviceWindows!: HTMLImageElement
    deviceAndroid!: HTMLImageElement
    deviceIOS!: HTMLImageElement
    deviceOther!: HTMLElement
    userHistory!: HTMLButtonElement
    submitWorking!: HTMLDivElement
    cancel!: HTMLButtonElement
    submit!: HTMLButtonElement
    _delete!: HTMLButtonElement
    detailForm!: FormUserDetailXml
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.registeredDevices = getViewById<HTMLDivElement>(view, "registeredDevices")
        this.deviceMac = getViewById<HTMLImageElement>(view, "deviceMac")
        this.deviceWindows = getViewById<HTMLImageElement>(view, "deviceWindows")
        this.deviceAndroid = getViewById<HTMLImageElement>(view, "deviceAndroid")
        this.deviceIOS = getViewById<HTMLImageElement>(view, "deviceIOS")
        this.deviceOther = getViewById<HTMLElement>(view, "deviceOther")
        this.userHistory = getViewById<HTMLButtonElement>(view, "userHistory")
        this.submitWorking = getViewById<HTMLDivElement>(view, "submitWorking")
        this.cancel = getViewById<HTMLButtonElement>(view, "cancel")
        this.submit = getViewById<HTMLButtonElement>(view, "submit")
        this._delete = getViewById<HTMLButtonElement>(view, "delete")
        replaceViewWithId(view, ()=>{ 
                this.detailForm = new FormUserDetailXml()
                return this.detailForm.setup(dependency)
        }, "detailForm")
        return view
    }
}
