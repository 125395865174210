// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/FormAlertTypeDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { District } from '../../model/District'
import { SessionApi } from '../../api/SessionApi'
import { FormAlertTypeVG } from './FormAlertTypeVG'
import { xObservablePropertyObservableNNGet } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { flatMap as rxFlatMap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ObservableInput } from 'rxjs'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ChatThread } from '../../model/ChatThread'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xObservableAsObservableProperty } from 'butterfly-web/dist/observables/EventToObservableProperty'
import { FormAlertTypeDetailXml } from '../../layout/FormAlertTypeDetailXml'
import { AlertGuidance } from '../../model/AlertGuidance'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { setViewVisibility } from 'butterfly-web/dist/views/View.ext'
import { School } from '../../model/School'
import { AlertType } from '../../model/AlertType'

//! Declares com.tresitgroup.android.tresit.vg.admin.FormAlertTypeDetailVG
export class FormAlertTypeDetailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly alertType: MutableObservableProperty<AlertType>;
    public readonly guidances: MutableObservableProperty<Array<AlertGuidance>>;
    public readonly region: (ForeignKey<District> | null);
    public readonly session: SessionApi;
    public readonly editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>;
    public readonly alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>;
    public constructor(dialog: ObservableStack<ViewGenerator>, alertType: MutableObservableProperty<AlertType>, guidances: MutableObservableProperty<Array<AlertGuidance>>, region: (ForeignKey<District> | null), session: SessionApi, editedAllGroupsObservable: MutableObservableProperty<Array<ChatThread>>, alreadySelectedGroupObservable: MutableObservableProperty<Array<ChatThread>>) {
        super();
        this.dialog = dialog;
        this.alertType = alertType;
        this.guidances = guidances;
        this.region = region;
        this.session = session;
        this.editedAllGroupsObservable = editedAllGroupsObservable;
        this.alreadySelectedGroupObservable = alreadySelectedGroupObservable;
        this.form = new FormAlertTypeVG(this.dialog, this.alertType, this.guidances, this.session, this.editedAllGroupsObservable, this.alreadySelectedGroupObservable, undefined);
        this.district = ((): (ObservableProperty<District> | null) => {
            const temp3849 = this.region;
            if(temp3849 === null) { return null }
            return ((it: number): ObservableProperty<District> => xObservableAsObservableProperty<District>(this.session.districts.get(it), new District(undefined, "")))(temp3849)
        })();
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.FormAlertTypeDetailVG.title
    public get title(): string { return "Form Alert Type Detail"; }
    
    
    public readonly form: FormAlertTypeVG;
    
    public readonly district: (ObservableProperty<District> | null);
    
    
    public school(): ObservableProperty<School> {
        return xObservableAsObservableProperty<School>(xObservablePropertyObservableNNGet(this.alertType).pipe(rxFlatMap((it: AlertType): ObservableInput<School> => this.session.schools.get(it.school))), new School(undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new FormAlertTypeDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- From Xml
        this.generateFromXml(dependency, xml);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    public generateFromXml(dependency: Window, xml: FormAlertTypeDetailXml): void {
        
        //--- Shortcuts
        const school = this.school();
        
        
        //--- Set Up xml.title (overwritten on flow generation)
        xTextViewBindString(xml.title, new ConstantObservableProperty<string>("Editing Alert Type"));
        
        //--- Set Up xml.siteSection
        setViewVisibility(xml.siteSection, (this.region === null ? "visible" : "gone"));
        
        //--- Set Up xml.school
        xTextViewBindString(xml.school, xObservablePropertyMap<School, string>(school, (it: School): string => it.name));
        
        //--- Set Up xml.regionSection
        setViewVisibility(xml.regionSection, (this.region !== null ? "visible" : "gone"));
        
        //--- Set Up xml.region
        const it_3859 = this.district;
        if (it_3859 !== null) {
            xTextViewBindString(xml.region, xObservablePropertyMap<District, string>(it_3859, (it: District): string => it.name));
        }
        
        
        //--- Set Up xml.form.xmlRoot
        //--- Set Up xml.form.alertName
        //--- Set Up xml.form.iconImage
        //--- Set Up xml.form.iconChange
        //--- Set Up xml.form.mp3Change
        //--- Set Up xml.form.mp3Name
        //--- Set Up xml.form.oggChange
        //--- Set Up xml.form.oggName
        //--- Set Up xml.form.guidances
        //--- Make Subview For xml.form.guidances
        //--- Set Up cellXml.root
        //--- Set Up cellXml.title
        //--- Set Up cellXml.body
        //--- Set Up cellXml.remove
        //--- End Make Subview For xml.form.guidances
        //--- Set Up xml.form.newGuidance
        //--- Set Up xml.form.newTitle
        //--- Set Up xml.form.newPlan
        //--- Set Up xml.form.cancelGuidance
        //--- Set Up xml.form.saveGuidance
        //--- Set Up xml.form.addGuidance
        //--- Set Up xml.form.adminApproval
        this.form.generateFromXml(dependency, xml.form);
        
        //--- End GenerateFromXml
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    //--- Action formIconChangeClick
    //--- Action formMp3ChangeClick
    //--- Action formOggChangeClick
    //--- Action cellXmlRemoveClick
    //--- Action formCancelGuidanceClick
    //--- Action formSaveGuidanceClick
    //--- Action formAddGuidanceClick
    
    //--- Body End
}
