// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/SvgDelegate.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { SvgNode, XmlNode, xXmlNodeToSvgNode } from '../../svg/SchoolSvgRender'
import { Time } from '../../util/time'
import { RectF } from 'butterfly-web/dist/views/geometry/RectF'
import { hashAnything, parseFloatOrNull, safeEq } from 'butterfly-web/dist/Kotlin'
import { matrixReset, matrixSet } from 'butterfly-web/dist/views/geometry/Matrix'
import { DisplayMetrics } from 'butterfly-web/dist/views/DisplayMetrics'
import { SharingObservableProperty, xObservablePropertyShare } from 'butterfly-web/dist/observables/SharingObservableProperty'
import { xDisposableUntil } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { CustomViewDelegate } from 'butterfly-web/dist/views/CustomViewDelegate'
import { parseXml } from '../../svg/XmlParser'
import { SubscriptionLike } from 'rxjs'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { getAnimationFrame } from 'butterfly-web/dist/delay'
import { applyMatrixToCanvas } from 'butterfly-web/dist/views/draw/Canvas'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { PointF } from 'butterfly-web/dist/views/geometry/PointF'

//! Declares com.tresitgroup.android.tresit.vg.map.SvgDelegate
export class SvgDelegate extends CustomViewDelegate {
    public readonly svgData: ObservableProperty<(string | null)>;
    public readonly selectPaint:  ((a: number) => Array<Paint>);
    public readonly onTap:  ((a: number) => void);
    public constructor(svgData: ObservableProperty<(string | null)>, selectPaint:  ((a: number) => Array<Paint>), onTap:  ((a: number) => void)) {
        super();
        this.svgData = svgData;
        this.selectPaint = selectPaint;
        this.onTap = onTap;
        this.parsedXmlData = xObservablePropertyShare<(XmlNode | null)>(xObservablePropertyMap<(string | null), (XmlNode | null)>(this.svgData, (it: (string | null)): (XmlNode | null) => ((): (XmlNode | null) => {
            const temp4983 = it;
            if(temp4983 === null) { return null }
            return ((it: string): XmlNode => parseXml(it))(temp4983)
        })()), undefined);
        this.bounds = xObservablePropertyShare<(RectF | null)>(xObservablePropertyMap<(XmlNode | null), (RectF | null)>(this.parsedXmlData, (it: (XmlNode | null)): (RectF | null) => ((): (RectF | null) => {
            const temp4985 = ((): (Array<string> | null) => {
                const temp4987 = ((): (string | null) => {
                    const temp4988 = (it?.attributes ?? null);
                    if(temp4988 === null) { return null }
                    return (temp4988.get("viewBox") ?? null)
                })();
                if(temp4987 === null) { return null }
                return temp4987.split(' ')
            })();
            if(temp4985 === null) { return null }
            return ((it: Array<string>): RectF => {
                const x = ((): (number | null) => {
                    const temp4990 = parseFloatOrNull(it[0]);
                    if(temp4990 === null) { return null }
                    return temp4990
                })() ?? 0;
                
                const y = ((): (number | null) => {
                    const temp4992 = parseFloatOrNull(it[1]);
                    if(temp4992 === null) { return null }
                    return temp4992
                })() ?? 0;
                
                const width = ((): (number | null) => {
                    const temp4994 = parseFloatOrNull(it[2]);
                    if(temp4994 === null) { return null }
                    return temp4994
                })() ?? 10;
                
                const height = ((): (number | null) => {
                    const temp4996 = parseFloatOrNull(it[3]);
                    if(temp4996 === null) { return null }
                    return temp4996
                })() ?? 10;
                
                return new RectF(x, y, x + width, y + height);
            })(temp4985)
        })()), undefined);
        this.parsedData = xObservablePropertyShare<(SvgNode | null)>(xObservablePropertyMap<(XmlNode | null), (SvgNode | null)>(this.parsedXmlData, (it: (XmlNode | null)): (SvgNode | null) => ((): (SvgNode | null) => {
            if(it !== null) {
                return xXmlNodeToSvgNode(it)
            } else { return null }
        })()), undefined);
        this.transform = new SvgDelegate.PartTransform(undefined, undefined, undefined);
        this.setScale = false;
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(SvgNode | null)>(this.parsedData, undefined, undefined, (it: (SvgNode | null)): void => {
            this.postInvalidate();
        }), this.removed);
        xDisposableUntil<SubscriptionLike>(getAnimationFrame().subscribe((it: number): void => {
            this.postInvalidate();
        }, undefined, undefined), this.removed);
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(RectF | null)>(this.bounds, undefined, undefined, (it: (RectF | null)): void => {
            if (it !== null) {
                this.transform.centerX = it!.centerX();
                this.transform.centerY = it!.centerY();
                this.setScale = true;
                this.postInvalidate();
            }
        }), this.removed);
        this.drawMatrix = new DOMMatrix();
        this.lastWidth = 0;
        this.lastHeight = 0;
        this.firstTouch = new SvgDelegate.TouchInfo(undefined, undefined, undefined, undefined, undefined);
        this.secondTouch = new SvgDelegate.TouchInfo(undefined, undefined, undefined, undefined, undefined);
        this.preTouchTransform = new SvgDelegate.PartTransform(undefined, undefined, undefined);
        this.couldBeTap = false;
        this.lastTap = Time.INSTANCE.epochMilliseconds();
        this.touchMatrix = new DOMMatrix();
    }
    
    
    public readonly parsedXmlData: SharingObservableProperty<(XmlNode | null)>;
    
    public readonly bounds: SharingObservableProperty<(RectF | null)>;
    
    public readonly parsedData: SharingObservableProperty<(SvgNode | null)>;
    
    
    public generateAccessibilityView(): (HTMLElement | null) {
        return null;
    }
    
    
    
    public readonly transform: SvgDelegate.PartTransform;
    
    public setScale: boolean;
    
    
    
    
    public readonly drawMatrix: DOMMatrix;
    
    public lastWidth: number;
    
    public lastHeight: number;
    
    public draw(canvas: CanvasRenderingContext2D, width: number, height: number, displayMetrics: DisplayMetrics): void {
        this.lastWidth = width;
        this.lastHeight = height;
        const it_5000 = this.bounds.value;
        if (it_5000 !== null) {
            if (this.setScale) {
                this.setScale = false;
                this.transform.scale = Math.min(width / it_5000.width(), height / it_5000.height()) * 0.85;
            }
        }
        this.updateFromTouches();
        canvas.save();
        matrixReset(this.drawMatrix);
        this.transform.applyTo(this.drawMatrix, width, height);
        applyMatrixToCanvas(canvas, this.drawMatrix);
        this.parsedData.value?.render(canvas, this.drawMatrix, (it: SvgNode): Array<Paint> => ((): (Array<Paint> | null) => {
            const temp5008 = it.dataId;
            if(temp5008 === null) { return null }
            return ((it: number): Array<Paint> => this.selectPaint(it))(temp5008)
        })() ?? []);
        canvas.restore();
    }
    
    private updateFromTouches(): void {
        if (this.firstTouch.down) {
            if (this.secondTouch.down) {
                this.transform.set(this.preTouchTransform);
                const downDist: number = Math.sqrt((this.firstTouch.downX - this.secondTouch.downX) * (this.firstTouch.downX - this.secondTouch.downX) + (this.firstTouch.downY - this.secondTouch.downY) * (this.firstTouch.downY - this.secondTouch.downY));
                
                const dist: number = Math.sqrt((this.firstTouch.x - this.secondTouch.x) * (this.firstTouch.x - this.secondTouch.x) + (this.firstTouch.y - this.secondTouch.y) * (this.firstTouch.y - this.secondTouch.y));
                
                const temp5040 = this.transform;
                temp5040.scale = temp5040.scale * dist / downDist;
                const averageDownX = (this.firstTouch.downX + this.secondTouch.downX) / 2;
                
                const averageDownY = (this.firstTouch.downY + this.secondTouch.downY) / 2;
                
                const averageX = (this.firstTouch.x + this.secondTouch.x) / 2;
                
                const averageY = (this.firstTouch.y + this.secondTouch.y) / 2;
                
                const temp5055 = this.transform;
                temp5055.centerX = temp5055.centerX - (averageX - averageDownX) / this.transform.scale;
                const temp5060 = this.transform;
                temp5060.centerY = temp5060.centerY - (averageY - averageDownY) / this.transform.scale;
            } else {
                this.transform.set(this.preTouchTransform);
                const temp5065 = this.transform;
                temp5065.centerX = temp5065.centerX - (this.firstTouch.x - this.firstTouch.downX) / this.transform.scale;
                const temp5072 = this.transform;
                temp5072.centerY = temp5072.centerY - (this.firstTouch.y - this.firstTouch.downY) / this.transform.scale;
            }
        }
    }
    
    
    
    public firstTouch: SvgDelegate.TouchInfo;
    
    public secondTouch: SvgDelegate.TouchInfo;
    
    public readonly preTouchTransform: SvgDelegate.PartTransform;
    
    
    public commit(): void {
        this.preTouchTransform.set(this.transform);
        this.firstTouch.commit();
        this.secondTouch.commit();
    }
    
    public couldBeTap: boolean;
    
    public onTouchDown(id: number, x: number, y: number, width: number, height: number): boolean {
        if (this.firstTouch.id === (-1)) {
            this.preTouchTransform.set(this.transform);
            this.firstTouch.id = id;
            this.firstTouch.downX = x;
            this.firstTouch.x = x;
            this.firstTouch.downY = y;
            this.firstTouch.y = y;
            this.couldBeTap = true;
        } else if (this.secondTouch.id === (-1)) {
            this.commit();
            this.secondTouch.id = id;
            this.secondTouch.downX = x;
            this.secondTouch.x = x;
            this.secondTouch.downY = y;
            this.secondTouch.y = y;
            this.couldBeTap = false;
        }
        return true;
    }
    
    public onTouchCancelled(id: number, x: number, y: number, width: number, height: number): boolean {
        return this.onTouchUp(id, x, y, width, height);
    }
    
    public onTouchMove(id: number, x: number, y: number, width: number, height: number): boolean {
        if (this.firstTouch.id === id) {
            this.firstTouch.x = x;
            this.firstTouch.y = y;
        }
        if (this.secondTouch.id === id) {
            this.secondTouch.x = x;
            this.secondTouch.y = y;
        }
        if (this.firstTouch.down && Math.abs(x - this.firstTouch.downX) + Math.abs(y - this.firstTouch.downY) > (width / 100)) {
            this.couldBeTap = false;
        }
        if (this.couldBeTap) {
            return true;
        }
        this.postInvalidate();
        return true;
    }
    
    public readonly lastTap: number;
    
    public readonly touchMatrix: DOMMatrix;
    
    public onTouchUp(id: number, x: number, y: number, width: number, height: number): boolean {
        this.commit();
        if (this.firstTouch.id === id) {
            this.firstTouch.id = (-1);
        }
        if (this.secondTouch.id === id) {
            this.secondTouch.id = (-1);
        }
        if (this.couldBeTap) {
            const now = Time.INSTANCE.epochMilliseconds();
            
            if (now - this.lastTap > 1000) {
                matrixReset(this.drawMatrix);
                this.transform.applyTo(this.drawMatrix, width, height);
                matrixSet(this.touchMatrix, this.drawMatrix.inverse());
                const it_5100 = (this.parsedData.value?.hit(new PointF(this.touchMatrix.transformPoint(new PointF(x, y))))?.dataId ?? null);
                if (it_5100 !== null) {
                    this.onTap(it_5100);
                }
                this.postInvalidate();
            }
        } else {
            this.triggerYankBack(width, height);
        }
        return true;
    }
    
    public triggerYankBack(width: number, height: number): void {
        
        //yank us back, brother
        const bounds_5103 = this.bounds.value;
        if (bounds_5103 !== null) {
            const coercedScale = xFloatCoerceInSafe(this.transform.scale, Math.min(width / bounds_5103.width(), height / bounds_5103.height()) * 0.5, 100);
            
            const coercedX = xFloatCoerceInSafe(this.transform.centerX, bounds_5103.left + width / 3 / coercedScale, bounds_5103.right - width / 3 / coercedScale);
            
            const coercedY = xFloatCoerceInSafe(this.transform.centerY, bounds_5103.top + height / 3 / coercedScale, bounds_5103.bottom - height / 3 / coercedScale);
            
            if (!(this.transform.centerX === coercedX) || !(this.transform.centerY === coercedY) || !(this.transform.scale === coercedScale)) {
                let sub: (SubscriptionLike | null) = null;
                
                sub = getAnimationFrame().subscribe((it: number): void => {
                    if (this.firstTouch.down) { const temp5126 = sub;
                        if(temp5126 !== null) {
                            temp5126.unsubscribe()
                    } }
                    this.transform.centerX = this.transform.centerX + (coercedX - this.transform.centerX) * 0.5;
                    this.transform.centerY = this.transform.centerY + (coercedY - this.transform.centerY) * 0.5;
                    this.transform.scale = this.transform.scale + (coercedScale - this.transform.scale) * 0.5;
                    if (Math.abs(coercedX - this.transform.centerX) < 5 &&
                        Math.abs(coercedY - this.transform.centerY) < 5 &&
                    Math.abs(coercedScale - this.transform.scale) < 0.01) {
                        const temp5148 = sub;
                        if(temp5148 !== null) {
                            temp5148.unsubscribe()
                        };
                    }
                    this.postInvalidate();
                }, undefined, undefined);
                this.removed.call(sub!!);
            }
        }
    }
    
    public onWheel(delta: number): boolean {
        if (delta > 0) {
            const temp5149 = this.transform;
            temp5149.scale = temp5149.scale * 0.9;
        } else {
            const temp5151 = this.transform;
            temp5151.scale = temp5151.scale * 1.1;
        }
        this.triggerYankBack(this.lastWidth, this.lastHeight);
        this.postInvalidate();
        return true;
    }
    
    public zoom(timesAmount: number): void {
        this.transform.scale = this.transform.scale * timesAmount;
        this.triggerYankBack(this.lastWidth, this.lastHeight);
        this.postInvalidate();
    }
}
export namespace SvgDelegate {
    //! Declares com.tresitgroup.android.tresit.vg.map.SvgDelegate.PartTransform
    export class PartTransform {
        public centerX: number;
        public centerY: number;
        public scale: number;
        public constructor(centerX: number = 0, centerY: number = 0, scale: number = 1) {
            this.centerX = centerX;
            this.centerY = centerY;
            this.scale = scale;
        }
        public hashCode(): number {
            let hash = 17;
            hash = 31 * hash + hashAnything(this.centerX);
            hash = 31 * hash + hashAnything(this.centerY);
            hash = 31 * hash + hashAnything(this.scale);
            return hash;
        }
        public equals(other: any): boolean { return other instanceof PartTransform && safeEq(this.centerX, other.centerX) && safeEq(this.centerY, other.centerY) && safeEq(this.scale, other.scale) }
        public toString(): string { return `PartTransform(centerX=${this.centerX}, centerY=${this.centerY}, scale=${this.scale})` }
        public copy(centerX: number = this.centerX, centerY: number = this.centerY, scale: number = this.scale): PartTransform { return new PartTransform(centerX, centerY, scale); }
        
        public set(other: PartTransform): void {
            this.centerX = other.centerX;
            this.centerY = other.centerY;
            this.scale = other.scale;
        }
        
        public applyTo(matrix: DOMMatrix, width: number, height: number): void {
            matrix.translateSelf(width / 2, height / 2);
            matrix.scaleSelf(this.scale, this.scale);
            matrix.translateSelf((-width) / 2, (-height) / 2);
            matrix.translateSelf(width / 2 - this.centerX, height / 2 - this.centerY);
        }
    }
}
export namespace SvgDelegate {
    //! Declares com.tresitgroup.android.tresit.vg.map.SvgDelegate.TouchInfo
    export class TouchInfo {
        public downX: number;
        public downY: number;
        public x: number;
        public y: number;
        public id: number;
        public constructor(downX: number = 0, downY: number = 0, x: number = 0, y: number = 0, id: number = (-1)) {
            this.downX = downX;
            this.downY = downY;
            this.x = x;
            this.y = y;
            this.id = id;
        }
        
        //! Declares com.tresitgroup.android.tresit.vg.map.SvgDelegate.TouchInfo.down
        public get down(): boolean { return !(this.id === (-1)); }
        
        public commit(): void {
            this.downX = this.x;
            this.downY = this.y;
        }
    }
}

function xFloatCoerceInSafe(this_: number, low: number, high: number): number {
    if (high <= low) {
        return (low + high) / 2;
    } else {
        return Math.min(Math.max(this_, low), high);
    }
}
