// File: vg/PinAuth.kt
// Package: com.tresitgroup.android.tresit.vg
import { R } from '../R'

//! Declares com.tresitgroup.android.tresit.vg.hasPhoneLock>com.lightningkite.butterfly.android.ActivityAccess
export function xActivityAccessHasPhoneLock(this_: Window): boolean {
    return false
}

//! Declares com.tresitgroup.android.tresit.vg.showAuthPrompt>com.lightningkite.butterfly.android.ActivityAccess
export function xActivityAccessShowAuthPrompt(this_: Window, onResult: ((a: boolean) => void)) {
    onResult(true)
}
