//
// ContactsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './contacts.html'
//! Declares com.tresitgroup.android.tresit.layouts.ContactsXml
export class ContactsXml {
    xmlRoot!: HTMLElement
    filter!: HTMLInputElement
    loadingList!: HTMLDivElement
    list!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.filter = getViewById<HTMLInputElement>(view, "filter")
        this.loadingList = getViewById<HTMLDivElement>(view, "loadingList")
        this.list = getViewById<HTMLDivElement>(view, "list")
        return view
    }
}
