// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/CacheNode.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { Observable, Subject, concat as rxConcat, of as rxOf } from 'rxjs'
import { Time } from '../util/time'
import { map as rxMap } from 'rxjs/operators'

//! Declares com.tresitgroup.android.tresit.api.CacheNode
export class CacheNode<T extends HasId> extends ObservableProperty<(T | null)> {
    public lastUpdated: number;
    public constructor(lastUpdated: number = 0) {
        super();
        this.lastUpdated = lastUpdated;
        this.value = null;
        this.onChangeSubject = new Subject();
        this.outboundRequest = null;
    }
    
    public value: (T | null);
    
    public readonly onChangeSubject: Subject<(T | null)>;
    
    //! Declares com.tresitgroup.android.tresit.api.CacheNode.onChange
    public get onChange(): Observable<(T | null)> { return this.onChangeSubject; }
    
    
    //! Declares com.tresitgroup.android.tresit.api.CacheNode.rx
    public get rx(): Observable<T> {
        const value = this.value;
        
        if (value !== null) {
            return rxConcat(rxOf(value), this.onChange.pipe(rxMap((it: (T | null)): T => it!!)));
        } else {
            return this.onChange.pipe(rxMap((it: (T | null)): T => it!!));
        }
    }
    
    
    public outboundRequest: (Observable<T> | null);
    
    public touch(time: number = Time.INSTANCE.epochMilliseconds()): void {
        this.lastUpdated = time;
    }
    public update(value: (T | null)): void {
        this.value = value;
        this.onChangeSubject.next(value);
    }
    public clear(): void {
        this.lastUpdated = 0;
        this.value = null;
        this.onChangeSubject.next(null);
    }
}