// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/board/PostCompleteVG.kt
// Package: com.tresitgroup.android.tresit.vg.board
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { BoardPost } from '../../model/BoardPost'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { PostCompleteXml } from '../../layout/PostCompleteXml'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { CacheNode } from '../../api/CacheNode'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'

//! Declares com.tresitgroup.android.tresit.vg.board.PostCompleteVG
export class PostCompleteVG extends ViewGenerator {
    public readonly postId: ForeignKey<BoardPost>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(postId: ForeignKey<BoardPost>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.postId = postId;
        this.session = session;
        this.stack = stack;
        this.working = new StandardObservableProperty<boolean>(false, undefined);
        this.reason = new StandardObservableProperty<string>("", undefined);
        this.observable = this.session.posts.observable(this.postId);
    }
    
    
    
    public readonly working: StandardObservableProperty<boolean>;
    
    public readonly reason: MutableObservableProperty<string>;
    
    public readonly observable: CacheNode<BoardPost>;
    
    
    public person(): ObservableProperty<(User | null)> {
        return xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<(BoardPost | null), (number | null)>(this.observable, (it: (BoardPost | null)): (number | null) => (it?.resolvedBy ?? null) ?? (it?.author ?? null)), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it));
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.board.PostCompleteVG.title
    public get title(): string { return "Post Complete"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PostCompleteXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.close (overwritten on flow generation)
        xViewOnClick(xml.close, undefined, (): void => {
            this.closeClick();
        });
        
        //--- Conveniences
        const person = this.person();
        
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<(BoardPost | null), string>(this.observable, (it: (BoardPost | null)): string => (it?.title ?? null) ?? ""));
        
        //--- Set Up xml.personType
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(BoardPost | null)>(this.observable, undefined, undefined, (it: (BoardPost | null)): void => {
            if ((it?.resolvedBy ?? null) !== null) { setViewText(xml.personType, R._string.found_by) } else { setViewText(xml.personType, R._string.submitted_by) }
        }), xViewRemovedGet(xml.personType));
        
        //--- Set Up xml.name
        xTextViewBindString(xml.name, xObservablePropertyMap<(User | null), string>(person, (it: (User | null)): string => {
            if (it !== null) {
                return `${it!.firstName} ${it!.lastName}`;
            } else {
                return "";
            }
        }));
        
        //--- Set Up xml.time
        xTextViewBindString(xml.time, xObservablePropertyMap<(Date | null), string>(xObservablePropertyMap<(BoardPost | null), (Date | null)>(this.observable, (it: (BoardPost | null)): (Date | null) => (it?.resolvedAt ?? null) ?? (it?.created ?? null)), (it: (Date | null)): string => ((): (string | null) => {
            if(it !== null) {
                return xDateFormat(it, ClockPartSize.Medium, ClockPartSize.Short)
            } else { return null }
        })() ?? "-"));
        
        
        //--- Set Up xml.postDetails
        xViewBindExists(xml.postDetails, xObservablePropertyMap<(BoardPost | null), boolean>(this.observable, (it: (BoardPost | null)): boolean => ((): (boolean | null) => {
            const temp4168 = (it?.content ?? null);
            if(temp4168 === null) { return null }
            return temp4168 !== ""
        })() === true));
        xTextViewBindString(xml.postDetails, xObservablePropertyMap<(BoardPost | null), string>(this.observable, (it: (BoardPost | null)): string => (it?.content ?? null) ?? ""));
        
        //--- Set Up xml.reason
        xEditTextBindString(xml.reason, this.reason);
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.completeWorking
        xViewFlipperBindLoading(xml.completeWorking, this.working, undefined);
        
        //--- Set Up xml.complete (overwritten on flow generation)
        xViewOnClick(xml.complete, undefined, (): void => {
            this.completeClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeClick(): void {
        this.stack.dismiss();
    }
    public cancelClick(): void {
        this.stack.dismiss();
    }
    public completeClick(): void {
        let reason = this.reason.value;
        
        if (reason === "") {
            reason = "Found";
        }
        xSingleCallDisplayingError<BoardPost>(xSingleWorking<BoardPost>(this.session.posts.patch(this.postId, [BoardPost.Companion.INSTANCE.resolve(this.session.session.userId, reason)]), this.working), undefined, (it: BoardPost): void => {
            this.stack.dismiss();
        });
    }
    
    //--- Body End
}
