// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/OffsiteResponse.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.OffsiteResponse
export class OffsiteResponse {
    public readonly room_id: number;
    public constructor(room_id: number) {
        this.room_id = room_id;
    }
    public static fromJson(obj: any): OffsiteResponse { return new OffsiteResponse(
        parseJsonTyped(obj["room_id"], [Number]) as number
    ) }
    public toJSON(): object { return {
        room_id: this.room_id
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.room_id);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof OffsiteResponse && safeEq(this.room_id, other.room_id) }
    public toString(): string { return `OffsiteResponse(room_id=${this.room_id})` }
    public copy(room_id: number = this.room_id): OffsiteResponse { return new OffsiteResponse(room_id); }
}