//
// LayoutMoreSettingXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './layout_more_setting.html'
//! Declares com.tresitgroup.android.tresit.layouts.LayoutMoreSettingXml
export class LayoutMoreSettingXml {
    xmlRoot!: HTMLElement
    schoolLoading!: HTMLDivElement
    schools!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.schoolLoading = getViewById<HTMLDivElement>(view, "schoolLoading")
        this.schools = getViewById<HTMLDivElement>(view, "schools")
        return view
    }
}
