// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/AdminApprovalListResponse.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.AdminApprovalListResponse
export class AdminApprovalListResponse implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly schoolId: (number | null);
    public readonly alertType: (string | null);
    public readonly schoolName: (string | null);
    public readonly initiatorName: (string | null);
    public readonly description: (string | null);
    public readonly initiator: (number | null);
    public readonly cellNumber: (string | null);
    public constructor(id: number = getNO_ID(), schoolId: (number | null) = 0, alertType: (string | null) = null, schoolName: (string | null) = null, initiatorName: (string | null) = null, description: (string | null) = null, initiator: (number | null) = 0, cellNumber: (string | null) = null) {
        this.id = id;
        this.schoolId = schoolId;
        this.alertType = alertType;
        this.schoolName = schoolName;
        this.initiatorName = initiatorName;
        this.description = description;
        this.initiator = initiator;
        this.cellNumber = cellNumber;
    }
    public static fromJson(obj: any): AdminApprovalListResponse { return new AdminApprovalListResponse(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["school"], [Number]) as (number | null),
        parseJsonTyped(obj["alert_type"], [String]) as (string | null),
        parseJsonTyped(obj["school_name"], [String]) as (string | null),
        parseJsonTyped(obj["initiator_name"], [String]) as (string | null),
        parseJsonTyped(obj["description"], [String]) as (string | null),
        parseJsonTyped(obj["initiator"], [Number]) as (number | null),
        parseJsonTyped(obj["initiator_cell_number"], [String]) as (string | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        school: this.schoolId,
        alert_type: this.alertType,
        school_name: this.schoolName,
        initiator_name: this.initiatorName,
        description: this.description,
        initiator: this.initiator,
        initiator_cell_number: this.cellNumber
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.schoolId);
        hash = 31 * hash + hashAnything(this.alertType);
        hash = 31 * hash + hashAnything(this.schoolName);
        hash = 31 * hash + hashAnything(this.initiatorName);
        hash = 31 * hash + hashAnything(this.description);
        hash = 31 * hash + hashAnything(this.initiator);
        hash = 31 * hash + hashAnything(this.cellNumber);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof AdminApprovalListResponse && safeEq(this.id, other.id) && safeEq(this.schoolId, other.schoolId) && safeEq(this.alertType, other.alertType) && safeEq(this.schoolName, other.schoolName) && safeEq(this.initiatorName, other.initiatorName) && safeEq(this.description, other.description) && safeEq(this.initiator, other.initiator) && safeEq(this.cellNumber, other.cellNumber) }
    public toString(): string { return `AdminApprovalListResponse(id=${this.id}, schoolId=${this.schoolId}, alertType=${this.alertType}, schoolName=${this.schoolName}, initiatorName=${this.initiatorName}, description=${this.description}, initiator=${this.initiator}, cellNumber=${this.cellNumber})` }
    public copy(id: number = this.id, schoolId: (number | null) = this.schoolId, alertType: (string | null) = this.alertType, schoolName: (string | null) = this.schoolName, initiatorName: (string | null) = this.initiatorName, description: (string | null) = this.description, initiator: (number | null) = this.initiator, cellNumber: (string | null) = this.cellNumber): AdminApprovalListResponse { return new AdminApprovalListResponse(id, schoolId, alertType, schoolName, initiatorName, description, initiator, cellNumber); }
}
