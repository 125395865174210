import { DrawableResource } from 'butterfly-web/dist/views/DrawableResource'
//! Declares com.tresitgroup.android.tresit.R
export namespace R {
    export namespace color {
        export const alertDark: string = "var(--color-alert-dark)";
        export const alertDarkish: string = "var(--color-alert-darkish)";
        export const alertLight: string = "var(--color-alert-light)";
        export const alertMedium: string = "var(--color-alert-medium)";
        export const background: string = "var(--color-background)";
        export const backgroundFade: string = "var(--color-background-fade)";
        export const backgroundFadeish: string = "var(--color-background-fadeish)";
        export const backgroundLogin: string = "var(--color-background-login)";
        export const backgroundRadial: string = "var(--color-background-radial)";
        export const backgroundSquare: string = "var(--color-background-square)";
        export const barBackground: string = "var(--color-bar-background)";
        export const barBackgroundAlert: string = "var(--color-bar-background-alert)";
        export const barBackgroundAlertFade: string = "var(--color-bar-background-alert-fade)";
        export const barBackgroundAlertFade2: string = "var(--color-bar-background-alert-fade2)";
        export const barBackgroundDrill: string = "var(--color-bar-background-drill)";
        export const barBackgroundDrillFade: string = "var(--color-bar-background-drill-fade)";
        export const barBackgroundDrillFade2: string = "var(--color-bar-background-drill-fade2)";
        export const barBackgroundFade: string = "var(--color-bar-background-fade)";
        export const barBackgroundFade2: string = "var(--color-bar-background-fade2)";
        export const barForeground: string = "var(--color-bar-foreground)";
        export const barForegroundFade: string = "var(--color-bar-foreground-fade)";
        export const building: string = "var(--color-building)";
        export const buildingBranch: string = "var(--color-building-branch)";
        export const buildingBranchDark: string = "var(--color-building-branch-dark)";
        export const buildingDark: string = "var(--color-building-dark)";
        export const campus: string = "var(--color-campus)";
        export const cleared: string = "var(--color-cleared)";
        export const clearedDark: string = "var(--color-cleared-dark)";
        export const clearedLight: string = "var(--color-cleared-light)";
        export const clearedMap: string = "var(--color-cleared-map)";
        export const clearedMapDark: string = "var(--color-cleared-map-dark)";
        export const colorPrimary: string = "var(--color-color-primary)";
        export const colorPrimaryDark: string = "var(--color-color-primary-dark)";
        export const colorPrimaryDarkish: string = "var(--color-color-primary-darkish)";
        export const colorPrimaryDivider: string = "var(--color-color-primary-divider)";
        export const colorPrimaryLight: string = "var(--color-color-primary-light)";
        export const demoYellow: string = "var(--color-demo-yellow)";
        export const dialogBack: string = "var(--color-dialog-back)";
        export const dialogFade: string = "var(--color-dialog-fade)";
        export const drill: string = "var(--color-drill)";
        export const drillDark: string = "var(--color-drill-dark)";
        export const drillDarker: string = "var(--color-drill-darker)";
        export const drillDarkish: string = "var(--color-drill-darkish)";
        export const foreground: string = "var(--color-foreground)";
        export const foregroundFade: string = "var(--color-foreground-fade)";
        export const googleMapCard: string = "var(--color-google-map-card)";
        export const googleMapCardBack: string = "var(--color-google-map-card-back)";
        export const googleMapCardSelected: string = "var(--color-google-map-card-selected)";
        export const googleMapText: string = "var(--color-google-map-text)";
        export const gray: string = "var(--color-gray)";
        export const highlight: string = "var(--color-highlight)";
        export const hintColor: string = "var(--color-hint-color)";
        export const lightBackground: string = "var(--color-light-background)";
        export const lightBackgroundFade: string = "var(--color-light-background-fade)";
        export const lightBackgroundFadeish: string = "var(--color-light-background-fadeish)";
        export const lightForeground: string = "var(--color-light-foreground)";
        export const lightForegroundFade: string = "var(--color-light-foreground-fade)";
        export const medical: string = "var(--color-medical)";
        export const messageSent: string = "var(--color-message-sent)";
        export const mobileBackground: string = "var(--color-mobile-background)";
        export const none: string = "var(--color-none)";
        export const red: string = "var(--color-red)";
        export const redDark: string = "var(--color-red-dark)";
        export const redLight: string = "var(--color-red-light)";
        export const reset: string = "var(--color-reset)";
        export const resetDark: string = "var(--color-reset-dark)";
        export const resetLight: string = "var(--color-reset-light)";
        export const resetMap: string = "var(--color-reset-map)";
        export const resetMapDark: string = "var(--color-reset-map-dark)";
        export const room: string = "var(--color-room)";
        export const roomDark: string = "var(--color-room-dark)";
        export const safe: string = "var(--color-safe)";
        export const safeDark: string = "var(--color-safe-dark)";
        export const safeLight: string = "var(--color-safe-light)";
        export const safeMap: string = "var(--color-safe-map)";
        export const safeMapDark: string = "var(--color-safe-map-dark)";
        export const status_switch: string = "var(-color-status-switch)";
        export const tab_foreground: string = "var(-color-tab-foreground)";
        export const textForeground: string = "var(--color-text-foreground)";
        export const unknown: string = "var(--color-unknown)";
        export const unknownDark: string = "var(--color-unknown-dark)";
        export const unknownLight: string = "var(--color-unknown-light)";
        export const unknownMap: string = "var(--color-unknown-map)";
        export const unknownMapDark: string = "var(--color-unknown-map-dark)";
        export const unsafe: string = "var(--color-unsafe)";
        export const unsafeDark: string = "var(--color-unsafe-dark)";
        export const unsafeLight: string = "var(--color-unsafe-light)";
        export const unsafeMap: string = "var(--color-unsafe-map)";
        export const unsafeMapDark: string = "var(--color-unsafe-map-dark)";
    }
    export namespace drawable {
        export const admin_dashboard_alert_history: DrawableResource = new DrawableResource("admin-dashboard-alert-history", "./images/admin-dashboard-alert-history.png");
        export const admin_dashboard_alert_types: DrawableResource = new DrawableResource("admin-dashboard-alert-types", "./images/admin-dashboard-alert-types.png");
        export const admin_dashboard_chat_threads: DrawableResource = new DrawableResource("admin-dashboard-chat-threads", "./images/admin-dashboard-chat-threads.png");
        export const admin_dashboard_users: DrawableResource = new DrawableResource("admin-dashboard-users", "./images/admin-dashboard-users.png");
        export const alert_in_progress: DrawableResource = new DrawableResource("drawable-alert-in-progress", "./images/alert-in-progress.svg");
        export const all_clear: DrawableResource = new DrawableResource("drawable-all-clear", "./images/all-clear.svg");
        export const app_background: DrawableResource = new DrawableResource("drawable-app-background");
        export const back_to_white: DrawableResource = new DrawableResource("drawable-back-to-white");
        export const bg_safe_medical_need: DrawableResource = new DrawableResource("drawable-bg-safe-medical-need");
        export const bg_unknown: DrawableResource = new DrawableResource("drawable-bg-unknown");
        export const bg_unsafe_medical_need: DrawableResource = new DrawableResource("drawable-bg-unsafe-medical-need");
        export const border: DrawableResource = new DrawableResource("drawable-border");
        export const button_background: DrawableResource = new DrawableResource("drawable-button-background");
        export const button_background_circle: DrawableResource = new DrawableResource("drawable-button-background-circle");
        export const button_background_disabled: DrawableResource = new DrawableResource("drawable-button-background-disabled");
        export const button_background_gray: DrawableResource = new DrawableResource("drawable-button-background-gray");
        export const button_background_light: DrawableResource = new DrawableResource("drawable-button-background-light");
        export const button_clever: DrawableResource = new DrawableResource("button-clever", "./images/button-clever.png");
        export const card: DrawableResource = new DrawableResource("drawable-card");
        export const card_alert: DrawableResource = new DrawableResource("drawable-card-alert");
        export const card_blue: DrawableResource = new DrawableResource("drawable-card-blue");
        export const card_gray: DrawableResource = new DrawableResource("drawable-card-gray");
        export const card_light: DrawableResource = new DrawableResource("drawable-card-light");
        export const card_message_sent: DrawableResource = new DrawableResource("drawable-card-message-sent");
        export const card_yellow: DrawableResource = new DrawableResource("drawable-card-yellow");
        export const cleared_button_background: DrawableResource = new DrawableResource("drawable-cleared-button-background");
        export const cleared_button_background_off: DrawableResource = new DrawableResource("drawable-cleared-button-background-off");
        export const collapse_button_background: DrawableResource = new DrawableResource("drawable-collapse-button-background");
        export const collapse_button_background_alert: DrawableResource = new DrawableResource("drawable-collapse-button-background-alert");
        export const collapse_button_background_drill: DrawableResource = new DrawableResource("drawable-collapse-button-background-drill");
        export const conversation_selected_indicator: DrawableResource = new DrawableResource("drawable-conversation-selected-indicator", "./images/conversation-selected-indicator.svg");
        export const cursor_drawable: DrawableResource = new DrawableResource("drawable-cursor-drawable");
        export const destructive_button_background: DrawableResource = new DrawableResource("drawable-destructive-button-background");
        export const device_android_light: DrawableResource = new DrawableResource("drawable-device-android-light", "./images/device-android-light.svg");
        export const device_apple_light: DrawableResource = new DrawableResource("drawable-device-apple-light", "./images/device-apple-light.svg");
        export const device_ios_light: DrawableResource = new DrawableResource("drawable-device-ios-light", "./images/device-ios-light.svg");
        export const device_mac_light: DrawableResource = new DrawableResource("drawable-device-mac-light", "./images/device-mac-light.svg");
        export const device_macos_light: DrawableResource = new DrawableResource("drawable-device-macos-light", "./images/device-macos-light.svg");
        export const device_windows_light: DrawableResource = new DrawableResource("drawable-device-windows-light", "./images/device-windows-light.svg");
        export const dialog_background: DrawableResource = new DrawableResource("drawable-dialog-background");
        export const dirs_cross: DrawableResource = new DrawableResource("drawable-dirs-cross", "./images/dirs-cross.svg");
        export const dirs_cross_primary: DrawableResource = new DrawableResource("drawable-dirs-cross-primary", "./images/dirs-cross-primary.svg");
        export const dirs_cross_tresit: DrawableResource = new DrawableResource("drawable-dirs-cross-tresit", "./images/dirs-cross-tresit.svg");
        export const drill_in_progress: DrawableResource = new DrawableResource("drawable-drill-in-progress", "./images/drill-in-progress.svg");
        export const fancy_login_background: DrawableResource = new DrawableResource("drawable-fancy-login-background");
        export const field_back: DrawableResource = new DrawableResource("drawable-field-back");
        export const field_back_bar: DrawableResource = new DrawableResource("drawable-field-back-bar");
        export const field_back_disabled: DrawableResource = new DrawableResource("drawable-field-back-disabled");
        export const field_back_yellow: DrawableResource = new DrawableResource("drawable-field-back-yellow");
        export const floor_button_background: DrawableResource = new DrawableResource("drawable-floor-button-background");
        export const green_check: DrawableResource = new DrawableResource("drawable-green-check", "./images/green-check.svg");
        export const green_check_empty: DrawableResource = new DrawableResource("drawable-green-check-empty", "./images/green-check-empty.svg");
        export const ic_add: DrawableResource = new DrawableResource("drawable-ic-add", "./images/ic-add.svg");
        export const ic_add_light: DrawableResource = new DrawableResource("drawable-ic-add-light", "./images/ic-add-light.svg");
        export const ic_arctest: DrawableResource = new DrawableResource("drawable-ic-arctest", "./images/ic-arctest.svg");
        export const ic_arrow_back: DrawableResource = new DrawableResource("drawable-ic-arrow-back", "./images/ic-arrow-back.svg");
        export const ic_arrow_back_bar: DrawableResource = new DrawableResource("drawable-ic-arrow-back-bar", "./images/ic-arrow-back-bar.svg");
        export const ic_arrow_drop_down: DrawableResource = new DrawableResource("drawable-ic-arrow-drop-down", "./images/ic-arrow-drop-down.svg");
        export const ic_arrow_drop_down_bar: DrawableResource = new DrawableResource("drawable-ic-arrow-drop-down-bar", "./images/ic-arrow-drop-down-bar.svg");
        export const ic_arrow_drop_up: DrawableResource = new DrawableResource("drawable-ic-arrow-drop-up", "./images/ic-arrow-drop-up.svg");
        export const ic_arrow_drop_up_bar: DrawableResource = new DrawableResource("drawable-ic-arrow-drop-up-bar", "./images/ic-arrow-drop-up-bar.svg");
        export const ic_attachment: DrawableResource = new DrawableResource("drawable-ic-attachment", "./images/ic-attachment.svg");
        export const ic_baseline_keyboard_arrow_down_16: DrawableResource = new DrawableResource("drawable-ic-baseline-keyboard-arrow-down-16", "./images/ic-baseline-keyboard-arrow-down-16.svg");
        export const ic_baseline_keyboard_arrow_up_16: DrawableResource = new DrawableResource("drawable-ic-baseline-keyboard-arrow-up-16", "./images/ic-baseline-keyboard-arrow-up-16.svg");
        export const ic_baseline_my_location: DrawableResource = new DrawableResource("drawable-ic-baseline-my-location", "./images/ic-baseline-my-location.svg");
        export const ic_baseline_portable_wifi_off: DrawableResource = new DrawableResource("drawable-ic-baseline-portable-wifi-off", "./images/ic-baseline-portable-wifi-off.svg");
        export const ic_baseline_portable_wifi_on: DrawableResource = new DrawableResource("drawable-ic-baseline-portable-wifi-on", "./images/ic-baseline-portable-wifi-on.svg");
        export const ic_blue_flag: DrawableResource = new DrawableResource("ic-blue-flag", "./images/ic-blue-flag.png");
        export const ic_call: DrawableResource = new DrawableResource("drawable-ic-call", "./images/ic-call.svg");
        export const ic_cancel: DrawableResource = new DrawableResource("drawable-ic-cancel", "./images/ic-cancel.svg");
        export const ic_chat: DrawableResource = new DrawableResource("drawable-ic-chat", "./images/ic-chat.svg");
        export const ic_chat_green: DrawableResource = new DrawableResource("drawable-ic-chat-green", "./images/ic-chat-green.svg");
        export const ic_chat_red: DrawableResource = new DrawableResource("drawable-ic-chat-red", "./images/ic-chat-red.svg");
        export const ic_chat_white: DrawableResource = new DrawableResource("drawable-ic-chat-white", "./images/ic-chat-white.svg");
        export const ic_check: DrawableResource = new DrawableResource("drawable-ic-check", "./images/ic-check.svg");
        export const ic_check_circle: DrawableResource = new DrawableResource("drawable-ic-check-circle", "./images/ic-check-circle.svg");
        export const ic_chevron_left: DrawableResource = new DrawableResource("drawable-ic-chevron-left", "./images/ic-chevron-left.svg");
        export const ic_chevron_right: DrawableResource = new DrawableResource("drawable-ic-chevron-right", "./images/ic-chevron-right.svg");
        export const ic_close: DrawableResource = new DrawableResource("drawable-ic-close", "./images/ic-close.svg");
        export const ic_delete: DrawableResource = new DrawableResource("drawable-ic-delete", "./images/ic-delete.svg");
        export const ic_delete_dark: DrawableResource = new DrawableResource("drawable-ic-delete-dark", "./images/ic-delete-dark.svg");
        export const ic_delete_red: DrawableResource = new DrawableResource("drawable-ic-delete-red", "./images/ic-delete-red.svg");
        export const ic_directions_walk: DrawableResource = new DrawableResource("drawable-ic-directions-walk", "./images/ic-directions-walk.svg");
        export const ic_download: DrawableResource = new DrawableResource("drawable-ic-download", "./images/ic-download.svg");
        export const ic_emergency: DrawableResource = new DrawableResource("drawable-ic-emergency", "./images/ic-emergency.svg");
        export const ic_error: DrawableResource = new DrawableResource("drawable-ic-error", "./images/ic-error.svg");
        export const ic_exit_to_app: DrawableResource = new DrawableResource("drawable-ic-exit-to-app", "./images/ic-exit-to-app.svg");
        export const ic_filter_list: DrawableResource = new DrawableResource("drawable-ic-filter-list", "./images/ic-filter-list.svg");
        export const ic_green_flag: DrawableResource = new DrawableResource("ic-green-flag", "./images/ic-green-flag.png");
        export const ic_group: DrawableResource = new DrawableResource("drawable-ic-group", "./images/ic-group.svg");
        export const ic_group_add: DrawableResource = new DrawableResource("drawable-ic-group-add", "./images/ic-group-add.svg");
        export const ic_group_bar: DrawableResource = new DrawableResource("drawable-ic-group-bar", "./images/ic-group-bar.svg");
        export const ic_group_large: DrawableResource = new DrawableResource("drawable-ic-group-large", "./images/ic-group-large.svg");
        export const ic_group_light: DrawableResource = new DrawableResource("drawable-ic-group-light", "./images/ic-group-light.svg");
        export const ic_history: DrawableResource = new DrawableResource("drawable-ic-history", "./images/ic-history.svg");
        export const ic_history_large: DrawableResource = new DrawableResource("drawable-ic-history-large", "./images/ic-history-large.svg");
        export const ic_history_large_light: DrawableResource = new DrawableResource("drawable-ic-history-large-light", "./images/ic-history-large-light.svg");
        export const ic_home: DrawableResource = new DrawableResource("drawable-ic-home", "./images/ic-home.svg");
        export const ic_house: DrawableResource = new DrawableResource("drawable-ic-house", "./images/ic-house.svg");
        export const ic_launcher_background: DrawableResource = new DrawableResource("drawable-ic-launcher-background", "./images/ic-launcher-background.svg");
        export const ic_launcher_foreground: DrawableResource = new DrawableResource("drawable-ic-launcher-foreground", "./images/ic-launcher-foreground.svg");
        export const ic_mail: DrawableResource = new DrawableResource("drawable-ic-mail", "./images/ic-mail.svg");
        export const ic_more_horiz: DrawableResource = new DrawableResource("drawable-ic-more-horiz", "./images/ic-more-horiz.svg");
        export const ic_more_settings: DrawableResource = new DrawableResource("drawable-ic-more-settings", "./images/ic-more-settings.svg");
        export const ic_notification_icon: DrawableResource = new DrawableResource("drawable-ic-notification-icon", "./images/ic-notification-icon.svg");
        export const ic_person: DrawableResource = new DrawableResource("drawable-ic-person", "./images/ic-person.svg");
        export const ic_person_add: DrawableResource = new DrawableResource("drawable-ic-person-add", "./images/ic-person-add.svg");
        export const ic_person_bar: DrawableResource = new DrawableResource("drawable-ic-person-bar", "./images/ic-person-bar.svg");
        export const ic_person_light: DrawableResource = new DrawableResource("drawable-ic-person-light", "./images/ic-person-light.svg");
        export const ic_phone: DrawableResource = new DrawableResource("drawable-ic-phone", "./images/ic-phone.svg");
        export const ic_phone_blue: DrawableResource = new DrawableResource("drawable-ic-phone-blue", "./images/ic-phone-blue.svg");
        export const ic_red_flag: DrawableResource = new DrawableResource("ic-red-flag", "./images/ic-red-flag.png");
        export const ic_refresh: DrawableResource = new DrawableResource("drawable-ic-refresh", "./images/ic-refresh.svg");
        export const ic_report: DrawableResource = new DrawableResource("drawable-ic-report", "./images/ic-report.svg");
        export const ic_report_large: DrawableResource = new DrawableResource("drawable-ic-report-large", "./images/ic-report-large.svg");
        export const ic_save_alt: DrawableResource = new DrawableResource("drawable-ic-save-alt", "./images/ic-save-alt.svg");
        export const ic_school: DrawableResource = new DrawableResource("ic-school", "./images/ic-school.png");
        export const ic_school_large: DrawableResource = new DrawableResource("ic-school-large", "./images/ic-school-large.png");
        export const ic_search: DrawableResource = new DrawableResource("drawable-ic-search", "./images/ic-search.svg");
        export const ic_search_bar: DrawableResource = new DrawableResource("drawable-ic-search-bar", "./images/ic-search-bar.svg");
        export const ic_search_light: DrawableResource = new DrawableResource("drawable-ic-search-light", "./images/ic-search-light.svg");
        export const ic_send: DrawableResource = new DrawableResource("drawable-ic-send", "./images/ic-send.svg");
        export const ic_send_bar: DrawableResource = new DrawableResource("drawable-ic-send-bar", "./images/ic-send-bar.svg");
        export const ic_sound_off: DrawableResource = new DrawableResource("drawable-ic-sound-off", "./images/ic-sound-off.svg");
        export const ic_sound_on: DrawableResource = new DrawableResource("drawable-ic-sound-on", "./images/ic-sound-on.svg");
        export const ic_warn: DrawableResource = new DrawableResource("drawable-ic-warn", "./images/ic-warn.svg");
        export const ic_warn_bar: DrawableResource = new DrawableResource("drawable-ic-warn-bar", "./images/ic-warn-bar.svg");
        export const ic_warn_light: DrawableResource = new DrawableResource("drawable-ic-warn-light", "./images/ic-warn-light.svg");
        export const ic_zoom_in: DrawableResource = new DrawableResource("drawable-ic-zoom-in", "./images/ic-zoom-in.svg");
        export const ic_zoom_out: DrawableResource = new DrawableResource("drawable-ic-zoom-out", "./images/ic-zoom-out.svg");
        export const image_background: DrawableResource = new DrawableResource("drawable-image-background");
        export const indicator_unsafe: DrawableResource = new DrawableResource("drawable-indicator-unsafe");
        export const indicator_unsafe_medical: DrawableResource = new DrawableResource("drawable-indicator-unsafe-medical");
        export const launch_background: DrawableResource = new DrawableResource("drawable-launch-background");
        export const light_background: DrawableResource = new DrawableResource("drawable-light-background");
        export const logo: DrawableResource = new DrawableResource("logo", "./images/logo.png");
        export const logo_icon: DrawableResource = new DrawableResource("logo-icon", "./images/logo-icon.png");
        export const offsite_button_background: DrawableResource = new DrawableResource("drawable-offsite-button-background");
        export const offsite_button_background_off: DrawableResource = new DrawableResource("drawable-offsite-button-background-off");
        export const offsite_button_background_unsafe: DrawableResource = new DrawableResource("drawable-offsite-button-background-unsafe");
        export const reset_button_background: DrawableResource = new DrawableResource("drawable-reset-button-background");
        export const safe_button_background: DrawableResource = new DrawableResource("drawable-safe-button-background");
        export const safe_button_background_off: DrawableResource = new DrawableResource("drawable-safe-button-background-off");
        export const status_background: DrawableResource = new DrawableResource("drawable-status-background");
        export const status_medical_background: DrawableResource = new DrawableResource("drawable-status-medical-background");
        export const switch_cleared: DrawableResource = new DrawableResource("drawable-switch-cleared", "./images/switch-cleared.svg");
        export const switch_cleared_medical: DrawableResource = new DrawableResource("drawable-switch-cleared-medical", "./images/switch-cleared-medical.svg");
        export const switch_empty: DrawableResource = new DrawableResource("drawable-switch-empty", "./images/switch-empty.svg");
        export const switch_safe: DrawableResource = new DrawableResource("drawable-switch-safe", "./images/switch-safe.svg");
        export const switch_safe_medical: DrawableResource = new DrawableResource("drawable-switch-safe-medical", "./images/switch-safe-medical.svg");
        export const switch_unknown: DrawableResource = new DrawableResource("drawable-switch-unknown", "./images/switch-unknown.svg");
        export const switch_unknown_medical: DrawableResource = new DrawableResource("drawable-switch-unknown-medical", "./images/switch-unknown-medical.svg");
        export const switch_unsafe: DrawableResource = new DrawableResource("drawable-switch-unsafe", "./images/switch-unsafe.svg");
        export const switch_unsafe_medical: DrawableResource = new DrawableResource("drawable-switch-unsafe-medical", "./images/switch-unsafe-medical.svg");
        export const tab_alert: DrawableResource = new DrawableResource("drawable-tab-alert", "./images/tab-alert.svg");
        export const tab_alert_large: DrawableResource = new DrawableResource("drawable-tab-alert-large", "./images/tab-alert-large.svg");
        export const tab_background: DrawableResource = new DrawableResource("drawable-tab-background");
        export const tab_board: DrawableResource = new DrawableResource("drawable-tab-board", "./images/tab-board.svg");
        export const tab_board_large: DrawableResource = new DrawableResource("drawable-tab-board-large", "./images/tab-board-large.svg");
        export const tab_chat: DrawableResource = new DrawableResource("drawable-tab-chat", "./images/tab-chat.svg");
        export const tab_chat_large: DrawableResource = new DrawableResource("drawable-tab-chat-large", "./images/tab-chat-large.svg");
        export const tab_chat_large_light: DrawableResource = new DrawableResource("drawable-tab-chat-large-light", "./images/tab-chat-large-light.svg");
        export const tab_map: DrawableResource = new DrawableResource("drawable-tab-map", "./images/tab-map.svg");
        export const tab_map_large: DrawableResource = new DrawableResource("drawable-tab-map-large", "./images/tab-map-large.svg");
        export const tab_settings: DrawableResource = new DrawableResource("drawable-tab-settings", "./images/tab-settings.svg");
        export const tab_settings_large: DrawableResource = new DrawableResource("drawable-tab-settings-large", "./images/tab-settings-large.svg");
        export const tag_background: DrawableResource = new DrawableResource("drawable-tag-background");
        export const tag_background_highlight: DrawableResource = new DrawableResource("drawable-tag-background-highlight");
        export const thin_border: DrawableResource = new DrawableResource("drawable-thin-border");
        export const toggle_button_background: DrawableResource = new DrawableResource("drawable-toggle-button-background");
        export const unread_indicator: DrawableResource = new DrawableResource("drawable-unread-indicator", "./images/unread-indicator.svg");
        export const unsafe_button_background: DrawableResource = new DrawableResource("drawable-unsafe-button-background");
        export const unsafe_button_background_off: DrawableResource = new DrawableResource("drawable-unsafe-button-background-off");
        export const unsafe_circle: DrawableResource = new DrawableResource("drawable-unsafe-circle");
        export const user_offline: DrawableResource = new DrawableResource("drawable-user-offline", "./images/user-offline.svg");
        export const user_safe: DrawableResource = new DrawableResource("drawable-user-safe", "./images/user-safe.svg");
        export const user_unsafe: DrawableResource = new DrawableResource("drawable-user-unsafe", "./images/user-unsafe.svg");
        export const white_button_background: DrawableResource = new DrawableResource("drawable-white-button-background");
    }
    export interface Strings {
        app_name: string;
        landing_instructions: string;
        send_email: string;
        email: string;
        try_another_email: string;
        check_email_instructions: string;
        start_alert: string;
        start_drill: string;
        create_group: string;
        login: string;
        admin_dashboard: string;
        dashboard: string;
        chat_messages: string;
        missing_reports: string;
        users: string;
        total_users: string;
        most_used_alert: string;
        total_alert_types: string;
        alert_history: string;
        admin_alert_history: string;
        alerts_this_month: string;
        alert_types: string;
        cancel_message_create: string;
        create_message_group: string;
        first_name: string;
        last_name: string;
        cell_phone: string;
        reset_password: string;
        associated_sites: string;
        filter_by_school: string;
        filter_by_name: string;
        cancel: string;
        save: string;
        add_another_site: string;
        _delete: string;
        restore: string;
        school: string;
        groups: string;
        group_name_hint: string;
        no_school_selected: string;
        enter_title: string;
        enter_content: string;
        alert_name: string;
        guidances: string;
        new_guidance: string;
        alert_icon: string;
        change: string;
        alert_sound_mp3: string;
        alert_sound_ogg_optional: string;
        new_school: string;
        example_date: string;
        add_another_guidance: string;
        title: string;
        plan: string;
        activity_history: string;
        add_user: string;
        started: string;
        ended: string;
        mark_complete: string;
        complete_post: string;
        reason: string;
        thread: string;
        reason_colon: string;
        complete: string;
        resolved: string;
        post: string;
        all_sites: string;
        starting_alert: string;
        silent_alert: string;
        silent_notification: string;
        end_alert: string;
        change_alert: string;
        back: string;
        empty: string;
        go_to_another_screen: string;
        i_agree: string;
        increment_the_number: string;
        mustAgree: string;
        ok: string;
        password: string;
        submit: string;
        username: string;
        verify_password: string;
        welcome: string;
        chat: string;
        board: string;
        alert: string;
        map: string;
        settings: string;
        internal: string;
        error_bad_request: string;
        error_unauthorized: string;
        error_forbidden: string;
        error_not_found: string;
        error_server: string;
        error_no_server: string;
        error_unknown: string;
        error_local: string;
        edit_user: string;
        manage_alerts: string;
        log_out: string;
        show_dialog_for_alert_start: string;
        where_are_you: string;
        select_using_map: string;
        safe: string;
        unsafe: string;
        are_you_safe: string;
        cleared: string;
        unknown: string;
        status: string;
        none: string;
        add_site: string;
        add_user_or_school: string;
        manage_default_locations: string;
        select_recipient_s: string;
        drill_in_progress: string;
        alert_in_progress: string;
        all_is_well: string;
        not_connected: string;
        connected: string;
        change_internal_status: string;
        change_to_drill: string;
        change_to_alert: string;
        email_address: string;
        cell_number: string;
        old_password: string;
        new_password: string;
        update: string;
        default_location_one: string;
        default_location_two: string;
        default_location_three: string;
        default_location_four: string;
        default_location_five: string;
        new_post: string;
        confirm: string;
        need_medical_attention: string;
        on_site: string;
        en_route: string;
        notified: string;
        not_notified: string;
        offsite: string;
        offsite_split: string;
        my_location: string;
        people_in_this_room: string;
        set_as_my_location: string;
        add_as_default_location: string;
        remove_as_default_location: string;
        full_map: string;
        exceed_max_defaults: string;
        incident_resolved: string;
        false_alarm: string;
        other: string;
        end_alert_subtitle: string;
        end_alert_title: string;
        campus: string;
        reset: string;
        pin: string;
        enter_your_pin: string;
        conversation: string;
        enter_your_new_pin: string;
        description: string;
        verify_your_pin: string;
        police_status: string;
        guidance: string;
        remove: string;
        ended_alert_type: string;
        drill: string;
        drill_type: string;
        export_as: string;
        summary_pdf: string;
        detailed_pdf: string;
        csv: string;
        filter_for_person_or_site: string;
        group_name: string;
        contacts: string;
        offline: string;
        ended_by: string;
        at: string;
        new_password_confirm: string;
        ending_alert_note: string;
        retry_loading_map: string;
        forgot_password: string;
        forgot_password_success: string;
        leave_group: string;
        people_in_this_group: string;
        people_in_this_thread: string;
        edit: string;
        edit_group: string;
        delete_group: string;
        add_sound: string;
        no_status: string;
        message_user_updated: string;
        message_user_updated_relogin: string;
        personal: string;
        alerts: string;
        type_message_hint: string;
        mute: string;
        site_list: string;
        on: string;
        off: string;
        search: string;
        reset_user_password: string;
        requires_admin_approval: string;
        user_type: string;
        select_a_user_type: string;
        selected: string;
        select_your_status: string;
        are_you_sure_delete_alert_type: string;
        are_you_sure_delete_user: string;
        are_you_sure_delete_group: string;
        history: string;
        unlock: string;
        app_locked: string;
        auth_title: string;
        auth_description: string;
        started_by: string;
        insist_notifications: string;
        select_a_conversation: string;
        home: string;
        zoom_in: string;
        zoom_out: string;
        add_post: string;
        validation_group_name_taken: string;
        alert_subscriptions: string;
        submitted_by: string;
        found_by: string;
        person_name: string;
        additional_details: string;
        submit_missing_person: string;
        mark_as_found: string;
        board_empty_explain: string;
        missing: string;
        found: string;
        add_new: string;
        report_missing_people: string;
        additional_details_optional: string;
        force_refresh: string;
        fake_errors: string;
        select_attachment_type: string;
        video: string;
        image: string;
        file: string;
        download_x: string;
        downloading_x: string;
        more: string;
        all_groups_create_group: string;
        all_contacts: string;
        all_groups: string;
        manual_login: string;
        automatic_login: string;
        silent_drill: string;
        alert_cancelled: string;
        description_channel_alert: string;
        description_channel_alert_silent: string;
        description_channel_drill: string;
        description_channel_drill_silent: string;
        description_channel_canceled: string;
        description_channel_police: string;
        description_channel_chat: string;
        turn_on_dnd_bypass: string;
        approve: string;
        approve_alert: string;
        avg_messages_per_alert: string;
        user_header: string;
        message_header: string;
        timestamp_header: string;
        to_header: string;
        site_header: string;
        alert_header: string;
        alert_type: string;
        user: string;
        alert_type_header: string;
        initiator_header: string;
        start_header: string;
        drill_header: string;
        canceller_header: string;
        end_header: string;
        cancel_reason_header: string;
        two_decimals_rounded: string;
        device_mac: string;
        device_windows: string;
        device_android: string;
        device_ios: string;
        device_other: string;
        name_header: string;
        associated_sites_header: string;
        latest_activity_date_header: string;
        registered_devices_header: string;
        version_not_supported: string;
        contact_your_rep_to_get_assigned_to_site: string;
        admin_alert_created_successfully: string;
        admin_alert_not_created: string;
        start_date: string;
        something_went_wrong: string;
        end_date: string;
        alert_history_header: string;
        clear: string;
        add_alert_type: string;
        alert_threads: string;
        thread_name_header: string;
        associated_users_header: string;
        sites_header: string;
        edit_google_map: string;
        export_svg: string;
        import_svg: string;
        success: string;
        show_me_around: string;
        change_user: string;
        region: string;
        dismiss: string;
        demonstration_mode: string;
        demonstration_mode_: string;
        demo_email_message: string;
        skip: string;
        continue_: string;
        select_schools: string;
        bad_passwords: string;
        can_not_start_alert_with_this_permission: string;
        alert_approval: string;
        there_is_a_alert_request: string;
        this_alert_is_already_accepted: string;
        request_declined: string;
        you_dont_have_permission: string;
        role: string;
        notification_permission_required: string;
        more_settings: string;
        type: string;
        alert_type_type: string;
        urgency: string;
        turn_on_dnd_bypass_samsung: string;
        you_dont_have_permission_go_to_settings: string;
        check_in: string;
    }
    export namespace DefaultStrings {
        export const app_name: string = "DIR-S";
        export const landing_instructions: string = "Please enter your email to log in.";
        export const send_email: string = "Send Email";
        export const email: string = "Email";
        export const try_another_email: string = "Try another email";
        export const check_email_instructions: string = "An email has been sent to you with a login link! Go check your email and tap on the link.";
        export const start_alert: string = "START ALERT";
        export const start_drill: string = "START DRILL";
        export const create_group: string = "Create Group";
        export const login: string = "Log In";
        export const admin_dashboard: string = "Admin Dashboard";
        export const dashboard: string = "Dashboard";
        export const chat_messages: string = "Chat Messages";
        export const missing_reports: string = "Missing Reports";
        export const users: string = "Users";
        export const total_users: string = "Total Users";
        export const most_used_alert: string = "Most Used Alert";
        export const total_alert_types: string = "Total Alert Types";
        export const alert_history: string = "Alert History";
        export const admin_alert_history: string = "Alert Request History";
        export const alerts_this_month: string = "Alerts this Month";
        export const alert_types: string = "Alert Types";
        export const cancel_message_create: string = "Cancel";
        export const create_message_group: string = "Create";
        export const first_name: string = "First Name";
        export const last_name: string = "Last Name";
        export const cell_phone: string = "Cell Phone";
        export const reset_password: string = "Reset Password";
        export const associated_sites: string = "Associated Site(s)";
        export const filter_by_school: string = "Filter by site";
        export const filter_by_name: string = "Filter by name";
        export const cancel: string = "Cancel";
        export const save: string = "Save";
        export const add_another_site: string = "+ Add Another Site";
        export const _delete: string = "Delete";
        export const restore: string = "Restore";
        export const school: string = "Site";
        export const groups: string = "Groups";
        export const group_name_hint: string = "Enter Group Name (optional)";
        export const no_school_selected: string = "No Site Selected";
        export const enter_title: string = "Enter Title";
        export const enter_content: string = "Enter Content";
        export const alert_name: string = "Alert Name";
        export const guidances: string = "Guidances";
        export const new_guidance: string = "New Guidance";
        export const alert_icon: string = "Alert Icon";
        export const change: string = "Change";
        export const alert_sound_mp3: string = "Alert Sound (mp3, optional)";
        export const alert_sound_ogg_optional: string = "Alert Sound (ogg, optional)";
        export const new_school: string = "New site";
        export const example_date: string = "6/25/2020";
        export const add_another_guidance: string = "+ Add Another Guidance";
        export const title: string = "Title";
        export const plan: string = "Plan";
        export const activity_history: string = "Activity History";
        export const add_user: string = "+ Add User";
        export const started: string = "Started";
        export const ended: string = "Ended";
        export const mark_complete: string = "Mark Complete";
        export const complete_post: string = "Complete Post";
        export const reason: string = "Reason";
        export const thread: string = "Thread";
        export const reason_colon: string = "Reason:";
        export const complete: string = "Complete";
        export const resolved: string = "Resolved";
        export const post: string = "Post";
        export const all_sites: string = "All Sites";
        export const starting_alert: string = "Starting Alert...";
        export const silent_alert: string = "Silent Alert";
        export const silent_notification: string = "Silent Notification";
        export const end_alert: string = "End Alert";
        export const change_alert: string = "Change Alert";
        export const back: string = "Back";
        export const empty: string = "";
        export const go_to_another_screen: string = "Go to Another Screen";
        export const i_agree: string = "I Agree to the Terms of Service";
        export const increment_the_number: string = "Increment the Number";
        export const mustAgree: string = "You must agree to the terms of service.";
        export const ok: string = "OK";
        export const password: string = "Password";
        export const submit: string = "Submit";
        export const username: string = "Username";
        export const verify_password: string = "Verify Password";
        export const welcome: string = "Welcome!";
        export const chat: string = "Chat";
        export const board: string = "Report";
        export const alert: string = "Alert";
        export const map: string = "Map";
        export const settings: string = "Settings";
        export const internal: string = "Internal";
        export const error_bad_request: string = "An error occurred where malformed data was sent to the server. Please let the developers know about this!";
        export const error_unauthorized: string = "An authorization error occurred. Please log out and back in. If the problem persists, contact support.";
        export const error_forbidden: string = "A request was made to the server for something you\'re not allowed to do.";
        export const error_not_found: string = "The requested resource was not found.";
        export const error_server: string = "Something went wrong on the server. We\'ve likely been signaled that this error occurred, but nonetheless it might be a good idea to contact support.";
        export const error_no_server: string = "Could not reach the server; please check your internet and try again.";
        export const error_unknown: string = "An unknown error occurred; please contact support.";
        export const error_local: string = "An error occurred on the device. Please check your app is up to date and contact support.";
        export const edit_user: string = "Edit User";
        export const manage_alerts: string = "Manage Alerts";
        export const log_out: string = "Log Out";
        export const show_dialog_for_alert_start: string = "Show dialog for alert start";
        export const where_are_you: string = "Where are you?";
        export const select_using_map: string = "Select using map";
        export const safe: string = "Safe";
        export const unsafe: string = "Unsafe";
        export const are_you_safe: string = "Are you safe?";
        export const cleared: string = "Cleared";
        export const unknown: string = "Unknown";
        export const status: string = "Status";
        export const none: string = "None";
        export const add_site: string = "Add Site";
        export const add_user_or_school: string = "Add User or Site";
        export const manage_default_locations: string = "Manage Default Locations";
        export const select_recipient_s: string = "Select Recipient(s)";
        export const drill_in_progress: string = "Drill in Progress";
        export const alert_in_progress: string = "Alert in Progress";
        export const all_is_well: string = "All is Well";
        export const not_connected: string = "Not Connected";
        export const connected: string = "Connected";
        export const change_internal_status: string = "Change to Drill";
        export const change_to_drill: string = "Change to Drill";
        export const change_to_alert: string = "Change to Alert";
        export const email_address: string = "Email Address";
        export const cell_number: string = "Cell Number";
        export const old_password: string = "Old Password";
        export const new_password: string = "New Password";
        export const update: string = "Update";
        export const default_location_one: string = "Default Location 1";
        export const default_location_two: string = "Default Location 2";
        export const default_location_three: string = "Default Location 3";
        export const default_location_four: string = "Default Location 4";
        export const default_location_five: string = "Default Location 5";
        export const new_post: string = "New Post";
        export const confirm: string = "Confrim";
        export const need_medical_attention: string = "Need medical attention";
        export const on_site: string = "On Site";
        export const en_route: string = "En Route";
        export const notified: string = "Notified";
        export const not_notified: string = "Not Notified";
        export const offsite: string = "Offsite";
        export const offsite_split: string = "Off\nsite";
        export const my_location: string = "My Location";
        export const people_in_this_room: string = "People in this room:";
        export const set_as_my_location: string = "Set as My Location";
        export const add_as_default_location: string = "Add as a Default Location";
        export const remove_as_default_location: string = "Remove as a Default Location";
        export const full_map: string = "Full Map";
        export const exceed_max_defaults: string = "You can only have 5 default locations. Your current locations are:\n%1$s";
        export const incident_resolved: string = "Incident Resolved";
        export const false_alarm: string = "False Alarm";
        export const other: string = "Other:";
        export const end_alert_subtitle: string = "Please select a reason to end this alert:";
        export const end_alert_title: string = "End Alert?";
        export const campus: string = "Campus";
        export const reset: string = "Reset";
        export const pin: string = "Pin";
        export const enter_your_pin: string = "Enter your PIN";
        export const conversation: string = "Conversation";
        export const enter_your_new_pin: string = "Enter your new PIN";
        export const description: string = "Alert Description";
        export const verify_your_pin: string = "Verify your PIN";
        export const police_status: string = "Police Status:";
        export const guidance: string = "Guidance";
        export const remove: string = "Remove";
        export const ended_alert_type: string = "%1$s Ended";
        export const drill: string = "Drill";
        export const drill_type: string = "Drill Type";
        export const export_as: string = "Export As";
        export const summary_pdf: string = "Summary PDF";
        export const detailed_pdf: string = "Detailed PDF";
        export const csv: string = "CSV";
        export const filter_for_person_or_site: string = "Type here to add person or site";
        export const group_name: string = "Group Name";
        export const contacts: string = "Contacts";
        export const offline: string = "Offline";
        export const ended_by: string = "Ended by:";
        export const at: string = "at";
        export const new_password_confirm: string = "Confirm New Password";
        export const ending_alert_note: string = "Note: Ending this alert will end the alert for ALL users.";
        export const retry_loading_map: string = "Retry loading map";
        export const forgot_password: string = "Forgot Password";
        export const forgot_password_success: string = "You\'ve been sent an email with a link to reset your password.";
        export const leave_group: string = "Leave Group";
        export const people_in_this_group: string = "People in this group:";
        export const people_in_this_thread: string = "People in this thread:";
        export const edit: string = "Edit";
        export const edit_group: string = "Edit Group";
        export const delete_group: string = "Delete Group";
        export const add_sound: string = "Add Sound";
        export const no_status: string = "No Status";
        export const message_user_updated: string = "User has been successfully updated.";
        export const message_user_updated_relogin: string = "User has been successfully updated with an email change. Please log in again.";
        export const personal: string = "Personal";
        export const alerts: string = "Alerts";
        export const type_message_hint: string = "Type Message...";
        export const mute: string = "Mute";
        export const site_list: string = "Site List";
        export const on: string = "On";
        export const off: string = "Off";
        export const search: string = "Search";
        export const reset_user_password: string = "Reset User Password";
        export const requires_admin_approval: string = "Requires Admin Approval";
        export const user_type: string = "User Type";
        export const select_a_user_type: string = "Select a user type";
        export const selected: string = "Selected";
        export const select_your_status: string = "Select Your Status";
        export const are_you_sure_delete_alert_type: string = "Are you sure you want to delete this Alert Type?";
        export const are_you_sure_delete_user: string = "Are you sure you want to delete this user?";
        export const are_you_sure_delete_group: string = "Are you sure you want to delete this group?";
        export const history: string = "History";
        export const unlock: string = "Unlock";
        export const app_locked: string = "App is Locked";
        export const auth_title: string = "Unlock DIR-S";
        export const auth_description: string = "Please unlock your phone to access DIR-S.";
        export const started_by: string = "Started by:";
        export const insist_notifications: string = "DIR-S can\'t notify you about alerts right now because you have declined to accept notifications from the app. We don\'t send non-alert related notifications. Do you want to open the settings so you can enable notifications?";
        export const select_a_conversation: string = "Select a Conversation";
        export const home: string = "home";
        export const zoom_in: string = "Zoom In";
        export const zoom_out: string = "Zoom Out";
        export const add_post: string = "Add Post";
        export const validation_group_name_taken: string = "This group name is already being used. Please choose a different one.";
        export const alert_subscriptions: string = "Alert Subscriptions";
        export const submitted_by: string = "Submitted by:";
        export const found_by: string = "Found by:";
        export const person_name: string = "Person Name";
        export const additional_details: string = "Additional details (optional)";
        export const submit_missing_person: string = "Submit Missing Person";
        export const mark_as_found: string = "Mark Person as Found";
        export const board_empty_explain: string = "Report only available during an active alert.";
        export const missing: string = "Missing";
        export const found: string = "Found";
        export const add_new: string = "Add New";
        export const report_missing_people: string = "Report Missing People";
        export const additional_details_optional: string = "Additional details (optional)";
        export const force_refresh: string = "Force Refresh";
        export const fake_errors: string = "Fake Errors";
        export const select_attachment_type: string = "Select Attachment Type";
        export const video: string = "Video";
        export const image: string = "Image";
        export const file: string = "File";
        export const download_x: string = "Are you sure you want to download %1$s?";
        export const downloading_x: string = "Downloading %1$s";
        export const more: string = "More";
        export const all_groups_create_group: string = "All Groups / Create Group";
        export const all_contacts: string = "All Contacts";
        export const all_groups: string = "All Groups";
        export const manual_login: string = "Manual Login";
        export const automatic_login: string = "< Back";
        export const silent_drill: string = "Silent Drill";
        export const alert_cancelled: string = "Alert Cancelled";
        export const description_channel_alert: string = "Notifications for real, unsilenced alerts. Please ensure you will notice and respond to these alerts quickly.";
        export const description_channel_alert_silent: string = "Notifications for real but silenced alerts.";
        export const description_channel_drill: string = "Notifications for drills.";
        export const description_channel_drill_silent: string = "Notifications for silenced drills.";
        export const description_channel_canceled: string = "Notifications for when an alert is cancelled.";
        export const description_channel_police: string = "Notifications for updates on police status.";
        export const description_channel_chat: string = "Notifications for new chat messages.";
        export const turn_on_dnd_bypass: string = "We strongly recommend allowing real alerts to bypass the do-not-disturb feature on your phone. Open notification channel settings?";
        export const approve: string = "Approve";
        export const approve_alert: string = "Approve Alert";
        export const avg_messages_per_alert: string = "Avg Messages Per Alert";
        export const user_header: string = "USER";
        export const message_header: string = "MESSAGE";
        export const timestamp_header: string = "TIMESTAMP";
        export const to_header: string = "TO";
        export const site_header: string = "SITE";
        export const alert_header: string = "ALERT";
        export const alert_type: string = "Alert Type";
        export const user: string = "User";
        export const alert_type_header: string = "ALERT TYPE";
        export const initiator_header: string = "INITIATOR";
        export const start_header: string = "START";
        export const drill_header: string = "DRILL";
        export const canceller_header: string = "CANCELLER";
        export const end_header: string = "END";
        export const cancel_reason_header: string = "CANCEL REASON";
        export const two_decimals_rounded: string = "%1$.2f";
        export const device_mac: string = "MAC";
        export const device_windows: string = "WINDOWS";
        export const device_android: string = "ANDROID";
        export const device_ios: string = "iOS";
        export const device_other: string = "OTHER";
        export const name_header: string = "NAME";
        export const associated_sites_header: string = "ASSOCIATED SITE(S)";
        export const latest_activity_date_header: string = "LATEST ACTIVITY DATE";
        export const registered_devices_header: string = "REGISTERED DEVICE(S)";
        export const version_not_supported: string = "An update to the app is available, and is strongly suggested for stability. Please go get the update from the app store.";
        export const contact_your_rep_to_get_assigned_to_site: string = "Please reach out to your System Administrator to have your site assigned to you.";
        export const admin_alert_created_successfully: string = "Admin Alert request has been sent successfully. You will be notified after admin approves or decline.";
        export const admin_alert_not_created: string = "There has been problem while creating admin alert request.";
        export const start_date: string = "Start Date";
        export const something_went_wrong: string = "Something went wrong";
        export const end_date: string = "End Date";
        export const alert_history_header: string = "Alert History";
        export const clear: string = "Clear";
        export const add_alert_type: string = "Add Alert Type";
        export const alert_threads: string = "Chat History";
        export const thread_name_header: string = "THREAD";
        export const associated_users_header: string = "ASSOCIATED USERS";
        export const sites_header: string = "SITE(S)";
        export const edit_google_map: string = "Edit Google Map";
        export const export_svg: string = "Export SVG";
        export const import_svg: string = "Import SVG";
        export const success: string = "Success!";
        export const show_me_around: string = "Show Me Around";
        export const change_user: string = "Change User";
        export const region: string = "Region";
        export const dismiss: string = "Dismiss";
        export const demonstration_mode: string = "Demonstration Mode";
        export const demonstration_mode_: string = "Demonstration Mode:";
        export const demo_email_message: string = "Enter your email if you are interested in hearing more about our app and services.";
        export const skip: string = "Skip";
        export const continue_: string = "Continue";
        export const select_schools: string = "You must select a school for this new user.";
        export const bad_passwords: string = "The passwords provided are invalid.";
        export const can_not_start_alert_with_this_permission: string = "You don\'t have permission to start an alert.";
        export const alert_approval: string = "Alert approval request";
        export const there_is_a_alert_request: string = "There is an alert request.";
        export const this_alert_is_already_accepted: string = "This alert is already accepted.";
        export const request_declined: string = "Your recent alert request is declined by admin.";
        export const you_dont_have_permission: string = "You dont have permission to peform this task.";
        export const role: string = "ROLE";
        export const notification_permission_required: string = "Notification permission is required, Please allow notification permission from setting!";
        export const more_settings: string = "More Settings";
        export const type: string = "Type";
        export const alert_type_type: string = "Please select type of the alert type";
        export const urgency: string = "Urgency";
        export const turn_on_dnd_bypass_samsung: string = "We strongly recommend allowing real alerts to bypass the do-not-disturb feature on your phone. To open the device settings, navigate to the \"App Notifications\" menu, and add \"Dir-s\" app to override the \"Do Not Disturb\" feature..";
        export const you_dont_have_permission_go_to_settings: string = "Please provide required permissions to perform this action. Go to settings and provide permission.";
        export const check_in: string = "Check In";
    }
    export let _string = DefaultStrings;
}
