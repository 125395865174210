// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/board/PostCreateVG.kt
// Package: com.tresitgroup.android.tresit.vg.board
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { PostCreateXml } from '../../layout/PostCreateXml'
import { ViewStringRaw } from 'butterfly-web/dist/views/ViewString'
import { CacheEdge } from '../../api/CacheEdge'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { setViewVisibility, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { BoardPost } from '../../model/BoardPost'
import { spinnerBindString } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { SessionApi } from '../../api/SessionApi'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.board.PostCreateVG
export class PostCreateVG extends ViewGenerator {
    public readonly parentPost: (ForeignKey<BoardPost> | null);
    public readonly schoolId: (ForeignKey<School> | null);
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(parentPost: (ForeignKey<BoardPost> | null) = null, schoolId: (ForeignKey<School> | null) = null, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.parentPost = parentPost;
        this.schoolId = schoolId;
        this.session = session;
        this.stack = stack;
        this.working = new StandardObservableProperty<boolean>(false, undefined);
        this.postTitle = new StandardObservableProperty<string>("", undefined);
        this.postContent = new StandardObservableProperty<string>("", undefined);
        this.selectedAlert = new StandardObservableProperty<(Alert | null)>(null, undefined);
        this.alerts = this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.board.PostCreateVG.title
    public get title(): string { return "Post Create"; }
    
    
    public readonly working: StandardObservableProperty<boolean>;
    
    public readonly postTitle: MutableObservableProperty<string>;
    
    public readonly postContent: MutableObservableProperty<string>;
    
    public readonly selectedAlert: MutableObservableProperty<(Alert | null)>;
    
    public readonly alerts: CacheEdge<Alert>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new PostCreateXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Catch background taps
        xViewOnClick(xml.xmlRoot, undefined, (): void => {});
        
        //--- Set Up xml.editTitle
        xEditTextBindString(xml.editTitle, this.postTitle);
        
        //--- Set Up xml.editContent
        xEditTextBindString(xml.editContent, this.postContent);
        
        //--- Set Up xml.school
        if (this.schoolId !== null) {
            setViewVisibility(xml.school, "gone");
            xSingleCallDisplayingError<Array<Alert>>(this.session.alerts.getListSimple(Alert.Companion.INSTANCE.forSchool(this.schoolId!), undefined, undefined, undefined, undefined), undefined, (it: Array<Alert>): void => {
                this.selectedAlert.value = (it[0] ?? null);
            });
        }
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Alert> | null)>(this.alerts, undefined, undefined, (it: (Array<Alert> | null)): void => {
            if (it !== null) {
                if (it!.length === 1) {
                    this.selectedAlert.value = it![0];
                }
            }
        }), xViewRemovedGet(view));
        spinnerBindString<(Alert | null)>(xml.school, xObservablePropertyMap<(Array<Alert> | null), Array<(Alert | null)>>(this.alerts, (it: (Array<Alert> | null)): Array<(Alert | null)> => {
                    if (it === null) { return ([null] as Array<(Alert | null)>) }
                    return ([null] as Array<(Alert | null)>).concat(it!);
            }), this.selectedAlert, (it: (Alert | null)): ObservableProperty<string> => {
                if (it !== null) {
                    return xObservablePropertyMap<(School | null), string>(this.session.schools.observable(it!.school), (it: (School | null)): string => (it?.name ?? null) ?? "");
                }
                return new ConstantObservableProperty<string>("Select an Alert");
        });
        
        //--- Set Up xml.cancel (overwritten on flow generation)
        xViewOnClick(xml.cancel, undefined, (): void => {
            this.cancelClick();
        });
        
        //--- Set Up xml.completeWorking
        xViewFlipperBindLoading(xml.completeWorking, this.working, undefined);
        
        //--- Set Up xml.post (overwritten on flow generation)
        xViewOnClick(xml.post, undefined, (): void => {
            this.postClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    public cancelClick(): void {
        this.stack.dismiss();
    }
    public postClick(): void {
        let content = this.postContent.value;
        
        if (content === "") {
            content = "No additional information provided.";
        }
        const alert_4175 = this.selectedAlert.value;
        if (alert_4175 !== null) {
            xSingleCallDisplayingError<BoardPost>(xSingleWorking<BoardPost>(this.session.posts.post(new BoardPost(undefined, undefined, this.postTitle.value, content, alert_4175.id, alert_4175.school, this.session.session.userId, undefined, undefined, undefined, undefined, undefined)), this.working), undefined, (it: BoardPost): void => {
                this.stack.dismiss();
            });
        } else {
            showDialogAlert(new ViewStringRaw("Could not find alert to post to."));
            return;
        }
    }
    
    //--- Body End
}
