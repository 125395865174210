//
// ComponentRowAlertTypeFullXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_row_alert_type_full.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentRowAlertTypeFullXml
export class ComponentRowAlertTypeFullXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    detailDummy!: HTMLElement
    detailMultiDummy!: HTMLElement
    icon!: HTMLImageElement
    name!: HTMLElement
    schoolName!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.detailDummy = getViewById<HTMLElement>(view, "detailDummy")
        this.detailMultiDummy = getViewById<HTMLElement>(view, "detailMultiDummy")
        this.icon = getViewById<HTMLImageElement>(view, "icon")
        this.name = getViewById<HTMLElement>(view, "name")
        this.schoolName = getViewById<HTMLElement>(view, "school_name")
        return view
    }
}
