// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/MessagesVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { setViewBackgroundColor, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { ComponentRowConversationAlertXml } from '../../layout/ComponentRowConversationAlertXml'
import { ContactsVG } from './ContactsVG'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { MessagesXml } from '../../layout/MessagesXml'
import { ConversationOptionsVG } from './ConversationOptionsVG'
import { DisplayMetrics } from 'butterfly-web/dist/views/DisplayMetrics'
import { unreadObs, unreadUserObs } from '../../api/Session.read'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { Alert } from '../../model/Alert'
import { xViewBindExists, xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { ConversationAllVG } from './ConversationAllVG'
import { cMax, safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { AlertType } from '../../model/AlertType'
import { R } from '../../R'
import { xObservablePropertyFlatMapNotNull2 } from '../../util/rxHelp'
import { iterFirstOrNullFind, iterableFilterNotNull, listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { PlaceholderSelectConversationVG } from './PlaceholderSelectConversationVG'
import { Observable, ObservableInput, SubscriptionLike, of as rxOf } from 'rxjs'
import { combinedAndMap, xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { flatMap as rxFlatMap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { DialogWrapperVG } from '../DialogWrapperVG'
import { ComponentRowConversationXml } from '../../layout/ComponentRowConversationXml'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { FlatMappedObservableProperty, xObservablePropertyFlatMap, xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ComponentRowConversationContactXml } from '../../layout/ComponentRowConversationContactXml'
import { School } from '../../model/School'
import { map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { AlertStatusEnum } from '../../model/AlertStatusEnum'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { StatusEnum } from '../../model/StatusEnum'
import { xObservablePropertyShare } from 'butterfly-web/dist/observables/SharingObservableProperty'
import { CacheEdge } from '../../api/CacheEdge'
import { xLinearLayoutBind } from 'butterfly-web/dist/observables/binding/LinearLayout.binding'
import { Room } from '../../model/Room'
import { post } from 'butterfly-web/dist/delay'
import { SessionApi } from '../../api/SessionApi'
import { xActivityAccessCall } from '../../PhoneCall'
import { numberToColor } from 'butterfly-web/dist/views/Colors'
import { Platform } from 'butterfly-web/dist/Platform'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ChatThread } from '../../model/ChatThread'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { xIterableDistinctBy } from 'butterfly-web/dist/kotlin/Iterables'
import { ConversationDetailVG } from './ConversationDetailVG'

//! Declares com.tresitgroup.android.tresit.vg.chat.MessagesVG
export class MessagesVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: ObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: ObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.filter = new StandardObservableProperty<string>("", undefined);
        this.firstStartup = true;
        this.selectedThread = new StandardObservableProperty<ForeignKey<ChatThread>>((-1), undefined);
        this.chatSubstack = new ObservableStack<ViewGenerator>();
        this.threads = this.session.threads.getListSimple(undefined, ChatThread.Companion.INSTANCE.mostRecent, undefined, undefined, undefined);
        this.alerts = this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined);
        this.alertThreads = xObservablePropertyMap<(Array<ChatThread> | null), (Array<ChatThread> | null)>(this.session.threads.observableListSimple(ChatThread.Companion.INSTANCE.isAlertThread(), ChatThread.Companion.INSTANCE.mostRecent, 100, undefined, undefined, undefined), (it: (Array<ChatThread> | null)): (Array<ChatThread> | null) => ((): (Array<ChatThread> | null) => {
            const temp4414 = it;
            if(temp4414 === null) { return null }
            const temp4415 = (it: ChatThread): (string | null) => ((): (string | null) => {
                const temp4416 = it.name;
                if(temp4416 === null) { return null }
                return temp4416.toLowerCase()
            })() ?? "";
            return temp4414.slice().sort((a, b) => safeCompare(temp4415(a), temp4415(b)))
        })());
    }
    
    
    //--- Provides threadId
    
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.MessagesVG.title
    public get title(): string { return "Messages"; }
    
    
    public readonly filter: MutableObservableProperty<string>;
    
    public firstStartup: boolean;
    
    public readonly selectedThread: StandardObservableProperty<number>;
    
    public readonly chatSubstack: ObservableStack<ViewGenerator>;
    
    public readonly threads: Observable<Array<ChatThread>>;
    
    public readonly alerts: CacheEdge<Alert>;
    
    
    public readonly alertThreads: ObservableProperty<(Array<ChatThread> | null)>;
    
    
    public recentGroupThreads(school: (School | null), count: number): ObservableProperty<(Array<ChatThread> | null)> {
        return xObservablePropertyMap<(Array<ChatThread> | null), (Array<ChatThread> | null)>(this.session.threads.observableListSimple(((): (ApiFilter<ChatThread> | null) => {
                        const temp4417 = (school?.id ?? null);
                        if(temp4417 === null) { return null }
                        return ((it: number): ApiFilter<ChatThread> => ApiFilter.Companion.INSTANCE.all<ChatThread>(ChatThread.Companion.INSTANCE.isGroupThread(), ChatThread.Companion.INSTANCE.forSchool(it)))(temp4417)
            })() ?? ChatThread.Companion.INSTANCE.isGroupThread(), ChatThread.Companion.INSTANCE.mostRecent, count, undefined, undefined, undefined), (it: (Array<ChatThread> | null)): (Array<ChatThread> | null) => ((): (Array<ChatThread> | null) => {
                const temp4419 = ((): (Array<ChatThread> | null) => {
                    const temp4421 = it;
                    if(temp4421 === null) { return null }
                    return temp4421.slice(0, count)
                })();
                if(temp4419 === null) { return null }
                const temp4420 = (it: ChatThread): (string | null) => ((): (string | null) => {
                    const temp4422 = it.name;
                    if(temp4422 === null) { return null }
                    return temp4422.toLowerCase()
                })() ?? "";
                return temp4419.slice().sort((a, b) => safeCompare(temp4420(a), temp4420(b)))
        })());
    }
    
    
    
    
    public recentUserThreads(school: (School | null), count: number): FlatMappedObservableProperty<(Array<MessagesVG.ChatThreadWithUser> | null), (Array<MessagesVG.ChatThreadWithUser> | null)> {
        return xObservablePropertyFlatMapNotNull<Array<MessagesVG.ChatThreadWithUser>, Array<MessagesVG.ChatThreadWithUser>>(xObservablePropertyMap<(Array<MessagesVG.ChatThreadWithUser> | null), (Array<MessagesVG.ChatThreadWithUser> | null)>(xObservablePropertyFlatMapNotNull2<Array<ChatThread>, Array<MessagesVG.ChatThreadWithUser>>(this.session.threads.observableListSimple(((): (ApiFilter<ChatThread> | null) => {
                                const temp4423 = (school?.id ?? null);
                                if(temp4423 === null) { return null }
                                return ((it: number): ApiFilter<ChatThread> => ApiFilter.Companion.INSTANCE.all<ChatThread>(ChatThread.Companion.INSTANCE.isUserThread(), ChatThread.Companion.INSTANCE.forSchool(it)))(temp4423)
                    })() ?? ChatThread.Companion.INSTANCE.isUserThread(), ChatThread.Companion.INSTANCE.mostRecent, count, undefined, undefined, undefined), (threads: Array<ChatThread>): ObservableProperty<Array<MessagesVG.ChatThreadWithUser>> => xObservablePropertyMap<Array<(MessagesVG.ChatThreadWithUser | null)>, Array<MessagesVG.ChatThreadWithUser>>(xListCombined<(MessagesVG.ChatThreadWithUser | null)>(iterToArray(iterableFilterNotNull(iterMap(threads, (thread: ChatThread): (ObservableProperty<(MessagesVG.ChatThreadWithUser | null)> | null) => ((): (ObservableProperty<(MessagesVG.ChatThreadWithUser | null)> | null) => {
                        const temp4426 = iterFirstOrNullFind(thread.userIds, (id: number): boolean => !(id === this.session.session.userId));
                        if(temp4426 === null) { return null }
                        return ((otherUserId: number): ObservableProperty<(MessagesVG.ChatThreadWithUser | null)> => xObservablePropertyMap<(User | null), (MessagesVG.ChatThreadWithUser | null)>(this.session.users.observable(otherUserId), (user: (User | null)): (MessagesVG.ChatThreadWithUser | null) => ((): (MessagesVG.ChatThreadWithUser | null) => {
                            const temp4431 = user;
                            if(temp4431 === null) { return null }
                            return ((it: User): MessagesVG.ChatThreadWithUser => new MessagesVG.ChatThreadWithUser(thread, user!))(temp4431)
                        })()))(temp4426)
                })())))), (it: Array<(MessagesVG.ChatThreadWithUser | null)>): Array<MessagesVG.ChatThreadWithUser> => listFilterNotNull(it))), (it: (Array<MessagesVG.ChatThreadWithUser> | null)): (Array<MessagesVG.ChatThreadWithUser> | null) => ((): (Array<MessagesVG.ChatThreadWithUser> | null) => {
                    const temp4434 = (it: MessagesVG.ChatThreadWithUser): (string | null) => it.user.name.toLowerCase();
                    const temp4433 = ((): (Array<MessagesVG.ChatThreadWithUser> | null) => {
                        const temp4436 = it;
                        if(temp4436 === null) { return null }
                        return temp4436.slice(0, count)
                    })();
                    if(temp4433 === null) { return null }
                    return temp4433.slice().sort((a, b) => safeCompare(temp4434(a), temp4434(b)))
            })()), (threads: Array<MessagesVG.ChatThreadWithUser>): ObservableProperty<(Array<MessagesVG.ChatThreadWithUser> | null)> => ((): ObservableProperty<(Array<MessagesVG.ChatThreadWithUser> | null)> => {
                if (threads.length < count) { return xObservablePropertyMap<(Array<User> | null), (Array<MessagesVG.ChatThreadWithUser> | null)>(this.session.users.observableListSimple(((this_: MessagesVG): ApiFilter<User> => {
                                const filters = ([] as Array<ApiFilter<User>>);
                                
                                const it_4439 = this_.session.me.value;
                                if (it_4439 !== null) {
                                    filters.push(it_4439.chatUsers());
                                }
                                const it_4441 = (school?.id ?? null);
                                if (it_4441 !== null) {
                                    filters.push(User.Companion.INSTANCE.belongingToSchool(it_4441));
                                }
                                return ApiFilter.Companion.INSTANCE.allList<User>(filters);
                    })(this), User.Companion.INSTANCE.nameSort, count - threads.length, undefined, undefined, true), (it: (Array<User> | null)): (Array<MessagesVG.ChatThreadWithUser> | null) => ((): (Array<MessagesVG.ChatThreadWithUser> | null) => {
                        const temp4444 = ((): (Array<User> | null) => {
                            const temp4446 = ((): (Array<User> | null) => {
                                const temp4448 = it;
                                if(temp4448 === null) { return null }
                                return temp4448.slice(0, count - threads.length)
                            })();
                            if(temp4446 === null) { return null }
                            return temp4446.filter((it: User): boolean => !(it.id === this.session.session.userId))
                        })();
                        if(temp4444 === null) { return null }
                        return ((it: Array<User>): Array<MessagesVG.ChatThreadWithUser> => threads.concat(it.map((it: User): MessagesVG.ChatThreadWithUser => new MessagesVG.ChatThreadWithUser(undefined, it))))(temp4444)
                })()) } else { return new ConstantObservableProperty<(Array<MessagesVG.ChatThreadWithUser> | null)>(threads) }
        })());
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new MessagesXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Startup
        if (this.firstStartup) {
            this.firstStartup = false;
            xDisposableForever<SubscriptionLike>(this.threads.subscribe((threads: Array<ChatThread>): void => {
                post((): void => {
                    const alertThreads = threads.filter((it: ChatThread): boolean => it.alert !== null && (this.schoolFilter.value === null || ((): (boolean | null) => {
                        const temp4460 = (this.schoolFilter.value?.id ?? null);
                        if(temp4460 === null) { return null }
                        return ((id: number): boolean => it.schools.some((x) => safeEq(id, x)))(temp4460)
                    })() === true));
                    
                    if (alertThreads.length === 1) {
                        const alertThread = alertThreads[0];
                        
                        if (safeEq(Platform.Companion.INSTANCE.current, Platform.Web)) {
                            this.selectedThread.value = alertThread.id;
                        }
                        this.cellXmlRootClick(alertThread.id, alertThread.name, xml.chatSubview !== null);
                    }
                });
            }, (it: any): void => {}));
        }
        
        //--- Set Up xml.chatSubview
        const subview_4464 = xml.chatSubview;
        if (subview_4464 !== null) {
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<number>(this.selectedThread, undefined, undefined, (it: number): void => {
                if (it > 0) {
                    this.chatSubstack.reset(new ConversationDetailVG(this.dialog, this.session, it, undefined, this.schoolFilter, this.stack));
                } else {
                    this.chatSubstack.reset(new PlaceholderSelectConversationVG());
                }
            }), xViewRemovedGet(subview_4464));
            xSwapViewBindStack<ViewGenerator>(subview_4464, dependency, this.chatSubstack);
        }
        if (xml.chatSubview !== null) {
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Alert> | null)>(this.alerts, undefined, undefined, (it: (Array<Alert> | null)): void => {
                if (it !== null && it!.some((it: Alert): boolean => (!it.drill))) {
                    setViewBackgroundColor(xml.messagesLoader, R.color.barBackgroundAlertFade2)
                } else if (it !== null && it!.length !== 0) {
                    setViewBackgroundColor(xml.messagesLoader, R.color.barBackgroundDrillFade2)
                } else  {
                    setViewBackgroundColor(xml.messagesLoader, R.color.barBackgroundFade2)
                }
            }), xViewRemovedGet(xml.messagesLoader));
        }
        
        
        //--- Last update info
        const lastReadKey = "com.tresitgroup.android.tresit.vg.chat.MessagesVG.lastRead";
        
        const lastReadStamp: number = Preferences.INSTANCE.get<number>([Number], lastReadKey) ?? Date.now();
        
        Preferences.INSTANCE.set<number>([Number], lastReadKey, Date.now());
        console.info(`${"ChatVG"}: ${`lastReadStamp: ${new Date()}`}`);
        
        //--- Measure time!
        const screenHeightDp: number = DisplayMetrics.INSTANCE.heightPixels / DisplayMetrics.INSTANCE.density;
        
        const takenSpace: number = 48 * 6.5;
        
        const availableScreenDp: number = screenHeightDp - takenSpace;
        
        const totalItemsToPull = Math.floor((availableScreenDp / 42));
        
        
        const groupThreads = xObservablePropertyShare<(Array<ChatThread> | null)>(xObservablePropertyFlatMap<(School | null), (Array<ChatThread> | null)>(this.schoolFilter, (it: (School | null)): ObservableProperty<(Array<ChatThread> | null)> => this.recentGroupThreads(it, 3)), undefined);
        
        
        const userThreads = xObservablePropertyShare<(Array<MessagesVG.ChatThreadWithUser> | null)>(xObservablePropertyMap<(Array<MessagesVG.ChatThreadWithUser> | null), (Array<MessagesVG.ChatThreadWithUser> | null)>(xObservablePropertyFlatMap<(School | null), (Array<MessagesVG.ChatThreadWithUser> | null)>(this.schoolFilter, (schoolFilter: (School | null)): ObservableProperty<(Array<MessagesVG.ChatThreadWithUser> | null)> => {
                    const alert = ((): (ChatThread | null) => {
                        const temp4479 = this.alertThreads.value;
                        if(temp4479 === null) { return null }
                        return iterFirstOrNullFind(temp4479, (it: ChatThread): boolean => (it.schools[0] ?? null) === (schoolFilter?.id ?? null))
                    })();
                    
                    if (alert !== null) {
                        //this feature is just for web
                        if (safeEq(Platform.Companion.INSTANCE.current, Platform.Web)) {
                            this.cellXmlRootClick(alert!.id, alert!.name, xml.chatSubview !== null);
                        }
                    } else {
                        this.selectedThread.value = (-1);
                    }
                    return this.recentUserThreads(schoolFilter, cMax((totalItemsToPull - 3), 3));
            }), (it: (Array<MessagesVG.ChatThreadWithUser> | null)): (Array<MessagesVG.ChatThreadWithUser> | null) => ((): (Array<MessagesVG.ChatThreadWithUser> | null) => {
                if(it !== null) {
                    return xIterableDistinctBy<MessagesVG.ChatThreadWithUser, number>(it, (it: MessagesVG.ChatThreadWithUser): number => it.user.id)
                } else { return null }
        })()), undefined);
        
        
        //--- Set Up xml.messagesLoader
        xViewFlipperBindLoading(xml.messagesLoader, new ConstantObservableProperty<boolean>(false), undefined);
        xViewFlipperBindLoading(xml.messagesLoader, combinedAndMap<boolean, boolean>([xObservablePropertyMap<(Array<ChatThread> | null), boolean>(this.alertThreads, (it: (Array<ChatThread> | null)): boolean => it === null), xObservablePropertyMap<(Array<ChatThread> | null), boolean>(groupThreads, (it: (Array<ChatThread> | null)): boolean => it === null), xObservablePropertyMap<(Array<MessagesVG.ChatThreadWithUser> | null), boolean>(userThreads, (it: (Array<MessagesVG.ChatThreadWithUser> | null)): boolean => it === null)], (it: Array<boolean>): boolean => it.every((it: boolean): boolean => it)), undefined);
        
        //--- Set Up xml.alertsHeader
        xViewBindExists(xml.alertsHeader, xObservablePropertyMap<(Array<ChatThread> | null), boolean>(this.alertThreads, (it: (Array<ChatThread> | null)): boolean => (((): (number | null) => {
            const temp4487 = it;
            if(temp4487 === null) { return null }
            return temp4487.length
        })() ?? 0) > 0));
        
        //--- Set Up xml.alerts
        xLinearLayoutBind<ChatThread>(xml.alerts, xObservablePropertyMap<(Array<ChatThread> | null), Array<ChatThread>>(this.alertThreads, (it: (Array<ChatThread> | null)): Array<ChatThread> => it ?? []), new ChatThread(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<ChatThread>): HTMLElement => {
            //--- Make Subview For xml.alerts
            const cellXml = new ComponentRowConversationAlertXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.alertTypeIcon
            const alert: ObservableProperty<(Alert | null)> = xObservablePropertyFlatMapNotNull<number, Alert>(xObservablePropertyMap<ChatThread, (number | null)>(observable, (it: ChatThread): (number | null) => it.alert), (alertId: number): ObservableProperty<(Alert | null)> => this.session.alerts.observable(alertId));
            
            
            const type: ObservableProperty<(AlertType | null)> = xObservablePropertyFlatMapNotNull<number, AlertType>(xObservablePropertyMap<(Alert | null), (number | null)>(alert, (it: (Alert | null)): (number | null) => (it?.name ?? null)), (it: number): ObservableProperty<(AlertType | null)> => this.session.alertTypes.observable(it));
            
            
            xImageViewBindImage(cellXml.alertTypeIcon, xObservablePropertyMap<(AlertType | null), (Image | null)>(type, (it: (AlertType | null)): (Image | null) => ((): (Image | null) => {
                const temp4488 = (it?.icon ?? null);
                if(temp4488 !== null) {
                    return xStringAsImage(temp4488)
                } else { return null }
            })()));
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.root, undefined, (): void => {
                this.cellXmlRootClick(observable.value.id, observable.value.name, xml.chatSubview !== null);
            });
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<ChatThread, string>(observable, (it: ChatThread): string => it.name ?? "ALERT"));
            
            //--- Set Up cellXml.unreadIndicator
            xViewBindExists(cellXml.unreadIndicator, unreadObs(this.session, xObservablePropertyMap<ChatThread, number>(observable, (it: ChatThread): number => it.id)));
            
            //--- Selectable
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Alert> | null)>(this.session.alerts.observableListSimple(undefined, undefined, undefined, undefined, undefined, undefined), undefined, undefined, (alerts: (Array<Alert> | null)): void => {
                if (alerts !== null && alerts!.some((it: Alert): boolean => (!it.drill))) {
                    setViewBackgroundColor(cellXml.xmlRoot, R.color.alertLight)
                } else if (alerts !== null && alerts!.length !== 0) {
                    setViewBackgroundColor(cellXml.xmlRoot, R.color.drill)
                } else  {
                    setViewBackgroundColor(cellXml.xmlRoot, R.color.colorPrimary)
                }
            }), xViewRemovedGet(cellView));
            
            if (xml.chatSubview !== null) {
                const selected = xObservablePropertyCombine<number, ChatThread, boolean>(this.selectedThread, observable, (sel: number, curr: ChatThread): boolean => sel === curr.id);
                
                const temp4498 = cellXml.selectedIndicator;
                if(temp4498 !== null) {
                    xViewBindVisible(temp4498, selected)
                };
            }
            
            //--- End Make Subview For xml.alerts
            return cellView;
        });
        //--- Set Up xml.groupsHeader
        //--- Set Up xml.groups
        xLinearLayoutBind<ChatThread>(xml.groups, xObservablePropertyMap<(Array<ChatThread> | null), Array<ChatThread>>(groupThreads, (it: (Array<ChatThread> | null)): Array<ChatThread> => it ?? []), new ChatThread(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<ChatThread>): HTMLElement => {
            //--- Make Subview For xml.groups
            const cellXml = new ComponentRowConversationXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            /*  xml.chatSubview?.let { subview ->
                chatSubstack.reset(PlaceholderSelectConversationVG())
                subview.bindStack(dependency, chatSubstack)
            }*/
            //--- Conveniences
            
            //--- Set Up cellXml.convRoot
            xViewOnClick(cellXml.convRoot, undefined, (): void => {
                this.cellXmlRootClick(observable.value.id, observable.value.name, xml.chatSubview !== null);
            });
            
            //--- Set Up cellXml.name
            const name = xObservablePropertyFlatMap<ChatThread, string>(observable, (it: ChatThread): ObservableProperty<string> => ((): (ConstantObservableProperty<string> | null) => {
                        const temp4499 = it.name;
                        if(temp4499 === null) { return null }
                        return ((name: string): ConstantObservableProperty<string> => {
                            console.log(`vitra gako name:${name}`);
                            return new ConstantObservableProperty<string>(name);
                        })(temp4499)
                })() ?? xObservablePropertyMap<Array<(string | null)>, string>(xListCombined<(string | null)>((
                    it.userIds.filter((it: number): boolean => !(it === this.session.session.userId)).map((it: number): ObservableProperty<(string | null)> => xObservablePropertyMap<(User | null), (string | null)>(this.session.users.observable(it), (it: (User | null)): (string | null) => (it?.name ?? null))).concat(it.schools.map((it: number): ObservableProperty<(string | null)> => xObservablePropertyMap<(School | null), (string | null)>(this.session.schools.observable(it), (it: (School | null)): (string | null) => (it?.name ?? null))))
            )), (it: Array<(string | null)>): string => listFilterNotNull(it).join(", ")));
            
            
            
            
            xTextViewBindString(cellXml.name, name);
            
            //--- Set Up cellXml.unreadIndicator
            xViewBindExists(cellXml.unreadIndicator, unreadObs(this.session, xObservablePropertyMap<ChatThread, number>(observable, (it: ChatThread): number => it.id)));
            
            //--- Set Up cellXml.more
            cellView.oncontextmenu = (_ev) => { _ev.preventDefault();
                const it = _ev.target as HTMLElement;
                if (observable.value.userIds.length !== 0 && observable.value.name !== null) {
                    this.cellXmlMoreClick(observable.value);
                }
                return true;
            };
            
            //--- Selectable
            if (xml.chatSubview !== null) {
                const selected = xObservablePropertyCombine<number, ChatThread, boolean>(this.selectedThread, observable, (sel: number, curr: ChatThread): boolean => sel === curr.id);
                
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(selected, undefined, undefined, (it: boolean): void => {
                    if (it) {
                        cellXml.name.style.opacity = `${1}`;
                    } else {
                        cellXml.name.style.opacity = `${0.5}`;
                    }
                }), xViewRemovedGet(cellView));
                const temp4516 = cellXml.selectedIndicator;
                if(temp4516 !== null) {
                    xViewBindVisible(temp4516, selected)
                };
            }
            //--- End Make Subview For xml.groups
            return cellView;
        });
        //--- Set Up xml.groupsMore
        xViewOnClick(xml.groupsMore, undefined, (): void => {
            this.groupsMoreClick(xml.chatSubview !== null);
        });
        
        //--- Set Up xml.contactsHeader
        //--- Set Up xml.contacts
        xLinearLayoutBind<MessagesVG.ChatThreadWithUser>(xml.contacts, xObservablePropertyMap<(Array<MessagesVG.ChatThreadWithUser> | null), Array<MessagesVG.ChatThreadWithUser>>(userThreads, (it: (Array<MessagesVG.ChatThreadWithUser> | null)): Array<MessagesVG.ChatThreadWithUser> => it ?? []), new MessagesVG.ChatThreadWithUser(undefined, undefined), (observable: ObservableProperty<MessagesVG.ChatThreadWithUser>): HTMLElement => {
            //--- Make Subview For xml.contacts
            const cellXml = new ComponentRowConversationContactXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Conveniences
            const belongsToSchoolWithAlert: ObservableProperty<boolean> = xObservablePropertyMap<Array<School>, boolean>(xObservablePropertyMap<(Array<School> | null), Array<School>>(this.session.schools.observableListSimple(School.Companion.INSTANCE.myUserBelongsTo(), undefined, undefined, undefined, undefined, undefined), (it: (Array<School> | null)): Array<School> => ((): (Array<School> | null) => {
                const temp4517 = ((): (Array<School> | null) => {
                    const temp4519 = it;
                    if(temp4519 === null) { return null }
                    const temp4520 = (it: School): (string | null) => it.name;
                    return temp4519.slice().sort((a, b) => safeCompare(temp4520(a), temp4520(b)))
                })();
                if(temp4517 === null) { return null }
                return temp4517.filter((it: School): boolean => safeEq(it.status, AlertStatusEnum.Active))
            })() ?? []), (it: Array<School>): boolean => it.length === 0);
            
            
            //--- Set Up cellXml.convRoot
            xViewOnClick(cellXml.convRoot, undefined, (): void => {
                const value = observable.value;
                
                const thread_4521 = value.chatThread;
                if (thread_4521 !== null) {
                    this.cellXmlRootClick(thread_4521.id, thread_4521.name, xml.chatSubview !== null);
                } else {
                    xSingleCallDisplayingError<ChatThread>(this.session.threads.post(new ChatThread(undefined, undefined, undefined, undefined, [this.session.session.userId, value.user.id], undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, (thread: ChatThread): void => {
                        value.chatThread = thread;
                        this.cellXmlRootClick(thread.id, thread.name, xml.chatSubview !== null);
                    });
                }
            });
            
            //--- Set Up cellXml.unreadIndicator
            xViewBindExists(cellXml.unreadIndicator, unreadUserObs(this.session, xObservablePropertyMap<MessagesVG.ChatThreadWithUser, number>(observable, (it: MessagesVG.ChatThreadWithUser): number => it.user.id)));
            
            //--- Set Up cellXml.userStatus
            const userLocation: ObservableProperty<(Room | null)> = xObservablePropertyFlatMapNotNull<number, Room>(xObservablePropertyMap<MessagesVG.ChatThreadWithUser, (number | null)>(observable, (it: MessagesVG.ChatThreadWithUser): (number | null) => it.user.currentLocation), (it: number): ObservableProperty<(Room | null)> => this.session.rooms.observable(it));
            
            xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Room | null)>(userLocation, undefined, undefined, (room: (Room | null)): void => {
                switch((room?.safe ?? null)) {
                    case StatusEnum.Safe:
                    case StatusEnum.Cleared:
                    imageViewSetImageResource(cellXml.userStatus, R.drawable.ic_chat_green)
                    break;
                    case StatusEnum.Unsafe:
                    imageViewSetImageResource(cellXml.userStatus, R.drawable.ic_chat_red)
                    break;
                    default:
                    imageViewSetImageResource(cellXml.userStatus, R.drawable.ic_chat_white)
                    break;
                }
                
            }), xViewRemovedGet(cellXml.userStatus));
            xViewOnClick(cellXml.userStatus, undefined, (): void => {
                const it_4528 = observable.value.user.cellPhone;
                if (it_4528 !== null) {
                    xActivityAccessCall(dependency, `+1${it_4528}`);
                }
            });
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<MessagesVG.ChatThreadWithUser, string>(observable, (it: MessagesVG.ChatThreadWithUser): string => it.user.name));
            
            //--- Selectable
            if (xml.chatSubview !== null) {
                const selected = xObservablePropertyCombine<number, MessagesVG.ChatThreadWithUser, boolean>(this.selectedThread, observable, (sel: number, curr: MessagesVG.ChatThreadWithUser): boolean => sel === (curr.chatThread?.id ?? null));
                
                xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(selected, undefined, undefined, (it: boolean): void => {
                    if (it) {
                        cellXml.name.style.opacity = `${1}`;
                    } else {
                        cellXml.name.style.opacity = `${0.5}`;
                    }
                }), xViewRemovedGet(cellView));
                const temp4532 = cellXml.selectedIndicator;
                if(temp4532 !== null) {
                    xViewBindVisible(temp4532, selected)
                };
            }
            //--- End Make Subview For xml.contacts
            return cellView;
        });
        //--- Set Up xml.contactsMore
        xViewOnClick(xml.contactsMore, undefined, (): void => {
            this.contactsMoreClick(xml.chatSubview !== null);
        });
        
        //--- Set Up cellXml.root
        //--- Set Up cellXml.unreadIndicator
        //--- Set Up cellXml.userStatus
        //--- Set Up cellXml.alertTypeIcon
        //--- Set Up cellXml.name
        //--- Set Up cellXml.convRoot
        //--- Set Up cellXml.unreadIndicator
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public groupsMoreClick(substackMode: boolean): void {
        this.dialog.push(new DialogWrapperVG(new ConversationAllVG(this.dialog, (it: ChatThread): void => {
            this.cellXmlRootClick(it.id, it.name, substackMode);
        }, this.schoolFilter, this.session), this.dialog));
    }
    
    public contactsMoreClick(substackMode: boolean): void {
        this.dialog.push(new DialogWrapperVG(new ContactsVG(undefined, (user: User, working: MutableObservableProperty<boolean>): void => {
            if ((this.session.me.value?.permissionCreateConversation ?? null) === true) {
                const userIds = [this.session.session.userId, user.id];
                
                xSingleCallDisplayingError<ChatThread>(this.session.threads.getListSimple(ChatThread.Companion.INSTANCE.exactlyUsers(userIds), undefined, undefined, undefined, undefined).pipe(rxFlatMap((it: Array<ChatThread>): ObservableInput<ChatThread> => {
                                const result = (it[0] ?? null);
                                
                                if (result !== null) { return rxOf(result) } else { return this.session.threads.post(new ChatThread(undefined, undefined, undefined, undefined, userIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) }
                    })), undefined, (it: ChatThread): void => {
                        this.dialog.dismiss();
                        this.cellXmlRootClick(it.id, null, substackMode);
                });
            }
        }, this.schoolFilter, this.session), this.dialog));
    }
    
    //--- Action createGroupClick
    //--- Action cellXmlXmlRootClick
    //--- Action conversationsClick
    
    public cellXmlRootClick(threadId: ForeignKey<ChatThread>, threadName: (string | null), substackMode: boolean): void {
        if (substackMode) {
            this.selectedThread.value = threadId;
        } else {
            
            this.stack.push(new ConversationDetailVG(this.dialog, this.session, threadId, threadName, this.schoolFilter, this.stack));
        }
    }
    
    
    public cellXmlMoreClick(thread: ChatThread): void {
        this.dialog.push(new ConversationOptionsVG(this.dialog, this.session, thread.id, this.schoolFilter, this.stack));
    }
    
    
    //--- Action cellXmlConvRootClick
    
    //--- Body End
}
export namespace MessagesVG {
    //! Declares com.tresitgroup.android.tresit.vg.chat.MessagesVG.ChatThreadWithUser
    export class ChatThreadWithUser {
        public chatThread: (ChatThread | null);
        public user: User;
        public constructor(chatThread: (ChatThread | null) = null, user: User = new User(undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) {
            this.chatThread = chatThread;
            this.user = user;
        }
    }
}
