// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/StatusEnum.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../R'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.StatusEnum
export class StatusEnum {
    public readonly comparableValue: number;
    public readonly darkColorResource: string;
    public readonly colorResource: string;
    public readonly textResource: string;
    private constructor(name: string, jsonName: string, comparableValue: number, darkColorResource: string, colorResource: string, textResource: string) {
        this.name = name;
        this.jsonName = jsonName;
        this.comparableValue = comparableValue;
        this.darkColorResource = darkColorResource;
        this.colorResource = colorResource;
        this.textResource = textResource;
    }
    
    public static Safe = new StatusEnum("Safe", "safe", 3, R.color.safeDark, R.color.safe, R._string.safe);
    
    public static Unsafe = new StatusEnum("Unsafe", "unsafe", 1, R.color.unsafeDark, R.color.unsafe, R._string.unsafe);
    
    public static Cleared = new StatusEnum("Cleared", "cleared", 4, R.color.clearedDark, R.color.cleared, R._string.cleared);
    
    public static Unknown = new StatusEnum("Unknown", "unknown", 2, R.color.unknownDark, R.color.unknown, R._string.unknown);
    
    private static _values: Array<StatusEnum> = [StatusEnum.Safe, StatusEnum.Unsafe, StatusEnum.Cleared, StatusEnum.Unknown];
    public static values(): Array<StatusEnum> { return StatusEnum._values; }
    public readonly name: string;
    public readonly jsonName: string;
    public static valueOf(name: string): StatusEnum { return (StatusEnum as any)[name]; }
    public toString(): string { return this.name }
    public toJSON(): string { return this.jsonName }
}

//! Declares com.tresitgroup.android.tresit.model.statusColorRes
export function statusColorRes(status: (StatusEnum | null)): string {
    return (status?.darkColorResource ?? null) ?? R.color.foreground;
}

//! Declares com.tresitgroup.android.tresit.model.statusTextRes
export function statusTextRes(status: (StatusEnum | null)): string {
    return (status?.textResource ?? null) ?? R._string.none;
}