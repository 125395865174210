//
// RowPostXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './row_post.html'
//! Declares com.tresitgroup.android.tresit.layouts.RowPostXml
export class RowPostXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    title!: HTMLElement
    personType!: HTMLElement
    name!: HTMLElement
    time!: HTMLElement
    resolved!: HTMLImageElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.title = getViewById<HTMLElement>(view, "title")
        this.personType = getViewById<HTMLElement>(view, "personType")
        this.name = getViewById<HTMLElement>(view, "name")
        this.time = getViewById<HTMLElement>(view, "time")
        this.resolved = getViewById<HTMLImageElement>(view, "resolved")
        return view
    }
}
