// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/offsite.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { Floor } from '../../model/Floor'
import { Building } from '../../model/Building'
import { Exception } from 'butterfly-web/dist/Kotlin'
import { Observable, ObservableInput } from 'rxjs'
import { flatMap as rxFlatMap, map as rxMap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { find as iterFind, first as iterFirst } from 'butterfly-web/dist/kotlin/lazyOp'
import { Room } from '../../model/Room'
import { School } from '../../model/School'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.map.isOffsite>com.tresitgroup.android.tresit.model.Building
export function xBuildingIsOffsite(this_: Building): boolean {
    return this_.name.toLowerCase() === "offsite" || this_.name.toLowerCase() === "off site" || this_.name.toLowerCase() === "off-site";
}

//! Declares com.tresitgroup.android.tresit.vg.map.offsiteForSchool>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiOffsiteForSchool(this_: SessionApi, schoolId: ForeignKey<School>): Observable<Room> {
    return this_.buildings.getListSimple(Building.Companion.INSTANCE.belongingToSchool(schoolId), undefined, undefined, undefined, undefined).pipe(rxMap((it: Array<Building>): Building => {
        const r = iterFind(it, (it: Building): boolean => xBuildingIsOffsite(it));
        
        if (r !== null) {
            return r;
        }
        throw new Exception("No offsite room found.", undefined);
    })).pipe(rxFlatMap((offsiteBuilding: Building): ObservableInput<Floor> => this_.floors.getListSimple(Floor.Companion.INSTANCE.forBuilding(offsiteBuilding.id), undefined, undefined, undefined, undefined).pipe(rxMap((it: Array<Floor>): Floor => {
        if (it.length > 0) {
            return iterFirst(it);
        }
        throw new Exception("No offsite room found.", undefined);
    })))).pipe(rxFlatMap((it: Floor): ObservableInput<Room> => this_.rooms.getListSimple(Room.Companion.INSTANCE.forFloor(it.id), undefined, undefined, undefined, undefined).pipe(rxMap((it: Array<Room>): Room => {
        if (it.length > 0) {
            return iterFirst(it);
        }
        throw new Exception("No offsite room found.", undefined);
    }))));
    
    //            rooms.getList(Room.forFloor(it.id)).map {
        //                it.first()
    //            }
}

//! Declares com.tresitgroup.android.tresit.vg.map.offsiteLocation>com.tresitgroup.android.tresit.api.SessionApi
export function xSessionApiOffsiteLocation(this_: SessionApi, schoolId: ForeignKey<School>): ObservableProperty<(Room | null)> {
    return xObservablePropertyFlatMapNotNull<Floor, Room>(xObservablePropertyFlatMapNotNull<Building, Floor>(xObservablePropertyMap<(Array<Building> | null), (Building | null)>(this_.buildings.observableListSimple(Building.Companion.INSTANCE.belongingToSchool(schoolId), undefined, undefined, undefined, undefined, undefined), (it: (Array<Building> | null)): (Building | null) => ((): (Building | null) => {
                        const temp5197 = it;
                        if(temp5197 === null) { return null }
                        return iterFind(temp5197, (it: Building): boolean => xBuildingIsOffsite(it))
            })()), (offsiteBuilding: Building): ObservableProperty<(Floor | null)> => xObservablePropertyMap<(Array<Floor> | null), (Floor | null)>(this_.floors.observableListSimple(Floor.Companion.INSTANCE.forBuilding(offsiteBuilding.id), undefined, undefined, undefined, undefined, undefined), (it: (Array<Floor> | null)): (Floor | null) => ((): (Floor | null) => {
                const temp5199 = it;
                if(temp5199 === null) { return null }
                return (temp5199[0] ?? null)
        })())), (it: Floor): ObservableProperty<(Room | null)> => xObservablePropertyMap<(Array<Room> | null), (Room | null)>(this_.rooms.observableListSimple(Room.Companion.INSTANCE.forFloor(it.id), undefined, undefined, undefined, undefined, undefined), (it: (Array<Room> | null)): (Room | null) => ((): (Room | null) => {
            const temp5200 = it;
            if(temp5200 === null) { return null }
            return (temp5200[0] ?? null)
    })()));
}