// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/board/RowPostVG.kt
// Package: com.tresitgroup.android.tresit.vg.board
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { R } from '../../R'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { PostCompleteVG } from './PostCompleteVG'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { RowPostXml } from '../../layout/RowPostXml'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { PostDetailVG } from './PostDetailVG'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { ForeignKey } from '../../model/ForeignKey'
import { imageViewSetImageResource } from 'butterfly-web/dist/views/ImageView'
import { User } from '../../model/User'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { BoardPost } from '../../model/BoardPost'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.board.RowPostVG
export class RowPostVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly observable: ObservableProperty<BoardPost>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, observable: ObservableProperty<BoardPost>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.observable = observable;
        this.session = session;
        this.isResolved = xObservablePropertyMap<BoardPost, boolean>(this.observable, (it: BoardPost): boolean => it.resolvedAt !== null);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.board.RowPostVG.postId
    public get postId(): ForeignKey<BoardPost> { return this.observable.value.id; }
    
    
    public readonly isResolved: ObservableProperty<boolean>;
    
    public person(): ObservableProperty<(User | null)> {
        return xObservablePropertyFlatMapNotNull<number, User>(xObservablePropertyMap<BoardPost, (number | null)>(this.observable, (it: BoardPost): (number | null) => it.resolvedBy ?? it.author), (it: number): ObservableProperty<(User | null)> => this.session.users.observable(it));
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.board.RowPostVG.title
    public get title(): string { return "Row Post"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new RowPostXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Conveniences
        const person = this.person();
        
        
        //--- Set Up xml.root
        xViewOnClick(xml.root, undefined, (): void => {
            this.rootClick();
        });
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(this.isResolved, undefined, undefined, (it: boolean): void => {
            xml.root.style.opacity = `${(it ? 0.5 : 1)}`;
        }), xViewRemovedGet(xml.root));
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<BoardPost, string>(this.observable, (it: BoardPost): string => it.title));
        
        //--- Set Up xml.personType
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<BoardPost>(this.observable, undefined, undefined, (it: BoardPost): void => {
            if (it.resolvedBy !== null) { setViewText(xml.personType, R._string.found_by) } else { setViewText(xml.personType, R._string.submitted_by) }
        }), xViewRemovedGet(xml.personType));
        
        //--- Set Up xml.name
        xTextViewBindString(xml.name, xObservablePropertyMap<(User | null), string>(person, (it: (User | null)): string => {
            if (it !== null) {
                return `${it!.firstName} ${it!.lastName}`;
            } else {
                return "";
            }
        }));
        
        //--- Set Up xml.time
        xTextViewBindString(xml.time, xObservablePropertyMap<Date, string>(xObservablePropertyMap<BoardPost, Date>(this.observable, (it: BoardPost): Date => it.resolvedAt ?? it.created), (it: Date): string => xDateFormat(it, ClockPartSize.Medium, ClockPartSize.Short)));
        //--- Set Up xml.resolved
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<boolean>(this.isResolved, undefined, undefined, (it: boolean): void => {
            imageViewSetImageResource(xml.resolved, it ? R.drawable.green_check : R.drawable.green_check_empty);
        }), xViewRemovedGet(xml.resolved));
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public rootClick(): void {
        if (this.observable.value.resolvedAt !== null) {
            this.dialog.push(new PostDetailVG(this.postId, this.session, this.dialog));
        } else {
            this.dialog.push(new PostCompleteVG(this.postId, this.session, this.dialog));
        }
    }
    
    //--- Body End
}
