// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/BoardPost.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { Time } from '../util/time'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { Alert } from './Alert'
import { HasId, getNO_ID } from './HasId'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ApiModifier } from '../api/ApiModifier'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.BoardPost
export class BoardPost implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly title: string;
    public readonly content: string;
    public readonly alert: (ForeignKey<Alert> | null);
    public readonly school: ForeignKey<School>;
    public readonly author: (ForeignKey<User> | null);
    public readonly parentPost: (ForeignKey<BoardPost> | null);
    public readonly created: Date;
    public readonly resolvedBy: (ForeignKey<User> | null);
    public readonly resolvedAt: (Date | null);
    public readonly resolvedMessage: string;
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, title: string = "", content: string = "", alert: (ForeignKey<Alert> | null) = null, school: ForeignKey<School>, author: (ForeignKey<User> | null), parentPost: (ForeignKey<BoardPost> | null) = null, created: Date = Time.INSTANCE.date(), resolvedBy: (ForeignKey<User> | null) = null, resolvedAt: (Date | null) = null, resolvedMessage: string = "") {
        this.id = id;
        this.deleted = deleted;
        this.title = title;
        this.content = content;
        this.alert = alert;
        this.school = school;
        this.author = author;
        this.parentPost = parentPost;
        this.created = created;
        this.resolvedBy = resolvedBy;
        this.resolvedAt = resolvedAt;
        this.resolvedMessage = resolvedMessage;
    }
    public static fromJson(obj: any): BoardPost { return new BoardPost(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["title"], [String]) as string,
        parseJsonTyped(obj["content"], [String]) as string,
        parseJsonTyped(obj["alert_id"], [Number]) as (number | null),
        parseJsonTyped(obj["school_id"], [Number]) as number,
        parseJsonTyped(obj["author_id"], [Number]) as (number | null),
        parseJsonTyped(obj["parent"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["resolved_by"], [Number]) as (number | null),
        parseJsonTyped(obj["resolved_at"], [Date]) as (Date | null),
        parseJsonTyped(obj["resolved_message"], [String]) as string
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        title: this.title,
        content: this.content,
        alert_id: this.alert,
        school_id: this.school,
        author_id: this.author,
        parent: this.parentPost,
        created: this.created,
        resolved_by: this.resolvedBy,
        resolved_at: this.resolvedAt,
        resolved_message: this.resolvedMessage
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.title);
        hash = 31 * hash + hashAnything(this.content);
        hash = 31 * hash + hashAnything(this.alert);
        hash = 31 * hash + hashAnything(this.school);
        hash = 31 * hash + hashAnything(this.author);
        hash = 31 * hash + hashAnything(this.parentPost);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.resolvedBy);
        hash = 31 * hash + hashAnything(this.resolvedAt);
        hash = 31 * hash + hashAnything(this.resolvedMessage);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof BoardPost && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.title, other.title) && safeEq(this.content, other.content) && safeEq(this.alert, other.alert) && safeEq(this.school, other.school) && safeEq(this.author, other.author) && safeEq(this.parentPost, other.parentPost) && safeEq(this.created, other.created) && safeEq(this.resolvedBy, other.resolvedBy) && safeEq(this.resolvedAt, other.resolvedAt) && safeEq(this.resolvedMessage, other.resolvedMessage) }
    public toString(): string { return `BoardPost(id=${this.id}, deleted=${this.deleted}, title=${this.title}, content=${this.content}, alert=${this.alert}, school=${this.school}, author=${this.author}, parentPost=${this.parentPost}, created=${this.created}, resolvedBy=${this.resolvedBy}, resolvedAt=${this.resolvedAt}, resolvedMessage=${this.resolvedMessage})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, title: string = this.title, content: string = this.content, alert: (ForeignKey<Alert> | null) = this.alert, school: ForeignKey<School> = this.school, author: (ForeignKey<User> | null) = this.author, parentPost: (ForeignKey<BoardPost> | null) = this.parentPost, created: Date = this.created, resolvedBy: (ForeignKey<User> | null) = this.resolvedBy, resolvedAt: (Date | null) = this.resolvedAt, resolvedMessage: string = this.resolvedMessage): BoardPost { return new BoardPost(id, deleted, title, content, alert, school, author, parentPost, created, resolvedBy, resolvedAt, resolvedMessage); }
    
    public matches(filter: string): boolean {
        const parts = filter.split(' ');
        
        return parts.every((it: string): boolean => (this.title.toLowerCase().indexOf(it.toLowerCase()) != -1) ||
            (this.content.toLowerCase().indexOf(it.toLowerCase()) != -1) ||
        (this.resolvedMessage.toLowerCase().indexOf(it.toLowerCase()) != -1));
    }
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace BoardPost {
    //! Declares com.tresitgroup.android.tresit.model.BoardPost.Companion
    export class Companion {
        private constructor() {
            this.createdSort = ApiSort.constructorStringComparatorHasId<BoardPost>("sort=created", (o1: BoardPost, o2: BoardPost): number => (-safeCompare(o1.created, o2.created)));
            this.forSomeAlert = ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>("alert_id__isnull=true", undefined, (it: BoardPost): boolean => it.alert === null);
        }
        public static INSTANCE = new Companion();
        
        public readonly createdSort: ApiSort<BoardPost>;
        
        
        public inReplyTo(id: ForeignKey<BoardPost>): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>(`parent=${id}`, undefined, (it: BoardPost): boolean => it.parentPost === id);
        }
        
        public topLevelForSchool(id: ForeignKey<School>): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>(`school_id=${id}&top_level=1`, undefined, (it: BoardPost): boolean => it.school === id && it.parentPost === null);
        }
        
        public forAlert(id: ForeignKey<Alert>): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>(`alert_id=${id}&top_level=1`, undefined, (it: BoardPost): boolean => it.alert === id && it.parentPost === null);
        }
        
        public forAlerts(ids: Array<ForeignKey<Alert>>): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>(`alert_ids=${ids.join(", ")}`, undefined, (it: BoardPost): boolean => ids.some((x) => safeEq(it.alert ?? (-1), x)) && it.parentPost === null);
        }
        
        public topLevelOnly(): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>("top_level=true", undefined, (it: BoardPost): boolean => it.parentPost === null);
        }
        public unresolved(): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>("unresolved=true", undefined, (it: BoardPost): boolean => it.resolvedAt === null);
        }
        public forSchool(school: ForeignKey<School>): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>(`school_id=${school}`, undefined, (it: BoardPost): boolean => it.school === school);
        }
        
        public readonly forSomeAlert: ApiFilter<BoardPost>;
        
        
        public textSearch(query: string): ApiFilter<BoardPost> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<BoardPost>(`query=${query}`, undefined, (it: BoardPost): boolean => (it.title.toLowerCase().indexOf(query.toLowerCase()) != -1));
        }
        
        public resolve(by: ForeignKey<User>, message: string): ApiModifier<BoardPost> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<BoardPost>("resolved_message", message, (it: BoardPost): BoardPost => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, by, new Date(), message));
        }
        
    }
}