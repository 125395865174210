//
// FormUserDetailXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { PartSchoolSelectXml } from './PartSchoolSelectXml'
import htmlForDefault from './form_user_detail.html'
//! Declares com.tresitgroup.android.tresit.layouts.FormUserDetailXml
export class FormUserDetailXml {
    xmlRoot!: HTMLElement
    title!: HTMLElement
    firstName!: HTMLInputElement
    lastName!: HTMLInputElement
    cellPhone!: HTMLInputElement
    email!: HTMLInputElement
    passwordReset!: HTMLButtonElement
    isVip!: HTMLInputElement
    userType!: HTMLSelectElement
    userSchoolsLoading!: HTMLDivElement
    userSchools!: HTMLDivElement
    newSchool!: HTMLDivElement
    addSite!: HTMLButtonElement
    newSchoolSelect!: PartSchoolSelectXml
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.title = getViewById<HTMLElement>(view, "title")
        this.firstName = getViewById<HTMLInputElement>(view, "firstName")
        this.lastName = getViewById<HTMLInputElement>(view, "lastName")
        this.cellPhone = getViewById<HTMLInputElement>(view, "cellPhone")
        this.email = getViewById<HTMLInputElement>(view, "email")
        this.passwordReset = getViewById<HTMLButtonElement>(view, "passwordReset")
        this.isVip = getViewById<HTMLInputElement>(view, "is_vip")
        this.userType = getViewById<HTMLSelectElement>(view, "userType")
        this.userSchoolsLoading = getViewById<HTMLDivElement>(view, "userSchoolsLoading")
        this.userSchools = getViewById<HTMLDivElement>(view, "userSchools")
        this.newSchool = getViewById<HTMLDivElement>(view, "newSchool")
        this.addSite = getViewById<HTMLButtonElement>(view, "addSite")
        replaceViewWithId(view, ()=>{ 
                this.newSchoolSelect = new PartSchoolSelectXml()
                return this.newSchoolSelect.setup(dependency)
        }, "newSchoolSelect")
        return view
    }
}
