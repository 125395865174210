// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/LocationDetailVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { ComponentRowPersonXml } from '../../layout/ComponentRowPersonXml'
import { Floor } from '../../model/Floor'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { Observable, ObservableInput, SubscriptionLike, of as rxOf, zip as rxZip } from 'rxjs'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { Alert } from '../../model/Alert'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { LocationDetailXml } from '../../layout/LocationDetailXml'
import { R } from '../../R'
import { listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { setViewBackgroundClass, xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { flatMap as rxFlatMap, map as rxMap } from 'rxjs/operators'
import { ForeignKey } from '../../model/ForeignKey'
import { DialogWrapperVG } from '../DialogWrapperVG'
import { ViewStringRaw, ViewStringResource, ViewStringTemplate } from 'butterfly-web/dist/views/ViewString'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { School } from '../../model/School'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { runOrNull, safeEq } from 'butterfly-web/dist/kotlin/Language'
import { StatusEnum } from '../../model/StatusEnum'
import { CacheEdge } from '../../api/CacheEdge'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { ContactsVG } from '../chat/ContactsVG'
import { Room } from '../../model/Room'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { CacheNode } from '../../api/CacheNode'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.map.LocationDetailVG
export class LocationDetailVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly roomId: ForeignKey<Room>;
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(dialog: ObservableStack<ViewGenerator>, roomId: ForeignKey<Room>, schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.dialog = dialog;
        this.roomId = roomId;
        this.schoolId = schoolId;
        this.session = session;
        this.settingLocation = new StandardObservableProperty<boolean>(false, undefined);
        this.addingAsDefault = new StandardObservableProperty<boolean>(false, undefined);
        this.settingStatus = new StandardObservableProperty<boolean>(false, undefined);
        this.settingMedical = new StandardObservableProperty<boolean>(false, undefined);
        this.room = this.session.rooms.observable(this.roomId);
        this.ongoingAlerts = this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(this.schoolId), undefined, undefined, undefined, undefined, undefined);
        this.hasActiveAlert = xObservablePropertyMap<(Array<Alert> | null), boolean>(this.ongoingAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4799 = it;
            if(temp4799 === null) { return null }
            return temp4799.length === 0
        })() === false);
        this.alertId = (((): (Alert | null) => {
            const temp4800 = this.ongoingAlerts.value;
            if(temp4800 === null) { return null }
            return (temp4800[0] ?? null)
        })()?.id ?? null);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.map.LocationDetailVG.title
    public get title(): string { return "Location Detail"; }
    
    
    public readonly settingLocation: StandardObservableProperty<boolean>;
    
    public readonly addingAsDefault: StandardObservableProperty<boolean>;
    
    public readonly settingStatus: StandardObservableProperty<boolean>;
    
    public readonly settingMedical: StandardObservableProperty<boolean>;
    
    public readonly room: CacheNode<Room>;
    
    
    public readonly ongoingAlerts: CacheEdge<Alert>;
    
    public readonly hasActiveAlert: ObservableProperty<boolean>;
    
    public readonly alertId: (number | null);
    
    
    
    public schoolRelationship(): ObservableProperty<(UserSchoolMembership | null)> {
        return xObservablePropertyMap<(Array<UserSchoolMembership> | null), (UserSchoolMembership | null)>(this.session.userSchoolMembership.observableListSimple(ApiFilter.Companion.INSTANCE.all<UserSchoolMembership>(UserSchoolMembership.Companion.INSTANCE.forUser(this.session.session.userId), UserSchoolMembership.Companion.INSTANCE.forSchool(this.schoolId)), undefined, undefined, undefined, undefined, undefined), (it: (Array<UserSchoolMembership> | null)): (UserSchoolMembership | null) => ((): (UserSchoolMembership | null) => {
            const temp4801 = it;
            if(temp4801 === null) { return null }
            return (temp4801[0] ?? null)
        })());
    }
    
    public floor(): ObservableProperty<(Floor | null)> {
        return xObservablePropertyFlatMapNotNull<Room, Floor>(this.room, (it: Room): ObservableProperty<(Floor | null)> => this.session.floors.observable(it.floor));
    }
    public building(floor: ObservableProperty<(Floor | null)>): ObservableProperty<(Building | null)> {
        return xObservablePropertyFlatMapNotNull<Floor, Building>(floor, (it: Floor): ObservableProperty<(Building | null)> => this.session.buildings.observable(it.building));
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LocationDetailXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shorcuts
        const schoolRelationship = this.schoolRelationship();
        
        const floor = this.floor();
        
        const building = this.building(floor);
        
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<Array<(string | null)>, string>(xListCombined<(string | null)>([xObservablePropertyMap<(Room | null), (string | null)>(this.room, (it: (Room | null)): (string | null) => (it?.name ?? null)), xObservablePropertyMap<(Floor | null), (string | null)>(floor, (it: (Floor | null)): (string | null) => (it?.name ?? null)), xObservablePropertyMap<(Building | null), (string | null)>(building, (it: (Building | null)): (string | null) => (it?.name ?? null))]), (it: Array<(string | null)>): string => listFilterNotNull(it).filter((it: string): boolean => !(it === "Default")).join(" - ")));
        
        //--- Set Up xml.close (overwritten on flow generation)
        xViewOnClick(xml.close, undefined, (): void => {
            this.closeClick();
        });
        
        //--- Set Up xml.settingLocation
        xViewFlipperBindLoading(xml.settingLocation, this.settingLocation, undefined);
        xViewBindExists(xml.settingLocation, xObservablePropertyCombine<(User | null), boolean, boolean>(this.session.me, this.hasActiveAlert, (it: (User | null), active: boolean): boolean => {
            if (it !== null) { return it!.localUser && !(it!.currentLocation === this.roomId) && active } else { return false }
        }));
        
        //--- Set Up xml.setLocation
        xViewOnClick(xml.setLocation, undefined, (): void => {
            this.setLocationClick();
        });
        
        //--- Set Up xml.addingDefaultLocation
        xViewFlipperBindLoading(xml.addingDefaultLocation, this.addingAsDefault, undefined);
        xViewBindExists(xml.addingDefaultLocation, xObservablePropertyCombine<boolean, boolean, boolean>(xObservablePropertyCombine<(User | null), (UserSchoolMembership | null), boolean>(this.session.me, schoolRelationship, (user: (User | null), membership: (UserSchoolMembership | null)): boolean => {
            if (user !== null && membership !== null) { return user!.localUser } else { return false }
        }), this.hasActiveAlert, (a: boolean, b: boolean): boolean => a && (!b)));
        
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<number>(xObservablePropertyCombine<(User | null), (UserSchoolMembership | null), number>(this.session.me, schoolRelationship, (user: (User | null), membership: (UserSchoolMembership | null)): number => {
                    if (membership !== null) {
                        if (membership!.defaultLocations.length >= 5) {
                            return 0;
                        }
                        if (membership!.defaultLocations.some((x) => safeEq(this.roomId, x))) {
                            return 1;
                        } else {
                            return 2;
                        }
                    } else {
                        return 0;
                    }
            }), undefined, undefined, (it: number): void => {
                switch(it) {
                    case 0:
                    setViewBackgroundClass(xml.addAsDefaultLocation, R.drawable.button_background_disabled.cssClass)
                    xml.addAsDefaultLocation.style.color = R.color.foregroundFade
                    break;
                    case 1:
                    setViewBackgroundClass(xml.addAsDefaultLocation, R.drawable.unsafe_button_background.cssClass)
                    xml.addAsDefaultLocation.style.color = R.color.foreground
                    break;
                    default:
                    setViewBackgroundClass(xml.addAsDefaultLocation, R.drawable.safe_button_background.cssClass)
                    xml.addAsDefaultLocation.style.color = R.color.foreground
                    break;
                }
                
        }), xViewRemovedGet(xml.addAsDefaultLocation));
        
        //--- Set Up xml.addAsDefaultLocation
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(UserSchoolMembership | null)>(schoolRelationship, undefined, undefined, (it: (UserSchoolMembership | null)): void => {
            setViewText(xml.addAsDefaultLocation, (it !== null && it!.defaultLocations.some((x) => safeEq(this.roomId, x)) ? R._string.remove_as_default_location : R._string.add_as_default_location));
        }), xViewRemovedGet(xml.addAsDefaultLocation));
        
        xViewOnClick(xml.addAsDefaultLocation, undefined, (): void => {
            const defaults_4829 = (schoolRelationship.value?.defaultLocations ?? null);
            if (defaults_4829 !== null) {
                if (defaults_4829.some((x) => safeEq(this.roomId, x))) {
                    this.removeAsDefaultLocationClick();
                } else if (defaults_4829.length < 5) {
                    this.addAsDefaultLocationClick();
                } else {
                    xSingleCallDisplayingError<Array<Room>>(rxZip(...defaults_4829.map((it: number): Observable<Room> => this.session.rooms.get(it))), undefined, (it: Array<Room>): void => {
                        showDialogAlert(new ViewStringTemplate(new ViewStringResource(R._string.exceed_max_defaults), [new ViewStringRaw(it.map((it: Room): string => it.name).join(`\n`))]));
                    });
                }
            }
        });
        
        //--- Set Up xml.statusOptions
        //--- Set Up xml.settingStatus
        xViewFlipperBindLoading(xml.settingStatus, this.settingStatus, undefined);
        xViewBindExists(xml.settingStatus, xObservablePropertyCombine<(User | null), boolean, boolean>(this.session.me, this.hasActiveAlert, (it: (User | null), active: boolean): boolean => {
            if (it !== null) { return active && ((!it!.localUser) || it!.currentLocation === this.roomId) } else { return false }
        }));
        
        //--- Set Up xml.safe
        //        room.subscribeBy {
            //            if (it?.safe == StatusEnum.Safe) {
                //                xml.safe.setBackgroundResource(R.drawable.safe_button_background)
            //            } else {
                //                xml.safe.setBackgroundResource(R.drawable.safe_button_background_off)
            //            }
        //        }.until(xml.safe.removed)
        xViewOnClick(xml.safe, undefined, (): void => {
            this.safeClick();
        });
        
        //--- Set Up xml.unsafe
        //        room.subscribeBy {
            //            if (it?.safe == StatusEnum.Unsafe) {
                //                xml.unsafe.setBackgroundResource(R.drawable.unsafe_button_background)
            //            } else {
                //                xml.unsafe.setBackgroundResource(R.drawable.unsafe_button_background_off)
            //            }
        //        }.until(xml.unsafe.removed)
        xViewOnClick(xml.unsafe, undefined, (): void => {
            this.unsafeClick();
        });
        
        //--- Set Up xml.extraButtons
        xViewBindExists(xml.extraButtons, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionCleared ?? null) === true));
        
        xViewBindExists(xml.safe, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.localUser ?? null) === true));
        xViewBindExists(xml.unsafe, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.localUser ?? null) === true));
        
        //--- Set Up xml.cleared
        //        room.subscribeBy {
            //            if (it?.safe == StatusEnum.Cleared) {
                //                xml.cleared.setBackgroundResource(R.drawable.cleared_button_background)
            //            } else {
                //                xml.cleared.setBackgroundResource(R.drawable.cleared_button_background_off)
            //            }
        //        }.until(xml.cleared.removed)
        xViewOnClick(xml.cleared, undefined, (): void => {
            this.clearedClick();
        });
        
        //--- Set Up xml.reset
        //        room.subscribeBy {
            //            if (it?.safe == null) {
                //                xml.reset.setBackgroundResource(R.drawable.reset_button_background)
            //            } else {
                //                xml.reset.setBackgroundResource(R.drawable.reset_button_background_off)
            //            }
        //        }.until(xml.reset.removed)
        xViewOnClick(xml.reset, undefined, (): void => {
            this.resetClick();
        });
        
        //--- Set Up xml.settingMedical
        xViewFlipperBindLoading(xml.settingMedical, this.settingMedical, undefined);
        xViewBindExists(xml.settingMedical, xObservablePropertyCombine<(User | null), boolean, boolean>(this.session.me, this.hasActiveAlert, (it: (User | null), active: boolean): boolean => {
            if (it !== null) { return active && (it!.localUser || it!.currentLocation === this.roomId) } else { return false }
        }));
        
        //--- Set Up xml.needMedicalAttention
        xCompoundButtonBind(xml.needMedicalAttention, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<(Room | null), boolean>(this.room, (it: (Room | null)): boolean => (it?.needsMedicalAttention ?? null) ?? false), (it: boolean): void => {
            xSingleCallDisplayingError<Room>(xSingleWorking<Room>(this.session.rooms.patch(this.roomId, [Room.Companion.INSTANCE.setNeedsMedical(it), Room.Companion.INSTANCE.setAlertId(this.alertId)]), this.settingMedical), undefined, undefined);
        }));
        
        //--- Set Up xml.peopleInRoomHeader
        xViewBindExists(xml.peopleInRoomHeader, this.hasActiveAlert);
        
        //--- Set Up xml.peopleInRoomContainer
        xViewBindExists(xml.peopleInRoomContainer, this.hasActiveAlert);
        
        //--- Set Up xml.peopleInRoom
        xViewBindExists(xml.peopleInRoom, this.hasActiveAlert);
        xRecyclerViewBind<User>(xml.peopleInRoom, xObservablePropertyMap<(Array<User> | null), Array<User>>(this.session.users.observableListSimple(User.Companion.INSTANCE.inLocation(this.roomId), undefined, undefined, undefined, undefined, undefined), (it: (Array<User> | null)): Array<User> => it ?? []), new User(undefined, undefined, "", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<User>): HTMLElement => {
            //--- Make Subview For xml.peopleInRoom
            const cellXml = new ComponentRowPersonXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root (overwritten on flow generation)
            
            //--- Set Up cellXml.name
            
            
            xTextViewBindString(cellXml.name, xObservablePropertyMap<User, string>(observable, (it: User): string => it.name));
            xObservablePropertySubscribeBy<User>(xObservablePropertyMap<User, User>(observable, (it: User): User => it), undefined, undefined, (it: User): void => {
                const safeData: (string | null) = observable.value.userRoomStatus;
                
                const needMedicalAttention: (boolean | null) = observable.value.needsMedicalAttention;
                
                if (safeData !== null) {
                    xTextViewBindString(cellXml.safe, xObservablePropertyMap<User, string>(observable, (it: User): string => it.userRoomStatus ?? ""));
                    
                    if (safeData === "safe" && needMedicalAttention === true) {
                        setViewBackgroundClass(cellXml.safe, R.drawable.bg_safe_medical_need.cssClass);
                    }
                    if (safeData === "unsafe"&& needMedicalAttention === true) {
                        console.log("ya ta aako cha cha");
                        setViewBackgroundClass(cellXml.safe, R.drawable.bg_unsafe_medical_need.cssClass);
                    }
                    if (safeData === "unsafe"&& needMedicalAttention === false) {
                        setViewBackgroundClass(cellXml.safe, R.drawable.unsafe_button_background.cssClass);
                    }
                    if (safeData === "safe"&& needMedicalAttention === false) {
                        setViewBackgroundClass(cellXml.safe, R.drawable.safe_button_background.cssClass);
                    }
                    
                    if (safeData === "unknown") {
                        setViewBackgroundClass(cellXml.safe, R.drawable.bg_unknown.cssClass);
                    }
                }
            });
            
            //--- End Make Subview For xml.peopleInRoom (overwritten on flow generation)
            return cellView;
        });
        
        //--- Set Up xml.movePerson
        xViewBindExists(xml.movePerson, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionEditUser ?? null) === true));
        xViewOnClick(xml.movePerson, undefined, (): void => {
            this.movePersonClick();
        });
        
        //-- Set Up xml.safe
        //        xml.safe.bindExists(session.me.map { it?.localUser })
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public closeClick(): void {
        this.dialog.dismiss();
    }
    public setLocationClick(): void {
        if ((this.session.rooms.observable(this.roomId).value?.safe ?? null) === null) {
            xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.get(this.session.session.userId).pipe(rxFlatMap((it: User): ObservableInput<StatusEnum> => ((): (Observable<StatusEnum> | null) => {
                                const temp4869 = it.currentLocation;
                                if(temp4869 === null) { return null }
                                return ((it: number): Observable<StatusEnum> => this.session.rooms.get(it).pipe(rxMap((it: Room): StatusEnum => it.safe ?? StatusEnum.Safe)))(temp4869)
                })() ?? rxOf(StatusEnum.Safe))).pipe(rxFlatMap((carryStatus: StatusEnum): ObservableInput<(StatusEnum | null)> => this.session.rooms.get(this.roomId).pipe(rxMap((existingRoom: Room): (StatusEnum | null) => {
                    const existingStatus = existingRoom.safe ?? StatusEnum.Safe;
                    
                    return existingStatus.comparableValue < carryStatus.comparableValue ? null : carryStatus
                })))).pipe(rxFlatMap((newStatusBoxed: (StatusEnum | null)): ObservableInput<void> => {
                    const newStatus = newStatusBoxed;
                    
                    return ((): Observable<void> => {
                        if (newStatus !== null) {
                            return this.session.rooms.patch(this.roomId, [Room.Companion.INSTANCE.setStatus(newStatus!), Room.Companion.INSTANCE.setAlertId(this.alertId)]).pipe(rxMap((it: Room): void => {
                                return undefined;
                            }));
                        } else {
                            return rxOf(undefined);
                        }
                    })()
            })).pipe(rxFlatMap((it: void): ObservableInput<User> => this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.setLocation(this.roomId)]))), this.settingLocation), undefined, undefined);
        } else {
            xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.setLocation(this.roomId)]), this.settingLocation), undefined, undefined);
        }
    }
    
    public addAsDefaultLocationClick(): void {
        const it_4881 = xObservablePropertyMap<(Array<UserSchoolMembership> | null), (UserSchoolMembership | null)>(this.session.userSchoolMembership.observableListSimple(ApiFilter.Companion.INSTANCE.all<UserSchoolMembership>(UserSchoolMembership.Companion.INSTANCE.forUser(this.session.session.userId), UserSchoolMembership.Companion.INSTANCE.forSchool(this.schoolId)), undefined, undefined, undefined, undefined, undefined), (it: (Array<UserSchoolMembership> | null)): (UserSchoolMembership | null) => ((): (UserSchoolMembership | null) => {
            const temp4882 = it;
            if(temp4882 === null) { return null }
            return (temp4882[0] ?? null)
        })()).value;
        if (it_4881 !== null) {
            xSingleCallDisplayingError<UserSchoolMembership>(xSingleWorking<UserSchoolMembership>(this.session.userSchoolMembership.patch(it_4881.id, listFilterNotNull([it_4881.addDefaultLocation(this.roomId)])), this.addingAsDefault), undefined, undefined);
        }
    }
    
    public removeAsDefaultLocationClick(): void {
        const it_4883 = xObservablePropertyMap<(Array<UserSchoolMembership> | null), (UserSchoolMembership | null)>(this.session.userSchoolMembership.observableListSimple(ApiFilter.Companion.INSTANCE.all<UserSchoolMembership>(UserSchoolMembership.Companion.INSTANCE.forUser(this.session.session.userId), UserSchoolMembership.Companion.INSTANCE.forSchool(this.schoolId)), undefined, undefined, undefined, undefined, undefined), (it: (Array<UserSchoolMembership> | null)): (UserSchoolMembership | null) => ((): (UserSchoolMembership | null) => {
            const temp4884 = it;
            if(temp4884 === null) { return null }
            return (temp4884[0] ?? null)
        })()).value;
        if (it_4883 !== null) {
            xSingleCallDisplayingError<UserSchoolMembership>(xSingleWorking<UserSchoolMembership>(this.session.userSchoolMembership.patch(it_4883.id, listFilterNotNull([it_4883.removeDefaultLocation(this.roomId)])), this.addingAsDefault), undefined, undefined);
        }
    }
    
    public safeClick(): void {
        console.error(`${"safe clicked"}: ${`alertId:${this.alertId}`}`);
        xSingleCallDisplayingError<Room>(xSingleWorking<Room>(this.session.rooms.patch(this.roomId, [Room.Companion.INSTANCE.setStatus(StatusEnum.Safe), Room.Companion.INSTANCE.setAlertId(this.alertId)]), this.settingStatus), undefined, (it: Room): void => {
            this.dialog.dismiss();
        });
    }
    
    public unsafeClick(): void {
        xSingleCallDisplayingError<Room>(xSingleWorking<Room>(this.session.rooms.patch(this.roomId, [Room.Companion.INSTANCE.setStatus(StatusEnum.Unsafe), Room.Companion.INSTANCE.setAlertId(this.alertId)]), this.settingStatus), undefined, (it: Room): void => {
            this.dialog.dismiss();
        });
    }
    
    public clearedClick(): void {
        xSingleCallDisplayingError<Room>(xSingleWorking<Room>(this.session.rooms.patch(this.roomId, [Room.Companion.INSTANCE.setStatus(StatusEnum.Cleared), Room.Companion.INSTANCE.setAlertId(this.alertId)]), this.settingStatus), undefined, (it: Room): void => {
            this.dialog.dismiss();
        });
    }
    
    public resetClick(): void {
        xSingleCallDisplayingError<Room>(xSingleWorking<Room>(this.session.rooms.patch(this.roomId, [Room.Companion.INSTANCE.setNullStatus(), Room.Companion.INSTANCE.setAlertId(this.alertId)]), this.settingStatus), undefined, (it: Room): void => {
            this.dialog.dismiss();
        });
    }
    public movePersonClick(): void {
        this.dialog.push(new DialogWrapperVG(new ContactsVG(false, (user: User, work: MutableObservableProperty<boolean>): void => {
            xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(user.id, [User.Companion.INSTANCE.setLocation(this.roomId)]), work), undefined, (it: User): void => {
                this.dialog.dismiss();
            });
        }, this.session.schools.observable(this.schoolId), this.session), this.dialog));
    }
    //--- Body End
}
