//
// ItemAdminRequestHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './item_admin_request_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.ItemAdminRequestHistoryXml
export class ItemAdminRequestHistoryXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    nameView!: HTMLElement
    startTime!: HTMLElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.nameView = getViewById<HTMLElement>(view, "nameView")
        this.startTime = getViewById<HTMLElement>(view, "startTime")
        return view
    }
}
