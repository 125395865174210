// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiOption.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xStringSubstringAfter, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { MockApi } from './MockApi'
import { SubscriptionLike } from 'rxjs'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { MockDatabasePreFilled } from './MockDatabase'
import { getDefaultApiOptionUrl, getLocalApiLocationRest, getLocalApiLocationSocket } from './localApiLocation'
import { filter as iterFilter, find as iterFind, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { tryCastClass } from 'butterfly-web/dist/Kotlin'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { DelayedApi } from './DelayedApi'
import { LiveApi } from './LiveApi'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { Api } from './Api'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.api.mockDatabase
export const _mockDatabase = new MockDatabasePreFilled();
export function getMockDatabase(): MockDatabasePreFilled { return _mockDatabase; }

//! Declares com.tresitgroup.android.tresit.api.mockApi
export const _mockApi = new MockApi(getMockDatabase());
export function getMockApi(): MockApi { return _mockApi; }


//! Declares com.tresitgroup.android.tresit.api.ApiOption
export class ApiOption {
    public readonly api: Api;
    public readonly description: string;
    public readonly ready: boolean;
    public readonly firebaseConfigName: (string | null);
    private constructor(name: string, jsonName: string, api: Api, description: string, ready: boolean = true, firebaseConfigName: (string | null) = null) {
        this.name = name;
        this.jsonName = jsonName;
        this.api = api;
        this.description = description;
        this.ready = ready;
        this.firebaseConfigName = firebaseConfigName;
    }
    
    public static Production = new ApiOption("Production", "Production", new LiveApi("https://dirs.app/", "wss://dirs.app/api/v2/ws/?token="), "The real deal", undefined, "production");
    
    public static Demo = new ApiOption("Demo", "Demo", new LiveApi("https://demo.dirs.app/", "wss://demo.dirs.app/api/v2/ws/?token="), "Previously known as 'Tresit Demo'", undefined, "demo");
    
    public static Stage = new ApiOption("Stage", "Stage", new LiveApi("https://stage.dirs.app/", "wss://stage.dirs.app/api/v2/ws/?token="), "this is stage", undefined, "stage");
    
    public static DemoNepal = new ApiOption("DemoNepal", "DemoNepal", new LiveApi("https://test.dirs.app/", "wss://test.dirs.app/api/v2/ws/?token="), "this is demo for nepal team", undefined, "demoNepal");
    
    public static ProdNepal = new ApiOption("ProdNepal", "ProdNepal", new LiveApi("https://prod.dirs.app/", "wss://prod.dirs.app/api/v2/ws/?token="), "this is demo for nepal team", undefined, "demoNepal");
    
    public static LittleMac = new ApiOption("LittleMac", "LittleMac", new LiveApi("http://172.19.17.220:8000/", "ws://172.19.17.220:8000/api/v2/ws/?token="), "Only works in the LK Office, used for testing new code", undefined, undefined);
    
    public static Ngrok = new ApiOption("Ngrok", "Ngrok", new LiveApi("http://6.tcp.ngrok.io:13417/", "http://6.tcp.ngrok.io:13417/api/v2/ws/?token="), "Connects to a changing NGROK server", undefined, undefined);
    
    public static Local = new ApiOption("Local", "Local", new LiveApi(getLocalApiLocationRest(), getLocalApiLocationSocket()), "Connects to a server directly on the device, requires HTTPS", undefined, undefined);
    
    public static MockFast = new ApiOption("MockFast", "MockFast", getMockApi(), "Mock data directly on the phone.  Resets with application.  Instant.", undefined, undefined);
    
    public static Mock = new ApiOption("Mock", "Mock", new DelayedApi(getMockApi(), 500), "Mock data directly on the phone.  Resets with application.  All requests delayed by 1 second.", undefined, undefined);
    
    
    
    private static _values: Array<ApiOption> = [ApiOption.Production, ApiOption.Demo, ApiOption.Stage, ApiOption.DemoNepal, ApiOption.ProdNepal, ApiOption.LittleMac, ApiOption.Ngrok, ApiOption.Local, ApiOption.MockFast, ApiOption.Mock];
    public static values(): Array<ApiOption> { return ApiOption._values; }
    public readonly name: string;
    public readonly jsonName: string;
    public static valueOf(name: string): ApiOption { return (ApiOption as any)[name]; }
    public toString(): string { return this.name }
    public toJSON(): string { return this.jsonName }
}
export namespace ApiOption {
    //! Declares com.tresitgroup.android.tresit.api.ApiOption.Companion
    export class Companion {
        private constructor() {
            this.prefKey = "apioption";
            this.liveOptions = iterToArray(iterFilter(ApiOption.values(), (it: ApiOption): boolean => it.ready));
            this.choice = new StandardObservableProperty<ApiOption>(this.defaultOption(), undefined);
            this.overrideWith = new StandardObservableProperty<(Api | null)>(null, undefined);
        }
        public static INSTANCE = new Companion();
        
        public readonly prefKey: string;
        
        public defaultOption(): ApiOption {
            for (const it of ApiOption.values()) {
                const api_11 = (tryCastClass<LiveApi>(it.api, LiveApi));
                if (api_11 !== null) {
                    const host = xStringSubstringBefore(xStringSubstringAfter(api_11.httpUrl, "://", undefined), "/", undefined);
                    
                    if (host === getDefaultApiOptionUrl()) {
                        return it;
                    }
                }
            }
            return ApiOption.Production;
        }
        
        public readonly liveOptions: Array<ApiOption>;
        
        
        public get(api: Api): (ApiOption | null) {
            return iterFind(ApiOption.values(), (it: ApiOption): boolean => it.api === api);
        }
        
        public readonly choice: StandardObservableProperty<ApiOption>;
        
        public readonly overrideWith: StandardObservableProperty<(Api | null)>;
        
        //! Declares com.tresitgroup.android.tresit.api.ApiOption.Companion.current
        public get current(): Api { return this.overrideWith.value ?? this.choice.value.api; }
        
        
        public bindToPreferences(): void {
            const start: (ApiOption | null) = Preferences.INSTANCE.get<ApiOption>([ApiOption], this.prefKey);
            
            if (start !== null) {
                this.choice.value = start!;
            } else {
                this.choice.value = this.defaultOption();
            }
            xDisposableForever<SubscriptionLike>(xObservablePropertySubscribeBy<ApiOption>(this.choice, undefined, undefined, (it: ApiOption): void => {
                //                if(it.api !is MockApi && it.api !is DelayedApi) {
                    Preferences.INSTANCE.set<ApiOption>([ApiOption], this.prefKey, it);
                //                }
            }));
        }
    }
}