// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/DelayedApi.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { Room } from '../model/Room'
import { Session } from '../model/Session'
import { StartLoginResponse } from '../model/StartLoginResponse'
import { CampusGeoShape } from '../map/Models'
import { User } from '../model/User'
import { Observable } from 'rxjs'
import { Device } from '../model/Device'
import { AlertHistory } from '../model/AlertHistory'
import { Image } from 'butterfly-web/dist/Image'
import { SessionApi } from './SessionApi'
import { Group } from '../model/Group'
import { DelayedTable } from './DelayedTable'
import { ChatThreadDetailed } from '../model/ChatThreadDetailed'
import { HttpProgress } from 'butterfly-web/dist/net/HttpModels'
import { AlertGuidance } from '../model/AlertGuidance'
import { ChatMessageDetailed } from '../model/ChatMessageDetailed'
import { AlertHistoryDetailed } from '../model/AlertHistoryDetailed'
import { ChatAttachment } from '../model/ChatAttachment'
import { ChatMessage } from '../model/ChatMessage'
import { Building } from '../model/Building'
import { PermissionsResponse } from '../model/PermissionsResponse'
import { FirebaseMessagingToken } from '../model/FirebaseMessagingToken'
import { AdminRequestHistory } from '../model/AdminRequestHistory'
import { AlertHistoryIdResponse } from '../model/AlertHistoryIdResponse'
import { Api } from './Api'
import { UserSchoolMembership } from '../model/UserSchoolMembership'
import { ApiTable } from './ApiTable'
import { ApiPartialQuery } from './ApiPartialQuery'
import { UserDetailed } from '../model/UserDetailed'
import { SocketMessage } from '../model/SocketMessage'
import { Alert } from '../model/Alert'
import { UnreadThread } from '../model/UnreadThread'
import { LoginActivity } from '../model/LoginActivity'
import { OffsiteResponse } from '../model/OffsiteResponse'
import { District } from '../model/District'
import { Stats } from '../model/Stats'
import { School } from '../model/School'
import { ChatThread } from '../model/ChatThread'
import { RegisteredDevice } from '../model/RegisteredDevice'
import { Floor } from '../model/Floor'
import { delay as rxDelay } from 'rxjs/operators'
import { AdminApprovalListResponse } from '../model/AdminApprovalListResponse'
import { ForeignKey } from '../model/ForeignKey'
import { AlertType } from '../model/AlertType'
import { CheckAlertLocationResponse } from '../model/CheckAlertLocationResponse'
import { BoardPost } from '../model/BoardPost'

//! Declares com.tresitgroup.android.tresit.api.DelayedApi
export class DelayedApi implements Api {
    public static implementsInterfaceComTresitgroupAndroidTresitApiApi = true;
    public readonly base: Api;
    public readonly delay: number;
    public constructor(base: Api, delay: number) {
        this.base = base;
        this.delay = delay;
    }
    
    
    public getSocket(session: Session, sessionApi: SessionApi): Observable<Observable<SocketMessage>> {
        return this.base.getSocket(session, sessionApi).pipe(rxDelay(this.delay));
    }
    
    public checkIsOkBuild(version: string): Observable<boolean> {
        return this.base.checkIsOkBuild(version).pipe(rxDelay(this.delay));
    }
    
    
    public startLogin(email: string, fName: (string | null), lName: (string | null), phone: (string | null)): Observable<StartLoginResponse> {
        return this.base.startLogin(email, fName, lName, phone).pipe(rxDelay(this.delay));
    }
    
    public cleverUrl(): Observable<StartLoginResponse> {
        return this.base.cleverUrl().pipe(rxDelay(this.delay));
    }
    
    public passwordLogin(email: string, password: string, fName: (string | null), lName: (string | null), phone: (string | null)): Observable<Session> {
        return this.base.passwordLogin(email, password, fName, lName, phone).pipe(rxDelay(this.delay));
    }
    
    public stats(session: Session): Observable<Stats> {
        return this.base.stats(session).pipe(rxDelay(this.delay));
    }
    
    public forgotPassword(email: string): Observable<void> {
        return this.base.forgotPassword(email).pipe(rxDelay(this.delay));
    }
    
    public changePassword(session: Session, password: string): Observable<void> {
        return this.base.changePassword(session, password).pipe(rxDelay(this.delay));
    }
    
    public refreshSession(token: string): Observable<Session> {
        return this.base.refreshSession(token).pipe(rxDelay(this.delay));
    }
    
    public clearFirebaseToken(session: Session, token: FirebaseMessagingToken): Observable<void> {
        return this.base.clearFirebaseToken(session, token).pipe(rxDelay(this.delay));
    }
    
    public postFirebaseToken(session: Session, token: FirebaseMessagingToken): Observable<void> {
        return this.base.postFirebaseToken(session, token).pipe(rxDelay(this.delay));
    }
    
    public requestAdminAlert(session: Session, schoolId: number, name: number, created: string, initiator: number, policeStatus: string, scoped: boolean, silent: boolean, internal: boolean, description: string): Observable<void> {
        return this.base.requestAdminAlert(session, schoolId, name, created, initiator, policeStatus, scoped, silent, internal, description).pipe(rxDelay(this.delay));
    }
    
    public createOffsite(session: Session, id: string): Observable<OffsiteResponse> {
        return this.base.createOffsite(session, id).pipe(rxDelay(this.delay));
    }
    
    public getAlertId(session: Session, id: string): Observable<AlertHistoryIdResponse> {
        return this.base.getAlertId(session, id).pipe(rxDelay(this.delay));
    }
    
    public getThreadId(session: Session, id: string): Observable<Array<ChatThread>> {
        return this.base.getThreadId(session, id).pipe(rxDelay(this.delay));
    }
    
    public verifyToken(session: Session, token: string): Observable<void> {
        return this.base.verifyToken(session, token).pipe(rxDelay(this.delay));
    }
    
    
    public cancelAlertWithReason(session: Session, id: ForeignKey<Alert>, reason: string): Observable<void> {
        return this.base.cancelAlertWithReason(session, id, reason).pipe(rxDelay(this.delay));
    }
    
    public uploadAlertIcon(session: Session, id: ForeignKey<AlertType>, image: Image): Observable<AlertType> {
        return this.base.uploadAlertIcon(session, id, image).pipe(rxDelay(this.delay));
    }
    
    public uploadAlertMp3(session: Session, id: ForeignKey<AlertType>, uri: File): Observable<AlertType> {
        return this.base.uploadAlertMp3(session, id, uri).pipe(rxDelay(this.delay));
    }
    
    public uploadAlertOgg(session: Session, id: ForeignKey<AlertType>, uri: File): Observable<AlertType> {
        return this.base.uploadAlertOgg(session, id, uri).pipe(rxDelay(this.delay));
    }
    
    public uploadAttachment(session: Session, uri: File, fileName: string, type: string): Observable<HttpProgress<ChatAttachment>> {
        return this.base.uploadAttachment(session, uri, fileName, type).pipe(rxDelay(this.delay));
    }
    
    public bindAttachments(session: Session, message: ForeignKey<ChatMessage>, attachments: Array<ForeignKey<ChatAttachment>>): Observable<void> {
        return this.base.bindAttachments(session, message, attachments).pipe(rxDelay(this.delay));
    }
    
    public updateGoogleMap(session: Session, site: ForeignKey<School>, campus: CampusGeoShape): Observable<void> {
        return this.base.updateGoogleMap(session, site, campus).pipe(rxDelay(this.delay));
    }
    
    public schools(session: Session): ApiTable<School> {
        return new DelayedTable<School>(this.base.schools(session), this.delay);
    }
    
    public districts(session: Session): ApiTable<District> {
        return new DelayedTable<District>(this.base.districts(session), this.delay);
    }
    
    public posts(session: Session): ApiTable<BoardPost> {
        return new DelayedTable<BoardPost>(this.base.posts(session), this.delay);
    }
    
    public alerts(session: Session): ApiTable<Alert> {
        return new DelayedTable<Alert>(this.base.alerts(session), this.delay);
    }
    
    public alertTypes(session: Session): ApiTable<AlertType> {
        return new DelayedTable<AlertType>(this.base.alertTypes(session), this.delay);
    }
    
    public alertGuidances(session: Session): ApiTable<AlertGuidance> {
        return new DelayedTable<AlertGuidance>(this.base.alertGuidances(session), this.delay);
    }
    
    public alertHistory(session: Session): ApiTable<AlertHistory> {
        return new DelayedTable<AlertHistory>(this.base.alertHistory(session), this.delay);
    }
    
    public threads(session: Session): ApiTable<ChatThread> {
        return new DelayedTable<ChatThread>(this.base.threads(session), this.delay);
    }
    
    public messages(session: Session): ApiTable<ChatMessage> {
        return new DelayedTable<ChatMessage>(this.base.messages(session), this.delay);
    }
    
    public buildings(session: Session): ApiTable<Building> {
        return new DelayedTable<Building>(this.base.buildings(session), this.delay);
    }
    
    public floors(session: Session): ApiTable<Floor> {
        return new DelayedTable<Floor>(this.base.floors(session), this.delay);
    }
    
    public rooms(session: Session): ApiTable<Room> {
        return new DelayedTable<Room>(this.base.rooms(session), this.delay);
    }
    public users(session: Session): ApiTable<User> {
        return new DelayedTable<User>(this.base.users(session), this.delay);
    }
    public devices(session: Session): ApiTable<Device> {
        return new DelayedTable<Device>(this.base.devices(session), this.delay);
    }
    
    public userSchoolMemberships(session: Session): ApiTable<UserSchoolMembership> {
        return new DelayedTable<UserSchoolMembership>(this.base.userSchoolMemberships(session), this.delay);
    }
    
    public permissionGroups(session: Session): ApiTable<Group> {
        return new DelayedTable<Group>(this.base.permissionGroups(session), this.delay);
    }
    
    public loginActivity(session: Session): ApiTable<LoginActivity> {
        return new DelayedTable<LoginActivity>(this.base.loginActivity(session), this.delay);
    }
    
    public firebaseMessagingToken(session: Session): ApiTable<FirebaseMessagingToken> {
        return new DelayedTable<FirebaseMessagingToken>(this.base.firebaseMessagingToken(session), this.delay);
    }
    
    public unreadThreads(session: Session): ApiTable<UnreadThread> {
        return new DelayedTable<UnreadThread>(this.base.unreadThreads(session), this.delay);
    }
    
    public postLog(session: Session, details: string): Observable<void> {
        return this.base.postLog(session, details).pipe(rxDelay(this.delay));
    }
    
    
    public usersDetailed(session: Session, query: ApiPartialQuery<UserDetailed>): Observable<Array<UserDetailed>> {
        return this.base.usersDetailed(session, query).pipe(rxDelay(this.delay));
    }
    
    public getAdminAlertHistory(session: Session, query: string): Observable<Array<AdminRequestHistory>> {
        return this.base.getAdminAlertHistory(session, query).pipe(rxDelay(this.delay));
    }
    
    public alertHistoryDetailed(session: Session, query: ApiPartialQuery<AlertHistoryDetailed>): Observable<Array<AlertHistoryDetailed>> {
        return this.base.alertHistoryDetailed(session, query).pipe(rxDelay(this.delay));
    }
    
    public messagesDetailed(session: Session, query: ApiPartialQuery<ChatMessageDetailed>): Observable<Array<ChatMessageDetailed>> {
        return this.base.messagesDetailed(session, query).pipe(rxDelay(this.delay));
    }
    
    public threadsDetailed(session: Session, query: ApiPartialQuery<ChatThreadDetailed>): Observable<Array<ChatThreadDetailed>> {
        return this.base.threadsDetailed(session, query).pipe(rxDelay(this.delay));
    }
    
    
    public submitContactEmail(email: string): Observable<void> {
        return this.base.submitContactEmail(email).pipe(rxDelay(this.delay));
    }
    
    public adminApproval(session: Session, schoolId: string): Observable<Array<AdminApprovalListResponse>> {
        return this.base.adminApproval(session, schoolId).pipe(rxDelay(this.delay));
    }
    
    public submitAdminApproval(session: Session, status: string, alertId: number): Observable<void> {
        return this.base.submitAdminApproval(session, status, alertId).pipe(rxDelay(this.delay));
    }
    
    public getRegisteredDevice(session: Session, userId: number): Observable<RegisteredDevice> {
        return this.base.getRegisteredDevice(session, userId).pipe(rxDelay(this.delay));
    }
    
    
    public checkAlertLocation(session: Session): Observable<CheckAlertLocationResponse> {
        return this.base.checkAlertLocation(session).pipe(rxDelay(this.delay));
    }
    
    public getPermissions(session: Session, userId: ForeignKey<User>): Observable<PermissionsResponse> {
        return this.base.getPermissions(session, userId).pipe(rxDelay(this.delay));
    }
    
    public startGroupAlert(session: Session, request: Alert): Observable<Alert> {
        return this.base.startGroupAlert(session, request).pipe(rxDelay(this.delay));
    }
    
    public withNewDelay(newDelay: number): DelayedApi {
        return new DelayedApi(this.base, newDelay);
    }
}

