// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/ChatAttachment.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { ChatMessage } from './ChatMessage'
import { Time } from '../util/time'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.ChatAttachment
export class ChatAttachment implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public readonly video: (string | null);
    public readonly videoThumbnail: (string | null);
    public readonly image: (string | null);
    public readonly imageThumbnail: (string | null);
    public readonly doc: (string | null);
    public readonly message: (ForeignKey<ChatMessage> | null);
    public readonly authorId: (ForeignKey<User> | null);
    public readonly created: Date;
    public deleted: (Date | null);
    public constructor(id: number = getNO_ID(), video: (string | null) = null, videoThumbnail: (string | null) = null, image: (string | null) = null, imageThumbnail: (string | null) = null, doc: (string | null) = null, message: (ForeignKey<ChatMessage> | null) = null, authorId: (ForeignKey<User> | null) = null, created: Date = Time.INSTANCE.date(), deleted: (Date | null) = null) {
        this.id = id;
        this.video = video;
        this.videoThumbnail = videoThumbnail;
        this.image = image;
        this.imageThumbnail = imageThumbnail;
        this.doc = doc;
        this.message = message;
        this.authorId = authorId;
        this.created = created;
        this.deleted = deleted;
    }
    public static fromJson(obj: any): ChatAttachment { return new ChatAttachment(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["video"], [String]) as (string | null),
        parseJsonTyped(obj["video_thumbnail"], [String]) as (string | null),
        parseJsonTyped(obj["image"], [String]) as (string | null),
        parseJsonTyped(obj["image_thumbnail"], [String]) as (string | null),
        parseJsonTyped(obj["doc"], [String]) as (string | null),
        parseJsonTyped(obj["message"], [Number]) as (number | null),
        parseJsonTyped(obj["author_id"], [Number]) as (number | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        video: this.video,
        video_thumbnail: this.videoThumbnail,
        image: this.image,
        image_thumbnail: this.imageThumbnail,
        doc: this.doc,
        message: this.message,
        author_id: this.authorId,
        created: this.created,
        deleted: this.deleted
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.video);
        hash = 31 * hash + hashAnything(this.videoThumbnail);
        hash = 31 * hash + hashAnything(this.image);
        hash = 31 * hash + hashAnything(this.imageThumbnail);
        hash = 31 * hash + hashAnything(this.doc);
        hash = 31 * hash + hashAnything(this.message);
        hash = 31 * hash + hashAnything(this.authorId);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.deleted);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ChatAttachment && safeEq(this.id, other.id) && safeEq(this.video, other.video) && safeEq(this.videoThumbnail, other.videoThumbnail) && safeEq(this.image, other.image) && safeEq(this.imageThumbnail, other.imageThumbnail) && safeEq(this.doc, other.doc) && safeEq(this.message, other.message) && safeEq(this.authorId, other.authorId) && safeEq(this.created, other.created) && safeEq(this.deleted, other.deleted) }
    public toString(): string { return `ChatAttachment(id=${this.id}, video=${this.video}, videoThumbnail=${this.videoThumbnail}, image=${this.image}, imageThumbnail=${this.imageThumbnail}, doc=${this.doc}, message=${this.message}, authorId=${this.authorId}, created=${this.created}, deleted=${this.deleted})` }
    public copy(id: number = this.id, video: (string | null) = this.video, videoThumbnail: (string | null) = this.videoThumbnail, image: (string | null) = this.image, imageThumbnail: (string | null) = this.imageThumbnail, doc: (string | null) = this.doc, message: (ForeignKey<ChatMessage> | null) = this.message, authorId: (ForeignKey<User> | null) = this.authorId, created: Date = this.created, deleted: (Date | null) = this.deleted): ChatAttachment { return new ChatAttachment(id, video, videoThumbnail, image, imageThumbnail, doc, message, authorId, created, deleted); }
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}