// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/Page.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId } from './HasId'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.Page
export class Page<T extends HasId> {
    public readonly count: number;
    public readonly next: (string | null);
    public readonly previous: (string | null);
    public readonly results: Array<T>;
    public constructor(count: number, next: (string | null), previous: (string | null), results: Array<T>) {
        this.count = count;
        this.next = next;
        this.previous = previous;
        this.results = results;
    }
    public static fromJson<T extends HasId>(obj: any, T: any): Page<T> { return new Page(
        parseJsonTyped(obj["count"], [Number]) as number,
        parseJsonTyped(obj["next"], [String]) as (string | null),
        parseJsonTyped(obj["previous"], [String]) as (string | null),
        parseJsonTyped(obj["results"], [Array, T]) as Array<T>
    ) }
    public toJSON(): object { return {
        count: this.count,
        next: this.next,
        previous: this.previous,
        results: this.results
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.count);
        hash = 31 * hash + hashAnything(this.next);
        hash = 31 * hash + hashAnything(this.previous);
        hash = 31 * hash + hashAnything(this.results);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof Page && safeEq(this.count, other.count) && safeEq(this.next, other.next) && safeEq(this.previous, other.previous) && safeEq(this.results, other.results) }
    public toString(): string { return `Page(count=${this.count}, next=${this.next}, previous=${this.previous}, results=${this.results})` }
    public copy(count: number = this.count, next: (string | null) = this.next, previous: (string | null) = this.previous, results: Array<T> = this.results): Page<T> { return new Page(count, next, previous, results); }
}