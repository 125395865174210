//
// OnAlertStartedXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './on_alert_started.html'
//! Declares com.tresitgroup.android.tresit.layouts.OnAlertStartedXml
export class OnAlertStartedXml {
    xmlRoot!: HTMLElement
    back!: HTMLButtonElement
    statusImage!: HTMLImageElement
    statusText!: HTMLElement
    school!: HTMLElement
    alertStartedView!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.back = getViewById<HTMLButtonElement>(view, "back")
        this.statusImage = getViewById<HTMLImageElement>(view, "status_image")
        this.statusText = getViewById<HTMLElement>(view, "status_text")
        this.school = getViewById<HTMLElement>(view, "school")
        this.alertStartedView = getViewById<HTMLDivElement>(view, "alertStartedView")
        return view
    }
}
