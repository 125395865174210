// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/BuildingMapSvgVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { BuildingMapSvgXml } from '../../layout/BuildingMapSvgXml'
import { Floor } from '../../model/Floor'
import { safeEq, tryCastClass } from 'butterfly-web/dist/Kotlin'
import { xObservablePropertyOnChangeNNGet, xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { HttpClient } from 'butterfly-web/dist/net/HttpClient'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xResponseReadText, xSingleReadText } from 'butterfly-web/dist/net/RxHttpAssist'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { Alert } from '../../model/Alert'
import { Paint } from 'butterfly-web/dist/views/draw/Paint'
import { Preferences } from 'butterfly-web/dist/Preferences'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { CombinedStatus } from '../../model/CombinedStatus'
import { SvgPaints } from './Paints'
import { ForeignKey } from '../../model/ForeignKey'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'
import { xSessionApiFloorStatusCached, xSessionApiRoomStatusCached } from './statuses'
import { School } from '../../model/School'
import { SvgDelegate } from './SvgDelegate'
import { xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { tap as rxTap } from 'rxjs/operators'
import { xStringSubstringAfter, xStringSubstringBefore } from 'butterfly-web/dist/kotlin/kotlin.text'
import { xDisposableForever, xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { StatusEnum } from '../../model/StatusEnum'
import { CacheEdge } from '../../api/CacheEdge'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { LiveApi } from '../../api/LiveApi'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { Room } from '../../model/Room'
import { spinnerBindString } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { CacheNode } from '../../api/CacheNode'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { find as iterFind } from 'butterfly-web/dist/kotlin/lazyOp'

//! Declares com.tresitgroup.android.tresit.vg.map.BuildingMapSvgVG
export class BuildingMapSvgVG extends ViewGenerator {
    public readonly buildingId: ForeignKey<Building>;
    public readonly onRoomTap:  ((a: ForeignKey<Room>) => void);
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(buildingId: ForeignKey<Building>, onRoomTap:  ((a: ForeignKey<Room>) => void), schoolId: ForeignKey<School>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.buildingId = buildingId;
        this.onRoomTap = onRoomTap;
        this.schoolId = schoolId;
        this.session = session;
        this.stack = stack;
        this.loadingMap = new StandardObservableProperty<boolean>(false, undefined);
        this.map = new StandardObservableProperty<(string | null)>(null, undefined);
        this.ongoingAlerts = this.session.alerts.observableListSimple(Alert.Companion.INSTANCE.forSchool(this.schoolId), undefined, undefined, undefined, undefined, undefined);
        this.hasActiveAlert = xObservablePropertyMap<(Array<Alert> | null), boolean>(this.ongoingAlerts, (it: (Array<Alert> | null)): boolean => ((): (boolean | null) => {
            const temp4538 = it;
            if(temp4538 === null) { return null }
            return temp4538.length === 0
        })() === false);
        this.building = this.session.buildings.observable(this.buildingId);
    }
    
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.map.BuildingMapSvgVG.title
    public get title(): string { return "Building Map Svg"; }
    
    
    public readonly loadingMap: StandardObservableProperty<boolean>;
    
    public readonly map: StandardObservableProperty<(string | null)>;
    
    public readonly ongoingAlerts: CacheEdge<Alert>;
    
    public readonly hasActiveAlert: ObservableProperty<boolean>;
    
    public readonly building: CacheNode<Building>;
    
    public floors(): ObservableProperty<(Array<Floor> | null)> {
        return xObservablePropertyFlatMap<(Building | null), (Array<Floor> | null)>(this.building, (it: (Building | null)): ObservableProperty<(Array<Floor> | null)> => this.session.floors.observableListSimple(Floor.Companion.INSTANCE.forBuilding(this.buildingId), undefined, undefined, undefined, undefined, undefined));
    }
    
    public mapReload(mapUrl: (string | null)): void {
        this.map.value = null;
        if (mapUrl === null) { return }
        const withoutProtocol = xStringSubstringAfter(mapUrl!, ':', undefined);
        
        const protocol = ((): (string | null) => {
            const temp4539 = ((tryCastClass<LiveApi>(this.session.rawApi, LiveApi))?.httpUrl ?? null);
            if(temp4539 !== null) {
                return xStringSubstringBefore(temp4539, ':', undefined)
            } else { return null }
        })() ?? "https";
        
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<string>(xSingleWorking<string>(xSingleReadText(HttpClient.INSTANCE.call(`${protocol}:${withoutProtocol}`, undefined, undefined, undefined, undefined)).pipe(rxTap((it: string): void => {
                            console.debug(`${"BuildingMapSVG"}: ${`Got ${it}`}`);
                })).pipe(rxTap(undefined, (it: any): void => {
                    if (it instanceof HttpResponseException) {
                        xResponseReadText((it as HttpResponseException).response).subscribe((it: string): void => {
                            console.debug(`${"BuildingMapSVG"}: ${`Got error ${it}`}`);
                        }, (it: any): void => {});
                    }
            })), this.loadingMap), undefined, (it: string): void => {
                this.map.value = it;
        }));
    }
    
    //--- Action zoomInClick
    //--- Action zoomOutClick
    
    public generate(dependency: Window): HTMLElement {
        const xml = new BuildingMapSvgXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Props
        const floors = this.floors();
        
        
        const selectedFloor = new StandardObservableProperty<Floor>(new Floor(undefined, 0, undefined, undefined, "", undefined), undefined);
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertyOnChangeNNGet(selectedFloor).subscribe((it: Floor): void => {
            Preferences.INSTANCE.set<number>([Number], BuildingMapSvgVG.Companion.INSTANCE.floorKey, it.id);
        }, undefined, undefined), xViewRemovedGet(view));
        
        const prevFloor = Preferences.INSTANCE.get<number>([Number], BuildingMapSvgVG.Companion.INSTANCE.floorKey);
        
        const prevBuilding = Preferences.INSTANCE.get<number>([Number], BuildingMapSvgVG.Companion.INSTANCE.buildingKey);
        
        if (prevBuilding === this.buildingId) {
            if (prevFloor !== null) {
                selectedFloor.value = new Floor(prevFloor!, this.buildingId, undefined, undefined, "", undefined);
            }
        } else {
            Preferences.INSTANCE.set<number>([Number], BuildingMapSvgVG.Companion.INSTANCE.buildingKey, this.buildingId);
        }
        
        const mapUrl = xObservablePropertyMap<Floor, (string | null)>(selectedFloor, (it: Floor): (string | null) => it.svgMap);
        
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(string | null)>(mapUrl, undefined, undefined, (it: (string | null)): void => {
            this.mapReload(it);
        }), xViewRemovedGet(view));
        
        //--- Set Up xml.title
        xTextViewBindString(xml.title, xObservablePropertyMap<(Building | null), string>(this.building, (it: (Building | null)): string => (it?.name ?? null) ?? ""));
        
        //--- Set Up xml.back (overwritten on flow generation)
        xViewOnClick(xml.back, undefined, (): void => {
            this.backClick();
        });
        
        //--- Set Up xml.floor
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Floor> | null)>(floors, undefined, undefined, (it: (Array<Floor> | null)): void => {
            if (it !== null) {
                selectedFloor.value = iterFind(it!, (it: Floor): boolean => it.id === selectedFloor.value.id) ?? (it![0] ?? null) ?? new Floor(undefined, 0, undefined, undefined, "", undefined);
            }
        }), xViewRemovedGet(xml.floor));
        spinnerBindString<Floor>(xml.floor, xObservablePropertyMap<(Array<Floor> | null), Array<Floor>>(floors, (it: (Array<Floor> | null)): Array<Floor> => it ?? []), selectedFloor, (floor: Floor): ObservableProperty<string> => xObservablePropertyMap<(CombinedStatus | null), string>(xSessionApiFloorStatusCached(this.session, this.schoolId, floor.id), (status: (CombinedStatus | null)): string => {
            let out = floor.name;
            
            if (safeEq((status?.status ?? null), StatusEnum.Unsafe)) {
                out = out + " !";
            }
            return out;
        }));
        
        //--- Set Up xml.map
        const paints = new SvgPaints(dependency);
        
        const dg = new SvgDelegate(this.map, (it: number): Array<Paint> => ((): Array<Paint> => {
                    if (this.hasActiveAlert.value) {
                        const status = xSessionApiRoomStatusCached(this.session, it).value;
                        
                        const base = status !== null ? paints.forCombinedStatus(status!) : ([] as Array<Paint>);
                        
                        return ((): Array<Paint> => {
                            if ((this.session.me.value?.currentLocation ?? null) === it) {
                                return base.concat(([paints.highlight()] as Array<Paint>));
                            } else {
                                return base;
                            }
                        })()
                    } else { return [] }
            })(), (it: number): void => {
                this.onRoomTap(it);
        });
        
        customViewSetDelegate(xml.map, dg);
        //This keeps the data flowing
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Room> | null)>(this.session.rooms.observableListSimple(Room.Companion.INSTANCE.forSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), undefined, undefined, undefined), xViewRemovedGet(xml.map));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Floor> | null)>(this.session.floors.observableListSimple(Floor.Companion.INSTANCE.forSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), undefined, undefined, undefined), xViewRemovedGet(xml.map));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(Array<Building> | null)>(this.session.buildings.observableListSimple(Building.Companion.INSTANCE.belongingToSchool(this.schoolId), undefined, 1000, undefined, undefined, undefined), undefined, undefined, undefined), xViewRemovedGet(xml.map));
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(User | null)>(this.session.me, undefined, undefined, undefined), xViewRemovedGet(xml.map));
        
        
        //--- Set Up xml.mapReload
        xViewBindVisible(xml.mapReload, xObservablePropertyCombine<boolean, (string | null), boolean>(this.loadingMap, this.map, (load: boolean, geo: (string | null)): boolean => (!load) && geo === null));
        xViewOnClick(xml.mapReload, undefined, (): void => {
            this.mapReload(mapUrl.value);
        });
        
        //--- Set Up xml.zoomIn
        xml.zoomIn.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            dg.zoom(1.333333);
        };
        
        //--- Set Up xml.zoomOut
        xml.zoomOut.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            dg.zoom(0.75);
        };
        
        //--- Set Up xml.mapLoading
        xViewFlipperBindLoading(xml.mapLoading, this.loadingMap, undefined);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    public backClick(): void {
        this.stack.pop();
    }
    //--- Action mapClick
    
    //--- Body End
}
export namespace BuildingMapSvgVG {
    //! Declares com.tresitgroup.android.tresit.vg.map.BuildingMapSvgVG.Companion
    export class Companion {
        private constructor() {
            this.buildingKey = "com.tresitgroup.android.tresit.vg.map.BuildingMapSvgVG.building";
            this.floorKey = "com.tresitgroup.android.tresit.vg.map.BuildingMapSvgVG.floor";
        }
        public static INSTANCE = new Companion();
        
        public readonly buildingKey: string;
        
        public readonly floorKey: string;
        
    }
}
