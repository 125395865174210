// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/chat/ConversationCreateVG.kt
// Package: com.tresitgroup.android.tresit.vg.chat
// Generated by Khrysalis - this file will be overwritten.
import { Observable } from 'rxjs'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { runOrNull, safeEq } from 'butterfly-web/dist/kotlin/Language'
import { MessageTarget } from '../../model/MessageTarget'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { find as iterFind, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { EqualOverrideSet, listFilterNotNull, setAddCausedChange } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xObservablePropertyFlatMap } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ConversationCreateXml } from '../../layout/ConversationCreateXml'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ComponentRowMessageTargetXml } from '../../layout/ComponentRowMessageTargetXml'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ChatThread } from '../../model/ChatThread'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.chat.ConversationCreateVG
export class ConversationCreateVG extends ViewGenerator {
    public readonly onComplete:  ((a: ChatThread) => void);
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly threadId: (ForeignKey<ChatThread> | null);
    public readonly schoolId: (number | null);
    public constructor(onComplete:  ((a: ChatThread) => void), session: SessionApi, stack: ObservableStack<ViewGenerator>, threadId: (ForeignKey<ChatThread> | null) = null, schoolId: (number | null)) {
        super();
        this.onComplete = onComplete;
        this.session = session;
        this.stack = stack;
        this.threadId = threadId;
        this.schoolId = schoolId;
        this.query = new StandardObservableProperty<string>("", undefined);
        this.buttonString = new StandardObservableProperty<string>("Create", undefined);
        this.selectedRecipients = new StandardObservableProperty<Set<MessageTarget>>(new EqualOverrideSet(), undefined);
        this.groupName = new StandardObservableProperty<string>("", undefined);
        if (this.threadId !== null) {
            this.buttonString.value = "Update";
            xSingleCallDisplayingError<ChatThread>(this.session.threads.get(this.threadId!), undefined, (it: ChatThread): void => {
                this.groupName.value = it.name ?? "";
                for (const userId of it.userIds) {
                    xSingleCallDisplayingError<User>(this.session.users.get(userId), undefined, (it: User): void => {
                        setAddCausedChange(this.selectedRecipients.value, new MessageTarget(it.name, undefined, it));
                    });
                }
                for (const schoolId of it.schools) {
                    xSingleCallDisplayingError<School>(this.session.schools.get(schoolId), undefined, (it: School): void => {
                        setAddCausedChange(this.selectedRecipients.value, new MessageTarget(it.name, it, undefined));
                    });
                }
            });
        };
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.chat.ConversationCreateVG.title
    public get title(): string { return "Conversation Create"; }
    
    
    //--- Generate Properties
    
    public readonly query: MutableObservableProperty<string>;
    
    public readonly buttonString: MutableObservableProperty<string>;
    
    
    public readonly selectedRecipients: MutableObservableProperty<Set<MessageTarget>>;
    
    private readonly groupName: MutableObservableProperty<string>;
    
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ConversationCreateXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Catch background taps
        xViewOnClick(xml.xmlRoot, undefined, (): void => {});
        xTextViewBindString(xml.post, this.buttonString);
        //--- Set Up xml.recipients
        
        xRecyclerViewBind<MessageTarget>(xml.recipients, xObservablePropertyMap<Array<MessageTarget>, Array<MessageTarget>>(xObservablePropertyFlatMap<string, Array<MessageTarget>>(this.query, (query: string): ObservableProperty<Array<MessageTarget>> => ((): ObservableProperty<Array<MessageTarget>> => {
                            if (xCharSequenceIsBlank(query)) {
                                return xObservablePropertyMap<Set<MessageTarget>, Array<MessageTarget>>(this.selectedRecipients, (it: Set<MessageTarget>): Array<MessageTarget> => ((): Array<MessageTarget> => {
                                    const temp4245 = (it: MessageTarget): (string | null) => it.display;
                                    return iterToArray(it).sort((a, b) => safeCompare(temp4245(a), temp4245(b)))
                                })());
                            } else {
                                const filters = ((): ApiFilter<User> => {
                                    if (this.schoolId === null) {
                                        return ApiFilter.Companion.INSTANCE.all<User>(User.Companion.INSTANCE.textSearch(query));
                                    } else {
                                        return ApiFilter.Companion.INSTANCE.all<User>(User.Companion.INSTANCE.textSearch(query), User.Companion.INSTANCE.filterSchool(this.schoolId!));
                                    }
                                })();
                                
                                const userTargets = xObservablePropertyMap<(Array<User> | null), Array<MessageTarget>>(this.session.users.observableListSimple(filters, undefined, 10, undefined, undefined, undefined), (it: (Array<User> | null)): Array<MessageTarget> => ((): (Array<MessageTarget> | null) => {
                                    const temp4246 = it;
                                    if(temp4246 === null) { return null }
                                    return temp4246.map((it: User): MessageTarget => new MessageTarget(`${it.firstName} ${it.lastName}`, undefined, it))
                                })() ?? []);
                                
                                const schoolTargets = xObservablePropertyMap<(Array<School> | null), Array<MessageTarget>>(this.session.schools.observableListSimple(School.Companion.INSTANCE.textSearch(query), undefined, 10, undefined, undefined, undefined), (it: (Array<School> | null)): Array<MessageTarget> => ((): (Array<MessageTarget> | null) => {
                                    const temp4248 = it;
                                    if(temp4248 === null) { return null }
                                    return temp4248.map((it: School): MessageTarget => new MessageTarget(it.name, it, undefined))
                                })() ?? []);
                                
                                return xObservablePropertyCombine<Array<MessageTarget>, Array<MessageTarget>, Array<MessageTarget>>(schoolTargets, userTargets, (a: Array<MessageTarget>, b: Array<MessageTarget>): Array<MessageTarget> => a.concat(b));
                            }
                })()), (it: Array<MessageTarget>): Array<MessageTarget> => ((): Array<MessageTarget> => {
                    const temp4250 = (it: MessageTarget): (number | null) => it.display.length;
                    return it.slice().sort((a, b) => safeCompare(temp4250(a), temp4250(b)))
            })()), new MessageTarget("", undefined, undefined), (observable: ObservableProperty<MessageTarget>): HTMLElement => {
                //--- Make Subview For xml.recipients (overwritten on flow generation)
                const cellXml = new ComponentRowMessageTargetXml();
                
                const cellView = cellXml.setup(dependency);
                
                
                //--- Set Up cellXml.isChecked
                xTextViewBindString(cellXml.isChecked, xObservablePropertyMap<MessageTarget, string>(observable, (it: MessageTarget): string => it.display));
                xCompoundButtonBind(cellXml.isChecked, xObservablePropertyWithWrite<boolean>(xObservablePropertyCombine<MessageTarget, Set<MessageTarget>, boolean>(observable, this.selectedRecipients, (it: MessageTarget, list: Set<MessageTarget>): boolean => list.has(it)), (it: boolean): void => {
                    if (this.selectedRecipients.value.has(observable.value)) {
                        this.selectedRecipients.value.delete(observable.value);
                        this.selectedRecipients.update();
                    } else {
                        setAddCausedChange(this.selectedRecipients.value, observable.value);
                        this.selectedRecipients.update();
                    }
                }));
                //--- End Make Subview For xml.recipients (overwritten on flow generation)
                return cellView;
        });
        
        //--- Set Up xml.groupNameEntry
        xEditTextBindString(xml.groupNameEntry, this.groupName);
        
        //--- Set Up xml.summmary
        xTextViewBindString(xml.summmary, xObservablePropertyMap<Set<MessageTarget>, string>(this.selectedRecipients, (it: Set<MessageTarget>): string => iterToArray(iterMap(it, (it: MessageTarget): string => it.display)).slice().sort().join(", ")));
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.query);
        
        //--- Set Up xml.cancelMessage (overwritten on flow generation)
        xViewOnClick(xml.cancelMessage, undefined, (): void => {
            this.cancelMessageClick();
        });
        
        //--- Set Up xml.post (overwritten on flow generation)
        xViewOnClick(xml.post, undefined, (): void => {
            this.postClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public cancelMessageClick(): void {
        this.stack.dismiss();
    }
    public postClick(): void {
        if (this.selectedRecipients.value.size === 0) { return }
        const userId = this.session.session.userId;
        
        const userIds = ([] as Array<number>);
        
        userIds.push(...listFilterNotNull(iterToArray(iterMap(this.selectedRecipients.value, (it: MessageTarget): (number | null) => (it.user?.id ?? null)))));
        if ((!userIds.some((x) => safeEq(userId, x)))) {
            userIds.push(userId);
        }
        const schoolIds = listFilterNotNull(iterToArray(iterMap(this.selectedRecipients.value, (it: MessageTarget): (number | null) => (it.school?.id ?? null))));
        
        xSingleCallDisplayingError<Array<ChatThread>>(this.session.threads.getListSimple(ChatThread.Companion.INSTANCE.search(this.groupName.value), undefined, undefined, undefined, undefined), undefined, (it: Array<ChatThread>): void => {
            const matching_4269 = iterFind(it, (it: ChatThread): boolean => ((): (string | null) => {
                const temp4273 = it.name;
                if(temp4273 === null) { return null }
                return temp4273.toLowerCase()
            })() === this.groupName.value.toLowerCase());
            if (matching_4269 !== null) {
                if (!(matching_4269.id === this.threadId)) {
                    showDialogAlert(new ViewStringResource(R._string.validation_group_name_taken));
                    return;
                }
            }
            
            const call: Observable<ChatThread> = ((): Observable<ChatThread> => {
                if (this.threadId !== null) {
                    return this.session.rawApi.threads(this.session.session).patch(this.threadId!, [ChatThread.Companion.INSTANCE.setUsers(userIds), ChatThread.Companion.INSTANCE.setSchools(schoolIds), ChatThread.Companion.INSTANCE.setName(this.groupName.value)]);
                } else {
                    return this.session.rawApi.threads(this.session.session).post(new ChatThread(undefined, undefined, this.groupName.value, undefined, userIds, schoolIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
                }
            })();
            
            xSingleCallDisplayingError<ChatThread>(call, undefined, (thread: ChatThread): void => {
                this.onComplete(thread);
                this.stack.dismiss();
            });
        });
        
    }
    
    //--- Body End
}
