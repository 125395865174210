// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/ApiTable.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { ApiModifier } from './ApiModifier'
import { Observable } from 'rxjs'
import { ForeignKey } from '../model/ForeignKey'
import { ApiPartialQuery } from './ApiPartialQuery'

//! Declares com.tresitgroup.android.tresit.api.ApiTable
export abstract class ApiTable<T extends HasId> {
    protected constructor() {
    }
    
    public abstract get(id: ForeignKey<T>): Observable<T>
    public abstract getMultiple(ids: Array<ForeignKey<T>>): Observable<Array<T>>
    public abstract getList(query: ApiPartialQuery<T>): Observable<Array<T>>
    public abstract put(value: T): Observable<T>
    public abstract post(value: T): Observable<T>
    public abstract patch(id: ForeignKey<T>, modifiers: Array<ApiModifier<T>>): Observable<T>
    public abstract _delete(id: ForeignKey<T>): Observable<void>
}

