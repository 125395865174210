//
// SelectAlertXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './select_alert.html'
//! Declares com.tresitgroup.android.tresit.layouts.SelectAlertXml
export class SelectAlertXml {
    xmlRoot!: HTMLElement
    selectAlertOrDrill!: HTMLElement
    back!: HTMLButtonElement
    alertTypeLoader!: HTMLDivElement
    list!: HTMLDivElement
    silentAlert!: HTMLInputElement
    internalAlert!: HTMLInputElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.selectAlertOrDrill = getViewById<HTMLElement>(view, "select_alert_or_drill")
        this.back = getViewById<HTMLButtonElement>(view, "back")
        this.alertTypeLoader = getViewById<HTMLDivElement>(view, "alertTypeLoader")
        this.list = getViewById<HTMLDivElement>(view, "list")
        this.silentAlert = getViewById<HTMLInputElement>(view, "silentAlert")
        this.internalAlert = getViewById<HTMLInputElement>(view, "internalAlert")
        return view
    }
}
