// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/api/CachedApiTable.kt
// Package: com.tresitgroup.android.tresit.api
// Generated by Khrysalis - this file will be overwritten.
import { Exception, IllegalStateException, safeEq } from 'butterfly-web/dist/Kotlin'
import { ApiFilter } from './ApiFilter'
import { delay as rxDelay, finalize as rxFinalize, flatMap as rxFlatMap, map as rxMap, onErrorResumeNext as rxOnErrorResumeNext, shareReplay as rxShareReplay, tap as rxTap } from 'rxjs/operators'
import { CacheNode } from './CacheNode'
import { Observable, ObservableInput, Subject, SubscriptionLike, of as rxOf, throwError as rxThrowError, zip as rxZip } from 'rxjs'
import { xResponseCodeGet } from 'butterfly-web/dist/net/HttpResponse'
import { Refreshable } from './Refreshable'
import { SocketInfo, xSocketInfoUpToDate } from './SocketInfo'
import { xMutableMapGetOrPut } from 'butterfly-web/dist/kotlin/Collections'
import { ApiModifier } from './ApiModifier'
import { xResponseReadText } from 'butterfly-web/dist/net/RxHttpAssist'
import { ApiTable } from './ApiTable'
import { ApiPartialQuery } from './ApiPartialQuery'
import { find as iterFind, map as iterMap, toArray as iterToArray } from 'butterfly-web/dist/kotlin/lazyOp'
import { HasId } from '../model/HasId'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { TypedSocketMessage } from '../model/TypedSocketMessage'
import { Time } from '../util/time'
import { ApiSort } from './ApiSort'
import { CacheEdge } from './CacheEdge'
import { EqualOverrideMap, iterableFilterNotNull, setAddCausedChange } from 'butterfly-web/dist/KotlinCollections'
import { printStackTrace, runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { HttpResponseException } from 'butterfly-web/dist/net/HttpResponseError'
import { ApiQuery } from './ApiQuery'
import { ForeignKey } from '../model/ForeignKey'

//! Declares com.tresitgroup.android.tresit.api.CachedApiTable
export class CachedApiTable<T extends HasId> extends ApiTable<T> implements Refreshable {
    public static implementsInterfaceComTresitgroupAndroidTresitApiRefreshable = true;
    public readonly socketInfo: SocketInfo;
    public readonly getUnderlyingTable:  (() => ApiTable<T>);
    public readonly expiration: number;
    public readonly softDelete: (((a: T) => T) | null);
    public readonly onMessage:  ((a: TypedSocketMessage<T>) => void);
    public readonly defaultFilter: ApiFilter<T>;
    public constructor(socketInfo: SocketInfo, socketObs: Observable<TypedSocketMessage<T>>, getUnderlyingTable:  (() => ApiTable<T>), expiration: number, softDelete: (((a: T) => T) | null) = null, onMessage:  ((a: TypedSocketMessage<T>) => void) = (it: TypedSocketMessage<T>): void => {}, defaultFilter: ApiFilter<T> = ApiFilter.Companion.INSTANCE.always<T>()) {
        super();
        this.socketInfo = socketInfo;
        this.getUnderlyingTable = getUnderlyingTable;
        this.expiration = expiration;
        this.softDelete = softDelete;
        this.onMessage = onMessage;
        this.defaultFilter = defaultFilter;
        this.items = new Map<number, CacheNode<T>>();
        this.knownEdges = new EqualOverrideMap<ApiQuery<T>, CacheEdge<T>>();
        this.socketListener = socketObs.subscribe((msg: TypedSocketMessage<T>): void => {
            const it_119 = msg.data;
            if (it_119 !== null) {
                this.received(it_119, msg.timestamp.getTime());
            } else {
                this.terminated(msg.id!!, msg.timestamp.getTime());
            }
        }, (it: any): void => {}, undefined);
        this.anyUpdated = new Subject();
        this.building = null;
    }
    
    public readonly items: Map<number, CacheNode<T>>;
    
    public readonly knownEdges: Map<ApiQuery<T>, CacheEdge<T>>;
    
    
    //! Declares com.tresitgroup.android.tresit.api.CachedApiTable.underlyingTable
    public get underlyingTable(): ApiTable<T> { return this.getUnderlyingTable(); }
    
    
    
    
    private readonly socketListener: SubscriptionLike;
    
    
    public readonly anyUpdated: Subject<ForeignKey<T>>;
    
    
    public clear(): void {
        for (const item of this.items.values()) {
            item.clear();
        }
        for (const item of this.knownEdges.values()) {
            item.clear();
        }
    }
    
    public refresh(): void {
        for (const toDestructure of this.items) {
            const key = toDestructure[0]
            const item = toDestructure[1]
            
            if ((item.onChangeSubject.observers.length > 0)) {
                this.refreshSingle(key, item);
            }
            
        }
        for (const toDestructure of this.knownEdges) {
            const key = toDestructure[0]
            const item = toDestructure[1]
            
            if ((item.onChangeSubject.observers.length > 0)) {
                this.refreshList(new ApiPartialQuery<T>(key, item.outboundRequestCount, undefined), item);
            }
            
        }
    }
    
    public close(): void {
        this.socketListener.unsubscribe();
    }
    
    
    
    public building: (CachedApiTable.BuildingMultiRequest<T> | null);
    
    private makeRequest(id: ForeignKey<T>): Observable<T> {
        if (CachedApiTable.Companion.INSTANCE.timeOptimization > 0) {
            const building = this.building;
            
            if (building !== null) {
                setAddCausedChange(building!.set, id);
                return building!.single.pipe(rxMap((it: Array<T>): T => {
                    const result = iterFind(it, (it: T): boolean => it.id === id);
                    
                    if (result !== null) {
                        return result;
                    } else {
                        this.terminated(id, undefined);
                        throw new NoSuchElementException(`No element ${id} found.`, undefined);
                    }
                }));
            } else {
                const newlyBuilding = new CachedApiTable.BuildingMultiRequest<T>();
                
                setAddCausedChange(newlyBuilding.set, id);
                const subj: Subject<Array<T>> = new Subject();
                
                xDisposableForever<SubscriptionLike>(rxOf(newlyBuilding).pipe(rxDelay(CachedApiTable.Companion.INSTANCE.timeOptimization)).pipe(rxFlatMap((it: CachedApiTable.BuildingMultiRequest<T>): ObservableInput<Array<T>> => {
                                this.building = null;
                                return this.underlyingTable.getMultiple(iterToArray(it.set));
                    })).subscribe((it: Array<T>): void => {
                            for (const item of it) {
                                this.received(item, undefined);
                            }
                            subj.next(it);
                            subj.complete();
                        }, (it: any): void => {
                            subj.error(it);
                }));
                newlyBuilding.single = subj;
                this.building = newlyBuilding;
                return newlyBuilding.single.pipe(rxMap((it: Array<T>): T => {
                    const result = iterFind(it, (it: T): boolean => it.id === id);
                    
                    if (result !== null) {
                        return result;
                    } else {
                        this.terminated(id, undefined);
                        throw new NoSuchElementException(`No element ${id} found.`, undefined);
                    }
                }));
            }
        } else {
            return this.underlyingTable.get(id).pipe(rxTap(undefined, (it: any): void => {
                if (it instanceof HttpResponseException && xResponseCodeGet((it as HttpResponseException).response) === 404) {
                    this.terminated(id, undefined);
                }
            })).pipe(rxTap((it: T): void => {
                this.received(it, undefined);
            })).pipe(rxShareReplay(1));
        }
    }
    
    private refreshSingle(id: ForeignKey<T>, node: CacheNode<T>): void {
        if (id === (-1)) { return }
        if (xSocketInfoUpToDate(this.socketInfo, node.lastUpdated, this.expiration)) {
            return;
        }
        const it_153 = node.outboundRequest;
        if (it_153 !== null) {
            return;
        }
        console.debug(`${"CachedApiTable"}: ${`Item ${id} is out of date; pulling...`}`);
        const req = this.makeRequest(id).pipe(rxFinalize((): void => {
            node.outboundRequest = null;
        }));
        
        node.outboundRequest = req;
        xDisposableForever<SubscriptionLike>(req.subscribe(undefined, (it: any): void => {
            console.warn(`${"CachedApiTable"}: ${"Failed to retrieve an item; moving on."}`);
            printStackTrace(it);
            if (it instanceof HttpResponseException) {
                xResponseReadText((it as HttpResponseException).response).subscribe((it: string): void => {
                    console.warn(`${"CachedApiTable"}: ${`Response was '${it}'`}`);
                }, (it: any): void => {});
            }
        }));
    }
    
    private refreshList(query: ApiPartialQuery<T>, edge: CacheEdge<T>): void {
        const minCount = query.limit + query.offset;
        
        if (xSocketInfoUpToDate(this.socketInfo, edge.lastUpdated, this.expiration)) {
            if (edge.hasAll || minCount <= edge.references.length) {
                return;
            }
        }
        if (minCount <= edge.outboundRequestCount) {
            const it_171 = edge.outboundRequest;
            if (it_171 !== null) {
                return;
            }
        }
        
        if (query.query.filter.mockFilter === null && query.query.sort.mockComparator === null) {
            const simplifiedAll_172 = iterFind(this.knownEdges.values(), (it: CacheEdge<T>): boolean => xSocketInfoUpToDate(this.socketInfo, it.lastUpdated, this.expiration) &&
                it.hasAll &&
                (it.query.filter.params === "" || (query.query.filter.params.indexOf(it.query.filter.params) != -1)) &&
            it.populated);
            if (simplifiedAll_172 !== null) {
                const v_179 = simplifiedAll_172.value;
                if (v_179 !== null) {
                    console.debug(`${"CachedApiTable"}: ${"Found existing superquery, using contents"}`);
                    edge.fromOther(v_179);
                }
                return;
            }
            const simplifiedAll_182 = iterFind(this.knownEdges.values(), (it: CacheEdge<T>): boolean => xSocketInfoUpToDate(this.socketInfo, it.lastUpdated, this.expiration) &&
                it.populated &&
                it.references.length >= minCount &&
                (it.query.filter.params === "" || (query.query.filter.params.indexOf(it.query.filter.params) != -1)) &&
            it.value!!.filter((it: T): boolean => query.query.filter.filter(it)).length > minCount);
            if (simplifiedAll_182 !== null) {
                const v_195 = simplifiedAll_182.value;
                if (v_195 !== null) {
                    console.debug(`${"CachedApiTable"}: ${"Found existing matching-sort superquery, using contents"}`);
                    edge.fromOther(v_195);
                }
                return;
            }
        }
        
        console.debug(`${"CachedApiTable"}: ${`Query ${query} is out of date; pulling...`}`);
        const req = this.underlyingTable.getList(query.copy(undefined, minCount, 0)).pipe(rxTap((it: Array<T>): void => {
            for (const item of it) {
                this.received(item, undefined);
            }
            edge.update(it, minCount);
        })).pipe(rxFinalize((): void => {
            edge.outboundRequest = null;
        })).pipe(rxShareReplay(1));
        
        edge.outboundRequest = req;
        edge.outboundRequestCount = query.limit;
        xDisposableForever<SubscriptionLike>(req.subscribe(undefined, (it: any): void => {
            console.warn(`${"CachedApiTable"}: ${"Failed to retrieve a list; moving on."}`);
            printStackTrace(it);
            if (it instanceof HttpResponseException) {
                xResponseReadText((it as HttpResponseException).response).subscribe((it: string): void => {
                    console.warn(`${"CachedApiTable"}: ${`Response was '${it}'`}`);
                }, (it: any): void => {});
            }
        }));
    }
    
    public cached(id: ForeignKey<T>): CacheNode<T> {
        return xMutableMapGetOrPut<number, CacheNode<T>>(this.items, id, (): CacheNode<T> => new CacheNode<T>(undefined));
    }
    
    public cachedList(query: ApiQuery<T>): CacheEdge<T> {
        return xMutableMapGetOrPut<ApiQuery<T>, CacheEdge<T>>(this.knownEdges, query, (): CacheEdge<T> => new CacheEdge<T>(this, query, undefined));
    }
    
    public cachedListSimple(filter: (ApiFilter<T> | null) = null, sort: ApiSort<T> = ApiSort.Companion.INSTANCE.byId<T>(), includeDefaultFilter: boolean = true): CacheEdge<T> {
        if (filter !== null) {
            if (includeDefaultFilter) {
                return this.cachedList(new ApiQuery<T>(ApiFilter.Companion.INSTANCE.all<T>(filter!, this.defaultFilter), sort));
            } else {
                return this.cachedList(new ApiQuery<T>(filter!, sort));
            }
        } else {
            if (includeDefaultFilter) {
                return this.cachedList(new ApiQuery<T>(this.defaultFilter, sort));
            } else {
                return this.cachedList(new ApiQuery<T>(ApiFilter.Companion.INSTANCE.always<T>(), sort));
            }
        }
    }
    
    public observable(id: ForeignKey<T>): CacheNode<T> {
        const node = this.cached(id);
        
        this.refreshSingle(id, node);
        return node;
    }
    
    public observableList(query: ApiPartialQuery<T>, acceptAdvancedFilter: boolean = false): CacheEdge<T> {
        if ((!acceptAdvancedFilter) && query.query.filter.mockFilter !== null) { throw "You cannot observe a list with a complex filter." }
        const edge = this.cachedList(query.query);
        
        this.refreshList(query, edge);
        return edge;
    }
    
    public observableListSimple(filter: (ApiFilter<T> | null) = null, sort: ApiSort<T> = ApiSort.Companion.INSTANCE.byId<T>(), limit: number = 100, offset: number = 0, includeDefaultFilter: boolean = true, acceptAdvancedFilter: boolean = false): CacheEdge<T> {
        if (filter !== null) {
            if (includeDefaultFilter) {
                return this.observableList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(ApiFilter.Companion.INSTANCE.all<T>(filter!, this.defaultFilter), sort, limit, offset), acceptAdvancedFilter);
            } else {
                return this.observableList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(filter!, sort, limit, offset), acceptAdvancedFilter);
            }
        } else {
            if (includeDefaultFilter) {
                return this.observableList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(this.defaultFilter, sort, limit, offset), acceptAdvancedFilter);
            } else {
                return this.observableList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(ApiFilter.Companion.INSTANCE.always<T>(), sort, limit, offset), acceptAdvancedFilter);
            }
        }
    }
    
    public get(id: ForeignKey<T>): Observable<T> {
        const node = this.observable(id);
        
        return node.outboundRequest ?? ((): (Observable<T> | null) => {
            const temp214 = node.value;
            if(temp214 === null) { return null }
            return ((it: T): Observable<T> => rxOf(it))(temp214)
        })() ?? rxThrowError(new IllegalStateException("Object has been deleted", undefined));
    }
    
    public getMultiple(ids: Array<ForeignKey<T>>): Observable<Array<T>> {
        return rxZip(...ids.map((it: number): Observable<T> => this.get(it)).map((it: Observable<T>): Observable<(T | null)> => it.pipe(rxMap((it: T): (T | null) => it)).pipe(rxOnErrorResumeNext(rxOf(null))))).pipe(rxMap((it: Array<(T | null)>): Array<T> => iterToArray(iterableFilterNotNull(iterMap(it, (it: (T | null)): (T | null) => it)))));
    }
    
    public getList(query: ApiPartialQuery<T>): Observable<Array<T>> {
        if (query.query.filter.mockFilter !== null) {
            return this.underlyingTable.getList(query);
        }
        const node = this.observableList(query, undefined);
        
        return ((): (Observable<Array<T>> | null) => {
            const temp227 = node.outboundRequest;
            if(temp227 === null) { return null }
            return temp227.pipe(rxMap((it: Array<T>): Array<T> => it.slice(query.offset).slice(0, query.limit)))
        })() ?? rxOf(node.value!!.slice(query.offset).slice(0, query.limit));
    }
    
    public getListSimple(filter: (ApiFilter<T> | null) = null, sort: ApiSort<T> = ApiSort.Companion.INSTANCE.byId<T>(), limit: number = 100, offset: number = 0, includeDefaultFilter: boolean = true): Observable<Array<T>> {
        if (filter !== null) {
            if (includeDefaultFilter) {
                return this.getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(ApiFilter.Companion.INSTANCE.all<T>(filter!, this.defaultFilter), sort, limit, offset));
            } else {
                return this.getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(filter!, sort, limit, offset));
            }
        } else {
            if (includeDefaultFilter) {
                return this.getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(this.defaultFilter, sort, limit, offset));
            } else {
                return this.getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(ApiFilter.Companion.INSTANCE.always<T>(), sort, limit, offset));
            }
        }
    }
    
    public put(value: T): Observable<T> {
        return this.underlyingTable.put(value).pipe(rxTap((result: T): void => {
            this.received(result, undefined);
        }));
    }
    
    public post(value: T): Observable<T> {
        return this.underlyingTable.post(value).pipe(rxTap((result: T): void => {
            this.received(result, undefined);
        }));
    }
    
    public patch(id: ForeignKey<T>, modifiers: Array<ApiModifier<T>>): Observable<T> {
        return this.underlyingTable.patch(id, modifiers).pipe(rxTap((result: T): void => {
            this.received(result, undefined);
        }));
    }
    
    public _delete(id: ForeignKey<T>): Observable<void> {
        return this.underlyingTable._delete(id).pipe(rxTap((it: void): void => {
            this.terminated(id, undefined);
        }));
    }
    
    public received(value: T, at: number = Time.INSTANCE.epochMilliseconds()): void {
        const existing = (this.items.get(value.id) ?? null);
        
        //Kick all messages older than last received
        if (at < ((existing?.lastUpdated ?? null) ?? 0)) { return }
        
        this.onMessage(new TypedSocketMessage<T>(undefined, value, undefined, undefined));
        this.anyUpdated.next(value.id);
        if (existing !== null) {
            existing!.touch(at);
            if (safeEq(existing!.value, value)) { return }
            existing!.update(value);
        } else {
            const newNode: CacheNode<T> = new CacheNode<T>(undefined);
            
            newNode.touch(at);
            newNode.update(value);
            this.items.set(value.id, newNode);
        }
        for (const toDestructure of this.knownEdges) {
            const query = toDestructure[0]
            const edge = toDestructure[1]
            
            edge.changed(value)
            
        }
    }
    
    public terminated(id: ForeignKey<T>, at: number = Time.INSTANCE.epochMilliseconds()): void {
        const existing = (this.items.get(id) ?? null);
        
        //Kick all messages older than last received
        if (at < ((existing?.lastUpdated ?? null) ?? 0)) { return }
        
        this.onMessage(new TypedSocketMessage<T>(undefined, undefined, undefined, id));
        this.anyUpdated.next(id);
        existing?.touch(at);
        if (existing !== null) {
            const value = existing!.value;
            
            if (value !== null) {
                const newValue = ((): (T | null) => {
                    const temp255 = this.softDelete;
                    if(temp255 === null) { return null }
                    return ((it: (a: T) => T): T => it(value!))(temp255)
                })();
                
                existing!.update(newValue);
                if (newValue !== null) {
                    for (const toDestructure of this.knownEdges) {
                        const query = toDestructure[0]
                        const edge = toDestructure[1]
                        
                        edge.changed(newValue!)
                        
                    }
                } else {
                    for (const toDestructure of this.knownEdges) {
                        const query = toDestructure[0]
                        const edge = toDestructure[1]
                        
                        edge.terminated(id)
                        
                    }
                }
            }
            if (this.softDelete === null) {
                existing!.value = null;
            }
        }
    }
    
    //    override fun createOffsite(id: String): Single<T> {
        //        return createOffsite(id)
    //    }
}
export namespace CachedApiTable {
    //! Declares com.tresitgroup.android.tresit.api.CachedApiTable.Companion
    export class Companion {
        private constructor() {
            this.timeOptimization = 16;
        }
        public static INSTANCE = new Companion();
        
        public timeOptimization: number;
        
    }
}
export namespace CachedApiTable {
    //! Declares com.tresitgroup.android.tresit.api.CachedApiTable.BuildingMultiRequest
    export class BuildingMultiRequest<T> {
        public constructor() {
            this.set = new Set();
        }
        
        public set: Set<ForeignKey<T>>;
        
        public single: Observable<Array<T>>;
        
    }
}

//! Declares com.tresitgroup.android.tresit.api.NoSuchElementException
export class NoSuchElementException extends Exception {
    public constructor(message: string = "Element not found", cause: (any | null) = null) {
        super(message, cause);
    }
}