//
// ComponentUserSchoolXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './component_user_school.html'
//! Declares com.tresitgroup.android.tresit.layouts.ComponentUserSchoolXml
export class ComponentUserSchoolXml {
    xmlRoot!: HTMLElement
    root!: HTMLDivElement
    label!: HTMLElement
    remove!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.root = getViewById<HTMLDivElement>(view, "root")
        this.label = getViewById<HTMLElement>(view, "label")
        this.remove = getViewById<HTMLButtonElement>(view, "remove")
        return view
    }
}
