// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/EditUserVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ViewStringRaw, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { EditUserXml } from '../../layout/EditUserXml'
import { ApiModifier } from '../../api/ApiModifier'

//! Declares com.tresitgroup.android.tresit.vg.settings.EditUserVG
export class EditUserVG extends ViewGenerator {
    public readonly session: SessionApi;
    public constructor(session: SessionApi) {
        super();
        this.session = session;
        this.initial = null;
        this.firstName = new StandardObservableProperty<string>("", undefined);
        this.lastName = new StandardObservableProperty<string>("", undefined);
        this.cellPhone = new StandardObservableProperty<string>("", undefined);
        this.newPassword = new StandardObservableProperty<string>("", undefined);
        this.newPasswordConfirm = new StandardObservableProperty<string>("", undefined);
        this.submitting = new StandardObservableProperty<boolean>(false, undefined);
        this.updating = new StandardObservableProperty<boolean>(false, undefined);
        xSingleCallDisplayingError<User>(this.session.users.get(this.session.session.userId), undefined, (it: User): void => {
            this.initial = it;
            this.firstName.value = it.firstName;
            this.lastName.value = it.lastName;
            this.cellPhone.value = it.cellPhone ?? "xxx-xxx-xxxx";
        });
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.EditUserVG.title
    public get title(): string { return "Edit User"; }
    
    
    public initial: (User | null);
    
    public readonly firstName: MutableObservableProperty<string>;
    
    public readonly lastName: MutableObservableProperty<string>;
    
    public readonly cellPhone: MutableObservableProperty<string>;
    
    public readonly newPassword: MutableObservableProperty<string>;
    
    public readonly newPasswordConfirm: MutableObservableProperty<string>;
    
    
    public readonly submitting: MutableObservableProperty<boolean>;
    
    public readonly updating: MutableObservableProperty<boolean>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new EditUserXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.editFirstName
        xEditTextBindString(xml.editFirstName, this.firstName);
        
        //--- Set Up xml.editLastName
        xEditTextBindString(xml.editLastName, this.lastName);
        
        //--- Set Up xml.editEmail
        xTextViewBindString(xml.editEmail, xObservablePropertyMap<(User | null), string>(this.session.me, (it: (User | null)): string => (it?.email ?? null) ?? "-"));
        
        //--- Set Up xml.editCellNumber
        xEditTextBindString(xml.editCellNumber, this.cellPhone);
        
        //--- Set Up xml.updatingUser
        xViewFlipperBindLoading(xml.updatingUser, this.updating, undefined);
        
        //--- Set Up xml.updateUser (overwritten on flow generation)
        xViewOnClick(xml.updateUser, undefined, (): void => {
            this.updateUserClick();
        });
        
        //--- Set Up xml.newPassword
        xEditTextBindString(xml.newPassword, this.newPassword);
        
        //--- Set Up xml.newPasswordConfirm
        xEditTextBindString(xml.newPasswordConfirm, this.newPasswordConfirm);
        
        //--- Set Up xml.updatingPassword
        xViewFlipperBindLoading(xml.updatingPassword, this.submitting, undefined);
        
        //--- Set Up xml.submitNewPassword (overwritten on flow generation)
        xViewOnClick(xml.submitNewPassword, undefined, (): void => {
            this.submitNewPasswordClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public updateUserClick(): void {
        const changes: Array<ApiModifier<User>> = [];
        
        if (!(this.firstName.value === (this.initial?.firstName ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("first_name", this.firstName.value, (it: User): User => it.copy(undefined, undefined, undefined, this.firstName.value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        
        if (!(this.lastName.value === (this.initial?.lastName ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("last_name", this.lastName.value, (it: User): User => it.copy(undefined, undefined, undefined, undefined, this.lastName.value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        
        if (!(this.cellPhone.value === (this.initial?.cellPhone ?? null))) {
            changes.push(ApiModifier.constructorStringAnyFunction1HasIdHasId<User>("cell_phone", this.cellPhone.value, (it: User): User => it.copy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, this.cellPhone.value, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
        }
        
        if (changes.length !== 0) {
            xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(this.session.session.userId, changes), this.updating), undefined, (it: User): void => {
                showDialogAlert(new ViewStringResource(R._string.message_user_updated));
            });
        }
    }
    public submitNewPasswordClick(): void {
        if (!(this.newPasswordConfirm.value === this.newPassword.value)) {
            showDialogAlert(new ViewStringRaw("Passwords do not match"));
        } else {
            xSingleCallDisplayingError<void>(xSingleWorking<void>(this.session.rawApi.changePassword(this.session.session, this.newPassword.value), this.submitting), undefined, (it: void): void => {
                this.newPassword.value = "";
                this.newPasswordConfirm.value = "";
                showDialogAlert(new ViewStringRaw("Success: Password successfully changed"));
            });
        }
    }
    
    //--- Body End
}

