// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/util/PagingHelper.kt
// Package: com.tresitgroup.android.tresit.util
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from '../model/HasId'
import { ApiTable } from '../api/ApiTable'
import { filter as rxFilter, map as rxMap, onErrorResumeNext as rxOnErrorResumeNext, share as rxShare, switchMap as rxSwitchMap, tap as rxTap } from 'rxjs/operators'
import { Observable, ObservableInput, ReplaySubject, Subject, of as rxOf } from 'rxjs'
import { ApiPartialQuery } from '../api/ApiPartialQuery'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ApiQuery } from '../api/ApiQuery'

//! Declares com.tresitgroup.android.tresit.util.PagingHelper
export class PagingHelper<T extends HasId> extends ObservableProperty<Array<T>> {
    public readonly query: Observable<ApiQuery<T>>;
    public readonly fetch:  ((query: ApiQuery<T>, page: number) => Observable<Array<T>>);
    public constructor(query: Observable<ApiQuery<T>>, fetch:  ((query: ApiQuery<T>, page: number) => Observable<Array<T>>)) {
        super();
        this.query = query;
        this.fetch = fetch;
        this.refreshSubject = new ReplaySubject(undefined);
        this.loadNewPageSubject = new ReplaySubject(undefined);
        this.refreshSubject.next(undefined);
        this.loadNewPageSubject.next(undefined);
        this.loading = new StandardObservableProperty<boolean>(false, undefined);
        this._wrapped = null;
        this.value = [];
    }
    
    private readonly refreshSubject: ReplaySubject<void>;
    
    public refresh(): void {
        this.refreshSubject.next(undefined);
    }
    
    private readonly loadNewPageSubject: ReplaySubject<void>;
    
    public loadNewPage(): void {
        this.loadNewPageSubject.next(undefined);
    }
    
    
    
    public readonly loading: StandardObservableProperty<boolean>;
    
    
    public _wrapped: (Observable<Array<T>> | null);
    
    //! Declares com.tresitgroup.android.tresit.util.PagingHelper.wrapped
    public get wrapped(): Observable<Array<T>> {
        const it_2945 = this._wrapped;
        if (it_2945 !== null) {
            return it_2945;
        }
        const setTo = this.refreshSubject.pipe(rxSwitchMap((it: void): ObservableInput<Array<T>> => this.query.pipe(rxSwitchMap((q: ApiQuery<T>): ObservableInput<Array<T>> => {
            let page = 0;
            
            let loading = false;
            
            let atEnd = false;
            
            let data: Array<T> = [];
            
            
            return this.loadNewPageSubject.pipe(rxFilter((it: void): boolean => (!loading))).pipe(rxFilter((it: void): boolean => {
                if (atEnd) { this.loading.value = false }
                return (!atEnd);
            })).pipe(rxSwitchMap((it: void): ObservableInput<Array<T>> => {
                loading = true;
                this.loading.value = true;
                return this.fetch(q, page).pipe(rxTap((it: Array<T>): void => {
                    if (it.length === 0) {
                        atEnd = true;
                    }
                    data = data.concat(it);
                    page = page + 1;
                    loading = false;
                    this.loading.value = false;
                })).pipe(rxTap(undefined, (it: any): void => {
                    loading = false;
                    this.loading.value = false;
                })).pipe(rxOnErrorResumeNext(rxOf())).pipe(rxMap((it: Array<T>): Array<T> => data));
            }));
        }))));
        
        this._wrapped = setTo;
        return setTo;
    }
    
    public value: Array<T>;
    
    //! Declares com.tresitgroup.android.tresit.util.PagingHelper.onChange
    public get onChange(): Observable<Array<T>> { return this.wrapped.pipe(rxTap((it: Array<T>): void => {
        this.value = it;
    })).pipe(rxMap((it: Array<T>): Array<T> => it)).pipe(rxShare()); }
    
}

//! Declares com.tresitgroup.android.tresit.util.getPaging>com.tresitgroup.android.tresit.api.ApiTable<com.tresitgroup.android.tresit.model.HasId>
export function xApiTableGetPaging<T extends HasId>(this_: ApiTable<T>, query: Observable<ApiQuery<T>>, pageSize: number = 100): PagingHelper<T> {
    return new PagingHelper<T>(query, (q: ApiQuery<T>, page: number): Observable<Array<T>> => this_.getList(ApiPartialQuery.constructorApiFilterHasIdApiSortHasIdIntInt<T>(q.filter, q.sort, pageSize, pageSize * page)));
}