// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/AdminAlertRequestVg.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { R } from '../../R'
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { SessionApi } from '../../api/SessionApi'
import { ItemAdminRequestHistoryXml } from '../../layout/ItemAdminRequestHistoryXml'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { AdminRequestHistory } from '../../model/AdminRequestHistory'
import { School } from '../../model/School'
import { LayoutAdminRequestHistoryXml } from '../../layout/LayoutAdminRequestHistoryXml'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'

//! Declares com.tresitgroup.android.tresit.vg.admin.AdminAlertRequestVg
export class AdminAlertRequestVg extends ViewGenerator {
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public constructor(schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi) {
        super();
        this.schoolFilter = schoolFilter;
        this.adminRequestList = new StandardObservableProperty<Array<AdminRequestHistory>>([], undefined);
        const query = "limit=100&offset=0";
        ;
        session.rawApi.getAdminAlertHistory(session.session, query).subscribe((it: Array<AdminRequestHistory>): void => {
                this.adminRequestList.value = it;
            }, (it: any): void => {
                showDialogAlert(new ViewStringResource(R._string.something_went_wrong));
        });
    }
    
    
    public readonly adminRequestList: MutableObservableProperty<Array<AdminRequestHistory>>;
    
    
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LayoutAdminRequestHistoryXml();
        
        const view = xml.setup(dependency);
        
        
        xRecyclerViewBind<AdminRequestHistory>(xml.rvGroupName, this.adminRequestList, new AdminRequestHistory(1, "", new Date(), " ", 2, 2), (observable: ObservableProperty<AdminRequestHistory>): HTMLElement => {
            //--- Make Subview For xml.list (overwritten on flow generation)
            const cellXml = new ItemAdminRequestHistoryXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.nameView, xObservablePropertyMap<AdminRequestHistory, string>(observable, (it: AdminRequestHistory): string => it.text ?? ""));
            
            xTextViewBindString(cellXml.startTime, xObservablePropertyMap<AdminRequestHistory, string>(observable, (it: AdminRequestHistory): string => xDateFormat(it.created, ClockPartSize.Short, ClockPartSize.Short)));
            //--- Click
            cellView.onclick = (_ev) => { _ev.stopPropagation();
                const it = _ev.target as HTMLElement;
                //  this.onSelected(observable.value, openingUserConversation)
            };
            
            //--- End Make Subview For xml.list
            return cellView;
        });
        
        return view;
        
    }
    
}