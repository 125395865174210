//
// AlertCancelXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw960dp from '../layout-w960dp/alert_cancel.html'
import htmlForDefault from './alert_cancel.html'
//! Declares com.tresitgroup.android.tresit.layouts.AlertCancelXml
export class AlertCancelXml {
    xmlRoot!: HTMLElement
    resolved!: HTMLInputElement
    falseAlarm!: HTMLInputElement
    other!: HTMLInputElement
    cancelReasonView!: HTMLTextAreaElement
    cancelButton!: HTMLButtonElement
    endWorking!: HTMLDivElement
    endAlert!: HTMLButtonElement
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.resolved = getViewById<HTMLInputElement>(view, "resolved")
        this.falseAlarm = getViewById<HTMLInputElement>(view, "false_alarm")
        this.other = getViewById<HTMLInputElement>(view, "other")
        this.cancelReasonView = getViewById<HTMLTextAreaElement>(view, "cancel_reason_view")
        this.cancelButton = getViewById<HTMLButtonElement>(view, "cancel_button")
        this.endWorking = getViewById<HTMLDivElement>(view, "endWorking")
        this.endAlert = getViewById<HTMLButtonElement>(view, "end_alert")
        return view
    }
}
