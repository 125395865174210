// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/School.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { HasId, getNO_ID } from './HasId'
import { RemoteFileRef } from './RemoteFileRef'
import { AlertStatusEnum } from './AlertStatusEnum'
import { User } from './User'
import { District } from './District'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'
import { MockDatabase } from '../api/MockDatabase'

//! Declares com.tresitgroup.android.tresit.model.School
export class School implements HasId {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public id: number;
    public readonly name: string;
    public readonly drill_enabled: boolean;
    public show_safe_unsafe: boolean;
    public readonly status: AlertStatusEnum;
    public readonly svgMap: (RemoteFileRef | null);
    public readonly jsonMap: (RemoteFileRef | null);
    public readonly district: (ForeignKey<District> | null);
    public readonly icon: (RemoteFileRef | null);
    public readonly address: (string | null);
    public readonly alertsEnabled: boolean;
    public readonly boardsEnabled: boolean;
    public readonly chatEnabled: boolean;
    public readonly mapsEnabled: boolean;
    public checkIn: boolean;
    public readonly baseSchoolId: (number | null);
    public constructor(id: number = getNO_ID(), name: string, drill_enabled: boolean = true, show_safe_unsafe: boolean = true, status: AlertStatusEnum = AlertStatusEnum.Inactive, svgMap: (RemoteFileRef | null) = null, jsonMap: (RemoteFileRef | null) = null, district: (ForeignKey<District> | null) = null, icon: (RemoteFileRef | null) = null, address: (string | null) = null, alertsEnabled: boolean = true, boardsEnabled: boolean = true, chatEnabled: boolean = true, mapsEnabled: boolean = true, checkIn: boolean = true, baseSchoolId: (number | null) = null) {
        this.id = id;
        this.name = name;
        this.drill_enabled = drill_enabled;
        this.show_safe_unsafe = show_safe_unsafe;
        this.status = status;
        this.svgMap = svgMap;
        this.jsonMap = jsonMap;
        this.district = district;
        this.icon = icon;
        this.address = address;
        this.alertsEnabled = alertsEnabled;
        this.boardsEnabled = boardsEnabled;
        this.chatEnabled = chatEnabled;
        this.mapsEnabled = mapsEnabled;
        this.checkIn = checkIn;
        this.baseSchoolId = baseSchoolId;
    }
    public static fromJson(obj: any): School { return new School(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["name"], [String]) as string,
        parseJsonTyped(obj["drill_enabled"], [Boolean]) as boolean,
        parseJsonTyped(obj["show_safe_unsafe"], [Boolean]) as boolean,
        parseJsonTyped(obj["status"], [AlertStatusEnum]) as AlertStatusEnum,
        parseJsonTyped(obj["svg"], [String]) as (string | null),
        parseJsonTyped(obj["json_map"], [String]) as (string | null),
        parseJsonTyped(obj["district"], [Number]) as (number | null),
        parseJsonTyped(obj["icon"], [String]) as (string | null),
        parseJsonTyped(obj["address"], [String]) as (string | null),
        parseJsonTyped(obj["alerts_enabled"], [Boolean]) as boolean,
        parseJsonTyped(obj["boards_enabled"], [Boolean]) as boolean,
        parseJsonTyped(obj["chat_enabled"], [Boolean]) as boolean,
        parseJsonTyped(obj["maps_enabled"], [Boolean]) as boolean,
        parseJsonTyped(obj["check_in"], [Boolean]) as boolean,
        parseJsonTyped(obj["baseuserschool_id"], [Number]) as (number | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        name: this.name,
        drill_enabled: this.drill_enabled,
        show_safe_unsafe: this.show_safe_unsafe,
        status: this.status,
        svg: this.svgMap,
        json_map: this.jsonMap,
        district: this.district,
        icon: this.icon,
        address: this.address,
        alerts_enabled: this.alertsEnabled,
        boards_enabled: this.boardsEnabled,
        chat_enabled: this.chatEnabled,
        maps_enabled: this.mapsEnabled,
        check_in: this.checkIn,
        baseuserschool_id: this.baseSchoolId
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.drill_enabled);
        hash = 31 * hash + hashAnything(this.show_safe_unsafe);
        hash = 31 * hash + hashAnything(this.status);
        hash = 31 * hash + hashAnything(this.svgMap);
        hash = 31 * hash + hashAnything(this.jsonMap);
        hash = 31 * hash + hashAnything(this.district);
        hash = 31 * hash + hashAnything(this.icon);
        hash = 31 * hash + hashAnything(this.address);
        hash = 31 * hash + hashAnything(this.alertsEnabled);
        hash = 31 * hash + hashAnything(this.boardsEnabled);
        hash = 31 * hash + hashAnything(this.chatEnabled);
        hash = 31 * hash + hashAnything(this.mapsEnabled);
        hash = 31 * hash + hashAnything(this.checkIn);
        hash = 31 * hash + hashAnything(this.baseSchoolId);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof School && safeEq(this.id, other.id) && safeEq(this.name, other.name) && safeEq(this.drill_enabled, other.drill_enabled) && safeEq(this.show_safe_unsafe, other.show_safe_unsafe) && safeEq(this.status, other.status) && safeEq(this.svgMap, other.svgMap) && safeEq(this.jsonMap, other.jsonMap) && safeEq(this.district, other.district) && safeEq(this.icon, other.icon) && safeEq(this.address, other.address) && safeEq(this.alertsEnabled, other.alertsEnabled) && safeEq(this.boardsEnabled, other.boardsEnabled) && safeEq(this.chatEnabled, other.chatEnabled) && safeEq(this.mapsEnabled, other.mapsEnabled) && safeEq(this.checkIn, other.checkIn) && safeEq(this.baseSchoolId, other.baseSchoolId) }
    public toString(): string { return `School(id=${this.id}, name=${this.name}, drill_enabled=${this.drill_enabled}, show_safe_unsafe=${this.show_safe_unsafe}, status=${this.status}, svgMap=${this.svgMap}, jsonMap=${this.jsonMap}, district=${this.district}, icon=${this.icon}, address=${this.address}, alertsEnabled=${this.alertsEnabled}, boardsEnabled=${this.boardsEnabled}, chatEnabled=${this.chatEnabled}, mapsEnabled=${this.mapsEnabled}, checkIn=${this.checkIn}, baseSchoolId=${this.baseSchoolId})` }
    public copy(id: number = this.id, name: string = this.name, drill_enabled: boolean = this.drill_enabled, show_safe_unsafe: boolean = this.show_safe_unsafe, status: AlertStatusEnum = this.status, svgMap: (RemoteFileRef | null) = this.svgMap, jsonMap: (RemoteFileRef | null) = this.jsonMap, district: (ForeignKey<District> | null) = this.district, icon: (RemoteFileRef | null) = this.icon, address: (string | null) = this.address, alertsEnabled: boolean = this.alertsEnabled, boardsEnabled: boolean = this.boardsEnabled, chatEnabled: boolean = this.chatEnabled, mapsEnabled: boolean = this.mapsEnabled, checkIn: boolean = this.checkIn, baseSchoolId: (number | null) = this.baseSchoolId): School { return new School(id, name, drill_enabled, show_safe_unsafe, status, svgMap, jsonMap, district, icon, address, alertsEnabled, boardsEnabled, chatEnabled, mapsEnabled, checkIn, baseSchoolId); }
    
    
}
export namespace School {
    //! Declares com.tresitgroup.android.tresit.model.School.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        public textSearch(query: string): ApiFilter<School> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<School>(`query=${query}`, undefined, (it: School): boolean => (it.toString().toLowerCase().indexOf(query.toLowerCase()) != -1));
        }
        
        public userBelongsTo(userId: ForeignKey<User>): ApiFilter<School> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<School>(`users=${userId}`, (it: MockDatabase, db: School): boolean => true, undefined);
        }
        
        public myUserBelongsTo(): ApiFilter<School> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<School>("", undefined, (it: School): boolean => true);
        }
        
        public byDistrict(districtId: ForeignKey<District>): ApiFilter<School> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<School>(`district=${districtId}`, undefined, (it: School): boolean => it.district === districtId);
        }
        
        public byCheckIn(): ApiFilter<School> {
            return new ApiFilter<School>("check_in=true");
        }
    }
}