// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/settings/SettingsVG.kt
// Package: com.tresitgroup.android.tresit.vg.settings
// Generated by Khrysalis - this file will be overwritten.
import { setViewText } from 'butterfly-web/dist/views/ViewWithText.ext'
import { EditUserVG } from './EditUserVG'
import { SchoolListVG } from './SchoolListVG'
import { ConversationDetailVG } from '../chat/ConversationDetailVG'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { GuidancesVG } from './GuidancesVG'
import { launchTestNotification } from '../../launchTestNotification'
import { User } from '../../model/User'
import { logVG } from '../../util/LogVG'
import { xViewOnClick, xViewOnLongClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { BuildConfig } from '../../BuildConfig'
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { AdminAlertRequestVg } from '../admin/AdminAlertRequestVg'
import { SettingsXml } from '../../layout/SettingsXml'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { AdminVG } from '../admin/AdminVG'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ManageAlertsVG } from './ManageAlertsVG'
import { GoogleMapEditVG } from '../map/GoogleMapEditVG'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { DialogWrapperVG } from '../DialogWrapperVG'
import { CheckInVG } from '../admin/CheckInVG'
import { LandingScreenVG } from '../auth/LandingScreenVG'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { AlertHistoryVG } from '../admin/AlertHistoryVG'
import { map as rxMap } from 'rxjs/operators'
import { School } from '../../model/School'
import { DefaultLocationsListVG } from './DefaultLocationsListVG'
import { xSessionApiLogOut } from '../../model/Session.api'

//! Declares com.tresitgroup.android.tresit.vg.settings.SettingsVG
export class SettingsVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly sessionStack: ObservableStack<ViewGenerator>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly topStack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, sessionStack: ObservableStack<ViewGenerator>, stack: ObservableStack<ViewGenerator>, topStack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.sessionStack = sessionStack;
        this.stack = stack;
        this.topStack = topStack;
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<School>>(this.session.allSchools.getListSimple(undefined, undefined, undefined, undefined, undefined), undefined, (_0: Array<School>): void => {}));
        this.displayAdminStuff = xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionAdmin ?? null) === true || (it?.isSuper ?? null) === true);
    }
    
    
    
    
    
    public readonly displayAdminStuff: ObservableProperty<boolean>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.settings.SettingsVG.title
    public get title(): string { return "Settings"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new SettingsXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Set Up xml.userName
        xTextViewBindString(xml.userName, xObservablePropertyMap<(User | null), string>(this.session.me, (it: (User | null)): string => (it?.name ?? null) ?? ""));
        
        //--- Set Up xml.userEmail
        xTextViewBindString(xml.userEmail, xObservablePropertyMap<(User | null), string>(this.session.me, (it: (User | null)): string => (it?.email ?? null) ?? ""));
        
        //--- Set Up xml.appVersion
        setViewText(xml.appVersion, BuildConfig.VERSION_NAME);
        
        //--- Set Up xml.admin
        xViewBindExists(xml.admin, this.displayAdminStuff);
        xViewOnClick(xml.admin, undefined, (): void => {
            this.adminClick();
        });
        
        //--- Set Up xml.editUser
        xViewOnClick(xml.editUser, undefined, (): void => {
            this.editUserClick();
        });
        xViewOnLongClick(xml.editUser, (): void => {
            ConversationDetailVG.Companion.INSTANCE.attachmentsEnabled.value = true;
        });
        
        //--- Set Up xml.guidances (overwritten on flow generation)
        xViewOnClick(xml.guidances, undefined, (): void => {
            this.guidancesClick();
        });
        
        //--- Set Up xml.alertHistory (overwritten on flow generation)
        xViewOnClick(xml.alertHistory, undefined, (): void => {
            this.alertHistoryClick();
        });
        
        const isAdmin = xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.permissionAdmin ?? null) === true);
        
        const temp5334 = xml.adminAlertHistory;
        if(temp5334 !== null) {
            xViewBindExists(temp5334, isAdmin)
        };
        const temp5335 = xml.adminAlertHistory;
        if(temp5335 !== null) {
            xViewOnClick(temp5335, undefined, (): void => {
                this.adminAlertHistoryClick();
            })
        };
        
        //--- Set Up xml.refresh
        xViewOnClick(xml.refresh, undefined, (): void => {
            this.refreshClick();
        });
        const checkinPermission = xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.allowSiteCheckIn ?? null) === true);
        
        xViewBindExists(xml.checkIn, checkinPermission);
        xViewOnClick(xml.checkIn, undefined, (): void => {
            this.dialog.push(new CheckInVG(this.session, this.topStack, this.dialog));
        });
        
        //--- Set Up xml.manageAlerts
        xViewOnClick(xml.manageAlerts, undefined, (): void => {
            this.manageAlertsClick();
        });
        xViewOnLongClick(xml.manageAlerts, (): void => {
            launchTestNotification();
        });
        
        //--- Set Up xml.manageDefaultLocations
        xViewBindExists(xml.manageDefaultLocations, xObservablePropertyMap<(User | null), boolean>(this.session.me, (it: (User | null)): boolean => (it?.localUser ?? null) === true));
        xViewOnClick(xml.manageDefaultLocations, undefined, (): void => {
            this.manageDefaultLocationsClick();
        });
        
        //--- Set Up xml.editGoogleMap
        xViewBindExists(xml.editGoogleMap, xObservablePropertyCombine<boolean, boolean, boolean>(this.displayAdminStuff, xObservablePropertyMap<(School | null), boolean>(this.schoolFilter, (it: (School | null)): boolean => it !== null), (a: boolean, b: boolean): boolean => a && b));
        xViewOnClick(xml.editGoogleMap, undefined, (): void => {
            this.editGoogleMapClick();
        });
        
        //--- Set Up xml.logOut (overwritten on flow generation)
        xViewOnClick(xml.logOut, undefined, (): void => {
            this.logOutClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    public adminClick(): void {
        this.topStack.push(new AdminVG(this.dialog, this.session, this.sessionStack, this.topStack));
    }
    public editUserClick(): void {
        this.dialog.push(new DialogWrapperVG(new EditUserVG(this.session), this.dialog));
    }
    
    public guidancesClick(): void {
        this.stack.push(new GuidancesVG(this.schoolFilter, this.session, this.stack));
    }
    public alertHistoryClick(): void {
        this.stack.push(new AlertHistoryVG(this.schoolFilter, this.session, this.stack, this.dialog));
    }
    
    public adminAlertHistoryClick(): void {
        this.stack.push(new AdminAlertRequestVg(this.schoolFilter, this.session));
    }
    
    
    public refreshClick(): void {
        this.session.forceRefresh();
    }
    
    public manageAlertsClick(): void {
        xSingleCallDisplayingError<(School | null)>(this.session.schools.getListSimple(undefined, undefined, undefined, undefined, undefined).pipe(rxMap((it: Array<School>): (School | null) => (it.length == 1 ? it[0] : null))), undefined, (it: (School | null)): void => {
            const it_5341 = it;
            if (it_5341 !== null) {
                this.dialog.push(new DialogWrapperVG(new ManageAlertsVG(it_5341.id, this.session), this.dialog));
            } else {
                this.dialog.push(new DialogWrapperVG(new SchoolListVG(this.session, this.dialog), this.dialog));
            }
        });
    }
    
    public manageDefaultLocationsClick(): void {
        this.dialog.push(new DialogWrapperVG(new DefaultLocationsListVG(this.session, this.dialog, this.dialog), this.dialog));
    }
    public editGoogleMapClick(): void {
        const it_5342 = (this.schoolFilter.value?.id ?? null);
        if (it_5342 !== null) {
            this.sessionStack.push(new GoogleMapEditVG(this.stack, this.dialog, it_5342, this.session));
        }
    }
    public logOutClick(): void {
        xSessionApiLogOut(this.session);
        this.root.reset(new LandingScreenVG(this.dialog, this.root, this.root));
    }
    
    //--- Body End
}

