// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/HasId.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { safeEq } from 'butterfly-web/dist/kotlin/Language'
import { ApiFilter } from '../api/ApiFilter'
import { ForeignKey } from './ForeignKey'

//! Declares com.tresitgroup.android.tresit.model.HasId
export interface HasId {
    
    id: number;
    
    
}

export namespace HasId {
    //! Declares com.tresitgroup.android.tresit.model.HasId.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        inIds<T extends HasId>(ids: Array<ForeignKey<T>>): ApiFilter<T> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<T>(`ids=${ids.join(",")}`, undefined, (it: T): boolean => ids.some((x) => safeEq(it.id, x)));
        }
    }
}
//! Declares com.tresitgroup.android.tresit.model.NO_ID
export const _NO_ID: number = (-1);
export function getNO_ID(): number { return _NO_ID; }
