// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/alert/SelectLocationVG.kt
// Package: com.tresitgroup.android.tresit.vg.alert
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { Floor } from '../../model/Floor'
import { flatMap as rxFlatMap, tap as rxTap } from 'rxjs/operators'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { xSessionApiOffsiteForSchool } from '../map/offsite'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { OffsiteResponse } from '../../model/OffsiteResponse'
import { ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { logVG } from '../../util/LogVG'
import { User } from '../../model/User'
import { ComponentRowLocationXml } from '../../layout/ComponentRowLocationXml'
import { Alert } from '../../model/Alert'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { Room } from '../../model/Room'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { xObservablePropertyFlatMapNotNull } from 'butterfly-web/dist/observables/FlatMappedObservableProperty'
import { MapVG } from '../map/MapVG'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { Building } from '../../model/Building'
import { listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { SelectLocationXml } from '../../layout/SelectLocationXml'
import { SelectStatusVG } from './SelectStatusVG'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ObservableInput } from 'rxjs'
import { CacheNode } from '../../api/CacheNode'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { xListCombined } from 'butterfly-web/dist/observables/CombineManyObservableProperty'

//! Declares com.tresitgroup.android.tresit.vg.alert.SelectLocationVG
export class SelectLocationVG extends ViewGenerator {
    public readonly alertId: ForeignKey<Alert>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(alertId: ForeignKey<Alert>, dialog: ObservableStack<ViewGenerator>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.alertId = alertId;
        this.dialog = dialog;
        this.session = session;
        this.stack = stack;
        this.alert = this.session.alerts.observable(this.alertId);
        this.selecting = new StandardObservableProperty<boolean>(false, undefined);
    }
    
    
    //--- Provides onDeviceTap
    //--- Provides onRoomTap
    //--- Provides schoolId
    
    //! Declares com.tresitgroup.android.tresit.vg.alert.SelectLocationVG.title
    public get title(): string { return "Select Location"; }
    
    
    public readonly alert: CacheNode<Alert>;
    
    public readonly selecting: StandardObservableProperty<boolean>;
    
    public favoriteLocations(): ObservableProperty<(Array<(Room | null)> | null)> {
        return xObservablePropertyFlatMapNotNull<UserSchoolMembership, Array<(Room | null)>>(xObservablePropertyMap<(Array<UserSchoolMembership> | null), (UserSchoolMembership | null)>(xObservablePropertyFlatMapNotNull<number, Array<UserSchoolMembership>>(xObservablePropertyMap<(Alert | null), (number | null)>(this.alert, (it: (Alert | null)): (number | null) => (it?.school ?? null)), (schoolId: number): ObservableProperty<(Array<UserSchoolMembership> | null)> => this.session.userSchoolMembership.observableListSimple(ApiFilter.Companion.INSTANCE.all<UserSchoolMembership>(UserSchoolMembership.Companion.INSTANCE.forSchool(schoolId), UserSchoolMembership.Companion.INSTANCE.forUser(this.session.session.userId)), undefined, undefined, undefined, undefined, undefined)), (it: (Array<UserSchoolMembership> | null)): (UserSchoolMembership | null) => ((): (UserSchoolMembership | null) => {
            const temp4091 = it;
            if(temp4091 === null) { return null }
            return (temp4091[0] ?? null)
        })()), (membership: UserSchoolMembership): ObservableProperty<(Array<(Room | null)> | null)> => xObservablePropertyMap<Array<(Room | null)>, (Array<(Room | null)> | null)>(xListCombined<(Room | null)>(listFilterNotNull([membership.defaultLocation1, membership.defaultLocation2, membership.defaultLocation3, membership.defaultLocation4, membership.defaultLocation5]).map((it: number): CacheNode<Room> => this.session.rooms.observable(it))), (it: Array<(Room | null)>): (Array<(Room | null)> | null) => it));
    }
    
    public generate(dependency: Window): HTMLElement {
        const xml = new SelectLocationXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const favoriteLocations = this.favoriteLocations();
        
        
        //--- Set Up xml.optionsLoading
        xViewFlipperBindLoading(xml.optionsLoading, xObservablePropertyCombine<boolean, (Array<(Room | null)> | null), boolean>(this.selecting, favoriteLocations, (a: boolean, b: (Array<(Room | null)> | null)): boolean => a || b === null), undefined);
        
        //--- Set Up xml.options
        xRecyclerViewBind<(Room | null)>(xml.options, xObservablePropertyMap<(Array<(Room | null)> | null), Array<(Room | null)>>(favoriteLocations, (it: (Array<(Room | null)> | null)): Array<(Room | null)> => it ?? []), null, (observable: ObservableProperty<(Room | null)>): HTMLElement => {
            //--- Make Subview For xml.options (overwritten on flow generation)
            const cellXml = new ComponentRowLocationXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Properties
            const roomName = xObservablePropertyMap<(Room | null), string>(observable, (it: (Room | null)): string => (it?.name ?? null) ?? "Default");
            
            const floor = xObservablePropertyFlatMapNotNull<number, Floor>(xObservablePropertyMap<(Room | null), (number | null)>(observable, (it: (Room | null)): (number | null) => (it?.floor ?? null)), (it: number): ObservableProperty<(Floor | null)> => this.session.floors.observable(it));
            
            const floorName = xObservablePropertyMap<(Floor | null), string>(floor, (it: (Floor | null)): string => (it?.name ?? null) ?? "Default");
            
            const buildingName = xObservablePropertyMap<(Building | null), string>(xObservablePropertyFlatMapNotNull<number, Building>(xObservablePropertyMap<(Floor | null), (number | null)>(floor, (it: (Floor | null)): (number | null) => (it?.building ?? null)), (it: number): ObservableProperty<(Building | null)> => this.session.buildings.observable(it)), (it: (Building | null)): string => (it?.name ?? null) ?? "Default");
            
            const names = xObservablePropertyMap<Array<string>, Array<string>>(xListCombined<string>([roomName, floorName, buildingName]), (it: Array<string>): Array<string> => it.filter((it: string): boolean => !(it === "Default")));
            
            
            //--- Set Up cellXml.name
            xTextViewBindString(cellXml.name, xObservablePropertyMap<Array<string>, string>(names, (it: Array<string>): string => (it[0] ?? null) ?? ""));
            
            //--- Set Up cellXml.floor
            xTextViewBindString(cellXml.floor, xObservablePropertyMap<Array<string>, string>(names, (it: Array<string>): string => (it[1] ?? null) ?? ""));
            
            //--- Clickem up
            xViewOnClick(cellXml.xmlRoot, undefined, (): void => {
                const it_4097 = observable.value;
                if (it_4097 !== null) {
                    this.optionsClick(it_4097.id);
                }
            });
            //--- End Make Subview For xml.options
            return cellView;
        });
        
        //--- Set Up xml.map
        xViewOnClick(xml.map, undefined, (): void => {
            this.mapClick();
        });
        
        //--- Set Up xml.offsite (overwritten on flow generation)
        xViewOnClick(xml.offsite, undefined, (): void => {
            this.offsiteClick();
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public optionsClick(room: ForeignKey<Room>): void {
        xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.setLocation(room)]), this.selecting), undefined, (it: User): void => {
            this.stack.reset(new SelectStatusVG(this.dialog, this.session, this.alertId));
        });
    }
    
    public mapClick(): void {
        const schoolId = (this.alert.value?.school ?? null)
        if(schoolId === null) { return }
        this.stack.push(new MapVG(this.stack, this.dialog, (it: number): void => {}, (room: number): void => {
            this.optionsClick(room);
        }, schoolId, this.session));
    }
    
    public offsiteClick(): void {
        const schoolId_4098 = (this.alert.value?.school ?? null);
        if (schoolId_4098 !== null) {
            xSingleCallDisplayingError<User>(xSingleWorking<User>(xSessionApiOffsiteForSchool(this.session, schoolId_4098).pipe(rxFlatMap((it: Room): ObservableInput<User> => this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.setLocation(it.id)]))).pipe(rxTap(undefined, (it: any): void => {
                                this.session.rawApi.createOffsite(this.session.session, schoolId_4098.toString()).subscribe((it: OffsiteResponse): void => {
                                        xSingleCallDisplayingError<User>(xSingleWorking<User>(this.session.users.patch(this.session.session.userId, [User.Companion.INSTANCE.setLocation(it.room_id)]), this.selecting), undefined, (it: User): void => {
                                            this.stack.reset(new SelectStatusVG(this.dialog, this.session, this.alertId));
                                        });
                                    }, (it: any): void => {
                                        showDialogAlert(new ViewStringResource(R._string.something_went_wrong));
                                });
                })), this.selecting), undefined, (it: User): void => {
                    this.stack.reset(new SelectStatusVG(this.dialog, this.session, this.alertId));
            });
        }
    }
    
    //--- Body End
}
