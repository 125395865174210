//
// UserActivityHistoryXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './user_activity_history.html'
//! Declares com.tresitgroup.android.tresit.layouts.UserActivityHistoryXml
export class UserActivityHistoryXml {
    xmlRoot!: HTMLElement
    items!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.items = getViewById<HTMLDivElement>(view, "items")
        return view
    }
}
