//! Declares com.tresitgroup.android.tresit.openSsoWindow
export function xActivityAccessOpenSsoWindow(window: Window, ssoURL: string, onJwt: (jwt: string) => void) {
    let ssoResponseUri = /\/oauth-success\/([A-Za-z0-9+=\-\._]+)\/?/
    //We use rel=opener for modern browsers to ensure we can access it
    let ssoWinMaybe = window.open(
        ssoURL,
        'DIR-S Login',
        'rel=opener,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,height=480,width=640'
    )
    if(ssoWinMaybe === null){
        window.location.href = ssoURL.replace("%2Fclever", "%2Fclever%2Fwebalt")
        return
    }

    let ssoWin = ssoWinMaybe!

    let ssoInterval = window.setInterval(function() {
        try {
            if (
                ssoWin &&
                ssoWin.location &&
                ssoWin.location.pathname &&
                ssoWin.location.pathname.match &&
                ssoWin.location.pathname.match(ssoResponseUri)
            ) {
                let matches = ssoResponseUri.exec(ssoWin.location.pathname)
                if (matches && matches.length) {
                    let token = matches[1]
                    onJwt(token)
                    ssoWin.close()
                }
            }
        } catch (e) {
            // pass
        }
    }, 250)

    function handleOAuthSuccess(event: MessageEvent) {
        console.log(`Got event ${event}`)
        try {
            let eventData: { type: string, token: string } = JSON.parse(event.data)
            if (eventData.type == 'oauth-success') {
                onJwt(eventData.token)
                window.removeEventListener('message', handleOAuthSuccess)
                ssoWin.close()
            }
        } catch (e) {
            // pass
        }
    }
    console.log("Opening SSO window...")
    ssoWin.onclose = function() {
        clearInterval(ssoInterval)
        window.removeEventListener('message', handleOAuthSuccess)
        console.log("Finished listening for SSO.")
    }
    window.addEventListener('message', handleOAuthSuccess)
}

//! Declares com.tresitgroup.android.tresit.requestFocus
export function xActivityAccessRequestFocus(window: any) {
    console.log("Requesting focus: ", window && window.showWindowAlarm)
    if (window && window.showWindowAlarm) {
        window.showWindowAlarm()
    }
}

//! Declares com.tresitgroup.android.tresit.onDeadBuild
export function onDeadBuild() {
    window.location.reload()
}