// File: api/localApiLocation.kt
// Package: com.tresitgroup.android.tresit.api

//! Declares com.tresitgroup.android.tresit.api.localApiLocationRest
export function getLocalApiLocationRest(): string { return process.env.API_URL ?? "http://localhost:8000/" }

//! Declares com.tresitgroup.android.tresit.api.localApiLocationSocket
export function getLocalApiLocationSocket(): string { return process.env.WEBSOCKET_URL ?? "ws://localhost:8000/api/v2/ws/?token=" }

//! Declares com.tresitgroup.android.tresit.api.defaultApiOptionUrl
export function getDefaultApiOptionUrl(): string {
    return window.location.host
}