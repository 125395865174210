// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/map/Geometry.kt
// Package: com.tresitgroup.android.tresit.map
// Generated by Khrysalis - this file will be overwritten.
import { Projector } from './Projector'
import { NumberRange, Range, hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { GeoCoordinate } from 'butterfly-web/dist/location/GeoCoordinate'
import { iterCount } from 'butterfly-web/dist/kotlin/Collections'
import { cMax } from 'butterfly-web/dist/kotlin/Comparable'

//! Declares com.tresitgroup.android.tresit.map.GeoCoordinateBounds
export class GeoCoordinateBounds {
    public readonly southwest: GeoCoordinate;
    public readonly northeast: GeoCoordinate;
    public constructor(southwest: GeoCoordinate, northeast: GeoCoordinate) {
        this.southwest = southwest;
        this.northeast = northeast;
        this.width = this.northeast.longitude - this.southwest.longitude;
        this.height = this.northeast.latitude - this.southwest.latitude;
    }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.southwest);
        hash = 31 * hash + hashAnything(this.northeast);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof GeoCoordinateBounds && safeEq(this.southwest, other.southwest) && safeEq(this.northeast, other.northeast) }
    public toString(): string { return `GeoCoordinateBounds(southwest=${this.southwest}, northeast=${this.northeast})` }
    public copy(southwest: GeoCoordinate = this.southwest, northeast: GeoCoordinate = this.northeast): GeoCoordinateBounds { return new GeoCoordinateBounds(southwest, northeast); }
    
    //! Declares com.tresitgroup.android.tresit.map.GeoCoordinateBounds.center
    public get center(): GeoCoordinate { return new GeoCoordinate((this.southwest.latitude + this.northeast.latitude) / 2, (this.southwest.longitude + this.northeast.longitude) / 2); }
    
    
    public readonly width: number;
    
    public readonly height: number;
    
    
    public containsProjected(projector: Projector, x: number, y: number): boolean {
        const xa = projector.convertToX(this.southwest);
        
        const xb = projector.convertToX(this.northeast);
        
        const ya = projector.convertToY(this.southwest);
        
        const yb = projector.convertToY(this.northeast);
        
        return new Range(Math.min(xa, xb), Math.max(xa, xb)).contains(x) && new Range(Math.min(ya, yb), Math.max(ya, yb)).contains(y);
    }
    
    public contains(coordinate: GeoCoordinate): boolean {
        return new Range(Math.min(this.southwest.latitude, this.northeast.latitude), Math.max(this.southwest.latitude, this.northeast.latitude)).contains(coordinate.latitude) && new Range(Math.min(this.southwest.longitude, this.northeast.longitude), Math.max(this.southwest.longitude, this.northeast.longitude)).contains(coordinate.longitude);
    }
    
    public takesUp(other: GeoCoordinateBounds): number {
        const latOverlap = cMax((Math.min(this.northeast.latitude, other.northeast.latitude) - Math.max(this.southwest.latitude, other.southwest.latitude)), 0.0);
        
        const lonOverlap = cMax((Math.min(this.northeast.longitude, other.northeast.longitude) - Math.max(this.southwest.longitude, other.southwest.longitude)), 0.0);
        
        const otherLatSize = cMax((other.northeast.latitude - other.southwest.latitude), .000001);
        
        const otherLonSize = cMax((other.northeast.longitude - other.southwest.longitude), .000001);
        
        
        return latOverlap / otherLatSize + lonOverlap / otherLonSize;
    }
    
    public intersects(other: GeoCoordinateBounds): boolean {
        return this.southwest.longitude < other.northeast.longitude &&
        this.northeast.longitude > other.southwest.longitude &&
        this.southwest.latitude < other.northeast.latitude &&
        this.northeast.latitude > other.southwest.latitude;
    }
    
    public points(): Array<GeoCoordinate> {
        return [this.southwest, new GeoCoordinate(this.southwest.latitude, this.northeast.longitude), this.northeast, new GeoCoordinate(this.northeast.latitude, this.southwest.longitude)];
    }
}

//! Declares com.tresitgroup.android.tresit.map.polygonContains>kotlin.collections.List<com.lightningkite.butterfly.location.GeoCoordinate>
export function xListPolygonContains(this_: Array<GeoCoordinate>, projector: Projector, coordinate: GeoCoordinate): boolean {
    const x = projector.convertToX(coordinate);
    
    const y = projector.convertToY(coordinate);
    
    const polyX = this_.map( (it: GeoCoordinate): number => projector.convertToX(it));
    
    const polyY = this_.map( (it: GeoCoordinate): number => projector.convertToY(it));
    
    const count = polyX.length;
    
    const intersections = iterCount(new NumberRange(0, polyX.length-1), (it: number): boolean => rayIntersectsLine(x, y, x + 100, y, polyX[it], polyY[it], polyX[(it + 1) % count], polyY[(it + 1) % count]));
    
    return intersections % 2 === 1;
}



function rayIntersectsLine(rayX: number, rayY: number, rayToX: number, rayToY: number, lineX1: number, lineY1: number, lineX2: number, lineY2: number): boolean {
    const denom: number = (rayToY - rayY) * (lineX2 - lineX1) - (rayToX - rayX) * (lineY2 - lineY1);
    
    if (denom === 0) { return false }
    const lineRatio: number = ((rayToX - rayX) * (lineY1 - rayY) - (rayToY - rayY) * (lineX1 - rayX)) / denom;
    
    const rayRatio: number = ((lineX2 - lineX1) * (lineY1 - rayY) - (lineY2 - lineY1) * (lineX1 - rayX)) / denom;
    
    return 0.0 <= lineRatio && lineRatio <= 1.0 && rayRatio > 0;
    
}