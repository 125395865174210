// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/CheckInVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { SessionApi } from '../../api/SessionApi'
import { xDisposableForever } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { LayoutSiteCheckInXml } from '../../layout/LayoutSiteCheckInXml'
import { xCompoundButtonBind } from 'butterfly-web/dist/observables/binding/CompoundButton.binding'
import { xObservablePropertyWithWrite } from 'butterfly-web/dist/observables/WriteAddedObservableProperty'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { UserSchoolMembership } from '../../model/UserSchoolMembership'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ApiOption } from '../../api/ApiOption'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { ComponentRowAlertCheckboxXml } from '../../layout/ComponentRowAlertCheckboxXml'
import { ApiModifier } from '../../api/ApiModifier'
import { SessionVG } from '../SessionVG'
import { School } from '../../model/School'
import { xRecyclerViewBind } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'

//! Declares com.tresitgroup.android.tresit.vg.admin.CheckInVG
export class CheckInVG extends ViewGenerator {
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public constructor(session: SessionApi, stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>) {
        super();
        this.session = session;
        this.stack = stack;
        this.dialog = dialog;
        this.schoolFilter = new StandardObservableProperty<Array<School>>([], undefined);
        this.loading = new StandardObservableProperty<boolean>(true, undefined);
        xDisposableForever<SubscriptionLike>(xSingleCallDisplayingError<Array<School>>(this.session.allSchools.getListSimple(undefined, undefined, undefined, undefined, undefined), undefined, (schoolList: Array<School>): void => {
            this.schoolFilter.value = schoolList;
            this.loading.value = false;
        }));
    }
    
    public readonly schoolFilter: StandardObservableProperty<Array<School>>;
    
    
    public loading: MutableObservableProperty<boolean>;
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LayoutSiteCheckInXml();
        
        const view = xml.setup(dependency);
        
        xViewFlipperBindLoading(xml.fetchingSchools, this.loading, undefined);
        
        xRecyclerViewBind<School>(xml.schoolList, this.schoolFilter, new School(undefined, "School", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<School>): HTMLElement => {
            const cellXml = new ComponentRowAlertCheckboxXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            xTextViewBindString(cellXml.isChecked, xObservablePropertyMap<School, string>(observable, (it: School): string => it.name));
            
            xCompoundButtonBind(cellXml.isChecked, xObservablePropertyWithWrite<boolean>(xObservablePropertyMap<School, boolean>(observable, (it: School): boolean => it.checkIn), (valueToWrite: boolean): void => {
                this.loading.value = true;
                console.error(`${"ManageAlert:"}: ${valueToWrite.toString()}`);
                const target = observable.value;
                
                const it_3723 = ((): (number | null) => {
                    const temp3724 = target.baseSchoolId;
                    if(temp3724 === null) { return null }
                    return temp3724
                })();
                if (it_3723 !== null) {
                    xSingleCallDisplayingError<UserSchoolMembership>(this.session.userSchoolMembership.patch(it_3723, [ApiModifier.constructorStringAnyFunction1HasIdHasId<UserSchoolMembership>("check_in", valueToWrite, (it: UserSchoolMembership): UserSchoolMembership => it)]), undefined, (it: UserSchoolMembership): void => {
                        this.loading.value = false;
                        target.checkIn = valueToWrite;
                    });
                }
            }));
            
            return cellView;
        });
        
        xViewOnClick(xml.ivClose, undefined, (): void => {
            this.session.forceRefresh();
            this.dialog.dismiss();
            const newSessionVg = new SessionVG(this.stack, this.stack, new SessionApi(this.session.session, ApiOption.Companion.INSTANCE.current, this.stack, this.dialog), this.stack);
            
            this.stack.reset(newSessionVg);
        });
        
        return view;
    }
    
}
