// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/SoftDeletable.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { HasId } from './HasId'
import { ApiFilter } from '../api/ApiFilter'

//! Declares com.tresitgroup.android.tresit.model.SoftDeletable
export interface SoftDeletable extends HasId {
    
    deleted: (Date | null);
    
    
    readonly isActive: boolean;
    
    
    
}
export namespace SoftDeletableDefaults {
    export function getIsActive(this_: SoftDeletable): boolean { return this_.deleted === null; }
}
export namespace SoftDeletable {
    //! Declares com.tresitgroup.android.tresit.model.SoftDeletable.Companion
    export class Companion {
        private constructor() {
        }
        public static INSTANCE = new Companion();
        
        isActive<T extends SoftDeletable>(): ApiFilter<T> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<T>("deleted=false", undefined, (it: T): boolean => it.isActive);
        }
    }
}