// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/map/MapVG.kt
// Package: com.tresitgroup.android.tresit.vg.map
// Generated by Khrysalis - this file will be overwritten.
import { xSwapViewBindStack } from 'butterfly-web/dist/observables/binding/SwapView.binding'
import { SessionApi } from '../../api/SessionApi'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertySubscribeBy } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { ForeignKey } from '../../model/ForeignKey'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { MapXml } from '../../layout/MapXml'
import { logVG } from '../../util/LogVG'
import { GoogleMapVG } from './GoogleMapVG'
import { CampusMapSvgVG } from './CampusMapSvgVG'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { HasBackAction } from 'butterfly-web/dist/views/HasBackAction'
import { Room } from '../../model/Room'
import { Device } from '../../model/Device'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.map.MapVG
export class MapVG extends ViewGenerator implements HasBackAction {
    public static implementsInterfaceComLightningkiteButterflyViewsHasBackAction = true;
    public readonly stack: ObservableStack<ViewGenerator>;
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly onDeviceTap:  ((a: ForeignKey<Device>) => void);
    public readonly onRoomTap:  ((a: ForeignKey<Room>) => void);
    public readonly schoolId: ForeignKey<School>;
    public readonly session: SessionApi;
    public constructor(stack: ObservableStack<ViewGenerator>, dialog: ObservableStack<ViewGenerator>, onDeviceTap:  ((a: ForeignKey<Device>) => void), onRoomTap:  ((a: ForeignKey<Room>) => void), schoolId: ForeignKey<School>, session: SessionApi) {
        super();
        this.stack = stack;
        this.dialog = dialog;
        this.onDeviceTap = onDeviceTap;
        this.onRoomTap = onRoomTap;
        this.schoolId = schoolId;
        this.session = session;
        this.mapStack = new ObservableStack<ViewGenerator>();
        this.map = xObservablePropertyMap<(School | null), (MapVG.MapToShow | null)>(this.session.schools.observable(this.schoolId), (it: (School | null)): (MapVG.MapToShow | null) => ((): (MapVG.MapToShow | null) => {
                    const temp4887 = (it?.jsonMap ?? null);
                    if(temp4887 === null) { return null }
                    return ((it: string): MapVG.MapToShow => new MapVG.MapToShow(undefined, it))(temp4887)
            })() ?? ((): (MapVG.MapToShow | null) => {
                const temp4889 = (it?.svgMap ?? null);
                if(temp4889 === null) { return null }
                return ((it: string): MapVG.MapToShow => new MapVG.MapToShow(it, undefined))(temp4889)
        })());
    }
    
    
    public readonly mapStack: ObservableStack<ViewGenerator>;
    
    
    public readonly map: ObservableProperty<(MapVG.MapToShow | null)>;
    
    
    //! Declares com.tresitgroup.android.tresit.vg.map.MapVG.title
    public get title(): string { return "Map"; }
    
    
    public onBackPressed(): boolean {
        return this.mapStack.backPressPop();
    }
    
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new MapXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Stack binding
        xDisposableUntil<SubscriptionLike>(xObservablePropertySubscribeBy<(MapVG.MapToShow | null)>(this.map, undefined, undefined, (it: (MapVG.MapToShow | null)): void => {
            this.mapStack.reset(((): (ViewGenerator | null) => {
                const temp4891 = it;
                if(temp4891 === null) { return null }
                return ((mapToShow: MapVG.MapToShow): ViewGenerator => ((): (GoogleMapVG | null) => {
                            const temp4893 = mapToShow.jsonMap;
                            if(temp4893 === null) { return null }
                            return ((it: string): GoogleMapVG => new GoogleMapVG(this.stack, this.dialog, it, this.onDeviceTap, this.onRoomTap, this.schoolId, this.session))(temp4893)
                    })() ?? ((): (CampusMapSvgVG | null) => {
                        const temp4895 = mapToShow.svgMap;
                        if(temp4895 === null) { return null }
                        return ((it: string): CampusMapSvgVG => new CampusMapSvgVG(it, this.onRoomTap, this.schoolId, this.session, this.mapStack))(temp4895)
                })() ?? new ViewGenerator.Default())(temp4891)
            })() ?? new ViewGenerator.Default());
        }), xViewRemovedGet(view));
        
        //--- Set Up xml.content (overwritten on flow generation)
        xSwapViewBindStack<ViewGenerator>(xml.content, dependency, this.mapStack);
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    
    //--- Body End
}
export namespace MapVG {
    //! Declares com.tresitgroup.android.tresit.vg.map.MapVG.MapToShow
    export class MapToShow {
        public readonly svgMap: (string | null);
        public readonly jsonMap: (string | null);
        public constructor(svgMap: (string | null) = null, jsonMap: (string | null) = null) {
            this.svgMap = svgMap;
            this.jsonMap = jsonMap;
        }
        public hashCode(): number {
            let hash = 17;
            hash = 31 * hash + hashAnything(this.svgMap);
            hash = 31 * hash + hashAnything(this.jsonMap);
            return hash;
        }
        public equals(other: any): boolean { return other instanceof MapToShow && safeEq(this.svgMap, other.svgMap) && safeEq(this.jsonMap, other.jsonMap) }
        public toString(): string { return `MapToShow(svgMap=${this.svgMap}, jsonMap=${this.jsonMap})` }
        public copy(svgMap: (string | null) = this.svgMap, jsonMap: (string | null) = this.jsonMap): MapToShow { return new MapToShow(svgMap, jsonMap); }
    }
}
