//
// LayoutAdminApproveDialogXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './layout_admin_approve_dialog.html'
//! Declares com.tresitgroup.android.tresit.layouts.LayoutAdminApproveDialogXml
export class LayoutAdminApproveDialogXml {
    xmlRoot!: HTMLElement
    ivAlert!: HTMLImageElement
    tvAlertType!: HTMLElement
    description!: HTMLElement
    tvSchoolName!: HTMLElement
    tvAlertCreatedBy!: HTMLElement
    cellPhone!: HTMLElement
    ivCall!: HTMLImageElement
    ivMessage!: HTMLImageElement
    progress!: HTMLProgressElement
    btnApprove!: HTMLButtonElement
    btnDecline!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.ivAlert = getViewById<HTMLImageElement>(view, "ivAlert")
        this.tvAlertType = getViewById<HTMLElement>(view, "tvAlertType")
        this.description = getViewById<HTMLElement>(view, "description")
        this.tvSchoolName = getViewById<HTMLElement>(view, "tvSchoolName")
        this.tvAlertCreatedBy = getViewById<HTMLElement>(view, "tvAlertCreatedBy")
        this.cellPhone = getViewById<HTMLElement>(view, "cellPhone")
        this.ivCall = getViewById<HTMLImageElement>(view, "ivCall")
        this.ivMessage = getViewById<HTMLImageElement>(view, "ivMessage")
        this.progress = getViewById<HTMLProgressElement>(view, "progress")
        this.btnApprove = getViewById<HTMLButtonElement>(view, "btnApprove")
        this.btnDecline = getViewById<HTMLButtonElement>(view, "btnDecline")
        return view
    }
}
