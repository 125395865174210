// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/model/ChatThreadDetailed.kt
// Package: com.tresitgroup.android.tresit.model
// Generated by Khrysalis - this file will be overwritten.
import { School } from './School'
import { SoftDeletable, SoftDeletableDefaults } from './SoftDeletable'
import { hashAnything, safeEq } from 'butterfly-web/dist/Kotlin'
import { RemoteFileRef } from './RemoteFileRef'
import { Time } from '../util/time'
import { User } from './User'
import { ForeignKey } from './ForeignKey'
import { ApiSort } from '../api/ApiSort'
import { MockDatabase } from '../api/MockDatabase'
import { Alert } from './Alert'
import { HasId, getNO_ID } from './HasId'
import { safeCompare } from 'butterfly-web/dist/kotlin/Comparable'
import { ApiModifier } from '../api/ApiModifier'
import { ApiFilter } from '../api/ApiFilter'
import { parse as parseJsonTyped } from 'butterfly-web/dist/net/jsonParsing'

//! Declares com.tresitgroup.android.tresit.model.ChatThreadDetailed
export class ChatThreadDetailed implements HasId, SoftDeletable {
    public static implementsInterfaceComTresitgroupAndroidTresitModelHasId = true;
    public static implementsInterfaceComLightningkiteButterflyCodable = true;
    public static implementsInterfaceComLightningkiteButterflyHashable = true;
    public static implementsInterfaceComTresitgroupAndroidTresitModelSoftDeletable = true;
    public id: number;
    public deleted: (Date | null);
    public readonly name: (string | null);
    public readonly created: Date;
    public readonly userIds: Array<ForeignKey<User>>;
    public readonly schools: Array<ForeignKey<School>>;
    public readonly associatedSchools: Array<ForeignKey<School>>;
    public readonly alert: (ForeignKey<Alert> | null);
    public readonly lastUpdated: (Date | null);
    public readonly userNames: Array<string>;
    public readonly schoolNames: Array<string>;
    public readonly alertTypeIcon: (RemoteFileRef | null);
    public constructor(id: number = getNO_ID(), deleted: (Date | null) = null, name: (string | null) = null, created: Date = Time.INSTANCE.date(), userIds: Array<ForeignKey<User>> = [], schools: Array<ForeignKey<School>> = [], associatedSchools: Array<ForeignKey<School>> = [], alert: (ForeignKey<Alert> | null) = null, lastUpdated: (Date | null) = null, userNames: Array<string> = [], schoolNames: Array<string> = [], alertTypeIcon: (RemoteFileRef | null) = null) {
        this.id = id;
        this.deleted = deleted;
        this.name = name;
        this.created = created;
        this.userIds = userIds;
        this.schools = schools;
        this.associatedSchools = associatedSchools;
        this.alert = alert;
        this.lastUpdated = lastUpdated;
        this.userNames = userNames;
        this.schoolNames = schoolNames;
        this.alertTypeIcon = alertTypeIcon;
    }
    public static fromJson(obj: any): ChatThreadDetailed { return new ChatThreadDetailed(
        parseJsonTyped(obj["id"], [Number]) as number,
        parseJsonTyped(obj["deleted"], [Date]) as (Date | null),
        parseJsonTyped(obj["name"], [String]) as (string | null),
        parseJsonTyped(obj["created"], [Date]) as Date,
        parseJsonTyped(obj["user_ids"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["schools"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["associated_schools"], [Array, [Number]]) as Array<number>,
        parseJsonTyped(obj["alert_id"], [Number]) as (number | null),
        parseJsonTyped(obj["last_update"], [Date]) as (Date | null),
        parseJsonTyped(obj["user_names"], [Array, [String]]) as Array<string>,
        parseJsonTyped(obj["school_names"], [Array, [String]]) as Array<string>,
        parseJsonTyped(obj["alert_type_icon"], [String]) as (string | null)
    ) }
    public toJSON(): object { return {
        id: this.id,
        deleted: this.deleted,
        name: this.name,
        created: this.created,
        user_ids: this.userIds,
        schools: this.schools,
        associated_schools: this.associatedSchools,
        alert_id: this.alert,
        last_update: this.lastUpdated,
        user_names: this.userNames,
        school_names: this.schoolNames,
        alert_type_icon: this.alertTypeIcon
    } }
    public hashCode(): number {
        let hash = 17;
        hash = 31 * hash + hashAnything(this.id);
        hash = 31 * hash + hashAnything(this.deleted);
        hash = 31 * hash + hashAnything(this.name);
        hash = 31 * hash + hashAnything(this.created);
        hash = 31 * hash + hashAnything(this.userIds);
        hash = 31 * hash + hashAnything(this.schools);
        hash = 31 * hash + hashAnything(this.associatedSchools);
        hash = 31 * hash + hashAnything(this.alert);
        hash = 31 * hash + hashAnything(this.lastUpdated);
        hash = 31 * hash + hashAnything(this.userNames);
        hash = 31 * hash + hashAnything(this.schoolNames);
        hash = 31 * hash + hashAnything(this.alertTypeIcon);
        return hash;
    }
    public equals(other: any): boolean { return other instanceof ChatThreadDetailed && safeEq(this.id, other.id) && safeEq(this.deleted, other.deleted) && safeEq(this.name, other.name) && safeEq(this.created, other.created) && safeEq(this.userIds, other.userIds) && safeEq(this.schools, other.schools) && safeEq(this.associatedSchools, other.associatedSchools) && safeEq(this.alert, other.alert) && safeEq(this.lastUpdated, other.lastUpdated) && safeEq(this.userNames, other.userNames) && safeEq(this.schoolNames, other.schoolNames) && safeEq(this.alertTypeIcon, other.alertTypeIcon) }
    public toString(): string { return `ChatThreadDetailed(id=${this.id}, deleted=${this.deleted}, name=${this.name}, created=${this.created}, userIds=${this.userIds}, schools=${this.schools}, associatedSchools=${this.associatedSchools}, alert=${this.alert}, lastUpdated=${this.lastUpdated}, userNames=${this.userNames}, schoolNames=${this.schoolNames}, alertTypeIcon=${this.alertTypeIcon})` }
    public copy(id: number = this.id, deleted: (Date | null) = this.deleted, name: (string | null) = this.name, created: Date = this.created, userIds: Array<ForeignKey<User>> = this.userIds, schools: Array<ForeignKey<School>> = this.schools, associatedSchools: Array<ForeignKey<School>> = this.associatedSchools, alert: (ForeignKey<Alert> | null) = this.alert, lastUpdated: (Date | null) = this.lastUpdated, userNames: Array<string> = this.userNames, schoolNames: Array<string> = this.schoolNames, alertTypeIcon: (RemoteFileRef | null) = this.alertTypeIcon): ChatThreadDetailed { return new ChatThreadDetailed(id, deleted, name, created, userIds, schools, associatedSchools, alert, lastUpdated, userNames, schoolNames, alertTypeIcon); }
    
    
    
    
    //! Declares com.tresitgroup.android.tresit.model.ChatThreadDetailed.threadType
    public get threadType(): ChatThreadDetailed.ThreadType { return ((): ChatThreadDetailed.ThreadType => {
        if (this.alert !== null) {
            return ChatThreadDetailed.ThreadType.Alert
        } else if (this.userIds.length === 2 && this.schools.length === 0 && this.name === null) {
            return ChatThreadDetailed.ThreadType.Contact
        } else  {
            return ChatThreadDetailed.ThreadType.Group
        }
    })(); }
    
    
    
    public get isActive(): boolean { return SoftDeletableDefaults.getIsActive(this); }
}
export namespace ChatThreadDetailed {
    //! Declares com.tresitgroup.android.tresit.model.ChatThreadDetailed.ThreadType
    export class ThreadType {
        private constructor(name: string, jsonName: string) {
            this.name = name;
            this.jsonName = jsonName;
        }
        
        public static Alert = new ThreadType("Alert", "Alert");
        public static Group = new ThreadType("Group", "Group");
        public static Contact = new ThreadType("Contact", "Contact");
        
        private static _values: Array<ThreadType> = [ThreadType.Alert, ThreadType.Group, ThreadType.Contact];
        public static values(): Array<ThreadType> { return ThreadType._values; }
        public readonly name: string;
        public readonly jsonName: string;
        public static valueOf(name: string): ThreadType { return (ThreadType as any)[name]; }
        public toString(): string { return this.name }
        public toJSON(): string { return this.jsonName }
    }
}
export namespace ChatThreadDetailed {
    //! Declares com.tresitgroup.android.tresit.model.ChatThreadDetailed.Companion
    export class Companion {
        private constructor() {
            this.mostRecent = ApiSort.constructorStringComparatorHasId<ChatThreadDetailed>("sort=-last_update", (left: ChatThreadDetailed, right: ChatThreadDetailed): number => {
                const leftLast = left.lastUpdated;
                
                const rightLast = right.lastUpdated;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(rightLast!, leftLast);
                        } else {
                            return (-1);
                        }
                    } else {
                        if (rightLast !== null) {
                            return 1;
                        } else {
                            return safeCompare(left.id, right.id);
                        }
                    }
                })()
            });
            this.mostRecentDown = ApiSort.constructorStringComparatorHasId<ChatThreadDetailed>("sort=last_update", (left: ChatThreadDetailed, right: ChatThreadDetailed): number => {
                const leftLast = left.lastUpdated;
                
                const rightLast = right.lastUpdated;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(leftLast!, rightLast);
                        } else {
                            return 1;
                        }
                    } else {
                        if (rightLast !== null) {
                            return (-1);
                        } else {
                            return (-safeCompare(left.id, right.id));
                        }
                    }
                })()
            });
            this.alphabetical = ApiSort.constructorStringComparatorHasId<ChatThreadDetailed>("sort=name", (left: ChatThreadDetailed, right: ChatThreadDetailed): number => {
                const leftLast = left.name;
                
                const rightLast = right.name;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(leftLast!, rightLast!);
                        } else {
                            return (-1);
                        }
                    } else {
                        if (rightLast !== null) {
                            return 1;
                        } else {
                            return safeCompare(left.id, right.id);
                        }
                    }
                })()
            });
            this.alphabeticalDown = ApiSort.constructorStringComparatorHasId<ChatThreadDetailed>("sort=-name", (left: ChatThreadDetailed, right: ChatThreadDetailed): number => {
                const leftLast = left.name;
                
                const rightLast = right.name;
                
                return ((): number => {
                    if (leftLast !== null) {
                        if (rightLast !== null) {
                            return safeCompare(rightLast!, leftLast!);
                        } else {
                            return 1;
                        }
                    } else {
                        if (rightLast !== null) {
                            return (-1);
                        } else {
                            return safeCompare(right.id, left.id);
                        }
                    }
                })()
            });
        }
        public static INSTANCE = new Companion();
        
        public textSearch(query: string): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>(`query=${query}`, (db: MockDatabase, hist: ChatThreadDetailed): boolean => true, undefined);
        }
        
        public setUsers(userIds: Array<ForeignKey<User>>): ApiModifier<ChatThreadDetailed> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<ChatThreadDetailed>("user_ids", userIds, (it: ChatThreadDetailed): ChatThreadDetailed => it.copy(undefined, undefined, undefined, undefined, userIds, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public setSchools(schoolIds: Array<ForeignKey<School>>): ApiModifier<ChatThreadDetailed> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<ChatThreadDetailed>("schools", schoolIds, (it: ChatThreadDetailed): ChatThreadDetailed => it.copy(undefined, undefined, undefined, undefined, undefined, schoolIds, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public setName(name: string): ApiModifier<ChatThreadDetailed> {
            return ApiModifier.constructorStringAnyFunction1HasIdHasId<ChatThreadDetailed>("name", name, (it: ChatThreadDetailed): ChatThreadDetailed => it.copy(undefined, undefined, name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        }
        
        public isNotAlertThread(): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>("alert_id__isnull=true", undefined, (it: ChatThreadDetailed): boolean => it.alert === null);
        }
        
        public isAlertThread(): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>("alert_id__isnull=false", undefined, (it: ChatThreadDetailed): boolean => it.alert !== null);
        }
        
        public isGroupThread(): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>("alert_id__isnull=true&is_direct_user=false", undefined, (it: ChatThreadDetailed): boolean => safeEq(it.threadType, ThreadType.Group));
        }
        
        public isUserThread(): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>("alert_id__isnull=true&is_direct_user=true", undefined, (it: ChatThreadDetailed): boolean => safeEq(it.threadType, ThreadType.Contact));
        }
        
        public forSchool(schoolId: ForeignKey<School>): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>(`school=${schoolId}`, undefined, (it: ChatThreadDetailed): boolean => it.associatedSchools.some((x) => safeEq(schoolId, x)));
        }
        
        public forAlert(alertId: ForeignKey<Alert>): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>(`alert_id=${alertId}`, undefined, (it: ChatThreadDetailed): boolean => it.alert === alertId);
        }
        
        public exactlyUsers(userIds: Array<ForeignKey<User>>): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>(`user_ids=${userIds.join(",")}`, undefined, (it: ChatThreadDetailed): boolean => safeEq(new Set(it.userIds), new Set(userIds)));
        }
        
        public active(): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>("is_active=true", undefined, (it: ChatThreadDetailed): boolean => it.isActive === true);
        }
        public search(query: string): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>(`query=${query}`, undefined, (it: ChatThreadDetailed): boolean => ((): (boolean | null) => {
                const temp2175 = it.name;
                if(temp2175 === null) { return null }
                return (temp2175.toLowerCase().indexOf(query.toLowerCase()) != -1)
            })() ?? false);
        }
        
        public anyMatchingUsers(userIds: Array<ForeignKey<User>>): ApiFilter<ChatThreadDetailed> {
            return ApiFilter.constructorStringFunction2MockDatabaseHasIdBooleanFunction1HasIdBoolean<ChatThreadDetailed>(`user_ids__contains=${userIds.join(",")}`, undefined, (it: ChatThreadDetailed): boolean => it.userIds.some((a: number): boolean => userIds.some((b: number): boolean => a === b)));
        }
        
        public readonly mostRecent: ApiSort<ChatThreadDetailed>;
        
        public readonly mostRecentDown: ApiSort<ChatThreadDetailed>;
        
        
        public readonly alphabetical: ApiSort<ChatThreadDetailed>;
        
        
        public readonly alphabeticalDown: ApiSort<ChatThreadDetailed>;
        
    }
}