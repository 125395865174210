//
// LayoutSelectGroupXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './layout_select_group.html'
//! Declares com.tresitgroup.android.tresit.layouts.LayoutSelectGroupXml
export class LayoutSelectGroupXml {
    xmlRoot!: HTMLElement
    loadingList!: HTMLDivElement
    rvGroupName!: HTMLDivElement
    btnAddGroup!: HTMLButtonElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.loadingList = getViewById<HTMLDivElement>(view, "loadingList")
        this.rvGroupName = getViewById<HTMLDivElement>(view, "rvGroupName")
        this.btnAddGroup = getViewById<HTMLButtonElement>(view, "btnAddGroup")
        return view
    }
}
