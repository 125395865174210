//
// AdminXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForw700dp from '../layout-w700dp/admin.html'
import htmlForw960dp from '../layout-w960dp/admin.html'
import htmlForDefault from './admin.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminXml
export class AdminXml {
    xmlRoot!: HTMLElement
    topBar: HTMLDivElement | null = null
    viewTitle!: HTMLDivElement
    header!: HTMLElement
    canChangeSchoolFilter!: HTMLImageElement
    subheader!: HTMLElement
    back!: HTMLButtonElement
    content!: HTMLDivElement
    bottomBar: HTMLDivElement | null = null
    adminDashboard!: HTMLInputElement
    adminUsers!: HTMLInputElement
    alertTypes!: HTMLInputElement
    alertHistory!: HTMLInputElement
    alertThreads!: HTMLInputElement
    wrap: HTMLDivElement | null = null
    logo: HTMLImageElement | null = null
    connectivityIndicator: HTMLImageElement | null = null
    connectedIndicator: HTMLImageElement | null = null
    landscapeIndicator: HTMLElement | null = null
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        if (window.innerWidth > 700) return htmlForw700dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960, 700])
        this.xmlRoot = view
        this.topBar = findViewById<HTMLDivElement>(view, "topBar")
        this.viewTitle = getViewById<HTMLDivElement>(view, "view_title")
        this.header = getViewById<HTMLElement>(view, "header")
        this.canChangeSchoolFilter = getViewById<HTMLImageElement>(view, "canChangeSchoolFilter")
        this.subheader = getViewById<HTMLElement>(view, "subheader")
        this.back = getViewById<HTMLButtonElement>(view, "back")
        this.content = getViewById<HTMLDivElement>(view, "content")
        this.bottomBar = findViewById<HTMLDivElement>(view, "bottomBar")
        this.adminDashboard = getViewById<HTMLInputElement>(view, "admin_dashboard")
        this.adminUsers = getViewById<HTMLInputElement>(view, "admin_users")
        this.alertTypes = getViewById<HTMLInputElement>(view, "alert_types")
        this.alertHistory = getViewById<HTMLInputElement>(view, "alert_history")
        this.alertThreads = getViewById<HTMLInputElement>(view, "alertThreads")
        this.wrap = findViewById<HTMLDivElement>(view, "wrap")
        this.logo = findViewById<HTMLImageElement>(view, "logo")
        this.connectivityIndicator = findViewById<HTMLImageElement>(view, "connectivityIndicator")
        this.connectedIndicator = findViewById<HTMLImageElement>(view, "connectedIndicator")
        this.landscapeIndicator = findViewById<HTMLElement>(view, "landscapeIndicator")
        return view
    }
}
