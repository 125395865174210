//
// ManageAlertsXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import htmlForDefault from './manage_alerts.html'
//! Declares com.tresitgroup.android.tresit.layouts.ManageAlertsXml
export class ManageAlertsXml {
    xmlRoot!: HTMLElement
    internalContainer!: HTMLDivElement
    internal!: HTMLInputElement
    approvalRequiredContainer!: HTMLDivElement
    approvalRequired!: HTMLInputElement
    fetchingAlertTypes!: HTMLDivElement
    alertTypeList!: HTMLDivElement
    loadHtmlString(): string {
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        this.xmlRoot = view
        this.internalContainer = getViewById<HTMLDivElement>(view, "internalContainer")
        this.internal = getViewById<HTMLInputElement>(view, "internal")
        this.approvalRequiredContainer = getViewById<HTMLDivElement>(view, "approvalRequiredContainer")
        this.approvalRequired = getViewById<HTMLInputElement>(view, "approvalRequired")
        this.fetchingAlertTypes = getViewById<HTMLDivElement>(view, "fetching_alert_types")
        this.alertTypeList = getViewById<HTMLDivElement>(view, "alert_type_list")
        return view
    }
}
