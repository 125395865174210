// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/auth/LandingScreenVG.kt
// Package: com.tresitgroup.android.tresit.vg.auth
// Generated by Khrysalis - this file will be overwritten.
import { xViewFlipperBindLoading } from 'butterfly-web/dist/observables/binding/ViewFlipper.binding'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { xEditTextSetOnDoneClick } from 'butterfly-web/dist/views/EditText'
import { xDisposableUntil, xViewRemovedGet } from 'butterfly-web/dist/rx/DisposeCondition.ext'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { ViewString, ViewStringRaw, ViewStringResource } from 'butterfly-web/dist/views/ViewString'
import { xSingleWorking } from 'butterfly-web/dist/rx/RxExtensions'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { xViewBindExists } from 'butterfly-web/dist/observables/binding/View.binding'
import { DisposeCondition } from 'butterfly-web/dist/rx/DisposeCondition'
import { R } from '../../R'
import { SessionApi } from '../../api/SessionApi'
import { DemoEmailVG } from '../DemoEmailVG'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { showDialogAlert } from 'butterfly-web/dist/views/showDialog'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { LandingScreenXml } from '../../layout/LandingScreenXml'
import { SubscriptionLike } from 'rxjs'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { runOrNull } from 'butterfly-web/dist/kotlin/Language'
import { ApiOption } from '../../api/ApiOption'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { xSingleCallDisplayingError } from '../../util/apicalls'
import { Session } from '../../model/Session'
import { StartLoginResponse } from '../../model/StartLoginResponse'
import { SessionVG } from '../SessionVG'
import { spinnerBind } from 'butterfly-web/dist/observables/binding/Spinner.binding'
import { ConstantObservableProperty } from 'butterfly-web/dist/observables/ConstantObservableProperty'
import { xActivityAccessOpenSsoWindow } from '../../openSsoWindow'

//! Declares com.tresitgroup.android.tresit.vg.auth.LandingScreenVG
export class LandingScreenVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly root: ObservableStack<ViewGenerator>;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, root: ObservableStack<ViewGenerator>, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.root = root;
        this.stack = stack;
        this.email = new StandardObservableProperty<string>("", undefined);
        this.password = new StandardObservableProperty<string>("", undefined);
        this.fName = new StandardObservableProperty<string>("", undefined);
        this.lName = new StandardObservableProperty<string>("", undefined);
        this.cellPhone = new StandardObservableProperty<string>("", undefined);
        this.loggingIn = new StandardObservableProperty<boolean>(false, undefined);
        this.resettingPassword = new StandardObservableProperty<boolean>(false, undefined);
        this.cleverWorking = new StandardObservableProperty<boolean>(false, undefined);
        this.usePasswordEntryForEmail = new StandardObservableProperty<string>("!#@", undefined);
        this.forcePassword = new StandardObservableProperty<boolean>(false, undefined);
        this.serverSwitchEnabled = new StandardObservableProperty<boolean>(false, undefined);
        this.locationLogin = new StandardObservableProperty<boolean>(false, undefined);
        this.isManualLoginAccount = xObservablePropertyCombine<string, string, boolean>(this.usePasswordEntryForEmail, this.email, (a: string, b: string): boolean => a === b);
        this.usePassword = xObservablePropertyCombine<boolean, boolean, boolean>(this.isManualLoginAccount, this.forcePassword, (a: boolean, b: boolean): boolean => a || b);
    }
    
    
    
    //! Declares com.tresitgroup.android.tresit.vg.auth.LandingScreenVG.title
    public get title(): string { return "Landing Screen"; }
    
    
    public readonly email: StandardObservableProperty<string>;
    
    public readonly password: StandardObservableProperty<string>;
    
    public readonly fName: StandardObservableProperty<string>;
    
    public readonly lName: StandardObservableProperty<string>;
    
    public readonly cellPhone: StandardObservableProperty<string>;
    
    public readonly loggingIn: StandardObservableProperty<boolean>;
    
    public readonly resettingPassword: StandardObservableProperty<boolean>;
    
    public readonly cleverWorking: StandardObservableProperty<boolean>;
    
    public readonly usePasswordEntryForEmail: StandardObservableProperty<string>;
    
    public readonly forcePassword: StandardObservableProperty<boolean>;
    
    public readonly serverSwitchEnabled: StandardObservableProperty<boolean>;
    
    public readonly locationLogin: StandardObservableProperty<boolean>;
    
    public readonly isManualLoginAccount: ObservableProperty<boolean>;
    
    public readonly usePassword: ObservableProperty<boolean>;
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new LandingScreenXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Set Up xml.background
        xml.backgroundDelegate.setup(dependency);
        //--- Set Up xml.secret
        if (!safeEq(ApiOption.Companion.INSTANCE.choice.value, ApiOption.Companion.INSTANCE.defaultOption())) {
            this.serverSwitchEnabled.value = true;
        }
        let times = 0;
        
        xml.secret.onclick = (_ev) => { _ev.stopPropagation();
            const it = _ev.target as HTMLElement;
            times = times + 1;
            if (times > 5) {
                this.serverSwitchEnabled.value = true;
            }
        };
        
        //--- Set Up xml.server
        xViewBindExists(xml.server, this.serverSwitchEnabled);
        spinnerBind<ApiOption>(xml.server, new ConstantObservableProperty<Array<ApiOption>>(ApiOption.Companion.INSTANCE.liveOptions), ApiOption.Companion.INSTANCE.choice, (it: ApiOption): string => it.name);
        
        //--- Set Up xml.email
        xEditTextBindString(xml.email, this.email);
        xEditTextSetOnDoneClick(xml.email, (): void => {
            xml.submit.click();
        });
        
        //--- Set Up xml.password
        xViewBindExists(xml.password, this.usePassword);
        xViewBindExists(xml.firstName, this.locationLogin);
        xViewBindExists(xml.lastName, this.locationLogin);
        xViewBindExists(xml.cellPhone, this.locationLogin);
        xEditTextBindString(xml.password, this.password);
        xEditTextBindString(xml.firstName, this.fName);
        xEditTextBindString(xml.lastName, this.lName);
        xEditTextBindString(xml.cellPhone, this.cellPhone);
        xEditTextSetOnDoneClick(xml.password, (): void => {
            xml.submit.click();
        });
        
        //--- Set Up xml.submitWorking
        xViewFlipperBindLoading(xml.submitWorking, this.loggingIn, undefined);
        
        //--- Set Up xml.submit
        xViewOnClick(xml.submit, undefined, (): void => {
            this.submitClick(xViewRemovedGet(xml.submit), (url: string): void => {
                    xActivityAccessOpenSsoWindow(dependency, url, (token: string): void => {
                        xDisposableUntil<SubscriptionLike>(xSingleCallDisplayingError<Session>(xSingleWorking<Session>(ApiOption.Companion.INSTANCE.current.refreshSession(token), this.loggingIn), undefined, (session: Session): void => {
                            //Success!  We logged in
                            this.stack.reset(new SessionVG(this.dialog, this.root, new SessionApi(session, ApiOption.Companion.INSTANCE.current, this.root, this.dialog), this.root));
                        }), xViewRemovedGet(xml.submit));
                    });
                }, (): void => {
                    xml.password.focus();
            });
        });
        
        //--- Set Up xml.demo
        xViewBindExists(xml.demo, this.serverSwitchEnabled);
        xViewOnClick(xml.demo, undefined, (): void => {
            this.demoClick();
        });
        
        //--- Set Up xml.forgotWorking
        xViewBindExists(xml.forgotWorking, this.usePassword);
        xViewFlipperBindLoading(xml.forgotWorking, this.resettingPassword, undefined);
        
        //--- Set Up xml.forgotPassword (overwritten on flow generation)
        xViewOnClick(xml.forgotPassword, undefined, (): void => {
            this.forgotPasswordClick();
        });
        
        //--- Set Up xml.manualLogin
        xViewBindExists(xml.manualLogin, xObservablePropertyMap<boolean, boolean>(this.isManualLoginAccount, (it: boolean): boolean => (!it)));
        xTextViewBindString(xml.manualLogin, xObservablePropertyMap<boolean, string>(this.forcePassword, (it: boolean): string => it ? R._string.automatic_login : R._string.manual_login));
        xViewOnClick(xml.manualLogin, undefined, (): void => {
            this.manualLoginClick();
        });
        
        //--- Set Up xml.cleverWorking
        xViewBindExists(xml.cleverWorking, xObservablePropertyMap<boolean, boolean>(this.usePassword, (it: boolean): boolean => (!it)));
        xViewFlipperBindLoading(xml.cleverWorking, this.cleverWorking, undefined);
        
        //--- Set Up xml.clever
        xViewOnClick(xml.clever, undefined, (): void => {
            xSingleCallDisplayingError<StartLoginResponse>(ApiOption.Companion.INSTANCE.current.cleverUrl(), undefined, (it: StartLoginResponse): void => {
                xActivityAccessOpenSsoWindow(dependency, it.url!!, (token: string): void => {
                    xDisposableUntil<SubscriptionLike>(xSingleCallDisplayingError<Session>(xSingleWorking<Session>(ApiOption.Companion.INSTANCE.current.refreshSession(token), this.loggingIn), undefined, (session: Session): void => {
                        //Success!  We logged in
                        this.stack.reset(new SessionVG(this.dialog, this.root, new SessionApi(session, ApiOption.Companion.INSTANCE.current, this.root, this.dialog), this.root));
                    }), xViewRemovedGet(xml.submit));
                });
            });
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public submitClick(disposeCondition: DisposeCondition, linkOutAction:  ((a: string) => void), usePassword:  (() => void)): void {
        if (this.usePasswordEntryForEmail.value === this.email.value || this.forcePassword.value) {
            if (this.locationLogin.value) {
                if (xCharSequenceIsBlank(this.fName.value)) {
                    showDialogAlert(new ViewStringRaw("Please enter your first name"));
                    return;
                }
                if (xCharSequenceIsBlank(this.lName.value)) {
                    showDialogAlert(new ViewStringRaw("Please enter your last name"));
                    return;
                }
                if (xCharSequenceIsBlank(this.cellPhone.value)) {
                    showDialogAlert(new ViewStringRaw("Please enter your phone number"));
                    return;
                }
                this.doPasswordLogin(disposeCondition);
                
            } else {
                this.doPasswordLogin(disposeCondition);
            }
        } else {
            this.doLoginStart(linkOutAction, usePassword, disposeCondition);
        }
    }
    
    private doLoginStart(linkOutAction:  ((a: string) => void), usePassword:  (() => void), disposeCondition: DisposeCondition): void {
        xDisposableUntil<SubscriptionLike>(xSingleCallDisplayingError<StartLoginResponse>(xSingleWorking<StartLoginResponse>(ApiOption.Companion.INSTANCE.current.startLogin(this.email.value, this.fName.value, this.lName.value, this.cellPhone.value), this.loggingIn), undefined, (it: StartLoginResponse): void => {
            const url_4139 = it.url;
            if (url_4139 !== null) {
                if (it.isLocationLogin === true) {
                    if (it.hasTempDetails === true) {
                        linkOutAction(url_4139);
                    } else {
                        this.locationLogin.value = true;
                    }
                } else {
                    linkOutAction(url_4139);
                }
            } else {
                this.usePasswordEntryForEmail.value = this.email.value;
                usePassword();
                if (it.isLocationLogin === true) { this.locationLogin.value = true }
            }
        }), disposeCondition);
    }
    
    private doPasswordLogin(disposeCondition: DisposeCondition): void {
        xDisposableUntil<SubscriptionLike>(xSingleCallDisplayingError<Session>(xSingleWorking<Session>(ApiOption.Companion.INSTANCE.current.passwordLogin(this.email.value, this.password.value, this.fName.value, this.lName.value, this.cellPhone.value), this.loggingIn), (code: number, message: string): (ViewString | null) => ((): (ViewString | null) => {
                    if (code === 400) {
                        return new ViewStringRaw("The email and password did not match our records.");
                    } else {
                        return null;
                    }
            })(), (session: Session): void => {
                //Success!  We logged in
                this.stack.reset(new SessionVG(this.dialog, this.root, new SessionApi(session, ApiOption.Companion.INSTANCE.current, this.root, this.dialog), this.root));
        }), disposeCondition);
    }
    
    public demoClick(): void {
        this.root.push(new DemoEmailVG(this.dialog, this.root));
    }
    
    public forgotPasswordClick(): void {
        xSingleCallDisplayingError<void>(xSingleWorking<void>(ApiOption.Companion.INSTANCE.current.forgotPassword(this.email.value), this.resettingPassword), undefined, (it: void): void => {
            showDialogAlert(new ViewStringResource(R._string.forgot_password_success));
        });
    }
    
    public manualLoginClick(): void {
        this.forcePassword.value = (!this.forcePassword.value);
    }
    //--- Action cleverClick
    
    //--- Body End
}
