// File: /Users/ayushshrestha/AndroidStudioProjects/tresit-khrysalis/android/src/main/java/com/tresitgroup/android/tresit/vg/admin/ChatHistoryVG.kt
// Package: com.tresitgroup.android.tresit.vg.admin
// Generated by Khrysalis - this file will be overwritten.
import { xDateFormat } from 'butterfly-web/dist/time/Date'
import { xViewBindVisible } from 'butterfly-web/dist/observables/binding/View.binding'
import { ApiSort } from '../../api/ApiSort'
import { safeEq } from 'butterfly-web/dist/Kotlin'
import { Observable } from 'rxjs'
import { Image, xStringAsImage } from 'butterfly-web/dist/Image'
import { xTextViewBindString } from 'butterfly-web/dist/observables/binding/TextView.binding'
import { logVG } from '../../util/LogVG'
import { ClockPartSize } from 'butterfly-web/dist/time/ClockPartSize'
import { xObservablePropertyPlusRx } from 'butterfly-web/dist/observables/DistinctObservableProperty'
import { PagingHelper } from '../../util/PagingHelper'
import { xViewOnClick } from 'butterfly-web/dist/views/View.ext'
import { debounceTime as rxDebounceTime } from 'rxjs/operators'
import { ComponentRowAlertThreadXml } from '../../layout/ComponentRowAlertThreadXml'
import { xRecyclerViewBind, xRecyclerViewBindRefresh, xRecyclerViewWhenScrolledToEnd } from 'butterfly-web/dist/observables/binding/RecyclerView.binding'
import { post } from 'butterfly-web/dist/delay'
import { ConversationDetailHistoryVG } from './ConversationDetailHistoryVG'
import { SessionApi } from '../../api/SessionApi'
import { listFilterNotNull } from 'butterfly-web/dist/KotlinCollections'
import { CombineManyObservableProperty } from 'butterfly-web/dist/observables/CombineManyObservableProperty'
import { xObservablePropertyCombine } from 'butterfly-web/dist/observables/CombineObservableProperty'
import { xObservablePropertyObservableNNGet } from 'butterfly-web/dist/observables/ObservableProperty.ext'
import { xEditTextBindString } from 'butterfly-web/dist/observables/binding/EditText.binding'
import { ApiFilter } from '../../api/ApiFilter'
import { ViewGenerator } from 'butterfly-web/dist/views/ViewGenerator'
import { MutableObservableProperty } from 'butterfly-web/dist/observables/MutableObservableProperty'
import { ApiPartialQuery } from '../../api/ApiPartialQuery'
import { xObservablePropertyMap } from 'butterfly-web/dist/observables/TransformedObservableProperty'
import { xImageViewBindImage } from 'butterfly-web/dist/observables/binding/ImageView.binding'
import { ApiQuery } from '../../api/ApiQuery'
import { StandardObservableProperty } from 'butterfly-web/dist/observables/StandardObservableProperty'
import { xCharSequenceIsBlank } from 'butterfly-web/dist/kotlin/kotlin.text'
import { ChatHistoryXml } from '../../layout/ChatHistoryXml'
import { ObservableStack } from 'butterfly-web/dist/observables/ObservableStack'
import { ObservableProperty } from 'butterfly-web/dist/observables/ObservableProperty'
import { ChatThreadDetailed } from '../../model/ChatThreadDetailed'
import { School } from '../../model/School'

//! Declares com.tresitgroup.android.tresit.vg.admin.ChatHistoryVG
export class ChatHistoryVG extends ViewGenerator {
    public readonly dialog: ObservableStack<ViewGenerator>;
    public readonly schoolFilter: MutableObservableProperty<(School | null)>;
    public readonly session: SessionApi;
    public readonly stack: ObservableStack<ViewGenerator>;
    public constructor(dialog: ObservableStack<ViewGenerator>, schoolFilter: MutableObservableProperty<(School | null)>, session: SessionApi, stack: ObservableStack<ViewGenerator>) {
        super();
        this.dialog = dialog;
        this.schoolFilter = schoolFilter;
        this.session = session;
        this.stack = stack;
        this.query = new StandardObservableProperty<string>("", undefined);
        this.userDefinedSort = new StandardObservableProperty<ApiSort<ChatThreadDetailed>>(ChatThreadDetailed.Companion.INSTANCE.mostRecent, undefined);
        this.debouncedApiFilter = xObservablePropertyMap<string, (ApiFilter<ChatThreadDetailed> | null)>(xObservablePropertyPlusRx<string>(this.query, (it: Observable<string>): Observable<string> => it.pipe(rxDebounceTime(1000))), (it: string): (ApiFilter<ChatThreadDetailed> | null) => xCharSequenceIsBlank(it) ? null : ChatThreadDetailed.Companion.INSTANCE.textSearch(it));
        this.schoolApiFilter = xObservablePropertyMap<(School | null), (ApiFilter<ChatThreadDetailed> | null)>(this.schoolFilter, (it: (School | null)): (ApiFilter<ChatThreadDetailed> | null) => ((): (ApiFilter<ChatThreadDetailed> | null) => {
            const temp3708 = it;
            if(temp3708 === null) { return null }
            return ((it: School): ApiFilter<ChatThreadDetailed> => ChatThreadDetailed.Companion.INSTANCE.forSchool(it.id))(temp3708)
        })());
        this.apiFilters = xObservablePropertyMap<Array<(ApiFilter<ChatThreadDetailed> | null)>, Array<ApiFilter<ChatThreadDetailed>>>(new CombineManyObservableProperty<(ApiFilter<ChatThreadDetailed> | null)>([this.debouncedApiFilter, this.schoolApiFilter]), (it: Array<(ApiFilter<ChatThreadDetailed> | null)>): Array<ApiFilter<ChatThreadDetailed>> => listFilterNotNull(it));
        this.queryObs = xObservablePropertyObservableNNGet(xObservablePropertyCombine<Array<ApiFilter<ChatThreadDetailed>>, ApiSort<ChatThreadDetailed>, ApiQuery<ChatThreadDetailed>>(this.apiFilters, this.userDefinedSort, (filters: Array<ApiFilter<ChatThreadDetailed>>, sort: ApiSort<ChatThreadDetailed>): ApiQuery<ChatThreadDetailed> => new ApiQuery<ChatThreadDetailed>(ApiFilter.Companion.INSTANCE.allList<ChatThreadDetailed>(filters.concat([ChatThreadDetailed.Companion.INSTANCE.isAlertThread()])), sort)));
    }
    
    
    
    public readonly query: MutableObservableProperty<string>;
    
    public readonly userDefinedSort: StandardObservableProperty<ApiSort<ChatThreadDetailed>>;
    
    public readonly debouncedApiFilter: ObservableProperty<(ApiFilter<ChatThreadDetailed> | null)>;
    
    public readonly schoolApiFilter: ObservableProperty<(ApiFilter<ChatThreadDetailed> | null)>;
    
    public readonly apiFilters: ObservableProperty<Array<ApiFilter<ChatThreadDetailed>>>;
    
    public readonly queryObs: Observable<ApiQuery<ChatThreadDetailed>>;
    
    
    public paging(): PagingHelper<ChatThreadDetailed> {
        return new PagingHelper<ChatThreadDetailed>(this.queryObs, (q: ApiQuery<ChatThreadDetailed>, p: number): Observable<Array<ChatThreadDetailed>> => this.session.rawApi.threadsDetailed(this.session.session, new ApiPartialQuery<ChatThreadDetailed>(q, 10, 10 * p)));
    }
    
    //! Declares com.tresitgroup.android.tresit.vg.admin.ChatHistoryVG.title
    public get title(): string { return "Chat History"; }
    
    
    public generate(dependency: Window): HTMLElement {
        const xml = new ChatHistoryXml();
        
        const view = xml.setup(dependency);
        
        
        //--- Log
        logVG(this, this.session);
        
        //--- Shortcuts
        const paging = this.paging();
        
        
        //--- Set Up xml.filter
        xEditTextBindString(xml.filter, this.query);
        
        //--- Set Up xml.loading
        //--- Set Up xml.threadNameHeader
        const temp3711 = xml.threadNameHeader;
        if(temp3711 !== null) {
            xViewOnClick(temp3711, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, ChatThreadDetailed.Companion.INSTANCE.alphabetical)) {
                    this.userDefinedSort.value = ChatThreadDetailed.Companion.INSTANCE.alphabeticalDown;
                } else {
                    this.userDefinedSort.value = ChatThreadDetailed.Companion.INSTANCE.alphabetical;
                }
            })
        };
        const temp3712 = xml.sortThreadNameAscending;
        if(temp3712 !== null) {
            xViewBindVisible(temp3712, xObservablePropertyMap<ApiSort<ChatThreadDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<ChatThreadDetailed>): boolean => !safeEq(it, ChatThreadDetailed.Companion.INSTANCE.alphabeticalDown)))
        };
        const temp3713 = xml.sortThreadNameDescending;
        if(temp3713 !== null) {
            xViewBindVisible(temp3713, xObservablePropertyMap<ApiSort<ChatThreadDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<ChatThreadDetailed>): boolean => !safeEq(it, ChatThreadDetailed.Companion.INSTANCE.alphabetical)))
        };
        
        //--- Set Up xml.threadNameHeader
        const temp3714 = xml.timestampHeader;
        if(temp3714 !== null) {
            xViewOnClick(temp3714, undefined, (): void => {
                if (safeEq(this.userDefinedSort.value, ChatThreadDetailed.Companion.INSTANCE.mostRecent)) {
                    this.userDefinedSort.value = ChatThreadDetailed.Companion.INSTANCE.mostRecentDown;
                } else {
                    this.userDefinedSort.value = ChatThreadDetailed.Companion.INSTANCE.mostRecent;
                }
            })
        };
        const temp3715 = xml.sortTimestampAscending;
        if(temp3715 !== null) {
            xViewBindVisible(temp3715, xObservablePropertyMap<ApiSort<ChatThreadDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<ChatThreadDetailed>): boolean => !safeEq(it, ChatThreadDetailed.Companion.INSTANCE.mostRecentDown)))
        };
        const temp3716 = xml.sortTimestampDescending;
        if(temp3716 !== null) {
            xViewBindVisible(temp3716, xObservablePropertyMap<ApiSort<ChatThreadDetailed>, boolean>(this.userDefinedSort, (it: ApiSort<ChatThreadDetailed>): boolean => !safeEq(it, ChatThreadDetailed.Companion.INSTANCE.mostRecent)))
        };
        
        //--- Set Up xml.alertThreads
        xRecyclerViewBindRefresh(xml.alertThreads, paging.loading, (): void => {
            paging.loadNewPage();
        });
        xRecyclerViewWhenScrolledToEnd(xml.alertThreads, (): void => {
            post((): void => {
                paging.loadNewPage();
            });
        });
        xRecyclerViewBind<ChatThreadDetailed>(xml.alertThreads, paging, new ChatThreadDetailed(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), (observable: ObservableProperty<ChatThreadDetailed>): HTMLElement => {
            //--- Make Subview For xml.alertThreads
            const cellXml = new ComponentRowAlertThreadXml();
            
            const cellView = cellXml.setup(dependency);
            
            
            //--- Set Up cellXml.root
            xViewOnClick(cellXml.xmlRoot, undefined, (): void => {
                this.xmlRootClick(observable.value);
            });
            
            //--- Set Up cellXml.alertTypeIcon
            const temp3717 = cellXml.alertTypeIcon;
            if(temp3717 !== null) {
                xImageViewBindImage(temp3717, xObservablePropertyMap<ChatThreadDetailed, (Image | null)>(observable, (it: ChatThreadDetailed): (Image | null) => ((): (Image | null) => {
                    const temp3718 = it.alertTypeIcon;
                    if(temp3718 !== null) {
                        return xStringAsImage(temp3718)
                    } else { return null }
                })()))
            };
            
            //--- Set Up cellXml.threadName
            xTextViewBindString(cellXml.threadName, xObservablePropertyMap<ChatThreadDetailed, string>(observable, (it: ChatThreadDetailed): string => it.name ?? "--"));
            
            //--- Set Up cellXml.schoolName
            xTextViewBindString(cellXml.schoolName, xObservablePropertyMap<ChatThreadDetailed, string>(observable, (it: ChatThreadDetailed): string => it.schoolNames.join(", ")));
            
            //--- Set Up cellXml.timestamp
            xTextViewBindString(cellXml.timestamp, xObservablePropertyMap<ChatThreadDetailed, string>(observable, (it: ChatThreadDetailed): string => ((): (string | null) => {
                const temp3720 = it.lastUpdated;
                if(temp3720 !== null) {
                    return xDateFormat(temp3720, ClockPartSize.Short, ClockPartSize.Short)
                } else { return null }
            })() ?? ""));
            
            //--- Set Up cellXml.alertIcon
            // HIDDEN: No access to alert icon from server at this time
            
            //--- End Make Subview For xml.alertThreads (overwritten on flow generation)
            return cellView;
        });
        
        //--- Generate End (overwritten on flow generation)
        
        return view;
    }
    
    //--- Init
    
    
    
    //--- Actions
    
    public xmlRootClick(thread: ChatThreadDetailed): void {
        this.stack.push(new ConversationDetailHistoryVG(this.dialog, this.session, thread.id, thread.name, this.schoolFilter, this.stack));
    }
    
    //--- Body End
}
