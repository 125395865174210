//
// AdminAlertTypeAddXml.ts
// Created by Khrysalis XML Typescript
//
import { loadHtmlFromString, findViewById, getViewById, replaceViewWithId, startupAutoResize } from 'butterfly-web/dist/views/html'
import { customViewSetDelegate } from 'butterfly-web/dist/views/CustomView'
import { FormAddAlertTypeXml } from './FormAddAlertTypeXml'
import htmlForw960dp from '../layout-w960dp/admin_alert_type_add.html'
import htmlForDefault from './admin_alert_type_add.html'
//! Declares com.tresitgroup.android.tresit.layouts.AdminAlertTypeAddXml
export class AdminAlertTypeAddXml {
    xmlRoot!: HTMLElement
    submitWorking!: HTMLDivElement
    cancel!: HTMLButtonElement
    submit!: HTMLButtonElement
    addForm!: FormAddAlertTypeXml
    loadHtmlString(): string {
        if (window.innerWidth > 960) return htmlForw960dp
        return htmlForDefault
    }
    setup(dependency: Window): HTMLElement {
        const view = loadHtmlFromString(this.loadHtmlString())
        startupAutoResize(view, [960])
        this.xmlRoot = view
        this.submitWorking = getViewById<HTMLDivElement>(view, "submitWorking")
        this.cancel = getViewById<HTMLButtonElement>(view, "cancel")
        this.submit = getViewById<HTMLButtonElement>(view, "submit")
        replaceViewWithId(view, ()=>{ 
                this.addForm = new FormAddAlertTypeXml()
                return this.addForm.setup(dependency)
        }, "addForm")
        return view
    }
}
